import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LoadingLevelsProgramViewModel, LoadingLevelsProgramViewModelRead } from 'src/app/_models/generatedModels';
import { dynamicSort } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'bxl-loading-levels-programs',
  templateUrl: 'loading-levels-programs.component.html',
})
export class LoadingLevelsProgramsComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  loadingLevelPrograms: LoadingLevelsProgramViewModelRead[];

  constructor(private fb: FormBuilder, private programData: ProgramsService, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Loading Levels Programs', 'loadingLevelsPrograms', []);

    this.programData.getLoadingLevelsPrograms().subscribe(results => {
      this.loadingLevelPrograms = results;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      loadingLevels: this.fb.array([])
    });

    const loadingLevels = this.formGroup.get('loadingLevels') as FormArray;
    this.loadingLevelPrograms.forEach(level => {
      loadingLevels.push(this.fb.group({
        loadingLevel: [level.loadingLevel],
        programId: [level.programId]
      }));
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: LoadingLevelsProgramViewModel[] = this.formGroup.get('loadingLevels').value;

      this.programData.updateLoadingLevelsPrograms(formData).subscribe((results) => {
        this.toastr.success('Programs Saved', 'Success');
        resetButton();
      }, error => { this.toastr.error('There was an error during saving', 'Error')});
    });
  }


}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="p-3">
  <div class="mb-2">
    <div class="row">
      <div class="col-12 text-center">
        <img class="rundna-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-default.png" />
      </div>
      <div class="col-12 text-center mb-1">
        <div>
          For full details visit app.rundna.com
        </div>
      </div>
    </div>
  </div>

  <quick-workout-view [quickWorkout]="quickWorkout" [isForPDF]="true"></quick-workout-view>

</div>

import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ProgramsService} from 'src/app/_services/generatedServices';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ProgramAssignmentViewModel } from 'src/app/_models/generatedModels';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'program-add-to-calendar-dialog',
  templateUrl: 'program-add-to-calendar.component.html',
})
export class ProgramAddToCalendarComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  maxStartDate: Date = null;

  @Input()
    clientUserId: number;

  @Input()
    programId: number;

  @Input()
    programName: string;

  @Input()
    isPurchasedProgram: boolean = false;

  @Input()
    purchaseDate: Date = null;

  @Output()
  saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private programData: ProgramsService, private auth: AuthenticationService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.purchaseDate) {
      this.maxStartDate = new Date().addDays(365);
    } else {
      this.maxStartDate = new Date(this.purchaseDate).addDays(365);
    }

    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientUserId: [this.clientUserId, Validators.required],
      programId: [this.programId],
      assignedByUserId: [this.auth.user.id],
      startDate: [null, Validators.required],
      isPurchasedProgram: [this.isPurchasedProgram]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: ProgramAssignmentViewModel = Object.assign({}, this.formGroup.getRawValue());
      formData.startDate = dateAsUTCNoTime(formData.startDate);

      this.programData.assignProgramToClient(formData).subscribe(results => {
          this.toastr.success('Program added to calendar', 'Success');
          this.saved.emit(true);
          this.activeModal.dismiss('saved');
          resetButton();
      }, error => resetButton());
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <form class="form" [formGroup]="formGroup" (ngSubmit)="onSave()" novalidate>
    <div class="card mt-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-8"><h3 class="mb-0">Edit profile</h3></div>

          <div class="col-4 text-right">
            <button class="btn btn-primary" [promiseBtn]="submitComplete" type="submit">Save</button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <h6 class="heading-small text-muted mb-4 inline-block">User information</h6>

        <div class="pl-lg-4">
          <div class="media align-items-center mb-3">
            <div class="avatar rounded-circle mr-3 d-inline">
              <img *ngIf="!croppedImage" class="img-fluid" src="fetch-avatar?userId={{ user.id }}&ignoreImage={{ croppedImage == null }}" alt="Image Description" />
              <img *ngIf="croppedImage" class="img-fluid" [src]="croppedImage" alt="Image Description" />
            </div>

            <div *ngIf="isSelf" class="media-body">
              <button type="button" (click)="editImage()" class="btn btn-sm btn-primary transition-3d-hover mb-1 mr-2 mb-sm-0">Upload New Picture</button>
              <button type="button" (click)="deleteImage()" class="btn btn-sm btn-soft-secondary transition-3d-hover mb-1 mb-sm-0">Delete</button>
            </div>

            <alert *ngIf="isCoachView && isMarketplaceAthlete" class="text-center" type="info" [dismissible]="false">
              <div>
                <span class="font-weight-bold">This athlete is a Marketplace athlete.</span>
              </div>
            </alert>
          </div>

          

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="input-first-name"> First name </label>

                <input class="form-control" id="input-first-name" formControlName="firstName" placeholder="First name" type="text" />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="input-last-name"> Last name </label>

                <input class="form-control" formControlName="lastName" id="input-last-name" placeholder="Last name" type="text" value="Jesse" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="input-email"> Email address </label>

                <input class="form-control" id="input-email" formControlName="email" placeholder="jesse@example.com" type="email" />
                <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.emailTaken">
                  <div>This email address is already in use.</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label  d-block" for="yearOfBirth"> Year Of Birth </label>
                <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="yearOfBirth" [decimals]="'0'" [format]="'####'" [min]="1900" [max]="2900" type="number"></kendo-numerictextbox>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="example3cols1Input"> Gender </label>
                <select formControlName="gender" class="form-control">
                  <option value="">Please Choose...</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="0">Other</option>
                </select>
                <div class="invalid-message">
                  <div>Gender is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="isSelf" class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="password"> Password </label>
                <a class="d-block mt-1" href="javascript:void(0)" (click)="onChangePassword()">Change Password</a>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="coachUserId"> Coach </label>
                <div *ngIf="isCoachView" class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="Assigned coaches are used when filtering athlete lists on reports and dashboards,
                  as well as determining which coach gets messages from athletes."></i>
                </div>
                <coaches-dropdown class="form-control" formControlName="coachUserId"></coaches-dropdown>
              </div>
            </div>
            <div *ngIf="isCoachView" class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="teamIds"> Teams </label>
                <teams-multiselect formControlName="teamIds"></teams-multiselect>
              </div>
            </div>
            <div *ngIf="isCoachView && isMarketplaceAthlete" class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="isCoachedAthlete"> Is Coached Athlete </label>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="This user is a marketplace athlete, and can also be set as a coached athlete so they won't be deactivated when their marketplace program access ends."></i>
                </div>
                <input class="d-block single-checkbox" formControlName="isCoachedAthlete" type="checkbox"/>
              </div> 
            </div>
          </div>
        </div>

        <hr class="my-4" />

        <h6 class="heading-small text-muted mb-4">Other information</h6>

        <div class="pl-lg-4">
          <div class="row">
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label" for="measurementSystem"> Measurement System</label>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="Choose a measurement system for distances to be displayed in."></i>
                </div>
                <select class="form-control" formControlName="measurementSystem" (ngModelChange)="onMeasurementSystemChanged()">
                  <ng-container *ngFor="let mapEntry of measurementSystemEnum | keyvalue">
                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label">Weight</label>
                <div class="input-group mb-3">
                  <input formControlName="weightInPounds" type="number" class="form-control" max="999" />

                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">{{isMetricSystem ? 'KG' : 'LBS'}}</span>
                  </div>
                  <div class="invalid-message-visible" *ngIf="formGroup?.controls['weightInPounds'].errors && formGroup?.controls['weightInPounds'].errors.required">
                    <div>Weight is required</div>
                  </div>
                  <div class="invalid-message-visible" *ngIf="formGroup?.controls['weightInPounds'].errors && formGroup?.controls['weightInPounds'].errors.pattern">
                    <div>Weight cannot contain a decimal</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label" for="example3cols1Input"> Height</label>
                
                <div *ngIf="isMetricSystem" class="input-group mb-3">
                  <input formControlName="heightInInches" type="number" class="form-control" max="999" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">CM</span>
                  </div>
                  <div class="invalid-message-visible" *ngIf="formGroup?.controls['heightInInches'].errors && formGroup?.controls['heightInInches'].errors.required">
                    <div>Height is required</div>
                  </div>
                </div>

                <select *ngIf="!isMetricSystem" formControlName="heightInInches" class="form-control">
                  <option value="">Please Choose...</option>
                  <option value="48">4ft</option>
                  <option value="49">4ft, 1in</option>
                  <option value="50">4ft, 2in</option>
                  <option value="51">4ft, 3in</option>
                  <option value="52">4ft, 4in</option>
                  <option value="53">4ft, 5in</option>
                  <option value="54">4ft, 6in</option>
                  <option value="55">4ft, 7in</option>
                  <option value="56">4ft, 8in</option>
                  <option value="57">4ft, 9in</option>
                  <option value="58">4ft, 10in</option>
                  <option value="59">4ft, 11in</option>
                  <option value="60">5ft</option>
                  <option value="61">5ft, 1in</option>
                  <option value="62">5ft, 2in</option>
                  <option value="63">5ft, 3in</option>
                  <option value="64">5ft, 4in</option>
                  <option value="65">5ft, 5in</option>
                  <option value="66">5ft, 6in</option>
                  <option value="67">5ft, 7in</option>
                  <option value="68">5ft, 8in</option>
                  <option value="69">5ft, 9in</option>
                  <option value="70">5ft, 10in</option>
                  <option value="71">5ft, 11in</option>
                  <option value="72">6ft</option>
                  <option value="73">6ft, 1in</option>
                  <option value="74">6ft, 2in</option>
                  <option value="75">6ft, 3in</option>
                  <option value="76">6ft, 4in</option>
                  <option value="77">6ft, 5in</option>
                  <option value="78">6ft, 6in</option>
                  <option value="79">6ft, 7in</option>
                  <option value="80">6ft, 8in</option>
                  <option value="81">6ft, 9in</option>
                  <option value="82">6ft, 10in</option>
                  <option value="83">6ft, 11in</option>
                  <option value="84">7ft</option>
                  <option value="85">7ft, 1in</option>
                  <option value="86">7ft, 2in</option>
                  <option value="87">7ft, 3in</option>
                  <option value="88">7ft, 4in</option>
                  <option value="89">7ft, 5in</option>
                  <option value="90">7ft, 6in</option>
                  <option value="91">7ft, 7in</option>
                  <option value="92">7ft, 8in</option>
                  <option value="93">7ft, 9in</option>
                  <option value="94">7ft, 10in</option>
                  <option value="95">7ft, 11in</option>
                </select>
                <div class="invalid-message">
                  <div>Height is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label" for="example3cols2Input"> Years of Running Experience </label>

                <select formControlName="yearsOfExperience" class="form-control">
                  <option value="">Please Choose...</option>
                  <option value="Beginner">Beginner</option>
                  <option value="OneToTwo">1-2 Years</option>
                  <option value="ThreeToFive">3-5 Years</option>
                  <option value="FiveToSeven">5-7 Years</option>
                  <option value="SevenToTen">7-10 Years</option>
                  <option value="TenPlus">10+ Years</option>
                </select>
                <div class="invalid-message">
                  <div>Experience is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label" for="example3cols3Input"> Highest Level of running </label>

                <select formControlName="highestLevelOfRunning" class="form-control">
                  <option value="">Please Choose...</option>
                  <option value="Recreational">Recreational</option>
                  <option value="HighSchool">High School</option>
                  <option value="College">College</option>
                  <option value="Professional">Professional</option>
                </select>
                <div class="invalid-message">
                  <div>Running Level is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label" for="maxHeartRate"> Max Heart Rate (bpm)</label>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="Max heart rate is used to calculate customized heart rate ranges for you when you are assigned a workout with a target heart rate zone."></i>
                </div>
                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="maxHeartRate" [decimals]="'0'" [format]="'####'" [min]="50" [max]="230" type="number"></kendo-numerictextbox>
                <div class="invalid-message">
                  <div>Max Heart Rate is optional. If specified, must be between 50 and 230.</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group">
                <label class="form-control-label" for="cyclingFunctionalThresholdPower"> Cycling FTP (W)</label>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="FTP is used to calculate customized cycling power ranges for you when you are assigned a workout with a target power zone."></i>
                </div>
                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="cyclingFunctionalThresholdPower" [decimals]="'0'" [format]="'####'" [min]="50" [max]="600" type="number"></kendo-numerictextbox>
                <div class="invalid-message">
                  <div>FTP is optional. If specified, must be between 50 and 600.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="showCoachBio">
          <hr class="my-4" />
          <h6 class="heading-small text-muted mb-4">Coach Bio</h6>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label" for="biography">
                    Coach Bio
                  </label>
                  <custom-editor formControlName="biography"></custom-editor>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        

      </div>
    </div>

    <app-image-upload-modal *ngIf="showImageUpload" (changedImage)="onImageChanged($event)" [(openDialog)]="showImageUpload" [initialCroppedImage]="initialCroppedImage"></app-image-upload-modal>
  </form>
</div>

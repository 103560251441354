<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-6">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block mb-0">Runner Readiness Assessment</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt--6">
    <form class="form" [formGroup]="formGroup" novalidate>
      <div class="card mb-4">
        <div class="card-header"><h5 class="mb-0">5 Fundamental Movements</h5></div>

        <div class="card-body">
          <div class="container">
            <div class="mr-auto">
              <div class="row">
                <div class="col"></div>
              </div>
              <div class="row align-items-center bg-lighter pt-1 pb-1">
                <div class="col-lg-6 col-md-12">
                  <h5>Toe Touch <button type="button" (click)="showHelp('toeTouch')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                </div>
                <div class="col-lg-6 col-md-12">
                  <select class="form-control" formControlName="toeTouch" id="exampleFormControlSelect1">
                    <option [ngValue]="'Able'">Able</option>
                    <option [ngValue]="'AbleWithPain'">Able Pain</option>
                    <option [ngValue]="'Unable'">Unable</option>
                    <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                  </select>
                </div>
              </div>

              <ng-container *ngIf="formGroup.controls['toeTouch'].value != 'Able'">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Straight Leg Raise <button type="button" (click)="showHelp('toeTouchStraighLegRaise')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="toeTouchStraighLegRaiseLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="toeTouchStraighLegRaiseRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2 align-bottom">
                      <h6>Child Pose <button type="button" (click)="showHelp('toeTouchChildPose')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <select class="form-control" formControlName="toeTouchChildPose" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row align-items-center bg-lighter pt-1 pb-1">
                <div class="col-lg-6 col-md-12">
                  <h5>Back Bend <button type="button" (click)="showHelp('backBend')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                </div>
                <div class="col-lg-6 col-md-12">
                  <select class="form-control" formControlName="backBend" id="exampleFormControlSelect1">
                    <option [ngValue]="'Able'">Able</option>
                    <option [ngValue]="'AbleWithPain'">Able Pain</option>
                    <option [ngValue]="'Unable'">Unable</option>
                    <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                  </select>
                </div>
              </div>
              <ng-container *ngIf="formGroup.controls['backBend'].value != 'Able'">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Prone Hip Extension <button type="button" (click)="showHelp('backBendProneHipExtension')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="backBendProneHipExtensionLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="backBendProneHipExtensionRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Prone Press Up <button type="button" (click)="showHelp('backBendPronePressUp')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <select class="form-control" formControlName="backBendPronePressUp" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row row align-items-center bg-lighter pt-1 pb-1">
                <div class="col-lg-6 col-md-12">
                  <h5>Rotation <button type="button" (click)="showHelp('rotation')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="rotationLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="rotationRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="formGroup.controls['rotationLeft'].value != 'Able' || formGroup.controls['rotationRight'].value != 'Able'">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Quadruped Rotation <button type="button" (click)="showHelp('rotationQuadrupedRotation')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationQuadrupedRotationLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationQuadrupedRotationRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>External Prone Hip Rotation <button type="button" (click)="showHelp('rotationProneHipExternalRotation')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationProneHipExternalRotationLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationProneHipExternalRotationRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Internal Prone Hip Rotation <button type="button" (click)="showHelp('rotationProneHipInternalRotation')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationProneHipInternalRotationLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationProneHipInternalRotationRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                            <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                            <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                            <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="row align-items-center bg-lighter pt-1 pb-1">
                <div class="col-lg-6 col-md-12">
                  <h5>Single Leg Balance <button type="button" (click)="showHelp('singleLegBalance')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="singleLegBalanceLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="singleLegBalanceRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="formGroup.controls['singleLegBalanceLeft'].value != 'Able' || formGroup.controls['singleLegBalanceRight'].value != 'Able'">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Half Kneel <button type="button" (click)="showHelp('singleLegBalanceHalfKneel')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="singleLegBalanceHalfKneelLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="singleLegBalanceHalfKneelRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Foot Assessment <button type="button" (click)="showHelp('singleLegBalanceFootAssessment')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <select class="form-control" formControlName="singleLegBalanceFootAssessment" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="row align-items-center bg-lighter pt-1 pb-1">
                <div class="col-lg-6 col-md-12">
                  <h5>Squat <button type="button" (click)="showHelp('squat')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                </div>
                <div class="col-lg-6 col-md-12">
                  <select class="form-control" formControlName="squat" id="exampleFormControlSelect1">
                    <option [ngValue]="'Able'">Able</option>
                    <option [ngValue]="'AbleWithPain'">Able Pain</option>
                    <option [ngValue]="'Unable'">Unable</option>
                    <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                  </select>
                </div>
              </div>

              <ng-container *ngIf="formGroup.controls['squat'].value != 'Able'">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>DKTC <button type="button" (click)="showHelp('squatDKTC')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="squatDKTCLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="squatDKTCRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="pl-2">
                      <h6>Lunge Dorsiflexion <button type="button" (click)="showHelp('squatLungeDorsiflexion')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="squatLungeDorsiflexionLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="squatLungeDorsiflexionRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'AbleWithPain'">Able Pain</option>
                            <option [ngValue]="'Unable'">Unable</option>
                            <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header"><h5 class="mb-0">Motor Control</h5></div>

        <div class="card-body">
          <div class="container">
            <div class="mr-auto">
              <div class="row">
                <div class="col"></div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Single Leg to Sit Stand <button type="button" (click)="showHelp('singleLegSitToStand')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="singleLegSitToStandLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="singleLegSitToStandRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Single Leg Calf Raise <button type="button" (click)="showHelp('singleLegCalfRaise')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="singleLegCalfRaiseLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="singleLegCalfRaiseRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Side Plank <button type="button" (click)="showHelp('sidePlank')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="sidePlankLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="sidePlankRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Unilateral Hip Bridge Endurance <button type="button" (click)="showHelp('unilateralHipBridgeEndurance')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="unilateralHipBridgeEnduranceLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="unilateralHipBridgeEnduranceRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-header"><h5 class="mb-0">Foot Assessment</h5></div>

        <div class="card-body">
          <div class="container">
            <div class="mr-auto">
              <div class="row">
                <div class="col"></div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Toe Splay <button type="button" (click)="showHelp('toeSplay')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="toeSplayLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="toeSplayRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Hallux Extension <button type="button" (click)="showHelp('halluxExtension')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="halluxExtensionLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                          <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                          <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                          <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="halluxExtensionRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'ActiveLimitation'">Active Limitation</option>
                          <option [ngValue]="'ActiveLimitationWithPain'">Active Limitation Pain</option>
                          <option [ngValue]="'PassiveLimitation'">Passive Limitation</option>
                          <option [ngValue]="'PassiveLimitationWithPain'">Passive Limitation Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Hallux Abduction <button type="button" (click)="showHelp('halluxExtensionAbduction')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="halluxExtensionAbductionLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="halluxExtensionAbductionRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Ankle Inversion <button type="button" (click)="showHelp('ankleInversion')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="ankleInversionLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="ankleInversionRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center bg-lighter">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <h5>Ankle Eversion <button type="button" (click)="showHelp('ankleEversion')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Left</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="ankleEversionLeft" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <span class="ml-2 text-muted text-xs">Right</span>
                      <div class="form-group">
                        <select class="form-control" formControlName="ankleEversionRight" id="exampleFormControlSelect1">
                          <option [ngValue]="'Able'">Able</option>
                          <option [ngValue]="'AbleWithPain'">Able Pain</option>
                          <option [ngValue]="'Unable'">Unable</option>
                          <option [ngValue]="'UnableWithPain'">Unable Pain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header"><h5 class="mb-0">Other</h5></div>
        <div class="card-body">
          <div class="container">
            <div class="mr-auto">

              <div class="row align-items-center bg-lighter pt-1 pb-1">
                <div class="col-lg-6 col-md-12">
                  <h5>Running Gait Impairment Category</h5>
                </div>
                <div class="col-lg-6 col-md-12">
                  <select class="form-control" formControlName="impairmentCategory" id="exampleFormControlSelect1">
                    <option [ngValue]="null">N/A</option>
                    <option [ngValue]="'OverstriderCadence'">Overstrider Cadence</option>
                    <option [ngValue]="'OverstriderKneeDrive'">Overstrider Knee Drive</option>
                    <option [ngValue]="'OverstriderCollapsing'">Overstrider Collapsing</option>
                    <option [ngValue]="'OverstriderGluteAmnesiac'">Overstrider Glute Amnesiac</option>
                    <option [ngValue]="'OverstriderGeneral'">Overstrider General</option>
                    <option [ngValue]="'GeneralCadence'">General Cadence</option>
                    <option [ngValue]="'CollapserCPD'">Collapser CPD</option>
                    <option [ngValue]="'CollapserBouncer'">Collapser Bouncer</option>
                    <option [ngValue]="'CollapserHip'">Collapser Hip</option>
                    <option [ngValue]="'Collapser'">Collapser</option>
                    <option [ngValue]="'Bouncer'">Bouncer</option>
                    <option [ngValue]="'GluteAmnesiac'">Glute Amnesiac</option>
                    <option [ngValue]="'Weaver'">Weaver</option>
                    <option [ngValue]="'Uncategorized'">Uncategorized</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <h5>Notes: </h5><textarea class="form-control" formControlName="notes" maxlength="250" type="text"></textarea>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
        </div>
        <div class="col text-right">
          <button (click)="onSave(true)" [promiseBtn]="submitComplete" class="btn btn-secondary">Save for Later</button>
          <button (click)="onSave(false)" [promiseBtn]="submitComplete" class="btn btn-primary">Finish</button>
        </div>
      </div>
    </form>
  </div>
</div>


import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { ClientDayEventType, ClientProgramWorkoutDayActivityTypeDurationViewModel, ClientProgramWorkoutDayViewModel, ClientProgramWorkoutDayViewModelRead } from 'src/app/_models/generatedModels';
import { CheckboxOption } from 'src/app/_models/models';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-manual-workout-modal',
  templateUrl: 'manual-workout-modal.component.html',
  styleUrls: ['manual-workout-modal.component.scss'],
})
export class ManualWorkoutModalComponent implements OnInit {
  initialized = false;
  clientWorkout: ClientProgramWorkoutDayViewModelRead;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  activityTypeEnum = Enums.ActivityTypeEnum;

  RPEOptions: CheckboxOption[] = [];
  showRPEError: boolean = false;

  @Input()
  clientUserId: number;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  selectedDate: Date | undefined;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private toastr: ToasterService, private clientService: ClientsService) {}

  ngOnInit(): void {
    for (let i = 1; i < 11; i++) {
      this.RPEOptions.push(new CheckboxOption(i.toString(), i.toString(), i, false));
    }

    if (this.clientProgramWorkoutDayId) {
      this.clientService.getClientProgramWorkoutDayById(this.clientUserId, this.clientProgramWorkoutDayId).subscribe((result) => {
        this.clientWorkout = result;
        if (this.clientWorkout.ratePerceivedExertion) {
          this.RPEOptions.find((x) => x.id == this.clientWorkout.ratePerceivedExertion).selected = true;
        }
        this.setupForm();
      });
    } else {
      this.setupForm();
    }
  }

  setupForm(): any {
    const activityTypeDuration = this.clientWorkout ? this.clientWorkout.activityTypeDurations[0] : null;
    this.formGroup = this.fb.group({
      workoutDate: [this.clientWorkout ? new Date(this.clientWorkout.workoutDate) : (this.selectedDate ? new Date(this.selectedDate) : new Date()), [Validators.required]],
      activityType: [activityTypeDuration ? activityTypeDuration.activityType : null, [Validators.required]],
      duration: [activityTypeDuration ? activityTypeDuration.duration : null, [Validators.required]],
      distance: [activityTypeDuration ? activityTypeDuration.distance : null],
    });

    this.initialized = true;
  }

  onRPEChanged(id: number) {
    this.showRPEError = false;
    for (var i = 0; i < this.RPEOptions.length; i++) {
      if (this.RPEOptions[i].id != id) {
        this.RPEOptions[i].selected = false;
      }
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    if (this.RPEOptions.filter((x) => x.selected).length == 0) {
      this.showRPEError = true;
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: ClientProgramWorkoutDayViewModel = this.clientWorkout ? this.clientWorkout : new ClientProgramWorkoutDayViewModel();
      formData.sortOrder = this.clientWorkout ? this.clientWorkout.sortOrder : 1;
      formData.workoutDate = dateAsUTCNoTime(this.formGroup.get('workoutDate').value);
      formData.ratePerceivedExertion = this.RPEOptions.find((x) => x.selected).id as number;
      formData.isCompleted = true;
      formData.completedDate = formData.workoutDate;
      formData.eventType = ClientDayEventType.ManualWorkout;

      //const duration = this.formGroup.get('duration').value;
      let activityTypeDuration: ClientProgramWorkoutDayActivityTypeDurationViewModel = this.formGroup.value;
      //activityTypeDuration.duration = duration || 0;
      formData.activityTypeDurations = [activityTypeDuration];


      if (this.clientProgramWorkoutDayId) {
        this.update(formData, resetButton);
      } else {
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.clientService.addClientProgramWorkoutDay(this.clientUserId, formData).subscribe((result) => {
      this.toastr.success('Workout Added', 'Success');
      this.activeModal.dismiss('saved');
      resetButton();
    });
  }

  update(formData: any, resetButton: () => any) {
    this.clientService.updateClientProgramWorkoutDay(this.clientUserId, this.clientProgramWorkoutDayId, formData).subscribe((result) => {
      this.toastr.success('Workout Updated', 'Success');
      this.activeModal.dismiss('saved');
      resetButton();
    });
  }
}

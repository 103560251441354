import { Injectable } from '@angular/core';
import { ProgramSummaryViewModelRead, ProgramViewModelRead, ProgramWeekViewModelRead, ProgramWorkoutDayViewModelRead } from '../_models/generatedModels';
import { ProgramWeek } from '../_models/models';
import { dynamicSort } from './extensions.module';


@Injectable()
export class ProgramHelper {
  constructor() { }

  static populatePogramWeeksFromSummary(program: ProgramSummaryViewModelRead): ProgramWeek[] {
    return this.populateWeeks(1, program.programFirstWeek, program.programWorkoutDaysFirstWeek);
  }

  static populateProgramWeeks(program: ProgramViewModelRead): ProgramWeek[] {
    return this.populateWeeks(program.weekCount, program.programWeeks, program.workoutDays);
  }

  static populateWeeks(weekCount: number, programWeeks: ProgramWeekViewModelRead[], workoutDays: ProgramWorkoutDayViewModelRead[]): ProgramWeek[] {
    var weeks: ProgramWeek[] = [];

    // first initialize the weeks
    for (let index = 0; index < weekCount; index++) {
      weeks.splice(index, 0, new ProgramWeek(index + 1));
    }

    // update week values if they exist
    programWeeks.forEach(week => {
      let currentWeek = weeks.find(x => x.weekNumber == week.weekNumber);
      currentWeek.trainingLoadPeriodType = week.trainingLoadPeriodType;
      currentWeek.id = week.id;
      currentWeek.programId = week.programId;
    });

    // then populate the workout days
    workoutDays.sort(dynamicSort('dayNumber')).sort(dynamicSort('sortOrder')).forEach(item => {
      const weekDayNumber = item.dayNumber % 7 || 7;
      const weekNumber = weekDayNumber === 7 ? Math.floor(item.dayNumber / 7) :  Math.floor(item.dayNumber / 7) + 1;
      const workoutDay: ProgramWorkoutDayViewModelRead = {
        dayNumber: item.dayNumber,
        id: item.id,
        programId: item.programId,
        sortOrder: item.sortOrder,
        workoutId: item.workoutId,
        workout: item.workout,
        coachNotes: item.coachNotes,
        eventName: item.eventName,
        eventType: item.eventType,
        taskDescription: item.taskDescription,
        workoutName: item.workoutName,
        quickWorkoutName: item.quickWorkoutName,
        quickWorkoutDescription: item.quickWorkoutDescription,
        quickWorkoutActivityType: item.quickWorkoutActivityType,
        quickWorkoutDuration: item.quickWorkoutDuration,
        quickWorkoutDistance: item.quickWorkoutDistance,
        quickWorkoutExpectedTrainingLoad: item.quickWorkoutExpectedTrainingLoad
      }

      weeks[weekNumber - 1].programDays[weekDayNumber - 1].workoutDays.push(workoutDay);
    });

    return weeks;
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="!isPrintVersion" class="row">
    <div class="col-lg-12 mt-1 mb-2">
      <div class="float-right d-none d-lg-block">
        <a href="javascript:void(0)" class="btn btn-sm btn-primary" (click)="onPrintPDF()">Print PDF</a>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Summary</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h3>{{assessment.dateTime | date}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>Movement Category: {{assessment.movementCategory | enumString: MovementCategoryEnum}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>{{'+' + assessment.handicapScore}} {{assessment.impairmentCategory | enumString: ImpairmentCategoryEnum}}</h3>
          </div>
        </div>
        <div *ngIf="assessment.notes" class="row">
          <div class="col">
            Notes: {{assessment.notes}}
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let section of assessment.sections" class="card">
      <div class="card-header"><h5 class="h4 mb-0">{{section.sectionName}}</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr *ngFor="let category of section.categoryResults">
                  <td style="font-size: 16px; padding:0.5rem">
                    <div style="font-weight:500;">{{category.category.name}}:
                      <span *ngIf="category.category.assessmentResult2" class="assessment-result">Left: </span>
                      <span class="assessment-result" [ngClass]="{'text-success': category.category.assessmentResult == AssessmentResult.Able, 'text-danger': category.category.assessmentResult != AssessmentResult.Able}">{{category.category.assessmentResult | enumString: AssessmentResultEnum}}</span>
                      <span *ngIf="category.category.assessmentResult2" class="assessment-result"> Right: </span>
                      <span *ngIf="category.category.assessmentResult2" class="assessment-result" [ngClass]="{'text-success': category.category.assessmentResult2 == AssessmentResult.Able, 'text-danger': category.category.assessmentResult2 != AssessmentResult.Able}">{{category.category.assessmentResult2 | enumString: AssessmentResultEnum}}</span>
                    </div>
                    <ng-container *ngFor="let subCat of category.subCategories">
                      <div class="ml-4">{{subCat.name}}: <span class="assessment-result" [ngClass]="{'text-success': subCat.assessmentResult == AssessmentResult.Able, 'text-danger': subCat.assessmentResult != AssessmentResult.Able}">{{subCat.assessmentResult | enumString: AssessmentResultEnum}}</span></div>
                    </ng-container>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime, parseDateString } from 'src/app/_helpers/extensions.module';
import { CalendarEventViewModel, CalendarType, ClientDayEventType, ClientProgramWorkoutDayViewModel, TeamProgramWorkoutDayViewModel, WorkoutViewModelRead } from 'src/app/_models/generatedModels';
import { CalendarWorkoutEvent } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService, TeamService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { CalendarWorkoutDialogComponent } from '../calendar-workout-dialog/calendar-workout-dialog.component';

@Component({
  selector: 'copy-calendar-event-dialog',
  templateUrl: 'copy-calendar-event-dialog.component.html',
  styleUrls: ['copy-calendar-event-dialog.component.scss'],
})
export class CopyCalendarEventDialogComponent implements OnInit {
  initialized: boolean;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  focusedDate: Date = new Date();

  // this component can be used to add client or team events; based on Input parameters provided

  @Input()
    event: CalendarEventViewModel;

  @Input()
    calendarType: CalendarType;

  @Input()
    clientUserId: number;

  @Input()
    teamId: number;

  constructor(public activeModal: NgbActiveModal, private clientsService: ClientsService, private teamsService: TeamService, private toastr: ToasterService,
    private fb: FormBuilder, private auth: AuthenticationService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.focusedDate = new Date(this.event.workoutDate);
    this.formGroup = this.fb.group({
      workoutDate: [null, Validators.required],
    });
    this.initialized = true;
  }

  onCopy() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    if (this.calendarType == CalendarType.Client) {
      this.copyClientEvent();
    } else {
      this.copyTeamEvent();
    }
  }

  onCopyAndRepeat() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    let calendarEvent = new CalendarWorkoutEvent(null, this.event.workout? this.event.workout.id : null, new Date(this.formGroup.get('workoutDate').value), 1, this.event.eventName, false, this.event.coachNotes,
      this.event.taskDescription, this.event.eventType, null, null, null, this.event.quickWorkoutName, this.event.quickWorkoutDescription, this.event.quickWorkoutActivityType, this.event.quickWorkoutDuration, this.event.quickWorkoutDistance);

    const modalRef = this.modalService.open(CalendarWorkoutDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
    modalRef.componentInstance.isCoachView = true;
    modalRef.componentInstance.calendarEvent = calendarEvent;
    modalRef.componentInstance.workoutIdToModify = null;

    if (this.calendarType == CalendarType.Client) {
      modalRef.componentInstance.calendarType = CalendarType.Client;
      modalRef.componentInstance.clientUserId = this.clientUserId;
    } else {
      modalRef.componentInstance.calendarType = CalendarType.Team;
      modalRef.componentInstance.teamId = this.teamId;
    }

    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.activeModal.dismiss('saved');
        }
        else {
          this.activeModal.dismiss('cancel');
        }
      }
    );
  }

  onCopyAndModify() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      let calendarEvent: CalendarWorkoutEvent;
      if (this.event.eventType == ClientDayEventType.QuickWorkout) {
        calendarEvent = new CalendarWorkoutEvent(null, null, new Date(this.formGroup.get('workoutDate').value), 1, null, false, null, null, ClientDayEventType.QuickWorkout, null, null, null,
          this.event.quickWorkoutName, this.event.quickWorkoutDescription, this.event.quickWorkoutActivityType, this.event.quickWorkoutDuration, this.event.quickWorkoutDistance);
      } else {
        calendarEvent = new CalendarWorkoutEvent(null, null, new Date(this.formGroup.get('workoutDate').value), 1, null, false, null, null, ClientDayEventType.Workout, null, null, null,
          null, null, null, null, null);
      }
      const modalRef = this.modalService.open(CalendarWorkoutDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
      modalRef.componentInstance.isCoachView = true;
      modalRef.componentInstance.calendarEvent = calendarEvent;
      modalRef.componentInstance.workoutIdToModify = this.event.workout ? this.event.workout.id : null;

      if (this.calendarType == CalendarType.Client) {
        modalRef.componentInstance.calendarType = CalendarType.Client;
        modalRef.componentInstance.clientUserId = this.clientUserId;
      } else {
        modalRef.componentInstance.calendarType = CalendarType.Team;
        modalRef.componentInstance.teamId = this.teamId;
      }

      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.activeModal.dismiss('saved');
          }
          else {
            this.activeModal.dismiss('cancel');
          }
        }
      );
    });
  }

  copyClientEvent() {
    const formData: ClientProgramWorkoutDayViewModel = Object.assign({}, this.formGroup.getRawValue());
    formData.workoutDate = dateAsUTCNoTime(formData.workoutDate);
    formData.workoutId = this.event.workout?.id;
    formData.clientUserId = this.clientUserId;
    formData.coachNotes = this.event.coachNotes;
    formData.assignedByUserId = this.auth.user.id;
    formData.eventType = this.event.eventType;
    formData.isCompleted = false;
    formData.sortOrder = 1;
    formData.activityTypeDurations = [];
    formData.quickWorkoutName = this.event.quickWorkoutName;
    formData.quickWorkoutActivityType = this.event.quickWorkoutActivityType;
    formData.quickWorkoutDescription = this.event.quickWorkoutDescription;
    formData.quickWorkoutDuration = this.event.quickWorkoutDuration;
    formData.quickWorkoutDistance = this.event.quickWorkoutDistance;

    this.clientsService.addClientProgramWorkoutDay(this.clientUserId, formData).subscribe((result) => {
      this.toastr.success('Workout Copied', 'Success');
      this.activeModal.dismiss('saved');
    });
  }

  copyTeamEvent() {
    const formData: TeamProgramWorkoutDayViewModel = Object.assign({}, this.formGroup.getRawValue());
    formData.workoutDate = dateAsUTCNoTime(formData.workoutDate);
    formData.workoutId = this.event.workout?.id;
    formData.teamId = this.teamId;
    formData.coachNotes = this.event.coachNotes;
    formData.assignedByUserId = this.auth.user.id;
    formData.eventType = this.event.eventType;
    formData.sortOrder = 1;
    formData.quickWorkoutName = this.event.quickWorkoutName;
    formData.quickWorkoutActivityType = this.event.quickWorkoutActivityType;
    formData.quickWorkoutDescription = this.event.quickWorkoutDescription;
    formData.quickWorkoutDuration = this.event.quickWorkoutDuration;
    formData.quickWorkoutDistance = this.event.quickWorkoutDistance;

    this.teamsService.addTeamProgramWorkoutDay(this.teamId, formData).subscribe((result) => {
      this.toastr.success('Workout Copied', 'Success');
      this.activeModal.dismiss('saved');
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { CalendarWorkoutEvent, CheckboxOption } from 'src/app/_models/models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientDayEventType, ClientProgramWorkoutDayActivityTypeDurationViewModel, ClientProgramWorkoutDayEditViewModel, ClientProgramWorkoutDayViewModel, RecurringEventViewModelRead, DayOfWeekFlag, RecurringEventFrequency, WorkoutViewModelRead, CalendarType, TeamProgramWorkoutDayEditViewModel, TeamProgramWorkoutDayViewModel, WorkoutSummaryViewModelRead, MeasurementSystem } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { WorkoutAddModalComponent } from '../workout-add-modal/workout-add-modal.component';
import { dateAsUTCNoTime, DayOfWeekName, FromMilesToLocalMeasurementPipe, MilesKilometersToMilesPipe } from 'src/app/_helpers/extensions.module';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';
import { ClientsService, TeamService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'calendar-workout-dialog',
  templateUrl: 'calendar-workout-dialog.component.html',
  styleUrls: ['calendar-workout-dialog.component.scss']
})
export class CalendarWorkoutDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  eventTypeEnum = Enums.ClientDayEventTypeEnum;
  eventType = ClientDayEventType;
  dayOfWeekEnum = Enums.DayOfWeekFlagEnum;
  dayOfWeekFlag = DayOfWeekFlag;
  daysOfWeek: CheckboxOption[] = [];
  repeatFrequency = RecurringEventFrequency;
  showDaysOfWeekError: boolean = false;
  currentRecurringEvent: RecurringEventViewModelRead;
  maxEndDate: Date = new Date().addDays(365);
  activityTypeEnum = Enums.ActivityTypeEnum;
  isLimitedEditing: boolean;
  disableEditing: boolean;
  disableEditingError: string;
  calendarTypeValues = CalendarType;
  newWorkoutId: number = null;
  isMetricSystem: boolean = false;
  localDistanceName: string;

  // this component can be used to add client or team events; based on Input parameters provided

  @Input()
    isCoachView: boolean;

  @Input()
    isSelf: boolean;

  @Input()
    calendarType: CalendarType;

  @Input()
    clientUserId: number;

  @Input()
    teamId: number;

  @Input()
    calendarEvent: CalendarWorkoutEvent;

  // this is an optional parameter that is only used if this dialog should immediately open the Create Workout modal with workout details populated
  @Input()
    workoutIdToModify: number;



  constructor(private fb: FormBuilder, private toastr: ToasterService, public activeModal: NgbActiveModal, private modalService: NgbModal,
    private clientsService: ClientsService, private auth: AuthenticationService, private teamsService: TeamService,
    private fromMilesToLocalMeasurementPipe: FromMilesToLocalMeasurementPipe, private milesKilometersToMilesPipe: MilesKilometersToMilesPipe) { }

  ngOnInit(): void {
    this.isMetricSystem = this.auth.getMeasurementSystem() == MeasurementSystem.Metric;
    this.localDistanceName = this.isMetricSystem ? 'Kilometers' : 'Miles';
    this.dayOfWeekEnum.forEach((value, key) => {
      this.daysOfWeek.push(new CheckboxOption(value, value.substr(0, 1), key, false));
    });

    this.setupForm();

    if (this.workoutIdToModify) {
      this.onCreateWorkout();
    }
  }

  setupForm() {
    this.currentRecurringEvent = this.calendarEvent && this.calendarEvent.recurringEvent ? this.calendarEvent.recurringEvent : null;
    if (this.currentRecurringEvent && this.currentRecurringEvent.byDay) {
      this.daysOfWeek.forEach(day => {
        if (this.currentRecurringEvent.byDay & this.dayOfWeekFlag[day.name]) {
          day.selected = true;
        }
      });
    } else if (!this.calendarEvent.eventId && this.calendarEvent.workoutDate) {
      // if this is a new event, go ahead and set the day of the week based on current date selected
      this.daysOfWeek.find(x => x.name == DayOfWeekName[new Date(this.calendarEvent.workoutDate).getDay()]).selected = true;
    }

    // check event to see what changes are allowed
    let allowedLimitedEditingTypes = [this.eventType.Workout, this.eventType.RestDay, this.eventType.Task, this.eventType.QuickWorkout];
    this.isLimitedEditing = this.calendarEvent && this.calendarEvent.recurringEvent && allowedLimitedEditingTypes.includes(this.calendarEvent.eventType);

    if (this.calendarEvent && this.calendarEvent.isCompleted && this.calendarEvent.eventType != ClientDayEventType.ManualWorkout) {
      this.disableEditing = true;
      this.disableEditingError = 'Cannot edit a workout/task that is already completed';
    } else if (this.calendarEvent && this.calendarEvent.recurringEvent && !allowedLimitedEditingTypes.includes(this.calendarEvent.eventType)) {
      this.disableEditing = true;
      this.disableEditingError = 'Cannot edit an item that is part of a series';
    }

    const manualWorkoutDurations = this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.ManualWorkout && this.calendarEvent.activityTypeDurations ? this.calendarEvent.activityTypeDurations[0] : null;
    let quickWorkoutDistance = this.calendarEvent ? this.calendarEvent.quickWorkoutDistance : null;
      if (this.isMetricSystem) {
        if (manualWorkoutDurations) {
          manualWorkoutDurations.distance = manualWorkoutDurations.distance ? this.fromMilesToLocalMeasurementPipe.transform(manualWorkoutDurations.distance) : null;
        }
        quickWorkoutDistance = quickWorkoutDistance ? this.fromMilesToLocalMeasurementPipe.transform(quickWorkoutDistance) : null;
      }

    this.formGroup = this.fb.group({
      eventId: [this.calendarEvent ? this.calendarEvent.eventId : null],
      eventName: [this.calendarEvent ? this.calendarEvent.eventName : null, ((this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.Event) ? Validators.required : null)],
      workoutId: [{value: this.calendarEvent ? this.calendarEvent.workoutId : null, disabled: this.isLimitedEditing}, ((this.calendarEvent && this.calendarEvent.eventType != ClientDayEventType.Workout) ? null : Validators.required)],
      workoutDate: [this.calendarEvent ? new Date(this.calendarEvent.workoutDate) : null, Validators.required],
      sortOrder: [this.calendarEvent ? this.calendarEvent.sortOrder : 1, Validators.required],
      coachNotes: [this.calendarEvent.coachNotes],
      taskDescription: [{value: this.calendarEvent.taskDescription, disabled: this.isLimitedEditing}],
      eventType: [{value: this.calendarEvent ? this.calendarEvent.eventType : ClientDayEventType.QuickWorkout, disabled: this.isLimitedEditing}],
      repeatFrequency: [this.currentRecurringEvent ? this.currentRecurringEvent.frequency : this.repeatFrequency.Never, Validators.required],
      repeatInterval: [this.currentRecurringEvent ? this.currentRecurringEvent.interval : 1],
      repeatEndType: [this.currentRecurringEvent ? (this.currentRecurringEvent.count ? 'after' : 'on') : null],
      repeatUntilDate: [this.currentRecurringEvent ? this.currentRecurringEvent.untilDate : null],
      repeatCount: [this.currentRecurringEvent ? this.currentRecurringEvent.count : null],
      activityType: [manualWorkoutDurations ? manualWorkoutDurations.activityType : null, ((this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.ManualWorkout) ? Validators.required : null)],
      duration: [manualWorkoutDurations ? manualWorkoutDurations.duration : null, ((this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.ManualWorkout) ? Validators.required : null)],
      distance: [manualWorkoutDurations ? manualWorkoutDurations.distance : null],
      ratePerceivedExertion: [manualWorkoutDurations ? this.calendarEvent.ratePerceivedExertion : null, ((this.calendarEvent && (this.calendarEvent.eventType == ClientDayEventType.ManualWorkout || (this.calendarEvent.eventType == ClientDayEventType.QuickWorkout && this.calendarEvent.isCompleted))) ? Validators.required : null)],
      quickWorkoutName: [{value: this.calendarEvent ? this.calendarEvent.quickWorkoutName : null, disabled: this.isLimitedEditing}, ((this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.QuickWorkout) ? Validators.required : null)],
      quickWorkoutDescription: [{value: this.calendarEvent ? this.calendarEvent.quickWorkoutDescription : null, disabled: this.isLimitedEditing}],
      quickWorkoutActivityType: [{value: this.calendarEvent ? this.calendarEvent.quickWorkoutActivityType : null, disabled: this.isLimitedEditing}, ((this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.QuickWorkout) ? Validators.required : null)],
      quickWorkoutDuration: [{value: this.calendarEvent ? this.calendarEvent.quickWorkoutDuration : null, disabled: this.isLimitedEditing}, ((this.calendarEvent && this.calendarEvent.eventType == ClientDayEventType.QuickWorkout) ? Validators.required : null)],
      quickWorkoutDistance: [{value: quickWorkoutDistance, disabled: this.isLimitedEditing}]
    });

    this.initialized = true;
  }

  onEventTypeChange(event: any) {
    if (this.formGroup.get('eventType').value != this.eventType.Workout) {
      this.formGroup.get('workoutId').setValue(null);
      this.formGroup.get('workoutId').setValidators([]);
    } else {
      this.formGroup.get('workoutId').setValidators([Validators.required]);
    }

    if (this.formGroup.get('eventType').value == this.eventType.Event) {
      this.formGroup.get('eventName').setValidators([Validators.required]);
      // don't allow recurring events for Events type
      this.formGroup.get('repeatFrequency').setValue(this.repeatFrequency.Never);
    } else {
      this.formGroup.get('eventName').setValue(null);
      this.formGroup.get('eventName').setValidators([]);
    }

    if (this.formGroup.get('eventType').value == this.eventType.Task) {
      this.formGroup.get('taskDescription').setValidators([Validators.required]);
    } else {
      this.formGroup.get('taskDescription').setValue(null);
      this.formGroup.get('taskDescription').setValidators([]);
    }

    if (this.formGroup.get('eventType').value == this.eventType.ManualWorkout) {
      this.formGroup.get('activityType').setValidators([Validators.required]);
      this.formGroup.get('duration').setValidators([Validators.required]);
      this.formGroup.get('ratePerceivedExertion').setValidators([Validators.required]);
    } else {
      this.formGroup.get('activityType').setValue(null);
      this.formGroup.get('activityType').setValidators([]);
      this.formGroup.get('duration').setValue(null);
      this.formGroup.get('duration').setValidators([]);
      this.formGroup.get('ratePerceivedExertion').setValue(null);
      this.formGroup.get('ratePerceivedExertion').setValidators([]);
    }

    if (this.formGroup.get('eventType').value == this.eventType.QuickWorkout) {
      this.formGroup.get('quickWorkoutActivityType').setValidators([Validators.required]);
      this.formGroup.get('quickWorkoutDuration').setValidators([Validators.required]);
      this.formGroup.get('quickWorkoutName').setValidators([Validators.required]);
    } else {
      this.formGroup.get('quickWorkoutActivityType').setValue(null);
      this.formGroup.get('quickWorkoutActivityType').setValidators([]);
      this.formGroup.get('quickWorkoutDuration').setValue(null);
      this.formGroup.get('quickWorkoutDuration').setValidators([]);
      this.formGroup.get('quickWorkoutName').setValue(null);
      this.formGroup.get('quickWorkoutName').setValidators([]);

      this.formGroup.get('quickWorkoutDescription').setValue(null);
      this.formGroup.get('quickWorkoutDistance').setValue(null);
    }

    this.formGroup.get('workoutId').updateValueAndValidity();
    this.formGroup.get('eventName').updateValueAndValidity();
    this.formGroup.get('taskDescription').updateValueAndValidity();
    this.formGroup.get('activityType').updateValueAndValidity();
    this.formGroup.get('duration').updateValueAndValidity();
    this.formGroup.get('ratePerceivedExertion').updateValueAndValidity();
    this.formGroup.get('quickWorkoutActivityType').updateValueAndValidity();
    this.formGroup.get('quickWorkoutDuration').updateValueAndValidity();
    this.formGroup.get('quickWorkoutName').updateValueAndValidity();
  }

  onFrequencyChange(event: any) {
    if (event == this.repeatFrequency.Never) {
      this.formGroup.get('repeatInterval').setValidators([]);
      this.formGroup.get('repeatEndType').setValidators([]);
      this.formGroup.get('repeatUntilDate').setValidators([]);
      this.formGroup.get('repeatCount').setValidators([]);
    } else {
      this.formGroup.get('repeatInterval').setValidators([Validators.required]);
      this.formGroup.get('repeatEndType').setValidators([Validators.required]);
    }

    this.formGroup.get('repeatInterval').updateValueAndValidity();
    this.formGroup.get('repeatEndType').updateValueAndValidity();
    this.formGroup.get('repeatUntilDate').updateValueAndValidity();
    this.formGroup.get('repeatCount').updateValueAndValidity();
  }

  onEndTypeChange(event: any) {
    this.repeatEndTypeChange(event.target.value);
  }

  repeatEndTypeChange(value: string) {
    if (value == 'on') {
      this.formGroup.get('repeatCount').setValidators([]);
      this.formGroup.get('repeatUntilDate').setValidators([Validators.required, ValidatorHelper.validateMaxDate(this.maxEndDate)]);
    } else {
      this.formGroup.get('repeatUntilDate').setValidators([]);
      this.formGroup.get('repeatCount').setValidators([Validators.required]);
    }

    this.formGroup.get('repeatUntilDate').updateValueAndValidity();
    this.formGroup.get('repeatCount').updateValueAndValidity();
  }

  onOccurrenceChange(value: any) {
    if (value) {
      const endType = 'after';
      this.formGroup.get('repeatEndType').setValue(endType);
      this.repeatEndTypeChange(endType);
    }
  }

  onEndDateChange(value: any) {
    if (value) {
      const endType = 'on';
      this.formGroup.get('repeatEndType').setValue(endType);
      this.repeatEndTypeChange(endType);
    }
  }

  onDayOfWeekChange(event: any) {
    this.setDayOfWeekError(event);
  }

  // have to pass parameter in case form field just changed
  setDayOfWeekError(nowHasValue: boolean): boolean {
    if (this.formGroup.get('repeatFrequency').value == this.repeatFrequency.Weekly && !this.daysOfWeek.some(x => x.selected) && !nowHasValue) {
      this.showDaysOfWeekError = true;
      return true;
    } else {
      this.showDaysOfWeekError = false;
      return false;
    }
  }

  onCreateWorkout() {
    this.newWorkoutId = null;
    const modalRef = this.modalService.open(WorkoutAddModalComponent, { size: 'lg', windowClass: 'modal-xl-custom', backdrop: 'static' });
    modalRef.componentInstance.workoutIdToModify = this.workoutIdToModify;
    modalRef.componentInstance.workoutCopyDate = this.workoutIdToModify ? new Date(this.calendarEvent.workoutDate) : null;
    modalRef.componentInstance.savedObject.subscribe((workout: WorkoutViewModelRead) => {

      this.newWorkoutId = workout.id; // refreshes the workout list
      this.formGroup.get('workoutId').setValue(workout.id);
    });
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onWorkoutChange(changedWorkout: WorkoutSummaryViewModelRead) {
    this.newWorkoutId = null;
  }

  onChangeToWorkoutType() {
    this.formGroup.get('eventType').setValue(this.eventType.Workout);
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    if (this.setDayOfWeekError(false)) {
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      if (this.calendarType == CalendarType.Client) {
        this.saveClientEvent();
      } else {
        this.saveTeamEvent();
      }
    });
  }

  saveClientEvent() {
    if (this.isLimitedEditing) {
      const model: ClientProgramWorkoutDayEditViewModel = {
        id: this.calendarEvent.eventId,
        coachNotes: this.formGroup.get('coachNotes').value,
        sortOrder: this.formGroup.get('sortOrder').value,
        workoutDate: dateAsUTCNoTime(this.formGroup.get('workoutDate').value)
      };

      this.clientsService.updateClientProgramWorkoutDayOccurrence(this.calendarEvent.eventId, model).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
      return;
    }

    const formData: ClientProgramWorkoutDayViewModel = Object.assign({}, this.formGroup.getRawValue());
    formData.workoutId = formData.eventType != ClientDayEventType.Workout ? null : formData.workoutId;
    formData.assignedByUserId = this.auth.user.id;
    formData.recurringEvent = this.getRecurringEventInfo(formData.workoutDate);
    formData.workoutDate = dateAsUTCNoTime(formData.workoutDate);

    // handle manual workout
    if (this.formGroup.get('eventType').value == ClientDayEventType.ManualWorkout) {
      formData.isCompleted = true;
      formData.completedDate = dateAsUTCNoTime(formData.workoutDate);
      let distance = this.formGroup.get('distance').value;
      if (this.isMetricSystem) {
        distance = distance ? this.milesKilometersToMilesPipe.transform(0, distance) : null;
      }
      let activityTypeDuration: ClientProgramWorkoutDayActivityTypeDurationViewModel = {
        clientProgramWorkoutDayId: this.calendarEvent ? this.calendarEvent.eventId ?? 0 : 0,
        activityType: this.formGroup.get('activityType').value,
        duration: this.formGroup.get('duration').value,
        distance: distance
      }
      formData.activityTypeDurations = [activityTypeDuration];

    } else {
      formData.activityTypeDurations = [];
    }

    if (this.isMetricSystem && formData.eventType == ClientDayEventType.QuickWorkout) {
      formData.quickWorkoutDistance = formData.quickWorkoutDistance ? this.milesKilometersToMilesPipe.transform(0, formData.quickWorkoutDistance) : null;
    }

    if (this.calendarEvent?.eventId) {
      this.clientsService.updateClientProgramWorkoutDay(this.clientUserId, this.calendarEvent.eventId, formData).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    } else {
      this.clientsService.addClientProgramWorkoutDay(this.clientUserId, formData).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    }
  }

  saveTeamEvent() {
    if (this.isLimitedEditing) {
      const model: TeamProgramWorkoutDayEditViewModel = {
        id: this.calendarEvent.eventId,
        coachNotes: this.formGroup.get('coachNotes').value,
        sortOrder: this.formGroup.get('sortOrder').value,
        workoutDate: dateAsUTCNoTime(this.formGroup.get('workoutDate').value)
      };

      this.teamsService.updateTeamProgramWorkoutDayOccurrence(this.calendarEvent.eventId, model).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
      return;
    }

    const formData: TeamProgramWorkoutDayViewModel = Object.assign({}, this.formGroup.getRawValue());
    formData.workoutId = formData.eventType != ClientDayEventType.Workout ? null : formData.workoutId;
    formData.assignedByUserId = this.auth.user.id;
    formData.recurringEvent = this.getRecurringEventInfo(formData.workoutDate);
    formData.workoutDate = dateAsUTCNoTime(formData.workoutDate);

    if (this.calendarEvent?.eventId) {
      this.teamsService.updateTeamProgramWorkoutDay(this.teamId, this.calendarEvent.eventId, formData).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    } else {
      this.teamsService.addTeamProgramWorkoutDay(this.teamId, formData).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    }
  }

  getRecurringEventInfo(workoutDate: Date): any {
    // handle recurring events
    if (this.formGroup.get('repeatFrequency').value == this.repeatFrequency.Never) {
      return null;
    } else {
      let recurringEvent: RecurringEventViewModelRead = {
        id: this.currentRecurringEvent ? this.currentRecurringEvent.id : 0,
        startDate: workoutDate,
        frequency: this.formGroup.get('repeatFrequency').value,
        interval: this.formGroup.get('repeatInterval').value,
        untilDate: this.formGroup.get('repeatEndType').value == 'on' ? dateAsUTCNoTime(this.formGroup.get('repeatUntilDate').value) : null,
        count: this.formGroup.get('repeatEndType').value == 'on' ? null : this.formGroup.get('repeatCount').value
      }

      // handle days of week mapping
      if (this.formGroup.get('repeatFrequency').value == this.repeatFrequency.Weekly) {
        let selectedDays = this.daysOfWeek.filter(x => x.selected);
        selectedDays.forEach(day => {
          recurringEvent.byDay |= this.dayOfWeekFlag[day.name];
        });
      }

      return recurringEvent;
    }
  }

}

<div kendoDialogContainer></div>
<alert *ngIf="settingsProvider.configuration.environmentName == 'Staging' || settingsProvider.configuration.environmentName == 'Alpha'" class="text-center" type="danger" [dismissible]="true" (onClosed)="onVersionAlertClosed(alert)">
  <strong>Warning: You are in the {{settingsProvider.configuration.environmentName}} environment</strong>
</alert>
<alert *ngIf="showVersionAlert" class="text-center" type="danger" [dismissible]="true" (onClosed)="onVersionAlertClosed(alert)">
  <strong>We've updated our website, and the page you are on might not work as expected. Please refresh this page to avoid errors.</strong><button (click)="onUpdateVersion()" class="btn btn-primary ml-2">Refresh</button>
</alert>
<alert *ngIf="showAthleteInactiveAlert" class="text-center" type="danger" [dismissible]="false">
  Your athlete account is inactive. You can still access your Assessments. To re-activate your account, reach out to your coach.
</alert>
<div *ngIf="auth.user && brand && brandBannerActive" class="row">
  <div class="col">
    <div class="alert alert-dismissable alert-6am mb-0" style="border-radius: 0" role="alert">
      <div class="row">
        <div class="col-12 text-center">
        <div class="d-inline">
          <a *ngIf="brand.bannerUrl" href="{{brand.bannerUrl}}" target="_blank'" class="text-6am">{{ brand.bannerText }}</a>
          <span *ngIf="!brand.bannerUrl" class="text-6am">{{ brand.bannerText }}</span>
        </div>
        <button type="button" class="close float-right" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        
      </div>
      
    </div>
  </div>
</div>
<div *ngIf="auth && auth.impersonated" class="row">
  <div class="col">
    <div class="alert alert-danger mb-0" style="border-radius: 0" role="alert">
      <div class="container">
        <div class="row h-100">
          <div class="col-md-9 my-auto">
            <h6 class="mb-0">You are currently impersonating this user. Click here to revert.</h6>
          </div>
          <div class="col-md-3 text-right">
            <button type="button" [routerLink]="[]" (click)="auth.unimpersonateUser()" class="btn btn-primary btn-sm">Revert</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

import { Component, OnInit } from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { MovementCategoryProgramViewModelRead, ImpairmentCategoryProgramViewModelRead, MovementCategoryProgramViewModel, ImpairmentCategoryProgramViewModel } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-superuser-program',
  templateUrl: 'superuser-program.component.html',
})
export class SuperUserProgramComponent implements OnInit {
  initialized = false;
  movementCategories = Enums.MovementCategoryEnum;
  impairmentCategories = Enums.ImpairmentCategoryEnum;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  movementCategoryPrograms: MovementCategoryProgramViewModelRead[];
  impairmentCategoryPrograms: ImpairmentCategoryProgramViewModelRead[];

  constructor(private fb: FormBuilder, private programData: ProgramsService, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Categories - Programs', 'programs', []);

    forkJoin([this.programData.getMovementCategoryPrograms(), this.programData.getImpairmentCategoryPrograms()]).subscribe(results => {
      this.movementCategoryPrograms = results[0];
      this.impairmentCategoryPrograms = results[1];
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      movementCategories: this.fb.array([]),
      impairmentCategories: this.fb.array([])
    });

    const movementCategories = this.formGroup.get('movementCategories') as FormArray;
    this.movementCategories.forEach((value, key) => {
      const programId = this.movementCategoryPrograms.find(x => x.movementCategory === key) ? this.movementCategoryPrograms.find(x => x.movementCategory === key).programId : null;
      movementCategories.push(this.fb.group({
        movementCategory: [key],
        name: [value],
        programId: [programId]
      }));
    });

    const impairmentCategories = this.formGroup.get('impairmentCategories') as FormArray;
    this.impairmentCategories.forEach((value, key) => {
      const programId = this.impairmentCategoryPrograms.find(x => x.impairmentCategory === key) ? this.impairmentCategoryPrograms.find(x => x.impairmentCategory === key).programId : null;
      impairmentCategories.push(this.fb.group({
        impairmentCategory: [key],
        name: [value],
        programId: [programId]
      }));
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const movementFormData: MovementCategoryProgramViewModel[] = this.formGroup.get('movementCategories').value;
      const impairmentFormData: ImpairmentCategoryProgramViewModel[] = this.formGroup.get('impairmentCategories').value;

      forkJoin([this.programData.addUpdateMovementCategoryPrograms(movementFormData), this.programData.addUpdateImpairmentCategoryPrograms(impairmentFormData)]).subscribe((results) => {
        this.toastr.success('Programs Saved', 'Success');
        resetButton();
      }, error => { this.toastr.error('There was an error during saving', 'Error')});
    });
  }


}

<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Change Password</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <form (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
    <div class="card-body">
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label"> Current Password </label>

          <input class="form-control" formControlName="currentPassword" type="password" />
          <div class="invalid-message">
            <div>Password is required.</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">

      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label"> New Password </label>

          <input class="form-control" formControlName="newPassword" type="password" />
          <div class="invalid-message">
            <div>Please enter a valid password.</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label"> Confirm New Password </label>

          <input class="form-control" formControlName="confirmNewPassword" type="password" />
          <div *ngIf="formGroup.errors?.notSame" class="invalid-message-visible">
            <div>Passwords do not match.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
        </div>
        <div class="col text-right">
          <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Update Password</button>
        </div>
      </div>
    </div>
  </form>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TeamAddViewModel, TeamDetailsViewModelRead, TeamEditViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { TeamService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'manage-team-dialog',
  templateUrl: 'manage-team-dialog.component.html',
  styleUrls: ['manage-team-dialog.component.scss']
})
export class ManageTeamDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  team: TeamDetailsViewModelRead;
  currentUserId: number;

  @Input()
    teamId: number;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private teamsService: TeamService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    if (this.teamId) {
      this.teamsService.getTeamDetailsById(this.teamId).subscribe(results => {
        this.team = results;
        this.setupForm();
      });
    } else {
      this.auth.fetchUserProfile().subscribe(user => {
        this.currentUserId = user.id;
        this.setupForm();
      });

    }
  }

  setupForm() {
    this.formGroup = this.fb.group({
      id: [this.team ? this.team.id : null],
      name: [this.team ? this.team.name : null, { validators: [Validators.required], asyncValidators: [this.validateNameNotTaken.bind(this)], updateOn: 'change' }],
      coachIds: [this.team ? this.team.coaches.map(x => x.id) : [this.currentUserId]],
      athleteIds: [this.team ? this.team.athletes.map(x => x.id) : []],
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      if (this.teamId) {
        const formData: TeamEditViewModel = Object.assign({}, this.formGroup.getRawValue());
        this.teamsService.updateTeam(this.teamId, formData).subscribe(result => {
          this.activeModal.dismiss('saved');
        });
      } else {
        const formData: TeamAddViewModel = Object.assign({}, this.formGroup.getRawValue());
        this.teamsService.addTeam(formData).subscribe(result => {
          this.activeModal.dismiss('saved');
        });
      }
    });
  }

  validateNameNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.teamsService.isTeamNameDuplicate(control.value, this.teamId || 0)),
      map((res)  => {
        return res ? { nameTaken: true } : null;
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { SuperuserStatsViewModel } from 'src/app/_models/generatedModels';
import { SuperUserService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ExportService } from 'src/app/_services/export.service';
import { formatShortDate } from 'src/app/_helpers/extensions.module';
import { saveAs } from 'file-saver';

@Component({
  selector: 'bxl-superuser-stats',
  templateUrl: 'stats.component.html',
  styleUrls: ['stats.component.scss']
})
export class SuperuserStatsComponent implements OnInit {

  initialized = false;
  stats: SuperuserStatsViewModel;
  submitComplete: Promise<{}> | undefined;

  constructor(private superuserService: SuperUserService, private breadcrumbs: BreadcrumbsService, private exportService: ExportService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Stats', 'stats', []);

    this.superuserService.getSuperuserStats().subscribe(result => {
      this.stats = result;
      this.initialized = true;
    });
  }

  onExportMonthlyData() {
    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.exportService.ExportMonthlyReporting().subscribe(file => {
        let fileName = `PortalMonthlyReporting_${formatShortDate(new Date())}.xlsx`;
        saveAs(file, fileName);
        resetButton();
      });
    });
    
  }

}

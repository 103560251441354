import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Enums } from 'src/app/_models/generatedEnums';
import { AddUserViewModel, ClientStatus, StringViewModel, TeamUserRole, TeamUserViewModel, UpdateUserViewModel, UserLookupInputViewModel, UserLookupViewModel, UserProfileViewModel, UserRole } from 'src/app/_models/generatedModels';
import { OrganizationService, UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { coachUserRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MaxAthleteSubscriptionService } from 'src/app/_services/maxAthleteSubscription.service';

@Component({
  selector: 'bxl-admin-user-add-edit-modal',
  templateUrl: 'admin-user-add-edit-modal.component.html',
})
export class AdminUserAddEditModalComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  userRoleEnum = Enums.UserRoleEnum;
  userRole = UserRole;
  isAdd: boolean;
  selectedUser: UserProfileViewModel;
  isExistingAthlete: boolean = false;
  emailFoundOnlyInOtherOrg: boolean = false;

  // only use this field if working with Org other than current context org
  @Input()
  selectedOrganizationId: number;

  @Input()
  user: UserProfileViewModel;


  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private toastr: ToasterService, private usersService: UserService, private auth: AuthenticationService,
    private maxAthleteService: MaxAthleteSubscriptionService, private organizationService: OrganizationService) {}

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm(): any {
    if (this.user) {
      this.selectedUser = this.user;
      this.formGroup = this.fb.group({
        userRole: [(this.user.userRole & ~this.userRole.Runner), [Validators.required], [this.validateAdminRole()]],
        isAthlete: [(this.user.userRole & this.userRole.Runner) == this.userRole.Runner],
        teamIds: [this.user.teams ? this.user.teams.filter(x => x.teamUserRole & TeamUserRole.Coach).map(x => x.teamId) : []],
      });

      this.isAdd = false;
    } else {
      this.formGroup = this.fb.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        email: [null, [Validators.required, Validators.email], [this.validateEmail()]],
        userRole: [null, Validators.required],
        isAthlete: [true],
        teamIds: [[]],
        isOfAge: [false, [Validators.requiredTrue]]
      });

      this.isAdd = true;
    }


    this.initialized = true;
  }

  // we are allowing an existing org email address to be used if it's for an athlete-only account who is now being added as a coach
  validateEmail(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const model: UserLookupInputViewModel = {
        email: control.value,
        specifiedOrganizationId: this.selectedOrganizationId
      };
      return timer(500).pipe(
        switchMap(() => this.usersService.lookupByEmail(model)),
        map((result: UserLookupViewModel) => {
          this.isExistingAthlete = result.foundInOrg && (result.userProfile.userRole & this.userRole.Runner) == this.userRole.Runner;
          this.selectedUser = this.isExistingAthlete ? result.userProfile : null;
          this.emailFoundOnlyInOtherOrg = result.found && !result.foundInOrg;
          return result.foundInOrg && (result.userProfile.userRole & coachUserRole) != 0 ? { emailInOrg: true } : null;
        })
      );
    };
  }

  // if changing someone from Admin to another role, make sure they aren't the only admin
  validateAdminRole(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.selectedUser && (this.selectedUser.userRole & this.userRole.Administrator) && control.value != this.userRole.Administrator) {
        return timer(0).pipe(
          switchMap(() => this.organizationService.getOrgUsers()),
          map((result: UserProfileViewModel[]) => {
            let totalActiveAdmins = result.filter(x => x.status == ClientStatus.Active && (x.userRole & UserRole.Administrator)).length;
            if (totalActiveAdmins == 1) {
              return { onlyAdmin: true }
            } else {
              return null;
            }
          })
        );
      } else {
        return of(null);
      }
      
    };
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    if (this.emailFoundOnlyInOtherOrg) {
      this.toastr.confirmDialog('Are you sure you want to invite this existing user to your organization? They will be listed under Invited until they accept.', 'Confirm Add User').subscribe(result => {
        if (result) {
          this.doSave();    
        }
      });
    } else {
      this.doSave();    
    }

    
  }

  doSave() {
    this.submitComplete = new Promise((resetButton:any, reject) => {
      var selectedUserId = this.isAdd && !this.isExistingAthlete ? 0 : this.selectedUser.id;
      if (this.formGroup.get('isAthlete').value) {
        this.formGroup.get('userRole').setValue(this.formGroup.get('userRole').value | this.userRole.Runner);
      } else {
        this.formGroup.get('userRole').setValue(this.formGroup.get('userRole').value &~ this.userRole.Runner);
      }

      if (this.isAdd && !this.isExistingAthlete) {
        const formData: AddUserViewModel = this.formGroup.value;
        formData.teams = (this.formGroup.get('teamIds').value as number[]).map((x) => {
          return { teamId: x, userId: selectedUserId, teamUserRole: TeamUserRole.Coach } as TeamUserViewModel;
        });

        if (this.selectedOrganizationId) {
          this.usersService.createNewUserForSpecifiedOrg(this.selectedOrganizationId, formData).subscribe(result => {
            this.toastr.success('User added', 'Success');
            this.activeModal.dismiss('saved');
          });
        } else {
          if (formData.userRole & UserRole.Runner) {
            this.maxAthleteService.isMaxActiveAthletes(false).subscribe(isMax => {
              if (!isMax) {
                this.createNewUser(formData);
              } else {
                resetButton();
              }
            });
          } else {
            this.createNewUser(formData);
          }
        }

      } else {
        this.update(selectedUserId, resetButton);
      }
    });
  }

  createNewUser(formData:any) {
    this.usersService.createNewUser(formData).subscribe((result) => {
      this.toastr.success('User added', 'Success');
      this.activeModal.dismiss('saved');
    });
  }


  update(selectedUserId: number, resetButton: () => any) {
    var updatedCoachTeamIds = this.formGroup.get('teamIds').value as number[];
        var updatedTeams: TeamUserViewModel[] = JSON.parse(
          JSON.stringify(
            Object.assign(
              [],
              this.selectedUser.teams.map((x) => x as TeamUserViewModel)
            )
          )
        );
        updatedTeams = updatedTeams.concat(
          updatedCoachTeamIds
            .filter((x) => this.selectedUser.teams.map((y) => y.teamId).indexOf(x) == -1)
            .map((x) => {
              return { teamId: x, userId: selectedUserId, teamUserRole: TeamUserRole.Coach } as TeamUserViewModel;
            })
        );
        updatedTeams.forEach((team) => {
          if (updatedCoachTeamIds.indexOf(team.teamId) != -1) {
            team.teamUserRole = team.teamUserRole | TeamUserRole.Coach;
          } else {
            team.teamUserRole = team.teamUserRole & ~TeamUserRole.Coach;
          }
        });
        updatedTeams.removeRange((x) => x.teamUserRole == TeamUserRole.None);

        const formData: UpdateUserViewModel = this.formGroup.value;
        formData.teams = updatedTeams;
        this.usersService.updateUser(this.selectedUser.id, formData).subscribe((result) => {
          this.toastr.success('User updated', 'Success');
          if (this.auth.user.id == this.selectedUser.id) {
            // to update their nav menu based on new role claims
            window.location.reload();
          }
          this.activeModal.dismiss('saved');
        });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized && acwrPercentage != null">
  <div class="">
    <div *ngIf="title" class="font-weight-bold text-center" style="font-size: 18px;">{{title}}</div>
    <donut-pie-chart [chartData]="chartData" [centerText]="((acwrPercentage * 100) | number: '1.0-0')  + '%'" [centerTextClass]="acwrPercentage | acuteChronicRatioColor"></donut-pie-chart>
  </div>
  <div *ngIf="!isTrainingLoadTargetIncomplete" class="warning-text {{acwrPercentage | acuteChronicRatioColor}}">
    {{acwrPercentage | acuteChronicRatioText}}
  </div>
  <div *ngIf="isTrainingLoadTargetIncomplete" class="text-danger warning-text">
    5 weeks of consistent workouts are needed for accurate Acute:Chronic values. Please select a training load that does not cause pain or excess fatigue.
  </div>
</div>

<div *ngIf="initialized && acwrPercentage == null">
  Not enough data to show
</div>

<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Select Date Range</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div  class="col-12">
              <div class="form-group">
                <div class="mb-2">
                  {{ headerText }}
                </div>
                <kendo-daterange class="d-block">
                  <label>
                    <span class="form-control-label d-block">Start</span>
                    <kendo-dateinput
                      kendoDateRangeStartInput
                      autoCorrectOn="blur"
                      formControlName="startDate"
                    ></kendo-dateinput>
                  </label>
                  <label>
                    <span class="form-control-label d-block">End</span>
                    <kendo-dateinput
                      kendoDateRangeEndInput
                      autoCorrectOn="blur"
                      formControlName="endDate"
                    ></kendo-dateinput>
                  </label>
                  <kendo-daterange-popup
                    [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
                    [popupAlign]="{ horizontal: 'left', vertical: 'top' }"></kendo-daterange-popup>
                </kendo-daterange>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


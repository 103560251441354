import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'bxl-my-profile',
  templateUrl: 'my-profile.component.html',
})
export class MyProfileComponent implements OnInit {
  initialized = false;

  constructor(public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.auth.fetchUserProfile().subscribe((result) => {
      this.initialized = true;
    });
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { dateWithoutTime, dynamicSort } from 'src/app/_helpers/extensions.module';
import { SurveyQuestionType, SurveyQuestionViewModelRead, SurveyResponseQuestionAnswerViewModel, SurveyResponseQuestionViewModel, SurveyResponseViewModel, SurveyViewModelRead } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SurveyService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'add-survey',
  templateUrl: 'add-survey.component.html',
  styleUrls: ['add-survey.component.scss']
})
export class AddSurveyComponent implements OnInit {
  initialized = false;
  submitComplete: Promise<{}> | undefined;
  survey: SurveyViewModelRead;
  surveyResponse: SurveyResponseViewModel = new SurveyResponseViewModel();
  surveyQuestionType = SurveyQuestionType;
  currentQuestion: SurveyQuestionViewModelRead;
  currentQuestionSortOrder: number;
  loadingNextQuestion: boolean = false;
  isStarted: boolean = false;
  isFinished: boolean = false;
  selectedAnswers: number[] = [];
  multipleAnswerError = '';

  showCalculatingText1: boolean;
  showCalculatingText2: boolean;
  showCalculatingText3: boolean;
  calculationsFinished: boolean

  @Input()
  surveyId: number;

  @Input()
  userId: number;

  @Input()
  surveyIntroText: string;

  @Output()
  surveySubmitted = new EventEmitter<SurveyResponseViewModel>();

  @Output()
  surveyCalculatingFinished = new EventEmitter<true>();

  constructor(private surveyService: SurveyService, private changeDetector: ChangeDetectorRef, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.surveyService.getSurveyById(this.surveyId).subscribe(results => {
      this.survey = results;
      this.initializeSurveyResponse();
      this.initialized = true;
    });
  }

  initializeSurveyResponse() {
    this.surveyResponse.userId = this.userId;
    this.surveyResponse.surveyId = this.surveyId;
    this.surveyResponse.surveyDate = dateWithoutTime(new Date());
    this.surveyResponse.surveyResponseQuestions = [];
  }

  onStart() {
    this.currentQuestion = this.survey.surveyQuestions.find(x => x.id == this.survey.startSurveyQuestionId);
    this.currentQuestionSortOrder = 1;
    this.isStarted = true;
  }

  onAnswerSelected(answerId: number) {
    //toggle answer being selected
    this.multipleAnswerError = '';
    if (this.selectedAnswers.findIndex(x => x == answerId) != -1) {
      this.selectedAnswers.remove(x => x == answerId);
    } else {
      this.selectedAnswers.push(answerId);
    }

    if (this.currentQuestion.surveyQuestionType == this.surveyQuestionType.OneAnswerOnly) {
      this.recordAnswer(this.selectedAnswers);
    }
  }

  onNextQuestion() {
    if (this.currentQuestion.surveyQuestionType == this.surveyQuestionType.MultipleAnswersRequired && this.selectedAnswers.length < 2) {
      this.multipleAnswerError = 'Select at least two answers before continuing.'
      return;
    }

    if (this.selectedAnswers.length < 1) {
      this.multipleAnswerError = 'Select at least one answer before continuing.'
      return;
    }

    this.recordAnswer(this.selectedAnswers);
  }

  recordAnswer(answerIds: number[]) {
    var responseAnswers = [];
    answerIds.forEach(answerId => {
      const responseAnswer: SurveyResponseQuestionAnswerViewModel = {
        surveyQuestionAnswerId: answerId
      };
      responseAnswers.push(responseAnswer);
    });

    const responseQuestion: SurveyResponseQuestionViewModel = {
      sortOrder: this.currentQuestionSortOrder,
      surveyQuestionId: this.currentQuestion.id,
      surveyResponseQuestionAnswers: responseAnswers
    }
    this.surveyResponse.surveyResponseQuestions.push(responseQuestion);

    // find the next question
    const surveyNextStep = this.getNextSurveyStep(answerIds);
    if (surveyNextStep.redirectURL) {
      window.location.href = surveyNextStep.redirectURL;
    } else {
      this.setNextQuestion(surveyNextStep.nextQuestionId);
    }

  }

  getNextSurveyStep(answerIds: number[]) : (SurveyNextStep) {
    if (this.currentQuestion.nextSurveyQuestionId) {
      return new SurveyNextStep(this.currentQuestion.nextSurveyQuestionId, null);
    }

    var nextStep: SurveyNextStep = new SurveyNextStep(null, null);
    const currentAnswers = this.currentQuestion.surveyQuestionAnswers.filter(x => answerIds.includes(x.id)).map(x => x.surveyWorkflows).reduce(function (a, b) { return a!.concat(b!); }).sort(dynamicSort('sortOrder'));
    var existingAnswers = this.surveyResponse.surveyResponseQuestions.map(x => x.surveyResponseQuestionAnswers).reduce(function (a, b) { return a!.concat(b!); }).map(x => x.surveyQuestionAnswerId);

    for (var i = 0; i < currentAnswers.length; i++) {
      const workflow = currentAnswers[i];
      var dependenciesFound = true;
      workflow.surveyWorkflowDependencies.forEach(dependency => {
        if (existingAnswers.indexOf(dependency.surveyQuestionAnswerId) == -1) {
          dependenciesFound = false;
        }
      });

      if (dependenciesFound) {
        nextStep.nextQuestionId = workflow.nextSurveyQuestionId;
        nextStep.redirectURL = workflow.redirectURL;
        break;
      }
    }

    return nextStep;
  }

  setNextQuestion(nextQuestionId: number) {
    // using this boolean to re-load video player each time; and manual change detection is also needed
    this.loadingNextQuestion = true;
    this.changeDetector.detectChanges();

    this.selectedAnswers = [];
    this.currentQuestionSortOrder += 1;
    if (!nextQuestionId) {
      this.currentQuestion = null;
    } else {
      this.currentQuestion = this.survey.surveyQuestions.find(x => x.id == nextQuestionId);
    }

    this.loadingNextQuestion = false;
  }

  onBack() {
    const lastSurveyResponseQuestion = this.surveyResponse.surveyResponseQuestions.pop();
    const lastQuestionId = lastSurveyResponseQuestion.surveyQuestionId;
    this.currentQuestionSortOrder -= 2;
    this.setNextQuestion(lastQuestionId);
  }

  onSubmit() {
    this.surveySubmitted.emit(this.surveyResponse);
    this.isStarted = false;
    this.isFinished = true;
    this.doSurveyCalculating();
  }

  doSurveyCalculating() {
    of(null)
      .pipe(delay(1000))
      .subscribe((result) => {
        this.showCalculatingText1 = true;

        of(null)
          .pipe(delay(2000))
          .subscribe((result) => {
            this.showCalculatingText2 = true;

            // only show the Create Account text if the user is not logged in
            if (!this.auth.isLoggedIn()) {
              of(null)
              .pipe(delay(2000))
              .subscribe((result) => {
                this.showCalculatingText3 = true;

                of(null)
                  .pipe(delay(2000))
                  .subscribe((result) => {
                    this.calculationsFinished = true;
                  });
              });
            } else {
              of(null)
                .pipe(delay(2000))
                .subscribe((result) => {
                  this.calculationsFinished = true;
                });
            }
          });
      });
  }

  onCalculatingSurveyDone() {
    this.surveyCalculatingFinished.emit(true);
  }
}

class SurveyNextStep {
  nextQuestionId: number | null;
  redirectURL: string;

  constructor(nextQuestionId: number, redirectURL: string) {
    this.nextQuestionId = nextQuestionId;
    this.redirectURL = redirectURL;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ClientProgramListViewModel } from 'src/app/_models/generatedModels';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgramRescheduleDialogComponent } from '../program-reschedule-dialog/program-reschedule-dialog.component';


@Component({
  selector: 'runner-program-details',
  templateUrl: 'runner-program-details.component.html',
  styleUrls: ['runner-program-details.component.scss']
})
export class RunnerProgramDetailsComponent implements OnInit {

  currentProgramId: number = 0;
  programs: ClientProgramListViewModel[];
  initialized: boolean;
  aYearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  @Input()
  clientUserId: number;

  @Input()
  useCards: boolean = false;

  @Input()
  summaryOnly: boolean = false;


  constructor(private router: Router, private clientService: ClientsService, private toastr: ToasterService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getPrograms();
  }

  getPrograms() {
    this.clientService.getCurrentProgramsForClient(this.clientUserId).subscribe(result => {
      this.programs = result;
      this.initialized = true;
    });
  }

  onShowDetails(programId: number) {
    if (this.currentProgramId === programId) {
      this.currentProgramId = 0;
    } else {
      this.currentProgramId = programId;
    }
  }

  deleteProgram(clientProgramId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this program and all the related future workouts from the athlete\'s calendar? This action cannot be undone.', 'Delete Program', 'Delete Program', 'Cancel').subscribe(result => {
      if (result) {
        this.clientService.deleteClientProgram(clientProgramId).subscribe(results => {
          this.programs.remove(x => x.id === clientProgramId);
          this.toastr.success('Program deleted', 'Success');
        });

      }
    });
  }

  rescheduleProgram(clientProgram: ClientProgramListViewModel) {
    const modalRef = this.modalService.open(ProgramRescheduleDialogComponent, { size: 'lg', windowClass: 'modal-full-custom'});
    modalRef.componentInstance.clientProgramId = clientProgram.id;
    modalRef.componentInstance.refProgramId = clientProgram.refProgramId;
    modalRef.componentInstance.userId = this.clientUserId;

    modalRef.componentInstance.programRescheduled.subscribe((result: boolean) => {
      this.getPrograms();
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {
      }
    );
  }
}

import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { UserService } from '../_services/generatedServices';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import moment from 'moment-timezone';
import { UserLookupInputViewModel } from '../_models/generatedModels';

@Injectable()
export class ValidatorHelper {
  constructor(private userService: UserService) { }

  validateEmailNotAlreadyInOrg(userId: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => this.userService.isEmailDuplicateInOrg(encodeURIComponent(control.value), userId || 0)),
        map((res)  => {
          return res ? { emailInOrg: true } : null;
        })
      );
    };
  }

  // just use this if someone is changing existing email address
  validateEmailNotUsed(originalEmailAddress: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // if email is not changed, don't validate bc validation will find existing user and say it's duplicate
      if (control.value.toLowerCase() == originalEmailAddress.toLowerCase()) {
        return of(null);
      } else {
        return timer(500).pipe(
          switchMap(() => this.userService.isEmailDuplicate(encodeURIComponent(control.value), 0)),
          map((res)  => {
            return res ? { emailTaken: true } : null;
          })
        );
      }
    };
  }

  static validateMaxDate(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value == null) {
            return null;
        }

        const controlDate = moment(control.value, '');
        const validationDate = moment(maxDate);

        if (!controlDate.isValid()) {
            return null;
        }

        return controlDate.isAfter(validationDate) ? { maxDate: true } : null;
    };
  }

  static dateRangeValidator(startDateName: string, endDateName: string): ValidatorFn {
    return (fg: FormGroup): ValidationErrors | null => {
      const value1 = fg.get(startDateName).value;
      const value2 = fg.get(endDateName).value;
      if (value1 && value2) {
        if (new Date(value1) > new Date(value2)) {
          return { invalidDateRange: true };
        }
      }
      return null;
    };
  }

  static validateURLSimple(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        if (!control.value.startsWith('http://') && !control.value.startsWith('https://') && !control.value.startsWith('www.')) {
            return { invalidURL: true };
        }

        return null;
    };
  }

}

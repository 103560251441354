import { Component, OnInit } from '@angular/core';
import { TeamService } from 'src/app/_services/generatedServices';
import { TeamSummaryViewModelRead } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageTeamDialogComponent } from 'src/app/_components/manage-team-dialog/manage-team-dialog.component';
import { ToasterService } from 'src/app/_services/toaster.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'bxl-teams-dashboard',
  templateUrl: 'teams-dashboard.component.html',
  styleUrls: ['teams-dashboard.component.scss']
})
export class TeamsDashboardComponent implements OnInit {
  teams: TeamSummaryViewModelRead[];
  initialized: boolean;

  constructor(private teamsService: TeamService, private breadcrumbs: BreadcrumbsService, private modalService: NgbModal, private toastr: ToasterService) {}

  ngOnInit() {
    this.breadcrumbs.SetSecondaryBreadcrumb('Dashboard', 'dashboard', []);
    this.getData();
  }

  getData() {
    this.teamsService.getTeamsSummaryForOrganizationWithPermissions().subscribe(results => {
      this.teams = results;
      this.initialized = true;
    });
  }

  onAddTeam() {
    const modalRef = this.modalService.open(ManageTeamDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
      modalRef.componentInstance.teamId = null;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
  }

  onDeleteTeam(teamId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this team? The entire team calendar will also be deleted and all future team calendar events will be removed from the athletes\' calendars. This action cannot be undone.', 'Delete Team', 'Delete Team', 'Cancel').subscribe(result => {
      if (result) {
        this.teamsService.deleteTeam(teamId).subscribe(results => {
          this.toastr.success('Team Deleted', 'Success');
          // wait for background job to delete the team
          of(null)
          .pipe(delay(2000))
          .subscribe((result) => {
            this.getData();
          });
        });
      }
    });
  }
}

import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserAuthenticationViewModel, UserAuthProfileViewModel, StringViewModel, UserRole, MeasurementSystem } from '../_models/generatedModels';
import { SettingsProvider } from './settingsProvider.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MeasurementSystemStorageName } from '../_helpers/extensions.module';
import moment from 'moment-timezone';
declare var zE: any;
declare var _hsq: any;

@Injectable()
export class AuthenticationService implements OnInit {
  private baseUrl: string;
  public user: UserAuthProfileViewModel;
  public organizationId: number;
  private localAuthTokenName: string = 'rundna-currentUser';
  private userCacheExpirationDate: moment.Moment;

  constructor(private http: HttpClient, settings: SettingsProvider, private router: Router) {
    this.baseUrl = settings.configuration.baseUrl;
  }

  login(username: string, password: string, preferredOrganizationId: number | null = null, preferredOrganizationAPI_Id: number | null = null, preferSelfCoachedOrg: boolean = false): Observable<UserAuthenticationViewModel> {
    return this.http
      .post<any>(this.baseUrl + '/users/authenticateWithParams', { email: username, password: password, preferredOrganizationId: preferredOrganizationId, preferredOrganizationAPI_Id: preferredOrganizationAPI_Id, preferSelfCoachedOrg: preferSelfCoachedOrg })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.localAuthTokenName, JSON.stringify(user));
            this.updateMeasurementSystem(user.measurementSystem);
          }
          this.fetchUserProfile(true, true).subscribe();
          this.removePersistedInputValues();

          return user;
        })
      );
  }

  changeOrgContext(organizationId: number): Observable<UserAuthenticationViewModel> {
    return this.http
      .post<UserAuthenticationViewModel>(this.baseUrl + '/users/changeOrgContext', organizationId)
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.localAuthTokenName, JSON.stringify(user));
          }
          this.fetchUserProfile(true, true).subscribe();
          this.removePersistedInputValues();

          return user;
        })
      );
  }

  updateMeasurementSystem(measurementSystem: MeasurementSystem) {
    localStorage.setItem(MeasurementSystemStorageName, measurementSystem.toString());
  }

  getMeasurementSystem(): MeasurementSystem {
    let localMeasurementSystem = localStorage.getItem(MeasurementSystemStorageName);
    if (localMeasurementSystem) {
      return parseInt(localMeasurementSystem) as MeasurementSystem;
    }
    return MeasurementSystem.Imperial;
  }

  loginOffice365(token: string) {
    let model = new StringViewModel();
    model.value = token;
    return this.http.post<any>(this.baseUrl + '/users/completeMsSignOn', model).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.localAuthTokenName, JSON.stringify(user));
        }
        this.fetchUserProfile().subscribe();

        return user;
      })
    );
  }

  refreshClaims(): Observable<any> {
    let token = this.getUser().token;
    return this.refreshClaimsWithToken(token);
  }

  refreshClaimsWithToken(token, firstLogOut: boolean = false) {
    if (this.isLoggedIn && firstLogOut) {
      this.logout(true);
    }
    return this.http
      .post<any>(this.baseUrl + '/users/refreshToken?access_token=' + token, { token: token })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.localAuthTokenName, JSON.stringify(user));
            this.updateMeasurementSystem(user.measurementSystem);
          }
          this.fetchUserProfile().subscribe();

          return user;
        })
      );
  }

  getToken(): any {
    let currentUser = JSON.parse(localStorage.getItem(this.localAuthTokenName));
    if (currentUser && currentUser.token) {
      return currentUser.token;
    }
  }

  ngOnInit() {
    this.fetchUserProfile(false, true).subscribe();
  }

  fetchUserProfile(isOrgContextChange: boolean = false, clearCache: boolean = false): Observable<UserAuthProfileViewModel> {
    if (!clearCache && !isOrgContextChange && this.user && this.userCacheExpirationDate && this.userCacheExpirationDate > moment()) {
      return of(this.user);

    } else {
      return this.http.get<UserAuthProfileViewModel>(this.baseUrl + '/users/authProfile').pipe(
        map((user) => {
          if (!isOrgContextChange && !this.impersonated && this.organizationId && user.organizationId != this.organizationId) {
            this.logout();
            return;
          }
          let claims = this.getClaims();
          this.organizationId = user.organizationId;
          this.user = user;
          this.userCacheExpirationDate = moment().add(5, 'minutes');
          this.load3rdPartyTrackingData(user.firstName, user.lastName, user.email, user.id);
          return user;
        })
      );
    }
  }

  load3rdPartyTrackingData(firstName: string, lastname: string, email: string, id: number) {
    let name = firstName + ' ' + lastname;
    //let email = this.user.email;
    zE(function () {
      zE.identify({
        name: name,
        email: email,
        organization: 'VIP',
      });
    });

    if (this.impersonated) {
      return;
    }

    _hsq.push([
      'identify',
      {
        email: email,
        firstname: firstName,
        lastname: lastname,
        id: id,
      },
    ]);
  }

  getUser(): UserAuthenticationViewModel {
    let user: UserAuthenticationViewModel = JSON.parse(localStorage.getItem(this.localAuthTokenName));
    return user;
  }

  getClaims() {
    let helper = new JwtHelperService();
    return helper.decodeToken(this.getUser().token);
  }

  isCoach() {
    let claims = this.getClaims();

    let globalCoach = claims['GlobalCoach'];
    let headCoach = claims['HeadCoach'];
    let assistantCoach = claims['AssistantCoach'];
    let administrator = claims['OrganizationAdmin'];

    return globalCoach || headCoach || assistantCoach || administrator;
  }

  isAthlete(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    let claims = this.getClaims();
    let athlete = claims['Runner'];
    return (athlete != undefined);
  }

  hasClaimForRole(userRoleArray: UserRole[]) {
    // returns true if there is a claim for at least one of the roles
    let hasAClaimForRole: boolean = false;
    let claims = this.getClaims();

    userRoleArray.forEach(role => {
      let claimName: string;
      switch (role) {
        case UserRole.Administrator:
        claimName = 'OrganizationAdmin';
        break;
        case UserRole.AssistantCoach:
        claimName = 'AssistantCoach';
        break;
        case UserRole.GlobalCoach:
        claimName = 'GlobalCoach';
        break;
        case UserRole.HeadCoach:
        claimName = 'HeadCoach';
        break;
        case UserRole.Runner:
        claimName = 'Runner';
        break;
      }

      if (claims[claimName]) {
        hasAClaimForRole = true;
      }
    });

    return hasAClaimForRole;
  }

  hasClaim(claimName: string, value: any): boolean {
    let claims = this.getClaims();

    let matchedClaim = claims[claimName];

    if (matchedClaim instanceof Array) {
      return matchedClaim.find((x) => x == value) >= 0;
    } else {
      return matchedClaim == value;
    }
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem(this.localAuthTokenName)) {
      return true;
    } else {
      return false;
    }
  }

  loadZendeskInfo() {
    let name = this.user.firstName + ' ' + this.user.lastName;
    let email = this.user.email;
    zE(function () {
      zE.identify({
        name: name,
        email: email,
        organization: 'VIP',
      });
    });
  }

  isEmailVerified(): boolean {
    return this.user && this.user.emailVerified;
  }

  isOrganizationAdmin(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return this.hasClaim('OrganizationAdmin', this.organizationId);
  }

  isSuperUser(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    return this.hasClaim('SuperUser', true.toString());
  }

  canEditGlobalLibrary(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    return this.hasClaim('CanEditGlobalLibrary', true.toString());
  }

  logout(skipRedirect: boolean = false) {
    // remove user from local storage to log user out
    localStorage.removeItem(this.localAuthTokenName);
    this.removePersistedInputValues();
    let brandKeyName = this.user?.organizationBrandKeyName;
    this.user = null;
    let currentUrl = this.router.url;
    // this.router.navigate([currentUrl]);
    if (!skipRedirect) {
      if (brandKeyName) {
        this.router.navigate(['/auth/login'], { queryParams: { brand: brandKeyName } });
      } else {
        this.router.navigate(['/auth/login']);
      }
    }
  }

  removePersistedInputValues() {
    Object.keys(localStorage).filter(x => x.startsWith('rundna-persisted')).forEach(x => localStorage.removeItem(x));
  }

  impersonateUser(user: UserAuthenticationViewModel) {
    if (user && user.token) {
      let currentUser = JSON.parse(localStorage.getItem(this.localAuthTokenName));
      localStorage.setItem('rundna-impersonated-currentUser', JSON.stringify(currentUser));
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(this.localAuthTokenName, JSON.stringify(user));
      this.fetchUserProfile(true, true).subscribe();
      this.router.navigate(['/']).then(() => {
         window.location.reload();
      });
    }
  }

  unimpersonateUser() {
    let currentUser = JSON.parse(localStorage.getItem('rundna-impersonated-currentUser'));
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem(this.localAuthTokenName, JSON.stringify(currentUser));
    localStorage.removeItem('rundna-impersonated-currentUser');
    this.fetchUserProfile(true, true).subscribe();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
   });
  }

  get impersonated() {
    return localStorage.getItem('rundna-impersonated-currentUser');
  }

  forceLogoutAndClearData() {
    this.logout(true);
    this.router.navigate(['/auth/login']).then(() => {
      window.location.reload();
    });
  }
}

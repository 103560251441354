<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="mt-3">
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Summary</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h3>{{ assessment.dateTime | date }} - <span [ngClass]="{'text-success': assessment.pass, 'text-danger': !assessment.pass}">{{ assessment.pass ? 'PASS' : 'FAIL'}}</span></h3>
          </div>
          <div class="col-md-6">
            <h3>Fitness Level: {{ assessment.compositeScoreCategory | enumString: compositeScoreCategoryEnum }} ({{ assessment.compositePoints }})</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Age: {{ assessment.age }}</div>
          <div class="col-md-6">Gender: {{ assessment.gender | enumString: genderEnum }}</div>
        </div>
        <div *ngIf="assessment.notes" class="row">
          <div class="col">Notes: {{ assessment.notes }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header text-center"><h5 class="h4 mb-0">Cardiorespiratory</h5></div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center font-weight-bold">{{assessment.cardioExerciseType | enumString: cardioExerciseTypeEnum}}</div>
              <div class="col-12 text-center">
                {{ assessment.cardioExerciseType == cardioExerciseType.Run ? (assessment.cardioValue | secsToHourMinSec) : assessment.cardioValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header text-center"><h5 class="h4 mb-0">Muscular Fitness 1</h5></div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center font-weight-bold">{{assessment.muscular1ExerciseType | enumString: muscular1ExerciseTypeEnum}}</div>
              <div class="col-12 text-center">
                {{ assessment.muscular1Value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header text-center"><h5 class="h4 mb-0">Muscular Fitness 2</h5></div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center font-weight-bold">{{assessment.muscular2ExerciseType | enumString: muscular2ExerciseTypeEnum}}</div>
              <div class="col-12 text-center">
                {{ assessment.muscular2ExerciseType == muscular2ExerciseType.ForearmPlank ? (assessment.muscular2Value | secsToHourMinSec) : assessment.muscular2Value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-4">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table align-items-center table-flush mobile-xs-table">
            <thead class="thead-light">
              <tr class="header-row">
                <th>Cardio</th>
                <th>Cardio Points</th>
                <th>Muscular 1</th>
                <th>Muscular 1 Points</th>
                <th>Muscular 2</th>
                <th>Muscular 2 Points</th>
                <th>Composite Score</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr>
                <td><span class="mobile-grid-label">Cardio</span>{{ assessment.cardioExerciseType == cardioExerciseType.Run ? (assessment.cardioValue | secsToHourMinSec) : assessment.cardioValue }}</td>
                <td><span class="mobile-grid-label">Cardio Points</span>{{ assessment.cardioPoints }}</td>
                <td><span class="mobile-grid-label">Muscular 1</span>{{ assessment.muscular1Value }}</td>
                <td><span class="mobile-grid-label">Muscular 1 Points</span>{{ assessment.muscular1Points }}</td>
                <td><span class="mobile-grid-label">Muscular 2</span>{{ assessment.muscular2ExerciseType == muscular2ExerciseType.ForearmPlank ? (assessment.muscular2Value | secsToHourMinSec) : assessment.muscular2Value }}</td>
                <td><span class="mobile-grid-label">Muscular 2 Points</span>{{ assessment.muscular2Points }}</td>
                <td><span class="mobile-grid-label">Composite Score</span>{{ assessment.compositePoints }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container" style="margin-top: 100px">
  <h3 class="text-center">Welcome to RunDNA!</h3>
  <div class="alert alert-warning text-center">Welcome to RunDNA! Please accept (or deny) your pending invitation below</div>
  
  <ng-container *ngIf="!errorMessage && !denySuccess && user && user.status != clientStatus.Active">
    <div class="row mt-3 mb-2">
      <div class="col-12 text-center">
        Organization {{ organization.name }} would like to add you as a {{ user.userRole | flagEnumString: userRoleEnum }} in their organization.
      </div>
    </div>
    <div class="row mt-3 text-center">
      <div class="col">
        <button type="button" class="btn btn-danger" (click)="onDeny()">Deny</button>
        <button type="button" class="btn btn-primary ml-3" (click)="onAccept()">Accept</button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="errorMessage" class="row">
    <div class="col-12 invalid-message-visible">
      {{ errorMessage }}
    </div>
  </div>
  <div *ngIf="denySuccess" class="row">
    <div class="col-12">
      Invitation has been denied. There is nothing else you need to do!
    </div>
  </div>
  <div *ngIf="user && user.status == clientStatus.Active" class="row">
    <div class="col-12">
      Invitation has already been accepted.
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div class="">
  <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Team Dashboard</h3>
    </div>
  </div>

  <div *ngIf="initialized" class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Next Team Event In</h5>
              <span class="h2 font-weight-bold mb-0"> {{team.nextScheduledEvent ? ((getDifferenceInDays(team.nextScheduledEvent)) + ' Days') : 'N/A'}} </span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow"><i class="fas fa-flag-checkered"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col pr-0">
              <h5 class="card-title text-uppercase text-muted mb-0">Scheduled Until</h5>
              <span class="h2 font-weight-bold mb-0"> {{ team.lastScheduledWorkoutDate ? (team.lastScheduledWorkoutDate | date) : 'N/A' }} </span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-green text-white rounded-circle shadow"><i class="far fa-calendar-alt"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Athlete Count</h5>
              <span class="h2 font-weight-bold mb-0"> {{team.activeAthleteCount}} Athletes </span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-orange text-white rounded-circle shadow"><i class="ni ni-user-run"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-12">
          <div class="card">
            <div class="card-header"><h5 class="h4 mb-0 d-inline">Today's Workouts</h5>
            </div>
            <div *ngIf="todaysWorkouts == null" class="container mt-3 mb-2 text-center">No events today</div>
            <calendar-day-details *ngIf="todaysWorkouts" [day]="todaysWorkouts" (actionTaken)="onTodaysWorkoutsChanged($event)"></calendar-day-details>
          </div>
        </div>
        <div *ngIf="team" class="col-lg-12 col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0 d-inline">Coaches</h5>
              <a href="javascript:void(0)" class="text-nowrap float-right small-link" (click)="onEditTeam()"> Add Coach </a>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngFor="let coach of team.coaches" class="col-md-6 col-lg-12">
                  <a style="vertical-align: text-bottom;" href="javascript:void(0)" (click)="onRemoveCoach(coach.id)">
                    <i class="mr-2 fas fa-trash"> </i>
                  </a>
                  <span>{{coach.fullName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <page-load-header *ngIf="!dashboard"></page-load-header>
      <div *ngIf="dashboard" class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0"><i class="far fa-frown text-danger mr-2"></i>Pain in last 7 days</h5>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of dashboard.dashboard.painClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{ user.fullName }}</a> ({{ user.dailyStatusPainDays }} days)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0"><i class="fad fa-circle-notch text-primary mr-2"></i>High ACWR in last 7 days</h5>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of dashboard.dashboard.highACWRClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{ user.fullName }}</a> ({{ user.acuteChronicPercentage }}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0 d-inline"><i class="ni ni-chart-bar-32 text-primary mr-2"></i>Low monthly Completion %</h5>
              <div class="d-inline text-center" kendoTooltip>
                <i class="fas fa-info-circle ml-3" title="Athletes who haven't had a scheduled workout for more than 45 days are excluded from this list."></i>
              </div>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of dashboard.dashboard.lowCompletionClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{ user.fullName }}</a> ({{ user.completionPercentage }}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0 d-inline">Programs</h5>
              <a href="javascript:void(0)" class="text-nowrap float-right small-link" [routerLink]="['/library/programs']"> Add Program </a>
            </div>
            <team-program-details [teamId]="teamId" (changed)="onProgramChanged()" [isCoachView]="true"></team-program-details>
          </div>
        </div>
      </div>
    </div>
  </div>

  <page-load-header *ngIf="searching"></page-load-header>
  <div class="row mt-2">
    <div class="col-12">
      <h5 class="mt-1 d-inline">Athletes</h5>
      <a href="javascript:void(0)" class="text-nowrap float-right small-link" (click)="onEditTeam()"> Add Athlete to Team</a>
    </div>
    <div *ngIf="!searching && (clients && clients.length > 0)" class="col-12">
      <div class="table-responsive">
        <table class="table align-items-center table-flush mobile-table">
          <thead class="thead-light">
            <tr class="header-row sortable two-line-header">
              <th (click)="clients.sort('Credential.FullName')" [ngClass]="clients.getCSSClass('Credential.FullName')">Name</th>

              <th (click)="clients.sort('Stats.NextScheduledEvent')" [ngClass]="clients.getCSSClass('Stats.NextScheduledEvent')">Next Event</th>

              <th (click)="clients.sort('Stats.CompletionPercentage')" [ngClass]="clients.getCSSClass('Stats.CompletionPercentage')">
                Completion % <br />
                (month)
              </th>

              <th (click)="clients.sort('Stats.DailyStatusPainDays')" [ngClass]="clients.getCSSClass('Stats.DailyStatusPainDays')">
                Reported Pain Days <br />
                (week)
              </th>

              <th (click)="clients.sort('Stats.AcuteChronicWorkloadRatioPercentage')" [ngClass]="clients.getCSSClass('Stats.AcuteChronicWorkloadRatioPercentage')">ACWR %</th>

              <th (click)="clients.sort('Stats.LastScheduledWorkoutDate')" [ngClass]="clients.getCSSClass('Stats.LastScheduledWorkoutDate')">Scheduled Until</th>

              <th (click)="clients.sort('CoachUser.Credential.FullName')" [ngClass]="clients.getCSSClass('CoachUser.Credential.FullName')">Coach</th>

              <th class="text-lg-center">Actions</th>
            </tr>
          </thead>

          <tbody class="list">
            <tr *ngFor="let client of clients">
              <th scope="row">
                <div [routerLink]="[athleteUrl, client.userId]" class="media align-items-center" style="cursor: pointer">
                  <a class="avatar rounded-circle mr-3" href="javascript:void(0)">
                    <img alt="Image placeholder" src="fetch-avatar?userId={{ client.userId }}" />
                  </a>

                  <div class="media-body">
                    <span class="name mb-0 text-sm"> {{ client.userFullName }} </span>
                  </div>
                </div>
              </th>

              <td class="budget">
                <span class="mobile-grid-label">Next Event</span>
                {{ client.nextScheduledEvent | date }}
              </td>

              <td>
                <span class="mobile-grid-label">Completion % (month)</span>
                <div class="d-flex align-items-center">
                  <span class="completion mr-2" [ngClass]="(client.completionPercentage != null ? (client.completionPercentage * 100) : null) | completionPercentRangeColor">{{ client.completionPercentage != null ? (client.completionPercentage * 100 | number: '1.2-2') + '%' : 'N/A' }}</span>
                  <div>
                    <ngb-progressbar *ngIf="client.completionPercentage != null" type="dark" [value]="client.completionPercentage * 100"></ngb-progressbar>
                  </div>
                </div>
              </td>

              <td>
                <span class="mobile-grid-label">Reported Pain Days (week)</span>
                {{ client.dailyStatusPainDays }}
              </td>

              <td>
                <span class="mobile-grid-label">ACWR %</span>
                {{ client.acuteChronicWorkloadRatioPercentage != null ? (client.acuteChronicWorkloadRatioPercentage * 100 | number: '1.0-0') + '%' : 'N/A' }}
              </td>

              <td>
                <span class="mobile-grid-label">Scheduled Until</span>
                {{ client.lastScheduledWorkoutDate | date }}
              </td>

              <td class="budget">
                <span class="mobile-grid-label">Coach</span>
                {{ client.coachFullName }}
              </td>

              <td class="text-lg-center">
                <span class="mobile-grid-label">Actions</span>
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-lg-right">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, client.userId]" class="dropdown-item"> View Details </a>
                    <a *ngIf="client.status === 1" href="javascript:void(0)" (click)="onRemoveAthlete(client.userId)" class="dropdown-item"> Remove from Team </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer py-4">
        <nav aria-label="...">
          <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
        </nav>
      </div>
    </div>
  </div>
</div>

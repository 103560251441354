<div *ngIf="initialized">
  <div class="card  mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Calendar Event</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div *ngIf="!disableEditing" class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-md-6 mb-2">
              <label class="form-control-label" for="eventType">
                Calendar Event Type
              </label>
              <select class="form-control" formControlName="eventType" (ngModelChange)="onEventTypeChange($event)">
                <ng-container *ngFor="let mapEntry of eventTypeEnum | keyvalue">
                  <option *ngIf="mapEntry.key != eventType.GarminSyncedWorkout && mapEntry.key != eventType.StravaSyncedWorkout
                    && (calendarType == calendarTypeValues.Client || mapEntry.key != eventType.ManualWorkout)
                    && ((!isCoachView || isSelf) || mapEntry.key != eventType.ManualWorkout)
                    && (isCoachView || (mapEntry.key == eventType.ManualWorkout || mapEntry.key == eventType.Task || mapEntry.key == eventType.Event))" [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                </ng-container>
              </select>
            </div>
            <div class="col-md-6">
            </div>
            <div *ngIf="formGroup.get('eventType').value == eventType.Event" class="col-md-12 small-info mb-2">
              Events should only be used for races and other major athletic events. Events are displayed as a countdown on the dashboard.
            </div>
            <div *ngIf="formGroup.get('eventType').value == eventType.Event" class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="eventName">
                  Event Name
                </label>
                <input class="form-control" formControlName="eventName" maxlength="100" type="text" />
                <div class="invalid-message">
                  <div>Event Name is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('eventType').value == eventType.Task" class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="taskDescription">
                  Task Description
                </label>
                <input class="form-control" formControlName="taskDescription" maxlength="150" type="text" />
                <div class="invalid-message">
                  <div>Task Description is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('eventType').value == eventType.Workout" class="col-md-12">
              <div class="form-group">
                <ng-container *ngIf="!isLimitedEditing"><a (click)="onCreateWorkout()" href="javascript:void(0)" class="link">Create Workout</a><span class="link"> or </span></ng-container>
                <label class="form-control-label" for="clientUserId">Select Workout</label>
                <workouts-dropdown formControlName="workoutId" (changedObject)="onWorkoutChange($event)" [newlyCreatedAndSelectedWorkoutId]="newWorkoutId"></workouts-dropdown>
                <div class="invalid-message">
                  <div>Workout is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="isLimitedEditing" class="col-md-12 invalid-message-visible">
              <div>Limited editing for this occurrence; this is part of a series</div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label class="form-control-label" for="workoutDate">
                  {{formGroup.get('eventType').value | enumString: eventTypeEnum}} Date
                </label>
                <kendo-datepicker placeholder="" class="form-control" formControlName="workoutDate"></kendo-datepicker>
                <div class="invalid-message">
                  <div>Date is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label class="form-control-label" for="sortOrder">
                  Workout Order
                </label>
                <kendo-numerictextbox noScroll placeholder="" class="form-control" formControlName="sortOrder" [decimals]="'0'" [format]="'n0'" min="1" max="10" type="number"></kendo-numerictextbox>
                <div class="invalid-message">
                  <div>Workout Order is required</div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="!formGroup.get('eventId').value && formGroup.get('eventType').value != eventType.Event && formGroup.get('eventType').value != eventType.ManualWorkout">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label" for="repeatFrequency">
                    Repeats
                  </label>
                  <select class="form-control" formControlName="repeatFrequency" (ngModelChange)="onFrequencyChange($event)">
                    <option [ngValue]="repeatFrequency.Never">Never</option>
                    <option [ngValue]="repeatFrequency.Daily">Daily</option>
                    <option [ngValue]="repeatFrequency.Weekly">Weekly</option>
                  </select>
                  <div class="invalid-message">
                    <div>Repeats value is required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="formGroup.get('repeatFrequency').value != repeatFrequency.Never" class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label d-block" for="repeatInterval">
                    Repeat Every
                  </label>
                  <kendo-numerictextbox noScroll placeholder="" class="form-control small-numeric" formControlName="repeatInterval" [decimals]="'0'" [format]="'n0'" min="1" max="31" type="number"></kendo-numerictextbox>
                  <span class="numeric-input-label">{{formGroup.get('repeatFrequency').value == repeatFrequency.Daily ? 'day' : 'week'}}(s)</span>
                  <div class="invalid-message">
                    <div>Repeat Every value is required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="formGroup.get('repeatFrequency').value == repeatFrequency.Weekly" class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label d-block" for="repeatOnDays">
                    Repeat On
                  </label>
                  <div [ngClass]="{'ng-invalid ng-touched': showDaysOfWeekError}" class="btn-group checkbox-buttons">
                    <ng-container *ngFor="let day of daysOfWeek">
                      <label [ngClass]="{'active btn-primary': day.selected, 'btn-secondary' : !day.selected}" class="btn">
                        <input type="checkbox" [(ngModel)]="day.selected" (ngModelChange)="onDayOfWeekChange($event)" [ngModelOptions]="{standalone: true}">{{day.displayName}}
                      </label>
                    </ng-container>
                  </div>
                  <div *ngIf="showDaysOfWeekError" class="invalid-message-visible">
                    <div>At least one day is required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="formGroup.get('repeatFrequency').value != repeatFrequency.Never" class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label d-block" for="repeatEndType">
                    Ends
                  </label>
                  <div class="col-12 mb-1">
                    <label>
                      <input type="radio" (change)="onEndTypeChange($event)" value="on" formControlName="repeatEndType">
                        <span>On</span>
                    </label>
                    <kendo-datepicker placeholder="" class="inline-date" formControlName="repeatUntilDate" (valueChange)="onEndDateChange($event)" [max]="maxEndDate"></kendo-datepicker>
                    <div *ngIf="formGroup.controls['repeatUntilDate'].dirty && formGroup.controls['repeatUntilDate'].errors?.required" class="invalid-message-visible">
                      <div>End Date is required</div>
                    </div>
                    <div *ngIf="formGroup.controls['repeatUntilDate'].dirty && formGroup.controls['repeatUntilDate'].errors?.maxDate" class="invalid-message-visible">
                      <div>End Date cannot be more than a year in the future</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label>
                      <input type="radio" (change)="onEndTypeChange($event)" value="after" formControlName="repeatEndType">
                        <span>After</span>
                    </label>
                    <kendo-numerictextbox noScroll placeholder="" class="form-control small-numeric ml-1" formControlName="repeatCount" (valueChange)="onOccurrenceChange($event)" [decimals]="'0'" [format]="'n0'" min="1" max="50" type="number"></kendo-numerictextbox>
                    <span class="numeric-input-label numeric-input-label-long">occurrences</span>
                    <div *ngIf="formGroup.controls['repeatCount'].dirty && formGroup.controls['repeatCount'].errors?.required" class="invalid-message-visible">
                      <div>Occurrences is required</div>
                    </div>
                  </div>
                  <div *ngIf="formGroup.controls['repeatEndType'].dirty && formGroup.controls['repeatEndType'].errors?.required" class="invalid-message-visible">
                    <div>Value is required</div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="formGroup.get('eventType').value == eventType.QuickWorkout">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label" for="quickWorkoutName">
                    Workout Name
                  </label>
                  <ng-container *ngIf="!isLimitedEditing"><span class="link"> or </span><a (click)="onChangeToWorkoutType()" href="javascript:void(0)" class="link">Create/Select Workout From Library</a></ng-container>
                  <input class="form-control" formControlName="quickWorkoutName" maxlength="100" type="text" />
                  <div class="invalid-message">
                    <div>Workout Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label" for="quickWorkoutDescription">
                    Workout Description
                  </label>
                  <custom-editor formControlName="quickWorkoutDescription"></custom-editor>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label" for="quickWorkoutActivityType"> Workout Type </label>
                  <select class="form-control" formControlName="quickWorkoutActivityType">
                    <option [ngValue]="null">(select one)</option>
                    <ng-container *ngFor="let mapEntry of activityTypeEnum | keyvalue">
                      <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="formGroup.controls['quickWorkoutActivityType'].dirty && formGroup.controls['quickWorkoutActivityType'].errors?.required" class="invalid-message-visible">
                    <div>Workout Type is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label" for="quickWorkoutDuration"> Duration </label>
                  <minutes-seconds-textbox formControlName="quickWorkoutDuration"></minutes-seconds-textbox>
                  <div class="invalid-message">
                    <div>format must be h:mm:ss or m:ss</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label" for="quickWorkoutDistance"> Distance ({{localDistanceName}})</label>
                  <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="quickWorkoutDistance" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number"></kendo-numerictextbox>
                </div>
              </div>
              <div class="col-md-6 d-lg-none">
              </div>
            </ng-container>
            <ng-container *ngIf="formGroup.get('eventType').value == eventType.ManualWorkout">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label" for="activityType"> Manual Workout Type </label>
                  <select class="form-control" formControlName="activityType">
                    <option [ngValue]="null">(select one)</option>
                    <ng-container *ngFor="let mapEntry of activityTypeEnum | keyvalue">
                      <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="formGroup.controls['activityType'].dirty && formGroup.controls['activityType'].errors?.required" class="invalid-message-visible">
                    <div>Manual Workout Type is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label" for="duration"> Duration </label>
                  <minutes-seconds-textbox formControlName="duration"></minutes-seconds-textbox>
                  <div class="invalid-message">
                    <div>format must be h:mm:ss or m:ss</div>
                  </div>
                </div>
              </div>
              <div *ngIf="(!isCoachView || isSelf)" class="col-lg-12">
                <div class="form-group">
                  <label class="form-control-label mr-2" for="rpe"> Rate of Perceived Exertion </label>
                  <rate-perceived-exertion formControlName="ratePerceivedExertion"></rate-perceived-exertion>
                  <div class="invalid-message">
                    <div>Rate of Perceived Exertion is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label" for="distance"> Distance ({{localDistanceName}})</label>
                  <kendo-numerictextbox noScroll class="form-control" formControlName="distance" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number"></kendo-numerictextbox>
                </div>
              </div>
              <div class="col-md-6">
              </div>
            </ng-container>
            <div *ngIf="formGroup.get('eventType').value == eventType.Workout || formGroup.get('eventType').value == eventType.RestDay" class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="coachNotes">
                  Event Notes
                </label>
                <textarea placeholder="Display a note to the athlete for this event" class="form-control" formControlName="coachNotes" type="text" rows="4"></textarea>
              </div>
            </div>
          </div>
      </form>
      </div>
      <div *ngIf="disableEditing" class="card-body editing-error">
        ({{disableEditingError}})
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button *ngIf="!disableEditing" type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">{{calendarEvent?.eventId ? 'Save' : 'Add'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

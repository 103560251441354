import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { OrganizationService, ProgramsService } from 'src/app/_services/generatedServices';
import { OrganizationViewModelRead, OrganizationViewModel, OrganizationType, MarketplaceStatus, MarketplaceCategoryDisplayType, StripeConnectedAccountBalanceViewModel, PromoCodeViewModelRead, ProgramKeywordViewModelRead } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Enums } from 'src/app/_models/generatedEnums';
import { TermsAndConditionsDialogComponent } from 'src/app/_components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, timer } from 'rxjs';
import { ExportService } from 'src/app/_services/export.service';
import { formatShortDate } from 'src/app/_helpers/extensions.module';
import { saveAs } from 'file-saver';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';
import { map, switchMap } from 'rxjs/operators';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import * as moment from 'moment';

@Component({
  selector: 'bxl-admin-marketplace',
  templateUrl: 'admin-marketplace.component.html',
  styleUrls: ['admin-marketplace.component.scss']
})
export class AdminMarketplaceComponent implements OnInit {
  initialized = false;
  org: OrganizationViewModelRead;
  currentMarketplaceStatus: MarketplaceStatus;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  downloadComplete: Promise<{}> | undefined;
  marketplaceStatus = MarketplaceStatus;
  marketplaceCategoryDisplayType = MarketplaceCategoryDisplayType;
  marketplaceCategoryDisplayTypeEnum = Enums.MarketplaceCategoryDisplayTypeEnum;
  stripeLink: string;
  stripeBalanceInfo: StripeConnectedAccountBalanceViewModel;
  stripeDashboard: string;
  orgMarketplaceURL: string;
  isStripeReturn: boolean = false;
  transactionFee: number;
  defaultMarketplaceOrganizationPercentage: number = 0.7;
  promoCodes: PromoCodeViewModelRead[] = [];

  dateTimeFormat: string = 'MM/dd/yy HH:ss';
  timezone: string;
  initializedPromoCode = false;
  public promoCodeFormGroup: FormGroup;
  submitPromoComplete: Promise<{}> | undefined;
  allProgramKeywords: ProgramKeywordViewModelRead[] = [];

  constructor(private orgService: OrganizationService, private breadcrumbs: BreadcrumbsService, private fb: FormBuilder, private toastr: ToasterService,
    private auth: AuthenticationService, private route: ActivatedRoute, private modalService: NgbModal, private exportService: ExportService, private settingsProvider: SettingsProvider,
    private programService: ProgramsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Marketplace', 'marketplace', []);
    let stripeSetupValue = this.route.snapshot.queryParamMap.get('stripe') ?? null;
    this.isStripeReturn = stripeSetupValue == 'return' ? true : false;
    let timeZone = moment.tz.guess();
    this.timezone = moment.tz(timeZone).format('z');
    this.programService.getProgramKeywordsForOrganization().subscribe(result => {
      this.allProgramKeywords = result;
    });

    this.getSettingsData(null);
    this.getPromoCodeData(null);
  }

  getSettingsData(resetButton: any = null) {
    this.auth.fetchUserProfile().subscribe(profile => {
      this.stripeDashboard = 'api/organizations/stripeConnectedAccount/dashboard?access_token=' + this.auth.getToken();
      forkJoin([this.orgService.getOrganization(profile.organizationId), this.orgService.getStripeConnectedAccountBalance(profile.organizationId)]).subscribe(results => {
        this.org = results[0];
        this.transactionFee = 100 - ((this.org.marketplaceOrganizationPercentage ?? this.defaultMarketplaceOrganizationPercentage) * 100);
        this.stripeBalanceInfo = results[1];
        this.currentMarketplaceStatus = this.org.marketplaceStatus;
        this.orgMarketplaceURL = this.settingsProvider.siteUrl + '/marketplace/' + this.org.marketplaceURLSlug;
        this.setupForm();

        if (this.org.marketplaceStatus == MarketplaceStatus.PendingStripeSetup) {
          this.createStripeConnectLink(resetButton);
        }
        else {
          if (resetButton != null) resetButton();
        }
      });
    });
  }

  getPromoCodeData(resetButton: any = null) {
    this.orgService.getPromoCodes().subscribe(result => {
      this.promoCodes = result;
      this.setupPromoCodeForm();

      if (resetButton != null) resetButton();
    });
    
  }

  setupForm() {
    this.formGroup = this.fb.group({
      marketplaceStatus: [(this.currentMarketplaceStatus == MarketplaceStatus.Active || this.currentMarketplaceStatus == MarketplaceStatus.PendingStripeSetup) ? true : false],
      marketplaceURLSlug: [this.org.marketplaceURLSlug],
      marketplaceDescription: [this.org.marketplaceDescription],
      organizationURL: [this.org.organizationURL],
      marketplaceLogo: [this.org.marketplaceLogo],
      marketplaceCategoryDisplayType: [this.org.marketplaceCategoryDisplayType],
    });

    this.onMarketplaceStatusChange();
    this.initialized = true;
  }

  setupPromoCodeForm() {
    this.promoCodeFormGroup = this.fb.group({
      promoCodes: this.fb.array([])
    });

    let promoCodesArray = this.promoCodeFormGroup.get('promoCodes') as FormArray;

    if (this.promoCodes.length > 0) {
      this.promoCodes.forEach(promoCode => {
        promoCodesArray.push(this.fb.group({
          id: [promoCode.id],
          code: [promoCode.code, Validators.required],
          percentageDiscount: [promoCode.percentageDiscount * 100, [Validators.required, Validators.min(1), Validators.max(99)]],
          startDate: [new Date(promoCode.startDate), Validators.required],
          endDate: [new Date(promoCode.endDate), Validators.required],
          programKeywords: [promoCode.programKeywords ? promoCode.programKeywords.map(x => x.id) : []],
          } , { validators: [ValidatorHelper.dateRangeValidator('startDate', 'endDate')] }));
      });
    } else {
      this.initializePromoCode();
    }
    

    this.initializedPromoCode = true;
  }

  initializePromoCode() {
    let promoCodesArray = this.promoCodeFormGroup.get('promoCodes') as FormArray;

    promoCodesArray.insert(0, this.fb.group({
      id: [0],
      code: [null, Validators.required],
      percentageDiscount: [null, [Validators.required, Validators.min(1), Validators.max(99)]],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      programKeywords: [[]],
      }));
  }

  onDeletePromoCode(index: number) {
    let promoCodesArray = this.promoCodeFormGroup.get('promoCodes') as FormArray;
    promoCodesArray.removeAt(index);
  }

  onMarketplaceStatusChange() {
    if (this.formGroup.get('marketplaceStatus').value == true) {
      this.formGroup.get('marketplaceURLSlug').setValidators([Validators.required, this.validateURLSlugFormat()]);
      this.formGroup.get('marketplaceURLSlug').setAsyncValidators([this.validateURLSlugNotTaken.bind(this)]);
      if (this.currentMarketplaceStatus == MarketplaceStatus.Active) {
        this.formGroup.get('marketplaceURLSlug').disable();
      }
      this.formGroup.get('organizationURL').setValidators([Validators.required, ValidatorHelper.validateURLSimple()]);
      this.formGroup.get('marketplaceLogo').setValidators([Validators.required]);
      this.formGroup.get('marketplaceCategoryDisplayType').setValidators([Validators.required]);
    } else {
      this.formGroup.get('marketplaceURLSlug').setValidators([this.validateURLSlugFormat()]);
      this.formGroup.get('marketplaceURLSlug').setAsyncValidators([this.validateURLSlugNotTaken.bind(this)]);
      this.formGroup.get('organizationURL').setValidators([]);
      this.formGroup.get('marketplaceLogo').setValidators([]);
      this.formGroup.get('marketplaceCategoryDisplayType').setValidators([]);
    }

    this.formGroup.get('marketplaceURLSlug').updateValueAndValidity();
    this.formGroup.get('organizationURL').updateValueAndValidity();
    this.formGroup.get('marketplaceLogo').updateValueAndValidity();
    this.formGroup.get('marketplaceCategoryDisplayType').updateValueAndValidity();
  }

  validateURLSlugNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.orgService.isMarketplaceURLSlugDuplicate(control.value)),
      map((res)  => {
        return res ? { nameTaken: true } : null;
      })
    );
  }

  validateURLSlugFormat(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const validRegEx = /^[-,A-Za-z0-9]+$/;
        if (!validRegEx.test(control.value) || control.value.length < 5) {
            return { invalidFormat: true };
        } else if (control.value.includes('rundna')) {
            return { invalidFormatRunDNA: true };
        }

        return null;
    };
  }

  onViewTerms() {
    const modalRef = this.modalService.open(TermsAndConditionsDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom'});
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
  }

  downloadPurchaseHistory() {
    this.downloadComplete = new Promise((resetButton: any, reject) => {
      this.exportService.ExportProgramPurchaseHistory().subscribe(file => {
        let date = formatShortDate(new Date());
        let fileName = `ProgramPurchaseHistory_${date}.xlsx`;
        saveAs(file, fileName);
        resetButton();
      });
    });
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton: any, reject) => {
      const formData: OrganizationViewModel = Object.assign({}, this.org, this.formGroup.value);
      formData.marketplaceURLSlug = formData.marketplaceURLSlug.toLowerCase();
      
      // have to determine if they've gone through the Stripe setup process
      if (this.formGroup.get('marketplaceStatus').value == true) {
        formData.marketplaceStatus = this.currentMarketplaceStatus == MarketplaceStatus.None ? MarketplaceStatus.PendingStripeSetup : this.currentMarketplaceStatus;
      } else {
        formData.marketplaceStatus = MarketplaceStatus.None;
      }

      this.orgService.updateOrganization(formData).subscribe((result) => {
        this.toastr.success('Marketplace Settings Updated', 'Success');

        this.getSettingsData(resetButton);
      });
    });
  }

  createStripeConnectLink(resetButton: any = null) {
    this.orgService.createConnectedAccountLink(this.org.id).subscribe(result => {
      this.stripeLink = result.value;
      resetButton();
    });
  }

  onPromoCodeSave() {
    if (!this.promoCodeFormGroup.valid) {
      this.promoCodeFormGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitPromoComplete = new Promise((resetButton: any, reject) => {
      const formData: PromoCodeViewModelRead[] = [];
      this.promoCodeFormGroup.get('promoCodes').value.forEach((promoCode) => {
        let promoCodeData = Object.assign({}, promoCode);
        promoCodeData.percentageDiscount = promoCode.percentageDiscount / 100;
        promoCodeData.programKeywords = this.allProgramKeywords.filter(x => promoCode.programKeywords.includes(x.id));
        formData.push(promoCodeData);
      });

      this.orgService.addUpdatePromoCodes(formData).subscribe((result) => {
        this.toastr.success('Promo Codes updated', 'Success');

        this.getPromoCodeData(resetButton);
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { coachUserRoleArray } from 'src/app/_models/models';

@Component({
  selector: 'bxl-runner-journal',
  templateUrl: 'runner-journal.component.html',
  styleUrls: ['runner-journal.component.scss']
})
export class RunnerJournalComponent implements OnInit {
  initialized = false;
  clientUserId: number;
  hasCoachRole: boolean;


  constructor(private auth: AuthenticationService,  private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Plans', 'plans', []);
    this.auth.fetchUserProfile().subscribe(user => {
      this.clientUserId = user.id;
      this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);
      this.initialized = true;
    });
  }
}

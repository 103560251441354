import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-view-2d-assessment',
  templateUrl: 'view-2d-assessment.component.html',
})
export class View2DAssessmentComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }

}

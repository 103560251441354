<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Terms and Conditions</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <div class="card-body" style="height: 80vh; overflow-y: auto;">
    <terms-and-conditions-text></terms-and-conditions-text>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col text-right">
        <button type="button" (click)="activeModal.dismiss('cancel')" class="btn btn-primary">OK</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="initialized">
  <div class="card">
    <div class="bg-dark">
      <header class="card-header py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Quick Workout Details</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body p-0">
        <div class="container mt-3">
          <div class="row">
            <div class="col-12">
              <h3 class="text-white d-inline">{{programWorkoutDay.quickWorkoutName}}</h3>
            </div>
            <div class="col-12">
              <div class="card p-3">
                <div>
                  <span class="font-weight-bold">Activity Type: </span><span>{{programWorkoutDay.quickWorkoutActivityType | enumString: activityTypeEnum}}</span>
                </div>
                <div>
                  <span class="font-weight-bold">Expected Duration: </span><span>{{programWorkoutDay.quickWorkoutDuration | secsToHourMinSec}}</span>
                </div>
                <div *ngIf="programWorkoutDay.quickWorkoutDistance">
                  <span class="font-weight-bold">Expected Distance: </span><span>{{(programWorkoutDay.quickWorkoutDistance | fromMilesToLocalMeasurement) | number : '1.2-2'}}</span><span> {{localMeasurementName}}</span>
                </div>
                <div *ngIf="programWorkoutDay.quickWorkoutDescription" class="mt-2">
                  <div class="font-weight-bold">Description:</div>
                <span [editorHTML]="programWorkoutDay.quickWorkoutDescription"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




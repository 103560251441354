import { ImpairmentCategory, AssessmentResult, AccountStatus, Gender, LevelOfRunning, RunningExperience, ClientStatus, VideoType, WorkoutItemType, WorkoutType, CardioIntensityType, CardioType, CardioDistanceUOM, UserRole, MovementCategory, ExerciseEquipment, AssessmentStatus, AssessmentType, PainType, TwoDimensionalAssessmentResult, ClientDayEventType, DayOfWeekFlag, RecurringEventFrequency, WorkoutCategory, AFHealthRiskCategory, AFCompositeScoreCategory, AFFitnessAssessmentCardioExerciseType, AFFitnessAssessmentMuscular1ExerciseType, AFFitnessAssessmentMuscular2ExerciseType, OrganizationType, NotificationType, TrainingLoadPeriodType, VDOTPaceType, RaceDistanceType, CurrentMessageMode, ActivityType, CalendarType, CalendarContextMenuOptions, TeamUserRole, SurveyQuestionType, ProgramCategory, MarketplaceStatus, MarketplaceCategoryDisplayType, AthleteType, GlobalMarketplaceExperienceLevel, GlobalMarketplaceProgramType, GarminPoolLengthUnit, WorkoutItemGarminRepeatType, GarminWorkoutStepIntensity, GarminWorkoutStepDurationType, GarminWorkoutStepDurationValueType, GarminWorkoutStepTargetType, GarminWorkoutStepTargetValueType, GarminWorkoutStepStrokeType, GarminWorkoutStepEquipmentType, MeasurementSystem, ExerciseResistanceType, WeightUOM, JournalType } from './generatedModels';

export class Enums {
  public static ImpairmentCategoryEnum = new Map<number, string>([
    [ImpairmentCategory.Uncategorized, 'Uncategorized'],
    [ImpairmentCategory.OverstriderCadence, 'Overstrider Cadence'],
    [ImpairmentCategory.OverstriderKneeDrive, 'Overstrider Knee Drive'],
    [ImpairmentCategory.OverstriderCollapsing, 'Overstrider Collapsing'],
    [ImpairmentCategory.OverstriderGluteAmnesiac, 'Overstrider Glute Amnesiac'],
    [ImpairmentCategory.OverstriderGeneral, 'Overstrider General'],
    [ImpairmentCategory.GeneralCadence, 'General Cadence'],
    [ImpairmentCategory.CollapserCPD, 'Collapser CPD'],
    [ImpairmentCategory.CollapserBouncer, 'Collapser Bouncer'],
    [ImpairmentCategory.CollapserHip, 'Collapser Hip'],
    [ImpairmentCategory.Bouncer, 'Bouncer'],
    [ImpairmentCategory.GluteAmnesiac, 'Glute Amnesiac'],
    [ImpairmentCategory.Weaver, 'Weaver'],
    [ImpairmentCategory.Collapser, 'Collapser'],
  ]);

  public static AssessmentResultEnum = new Map<number, string>([
    [AssessmentResult.Able, 'Able'],
    [AssessmentResult.Unable, 'Unable'],
    [AssessmentResult.AbleWithPain, 'Able With Pain'],
    [AssessmentResult.UnableWithPain, 'Unable With Pain'],
    [AssessmentResult.ActiveLimitation, 'Active Limitation'],
    [AssessmentResult.PassiveLimitation, 'Passive Limitation'],
    [AssessmentResult.ActiveLimitationWithPain, 'Active Limitation With Pain'],
    [AssessmentResult.PassiveLimitationWithPain, 'Passive Limitation With Pain'],
  ]);

  public static AccountStatusEnum = new Map<number, string>([
    [AccountStatus.FullAccount, 'FullAccount'],
    [AccountStatus.PendingSetup, 'PendingSetup'],
  ]);

  public static GenderEnum = new Map<number, string>([
    [Gender.Other, 'Other'],
    [Gender.Male, 'Male'],
    [Gender.Female, 'Female'],
  ]);

  public static LevelOfRunningEnum = new Map<number, string>([
    [LevelOfRunning.Recreational, 'Recreational'],
    [LevelOfRunning.HighSchool, 'HighSchool'],
    [LevelOfRunning.College, 'College'],
    [LevelOfRunning.Professional, 'Professional'],
  ]);

  public static RunningExperienceEnum = new Map<number, string>([
    [RunningExperience.Beginner, 'Beginner'],
    [RunningExperience.OneToTwo, '1-2 Years'],
    [RunningExperience.ThreeToFive, 'ThreeToFive'],
    [RunningExperience.FiveToSeven, 'FiveToSeven'],
    [RunningExperience.SevenToTen, 'SevenToTen'],
    [RunningExperience.TenPlus, 'TenPlus'],
  ]);

  public static ClientStatusEnum = new Map<number, string>([
    [ClientStatus.Active, 'Active'],
    [ClientStatus.InActive, 'InActive'],
    [ClientStatus.Invited, 'Invited'],
  ]);

  public static VideoTypeEnum = new Map<number, string>([
    [VideoType.None, 'None'],
    [VideoType.Upload, 'Upload'],
    [VideoType.Public, 'Public'],
  ]);

  public static WorkoutItemTypeEnum = new Map<number, string>([
    [WorkoutItemType.Exercise, 'Exercise'],
    [WorkoutItemType.Circuit, 'Circuit'],
    [WorkoutItemType.Cardio, 'Cardio'],
    [WorkoutItemType.Interval, 'Interval'],
    [WorkoutItemType.GarminWorkoutStep, 'Step'],
    [WorkoutItemType.GarminWorkoutRepeatStep, 'Interval'],
  ]);

  public static WorkoutTypeEnum = new Map<number, string>([
    [WorkoutType.Strength, 'Strength'],
    [WorkoutType.Cardio, 'Cardio'],
    [WorkoutType.GarminRunning, 'Garmin Running'],
    [WorkoutType.GarminCycling, 'Garmin Cycling'],
    [WorkoutType.GarminLap_Swimming, 'Garmin Swimming'],
  ]);

  public static CardioIntensityTypeEnum = new Map<number, string>([
    [CardioIntensityType.HeartRateZone, 'Heart Rate Zone'],
    [CardioIntensityType.Pace, 'Pace'],
    [CardioIntensityType.RateOfPerceivedExertion, 'Rate of Perceived Exertion'],
    [CardioIntensityType.VDOTPace, 'VDOT Pace'],
  ]);

  public static CardioTypeEnum = new Map<number, string>([
    [CardioType.Work, 'Work'],
    [CardioType.Recovery, 'Recovery'],
  ]);

  public static CardioDistanceUOMEnum = new Map<number, string>([
    [CardioDistanceUOM.Miles, 'miles'],
    [CardioDistanceUOM.Kilometers, 'km'],
    [CardioDistanceUOM.Meters, 'meters'],
  ]);

  public static UserRoleEnum = new Map<number, string>([
    [UserRole.Runner, 'Athlete'],
    [UserRole.AssistantCoach, 'Assistant Coach'],
    [UserRole.HeadCoach, 'Head Coach'],
    [UserRole.GlobalCoach, 'Global Coach'],
    [UserRole.Administrator, 'Administrator'],
  ]);

  public static MovementCategoryEnum = new Map<number, string>([
    [MovementCategory.Uncategorized, 'Uncategorized'],
    [MovementCategory.SquatDorsiflexion, 'Squat: Dorsiflexion'],
    [MovementCategory.BackBend, 'Back Bend'],
    [MovementCategory.ToeTouchPosteriorChain, 'Toe Touch - Posterior Chain'],
    [MovementCategory.ToeTouchSpine, 'Toe Touch Spine'],
    [MovementCategory.SquatHipMobility, 'Squat: Hip Mobility'],
    [MovementCategory.UHBE, 'UHBE'],
    [MovementCategory.SingleLegSitToStand, 'Single Leg Sit to Stand'],
    [MovementCategory.BalanceHipCore, 'Balance: Hip/Core'],
    [MovementCategory.BalanceFootAnkle, 'Balance: Foot/Ankle'],
    [MovementCategory.CalfRaises, 'Calf Raises'],
    [MovementCategory.StrongFoot, 'Strong Foot'],
    [MovementCategory.RotationUE, 'Rotation: UE'],
    [MovementCategory.RotationLE, 'Rotation: LE'],
    [MovementCategory.SidePlank, 'Side Plank'],
  ]);

  public static ExerciseEquipmentEnum = new Map<number, string>([
    [ExerciseEquipment.AerobicPlyoStep, 'Aerobic / Plyo Step'],
    [ExerciseEquipment.Barbell, 'Barbell'],
    [ExerciseEquipment.Box, 'Box'],
    [ExerciseEquipment.Dumbbell, 'Dumbbell'],
    [ExerciseEquipment.FoamRoller, 'Foam Roller'],
    [ExerciseEquipment.HeavyBands, 'Heavy Bands'],
    [ExerciseEquipment.Kettlebell, 'Kettlebell'],
    [ExerciseEquipment.LacrosseBall, 'Lacrosse Ball'],
    [ExerciseEquipment.LargePhysioBall, 'Large Physio Ball'],
    [ExerciseEquipment.LoopBands, 'Loop Bands'],
    [ExerciseEquipment.MedicineBall, 'Medicine Ball'],
    [ExerciseEquipment.PullUpBar, 'Pull Up Bar'],
    [ExerciseEquipment.TRXSuspensionStraps, 'TRX / Suspension Straps'],
    [ExerciseEquipment.WeightedVest, 'Weighted Vest'],
    [ExerciseEquipment.YogaBlock, 'Yoga Block'],
  ]);

  public static AssessmentStatusEnum = new Map<number, string>([
    [AssessmentStatus.InProgress, 'Incomplete'],
    [AssessmentStatus.Completed, 'Programs Unassigned'],
    [AssessmentStatus.ProgramsAssigned, 'Programs Assigned'],
  ]);

  public static AssessmentTypeEnum = new Map<number, string>([
    [AssessmentType.GaitAnalysis, '3D Gait Analysis'],
    [AssessmentType.RRA, 'Runner Readiness Assessment'],
    [AssessmentType.LoadingLevels, 'Loading Levels Assessment'],
    [AssessmentType.TwoDimensional, '2D Assessment'],
    [AssessmentType.USAFFitnessAssessment, 'USAF Fitness Assessment'],
  ]);

  public static PainTypeEnum = new Map<number, string>([
    [PainType.Rest, 'Rest'],
    [PainType.Activity, 'Activity'],
    [PainType.Both, 'Both'],
  ]);

  public static TwoDimensionalAssessmentResultEnum = new Map<number, string>([
    [TwoDimensionalAssessmentResult.NeedsAttention, 'Needs Attention'],
    [TwoDimensionalAssessmentResult.Satisfactory, 'Satisfactory'],
  ]);

  public static ClientDayEventTypeEnum = new Map<number, string>([
    [ClientDayEventType.Workout, 'Workout'],
    [ClientDayEventType.RestDay, 'Rest Day'],
    [ClientDayEventType.Event, 'Event'],
    [ClientDayEventType.Task, 'Task'],
    [ClientDayEventType.GarminSyncedWorkout, 'Garmin Synced Workout'],
    [ClientDayEventType.ManualWorkout, 'Manual Workout'],
    [ClientDayEventType.StravaSyncedWorkout, 'Strava Synced Workout'],
    [ClientDayEventType.QuickWorkout, 'Quick Workout'],
  ]);

  public static DayOfWeekFlagEnum = new Map<number, string>([
    [DayOfWeekFlag.Sunday, 'Sunday'],
    [DayOfWeekFlag.Monday, 'Monday'],
    [DayOfWeekFlag.Tuesday, 'Tuesday'],
    [DayOfWeekFlag.Wednesday, 'Wednesday'],
    [DayOfWeekFlag.Thursday, 'Thursday'],
    [DayOfWeekFlag.Friday, 'Friday'],
    [DayOfWeekFlag.Saturday, 'Saturday'],
  ]);

  public static RecurringEventFrequencyEnum = new Map<number, string>([
    [RecurringEventFrequency.Never, 'Never'],
    [RecurringEventFrequency.Daily, 'Daily'],
    [RecurringEventFrequency.Weekly, 'Weekly'],
  ]);

  public static WorkoutCategoryEnum = new Map<number, string>([
    [WorkoutCategory.Warmup, 'Warm Up'],
    [WorkoutCategory.Cardio, 'Cardio'],
    [WorkoutCategory.Mobility, 'Mobility'],
    [WorkoutCategory.Core, 'Core'],
    [WorkoutCategory.Strength, 'Strength'],
    [WorkoutCategory.Rehab, 'Rehab'],
  ]);

  public static AFHealthRiskCategoryEnum = new Map<number, string>([
    [AFHealthRiskCategory.LowRisk, 'Low Risk'],
    [AFHealthRiskCategory.ModerateRisk, 'Moderate Risk'],
    [AFHealthRiskCategory.HighRisk, 'High Risk'],
  ]);

  public static AFCompositeScoreCategoryEnum = new Map<number, string>([
    [AFCompositeScoreCategory.Unsatisfactory, 'Unsatisfactory'],
    [AFCompositeScoreCategory.Satisfactory, 'Satisfactory'],
    [AFCompositeScoreCategory.Excellent, 'Excellent'],
  ]);

  public static AFFitnessAssessmentCardioExerciseTypeEnum = new Map<number, string>([
    [AFFitnessAssessmentCardioExerciseType.Run, '1.5 Mile Run'],
    [AFFitnessAssessmentCardioExerciseType.HamrShuttle, 'HAMR Shuttle'],
  ]);

  public static AFFitnessAssessmentMuscular1ExerciseTypeEnum = new Map<number, string>([
    [AFFitnessAssessmentMuscular1ExerciseType.Pushup, 'Pushups'],
    [AFFitnessAssessmentMuscular1ExerciseType.HandReleasePushup, 'Hand-release Pushups'],
  ]);

  public static AFFitnessAssessmentMuscular2ExerciseTypeEnum = new Map<number, string>([
    [AFFitnessAssessmentMuscular2ExerciseType.Situp, 'Situps'],
    [AFFitnessAssessmentMuscular2ExerciseType.CrossLegReverseCrunch, 'Cross Leg Reverse Crunch'],
    [AFFitnessAssessmentMuscular2ExerciseType.ForearmPlank, 'Forearm Plank'],
  ]);

  public static OrganizationTypeEnum = new Map<number, string>([
    [OrganizationType.General, 'General'],
    [OrganizationType.HasUSAFFitnessAssessment, 'HasUSAFFitnessAssessment'],
  ]);

  public static NotificationTypeEnum = new Map<number, string>([
    [NotificationType.DailyWorkout, 'Daily Workout'],
    [NotificationType.ProgramAssigned, 'Program Assigned'],
    [NotificationType.WeeklyAthleteReport, 'Weekly Athlete Report'],
    [NotificationType.SyncedWorkoutRPE, 'Synced Workout RPE'],
    [NotificationType.WeeklyTrainingLoadTarget, 'Weekly Training Load Target'],
    [NotificationType.InAppMessage, 'In-App Message'],
  ]);

  public static TrainingLoadPeriodTypeEnum = new Map<number, string>([
    [TrainingLoadPeriodType.Maintenance, 'Maintenance'],
    [TrainingLoadPeriodType.Base, 'Base'],
    [TrainingLoadPeriodType.Build, 'Build'],
    [TrainingLoadPeriodType.Peak, 'Peak'],
    [TrainingLoadPeriodType.Rest, 'Rest'],
    [TrainingLoadPeriodType.Taper, 'Taper'],
    [TrainingLoadPeriodType.Race, 'Race'],
    [TrainingLoadPeriodType.Skip, 'Skip'],
  ]);

  public static VDOTPaceTypeEnum = new Map<number, string>([
    [VDOTPaceType.Easy, 'Easy'],
    [VDOTPaceType.Marathon, 'Marathon'],
    [VDOTPaceType.Threshold, 'Threshold'],
    [VDOTPaceType.Interval, 'Interval'],
    [VDOTPaceType.Repetition, 'Repetition'],
  ]);

  public static RaceDistanceTypeEnum = new Map<number, string>([
    [RaceDistanceType.FiveK, '5K'],
    [RaceDistanceType.TenK, '10K'],
    [RaceDistanceType.HalfMarathon, 'Half Marathon'],
    [RaceDistanceType.Marathon, 'Marathon'],
    [RaceDistanceType.Custom, 'Custom'],
  ]);

  public static CurrentMessageModeEnum = new Map<number, string>([
    [CurrentMessageMode.SpecificUser, 'SpecificUser'],
    [CurrentMessageMode.AllAthletes, 'AllAthletes'],
    [CurrentMessageMode.AllCoaches, 'AllCoaches'],
    [CurrentMessageMode.SpecificTeam, 'SpecificTeam'],
  ]);

  public static ActivityTypeEnum = new Map<number, string>([
    [ActivityType.Running, 'Running'],
    [ActivityType.Biking, 'Biking'],
    [ActivityType.Swimming, 'Swimming'],
    [ActivityType.Walking, 'Walking'],
    [ActivityType.StrengthTraining, 'Strength Training'],
    [ActivityType.Other, 'Other'],
  ]);

  public static CalendarTypeEnum = new Map<number, string>([
    [CalendarType.Client, 'Client'],
    [CalendarType.Team, 'Team'],
  ]);

  public static CalendarContextMenuOptionsEnum = new Map<number, string>([
    [CalendarContextMenuOptions.None, 'None'],
    [CalendarContextMenuOptions.View, 'View'],
    [CalendarContextMenuOptions.Edit, 'Edit'],
    [CalendarContextMenuOptions.Delete, 'Delete'],
    [CalendarContextMenuOptions.ToggleComplete, 'Toggle Complete'],
    [CalendarContextMenuOptions.Copy, 'Copy'],
  ]);

  public static TeamUserRoleEnum = new Map<number, string>([
    [TeamUserRole.None, 'None'],
    [TeamUserRole.Runner, 'Athlete'],
    [TeamUserRole.Coach, 'Coach'],
  ]);

  public static SurveyQuestionTypeEnum = new Map<number, string>([
    [SurveyQuestionType.OneAnswerOnly, 'OneAnswerOnly'],
    [SurveyQuestionType.MultipleAnswersOptional, 'MultipleAnswersOptional'],
    [SurveyQuestionType.MultipleAnswersRequired, 'MultipleAnswersRequired'],
  ]);

  public static ProgramCategoryEnum = new Map<number, string>([
    [ProgramCategory.Cardio, 'Cardio'],
    [ProgramCategory.Mobility, 'Mobility'],
    [ProgramCategory.Rehab, 'Rehab'],
    [ProgramCategory.Strength, 'Strength'],
  ]);

  public static MarketplaceStatusEnum = new Map<number, string>([
    [MarketplaceStatus.None, 'None'],
    [MarketplaceStatus.PendingStripeSetup, 'Pending Stripe Setup'],
    [MarketplaceStatus.Active, 'Active'],
  ]);

  public static MarketplaceCategoryDisplayTypeEnum = new Map<number, string>([
    [MarketplaceCategoryDisplayType.ByCoach, 'By Coach'],
    [MarketplaceCategoryDisplayType.ByKeyword, 'By Keyword'],
  ]);

  public static AthleteTypeEnum = new Map<number, string>([
    [AthleteType.Coached, 'Coached'],
    [AthleteType.Marketplace, 'Marketplace'],
  ]);

  public static GlobalMarketplaceExperienceLevelEnum = new Map<number, string>([
    [GlobalMarketplaceExperienceLevel.All, 'All'],
    [GlobalMarketplaceExperienceLevel.Beginner, 'Beginner'],
    [GlobalMarketplaceExperienceLevel.Intermediate, 'Intermediate'],
    [GlobalMarketplaceExperienceLevel.Advanced, 'Advanced'],
    [GlobalMarketplaceExperienceLevel.Elite, 'Elite'],
  ]);

  public static GlobalMarketplaceProgramTypeEnum = new Map<number, string>([
    [GlobalMarketplaceProgramType.FiveK, '5k'],
    [GlobalMarketplaceProgramType.TenK, '10k'],
    [GlobalMarketplaceProgramType.HalfMarathon, 'Half Marathon'],
    [GlobalMarketplaceProgramType.Marathon, 'Marathon'],
    [GlobalMarketplaceProgramType.Ultra, 'Ultra'],
    [GlobalMarketplaceProgramType.TrackLess800m, 'Track <800m'],
    [GlobalMarketplaceProgramType.TrackGreater800m, 'Track 800m+'],
    [GlobalMarketplaceProgramType.Triathlon, 'Triathlon'],
    [GlobalMarketplaceProgramType.BaseBuilding, 'Base Building'],
    [GlobalMarketplaceProgramType.RehabInjury, 'Rehab / Injury'],
    [GlobalMarketplaceProgramType.StrengthCore, 'Strength and Core'],
    [GlobalMarketplaceProgramType.OtherDistance, 'Other Distance'],
  ]);

  public static GarminPoolLengthUnitEnum = new Map<number, string>([
    [GarminPoolLengthUnit.METER, 'Meters'],
    [GarminPoolLengthUnit.YARD, 'Yards'],
  ]);

  public static WorkoutItemGarminRepeatTypeEnum = new Map<number, string>([
    [WorkoutItemGarminRepeatType.REPEAT_UNTIL_STEPS_CMPLT, 'Repeat Until Steps Complete'],
  ]);

  public static GarminWorkoutStepIntensityEnum = new Map<number, string>([
    [GarminWorkoutStepIntensity.WARMUP, 'Warm up'],
    [GarminWorkoutStepIntensity.ACTIVE, 'Active'],
    [GarminWorkoutStepIntensity.RECOVERY, 'Recovery'],
    [GarminWorkoutStepIntensity.REST, 'Rest'],
    [GarminWorkoutStepIntensity.COOLDOWN, 'Cool down'],
  ]);

  public static GarminWorkoutStepDurationTypeEnum = new Map<number, string>([
    [GarminWorkoutStepDurationType.TIME, 'Time'],
    [GarminWorkoutStepDurationType.DISTANCE, 'Distance'],
    [GarminWorkoutStepDurationType.OPEN, 'Lap Button Press'],
  ]);

  public static GarminWorkoutStepDurationValueTypeEnum = new Map<number, string>([
    [GarminWorkoutStepDurationValueType.MILE, 'Miles'],
    [GarminWorkoutStepDurationValueType.KILOMETER, 'KM'],
    [GarminWorkoutStepDurationValueType.METER, 'Meters'],
    [GarminWorkoutStepDurationValueType.YARD, 'Yards'],
  ]);

  public static GarminWorkoutStepTargetTypeEnum = new Map<number, string>([
    [GarminWorkoutStepTargetType.SPEED, 'Speed'],
    [GarminWorkoutStepTargetType.PACE, 'Pace'],
    [GarminWorkoutStepTargetType.HEART_RATE_ZONE, 'Heart Rate Zone'],
    [GarminWorkoutStepTargetType.HEART_RATE_CUSTOM, 'Custom Heart Rate'],
    [GarminWorkoutStepTargetType.POWER_ZONE, 'Power Zone'],
    [GarminWorkoutStepTargetType.POWER_CUSTOM, 'Custom Power'],
  ]);

  public static GarminWorkoutStepTargetValueTypeEnum = new Map<number, string>([
    [GarminWorkoutStepTargetValueType.MINS_PER_MILE, 'Min/Mile'],
    [GarminWorkoutStepTargetValueType.MINS_PER_KILOMETER, 'Min/KM'],
    [GarminWorkoutStepTargetValueType.MILES_PER_HOUR, 'MPH'],
    [GarminWorkoutStepTargetValueType.KILOMETERS_PER_HOUR, 'KPH'],
    [GarminWorkoutStepTargetValueType.MIN_PER_100_YARDS, 'Min/100y'],
    [GarminWorkoutStepTargetValueType.MIN_PER_100_METERS, 'Min/100m'],
  ]);

  public static GarminWorkoutStepStrokeTypeEnum = new Map<number, string>([
    [GarminWorkoutStepStrokeType.BACKSTROKE, 'Backstroke'],
    [GarminWorkoutStepStrokeType.BREASTSTROKE, 'Breaststroke'],
    [GarminWorkoutStepStrokeType.DRILL, 'Drill'],
    [GarminWorkoutStepStrokeType.BUTTERFLY, 'Butterfly'],
    [GarminWorkoutStepStrokeType.FREESTYLE, 'Freestyle'],
    [GarminWorkoutStepStrokeType.MIXED, 'Mixed'],
    [GarminWorkoutStepStrokeType.IM, 'Individual Medley'],
  ]);

  public static GarminWorkoutStepEquipmentTypeEnum = new Map<number, string>([
    [GarminWorkoutStepEquipmentType.NONE, 'None'],
    [GarminWorkoutStepEquipmentType.SWIM_FINS, 'Swim Fins'],
    [GarminWorkoutStepEquipmentType.SWIM_KICKBOARD, 'Swim Kickboard'],
    [GarminWorkoutStepEquipmentType.SWIM_PADDLES, 'Swim Paddles'],
    [GarminWorkoutStepEquipmentType.SWIM_PULL_BUOY, 'Swim Pull Buoy'],
    [GarminWorkoutStepEquipmentType.SWIM_SNORKEL, 'Swim Snorkel'],
  ]);

  public static MeasurementSystemEnum = new Map<number, string>([
    [MeasurementSystem.Imperial, 'Imperial'],
    [MeasurementSystem.Metric, 'Metric'],
  ]);

  public static ExerciseResistanceTypeEnum = new Map<number, string>([
    [ExerciseResistanceType.Resistance, 'Resistance'],
    [ExerciseResistanceType.Weight, 'Weight'],
    [ExerciseResistanceType.Hold, 'Hold'],
  ]);

  public static WeightUOMEnum = new Map<number, string>([
    [WeightUOM.Pounds, 'lbs'],
    [WeightUOM.Kilograms, 'kgs'],
  ]);

  public static JournalTypeEnum = new Map<number, string>([
    [JournalType.General, 'General Journal'],
    [JournalType.Workout, 'Workout Journal'],
    [JournalType.DailyStatus, 'Daily Status'],
  ]);

}

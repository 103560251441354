<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12 text-center">
      <label class="btn-bs-file btn btn-lg btn-primary">
        Choose an Image
        <input type="file" (change)="fileChangeEvent($event)" />
      </label>

      <image-cropper [imageChangedEvent]="imageChangedEvent" [imageBase64]="initialCroppedImage" [maintainAspectRatio]="true" [onlyScaleDown]="true" [aspectRatio]="1 / 1" [resizeToWidth]="512" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('saved')">Save</button>
  </div>
</ng-template>

import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'rundna-basic-website-wrapper',
  templateUrl: 'rundna-basic-website-wrapper.component.html',
  styleUrls: ['rundna-basic-website-wrapper.component.scss']
})
export class RunDNABasicWebsiteWrapperComponent implements OnInit {
  mobileNavActive = false;
  getStartedActive = false;
  communityActive = false;

  constructor() {}

  ngOnInit() {

  }

  toggleMobileNav() {
    this.mobileNavActive = !this.mobileNavActive;
  }

  onGetStartedClick() {
    this.getStartedActive = !this.getStartedActive;
  }

  onCommunityClick() {
    this.communityActive = !this.communityActive;
  }

}
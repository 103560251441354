import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-exercise-preview',
  templateUrl: 'exercise-preview.component.html',
})
export class ExercisePreviewComponent implements OnInit {
  initialized = false;

  @Input()
  exerciseId: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.initialized = true;
  }
}

import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ClientSummaryDTO, ProgramTeamAssignmentViewModel } from 'src/app/_models/generatedModels';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'assign-team-program',
  templateUrl: 'assign-team-program.component.html'
})
export class AssignTeamProgramComponent implements OnChanges {
  initialized = false;
  public formGroup: FormGroup;
  clients: ClientSummaryDTO[];
  submitComplete: Promise<{}> | undefined;
  openAssignProgramValue: boolean;

  @Input()
    programId: number;

  @Input()
    programName: string;

    @Input()
    get openAssignProgram() {
        return this.openAssignProgramValue;
    }

    set openAssignProgram(val) {
        this.openAssignProgramValue = val;
        this.openAssignProgramChange.emit(this.openAssignProgramValue);
    }

    @Output()
    openAssignProgramChange = new EventEmitter<boolean>();

  constructor(private programData: ProgramsService, private auth: AuthenticationService, private fb: FormBuilder, private toastr: ToasterService) { }

  ngOnChanges(): void {
    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      teamId: [null, Validators.required],
      programId: [this.programId],
      assignedByUserId: [this.auth.user.id],
      startDate: [null, Validators.required]
    });

    this.initialized = true;
  }

  closeAssignProgramDialog(status: string) {
    if (status === 'save') {
        if (!this.formGroup.valid) {
            this.formGroup.markAllControlsDirty();
            return;
        }

        this.submitComplete = new Promise((resetButton:any, reject) => {
          const formData: ProgramTeamAssignmentViewModel = Object.assign({}, this.formGroup.getRawValue());
          formData.startDate = dateAsUTCNoTime(formData.startDate);
            this.programData.assignProgramToTeam(formData).subscribe(results => {
                this.toastr.success('Program has been assigned', 'Success');
                this.openAssignProgram = false;
                resetButton();
            }, error => resetButton());
        });

    } else {
        this.openAssignProgram = false;
    }
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AthleteType, ClientStatus, UserRole } from '../_models/generatedModels';
import { AuthenticationService } from '../_services/authentication.service';
import { getFullURLFromRoute } from '../_helpers/extensions.module';

@Injectable()
export class IsOrgActiveGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.fetchUserProfile().pipe(map(user => {
      // organization must be active, or user must be marketplace user that is still active
      if (user.trialDaysLeft >= 0 || user.activeSubscription || (user.status == ClientStatus.Active && (user.athleteType & AthleteType.Marketplace) != 0)) {
        return true;
      } else {
        if (user.userRole & UserRole.Administrator) {
          // allow admins of expired orgs to access athlete list so they can deactivate athletes before creating subscription
          if (getFullURLFromRoute(route) == '/athletes/list') {
            return true;
          } else {
            this.router.navigateByUrl('/admin/billing');
          }
          
        } else {
          this.router.navigateByUrl('/org-inactive');
        }

        return false;
      }
    }));
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import {  WorkoutDisplayExtendedViewModelRead, WorkoutInstanceViewModelRead, WorkoutItemDisplayViewModelRead } from 'src/app/_models/generatedModels';

@Component({
  selector: 'workout-view-pdf',
  templateUrl: 'workout-view-pdf.component.html',
  styleUrls: ['workout-view-pdf.component.scss']
})
export class WorkoutViewPDFComponent implements OnInit {
  initialized = false;
  workoutItems: WorkoutItemDisplayViewModelRead[];
  workoutCategoryEnum = Enums.WorkoutCategoryEnum;
  workoutInstancesWithNotes: WorkoutInstanceViewModelRead[];
  garminPoolLengthUnitEnum = Enums.GarminPoolLengthUnitEnum;

  @Input()
  workout: WorkoutDisplayExtendedViewModelRead;


  constructor() { }

  ngOnInit(): void {
    this.workoutItems = this.workout.workoutItems as WorkoutItemDisplayViewModelRead[];
    this.workoutInstancesWithNotes = this.workout.workoutInstances.filter(x => x.coachNotes);
    this.initialized = true;
  }
}

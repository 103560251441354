import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { parseDateStringAsUTC } from "src/app/_helpers/extensions.module";
import { BrandViewModelRead, OrganizationViewModelRead } from "src/app/_models/generatedModels";
import { hammerAndAxeSlug } from "src/app/_models/models";
import { BrandService, PaidProgramService } from "src/app/_services/generatedServices";
import { OrgMarketplaceService } from "src/app/_services/orgMarketplace.service";

@Component({
  selector: "app-hax-programs-layout",
  templateUrl: "./hax-programs-layout.component.html",
  styleUrls: ["./hax-programs-layout.component.scss"]
})
export class HAXProgramsLayoutComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private brandService: BrandService, private paidProgramService: PaidProgramService, private orgMarketplaceService: OrgMarketplaceService) {}
  brand: BrandViewModelRead;
  brandBannerActive: boolean = false;
  initialized: boolean = false;

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("body-hax");

    this.paidProgramService.getOrganizationByMarketplaceURLSlug(hammerAndAxeSlug).subscribe(organization => {
      this.orgMarketplaceService.SetOrganizationInfo(organization);
      this.initialized = true;
    });

    this.brandService.getBrandByKeyName('hammerAndAxe').subscribe(brand => {
      this.brand = brand;
      if (brand && brand.bannerText && brand.bannerTextExpirationDate) {
        if (parseDateStringAsUTC(this.brand.bannerTextExpirationDate).getTime() > (new Date()).getTime()) {
          this.brandBannerActive = true;
        }
      }
    });
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("body-hax");
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6" style="min-height: 500px">
  <div class="container pt-6">
    <div class="header-body">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 text-center">
          <h6 class="h2 d-inline-block mb-0">{{survey.name}}</h6>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="initialized">
    <div class="container-fluid mt-3">
      <div class="row justify-content-center">
        <div class="col-12">
          <ng-container>
            <div class="card">
              <div *ngIf="!surveyResult || surveyResultPrograms.length == 0" class="card-body">
                We are unable to find recommendations for you based on your answers. Please take the survey again.
              </div>
              <div *ngIf="surveyResult" class="card-body">
                <h5>Personalized Program Recommendations</h5>
                <div>
                  <span [editorHTML]="surveyResult.description"></span>
                </div>
                <div class="row mt-4">
                  <div *ngFor="let program of surveyResultPrograms" class="col-12 mt-2">
                    <div class="card">
                      <div class="card-header">
                        <h5 class="program-name">{{program.name}}<span class="program-category" *ngIf="program.programCategory">  ({{ (program.programCategory | flagEnumString:programCategoryEnum) }})</span></h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 text-lg mb-2">
                            Program Length: {{program.weekCount}} Weeks
                          </div>
                          <div class="col-md-12">
                            <div *ngIf="program.videoLink || program.videoId">
                              <embed-video-player [url]="program.videoLink" [videoId]="program.videoId" [size]="'small'"></embed-video-player>
                            </div>
                            <div [ngClass]="{'collapsed-description': !program.isExpandedDescription}">
                              <span [editorHTML]="program.description"></span>
                            </div>
                            <div *ngIf="program.description" class="text-link" (click)="program.isExpandedDescription = !program.isExpandedDescription">
                              {{ program.isExpandedDescription ? 'Less...' : 'More...' }}
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <program-first-week-preview [program]="program"></program-first-week-preview>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-lg-4 mt-2">
                            <div class="form-group">
                              <label class="form-control-label" for="programStartDate">
                                Program Start Date
                              </label>
                              <kendo-datepicker placeholder="" class="form-control" [(ngModel)]="program.programStartDate"></kendo-datepicker>
                              <div class="invalid-message">
                                <div>Program start date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="surveyResult" class="row">
              <div class="col text-right">
                <button type="button" class="btn btn-primary" (click)="onAddPrograms()" [promiseBtn]="submitComplete">{{ isPublicAccess ? 'Create Account and Add Programs' : 'Add Programs to Calendar' }}</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>


import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutDisplayExtendedViewModelRead, WorkoutDisplayParams } from 'src/app/_models/generatedModels';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GeneratePDFService } from 'src/app/_services/generatePDF.service';

@Component({
  selector: 'print-workout-dialog',
  templateUrl: 'print-workout-dialog.component.html',
  styleUrls: ['print-workout-dialog.component.scss'],
})
export class PrintWorkoutDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  workout: WorkoutDisplayExtendedViewModelRead;
  ready: boolean;

  @Input()
    workoutId: number;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private exerciseService: ExerciseService, private pdfService: GeneratePDFService) { }

  ngOnInit(): void {
    let model = new WorkoutDisplayParams();
    model.workoutId = this.workoutId;
    model.forCurrentUser = false;
    model.clientProgramWorkoutDayId = null;
    this.exerciseService.getWorkoutDisplayDetails(model).subscribe(result => {
      this.workout = result as WorkoutDisplayExtendedViewModelRead;
      this.workout.workoutInstances = [];
      this.formGroup = this.fb.group({});
      this.initialized = true;
    });
  }

  onSave() {
    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.ready = true;

      setTimeout(() => {
        this.pdfService.createPDF('workoutPrintDiv', 'RunDNA Workout - ' + this.workout.name.replace(new RegExp("[\\\\/:*?\"<>|]", "g"), "")).then(result => {
          this.activeModal.dismiss('saved');
        });
      }, 250);
      
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>


<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Settings</h3>
      </div>
    </div>

    <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="name">Organization Name</label>
            <input type="text" class="form-control" formControlName="name" />
            <div class="invalid-message">
              <div>Name is required</div>
            </div>
          </div>
          <label for="basic-url">Your Organization's URL</label>
          <input type="text" class="form-control" formControlName="organizationURL" maxlength="100" />
          <div class="invalid-message">
            <div>Organization URL must start with www. or http:// or https://</div>
          </div>
          <div class="form-group mt-2">
            <label for="requestDailyStatus">
              Request Daily Status from Athletes
            </label>
            <div class="d-inline text-center" kendoTooltip>
              <i class="fas fa-info-circle ml-1" title="If this is turned off, the Daily Status form won't pop up when an athlete logs in."></i>
            </div>
            <kendo-switch class="d-flex" formControlName="requestDailyStatus"></kendo-switch>
          </div>
          <hr/>
          <h4>Address</h4>
          <div class="form-group">
            <label for="street">Address</label>
            <input type="text" class="form-control" formControlName="street1">
          </div>
          <div class="form-group">
            <label for="street2">Address 2</label>
            <input type="text" class="form-control" formControlName="street2">
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="city">City</label>
              <input type="text" class="form-control" formControlName="city">
            </div>
            <div class="form-group col-md-4">
              <label for="stateCode">State</label>
              <state-dropdown class="form-control" formControlName="stateCode"></state-dropdown>
            </div>
            <div class="form-group col-md-2">
              <label for="postalCode">Zip</label>
              <input type="text" class="form-control" formControlName="postalCode">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h4>Logo</h4>
          <div class="mb-1">
            Upload a 224x60px image here if you'd like your organization's logo to appear in the top left navigation bar for all users in this organization. (You'll want to use a light colored logo since it will be shown on a dark background.)
            <a class="d-block" href="https://rundnahelp.zendesk.com/hc/en-us/articles/14655366602007-Web-Portal-Custom-Logo-sizing-instructions" target="_blank">Logo sizing instructions</a>
          </div>
          <div>
            <azure-file-upload #uploader formControlName="avatar" src="/fetchimage?fileName={{ formGroup.controls.avatar.value }}" [useDarkBackground]="true" [requiredDimensions]="'224x60'" [immediateUpload]="true" name="logo"></azure-file-upload>
          </div>
          <div class="invalid-message-visible" *ngIf="formGroup?.controls['avatar'].errors && formGroup?.controls['avatar'].errors.invalidDimensions">
            <div>Invalid Dimensions</div>
          </div>
          <div class="invalid-message-visible" *ngIf="formGroup?.controls['avatar'].errors && formGroup?.controls['avatar'].errors.invalidFileSize">
            <div>Invalid File Size - must be less than 200KB</div>
          </div>
          <div class="invalid-message-visible" *ngIf="formGroup?.controls['avatar'].errors && formGroup?.controls['avatar'].errors.invalidFileType">
            <div>Invalid File Type</div>
          </div>
          <!-- <div class="media align-items-center mb-3">
            <div class="avatar-xl mr-3">
              <img *ngIf="croppedImage" class="img-fluid" [src]="croppedImage" alt="Image Description" />
            </div>
    
            <div class="media-body">
              <button type="button" (click)="editImage()" class="btn btn-sm btn-primary transition-3d-hover mb-1 mr-2 mb-sm-0">Upload New Picture</button>
              <button type="button" (click)="deleteImage()" class="btn btn-sm btn-soft-secondary transition-3d-hover mb-1 mb-sm-0">Delete</button>
            </div>
          </div> -->
        </div>
        <div class="col-md-12">
          <div class="row form-btns">
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- <app-image-upload-modal *ngIf="showImageUpload" (changedImage)="onImageChanged($event)" [(openDialog)]="showImageUpload" [initialCroppedImage]="initialCroppedImage"></app-image-upload-modal> -->

</div>


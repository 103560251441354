import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService, ProgramsService } from 'src/app/_services/generatedServices';
import { TwoDimensionalAssessmentProgramDisplayViewModel, TwoDimensionalProgramAssignmentViewModel } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'two-dimensional-program-dialog',
  templateUrl: 'two-dimensional-program-dialog.component.html'
})
export class TwoDimensionalProgramDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  model: TwoDimensionalAssessmentProgramDisplayViewModel;
  impairmentCategoryEnum = Enums.ImpairmentCategoryEnum;

  @Input()
    assessmentId: number;

    @Output()
    saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private assessmentService: AssessmentService,
    private programsService: ProgramsService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.assessmentService.getTwoDimensionalAssessmentProgramInfoById(this.assessmentId).subscribe(result => {
      this.model = result;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      twoDimensionalAssessmentId: [this.model.twoDimensionalAssessmentId],
      clientUserId: [this.model.clientUserId],
      impairmentCategory: [this.model.impairmentCategory],
      impairmentCategoryProgramId: [this.model.impairmentCategoryProgramId],
      impairmentCategoryProgramName: [this.model.impairmentCategoryProgramName],
      impairmentCategoryProgramStartDate: [ null, (this.model.impairmentCategoryProgramId ? Validators.required : null)],
      optionalProgramId: [null],
      optionalProgramStartDate: [null]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: TwoDimensionalProgramAssignmentViewModel = Object.assign({}, this.formGroup.getRawValue());
      formData.impairmentCategoryProgramStartDate = formData.impairmentCategoryProgramStartDate ? dateAsUTCNoTime(formData.impairmentCategoryProgramStartDate) : null;
      formData.optionalProgramStartDate = formData.optionalProgramStartDate ? dateAsUTCNoTime(formData.optionalProgramStartDate) : null;
      this.programsService.assignTwoDimensionalAssessmentProgramsToClient(formData).subscribe(result => {
        this.toastr.success('Programs Assigned', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }

  onOptionalProgramChange() {
    if (this.formGroup.get('optionalProgramId').value) {
      this.formGroup.get('optionalProgramStartDate').setValidators([Validators.required]);
    } else {
      this.formGroup.get('optionalProgramStartDate').setValidators([]);
    }

    this.formGroup.get('optionalProgramStartDate').updateValueAndValidity();
  }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { invalid } from "moment-timezone";
import { getParamFromRoute } from "src/app/_helpers/extensions.module";
import { MarketplaceStatus, OrganizationViewModelRead } from "src/app/_models/generatedModels";
import { hammerAndAxeSlug } from "src/app/_models/models";
import { PaidProgramService } from "src/app/_services/generatedServices";
import { OrgMarketplaceService } from "src/app/_services/orgMarketplace.service";

@Component({
  selector: "app-org-marketplace-layout",
  templateUrl: "./org-marketplace-layout.component.html",
  styleUrls: ["./org-marketplace-layout.component.scss"]
})
export class OrgMarketplaceLayoutComponent implements OnInit, OnDestroy {
  organization: OrganizationViewModelRead;
  initialized: boolean = false;
  invalidURL: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private paidProgramService: PaidProgramService, private orgMarketplaceService: OrgMarketplaceService) {}

  ngOnInit() {
    let marketplaceURLSlug = getParamFromRoute(this.route, 'orgURLSlug');
    this.paidProgramService.getOrganizationByMarketplaceURLSlug(marketplaceURLSlug).subscribe(organization => {
      this.organization = organization;
      if (this.organization.organizationURL && !this.organization.organizationURL.includes('//')) {
        this.organization.organizationURL = '//' + this.organization.organizationURL;
      }

      if (!this.organization || !this.organization.activeSubscription || this.organization.marketplaceStatus != MarketplaceStatus.Active) {
        this.invalidURL = true;
      } else if (this.organization.marketplaceURLSlug == hammerAndAxeSlug) {
        this.router.navigate(['/' + hammerAndAxeSlug]);
      }
      else {
        this.orgMarketplaceService.SetOrganizationInfo(organization);
        this.initialized = true;
      }
      
    });
  }

  ngOnDestroy() {

  }
}

import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter  } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportsService } from 'src/app/_services/generatedServices';
import { CoachAthleteGroupViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { forkJoin } from 'rxjs';



@Component({
    selector: 'coach-athlete-group-dropdown',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <kendo-combobox #combo [data]="comboData" [disabled]="isDisabled" [valuePrimitive]="true" [filterable]="true" [popupSettings]="{popupClass: 'df-list-scroller'}" valueField="userId" textField="description" [(ngModel)]="selectedValue" class="{{cssClass}}" (filterChange)="handleFilter($event)" (valueChange)="onChange($event)"></kendo-combobox>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoachAthleteGroupDropdownControl),
            multi: true
        }
    ]
})

export class CoachAthleteGroupDropdownControl implements ControlValueAccessor, AfterContentInit {

    someAttr: string = 'anyValue';
    selectedValue: number | null = null;

    constructor(elementRef: ElementRef, private reportsService: ReportsService, public auth: AuthenticationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    isDisabled: boolean;

    @Output()
    changed = new EventEmitter<number | null>();


    comboData: CoachAthleteGroupViewModel[];
    rawData: CoachAthleteGroupViewModel[];
    @ViewChild('combo') public combo: ComboBoxComponent | undefined;
    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: number | null): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.changed.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngAfterContentInit(): void {
        forkJoin([this.reportsService.getCoachAthleteGroups(), this.auth.fetchUserProfile()]).subscribe(results => {
            this.rawData = results[0];
            this.comboData = results[0];

            // default to current user if it exists in drop down
            const currentUser = results[1].id;
            if (this.rawData.some(x => x.userId == currentUser)) {
              this.onChange(currentUser);
            }
            else {
              this.onChange(null);
            }

        });
    }

    handleFilter(value: any) {
        if (value.length >= 1) {
            this.comboData = this.rawData.filter(x => x.description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
            this.comboData = this.rawData;
            this.combo.toggle(false);
        }
    }
}

<div class="container-fluid">
  <footer class="footer">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-md-12">
        <div class="copyright text-center text-muted">
          &copy; {{ currentDate | date: "yyyy" }}
          <a
            href="javascript:void(0)"
            class="font-weight-bold ml-1"
            >RunDNA</a
          >
          <p class="small text-muted mb-0">Version: {{settings.configuration.version}} ({{settings.configuration.environmentName}})</p>
          <!-- <p class="small text-muted mb-0">{{settings.configuration.clientIP}}</p>
          <p class="small text-muted mb-0">{{settings.configuration.environmentName}}</p> -->
          <p class="small text-muted mb-0"><a href="/terms" target="_blank">Terms and Conditions</a></p>
          <p class="small text-muted mb-0"><a href="/privacy" target="_blank">Privacy Policy</a></p>
        </div>
      </div>
      <div class="col-xl-6">
        <!-- <ul
          class="nav nav-footer justify-content-center justify-content-xl-end"
        >
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >RunDNA</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com/presentation?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a
              href="http://blog.creative-tim.com?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >Blog</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com/license"
              class="nav-link"
              target="_blank"
              >License</a
            >
          </li>
        </ul> -->
      </div>
    </div>
  </footer>
</div>

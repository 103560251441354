import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { RacePlanViewModalComponent } from 'src/app/_components/race-plan-view-modal/race-plan-view-modal.component';
import { TrainingPlanViewModalComponent } from 'src/app/_components/training-plan-view-modal/training-plan-view-modal.component';
import { formatShortDate } from 'src/app/_helpers/extensions.module';
import { RacePlanListViewModelRead, SurveySummaryViewModelRead, TrainingLoadPlannerListViewModelRead } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RacePlanService, TrainingLoadPlannerService } from 'src/app/_services/generatedServices';
import { saveAs } from 'file-saver';
import { ExportService } from 'src/app/_services/export.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { coachUserRoleArray } from 'src/app/_models/models';
import { SurveyHandlerService } from 'src/app/_services/surveyHandler.service';

@Component({
  selector: 'bxl-runner-plans',
  templateUrl: 'runner-plans.component.html',
  styleUrls: ['runner-plans.component.scss']
})
export class RunnerPlansComponent implements OnInit {
  initialized = false;
  clientUserId: number;
  trainingPlans: TrainingLoadPlannerListViewModelRead[];
  racePlans: RacePlanListViewModelRead[];
  hasCoachRole: boolean;
  survey: SurveySummaryViewModelRead;


  constructor(private router: Router, private auth: AuthenticationService, private racePlanService: RacePlanService, private trainingPlanService: TrainingLoadPlannerService,
    private modalService: NgbModal, private exportService: ExportService, private breadcrumbs: BreadcrumbsService, private surveyHandlerService: SurveyHandlerService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Plans', 'plans', []);
    this.auth.fetchUserProfile().subscribe(user => {
      this.clientUserId = user.id;
      this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);

      forkJoin([this.trainingPlanService.getTrainingLoadPlansByUser(this.clientUserId), this.racePlanService.getRacePlansByUser(this.clientUserId), this.surveyHandlerService.getSurvey()]).subscribe(results => {
        this.trainingPlans = results[0];
        this.racePlans = results[1];
        this.survey = results[2];
        this.initialized = true;
      });
    });
  }

  onShowTrainingPlanDetails(trainingPlanId: number) {
    const modalRef = this.modalService.open(TrainingPlanViewModalComponent, { size: 'lg' });
    modalRef.componentInstance.trainingPlanId = trainingPlanId;
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onShowRacePlanDetails(racePlanId: number) {
    const modalRef = this.modalService.open(RacePlanViewModalComponent, { size: 'lg' });
    modalRef.componentInstance.racePlanId = racePlanId;
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onExportRacePlan(racePlanId: number, name: string) {
    this.exportService.ExportRacePlan(this.clientUserId, racePlanId).subscribe(file => {
      let date = formatShortDate(new Date());
      let fileName = `RacePlan_${name}_${date}.xlsx`;
      saveAs(file, fileName);
    });
  }

  handleSurvey() {
    this.surveyHandlerService.handleSurvey();
  }
}

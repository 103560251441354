<div *ngIf="initialized">
  <div class="card">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Exercise Progression Preview</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h3>{{ progression.name }}</h3>
          </div>
          <div *ngIf="progression.notes" class="col-12">
            {{ progression.notes }}
          </div>
        </div>
        <div class="row">
          <div *ngFor="let level of progression.levels" class="col-12 mt-3">
            Level {{level.level}}: <a href="javascript:void(0)" (click)="onExercisePreview(level.exerciseId)">{{ level.exerciseName }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">

    <ul ngbNav #tabNav="ngbNav" [destroyOnHide]="false" class="nav-pills">
      <li [ngbNavItem]="0">
        <a ngbNavLink>{{this.organizationId ? 'Edit' :'Add'}} Organization</a>
        <ng-template ngbNavContent>
          <div class="row mt-2 no-gutters">
            <div class="col">
              <h3 class="mb-0">{{this.organizationId ? 'Edit' :'Add'}} Organization</h3>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" #ngForm="ngForm" novalidate>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="organizationName"> Organization Name </label>
                      <input class="form-control" formControlName="organizationName" maxlength="100" type="text" />
                    </div>
                  </div>
                  <div *ngIf="!organizationId" class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="email"> Administrator Email </label>
                      <input class="form-control" formControlName="email" maxlength="75" type="text" />
                      <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.emailInOrg">
                        <div>Email address is already taken</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div *ngIf="!organizationId" class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="firstName"> Administrator First Name </label>
                      <input class="form-control" formControlName="firstName" maxlength="75" type="text" />
                    </div>
                  </div>
                  <div *ngIf="!organizationId" class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="lastName"> Administrator Last Name </label>
                      <input class="form-control" formControlName="lastName" maxlength="75" type="text" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="firstName"> Level </label>
                      <select class="form-control" formControlName="isLevel2">
                        <option [value]="false">Level 1</option>
                        <option [value]="true">Level 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-2 mt-1">
                    <span class="font-weight-bold">Has Paid Subscription?</span>
                    <span> {{organization.runnerSlotSubscriptionId ? ('YES: ' + organization.runnerSlotSubscriptionId)  : 'NO'}}</span>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label" for="firstName"> Complimentary Subscription </label>
                      <select class="form-control" formControlName="complimentarySubscription">
                        <option [value]="false">No</option>
                        <option [value]="true">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label" for="firstName"> Complimentary Subscription End Date </label>
                      <div class="d-inline text-center" kendoTooltip>
                        <i
                          class="fas fa-info-circle ml-2"
                          title="If org has paid subscription and you assign a complimentary subscription WITH end date, the paid subscription will be updated to add free trial days
                           until Complimentary Subscription End Date. If no end date is specified, the paid subscription isn't modified and you should probably manually cancel it
                            (you can do this by impersonating the admin and canceling from the Billing page)."
                        ></i>
                      </div>
                      <kendo-datepicker class="form-control" formControlName="complimentarySubscriptionEndDate"></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <div *ngIf="organizationId" class="row">
                  <div class="col-12 mb-2 mt-1">
                    <span class="font-weight-bold">Marketplace Status:</span><span> {{organization.marketplaceStatus | enumString: marketplaceStatusEnum}}</span>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label" for="marketplaceOrganizationPercentage"> Marketplace % for Organization </label>
                      <kendo-numerictextbox noScroll class="form-control" formControlName="marketplaceOrganizationPercentage" [decimals]="'2'" [format]="'n2'" min="0" max="1" type="number" ></kendo-numerictextbox>
                      <div class="invalid-message">
                        <div>Must be between 0 and 1 (60% is .60)</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label" for="featuredInGlobalMarketplace"> Is Featured In Global Marketplace </label>
                      <kendo-switch class="d-flex" formControlName="featuredInGlobalMarketplace"></kendo-switch>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="firstName"> Organization Types </label>
                      <div class="btn-group checkbox-buttons d-block">
                        <ng-container *ngFor="let type of organizationTypes">
                          <label [ngClass]="{'active btn-primary': type.selected, 'btn-secondary' : !type.selected}" class="btn">
                            <input type="checkbox" [(ngModel)]="type.selected" [ngModelOptions]="{standalone: true}">{{type.displayName}}
                          </label>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="organizationId">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="firstName"> Trial Expires </label>

                      <kendo-datepicker class="form-control" formControlName="trialExpires"></kendo-datepicker>
                    </div>
                  </div>
                </div>
                <h5>Dongle Ids</h5>
                <div formArrayName="dongleIds" *ngFor="let dongle of formGroup.controls['dongleIds'].controls; let i = index" class="row mb-3">
                  <div class="col-md-6">
                    <div [formGroupName]="i" class="input-group input-group">
                      <input type="text" formControlName="dongleId" class="form-control" />

                      <div class="input-group-append">
                        <button type="button" class="btn btn-icon btn-danger transition-3d-hover" (click)="removeDongleId(i)">
                          <span class="fas fa-trash btn-icon__inner"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="javascript:void(0)" (click)="addDongle()">Add Dongle</a>
                <hr />
                <div class="row form-btns">
                  <div class="col">
                    <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
                  </div>
                  <div class="col text-right">
                    <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Organization</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
      </li>
      <li *ngIf="this.organizationId" [ngbNavItem]="1">
        <a ngbNavLink>Coaches / Admins</a>
        <ng-template ngbNavContent>
          <div class="row align-items-center py-4">
            <div class="col-12 text-right">
              <a class="btn btn-primary" href="javascript:void(0)" (click)="addUser()"> New User</a>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-header border-0">
                  <h3 class="mb-0">Admin / Coaches</h3>
                </div>

                <div *ngIf="users" class="table-responsive">
                  <table class="table align-items-center table-flush mobile-table">
                    <thead class="thead-light">
                      <tr class="header-row">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody class="list">
                      <tr *ngFor="let user of users">
                        <th scope="row">
                          <div class="media align-items-center" style="cursor: pointer">
                            <a class="avatar rounded-circle mr-3" href="javascript:void(0)">
                              <img alt="Image placeholder" src="fetch-avatar?userId={{user.id}}" />
                            </a>

                            <div class="media-body">
                              <span class="name mb-0 text-sm"> {{ user.firstName }} {{ user.lastName }} </span>
                            </div>
                          </div>
                        </th>
                        <td>
                          <span class="mobile-grid-label">Email</span>
                          {{ user.email }}
                        </td>
                        <td>
                          <span class="mobile-grid-label">Role</span>
                          {{ user.userRole | flagEnumString: userRoleEnum }}
                        </td>
                        <td>
                          <span class="mobile-grid-label">Status</span>
                          {{ user.status | enumString: clientStatusEnum }}
                        </td>
                        <td>
                          <span class="mobile-grid-label">Actions</span>
                          <div class="btn-group no-caret">
                            <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"> </i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-lg-right">
                              <a href="javascript:void(0)" (click)="onImpersonateUser(user.id)" class="dropdown-item"> Impersonate User </a>
                              <a *ngIf="user.status == clientStatus.Invited" href="javascript:void(0)" (click)="onSendInvitation(user)" class="dropdown-item"> Re-send Invitation </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="card-footer py-4">
                  <nav aria-label="..."></nav>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="tabNav"></div>

  </div>
</div>

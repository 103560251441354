<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block mb-0">2D Assessment</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form class="form" [formGroup]="formGroup" novalidate>

    <div class="card">
      <div class="card-header">
        <h5  class="mb-0">Details</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="title"> Assessment Title </label>
              <input class="form-control" formControlName="title" maxlength="75" type="text" />
              <div class="invalid-message" *ngIf="formGroup?.controls['title'].errors && formGroup?.controls['title'].errors.required">
                <div>Title is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="row">
          <div class="col-12">
            <h5 class="mt-2">Videos (up to 4)</h5>
            <div formArrayName="videos" class="formGroupContainer" *ngFor="let video of formGroup.get('videos').controls; let v = index">
              <div class="row formGroupName image-border sortable-show-hover" [formGroupName]="v">
                <div class="form-group col-md-6">
                  <video-upload *ngIf="!video.get('id').value" formControlName="videoId" [label]="'Video ' + (v + 1)"></video-upload>
                  <div *ngIf="video.get('id').value" class="pt-2">
                    <embed-video-player [size]="'small'" [videoId]="video.get('videoId').value"></embed-video-player>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label class="form-control-label" for="description"> Video {{ v + 1 }} Description </label>
                  <input class="form-control right-icon-spacing" formControlName="description" maxlength="100" type="text" />
                  <a href="javascript:void(0)" (click)="onDeleteVideo(v)" class="right-icon">
                    <i class="fa fa-trash right-icon"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12 mb-3 link">
                <a class="" href="javascript:void(0)" (click)="onAddVideo()">Add Video</a>
              </div>
            </div>
            <div class="invalid-message-visible" *ngIf="formGroup?.controls['videos'].errors && formGroup?.controls['videos'].errors.maxlength">Cannot add more than 4 videos</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h5 class="mt-2">Images (up to 6)</h5>
            <div formArrayName="images" class="formGroupContainer" *ngFor="let image of formGroup.get('images').controls; let i = index">
              <div class="row formGroupName image-border sortable-show-hover" [formGroupName]="i">
                <div class="form-group col-md-6">
                  <image-upload-control *ngIf="!image.get('imageLocatorName').value" formControlName="imageLocatorName" [label]="'Image ' + (i + 1)"></image-upload-control>
                  <img *ngIf="image.get('imageLocatorName').value" class="image-size" src="fetchimage?filename={{ image.get('imageLocatorName').value }}" alt="Image" />
                </div>
                <div class="form-group col-md-6">
                  <label class="form-control-label" for="description"> Image {{ i + 1 }} Description </label>
                  <input class="form-control right-icon-spacing" formControlName="description" maxlength="100" type="text" />
                  <a href="javascript:void(0)" (click)="onDeleteImage(i)" class="right-icon">
                    <i class="fa fa-trash right-icon"></i>
                  </a>
                </div>
                <div class="form-group col-12">
                  <label class="form-control-label" for="notes"> Image {{ i + 1 }} Notes </label>
                  <textarea class="form-control" formControlName="notes" maxlength="300" type="text" ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12 mb-3 link">
                <a class="" href="javascript:void(0)" (click)="onAddImage()">Add Image</a>
              </div>
            </div>
            <div class="invalid-message-visible" *ngIf="formGroup?.controls['images'].errors && formGroup?.controls['images'].errors.maxlength">Cannot add more than 6 images</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0">General Parameters</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="generalCadence"> Cadence </label>
              <multi-option-control formControlName="generalCadence" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="generalBouncing"> Bouncing </label>
              <multi-option-control formControlName="generalBouncing" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="generalGroundContactTime"> Ground Contact Time </label>
              <multi-option-control formControlName="generalGroundContactTime" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="generalOther1"> Other </label>
              <div class="row">
                <div class="col-lg-5">
                  <input class="form-control" placeholder="please specify" (change)="onChangeOtherText('generalOther1Text')" formControlName="generalOther1Text" maxlength="50" type="text" />
                </div>
                <div class="col-lg-7">
                  <multi-option-control formControlName="generalOther1" (change)="onChangeOtherValue('generalOther1')" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="generalOther2"> Other </label>
              <div class="row">
                <div class="col-lg-5">
                  <input class="form-control" placeholder="please specify" (change)="onChangeOtherText('generalOther2Text')" formControlName="generalOther2Text" maxlength="50" type="text" />
                </div>
                <div class="col-lg-7">
                  <multi-option-control formControlName="generalOther2" (change)="onChangeOtherValue('generalOther2')" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label" for="notes"> Section Notes </label>
              <textarea class="form-control" formControlName="generalNotes" maxlength="300" type="text"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0">Initial Contact</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="initialFootPosition"> Foot Position </label>
              <multi-option-control formControlName="initialFootPosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="initialKneePosition"> Knee Position </label>
              <multi-option-control formControlName="initialKneePosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="initialHipPosition"> Hip Position </label>
              <multi-option-control formControlName="initialHipPosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="initialMalleolarLine"> Malleolar Line </label>
              <multi-option-control formControlName="initialMalleolarLine" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="initialHipSeparationAngle"> Hip Separation Angle </label>
              <multi-option-control formControlName="initialHipSeparationAngle" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="initialOther"> Other </label>
              <div class="row">
                <div class="col-lg-5">
                  <input class="form-control" placeholder="please specify" (change)="onChangeOtherText('initialOtherText')" formControlName="initialOtherText" maxlength="50" type="text" />
                </div>
                <div class="col-lg-7">
                  <multi-option-control formControlName="initialOther" (change)="onChangeOtherValue('initialOther')" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label" for="notes"> Section Notes </label>
              <textarea class="form-control" formControlName="initialNotes" maxlength="300" type="text"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0">Mid Stance</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="midFootPosition"> Foot Position </label>
              <multi-option-control formControlName="midFootPosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="midKneePosition"> Knee Position </label>
              <multi-option-control formControlName="midKneePosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="midHipPosition"> Hip Position </label>
              <multi-option-control formControlName="midHipPosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="midKneeWindow"> Knee Window </label>
              <multi-option-control formControlName="midKneeWindow" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="midPosture"> Posture </label>
              <multi-option-control formControlName="midPosture" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="midOther"> Other </label>
              <div class="row">
                <div class="col-lg-5">
                  <input class="form-control" placeholder="please specify" (change)="onChangeOtherText('midOtherText')" formControlName="midOtherText" maxlength="50" type="text" />
                </div>
                <div class="col-lg-7">
                  <multi-option-control formControlName="midOther" (change)="onChangeOtherValue('midOther')" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label" for="notes"> Section Notes </label>
              <textarea class="form-control" formControlName="midNotes" maxlength="300" type="text"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0">Swing</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="swingFootPosition"> Foot Position </label>
              <multi-option-control formControlName="swingFootPosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="swingKneePosition"> Knee Position </label>
              <multi-option-control formControlName="swingKneePosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="swingHipPosition"> Hip Position </label>
              <multi-option-control formControlName="swingHipPosition" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="swingPosture"> Posture </label>
              <multi-option-control formControlName="swingPosture" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="swingOther"> Other </label>
              <div class="row">
                <div class="col-lg-5">
                  <input class="form-control" placeholder="please specify" (change)="onChangeOtherText('swingOtherText')" formControlName="swingOtherText" maxlength="50" type="text" />
                </div>
                <div class="col-lg-7">
                  <multi-option-control formControlName="swingOther" (change)="onChangeOtherValue('swingOther')" [leftOption]="leftOption" [rightOption]="rightOption"></multi-option-control>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label" for="notes"> Section Notes </label>
              <textarea class="form-control" formControlName="swingNotes" maxlength="300" type="text"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-control-label" for="notes"> Running Gait Impairment Category </label>
          <select class="form-control" formControlName="impairmentCategory">
            <option [ngValue]="">N/A</option>
            <option [ngValue]="'Bouncer'">Bouncer</option>
            <option [ngValue]="'Collapser'">Collapser</option>
            <option [ngValue]="'GluteAmnesiac'">Glute Amnesiac</option>
            <option [ngValue]="'OverstriderGeneral'">Overstrider General</option>
            <option [ngValue]="'Weaver'">Weaver</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-control-label" for="notes"> Assessment Notes </label>
          <textarea class="form-control" formControlName="assessmentNotes" maxlength="750" type="text"></textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>
      <div class="col text-right">
        <button *ngIf="!assessmentId || assessment?.status == assessmentStatus.InProgress" (click)="onSave(true)" [promiseBtn]="submitComplete" class="btn btn-secondary">Save for Later</button>
        <button (click)="onSave(false)" [promiseBtn]="submitComplete" class="btn btn-primary">Finish</button>
      </div>
    </div>
  </form>
</div>

/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input} from '@angular/core';
import { localMeasurementDistanceName, localMeasurementElevationName } from 'src/app/_helpers/extensions.module';
import { ActivityType, ExternalWorkoutSummaryViewModelRead, MeasurementSystem } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ExternalWorkoutService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-workout-summary',
  templateUrl: 'external-workout-summary.component.html',
  styleUrls: ['external-workout-summary.component.scss']
})
export class ExternalWorkoutSummaryComponent implements OnInit {
  initialized = false;
  externalWorkout: ExternalWorkoutSummaryViewModelRead;
  activityType = ActivityType;
  localMeasurementName: string;
  localMeasurementElevationName: string;

  @Input()
  clientUserId: number;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  isSummaryOnly: boolean = false;


  constructor(private externalWorkoutService: ExternalWorkoutService, private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.localMeasurementName = localMeasurementDistanceName();
    this.localMeasurementElevationName = localMeasurementElevationName();
    const isMetricSystem = this.auth.getMeasurementSystem() == MeasurementSystem.Metric;
    this.externalWorkoutService.getExternalWorkoutSummary(this.clientProgramWorkoutDayId, isMetricSystem).subscribe(activity => {
      this.externalWorkout = activity;
      this.initialized = true;
    });
  }
}

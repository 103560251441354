<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="programs.length > 0" class="row no-gutters mb-3">
    <div class="col">
      <h3 class="mt-1 mb-3">Programs</h3>

      <div class="row">
        <div *ngFor="let program of programs" class="col-md-6 col-lg-4">
          <div class="card mb-3">
            <div class="card-header">
              {{ program.name }}
              <div *ngIf="!program.isActive" class="d-inline text-center" kendoTooltip>
                <i class="fas fa-info-circle ml-2" title="This program was purchased over a year ago, and can no longer be accessed. You can re-purchase the program in order to schedule it on your calendar again."></i>
              </div>
              <div *ngIf="program.isActive" class="float-right ">
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-dark dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a  href="javascript:void(0)" (click)="onAddProgramToCalendar(program.id, program.name, program.purchaseDate)" class="dropdown-item"> Add to Calendar </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <div class="">
                    <span class="font-weight-bold">Purchase Date: </span>
                    <span>{{ program.purchaseDate | date: 'shortDate' }}</span>
                  </div>
                  <div *ngIf="!program.isActive" class="mb-1">
                    <a href="{{program.paidProgramUrl}}" class="small-link" target="_blank"> Purchase again </a>
                  </div>
                  <div *ngIf="program.videoLink">
                    <embed-video-player [url]="program.videoLink" [size]="'small'"></embed-video-player>
                  </div>
                  <div *ngIf="program.videoId">
                    <embed-video-player [videoId]="program.videoId" [size]="'small'"></embed-video-player>
                  </div>
                  <div *ngIf="program.description" class="font-weight-bold">Description:</div>
                  <div [ngClass]="{'collapsed-description': program.id !== currentProgramId}">
                    <span [editorHTML]="program.description"></span>
                  </div>
                </div>
                <a *ngIf="program.description" class="small-link ml-2" (click)="onShowProgramDetails(program.id)" href="javascript:void(0)">
                  {{ program.id === currentProgramId ? 'Less...' : 'More...'}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Workouts</h3>
    </div>
  </div>

  <div *ngIf="clientWorkoutCategories.length === 0" class="mt-2">
    {{isCoachView ? 'Go to the Workouts Library to share workouts to your athlete\'s \'My Library\' list.' : 'No workouts have been shared by your coach.'}}
  </div>

  <accordion>
    <accordion-group *ngFor="let category of clientWorkoutCategories">
      <h5 accordion-heading>
        <div class="pull-left float-left">{{category.workoutCategory ? (category.workoutCategory | enumString: workoutCategoryEnum) : 'Other'}}</div>
        <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
      </h5>
      <div class="row">
        <div *ngFor="let workout of category.workouts" class="col-md-6 col-lg-4">
          <div class="card mb-2">
            <div class="card-header">
              <a class="header-link" (click)="onShowDetails(workout.id)" href="javascript:void(0)">
                {{ workout.name }}
              </a>
              <div class="float-right ">
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-dark dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a  href="javascript:void(0)" (click)="onRemoveWorkout(category.workoutCategory, workout.id)" class="dropdown-item"> Remove </a>
                    <a  href="javascript:void(0)" (click)="onAddWorkoutToCalendar(workout.id, workout.name)" class="dropdown-item"> Add to Calendar </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngIf="workout.workoutKeywords.length > 0" class="col-md-12">
                  <span class="font-weight-bold">Keywords: </span>
                  <span *ngFor="let keyword of workout.workoutKeywords; let last = last">
                    <span>{{ keyword.name }}{{ last ? '' : ', ' }}</span>
                  </span>
                </div>
                <div *ngIf="workout.goal" class="col-md-12">
                  <span class="font-weight-bold">Goal: </span>
                  <span [innerHTML]="workout.goal"></span>
                </div>
                <div *ngIf="workout.videoLink || workout.videoId" class="col-md-12" >
                  <embed-video-player [url]="workout.videoLink" [videoId]="workout.videoId" [size]="'small'"></embed-video-player>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </accordion-group>
  </accordion>


  <page-load-header *ngIf="!exercisesInitialized"></page-load-header>
  <div *ngIf="exercisesInitialized" class="row no-gutters mb-3 mt-3">
    <div class="col">
      <h3 class="mt-1 mb-3">Exercises</h3>
      <div *ngIf="exercises.length === 0" class="mt-2">
        {{isCoachView ? 'Go to the Exercises Library to share exercises to your athlete\'s \'My Library\' list.' : 'No exercises have been shared by your coach.'}}
      </div>

      <div *ngIf="exercises.length > 0" class="row">
        <div *ngFor="let exercise of exercises" class="col-md-6 col-lg-4">
          <div class="card mb-3">
            <div class="card-header">
              {{ exercise.name }}
              <span *ngIf="exercise.exerciseLevelDownId || exercise.exerciseLevelUpId" class="d-block d-md-inline">
                <a *ngIf="exercise.exerciseLevelDownId" class="ml-1 level-change" href="javascript:void(0)" (click)="exerciseLevelChange('down', exercise.exerciseLevelDownId, exercise.id)" title="Level Down"><i class="fas fa-arrow-circle-down"></i></a>
                <a *ngIf="exercise.exerciseLevelUpId" class="ml-1 level-change" href="javascript:void(0)" (click)="exerciseLevelChange('up', exercise.exerciseLevelUpId, exercise.id)" title="Level Up"><i class="fas fa-arrow-circle-up"></i></a>
              <div class="d-inline text-center" style="vertical-align: top;" kendoTooltip>
                <i class="fas fa-info-circle ml-2" title="Select Down to make this exercise easier, or select Up to make this exercise more challenging."></i>
              </div>
              </span>
              <div class="float-right ">
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-dark dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a  href="javascript:void(0)" (click)="onRemoveExercise(exercise.id)" class="dropdown-item"> Remove </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <embed-video-player *ngIf="exercise.videoLink && exercise.videoType == 2" [url]="exercise.videoLink" [size]="'small'"></embed-video-player>
                  <embed-video-player *ngIf="exercise.videoType == 1" [videoId]="exercise.videoId"></embed-video-player>
                  <div *ngIf="exercise.instructions">
                    <a (click)="toggleExerciseInstructions(exercise.id)" href="javascript:void(0)" class="toggle-link">{{ exercise.id == currentExerciseId ? 'Hide' : 'View' }} Instructions</a>
                  </div>
                  <ng-container *ngIf="exercise.id == currentExerciseId">
                    <div *ngIf="exercise.instructions" class="font-weight-bold">
                      Instructions:
                    </div>
                    <div>
                      <span [editorHTML]="exercise.instructions"></span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block mb-0">USAF Fitness Assessment</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div>
      <div class="row">
        <div class="col-md-12 col-lg-9 col-xl-7">
          <form class="form" [formGroup]="formGroup" novalidate>
            <div class="card mb-4">
              <div class="card-header"><h5 class="mb-0">Athlete Details</h5></div>

              <div class="card-body">
                <div class="container">
                  <div class="mr-auto">
                    <div class="row">
                      <div class="col"></div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Gender</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <select formControlName="gender" class="form-control">
                                <option value="">Please Choose...</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5 class="d-inline">Age</h5><span class="text-sm"> (at time of assessment)</span>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="age" [decimals]="'0'" [format]="'n0'" min="10" max="150" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-4">
              <div class="card-header"><h5 class="mb-0">Cardiorespiratory Fitness
                <button type="button" (click)="showHelp('usaf-run')" class="btn btn-sm btn-icon-only">
                  <span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span>
                </button>
              </h5></div>

              <div class="card-body">
                <div class="container">
                  <div class="mr-auto">
                    <div class="row">
                      <div class="col"></div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          Exercise
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <select class="form-control" formControlName="cardioExerciseType" (ngModelChange)="onCardioExerciseChanged($event)">
                                <option [ngValue]="null">(select one)</option>
                                <ng-container *ngFor="let mapEntry of cardioExerciseTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="formGroup.get('cardioExerciseType').value == cardioExerciseType.Run" class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          {{formGroup.get('cardioExerciseType').value | enumString: cardioExerciseTypeEnum}} Time
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <minutes-seconds-textbox formControlName="cardioValue"></minutes-seconds-textbox>
                              <div class="invalid-message">
                                <div>format must be h:mm:ss or m:ss</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="formGroup.get('cardioExerciseType').value != cardioExerciseType.Run" class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          {{formGroup.get('cardioExerciseType').value | enumString: cardioExerciseTypeEnum}} Count
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="cardioValue" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Cardio Points</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="cardioPoints" (valueChange)="onPointsChange($event)" [decimals]="'1'" [format]="'n1'" min="0" max="100" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Cardio Points Minimum Met?</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <multi-option-control formControlName="cardioMinimumMet" [leftOption]="trueOption" [rightOption]="falseOption"></multi-option-control>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-4">
              <div class="card-header"><h5 class="mb-0">Muscular Fitness 1
                <button type="button" (click)="showHelp('usaf-pushups')" class="btn btn-sm btn-icon-only">
                  <span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span>
                </button>
              </h5>
              </div>

              <div class="card-body">
                <div class="container">
                  <div class="mr-auto">
                    <div class="row">
                      <div class="col"></div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          Exercise
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <select class="form-control" formControlName="muscular1ExerciseType" (ngModelChange)="onMuscular1ExerciseChanged($event)">
                                <option [ngValue]="null">(select one)</option>
                                <ng-container *ngFor="let mapEntry of muscular1ExerciseTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          {{formGroup.get('muscular1ExerciseType').value | enumString: muscular1ExerciseTypeEnum}} Count
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="muscular1Value" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Muscular 1 Points</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="muscular1Points" (valueChange)="onPointsChange($event)" [decimals]="'1'" [format]="'n1'" min="0" max="100" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Muscular 1 Points Minimum Met?</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <multi-option-control formControlName="muscular1MinimumMet" [leftOption]="trueOption" [rightOption]="falseOption"></multi-option-control>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-4">
              <div class="card-header"><h5 class="mb-0">Muscular Fitness 2
                <button type="button" (click)="showHelp('usaf-situps')" class="btn btn-sm btn-icon-only">
                  <span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span>
                </button>
              </h5>
            </div>

              <div class="card-body">
                <div class="container">
                  <div class="mr-auto">
                    <div class="row">
                      <div class="col"></div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          Exercise
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <select class="form-control" formControlName="muscular2ExerciseType" (ngModelChange)="onMuscular2ExerciseChanged($event)">
                                <option [ngValue]="null">(select one)</option>
                                <ng-container *ngFor="let mapEntry of muscular2ExerciseTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="formGroup.get('muscular2ExerciseType').value == muscular2ExerciseType.ForearmPlank" class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>
                          Forearm Plank Time
                        </h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <minutes-seconds-textbox formControlName="muscular2Value"></minutes-seconds-textbox>
                              <div class="invalid-message">
                                <div>format must be h:mm:ss or m:ss</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="formGroup.get('muscular2ExerciseType').value != muscular2ExerciseType.ForearmPlank" class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>{{formGroup.get('muscular2ExerciseType').value | enumString: muscular2ExerciseTypeEnum}} Count</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="muscular2Value" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Muscular 2 Points</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="muscular2Points" (valueChange)="onPointsChange($event)" [decimals]="'1'" [format]="'n1'" min="0" max="100" type="number"></kendo-numerictextbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Muscular 2 Points Minimum Met?</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <multi-option-control formControlName="muscular2MinimumMet" [leftOption]="trueOption" [rightOption]="falseOption"></multi-option-control>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-4">
              <div class="card-header"><h5 class="mb-0">Assessment Summary</h5></div>

              <div class="card-body">
                <div class="container">
                  <div class="mr-auto">
                    <div class="row">
                      <div class="col"></div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Assessment Date</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <kendo-datepicker placeholder="" class="form-control" formControlName="dateTime"></kendo-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Composite Points</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              {{formGroup.get('compositePoints').value}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Composite Score Category</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <select class="form-control" formControlName="compositeScoreCategory">
                                <option [ngValue]="null">(select one)</option>
                                <ng-container *ngFor="let mapEntry of compositeScoreCategoryEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-6">
                        <h5>Health Risk Category</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <select class="form-control" formControlName="healthRiskCategory">
                                <option [ngValue]="null">(select one)</option>
                                <ng-container *ngFor="let mapEntry of healthRiskCategoryEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <h5>Notes:</h5>
              <textarea class="form-control" formControlName="notes" maxlength="250" type="text"></textarea>
            </div>
            <div class="float-right mt-2">
              <button (click)="onSave()" [promiseBtn]="submitComplete" class="btn btn-primary">Finish</button>
            </div>
          </form>
        </div>
        <div class="col-lg-6 d-sm-none"></div>
      </div>
    </div>
  </div>


</div>

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientProgramWorkoutViewModel } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-workout-level-change-modal',
  templateUrl: 'workout-level-change-modal.component.html',
  styleUrls: ['workout-level-change-modal.component.scss']
})
export class WorkoutLevelChangeModalComponent implements OnInit {
  initialized = false;
  includeCurrentWorkout: boolean = false;

  @Input()
  levelChangeWorkoutId: number;

  @Input()
  isLevelUp: boolean;

  @Input()
  currentClientProgramWorkoutDay: ClientProgramWorkoutViewModel;


  constructor(public activeModal: NgbActiveModal, private clientService: ClientsService, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.includeCurrentWorkout = !this.currentClientProgramWorkoutDay.isCompleted;
    this.initialized = true;
  }

  onLevelChange() {
    this.clientService.makeClientProgramWorkoutDayLevelChanges(this.currentClientProgramWorkoutDay.clientUserId, this.currentClientProgramWorkoutDay.id, this.levelChangeWorkoutId).subscribe(result => {
      this.toastr.success('Workouts Leveled ' + (this.isLevelUp ? 'Up' : 'Down'), 'Success');
      this.activeModal.dismiss('saved');
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationType, SurveyProgramAssignmentViewModel, SurveyResponseViewModel, SurveyResultViewModelRead, UserRole } from 'src/app/_models/generatedModels';
import { coachUserRoleArray, ProgramSummaryViewModelReadExtended } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ProgramsService, SurveyService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-runner-add-survey',
  templateUrl: 'runner-add-survey.component.html',
  styleUrls: ['runner-add-survey.component.scss']
})
export class RunnerAddSurveyComponent implements OnInit {
  initialized = false;
  surveyId: number;
  userId: number;
  surveyResult: SurveyResultViewModelRead;
  surveyResultPrograms: ProgramSummaryViewModelReadExtended[];
  submitComplete: Promise<{}> | undefined;
  isFinished: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthenticationService, private surveyService: SurveyService, private toastr: ToasterService, private programService: ProgramsService) { }

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.auth.fetchUserProfile().subscribe(user => {
      this.userId = user.id;

      // make sure the survey is valid for the org
      if ((!this.auth.hasClaimForRole([UserRole.Runner]) || !this.auth.hasClaimForRole(coachUserRoleArray)) && this.surveyId == 1) {
        this.router.navigate(['/']);
      }

      this.initialized = true;
    });
  }

  onSubmit(surveyResponse: SurveyResponseViewModel) {
    this.surveyService.createSurveyResponse(surveyResponse).subscribe(result => {
      this.surveyResult = result;
    });
  }

  onSurveyCalculatingFinished() {
    this.isFinished = true;
  }

  onAddPrograms(programAssignments: SurveyProgramAssignmentViewModel[]) {
    this.submitComplete = new Promise((resetButton: any, reject) => {
      this.programService.assignSurveyProgramsToClient(programAssignments).subscribe(result => {
        this.toastr.success('Programs Assigned', 'Success');
        this.router.navigate(['/runner/calendar']);
        resetButton();
      }, error => resetButton());
    });
  }
}

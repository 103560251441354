export class SortableList<T> implements Iterable<T> {
  private fullList: T[];
  private filteredList: T[];
  _isDesc: boolean = false;
  _column: string;
  _isDescIndex: boolean = false;
  _columnIndex: number;
  callbackfn: (this: void, value: T, index: number, array: T[]) => any;

  constructor(
    data: T[],
    initialSortedColumn: string,
    callbackfn: (this: void, value: T, index: number, array: T[]) => any = () => {
      return true;
    },
    isInitialSortedDesc: boolean = false
  ) {
    this.fullList = data;
    this.filteredList = data;
    this._column = initialSortedColumn;
    this.callbackfn = callbackfn;
    this._isDesc = isInitialSortedDesc;
    this.sort(initialSortedColumn);
  }

  public updateData(data: T[]) {
    this.fullList = data;
    this.filteredList = data;
    this._isDesc = !this._isDesc;
    this.sort(this._column);
  }

  [Symbol.iterator]() {
    let pointer = 0;
    let filteredList = this.filteredList;
    return {
      next(): IteratorResult<T> {
        return {
          done: pointer === filteredList.length,
          value: filteredList[pointer++],
        };
      },
    };
  }

  public sort(property: any) {
    this._isDesc = property == this._column ? !this._isDesc : this._isDesc; //change the direction if same property
    this._column = property;
    this._columnIndex = null;
    let direction = this._isDesc ? 1 : -1;

    let propertyArray = property.split('.');

    //handles up to 3 levels of object properties
    this.fullList.sort(function (a: any, b: any) {
      let aProperty = a[propertyArray[0]] == null || a[propertyArray[0]].length == 0 ? null : a[propertyArray[0]];
      let bProperty = b[propertyArray[0]] == null || b[propertyArray[0]].length == 0 ? null : b[propertyArray[0]];

      if (propertyArray.length > 1) {
        aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]] == null || a[propertyArray[0]][propertyArray[1]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]];
        bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]] == null || b[propertyArray[0]][propertyArray[1]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]];
      }
      if (propertyArray.length == 3) {
        aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]][propertyArray[2]];
        bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]][propertyArray[2]];
      }

      aProperty = typeof aProperty === 'string' ? aProperty.toLowerCase() : aProperty;
      bProperty = typeof bProperty === 'string' ? bProperty.toLowerCase() : bProperty;

      if (bProperty == null) {
        return 1 * direction;
      }
      if (aProperty == null) {
        return -1 * direction;
      } else if (aProperty < bProperty) {
        return -1 * direction;
      } else if (aProperty > bProperty) {
        return 1 * direction;
      } else {
        return 0;
      }
    });

    this.applyFilter(this.callbackfn);
  }

  public sortByIndex(arrayName: string, index: number, property: string) {
    this._isDescIndex = index == this._columnIndex ? !this._isDescIndex : this._isDescIndex; //change the direction if same property
    this._columnIndex = index;
    this._column = null;
    let direction = this._isDescIndex ? 1 : -1;

    this.fullList.sort(function (a: any, b: any) {
      let aProperty = (property == null ? a[arrayName][index] : a[arrayName][index][property]);
      let bProperty = (property == null ? b[arrayName][index] : b[arrayName][index][property]);

      aProperty = typeof aProperty === 'string' ? aProperty.toLowerCase() : aProperty;
      bProperty = typeof bProperty === 'string' ? bProperty.toLowerCase() : bProperty;

      if (bProperty == null) {
        return 1 * direction;
      }
      if (aProperty == null) {
        return -1 * direction;
      } else if (aProperty < bProperty) {
        return -1 * direction;
      } else if (aProperty > bProperty) {
        return 1 * direction;
      } else {
        return 0;
      }
    });

    this.applyFilter(this.callbackfn);
  }

  get length(): number {
    return this.fullList.length;
  }

  get filteredLength(): number {
    return this.filteredList.length;
  }

  get column(): string {
    return this._column;
  }

  get isDesc(): boolean {
    return this._isDesc;
  }

  indexOf(searchElement: T, fromIndex?: number): number {
    return this.fullList.indexOf(searchElement, fromIndex);
  }

  getCSSClass(columnName: string, none: string = 'sortable-none', desc: string = 'sortable-desc', asc: string = 'sortable-asc') {
    if (columnName != this._column) {
      return none;
    } else if (columnName == this._column && !this._isDesc) {
      return desc;
    } else if (columnName == this._column && this._isDesc) {
      return asc;
    }
  }

  getCSSClassIndex(index: number, none: string = 'sortable-none', desc: string = 'sortable-desc', asc: string = 'sortable-asc') {
    if (index != this._columnIndex) {
      return none;
    } else if (index == this._columnIndex && !this._isDescIndex) {
      return desc;
    } else if (index == this._columnIndex && this._isDescIndex) {
      return asc;
    }
  }

  applyFilter(callbackfn: (this: void, value: T, index: number, array: T[]) => any) {
    this.callbackfn = callbackfn;
    this.filteredList = this.fullList.filter(callbackfn);
  }

  clearFilter() {
    this.applyFilter((x) => true);
  }

  filter(callbackfn: (this: void, value: T, index: number, array: T[]) => any): T[] {
    return this.filteredList.filter(callbackfn);
  }

  remove(callbackfn: (this: void, value: T, index: number, array: T[]) => any) {
    let index = this.fullList.findIndex(callbackfn); //find index in your array
    this.fullList.splice(index, 1);

    let index2 = this.filteredList.findIndex(callbackfn); //find index in your array
    this.filteredList.splice(index2, 1);
  }

  add(value: T) {
    this.fullList.push(value);
    this.filteredList.push(value);
    this._isDesc = !this._isDesc;
    this.sort(this._column);
  }

  find(callbackfn: (this: void, value: T, index: number, array: T[]) => any): T {
    let index = this.fullList.findIndex(callbackfn); //find index in your array
    return this.fullList[index];
  }

  toArray(): T[] {
    return this.filteredList;
  }
}

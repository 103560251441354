
  <div class="card-body">
    <div>
      <div *ngFor="let workout of day.events" class="timeline-block">
        <span *ngIf="(workout.workout || workout.eventType == eventType.QuickWorkout) && !workout.isCompleted" class="timeline-step badge-secondary"></span>
        <span *ngIf="(workout.workout || workout.garminActivityId || workout.stravaActivityId || workout.eventType == eventType.ManualWorkout || workout.eventType == eventType.QuickWorkout) && workout.isCompleted" class="timeline-step badge-success"><i class="fas fa-check"></i></span>
        <span *ngIf="workout.eventType == eventType.RestDay" class="timeline-step badge-secondary"><i class="far fa-bed"></i></span>
        <span *ngIf="workout.eventType == eventType.Event" class="timeline-step badge-danger"><i class="fas fa-flag-checkered"></i></span>
        <span *ngIf="workout.eventType == eventType.Task && !workout.isCompleted" class="timeline-step badge-secondary"><i class="far fa-list-alt"></i></span>
        <span *ngIf="workout.eventType == eventType.Task && workout.isCompleted" class="timeline-step badge-secondary"><i class="far fa-check-square"></i></span>
        <div class="timeline-content">
          <div class="d-flex justify-content-between pt-1">
            <div>
              <span *ngIf="workout.coachNotes && workout.eventType == eventType.RestDay" class="mr-1"><i class="far fa-sticky-note"></i></span>
              <span class="text-muted text-sm font-weight-bold">{{ workout.workoutName}}</span>
              <!-- <h6 *ngIf="workout.workout" class="text-sm mt-1 mb-0">{{ workout.workout.goal }}</h6> -->
              <div *ngIf="workout.miles || workout.activityTypeDescription" class="text-sm mb-0">
                <span *ngIf="workout.miles">{{(workout.miles | fromMilesToLocalMeasurement) | number : '1.2-2'}} {{localMeasurementName}}</span>
                <span *ngIf="workout.activityTypeDescription"> {{workout.activityTypeDescription}}</span>
              </div>
              <div *ngIf="workout.timeInSeconds || workout.ratePerceivedExertion" class="text-sm mb-0">
                <span *ngIf="workout.timeInSeconds">{{workout.timeInSeconds | secsToHourMinSec}}</span>
                <span *ngIf="workout.ratePerceivedExertion" class="text-sm mb-0 ml-2">RPE: {{workout.ratePerceivedExertion}}</span>
              </div>
            </div>
            <div class="text-right">
              <small *ngIf="workout.contextMenuOptions | flagEnumCompare:contextMenuOptions.View" class="text-muted"><a href="javascript:void(0)" (click)="onViewWorkout(workout)"> View </a></small>
              <small *ngIf="workout.contextMenuOptions | flagEnumCompare:contextMenuOptions.Edit" class="text-muted d-block mt-1"><a href="javascript:void(0)" (click)="onEditWorkout(workout)"> Edit </a></small>
              <!-- <small *ngIf="workout.contextMenuOptions | flagEnumCompare:contextMenuOptions.Copy" class="text-muted d-block mt-1"><a href="javascript:void(0)" (click)="onCopyWorkout(workout)"> Copy </a></small> -->
              <small *ngIf="workout.contextMenuOptions | flagEnumCompare:contextMenuOptions.ToggleComplete" class="text-muted d-block mt-1"><a href="javascript:void(0)" (click)="onToggleComplete(workout)">Mark {{workout.isCompleted ? 'Incomplete' : 'Complete'}}</a></small>
              <small *ngIf="workout.contextMenuOptions | flagEnumCompare:contextMenuOptions.Delete" class="text-muted d-block mt-1"><a href="javascript:void(0)" (click)="onDeleteWorkout(workout)"> Delete </a></small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

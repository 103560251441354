import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { MinutesDecimalToStringPipe } from 'src/app/_helpers/extensions.module';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { ExternalWorkoutService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-stride-length-chart',
  templateUrl: 'stride-length-chart.component.html',
})
export class ExternalStrideLengthChartComponent implements OnInit, OnDestroy {
  initialized = false;
  hasData: boolean = true;

  @Input()
  clientProgramWorkoutDayId: number;


  constructor(private charting: ChartingService, private externalWorkoutService: ExternalWorkoutService, private minutesDecimalToStringPipe: MinutesDecimalToStringPipe) {}

  ngOnInit(): void {
    const title = 'Stride Length (m)';
    const chartHeaders: any[] = [{ type: 'timeofday', id: 'Time', label: 'Time' }, { type: 'number', id: 'Stride Length', label: title }];

    this.externalWorkoutService.getStrideLengthChartData(this.clientProgramWorkoutDayId).subscribe(result => {
      this.hasData = result.length > 0;

      this.charting.queueCharts(
        this.charting.drawScatterChart.bind(this.charting),
        new ChartData(
            'strideLengthChart',
            chartHeaders,
            result.map(x => [x.timerDuration, x.value]),
            title
        )
      );
      this.initialized = true;
    });
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

<div *ngIf="initialized">
  <div class="card">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">{{ clientProgramWorkoutDayId ? 'Edit' : 'Add' }} Manual Workout</h3>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="workoutDate"> Workout Date </label>
                <kendo-datepicker placeholder="" class="form-control" formControlName="workoutDate"></kendo-datepicker>
                <div class="invalid-message">
                  <div>Workout date is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="activityType"> Workout Type </label>
                <select class="form-control" formControlName="activityType">
                  <option [ngValue]="null">(select one)</option>
                  <ng-container *ngFor="let mapEntry of activityTypeEnum | keyvalue">
                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
                <div *ngIf="formGroup.controls['activityType'].dirty && formGroup.controls['activityType'].errors?.required" class="invalid-message-visible">
                  <div>Workout Type is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="duration"> Duration </label>
                <minutes-seconds-textbox formControlName="duration"></minutes-seconds-textbox>
                <div class="invalid-message">
                  <div>format must be h:mm:ss or m:ss</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="rpe"> Rate of Perceived Exertion </label>
                <div [ngClass]="{ 'ng-invalid ng-touched': showRPEError }" class="btn-group checkbox-buttons rpe">
                  <ng-container *ngFor="let score of RPEOptions">
                    <label [ngClass]="{ 'active btn-primary': score.selected, 'btn-secondary': !score.selected }" class="btn btn-narrow"> <input type="checkbox" [(ngModel)]="score.selected" (ngModelChange)="onRPEChanged(score.id)" [ngModelOptions]="{ standalone: true }" />{{ score.displayName }} </label>
                  </ng-container>
                </div>
                <div *ngIf="showRPEError" class="invalid-message-visible">
                  <div>Rate of Perceived Exertion is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="form-control-label" for="distance"> Distance (miles)</label>
                <kendo-numerictextbox noScroll class="form-control" formControlName="distance" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
            </div>
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Workout</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

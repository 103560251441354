<div *ngIf="initialized">
  <div class="card">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Workout Details</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body p-0">
        <runner-workout-view [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [clientUserId]="clientUserId" (saved)="workoutSaved()" (syncedWorkoutNameUpdated)="onSyncedWorkoutNameUpdated()" [isCoachView]="isCoachView" [isSummaryOnly]="true"></runner-workout-view>
      </div>
    </div>
  </div>
</div>




<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="card-body">
    <div class="row mt-3">
      <div class="col">

        <athlete-calendar [clientUserId]="clientUserId" [hasCoachRole]="true" [isCoachView]="!isSelf"></athlete-calendar>

      </div>
    </div>
  </div>
</div>



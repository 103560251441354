import { Component, OnInit, Input } from '@angular/core';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { ExerciseProgressionViewModelRead } from 'src/app/_models/generatedModels';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExercisePreviewComponent } from '../../shared/exercise-preview/exercise-preview.component';

@Component({
  selector: 'app-exercise-progression-preview',
  templateUrl: 'exercise-progression-preview.component.html',
})
export class ExerciseProgressionPreviewComponent implements OnInit {
  initialized = false;
  progression: ExerciseProgressionViewModelRead;

  @Input()
  exerciseProgressionId: number;

  constructor(private exerciseData: ExerciseService, public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.exerciseData.getExerciseProgressionById(this.exerciseProgressionId).subscribe((result) => {
      this.progression = result;
      this.initialized = true;
    });
  }

  onExercisePreview(exerciseId: number) {
    const modalRef = this.modalService.open(ExercisePreviewComponent, { size: 'lg' });
    modalRef.componentInstance.exerciseId = exerciseId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

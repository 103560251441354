import moment from 'moment-timezone';
import { CalendarWeekActivityTypeDurationViewModel, CalendarEventViewModel, ClientDayEventType, EventActivityTypeDurationViewModel, ProgramWorkoutDayViewModelRead, RaceDistanceType, RecurringEventViewModelRead, UserRole, TrainingLoadPeriodType, WorkoutItemDisplayViewModelRead, CalendarContextMenuOptions, ProgramSummaryViewModelRead, ActivityType, ClientProgramWorkoutDayExerciseProgressViewModel } from './generatedModels';

export class PagedResult <T>
{
	public currentPage: number;
  public firstRowOnPage: number;
  public lastRowOnPage: number;
  public pageCount: number;
  public pageSize: number;
  public results: T[];
  public rowCount: number;
}

export class MinutesSeconds {
  minutes: number;
  seconds: number;

  constructor(minutes: number, seconds: number) {
      this.minutes = minutes;
      this.seconds = seconds;
  }
}

export class HoursMinutesSeconds {
  hours: number;
  minutes: number;
  seconds: number;

  constructor(hours: number, minutes: number, seconds: number) {
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
  }
}

export class ProgramDay {
  weekDayNumber: number;
  workoutDays: ProgramWorkoutDayViewModelRead[];

  constructor(weekDayNumber: number) {
    this.weekDayNumber = weekDayNumber;
    this.workoutDays = [];
  }
}

export class ProgramWeek {
  programId: number;
  id: number;
  weekNumber: number;
  programDays: ProgramDay[];
  activityTypeDurations: CalendarWeekActivityTypeDurationViewModel[];
  trainingLoadPeriodType?: TrainingLoadPeriodType;
  scheduledTrainingLoad: number = 0;
  scheduledRPE1To4Load: number = 0;
  scheduledRPE5To6Load: number = 0;
  scheduledRPE7To8Load: number = 0;
  scheduledRPE9To10Load: number = 0;
  suggestedTrainingLoad: number;
  trainingLoadLow: number;
  trainingLoadHigh: number;
  rpE1To4Load: number;
  rpE5To6Load: number;
  rpE7To8Load: number;
  rpE9To10Load: number;

  constructor(weekNumber: number) {
    this.weekNumber = weekNumber;
    this.programDays = [];
    for (let index = 0; index < 7; index++) {
      this.programDays.push(new ProgramDay(index + 1));
    }
    this.activityTypeDurations = [];
  }
}

export class ProgramWeekExtended extends ProgramWeek {
  isExpanded: boolean;
}

export class ProgramWorkoutDayCopy {
  weekNumber: number;
  weekDayNumber: number;
  copyOnly: boolean;

  constructor(weekNumber: number, weekDayNumber: number, copyOnly: boolean) {
    this.weekNumber = weekNumber;
    this.weekDayNumber = weekDayNumber;
    this.copyOnly = copyOnly;
  }
}

export class CalendarWorkoutEvent {
  eventId: number | null;
  workoutId: number | null;
  workoutDate: Date;
  sortOrder: number;
  eventName: string;
  isCompleted: boolean;
  coachNotes: string;
  taskDescription: string;
  eventType: ClientDayEventType;
  recurringEvent: RecurringEventViewModelRead;
  activityTypeDurations: EventActivityTypeDurationViewModel[];
  ratePerceivedExertion: number | null;
  quickWorkoutName: string;
  quickWorkoutDescription: string;
	quickWorkoutActivityType?: ActivityType;
	quickWorkoutDuration?: number;
	quickWorkoutDistance?: number;

  constructor(eventId: number | null, workoutId: number | null, workoutDate: Date, sortOrder: number, eventName: string, isCompleted: boolean, coachNotes: string, taskDescription: string,
    eventType: ClientDayEventType, recurringEvent: RecurringEventViewModelRead, activityTypeDurations: EventActivityTypeDurationViewModel[], ratePerceivedExertion: number | null,
    quickWorkoutName: string, quickWorkoutDescription: string, quickWorkoutActivityType?: ActivityType, quickWorkoutDuration?: number, quickWorkoutDistance?: number) {
    this.eventId = eventId;
    this.workoutId = workoutId;
    this.workoutDate = workoutDate;
    this.sortOrder = sortOrder;
    this.eventName = eventName;
    this.isCompleted = isCompleted;
    this.coachNotes = coachNotes;
    this.taskDescription = taskDescription;
    this.eventType = eventType;
    this.recurringEvent = recurringEvent;
    this.activityTypeDurations = activityTypeDurations;
    this.ratePerceivedExertion = ratePerceivedExertion;
    this.quickWorkoutName = quickWorkoutName;
    this.quickWorkoutDescription = quickWorkoutDescription;
    this.quickWorkoutActivityType = quickWorkoutActivityType;
    this.quickWorkoutDuration = quickWorkoutDuration;
    this.quickWorkoutDistance = quickWorkoutDistance;
  }
}

export class BasicObject {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class BasicDonutPieData {
  name: string;
  value: number;
  color: string;
  percentage: number;
  dashoffset: number;

  constructor(name: string, value: number, color: string) {
      this.name = name;
      this.value = value;
      this.color = color;
  }
}

export class CheckboxOption {
  name: string;
  displayName: string;
  id: number;
  selected: boolean;

  constructor(name: string, displayName: string, id: number, selected: boolean) {
      this.name = name;
      this.displayName = displayName;
      this.id = id;
      this.selected = selected;
  }
}

export class CalendarParameters {
  selectedDate: Date;
  today: Date;
  isMonthView: boolean;

  constructor(selectedDate: Date, today: Date, isMonthView: boolean) {
    this.selectedDate = selectedDate;
    this.today = today;
    this.isMonthView = isMonthView;
  }
}

export class CalendarAddEventParameters {
  selectedDate: Date;
  maxExistingSortOrder: number;

  constructor(selectedDate: Date, maxExistingSortOrder: number) {
    this.selectedDate = selectedDate;
    this.maxExistingSortOrder = maxExistingSortOrder;
  }
}

export class CalendarDraggedEventParameters {
  newEventIndex: number;
  eventDate: Date;
  draggedEvent: CalendarEventViewModel;
  isCopyAndDrag: boolean;

  constructor(newEventIndex: number, eventDate: Date, draggedEvent: CalendarEventViewModel, isCopyAndDrag: boolean) {
    this.newEventIndex = newEventIndex;
    this.eventDate = eventDate;
    this.draggedEvent = draggedEvent;
    this.isCopyAndDrag = isCopyAndDrag;
  }
}

export class CalendarEventActionParameters {
  menuOption: CalendarContextMenuOptions;
  event: CalendarEventViewModel;

  constructor(menuOption: CalendarContextMenuOptions, event: CalendarEventViewModel) {
    this.menuOption = menuOption;
    this.event = event;
  }
}

export class ProgramSummaryViewModelReadExtended extends ProgramSummaryViewModelRead {
  isExpandedDescription: boolean;
  programStartDate: Date;
}

export class ExerciseLevelChangedParameters {
  workoutItemId: number;
  workoutItemDetailsId: number;
  exerciseProgress: ClientProgramWorkoutDayExerciseProgressViewModel;

  constructor(workoutItemId: number, workoutItemDetailsId: number, exerciseProgress: ClientProgramWorkoutDayExerciseProgressViewModel) {
    this.workoutItemId = workoutItemId;
    this.workoutItemDetailsId = workoutItemDetailsId;
    this.exerciseProgress = exerciseProgress;
  }
}

export const raceDistanceTypeValues = [
  { raceDistanceType: RaceDistanceType.FiveK, raceDistanceValue: 3.1},
  { raceDistanceType: RaceDistanceType.TenK, raceDistanceValue: 6.2},
  { raceDistanceType: RaceDistanceType.HalfMarathon, raceDistanceValue: 13.1},
  { raceDistanceType: RaceDistanceType.Marathon, raceDistanceValue: 26.2},
  { raceDistanceType: RaceDistanceType.Custom, raceDistanceValue: null},
]

export const coachUserRoleArray: UserRole[] =
  [UserRole.Administrator, UserRole.AssistantCoach, UserRole.GlobalCoach, UserRole.HeadCoach];

  export const coachUserRole: UserRole = UserRole.Administrator | UserRole.AssistantCoach | UserRole.GlobalCoach | UserRole.HeadCoach;

  export const KILOMETERS_PER_MILE = 0.621371192;

  export const hammerAndAxeSlug = 'hammer-and-axe';

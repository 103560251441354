<div *ngIf="initialized">
  <div class="card">
    <div class="bg-dark">
      <header class="card-header py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Workout Details</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body p-0">
        <div class="container mt-3">
          <workout-view [isWorkoutPreview]="true" [isCoachView]="true" [workoutId]="workoutId"></workout-view>
        </div>
      </div>
    </div>
  </div>
</div>




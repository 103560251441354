import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter  } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SuperUserService } from '../_services/generatedServices';
import { AuthenticationService } from '../_services/authentication.service';
import { ProgramListViewModelRead } from '../_models/generatedModels';
import { dynamicSort } from '../_helpers/extensions.module';


@Component({
    selector: 'superuser-programs-dropdown',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <kendo-combobox #combo [data]="comboData" [disabled]="isDisabled" [valuePrimitive]="true" [filterable]="true" [popupSettings]="{popupClass: 'df-list-scroller'}" valueField="id" textField="name" [(ngModel)]="selectedValue" class="{{cssClass}}" (filterChange)="handleFilter($event)" (valueChange)="onChange($event)"></kendo-combobox>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SuperuserProgramDropdownControl),
            multi: true
        }
    ]
})

export class SuperuserProgramDropdownControl implements ControlValueAccessor, AfterContentInit {

    someAttr: string = 'anyValue';

    constructor(elementRef: ElementRef, private data: SuperUserService, private auth: AuthenticationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    selectedValue: string = '';

    @Input()
    isDisabled: boolean;

    @Output()
    changed = new EventEmitter<string>();

    @Output()
    changedObject = new EventEmitter<ProgramListViewModelRead>();

    comboData: ProgramListViewModelRead[];
    rawData: ProgramListViewModelRead[];
    @ViewChild('combo') public combo: ComboBoxComponent | undefined;
    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: string): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.changedObject.emit(this.rawData.find(x => value === x.id));
        this.changed.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngAfterContentInit(): void {
        this.data.getAllGlobalPrograms().subscribe(results => {
            this.rawData = results.sort(dynamicSort('name'));
            this.comboData = results.sort(dynamicSort('name'));
        });
    }

    handleFilter(value: any) {
        if (value.length >= 1) {
            this.comboData = this.rawData.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
            this.comboData = this.rawData;
            this.combo.toggle(false);
        }
    }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ClientsService } from 'src/app/_services/generatedServices';
import { WorkoutItemType, ClientProgramWorkoutDayItemViewModel, WorkoutItemDisplayViewModelRead } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'workout-item-view',
  templateUrl: 'workout-item-view.component.html',
  styleUrls: ['workout-item-view.component.scss']
})
export class WorkoutItemViewComponent implements OnInit {
  initialized = false;
  workoutItemType = WorkoutItemType;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  workoutItem: WorkoutItemDisplayViewModelRead;

  @Input()
  disabled: boolean;

  @Input()
  isWorkoutPreview: boolean = false;

  @Input()
  summaryOnly: boolean = false;

  @Output()
  toggleCompleteChanged = new EventEmitter<ClientProgramWorkoutDayItemViewModel>();

  constructor(private clientService: ClientsService) { }

  ngOnInit(): void {
    this.initialized = true;
  }

  toggleComplete(event: any) {
    const model: ClientProgramWorkoutDayItemViewModel = {
      clientProgramWorkoutDayId: this.clientProgramWorkoutDayId,
      workoutItemId: this.workoutItem.id,
      isCompleted: this.workoutItem.isCompleted,
      completedDate: (this.workoutItem.isCompleted ? new Date() : null)
    }

    this.clientService.addUpdateClientProgramWorkoutDayItem(model).subscribe(result => {
      this.toggleCompleteChanged.emit(model);
    });
  }
}

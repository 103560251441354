<div *ngIf="initialized && hasCoachRole" class="header pb-2">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-md-8 col-4">
            <h6 class="h2 d-inline-block mb-0">Me</h6>
  
            <rundna-breadcrumbs></rundna-breadcrumbs>
  
          </div>
        </div>
  
        <div class="flex-column flex-md-row tab-container" type="pills">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/runner/dashboard']" [routerLinkActive]="'active'"><i class="fas fa-home mr-2"></i>Dashboard </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/runner/calendar']" [routerLinkActive]="'active'"><i class="far fa-calendar-alt mr-2"></i>Calendar </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/runner/my-library']" [routerLinkActive]="'active'"><i class="far fa-star mr-2"></i>My Library </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/runner/assessments']" [routerLinkActive]="'active'"><i class="far fa-object-ungroup mr-2"></i>Assessments </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/runner/plans']" [routerLinkActive]="'active'"><i class="far fa-address-card mr-2"></i>Plans </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/runner/journal']" [routerLinkActive]="'active'"><i class="far fa-book-open mr-2"></i>Journal </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <alert *ngIf="initialized && !hasCoachRole && auth.user && auth.user.hasActiveCoachAthleteOrg" class="text-center" type="info" [dismissible]="true">
    To access coach functionality, switch to the organization where you are a coach.
  </alert>
  
  <div class="container-fluid runner-container">
    <router-outlet></router-outlet>
  </div>
<page-load-header *ngIf="!initialized"></page-load-header>

<ng-container *ngIf="initialized">
  <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
    <div>
      <div class="card mb-4">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col">
              <h3 class="mb-0">Add Athlete</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-control-label" for="example3cols1Input"> Email </label>

                <input class="form-control" formControlName="email" placeholder="Athlete's email" type="text" />
                <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.required">
                  <div>Email Address is required</div>
                </div>
                <div class="invalid-message" *ngIf="duplicateEmailError && formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.emailInOrg">
                  <div>This email address already exists in your organization. {{duplicateEmailError}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="isStep2 || isPendingConfirm" class="col-md-4">
              <div class="form-group">
                <label class="form-control-label d-block" for="basic-url">Teams</label>
                <div class="input-group mb-3">
                  <teams-multiselect formControlName="teamIds"></teams-multiselect>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="isStep2">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="example3cols2Input"> First Name </label>
  
                  <input class="form-control" formControlName="firstName" placeholder="John" type="text" />
                  <div class="invalid-message">
                    <div>First Name is required</div>
                  </div>
                </div>
              </div>
  
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="example3cols3Input"> Last Name </label>
  
                  <input class="form-control" formControlName="lastName" placeholder="Smith" type="text" />
                  <div class="invalid-message">
                    <div>Last Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="example3cols1Input"> Gender </label>
                  <select formControlName="gender" class="form-control">
                    <option value="">Please Choose...</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <div class="invalid-message">
                    <div>Gender is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label d-block" for="yearOfBirth"> Year of Birth </label>
                  <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="yearOfBirth" [decimals]="'0'" [format]="'####'" min="1900" max="2900" type="number"></kendo-numerictextbox>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="isOfAge"> Athlete is at least 14 years old </label>
                  <input class="d-block single-checkbox" formControlName="isOfAge" type="checkbox" />
                  <div class="invalid-message p-1">
                    <div>Field is required</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          
          <div *ngIf="!isStep2 && !isPendingConfirm" class="row">
            <div class="col">
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-primary" (click)="onNext()">Next</button>
            </div>
          </div>
          <div *ngIf="isPendingConfirm" class="row">
            <div class="col-12 p-2">
              Are you sure you want to invite this existing user to your organization? They will be listed under Invited until they accept.
            </div>
            <div class="col mt-1">
              <button type="button" class="btn btn-secondary" (click)="onCancelPending()">Cancel</button>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-primary" [promiseBtn]="submitComplete" (click)="onConfirmPending()">Add Athlete</button>
            </div>
          </div>
          <div *ngIf="isStep2" class="row">
            <div class="col">
            </div>
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Add Athlete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

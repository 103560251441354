import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import { ClientProgramWorkoutDayItemViewModelRead, ExerciseViewModelRead, WorkoutDisplayViewModelRead } from 'src/app/_models/generatedModels';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'exercise-view',
  templateUrl: 'exercise-view.component.html',
  styleUrls: ['exercise-view.component.scss']
})
export class ExerciseViewComponent implements OnInit {
  initialized = false;
  exercise: ExerciseViewModelRead;

  @Input()
  exerciseId: number;

  @Input()
  isPrintVersion: boolean = false;

  @Output()
  saved = new EventEmitter<boolean>();

  constructor(private exerciseService : ExerciseService, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.exerciseService.getExerciseById(this.exerciseId).subscribe((result) => {
      this.exercise = result;
      this.initialized = true;
    });
  }
}

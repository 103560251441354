import { Component, Input, AfterContentInit, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as customBuild from 'src/ckCustomBuild/ckeditor';
import { CKEditorUploadAdapter } from '../_helpers/ckEditor-upload-adapter';
import { AuthenticationService } from '../_services/authentication.service';


@Component({
    selector: 'custom-editor',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <ckeditor [editor]="Editor" [config]="htmlEditorConfig" [(ngModel)]="selectedValue" (ready)="onReady($event)" (change)="onChange($event)" [disabled]="disabled" style="width: 100%"></ckeditor>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomEditorControl),
            multi: true
        }
    ]
})

export class CustomEditorControl implements ControlValueAccessor, AfterContentInit {

    someAttr: string = 'anyValue';
    
    selectedValue: any;

    public Editor = customBuild;

    htmlEditorConfig = {
        toolbar: {
          items: ['bold', 'italic', 'underline', 'link', 'alignment', 'bulletedList', 'numberedList', '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'insertImage'],
          shouldNotGroupWhenFull: false,
        },
        fontSize: {
          options: [9, 11, 13, 'default', 17, 19, 21],
        },
        mediaEmbed: {
          previewsInData: true,
        },
      };

    constructor(private elementRef: ElementRef, private auth: AuthenticationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }
    

    @Input()
    disabled: boolean;


    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: any): void {
      this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        //this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngAfterContentInit(): void {
    }

    onReady(editor): void {       
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
          return new CKEditorUploadAdapter(loader, this.auth.getToken());
        };
        editor.editing.view.change((writer) => {
          writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
        });
      }
}

import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CalendarEventActionParameters } from 'src/app/_models/models';
import { CalendarContextMenuOptions, CalendarDayViewModel, CalendarEventViewModel, ClientDayEventType } from '../../_models/generatedModels';
import { localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'calendar-day-details',
  templateUrl: 'calendar-day-details.component.html',
  styleUrls: ['calendar-day-details.component.scss'],
})
export class CalendarDayDetailsComponent implements OnInit, OnChanges {
  initialized = false;
  selectedWorkoutDay: number;
  eventType = ClientDayEventType;
  contextMenuOptions = CalendarContextMenuOptions;
  localMeasurementName: string;

  @Input()
  day: CalendarDayViewModel;

  @Output()
  actionTaken = new EventEmitter<CalendarEventActionParameters>();

  constructor() {}

  ngOnInit() {
    this.localMeasurementName = localMeasurementDistanceName();
  }

  ngOnChanges(): void {
    this.initialized = true;
  }

  onEditWorkout(event: CalendarEventViewModel) {
    const params = new CalendarEventActionParameters(CalendarContextMenuOptions.Edit, event);
    this.actionTaken.emit(params);
  }

  onCopyWorkout(event: CalendarEventViewModel) {
    const params = new CalendarEventActionParameters(CalendarContextMenuOptions.Copy, event);
    this.actionTaken.emit(params);
  }

  onViewWorkout(event: CalendarEventViewModel) {
    const params = new CalendarEventActionParameters(CalendarContextMenuOptions.View, event);
    this.actionTaken.emit(params);
  }

  onToggleComplete(event: CalendarEventViewModel) {
    const params = new CalendarEventActionParameters(CalendarContextMenuOptions.ToggleComplete, event);
    this.actionTaken.emit(params);
  }

  onDeleteWorkout(event: CalendarEventViewModel) {
    const params = new CalendarEventActionParameters(CalendarContextMenuOptions.Delete, event);
    this.actionTaken.emit(params);
  }
}

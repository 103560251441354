import { Component, OnInit, Input} from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import { AssessmentService, ClientsService } from 'src/app/_services/generatedServices';
import { AssessmentListViewModelRead, AssessmentType, AssessmentStatus, OrganizationType, ImpairmentCategory } from 'src/app/_models/generatedModels';
import { AssessmentProgramDialogComponent } from '../assessment-program-assignment/assessment-program-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingLevelsProgramDialogComponent } from '../loading-levels-program-assignment/loading-levels-program-dialog.component';
import { AssessmentNotesDialogComponent } from '../assessment-notes-dialog/assessment-notes-dialog.component';
import { TwoDimensionalProgramDialogComponent } from '../two-dimensional-program-assignment/two-dimensional-program-dialog.component';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { coachUserRoleArray } from 'src/app/_models/models';
import { RunningCategoryChangeDialogComponent } from '../running-category-change-dialog/running-category-change-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentPDFDialogComponent } from '../assessment-pdf-dialog/assessment-pdf-dialog.component';
import { GaitAnalysisUpdateDialogComponent } from '../gait-analysis-update-dialog/gait-analysis-update-dialog.component';

@Component({
  selector: 'assessment-list-view',
  templateUrl: 'assessment-list-view.component.html',
  styleUrls: ['assessment-list-view.component.scss']
})
export class AssessmentListViewComponent implements OnInit {
  initialized = false;
  assessments: AssessmentListViewModelRead[];
  assessmentTypeEnum = Enums.AssessmentTypeEnum;
  assessmentType = AssessmentType;
  movementCategoryEnum = Enums.MovementCategoryEnum;
  AssessmentStatus = AssessmentStatus;
  AssessmentStatusEnum = Enums.AssessmentStatusEnum;
  organizationType = OrganizationType;
  hasUSAFFitnessAssessment: boolean = false;
  hasCoachRole: boolean = false;
  currentImpairmentCategory: ImpairmentCategory;
  impairmentCategoryEnum = Enums.ImpairmentCategoryEnum;
  clientFullName: string;

  @Input()
  userId: number;

  @Input()
  isCoachView: boolean = false;

  constructor(private assessmentData: AssessmentService, private modalService: NgbModal, private toastr: ToasterService, private auth: AuthenticationService,
    private clientsService: ClientsService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {

    this.getData();

    this.auth.fetchUserProfile().subscribe(user => {
      if (user.organizationType & this.organizationType.HasUSAFFitnessAssessment) {
        this.hasUSAFFitnessAssessment = true;
      }
      this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);
    });
  }

  getData() {
    this.assessmentData.getAllAssessmentsForUser(this.userId).subscribe(result => {
      this.assessments = result;
      this.getClientDetails();
    });
  }

  getClientDetails() {
    this.clientsService.getClientDetailsByID(this.userId).subscribe(result => {
      this.currentImpairmentCategory = result.currentImpairmentCategory;
      this.clientFullName = result.fullName;
      this.initialized = true;
    });
  }

  onViewAssessment(assessmentId: number, assessmentType: AssessmentType) {
    let urlPrefix = '../';

    if (assessmentType == this.assessmentType.RRA) {
      this.router.navigate([urlPrefix + 'rra-assessment/view', assessmentId], { relativeTo: this.route });
    } else if (assessmentType == this.assessmentType.LoadingLevels) {
      this.router.navigate([urlPrefix + 'loading-levels/view', assessmentId], { relativeTo: this.route });
    } else if (assessmentType == this.assessmentType.TwoDimensional) {
      this.router.navigate([urlPrefix + '2d-assessment/view', assessmentId], { relativeTo: this.route });
    } else if (assessmentType == this.assessmentType.GaitAnalysis) {
      this.router.navigate([urlPrefix + 'analysis/view', assessmentId], { relativeTo: this.route });
    } else if (assessmentType == this.assessmentType.USAFFitnessAssessment) {
      this.router.navigate([urlPrefix + 'af-fitness/view', assessmentId], { relativeTo: this.route });
    }
  }

  onAssignPrograms(assessmentId: number, assessmentType: AssessmentType) {
    const modalRef = this.modalService.open((assessmentType == this.assessmentType.RRA ? AssessmentProgramDialogComponent : ( assessmentType == this.assessmentType.LoadingLevels ? LoadingLevelsProgramDialogComponent : TwoDimensionalProgramDialogComponent)), { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;
    modalRef.componentInstance.saved.subscribe((result) => {
      this.assessments.find(x => x.id === assessmentId).status = this.AssessmentStatus.ProgramsAssigned;
    });
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onResume(assessmentId: number, assessmentType: AssessmentType) {
    //navigation depends on isCoachView or hasCoachRole
    let urlPrefix = '../'; //isCoachView
    if (!this.isCoachView && this.hasCoachRole) {
      urlPrefix = '/athletes/details/' + this.userId + '/assessments/';
    }

    if (assessmentType == this.assessmentType.RRA) {
      this.router.navigate([urlPrefix + 'rra-assessment/edit', assessmentId], { relativeTo: this.route });
    } else if (assessmentType == this.assessmentType.LoadingLevels) {
      this.router.navigate([urlPrefix + 'loading-levels/edit', assessmentId], { relativeTo: this.route });
    } else if (assessmentType == this.assessmentType.TwoDimensional) {
      this.router.navigate([urlPrefix + '2d-assessment/edit', assessmentId], { relativeTo: this.route });
    }
  }

  onUpdateNotes(assessmentId: number, assessmentType: AssessmentType) {
    const modalRef = this.modalService.open(AssessmentNotesDialogComponent, { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;
    modalRef.componentInstance.assessmentType = assessmentType;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onUpdateGaitAnalysis(assessmentId: number) {
    const modalRef = this.modalService.open(GaitAnalysisUpdateDialogComponent, { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          // refresh list in case name changed
          this.getData();
        }
      }
    );
  }

  onDeleteAssessment(assessmentId: number, assessmentType: AssessmentType) {
    this.toastr.confirmDialog('Are you sure you want to delete this assessment? This action cannot be undone.', 'Delete Assessment', 'Delete Assessment', 'Cancel').subscribe(result => {
      if (result) {
        this.assessmentData.deleteAssessment(assessmentId, assessmentType).subscribe(result => {
          this.assessments.remove(x => x.id == assessmentId && x.assessmentType == assessmentType);
          this.toastr.success('Assessment deleted', 'Success');
        });
      }
    });
  }

  onChangeCategory() {
    const modalRef = this.modalService.open(RunningCategoryChangeDialogComponent, { size: 'lg' });
    modalRef.componentInstance.clientUserId = this.userId;
    modalRef.componentInstance.clientFullName = this.clientFullName;
    modalRef.componentInstance.saved.subscribe((result) => {
      this.getClientDetails();
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {
      }
    );
  }

  onPrintPDF(assessmentId: number, assessmentType: AssessmentType) {
    if (assessmentType == this.assessmentType.RRA || assessmentType == this.assessmentType.LoadingLevels) {
      const modalRef = this.modalService.open(AssessmentPDFDialogComponent, { size: 'lg', windowClass: 'modal-max-xl-custom' });
        modalRef.componentInstance.assessmentId = assessmentId;
        modalRef.componentInstance.assessmentType = assessmentType;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
    }
  }
}

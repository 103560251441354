import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';
import { CalendarType, CopyCalendarWeekViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService, TeamService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'copy-calendar-week-dialog',
  templateUrl: 'copy-calendar-week-dialog.component.html',
  styleUrls: ['copy-calendar-week-dialog.component.scss'],
})
export class CopyCalendarWeekDialogComponent implements OnInit {
  initialized: boolean;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  nextWeek: Date = new Date();

  // this component can be used to copy client or team weeks; based on Input parameters provided

  @Input()
    originalDate: Date;

  @Input()
    calendarType: CalendarType;

  @Input()
    clientUserId: number;

  @Input()
    teamId: number;


    public disabledDates = (date: Date): boolean => {
      return date.getDay() != 1;
    };

  constructor(public activeModal: NgbActiveModal, private clientsService: ClientsService, private teamsService: TeamService, private toastr: ToasterService,
    private fb: FormBuilder, private auth: AuthenticationService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.nextWeek = new Date(this.originalDate).addDays(7);
    this.formGroup = this.fb.group({
      copyToDate: [this.nextWeek, Validators.required],
    });
    this.initialized = true;
  }

  onCopy() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    let model = new CopyCalendarWeekViewModel();
    model.originalWeekStartDate = dateAsUTCNoTime(new Date(this.originalDate));
    model.copyToWeekStartDate = dateAsUTCNoTime(new Date(this.formGroup.controls.copyToDate.value));

    if (this.calendarType == CalendarType.Client) {
      this.copyClientWeek(model);
    } else {
      this.copyTeamWeek(model);
    }
  }

  copyClientWeek(model: CopyCalendarWeekViewModel) {
    this.clientsService.copyClientCalendarWeek(this.clientUserId, model).subscribe((result) => {
      this.toastr.success('Week Copied', 'Success');
      this.activeModal.dismiss('saved');
    });
  }

  copyTeamWeek(model: CopyCalendarWeekViewModel) {
    this.teamsService.copyTeamCalendarWeek(this.teamId, model).subscribe((result) => {
      this.toastr.success('Week Copied', 'Success');
      this.activeModal.dismiss('saved');
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { SurveyService } from 'src/app/_services/generatedServices';
import { SurveySummaryViewModelRead, SurveyQuestionSummaryViewModelRead, SurveyQuestionSummaryViewModel, SurveySummaryViewModel, VideoType, SurveyResultViewModelRead, SurveyResultViewModel } from 'src/app/_models/generatedModels';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'edit-survey-modal',
  templateUrl: 'edit-survey-modal.component.html',
  styleUrls: ['edit-survey-modal.component.scss']
})
export class EditSurveyModalComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  survey: SurveySummaryViewModelRead;
  surveyQuestion: SurveyQuestionSummaryViewModelRead;
  surveyResult: SurveyResultViewModelRead;

  @Input()
    id: number;

  @Input()
  recordType: string; // survey, question, result

  constructor(private fb: FormBuilder, private toastr: ToasterService, private surveyService: SurveyService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.recordType == 'question') {
      this.surveyService.getSurveyQuestionSummaryById(this.id).subscribe(result => {
        this.surveyQuestion = result;
        this.setupQuestionForm();
      });
    } else if (this.recordType == 'survey') {
      this.surveyService.getSurveySummaryById(this.id).subscribe(result => {
        this.survey = result;
        this.setupSurveyForm();
      });
    } else if (this.recordType == 'result') {
      this.surveyService.getSurveyResultById(this.id).subscribe(result => {
        this.surveyResult = result;
        this.setupSurveyResultForm();
      });
    }
  }

  setupQuestionForm() {
    this.formGroup = this.fb.group({
      question: [this.surveyQuestion.question, Validators.required],
      description: [this.surveyQuestion.description],
      videoLink: [this.surveyQuestion.videoLink],
      videoType: [this.surveyQuestion.videoType, Validators.required],
      videoId: [this.surveyQuestion.videoId],
    });

    this.initialized = true;
  }

  setupSurveyForm() {
    this.formGroup = this.fb.group({
      name: [this.survey.name, Validators.required],
      description: [this.survey.description],
      isActive: [this.survey.isActive]
    });

    this.initialized = true;
  }

  setupSurveyResultForm() {
    this.formGroup = this.fb.group({
      name: [this.surveyResult.name, Validators.required],
      description: [this.surveyResult.description],
      surveyResultPrograms: this.fb.array([], [Validators.required, Validators.minLength(1)])
    });

    let surveyResultPrograms = this.formGroup.get('surveyResultPrograms') as FormArray;

    this.surveyResult.surveyResultPrograms.forEach(resultProgram => {
      surveyResultPrograms.push(this.fb.group({
        id: [resultProgram.id],
        programId: [resultProgram.programId, Validators.required],
        surveyResultId: [resultProgram.surveyResultId]
        }));
    });

    this.initialized = true;
  }

  onAddProgram() {
    let surveyResultPrograms = this.formGroup.get('surveyResultPrograms') as FormArray;
    surveyResultPrograms.push(this.fb.group({
      id: [0],
      programId: [null, Validators.required],
      surveyResultId: [this.surveyResult.id]
      }));
  }

  onDeleteProgram(index: number) {
    this.toastr.confirmDialog('Are you sure you want to remove this program from the result?', 'Delete Program', 'Delete Program', 'Cancel').subscribe(result => {
      if (result) {
        let surveyResultPrograms = this.formGroup.get('surveyResultPrograms') as FormArray;
        surveyResultPrograms.removeAt(index);
      }
    });
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      if (this.recordType == 'question') {
        // make sure video fields are in correct state
        if (this.formGroup.get('videoType').value == VideoType.None) {
          this.formGroup.get('videoId').setValue(null);
          this.formGroup.get('videoLink').setValue(null);
        } else if (this.formGroup.get('videoType').value == VideoType.Public) {
          this.formGroup.get('videoId').setValue(null);
        } else if (this.formGroup.get('videoType').value == VideoType.Upload) {
          this.formGroup.get('videoLink').setValue(null);
        }

        const formData: SurveyQuestionSummaryViewModel = Object.assign(this.surveyQuestion, this.formGroup.getRawValue());
        this.surveyService.updateSurveyQuestion(this.id, formData).subscribe(result => {
          this.toastr.success('Survey Question Updated', 'Success');
          this.activeModal.dismiss('saved');
        });
      } else if (this.recordType == 'survey') {
        const formData: SurveySummaryViewModel = Object.assign(this.survey, this.formGroup.getRawValue());
        this.surveyService.updateSurvey(this.id, formData).subscribe(result => {
          this.toastr.success('Survey Updated', 'Success');
          this.activeModal.dismiss('saved');
        });
      } else if (this.recordType == 'result') {
        const formData: SurveyResultViewModel = Object.assign(this.surveyResult, this.formGroup.getRawValue());
        this.surveyService.updateSurveyResult(this.id, formData).subscribe(result => {
          this.toastr.success('Survey Updated', 'Success');
          this.activeModal.dismiss('saved');
        });
      }
    });
  }
}

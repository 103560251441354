import { Component, OnDestroy, OnInit } from '@angular/core';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { CalendarAddEventParameters, CalendarDraggedEventParameters, CalendarParameters } from 'src/app/_models/models';
import { CalendarEventViewModel, CalendarWeekViewModel } from 'src/app/_models/generatedModels';
import { TeamCalendarService } from 'src/app/_services/teamCalendar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bxl-team-calendar',
  templateUrl: 'team-calendar.component.html',
  styleUrls: ['team-calendar.component.scss'],
})
export class TeamCalendarComponent implements OnInit, OnDestroy {
  initialized: boolean;
  teamId: number;
  data: CalendarWeekViewModel[] = [];
  subscription: Subscription;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router, private teamCalendarService: TeamCalendarService) {}

  ngOnInit() {
    this.teamId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Calendar', this.router.url, []);

    this.teamCalendarService.setInitialValues(this.teamId, true);
    this.subscription = this.teamCalendarService.dataChanged.subscribe(result => {
      this.data = result;
    });
    this.initialized = true;
  }

  calendarParametersChanged(parameters: CalendarParameters) {
    this.teamCalendarService.calendarParametersChanged(parameters);
  }

  onEventInfoClicked(event: CalendarEventViewModel) {
    this.teamCalendarService.onEventInfoClicked(event);
  }

  onEventEditClicked(event: CalendarEventViewModel) {
    this.teamCalendarService.onEventEditClicked(event);
  }

  onEventDeleteClicked(event: CalendarEventViewModel) {
    this.teamCalendarService.onEventDeleteClicked(event);
  }

  onEventAddClicked(parameters: CalendarAddEventParameters) {
    this.teamCalendarService.onEventAddClicked(parameters);
  }

  onEventDragEnd(parameters: CalendarDraggedEventParameters) {
    this.teamCalendarService.onEventDragEnd(parameters);
  }

  onEventCopyClicked(event: CalendarEventViewModel) {
    this.teamCalendarService.onEventCopyClicked(event);
  }

  onWeekCopyClicked(date: Date) {
    this.teamCalendarService.onWeekCopyClicked(date);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div class="header pb-6" style="min-height: 500px">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-md-6 col-12">
          <h6 class="h2 d-inline-block mb-0">Assessments</h6>
        </div>

        <div *ngIf="initialized" class="col-md-6 col-12 text-right">
          <div *ngIf="hasCoachRole || hasUSAFFitnessAssessment || (showPlanGenieSurvey && survey.isActive)" class="btn-group">
            <div class="btn-group" ngbDropdown role="group" aria-label="Workout dropdown">
              <button class="btn btn-primary" ngbDropdownToggle>New Assessment</button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button *ngIf="hasCoachRole" [routerLink]="['/athletes/details/' + userId + '/assessments/rra-assessment/add']" ngbDropdownItem>Runner Readiness</button>
                <button *ngIf="hasCoachRole" [routerLink]="['/athletes/details/' + userId + '/assessments/loading-levels/add']" ngbDropdownItem>Loading Levels</button>
                <button *ngIf="hasCoachRole" [routerLink]="['/athletes/details/' + userId + '/assessments/2d-assessment/add']" ngbDropdownItem>2D Assessment</button>
                <button *ngIf="hasUSAFFitnessAssessment" [routerLink]="['../af-fitness-assessment/add']" ngbDropdownItem>USAF Fitness Assessment</button>
                <button *ngIf="showPlanGenieSurvey && survey.isActive" [routerLink]="['../surveys/add/' + survey.id]" ngbDropdownItem>{{survey.name}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="initialized">
    <div class="container-fluid mt-3">
      <assessment-list-view [userId]="userId" [isCoachView]="false"></assessment-list-view>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Assessment Tooltips</h3>
      </div>
    </div>

    <div *ngIf="initialized" class="row mt-3">
      <div class="col">
        <div class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row">
                <th class="sort" data-sort="key" scope="col">Key</th>

                <th class="sort" data-sort="name" scope="col">Title</th>

                <th class="sort" data-sort="name" scope="col">Video Link</th>

                <th class="text-lg-center" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let tooltip of tooltips">
                <td>
                  <span class="mobile-grid-label">Key</span>
                  {{tooltip.key}}</td>

                <td>
                  <span class="mobile-grid-label">Title</span>
                  {{tooltip.title}}</td>

                <td>
                  <span class="mobile-grid-label">Video Link</span>
                  {{tooltip.videoLink}}</td>

                <td class="text-lg-center">
                  <span class="mobile-grid-label">Actions</span>
                  <div class="btn-group no-caret">
                    <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"> </i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg-right">
                      <a [routerLink]="[editUrl, tooltip.id]" class="dropdown-item">
                        Edit
                      </a>
                    </div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

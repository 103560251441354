import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'bxl-organization-inactive',
  templateUrl: 'organization-inactive.component.html',
})
export class OrganizationInactiveComponent implements OnInit {
  initialized = false;


  constructor(public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.initialized = true;
  }

  logout() {
    this.auth.logout();
  }

}

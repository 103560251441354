<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Rebuild Reporting For a User Credential</h3>
      </div>
    </div>

    <div *ngIf="initialized" class="row mt-3">
      <div class="col">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" #ngForm="ngForm" novalidate>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="clientUserId"> Athlete User Credential Id </label>
                <input class="form-control" formControlName="clientUserCredentialId" placeholder="User Credential Id" type="text" />
                <div class="invalid-message">
                  <div>User Credential Id is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="startDate"> Start Date </label>
                <kendo-datepicker placeholder="" class="form-control" formControlName="startDate"></kendo-datepicker>
                <div class="invalid-message">
                  <div>Start Date is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-btns">
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Rebuild</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

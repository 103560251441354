import { Directive } from '@angular/core';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Directive({
  selector: '[noScroll]',
})
export class KendoNumericTextboxDirective {

  constructor(private host: NumericTextBoxComponent) {
    this.host.changeValueOnScroll = false;
   }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="mt-3">
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Summary</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h3>{{ assessment.dateTime | date }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>{{assessment.title}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>Running Category: {{ assessment.impairmentCategory | enumString: ImpairmentCategoryEnum}}</h3>
          </div>
        </div>
        <div *ngIf="assessment.assessmentNotes" class="row">
          <div class="col">Notes: {{ assessment.assessmentNotes }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="assessment.videos.length > 0 || assessment.images.length > 0" class="card">
      <div class="card-header"><h5 class="h4 mb-0">Media</h5></div>
      <div class="card-body">
        <div class="row">
          <div *ngFor="let video of assessment.videos" class="col-md-6 mb-5">
            <embed-video-player [size]="'small'" [videoId]="video.videoId"></embed-video-player>
            <div>{{video.description}}</div>
          </div>
          <div *ngFor="let image of assessment.images" class="col-md-6 mb-5">
            <img class="img-fluid image-size" src="fetchimage?filename={{ image.imageLocatorName }}" alt="Image" />
            <div>{{image.description}}</div>
            <div>{{image.notes}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <span class="d-block d-md-inline">Legend: </span><i class="assessment-result fas fa-times text-danger mr-0"></i> = Needs Attention, <i class="assessment-result fas fa-check text-success ml-1 mr-0"></i> = Satisfactory
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">General Parameters</h5></div>
      <div class="card-body details">
        <div class="row">
          <div class="col-md-6">
            Cadence: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.generalCadence == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.generalCadence == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Bouncing: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.generalBouncing == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.generalBouncing == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Ground Contact Time: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.generalGroundContactTime == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.generalGroundContactTime == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div *ngIf="assessment.generalOther1" class="col-md-6">
            {{assessment.generalOther1Text}}: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.generalOther1 == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.generalOther1 == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div *ngIf="assessment.generalOther2" class="col-md-6">
            {{assessment.generalOther2Text}}: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.generalOther2 == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.generalOther2 == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
        </div>
      </div>
      <div *ngIf="assessment.generalNotes" class="card-footer">
        Notes: {{assessment.generalNotes}}
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Initial Contact</h5></div>
      <div class="card-body details">
        <div class="row">
          <div class="col-md-6">
            Foot Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.initialFootPosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.initialFootPosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Knee Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.initialKneePosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.initialKneePosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Hip Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.initialHipPosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.initialHipPosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Malleolar Line: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.initialMalleolarLine == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.initialMalleolarLine == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Hip Separation Angle: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.initialHipSeparationAngle == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.initialHipSeparationAngle == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div *ngIf="assessment.initialOther" class="col-md-6">
            {{assessment.initialOtherText}}: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.initialOther == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.initialOther == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
        </div>
      </div>
      <div *ngIf="assessment.initialNotes" class="card-footer">
        Notes: {{assessment.initialNotes}}
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Mid Stance</h5></div>
      <div class="card-body details">
        <div class="row">
          <div class="col-md-6">
            Foot Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.midFootPosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.midFootPosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Knee Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.midKneePosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.midKneePosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Hip Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.midHipPosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.midHipPosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Knee Window: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.midKneeWindow == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.midKneeWindow == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Posture: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.midPosture == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.midPosture == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div *ngIf="assessment.midOther" class="col-md-6">
            {{assessment.midOtherText}}: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.midOther == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.midOther == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
        </div>
      </div>
      <div *ngIf="assessment.midNotes" class="card-footer">
        Notes: {{assessment.midNotes}}
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Swing</h5></div>
      <div class="card-body details">
        <div class="row">
          <div class="col-md-6">
            Foot Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.swingFootPosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.swingFootPosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Knee Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.swingKneePosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.swingKneePosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Hip Position: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.swingHipPosition == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.swingHipPosition == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div class="col-md-6">
            Posture: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.swingPosture == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.swingPosture == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
          <div *ngIf="assessment.swingOther" class="col-md-6">
            {{assessment.swingOtherText}}: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.swingOther == TwoDimensionalAssessmentResult.Satisfactory, 'text-danger fa-times': assessment.swingOther == TwoDimensionalAssessmentResult.NeedsAttention }"></i>
          </div>
        </div>
      </div>
      <div *ngIf="assessment.swingNotes" class="card-footer">
        Notes: {{assessment.swingNotes}}
      </div>
    </div>

  </div>
</div>

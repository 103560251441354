<div *ngIf="initialized" class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Update Card</h5>
  </div>
  <form [formGroup]="contactForm" (ngSubmit)="save()">
    <div class="modal-body">
      <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>
      <div class="form-row mb-2">
        <div class="col-md-9">
          <input type="text" class="form-control" placeholder="Card Number" formControlName="cardNumber" aria-label="Username" aria-describedby="basic-addon1" />
          <div class="invalid-message">
            <div>Must be a valid card number</div>
          </div>
        </div>
        <div class="col-md-3 mt-auto mb-auto">
          <img class="img-fluid" src="/assets/visa-mastercard-amex_0.png" />
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <select class="form-control" formControlName="expMo">
            <option value="1">01 - January</option>
            <option value="2">02 - February</option>
            <option value="3">03 - March</option>
            <option value="4">04 - April</option>
            <option value="5">05 - May</option>
            <option value="6">06 - June</option>
            <option value="7">07 - July</option>
            <option value="8">08 - August</option>
            <option value="9">09 - September</option>
            <option value="10">10 - October</option>
            <option value="11">11 - November</option>
            <option value="12">12 - December</option>
          </select>
          <div class="invalid-message">
            <div>Must be a valid month</div>
          </div>
        </div>
        <div class="col">
          <select class="form-control" formControlName="expYr">
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
            <option value="2031">2031</option>
            <option value="2032">2032</option>
            <option value="2033">2033</option>
            <option value="2034">2034</option>
          </select>
          <div class="invalid-message">
            <div>Must be a valid year</div>
          </div>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="cvv" formControlName="cvv" />
          <div class="invalid-message">
            <div>CVV is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col text-left pl-0">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cross click')">Close</button>
      </div>
      <div class="col text-right pr-0">
        <button type="submit" class="btn btn-primary" [promiseBtn]="saving">Save</button>
      </div>
    </div>
  </form>
</div>


import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GeneratePDFService } from 'src/app/_services/generatePDF.service';
import { AssessmentType } from 'src/app/_models/generatedModels';

@Component({
  selector: 'assessment-pdf-dialog',
  templateUrl: 'assessment-pdf-dialog.component.html',
  styleUrls: ['assessment-pdf-dialog.component.scss'],
})
export class AssessmentPDFDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  ready: boolean;
  assessmentTypeValues = AssessmentType;

  @Input()
    assessmentId: number;

    @Input()
    assessmentType: AssessmentType;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private pdfService: GeneratePDFService) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({});
      this.initialized = true;
  }

  onSave() {
    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.ready = true;

      setTimeout(() => {
        this.pdfService.createPDF('printDiv', 'RunDNA ' + (this.assessmentType == this.assessmentTypeValues.LoadingLevels ? 'Loading Levels Assessment' : 'Runner Readiness Assessment')).then(result => {
          this.activeModal.dismiss('saved');
        });
      }, 250);
      
    });
  }
}

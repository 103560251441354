import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { formatShortDate } from 'src/app/_helpers/extensions.module';
import { DynamicReportFilterViewModel, TrainingVolumeReportRowViewModel, TrainingVolumeReportViewModel } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ExportService } from 'src/app/_services/export.service';
import { ReportsService } from 'src/app/_services/generatedServices';
import { saveAs } from 'file-saver';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { SortableList } from 'src/app/_helpers/sortableList';

@Component({
  selector: 'app-report-training-volume',
  templateUrl: 'training-volume.component.html',
  styleUrls: ['training-volume.component.scss']
})
export class ReportTrainingVolumeComponent implements OnInit {
  initialized = false;
  data: TrainingVolumeReportViewModel;
  currentFilterValue: DynamicReportFilterViewModel;
  exportComplete: Promise<{}> | undefined;

  @ViewChild('tabNav') tabNav;

  constructor(private reportsService: ReportsService, private breadcrumbs: BreadcrumbsService, private exportService: ExportService, private charting: ChartingService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Training Volume Report', 'training-volume', []);
  }

  onCoachFilterChanged(value: number | null) {
    this.initialized = false;
    this.tabNav.select(0);
    this.currentFilterValue = new DynamicReportFilterViewModel();
    this.currentFilterValue.coachId = value;
    this.reportsService.getTrainingVolumeReport(this.currentFilterValue).subscribe(results => {
      this.data = results;
      this.displayChart();
      this.initialized = true;
    });
  }

  displayChart() {
    this.charting.queueCharts(
      this.charting.drawStackedColumnChart.bind(this.charting),
      new ChartData(
          'timeChart',
          this.data.chartHeaders,
          this.data.timeChartData,
          'Duration % Change',
          'trainingVolume'
      )
    );

    this.charting.queueCharts(
      this.charting.drawStackedColumnChart.bind(this.charting),
      new ChartData(
          'milesChart',
          this.data.chartHeaders,
          this.data.milesChartData,
          'Miles % Change',
          'trainingVolume'
      )
    );
  }

  onExport() {
    this.exportComplete = new Promise((resetButton:any, reject) => {
      this.exportService.ExportTrainingVolumeReport(this.currentFilterValue).subscribe(file => {
        let date = formatShortDate(new Date());
        let fileName = `TrainingVolumeReport_${date}.xlsx`;
        saveAs(file, fileName);
        resetButton();
      });
    });
  }
}

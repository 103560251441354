
<div *ngIf="isCoachView && clientDetails && !clientDetails.coachUserId" class="row">
  <div class="col-12">
    <alert class="text-center" type="danger" [dismissible]="true">
      <div>
        <span class="font-weight-bold">This athlete does not have an assigned coach.</span>
        <div class="d-inline text-center" kendoTooltip>
          <i class="fas fa-info-circle ml-2" title="Assigned coaches are used when filtering athlete lists on reports and dashboards,
          as well as determining which coach gets messages from athletes."></i>
        </div>
      </div>
      <div class="text-xs">Go to the <a class="text-dark text-underline" href="javascript:void(0)" [routerLink]="['/athletes/details/' + clientUserId + '/athlete-profile']"> Profile </a> tab to assign a coach.</div>
    </alert>
  </div>
</div>
<div *ngIf="clientDetails" class="row">
  <div class="col-12 d-md-none">
    <todays-workouts (changed)="loadTodaysWorkouts()" [todaysWorkouts]="todaysWorkouts" [clientUserId]="clientUserId" [isCoachView]="isCoachView"></todays-workouts>
  </div>
  <div class="col-xl-3 col-md-6">
    <div class="card card-stats">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Next Event In</h5>
            <span class="h2 font-weight-bold mb-0"> {{clientDetails.nextScheduledEvent ? ((getDifferenceInDays(clientDetails.nextScheduledEvent)) + ' Days') : 'N/A'}}</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow"><i class="fas fa-flag-checkered"></i></div>
          </div>
        </div>
        <p class="mt-0 ml--2 mb-0 text-sm"><span class="text-success mr-2"></span>
          <a *ngIf="!isCoachView" [routerLink]="['/runner/calendar']" href="javascript:void(0)" class="text-nowrap"> View Calendar </a>
          <a *ngIf="isCoachView" [routerLink]="['/athletes/details/' + clientUserId + '/calendar']" href="javascript:void(0)" class="text-nowrap"> View Calendar </a>
        </p>
        <div class="mt-0 mb-0 text-xs">
          <span> Workouts scheduled until {{ clientDetails.lastScheduledWorkoutDate ? (clientDetails.lastScheduledWorkoutDate | date) : 'N/A'}} </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-md-6">
    <div class="card card-stats">
      <div class="card-body">
        <div class="row">
          <div class="col pr-0">
            <h5 class="card-title text-uppercase text-muted mb-0">Distance (7 days)</h5>
            <span class="h2 font-weight-bold mb-0"> {{(clientDetails.miles7Days | fromMilesToLocalMeasurement) | number: '1.2-2'}} {{localMeasurementName}}</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-orange text-white rounded-circle shadow"><i class="ni ni-user-run"></i></div>
          </div>
        </div>
        <p class="mt-0 mb-0 text-sm">
          <span [ngClass]="{'text-success': clientDetails.percentChangeMiles >= 0, 'text-danger': clientDetails.percentChangeMiles < 0}" class="mr-2"><i [ngClass]="{'fa-arrow-up': clientDetails.percentChangeMiles >= 0, 'fa-arrow-down': clientDetails.percentChangeMiles < 0}" class="fa"></i> {{clientDetails.percentChangeMiles | number: '1.2-2'}}% </span>
          <span class="text-nowrap"> Since last week </span>
        </p>
        <div class="mt-0 mb-0 text-xs">
          <span> Running and walking only </span>
        </div>
<!--         <p class="mt-3 mb-0 text-sm">
          <span [ngClass]="{'text-success': clientDetails.percentChangeMiles >= 0, 'text-danger': clientDetails.percentChangeMiles < 0}" class="mr-2"><i [ngClass]="{'fa-arrow-up': clientDetails.percentChangeMiles >= 0, 'fa-arrow-down': clientDetails.percentChangeMiles < 0}" class="fa"></i> {{clientDetails.percentChangeMiles | number: '1.2-2'}}% </span>
          +
          <span [ngClass]="{'text-success': clientDetails.percentChangeKilometers >= 0, 'text-danger': clientDetails.percentChangeKilometers < 0}" class="mr-2 ml-1"><i [ngClass]="{'fa-arrow-up': clientDetails.percentChangeKilometers >= 0, 'fa-arrow-down': clientDetails.percentChangeKilometers < 0}" class="fa"></i> {{clientDetails.percentChangeKilometers | number: '1.2-2'}}% </span><span class="text-nowrap"> Since last week </span>
        </p> -->
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-md-6">
    <div class="card card-stats">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Time (7 days)</h5>
            <span class="h2 font-weight-bold mb-0"> {{clientDetails.timeInSeconds7Days | secsToHourMinSec}} </span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow"><i class="far fa-clock"></i></div>
          </div>
        </div>
        <p class="mt-0 mb-0 text-sm">
          <span [ngClass]="{'text-success': clientDetails.percentChangeTimeInSeconds >= 0, 'text-danger': clientDetails.percentChangeTimeInSeconds < 0}" class="mr-2"><i [ngClass]="{'fa-arrow-up': clientDetails.percentChangeTimeInSeconds >= 0, 'fa-arrow-down': clientDetails.percentChangeTimeInSeconds < 0}" class="fa"></i> {{clientDetails.percentChangeTimeInSeconds | number: '1.2-2'}}% </span><span class="text-nowrap"> Since last week </span>
        </p>
        <div class="mt-0 mb-0 text-xs">
          <span> Time includes all activities </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-md-6">
    <div class="card card-stats">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Completion %</h5>
            <span class="h2 font-weight-bold mb-0" [ngClass]="(clientDetails.completionPercentage != null ? (clientDetails.completionPercentage * 100) : null) | completionPercentRangeColor"> {{clientDetails.completionPercentage != null ? ((clientDetails.completionPercentage * 100) | number : '1.2-2') + '%' : 'N/A'}} </span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow"><i class="ni ni-chart-bar-32"></i></div>
          </div>
        </div>
        <p class="mt-0 mb-0 text-sm">
          <span [ngClass]="{'text-success': clientDetails.percentChangeCompletionPercentage >= 0, 'text-danger': clientDetails.percentChangeCompletionPercentage < 0}" class="mr-2"><i [ngClass]="{'fa-arrow-up': clientDetails.percentChangeCompletionPercentage >= 0, 'fa-arrow-down': clientDetails.percentChangeCompletionPercentage < 0}" class="fa"></i> {{clientDetails.percentChangeCompletionPercentage | number: '1.2-2'}}% </span><span class="text-nowrap"> Since previous month </span>
        </p>
        <div class="mt-0 mb-0 text-xs">
          <span> Last completed workout on {{ clientDetails.lastCompletedWorkoutDate ? (clientDetails.lastCompletedWorkoutDate | date) : 'N/A'}} </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-4">
    <todays-workouts class="d-none d-md-inline" (changed)="loadTodaysWorkouts()" [todaysWorkouts]="todaysWorkouts" [clientUserId]="clientUserId" [isCoachView]="isCoachView"></todays-workouts>

    <div *ngIf="clientDetails" class="card">
      <div class="card-header">
        <h5 class="h4 mb-0 d-inline">Acute/Chronic Ratio (Intensity)</h5>
        <div class="d-inline text-center" kendoTooltip>
          <i class="fas fa-info-circle ml-2" title="Acute to Chronic Workload ratio measures the amount of training that you have done in the last 7 days,
          compared to your average of the previous 4 weeks.
          The percentage is color coded based on how rapidly you are increasing or decreasing your training load.
          You can use this number to help you modify your training so that you stay healthy and performing your best.
          The Training Load card will give you an expected range of training for the week to help you guide your decisions about working out."></i>
        </div>
        <span class="text-nowrap float-right">
          <a *ngIf="isCoachView" [routerLink]="['/athletes/details/' + clientUserId + '/acute-chronic']" href="javascript:void(0)" class="small-link"> View More </a>
          <a *ngIf="!isCoachView" [routerLink]="['/runner/acute-chronic']" href="javascript:void(0)" class="small-link"> View More</a>
          <i *ngIf="clientDetails.showSportACWRWarning == true" style="font-size: 18px;" class="fas fa-exclamation-triangle text-warning"></i>
        </span>
        
      </div>
      <div *ngIf="clientDetails.acuteChronicWorkloadRatioPercentage == null" class="card-body">
        <div class="">
          Not enough data to show
        </div>
      </div>
      <div *ngIf="clientDetails.acuteChronicWorkloadRatioPercentage != null" class="card-body">
        <div class="">
          <acute-chronic-donut-chart [acwrPercentage]="clientDetails.acuteChronicWorkloadRatioPercentage" [intensity7Days]="clientDetails.intensityInSeconds7Days"
          [intensityTrailing7to34Days]="clientDetails.intensityInSecondsTrailing7to34Days" [isTrainingLoadTargetIncomplete]="trainingLoadTargetData.isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
        </div>
      </div>
      <div class="card-footer card-footer-small">
        <a class="small-link" href="https://rundna.com/resources/acwr-and-injury-prevention/" target="_blank">Learn more about ACWR</a>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div *ngIf="clientDetails" class="card">
      <div class="card-header">
        <h5 class="h4 mb-0 d-inline">Training Load</h5>
        <div class="d-inline text-center" kendoTooltip>
          <i class="fas fa-info-circle ml-2" title="The vertical bar represents your target training load range for the week, based on your previous 4 week average."></i>
          </div>
        <a *ngIf="isCoachView" [routerLink]="['/athletes/details/' + clientUserId + '/training-volume']" href="javascript:void(0)" class="text-nowrap float-right small-link"> View More </a>
        <a *ngIf="!isCoachView" [routerLink]="['/runner/training-volume']" href="javascript:void(0)" class="text-nowrap float-right small-link"> View More</a>
      </div>
      <intensity-rpe-range-chart [clientUserId]="clientUserId" [page]="1"></intensity-rpe-range-chart>
      <div *ngIf="trainingLoadTargetData.trainingLoadTargetLow" class="card-body">
        This week's target range: {{trainingLoadTargetData.trainingLoadTargetLow}} - {{trainingLoadTargetData.trainingLoadTargetHigh}}
      </div>
      <div *ngIf="trainingLoadTargetData.isTrainingLoadTargetIncomplete" class="card-body text-danger warning-text">
        5 weeks of consistent workouts are needed for accurate target training load values. Please select a training load that does not cause pain or excess fatigue.
      </div>
      <div class="card-footer card-footer-small">
        <a class="small-link" href="https://rundna.com/resources/run-training/defining-training-load/" target="_blank">Learn more about Training Load</a>
      </div>
    </div>
    <!-- <div *ngIf="clientDetails" class="card">
      <div class="card-header">
        <h5 class="h4 card-title mb-0">Runner’s Handicap</h5>
      </div>

      <div class="card-body">
        <h5 class="h3 card-title mb-0 text-center">{{clientDetails.currentHandicapScore ? ('+' + clientDetails.currentHandicapScore) : ''}} {{clientDetails.currentImpairmentCategory | enumString: ImpairmentCategoryEnum}}</h5>
        <a *ngIf="!isCoachView" [routerLink]="['/runner/assessments']" href="javascript:void(0)" class="btn btn-link px-0"> View Assessment </a>
        <a *ngIf="isCoachView" [routerLink]="['/athletes/details/' + clientUserId + '/assessments']" href="javascript:void(0)" class="btn btn-link px-0"> View Assessment </a>
      </div>
    </div> -->
  </div>
  <div class="col-lg-4">
    <!-- <div class="card">
      <div class="card-header"><h5 class="h4 mb-0 d-inline">Health Stats</h5>
      </div>
      <resting-heart-rate-chart [clientUserId]="clientUserId"></resting-heart-rate-chart>
    </div> -->
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0 d-inline">Program Progress</h5>
        <a *ngIf="hasCoachRole" [routerLink]="['/library/programs']" href="javascript:void(0)" class="text-nowrap float-right small-link"> Add Program </a>
      </div>
      <runner-program-details [clientUserId]="clientUserId"></runner-program-details>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0 d-inline">Recent Daily Statuses</h5>
        <a *ngIf="isCoachView" [routerLink]="['/athletes/details/' + clientUserId + '/daily-statuses']" href="javascript:void(0)" class="text-nowrap float-right small-link"> View More </a>
        <a *ngIf="!isCoachView" [routerLink]="['/runner/daily-statuses']" href="javascript:void(0)" class="text-nowrap float-right small-link"> View More</a>
      </div>
      <runner-daily-statuses [clientUserId]="clientUserId" [isCoachView]="isCoachView"></runner-daily-statuses>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">{{ exerciseId ? 'Edit' : 'Add' }} Exercise</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div *ngIf="exercise.relatedExerciseProgressionName && !isExerciseCopy" class="row">
            <div class="col-12">
              <alert class="text-center" type="info" [dismissible]="true">
                <div>
                  <span class="font-weight-bold">This exercise is part of a progression: {{exercise.relatedExerciseProgressionName}}</span>
                </div>
              </alert>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="name"> Name </label>
                <input class="form-control" formControlName="name" maxlength="100" type="text" />
                <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.required">
                  <div>Name is required</div>
                </div>
                <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.nameTaken">Exercise with this name already exists</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="videoLink"> Exercise Equipment </label>
                <kendo-multiselect [data]="exerciseEquipmentList" [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" formControlName="exerciseEquipments"></kendo-multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="videoLink"> Video Type </label>
                <select formControlName="videoType" class="form-control">
                  <option value="2">Public (YouTube, Vimeo, etc)</option>
                  <option value="1">Uploaded</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="formGroup.controls.videoType.value == 2" class="form-group">
                <label class="form-control-label" for="videoLink"> Link to Video </label>
                <input type="text" class="form-control" formControlName="videoLink" maxlength="1000" placeholder="YouTube/Vimeo Link" aria-label="Recipient's username" aria-describedby="button-addon2" />
              </div>
              <div *ngIf="formGroup.controls.videoType.value == 1" class="form-group">
                <video-upload formControlName="videoId" [label]="'Upload Video'"></video-upload>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="patterns"> Target Tissue </label>
                <kendo-multiselect [data]="tagsList" formControlName="exerciseTags" [allowCustom]="true"></kendo-multiselect>
                <div class="invalid-message">
                  <div>There is an error with this field</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="instructions"> Instructions </label>
                <custom-editor formControlName="instructions"></custom-editor>
                <div class="invalid-message">
                  <div>Instructions are required</div>
                </div>
              </div>
            </div>
            <div *ngIf="canEditGlobalLibrary" class="col-lg-6 col-md-12">
              <div class="form-group">
                <label class="form-control-label mr-1" for="isHidden">
                  Is Hidden Exercise?
                </label>
                <kendo-switch formControlName="isHidden"></kendo-switch>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
            </div>
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Exercise</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="card-body">
  <div class="row no-gutters">
    <div class="col-lg-12">
      <h3 class="mb-0 d-inline">Exercises</h3>
      <div class="float-right">
        <a [routerLink]="['add']"  class="btn btn-primary">Add Exercise</a>
      </div>
    </div>
    <div class="col">Build exercises to use for your athletes</div>
  </div>

  <bxl-exercise-list [title]="'Exercise'"></bxl-exercise-list>
</div>

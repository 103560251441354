import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-view-af-fitness',
  templateUrl: 'view-af-fitness.component.html',
})
export class ViewAFFitnessComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }

}

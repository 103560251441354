import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { TrainingLoadPlannerViewModelRead, TrainingLoadPlannerWeekViewModelRead } from 'src/app/_models/generatedModels';
import {  TrainingLoadPlannerService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-training-plan-view-modal',
  templateUrl: 'training-plan-view-modal.component.html',
  styleUrls: ['training-plan-view-modal.component.scss']
})
export class TrainingPlanViewModalComponent implements OnInit {
  initialized = false;
  plan: TrainingLoadPlannerViewModelRead;
  planWeeks: TrainingLoadPlannerWeekViewModelRead[];
  trainingLoadPeriodTypeEnum = Enums.TrainingLoadPeriodTypeEnum;

  @Input()
  clientUserId: number;

  @Input()
  trainingPlanId: number;

  constructor(public activeModal: NgbActiveModal, private trainingPlanService: TrainingLoadPlannerService) { }

  ngOnInit(): void {
    this.trainingPlanService.getTrainingLoadPlanById(this.clientUserId, this.trainingPlanId).subscribe(result => {
      this.plan = result;
      this.plan.trainingLoadPlannerWeeks.sort(dynamicSort('sortOrder'));
      this.initialized = true;
    })
  }
}


<link rel='stylesheet' id='rundna-style-css' href='https://rundna.com/wp-content/themes/rundna/style.css' media='all' />
<link rel='stylesheet' id='rundna-core-bundle-style-css' href='https://rundna.com/wp-content/themes/rundna/core.bundle.css' media='all' />

<div>
<header class="main-header" [ngClass]="{'no_header_bg': mobileNavActive}">
  <div class="container lg">
  <div class="header flex flex-vcenter">
      <div class="logo">
          <a href="https://rundna.com"><img src="https://rundna.com/wp-content/themes/rundna/images/logo.svg" alt="logo"></a>
      </div>
      <div class="header-right flex flex-vcenter">

          <!-- <div class="cart_icon pos-relative">
              <a href="#" target="_blank"><span class="fa-regular fa-cart-shopping"></span></a>
              <a class="cart-customlocation" href="https://rundna.com/cart/" title="View your shopping cart">0</a>
          </div> -->

          <button type="button" class="togglebar toggle-button btn-transparent" [ngClass]="{'active': mobileNavActive}" aria-hidden="true" (click)="toggleMobileNav()"><span class="fas fa-bars"></span></button>
          <nav class="navigation flex flex-vcenter" [ngClass]="{'mobile-active': mobileNavActive}">
          <ul class="main_menu flex flex-vcenter"><li data-depth="0" id="menu-item-1391" class="products menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children"><a [ngClass]="{'sub-active': getStartedActive}" href="javascript:void(0)" (click)="onGetStartedClick()">Get Started<p class="menu-des"></p></a>
  <ul class="sub-menu" [ngClass]="{'mobile-active': mobileNavActive && getStartedActive}">
    <li data-depth="1" id="menu-item-1742" class="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-217"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundna.com/running-education/"><img width="1" height="1" src="https://rundna.com/wp-content/uploads/2023/01/icon-one.svg" class="menu-image menu-image-title-below" alt="Run Specialists" decoding="async" loading="lazy" /><span class="menu-image-title-below menu-image-title">Running Education</span></a><p class="menu-des">Get certified with best in class continuing education on all things running.</p></div></div></li>
    <li data-depth="1" id="menu-item-1743" class="menu-item menu-item-type-post_type menu-item-object-page"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundna.com/helix3d/"><img src="https://esapdjq7mkb.exactdn.com/wp-content/uploads/2024/01/icon-two.svg" class="menu-image menu-image-title-below perfmatters-lazy" alt decoding="async" data-src="https://esapdjq7mkb.exactdn.com/wp-content/uploads/2024/01/icon-two.svg" /><noscript><img src="https://esapdjq7mkb.exactdn.com/wp-content/uploads/2024/01/icon-two.svg" class="menu-image menu-image-title-below" alt="" decoding="async" /></noscript><span class="menu-image-title-below menu-image-title">Helix3D</span></a><p class="menu-des">Get the whole picture with portable 3D running software and hardware.</p></div></div></li>
    <li data-depth="1" id="menu-item-3154" class="menu-item menu-item-type-post_type menu-item-object-page"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundna.com/rundna-portal/"><img width="1" height="1" src="https://rundna.com/wp-content/uploads/2022/12/icon-four.svg" class="menu-image menu-image-title-after" alt="Gait Analysis" decoding="async" loading="lazy" /><span class="menu-image-title-after menu-image-title">RunDNA Portal</span></a><p class="menu-des">Get the whole picture with portable 3D running software and hardware.</p></div></div></li>
  </ul>
  </li>
  <li data-depth="0" id="menu-item-1393" class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://rundna.com/run-specialists/">Run Specialists<p class="menu-des"></p></a></li>
  <li data-depth="0" id="menu-item-1392" class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://rundna.com/runners/">Runners<p class="menu-des"></p></a></li>
  <li data-depth="0" id="menu-item-1394" class="community menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children"><a [ngClass]="{'sub-active': communityActive}" href="javascript:void(0)" (click)="onCommunityClick()">Community<p class="menu-des"></p></a>
  <ul class="sub-menu" [ngClass]="{'mobile-active': mobileNavActive && communityActive}">
    <li data-depth="1" id="menu-item-1755" class="menu-item menu-item-type-post_type menu-item-object-page"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundna.com/community/find-a-provider/"><img width="1" height="1" src="https://rundna.com/wp-content/uploads/2023/01/run-pro-icon.svg" class="menu-image menu-image-title-after" alt="run-pro-icon" decoding="async" loading="lazy" /><span class="menu-image-title-after menu-image-title">Find a Specialist</span></a><p class="menu-des">Access top running specialist near you, or put your name on the list.</p></div></div></li>
    <li data-depth="1" id="menu-item-1759" class="menu-item menu-item-type-post_type menu-item-object-page"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundna.com/community/vdot-calculator/"><img width="1" height="1" src="https://rundna.com/wp-content/uploads/2023/01/calc-icon.svg" class="menu-image menu-image-title-below" alt="calc-icon" decoding="async" loading="lazy" /><span class="menu-image-title-below menu-image-title">V Dot Calculator</span></a><p class="menu-des">Find the perfect pace based off recent performances.</p></div></div></li>
    <li data-depth="1" id="menu-item-1758" class="menu-item menu-item-type-taxonomy menu-item-object-category"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundna.com/resources/"><img width="1" height="1" src="https://rundna.com/wp-content/uploads/2023/01/icon-ten.svg" class="menu-image menu-image-title-below" alt="icon-ten" decoding="async" loading="lazy" /><span class="menu-image-title-below menu-image-title">Resources</span></a><p class="menu-des">Essential articles and downloads for all things running.</p></div></div></li>
    <li data-depth="1" id="menu-item-4805" class="menu-item menu-item-type-custom menu-item-object-custom"><div class="menu-item-wrap"><div class="menu-item-text"><a href="https://rundnahelp.zendesk.com/hc/en-us"><img width="1" height="1" src="https://rundna.com/wp-content/uploads/2022/12/resource-icon.svg" class="menu-image menu-image-title-below" alt="Resources" decoding="async" loading="lazy" /><span class="menu-image-title-below menu-image-title">Help Desk</span></a><p class="menu-des"></p></div></div></li>
  </ul>
  </li>
  <li data-depth="0" id="menu-item-1395" class="button menu-item menu-item-type-custom menu-item-object-custom"><a href="https://rundna.com/product/essential-elements-of-running/">Free Course<p class="menu-des"></p></a></li>
  </ul>        <div class="header-icons flex">
              <div class="header-icon login-icon pos-relative">
                  <a href="javascript:void(0);">
                    <span class="fa-stack" style="font-size: 14px;">
                      <i class="fas fa-ellipsis-h fa-stack-1x"></i>
                      <i class="far fa-circle fa-stack-2x"></i>
                    </span>
                  </a>
                  <div class="user-login" aria-hidden="false">
                      <span>USER LOGIN:</span>
                      <ul class="user-links">
                              <li><a class="#" href="https://app.rundna.com/auth/login" target="_blank" rel="noopener">RUNDNA APP</a></li>
                              <li><a class="#" href="https://www.certifiedrunninggaitanalyst.com/login" target="_blank" rel="noopener">COURSES</a></li>
                      </ul>
                  </div>
              </div>

          <!-- <div class="header-icon pos-relative"><a href="https://rundna.com/cart/"><span class="fa-regular fa-cart-shopping"></span></a>
          <a class="cart-customlocation" href="https://rundna.com/cart/" title="View your shopping cart">0</a>
          </div> -->

          </div>
          </nav>
      </div>
  </div>
  </div>
  </header>
  <div class="site-main-cover" [ngClass]="{'content-hide': mobileNavActive}">
  
    <section class="default-banner-section pos-relative">
      <div class="default-banner-main flex">
          <div class="default-banner-right">
                          <picture class="hero-banner-image object-fit">
                      <source srcset="https://rundna.com/wp-content/uploads/2022/12/runners-banner@2x.jpg" media="(min-width: 768px)"/>
                      <img src="https://rundna.com/wp-content/uploads/2023/01/runners-banner-mobile@2x.jpg" alt="hero-banner-mobile"/>
                  </picture>
                  </div>
          <div class="default-banner-left pos-relative">
              <div class="default-banner-art background-bg">
                  <picture class="default-line-art object-fit">
                      <source srcset="https://rundna.com/wp-content/themes/rundna/images/line-art-banner.svg" media="(min-width: 768px)"/>
                      <img src="https://rundna.com/wp-content/themes/rundna/images/line-art-banner.svg" alt="line-art-banner"/>
                  </picture>
              </div>
              <div class="default-banner-pos flex flex-vcenter">
                  <div class="default-banner-text">
                                          <ul class="banner-category flex pos-relative">
                              <li><ng-content select="[header-1]"></ng-content></li>
                          </ul>
                                          <h1><ng-content select="[header-2]"></ng-content></h1>
                      <p><ng-content select="[text]"></ng-content></p>
                  </div>
              </div>
          </div>
      </div>
      <div class="edge-top abs-pos bottom"></div>
    </section>
  </div>

  <ng-content></ng-content>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="hasCoachRole || isCoachView" class="row py-2">
    <div class="col-12">
      Current Running Category: <span class="d-block d-md-inline"><span class="font-weight-600">{{currentImpairmentCategory ? (currentImpairmentCategory | enumString: impairmentCategoryEnum) :'(none)' }}</span> <button (click)="onChangeCategory()" class="btn btn-primary ml-2">Change</button></span>
    </div>
  </div>

  <h5 *ngIf="!assessments || assessments.length == 0" class="mb-3 ml-3 mt-3">No Assessments</h5>
  <div *ngIf="assessments && assessments.length > 0" class="table-responsive">
    <table class="table align-items-center table-flush mobile-table">
      <thead class="thead-light">
        <tr class="header-row">
          <th class="sort" data-sort="name" scope="col">Date</th>
          <th class="sort" data-sort="name" scope="col">Assessment Type</th>
          <th class="sort" data-sort="budget" scope="col">Running Category</th>
          <th class="sort" data-sort="budget" scope="col">Movement Category</th>
          <th class="sort" data-sort="budget" scope="col">Level/Score/Shoe</th>
          <th *ngIf="isCoachView || hasCoachRole" class="sort" data-sort="budget" scope="col">Status</th>
          <th class="text-lg-center" scope="col">Actions</th>
        </tr>
      </thead>

      <tbody class="list">
        <tr *ngFor="let item of assessments">
          <td>
            <span class="mobile-grid-label">Date</span>
            <a *ngIf="item.status != AssessmentStatus.InProgress" href="javascript:void(0)" (click)="onViewAssessment(item.id, item.assessmentType)">{{ item.assessmentDate | date }}</a>
            <span *ngIf="item.status == AssessmentStatus.InProgress" >{{ item.assessmentDate | date }}</span>
          </td>
          <td>
            <span class="mobile-grid-label">Assessment Type</span>
            <a *ngIf="item.status != AssessmentStatus.InProgress" href="javascript:void(0)" (click)="onViewAssessment(item.id, item.assessmentType)">{{ item.name ? item.name : (item.assessmentType | enumString: assessmentTypeEnum) }}</a>
            <span *ngIf="item.status == AssessmentStatus.InProgress" >{{ item.name ? item.name : (item.assessmentType | enumString: assessmentTypeEnum) }}</span>
          </td>
          <td>
            <span class="mobile-grid-label">Running Category</span>
            {{ item.runningCategory }}</td>
          <td>
            <span class="mobile-grid-label">Movement Category</span>
            {{ item.movementCategory | enumString: movementCategoryEnum }}</td>
          <td>
            <span class="mobile-grid-label">Level/Score/Shoe</span>
            {{ item.score }}</td>
          <td *ngIf="isCoachView || hasCoachRole" [ngClass]="{'text-warning': item.status == AssessmentStatus.Completed, 'text-danger': item.status == AssessmentStatus.InProgress}">
            <span class="mobile-grid-label">Status</span>
            <ng-container *ngIf="item.assessmentType != assessmentType.USAFFitnessAssessment">{{item.status | enumString: AssessmentStatusEnum}}</ng-container>
          </td>

          <td class="text-lg-center">
            <span class="mobile-grid-label">Actions</span>
            <div class="btn-group no-caret">
              <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"> </i>
              </button>
              <div class="dropdown-menu dropdown-menu-lg-right">
                <ng-container *ngIf="auth.user.organizationId == item.organizationId && (isCoachView || hasCoachRole)">
                  <a *ngIf="item.status == AssessmentStatus.Completed" href="javascript:void(0)" (click)="onAssignPrograms(item.id, item.assessmentType)" class="dropdown-item"> Assign Programs </a>
                  <a *ngIf="item.status == AssessmentStatus.InProgress" href="javascript:void(0)" (click)="onResume(item.id, item.assessmentType)" class="dropdown-item"> Resume </a>
                  <a *ngIf="item.status != AssessmentStatus.InProgress && item.assessmentType == assessmentType.GaitAnalysis" href="javascript:void(0)" (click)="onUpdateGaitAnalysis(item.id)" class="dropdown-item"> Update </a>
                  <a *ngIf="item.status != AssessmentStatus.InProgress && item.assessmentType != assessmentType.GaitAnalysis" href="javascript:void(0)" (click)="onUpdateNotes(item.id, item.assessmentType)" class="dropdown-item"> Update Notes </a>
                  <a *ngIf="item.status != AssessmentStatus.InProgress && (item.assessmentType == assessmentType.LoadingLevels || item.assessmentType == assessmentType.TwoDimensional)" href="javascript:void(0)" (click)="onResume(item.id, item.assessmentType)" class="dropdown-item"> Edit Assessment </a>
                </ng-container>
                <a *ngIf="item.status != AssessmentStatus.InProgress" href="javascript:void(0)" (click)="onViewAssessment(item.id, item.assessmentType)" class="dropdown-item"> View </a>
                <a *ngIf="auth.user.organizationId == item.organizationId && (isCoachView || hasCoachRole || item.assessmentType === assessmentType.USAFFitnessAssessment)" href="javascript:void(0)" (click)="onDeleteAssessment(item.id, item.assessmentType)" class="dropdown-item"> Delete </a>
                <!-- don't display Print PDF on smaller screens -->
                <a *ngIf="item.status != AssessmentStatus.InProgress && (item.assessmentType == assessmentType.LoadingLevels || item.assessmentType == assessmentType.RRA)" href="javascript:void(0)" (click)="onPrintPDF(item.id, item.assessmentType)" class="dropdown-item d-none d-lg-block"> Print PDF </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-12">
          <h6 class="h2 d-inline-block mb-0">Acute Chronic Workload Ratio Details</h6>
        </div>
      </div>
    </div>
    <acute-chronic-list [clientUserId]="userId" [isCoachView]="false"></acute-chronic-list>
  </div>
</div>

import { Component, Input, forwardRef, ElementRef, OnInit  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyPolicyDialogComponent } from 'src/app/_components/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsAndConditionsDialogComponent } from 'src/app/_components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { TermsAndConditionsService } from 'src/app/_services/generatedServices';


@Component({
    selector: 'terms-conditions-checkbox',
  templateUrl: 'termsConditionsCheckbox.component.html',
  styleUrls: ['termsConditionsCheckbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TermsConditionsControl),
            multi: true
        }
    ]
})

// control value is the version number of the terms and conditions selected

export class TermsConditionsControl implements ControlValueAccessor, OnInit {

    someAttr: string = 'anyValue';

    constructor(elementRef: ElementRef, private modalService: NgbModal, private termsService: TermsAndConditionsService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    checkboxValue: boolean;
    selectedValue: number;

    cssClass: string = '';
    currentTermsVersion: number;
    initialized: boolean = false;

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: boolean): void {
        this.selectedValue = value ? this.currentTermsVersion : null;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
    }

    ngOnInit(): void {
        this.termsService.getLatestTermsAndConditions().subscribe((result) => {
          this.currentTermsVersion = result.version;
          this.initialized = true;
        });
    }

    onViewTerms() {
      const modalRef = this.modalService.open(TermsAndConditionsDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom'});
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
    }

    onViewPrivacyPolicy() {
        const modalRef = this.modalService.open(PrivacyPolicyDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom'});
          modalRef.result.then(
            (result) => {},
            (reason) => {}
          );
      }
}

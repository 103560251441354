import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter  } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrganizationService } from '../_services/generatedServices';
import { ClientStatus, UserProfileViewModel } from '../_models/generatedModels';
import { dynamicSort } from '../_helpers/extensions.module';


@Component({
    selector: 'coaches-multiselect',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <page-load-header *ngIf="!initialized"></page-load-header>
    <kendo-multiselect *ngIf="initialized" #multiselect [placeholder]="placeholder" [clearButton]="false" [valuePrimitive]="true" [textField]="'fullName'" [valueField]="'id'" [data]="comboData" class="{{cssClass}}" [(ngModel)]="selectedValue" [filterable]="true" (filterChange)="handleFilter($event)" (valueChange)="onChange($event)"></kendo-multiselect>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoachMultiselectControl),
            multi: true
        }
    ]
})

export class CoachMultiselectControl implements ControlValueAccessor, AfterContentInit {

    someAttr: string = 'anyValue';

    constructor(elementRef: ElementRef, private orgService: OrganizationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    isDisabled: boolean;

    @Input()
    placeholder: string = "";

    @Output()
    changed = new EventEmitter<number[]>();

    @Output()
    changedObjects = new EventEmitter<UserProfileViewModel[]>();

    initialized: boolean;
    selectedValue: number[] = [];
    comboData: UserProfileViewModel[] = [];
    coaches: UserProfileViewModel[] = [];
    @ViewChild('multiselect') public multiselect: MultiSelectComponent;
    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.changedObjects.emit(this.comboData.filter(x => this.selectedValue.indexOf(x.id!) !== -1).map(x => x as UserProfileViewModel));
        this.changed.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngAfterContentInit(): void {
      this.orgService.getOrgUsers().subscribe(results => {
        this.coaches = results.sort(dynamicSort('fullName')).filter(x => x.status == ClientStatus.Active || this.selectedValue.indexOf(x.id!) !== -1);
        this.comboData = this.coaches;
        this.initialized = true;
      });
  }

    handleFilter(value: any) {
      if (value.length >= 1) {
          this.comboData = this.coaches.filter(x => x.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      } else {
          this.comboData = this.coaches;
          this.multiselect!.toggle(false);
      }
  }
}

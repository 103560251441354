
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExerciseViewModelRead } from 'src/app/_models/generatedModels';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GeneratePDFService } from 'src/app/_services/generatePDF.service';

@Component({
  selector: 'print-exercise-dialog',
  templateUrl: 'print-exercise-dialog.component.html',
  styleUrls: ['print-exercise-dialog.component.scss'],
})
export class PrintExerciseDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  exercise: ExerciseViewModelRead;
  ready: boolean;

  @Input()
    exerciseId: number;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private exerciseService: ExerciseService, private pdfService: GeneratePDFService) { }

  ngOnInit(): void {
    this.exerciseService.getExerciseById(this.exerciseId).subscribe(result => {
      this.exercise = result;  
      this.formGroup = this.fb.group({});
      this.initialized = true;   
    });
  }

  onSave() {
    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.ready = true;

      setTimeout(() => {
        this.pdfService.createPDF('exercisePrintDiv', 'RunDNA Exercise - ' + this.exercise.name.replace(new RegExp("[\\\\/:*?\"<>|]", "g"), "")).then(result => {
          this.activeModal.dismiss('saved');
        });
      }, 250);
      
    });
  }
}

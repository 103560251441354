import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bxl-runner-workout',
  templateUrl: 'runner-workout.component.html'
})
export class RunnerWorkoutComponent implements OnInit {
  initialized = false;
  clientProgramWorkoutDayId: number;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.clientProgramWorkoutDayId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.initialized = true;
  }

  workoutSaved() {
    this.router.navigate(['/runner/calendar']);
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SettingsProvider } from './_services/settingsProvider.service';
import { interval, Subscription } from 'rxjs';
import { ClientStatus, UserRole, UserAuthProfileViewModel, BrandViewModelRead } from './_models/generatedModels';
import { UserStatusService } from './_services/userStatus.service';
import { AuthenticationService } from './_services/authentication.service';
import { BrandService } from './_services/generatedServices';
import moment from 'moment';
import { parseDateStringAsUTC } from './_helpers/extensions.module';
import { AiLoggingService } from './_services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  showVersionAlert: boolean = false;
  versionPolling: Subscription;
  userStatusSubscription: Subscription;
  userOrgChangedSubscription: Subscription;
  showAthleteInactiveAlert: boolean = false;
  brand: BrandViewModelRead;
  brandBannerActive: boolean = false;

  constructor(private router: Router, private http: HttpClient, public settingsProvider: SettingsProvider, private userStatus: UserStatusService, public auth: AuthenticationService,
    private brandService: BrandService, private aiLoggingService: AiLoggingService) {

    let win = (<any>window);
    var _hsq = win._hsq = win._hsq || [];

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        if (_hsq != undefined && !auth.impersonated) {
          _hsq.push(['setPath', event.url]);
          _hsq.push(['trackPageView']);
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

    if (this.auth.isLoggedIn()) {
      this.auth.fetchUserProfile().subscribe(user => {
        this.checkUserBrand(user);
      });
    }

    this.userStatusSubscription = this.userStatus.currentUserStatusValue.subscribe(user => {
      this.checkUserStatus(user);
    });

    this.userOrgChangedSubscription = this.userStatus.currentUserOrgChanged.subscribe(user => {
      this.checkUserBrand(user);
    });

    this.versionPolling = interval(1000 * 60 * 3).subscribe((val) => this.checkVersion());
  }

  checkUserStatus(user: UserAuthProfileViewModel) {
    if (user.status == ClientStatus.InActive && (user.userRole & UserRole.Runner)) {
      this.showAthleteInactiveAlert = true;
    } else {
      this.showAthleteInactiveAlert = false;
    }
  }

  checkUserBrand(user: UserAuthProfileViewModel) {
    this.brand = null;
    this.brandBannerActive = false;
    if (user.organizationBrandKeyName) {
      this.brandService.getBrandByKeyName(user.organizationBrandKeyName).subscribe(brand => {
        this.brand = brand;
        if (brand && brand.bannerText && brand.bannerTextExpirationDate) {
          if (parseDateStringAsUTC(this.brand.bannerTextExpirationDate).getTime() > (new Date()).getTime()) {
            this.brandBannerActive = true;
          }
        }
      });
    }
  }

  checkVersion() {
    // timestamp these requests to invalidate caches
    this.http.get('/api/settings/client?t=' + new Date().getTime()).subscribe((config: any) => {
      if (config.version != this.settingsProvider.configuration.version) {
        this.showVersionAlert = true;
      }
    });
  }

  onVersionAlertClosed(event: any) {
    this.showVersionAlert = false;
  }

  onUpdateVersion() {
    this.showVersionAlert = false;
    window.location.reload();
  }

  ngOnDestroy() {
    this.versionPolling.unsubscribe();
    this.userStatusSubscription.unsubscribe();
    this.userOrgChangedSubscription.unsubscribe();
  }
}

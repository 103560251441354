
<div class="">
  <!-- <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Favorite Workouts</h3>
    </div>
  </div> -->

  <runner-my-library-view [clientUserId]="clientUserId" [isCoachView]="true"></runner-my-library-view>
</div>



import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AcuteChronicHistoryViewModel, UserCredentialStatIntensityByActivityTypeViewModelRead } from 'src/app/_models/generatedModels';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/_services/generatedServices';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { dateWithoutTime, parseDateString } from 'src/app/_helpers/extensions.module';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'acute-chronic-list',
  templateUrl: 'acute-chronic-list.component.html',
  styleUrls: ['acute-chronic-list.component.scss']
})
export class AcuteChronicListComponent implements OnInit, OnDestroy {

  history: AcuteChronicHistoryViewModel[];
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  initialized: boolean;
  activityInitialized: boolean;
  chartData: any[];
  acwrByActivity: UserCredentialStatIntensityByActivityTypeViewModelRead;
  isTrainingLoadTargetIncomplete: boolean = true;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;


  constructor(private router: Router, private reportService: ReportsService, private charting: ChartingService) { }

  ngOnInit(): void {
    this.getACWRByActivity();
    this.getData();
  }

  getData() {
    const title = 'Acute/Chronic Workload Ratio %';
    const chartHeaders: any[] = [{ type: 'date', id: 'Date', label: 'Date' }, { type: 'number', id: 'ACWR %', label: title }];

    this.reportService.getACWRHistoryForClient(this.clientUserId, this.currentPage, this.itemsPerPage).subscribe(result => {
      this.totalItems = result.rowCount;
      this.history = result.results;

      this.chartData = result.results.filter(x => x.acuteChronicWorkloadRatioPercentage != null && x.acuteChronicWorkloadRatioPercentage != undefined).map(x => [parseDateString(x.activityDate), Math.round(x.acuteChronicWorkloadRatioPercentage * 100)]);
      this.charting.queueCharts(
        this.charting.drawLineChart.bind(this.charting),
        new ChartData(
            'acwrChart',
            chartHeaders,
            this.chartData,
            title
        )
      );
      this.initialized = true;
    });
  }

  getACWRByActivity() {
    const today = dateWithoutTime(new Date());
    forkJoin([this.reportService.getACWRByActivityTypeForUser(this.clientUserId), this.reportService.getCurrentWeekTrainingLoadTargetData(this.clientUserId, today)]).subscribe(result => {
      this.acwrByActivity = result[0];
      this.isTrainingLoadTargetIncomplete = result[1].isTrainingLoadTargetIncomplete;
      this.activityInitialized = true;
    });
  }

  pageChanged(event: any): void {
    this.getData();
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Acute to Chronic Workload Ratio Details</h3>
    </div>
  </div>
  <acute-chronic-list [clientUserId]="clientUserId" [isCoachView]="true"></acute-chronic-list>
</div>


import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { coachUserRoleArray } from '../_models/models';
import { BreadcrumbsService } from '../_services/breadcrumbs.service';

@Component({
  selector: 'bxl-runner-container',
  templateUrl: 'runner-container.component.html',
  styleUrls: ['runner-container.component.scss']
})
export class RunnerContainerComponent implements OnInit {
  initialized = false;
  hasCoachRole: boolean;


  constructor(public auth: AuthenticationService, private breadcrumbs: BreadcrumbsService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetBreadcrumb('Me', '/runner', []);
    this.auth.refreshClaims().subscribe(result => {
      this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);
      this.auth.fetchUserProfile().subscribe(user => {
        this.initialized = true;
      });
      
    });
  }
}

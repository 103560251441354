<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card">
    <div class="bg-dark">
      <header class="card-header py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Annual Training Plan Details</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body p-0">
        <div class="container mt-3">
          <div *ngIf="initialized">
            <h3 class="text-white">{{ plan.startDate | date }}</h3>
            <h3 class="text-white mb-2">{{ plan.name }}</h3>
            <div class="card p-3">
              <div>
                <span class="font-weight-bold">Starting Training Load: </span><span>{{ plan.startTrainingLoad }}</span>
              </div>
              <div>
                <span>{{ plan.startNotes }}</span>
              </div>
            </div>

            <ng-container *ngFor="let week of plan.trainingLoadPlannerWeeks">
              <div class="card detail-card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <span class="font-weight-bold">Week {{week.sortOrder}}: </span>{{week.startDate | date:'shortDate'}} &nbsp; &nbsp; &nbsp; {{week.trainingLoadPeriodType | enumString: trainingLoadPeriodTypeEnum}} Period
                    </div>
                    <div class="col-12">
                      <table class="table table-sm w-auto mt-1">
                        <thead class="table-bordered">
                          <tr>
                            <th>Suggested</th>
                            <th>Low</th>
                            <th>High</th>
                            <th class="d-none d-sm-none d-md-none d-lg-table-cell">RPE 1-4</th>
                            <th class="d-none d-sm-none d-md-none d-lg-table-cell">RPE 5-6</th>
                            <th class="d-none d-sm-none d-md-none d-lg-table-cell">RPE 7-8</th>
                            <th class="d-none d-sm-none d-md-none d-lg-table-cell">RPE 9-10</th>
                          </tr>
                        </thead>
                        <tbody class="table-bordered">
                          <tr>
                            <td class="text-right">{{week.suggestedTrainingLoad}}</td>
                            <td class="text-right">{{week.trainingLoadLow}}</td>
                            <td class="text-right">{{week.trainingLoadHigh}}</td>
                            <td class="text-right d-none d-sm-none d-md-none d-lg-table-cell">{{week.rpE1To4Load}}</td>
                            <td class="text-right d-none d-sm-none d-md-none d-lg-table-cell">{{week.rpE5To6Load}}</td>
                            <td class="text-right d-none d-sm-none d-md-none d-lg-table-cell">{{week.rpE7To8Load}}</td>
                            <td class="text-right d-none d-sm-none d-md-none d-lg-table-cell">{{week.rpE9To10Load}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-12" *ngIf="week.notes">
                      {{ week.notes }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input} from '@angular/core';
import { TwoDimensionalAssessmentResult, TwoDimensionalAssessmentViewModelRead } from 'src/app/_models/generatedModels';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'two-dimensional-result-details',
  templateUrl: 'two-dimensional-result-details.component.html',
  styleUrls: ['two-dimensional-result-details.component.scss']
})
export class TwoDimensionalAssessmentResultDetailsComponent implements OnInit {
  initialized = false;
  assessmentId: any;
  assessment: TwoDimensionalAssessmentViewModelRead;
  TwoDimensionalAssessmentResult = TwoDimensionalAssessmentResult;
  ImpairmentCategoryEnum = Enums.ImpairmentCategoryEnum;

  @Input()
  isCoachView: boolean = false;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService) {}

  ngOnInit(): void {
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    this.assessmentData.getTwoDimensionalAssessment(this.assessmentId).subscribe((result) => {
      this.assessment = result;
      this.initialized = true;
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Injectable()
export class MediaBreakpointService implements OnDestroy {
  public mediaBreakpointChanged: Subject<string> = new Subject();
  public currentBreakpoint: string = '';
  public isMobile: boolean = false;
  private oldBreakpoint: string;
  private _unsubscriber$: Subject<any> = new Subject();

  constructor() {
    this.onInit();
  }

  onInit() {
    this.SetBreakpoint(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(500), takeUntil(this._unsubscriber$))
      .subscribe((evt: any) => {
        this.SendMediaBreakpointChangedEvent(evt.target.innerWidth);
      });
  }

  SetBreakpoint(innerWidth: number) {
    this.oldBreakpoint = this.currentBreakpoint;
    this.isMobile = innerWidth < 576;
    this.currentBreakpoint = '';

    if (innerWidth >= 576) {
      this.currentBreakpoint = 'sm';
    }
    if (innerWidth >= 768) {
      this.currentBreakpoint = 'md';
    }
    if (innerWidth >= 992) {
      this.currentBreakpoint = 'lg';
    }
    if (innerWidth >= 1200) {
      this.currentBreakpoint = 'xl';
    }
    /* if (innerWidth >= 1590) {
      this.currentBreakpoint = 'xxl';
    } */
  }

  SendMediaBreakpointChangedEvent(innerWidth: number) {
    this.SetBreakpoint(innerWidth);
    if (this.oldBreakpoint != this.currentBreakpoint) {
      this.mediaBreakpointChanged.next(this.currentBreakpoint);
    }
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }
}

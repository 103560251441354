<page-load-header *ngIf="!initialized"></page-load-header>

<div class="row mt-3">
  <div class="col-lg-9 col-md-7">
    <div class="form-group">
      <label class="form-control-label"> &nbsp; </label>
      <custom-input-persisted class="form-control" [placeholder]="'Search by exercise name or keyword'" [key]="'exercises'" [(ngModel)]="filter" (valueChange)="onFilterChanged($event)"></custom-input-persisted>
    </div>
  </div>
  <div class="col-lg-3 col-md-5">
    <div class="form-group">
      <i class="far fa-filter mr-1"></i><label class="form-control-label"> Created By </label>
      <coaches-library-dropdown-persisted class="form-control" [key]="'exercises'" [(ngModel)]="coachFilter" (valueChange)="onCoachFilterChange($event)"></coaches-library-dropdown-persisted>
    </div>
  </div>
  <div class="col-12 text-sm">
    <i class="fas fa-sort-alt"></i> = included in an Exercise Progression
  </div>
</div>
<page-load-header *ngIf="searching"></page-load-header>
<div class="row mt-3">
  <div class="col">
    <div *ngIf="initialized && !searching" class="table-responsive">
      <table class="table align-items-center table-flush mobile-table">
        <thead class="thead-light">
          <tr class="header-row sortable">
            <th (click)="exercises.sort('Name')" [ngClass]="exercises.getCSSClass('Name')">Name</th>

            <th>Target Tissue</th>

            <th (click)="exercises.sort('User.Credential.FullName')" [ngClass]="exercises.getCSSClass('User.Credential.FullName')">Created By</th>

            <th class="text-lg-center">Actions</th>
          </tr>
        </thead>
        <tbody class="list">
          <tr *ngFor="let exercise of exercises">
            <td>
              <span class="mobile-grid-label">Name</span>
              <a href="javascript:void(0)" (click)="onPreview(exercise.id)">{{exercise.name}}</a><span *ngIf="exercise.inExerciseProgression"><i class="fas fa-sort-alt ml-1"></i></span></td>

            <td>
              <span class="mobile-grid-label">Target Tissue</span>
              <span *ngFor="let tag of exercise.exerciseTags; let last = last">
                <span>{{ tag.name }}{{ last ? '' : ', ' }}</span>
              </span></td>

            <td>
              <span class="mobile-grid-label">Created By</span>
              {{exercise.userFullName || "RunDNA"}}</td>

            <td class="text-lg-center">
              <span class="mobile-grid-label">Actions</span>
              <div class="btn-group no-caret">
                <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v"> </i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg-right">
                  <a *ngIf="exercise.organizationId || canEditGlobalLibrary" [routerLink]="[editUrl, exercise.id]" class="dropdown-item">
                    Edit
                  </a>
                  <a href="javascript:void(0)" (click)="onClone(exercise.id)" class="dropdown-item"> Clone </a>
                  <a href="javascript:void(0)" (click)="onShare(exercise.id, exercise.name)" class="dropdown-item"> Share with Athlete </a>
                  <a *ngIf="exercise.organizationId || canEditGlobalLibrary" href="javascript:void(0)" (click)="onDelete(exercise)" class="dropdown-item">
                    Delete
                  </a>
                  <!-- only displays if on large screen -->
                  <a href="javascript:void(0)" (click)="printPDF(exercise.id)" class="dropdown-item d-none d-lg-block">
                    Print PDF
                  </a>
                </div>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class=" card-footer py-4">
      <nav aria-label="...">
        <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
      </nav>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ClientsService, UserService } from 'src/app/_services/generatedServices';
import { UserLookupViewModel, AddRunnerViewModel, TeamUserRole, TeamUserViewModel, UserRole } from 'src/app/_models/generatedModels';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MaxAthleteSubscriptionService } from 'src/app/_services/maxAthleteSubscription.service';
import { coachUserRole } from 'src/app/_models/models';
import { EnumStringPipe } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'bxl-add-client',
  templateUrl: 'add-client.component.html',
})
export class AddClientComponent implements OnInit {
  initialized = false;
  emailLookupResult: UserLookupViewModel;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  errorMessage: string;
  isStep2: boolean;
  isPendingConfirm: boolean;
  duplicateEmailError: string;

  constructor(private router: Router, private fb: FormBuilder, private toastr: ToasterService, private clientsService: ClientsService, private usersService: UserService,
    private breadcrumbs: BreadcrumbsService, private validatorHelper: ValidatorHelper, private auth: AuthenticationService, private maxAthleteService: MaxAthleteSubscriptionService,
    private enumStringPipe: EnumStringPipe) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Add Client', 'add', []);

    this.maxAthleteService.isMaxActiveAthletes(true).subscribe(isMax => {
      if (!isMax) {
        this.setupForm();
      }
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email], [this.validatorHelper.validateEmailNotAlreadyInOrg(null)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: [''],
      yearOfBirth: [null, null],
      teamIds: [[]],
      isOfAge: [false, [Validators.requiredTrue]]
    });
    this.initialized = true;
  }

  onNext() {
    this.duplicateEmailError = null;
    if (this.formGroup.get('email').invalid) {
      this.clientsService.getClientByEmail(encodeURIComponent(this.formGroup.get('email').value)).subscribe(user => {
        if ((user.userRole & coachUserRole) != 0 && (user.userRole & UserRole.Runner) == 0) {
          this.duplicateEmailError = 'This user is a coach, go to Admin -> Users to add the Athlete role to their account.';
        } else {
          var status = this.enumStringPipe.transform(user.status, Enums.ClientStatusEnum);
          this.duplicateEmailError = 'This user has a status of ' + status + (user.coachFullName ? (' and is assigned to coach ' + user.coachFullName) : '') + '.';
        }
        this.formGroup.get('email').markAsDirty();
        this.toastr.error('Please fill out all required fields', 'Error');
        return;
      });
      
    } else {
      this.usersService.doesEmailExistInOtherOrganization(encodeURIComponent(this.formGroup.get('email').value)).subscribe((result) => {
        this.formGroup.get('email').disable();
        if (result) {
          this.isPendingConfirm = true;
        } else {
          this.isStep2 = true;
        }
      });
    }
  }

  onCancelPending() {
    this.router.navigate(['/athletes/list']);
  }

  onConfirmPending() {
    this.save();
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.save();
  }

  save() {
    this.submitComplete = new Promise((resetButton:any, reject) => {
      var teams = (this.formGroup.get('teamIds').value as number[]).map(x => {return { teamId: x, userId: 0, teamUserRole: TeamUserRole.Runner } as TeamUserViewModel});
      const formData: AddRunnerViewModel = this.formGroup.getRawValue();
      formData.coachUserId = this.auth.user.id;
      formData.teams = teams;

      this.clientsService.addNewClient(formData).subscribe((result) => {
        this.toastr.success('Athlete Added', 'Success');
        this.router.navigate(['/athletes/list']);
      });
    });
  }
}

import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter, OnChanges  } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExerciseService } from '../_services/generatedServices';
import { AuthenticationService } from '../_services/authentication.service';
import { WorkoutSummaryViewModelRead } from '../_models/generatedModels';
import { dynamicSort } from '../_helpers/extensions.module';


@Component({
    selector: 'workouts-dropdown',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <kendo-combobox #workoutCombo [data]="workoutData" [disabled]="isDisabled" [valuePrimitive]="true" [filterable]="true" [popupSettings]="{popupClass: 'df-list-scroller'}" valueField="id" textField="name" [(ngModel)]="selectedValue" class="{{cssClass}}" (filterChange)="handleFilter($event)" (valueChange)="onChange($event)">
      <ng-template kendoComboBoxItemTemplate let-dataItem>
          <div>
            <span>{{dataItem.name}}</span><span *ngIf="dataItem.inWorkoutProgression"><i class="fas fa-sort-alt ml-1"></i></span>
          </div>
      </ng-template>
    </kendo-combobox>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WorkoutDropdownControl),
            multi: true
        }
    ]
})

export class WorkoutDropdownControl implements ControlValueAccessor, AfterContentInit, OnChanges {

    someAttr: string = 'anyValue';

    constructor(elementRef: ElementRef, private data: ExerciseService, private auth: AuthenticationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    selectedValue: number;

    @Input()
    isDisabled: boolean;

    // this is used if the workouts dropdown is on a page that also allows a workout to be dynamically created and auto-selected
    @Input()
     newlyCreatedAndSelectedWorkoutId: number;

    @Output()
    changed = new EventEmitter<string>();

    @Output()
    changedObject = new EventEmitter<WorkoutSummaryViewModelRead>();

    workoutData: WorkoutSummaryViewModelRead[];
    rawWorkoutData: WorkoutSummaryViewModelRead[];
    @ViewChild('workoutCombo') public workoutCombo: ComboBoxComponent | undefined;
    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: number): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.changedObject.emit(this.rawWorkoutData.find(x => value === x.id));
        this.changed.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngAfterContentInit(): void {
        this.data.getAllWorkoutsForOrganization().subscribe(results => {
            this.rawWorkoutData = results.sort(dynamicSort('name'));
            this.workoutData = results.sort(dynamicSort('name'));
        });
    }

    ngOnChanges() {
      if (this.newlyCreatedAndSelectedWorkoutId) {
        // have to refresh the list if a new workout was created and then select it from the list
      this.data.getAllWorkoutsForOrganization().subscribe(results => {
        this.rawWorkoutData = results.sort(dynamicSort('name'));
        this.workoutData = results.sort(dynamicSort('name'));
        this.selectedValue = this.newlyCreatedAndSelectedWorkoutId;
      });
      }
    }

    handleFilter(value: any) {
        // added check on this.rawWorkoutData to prevent frontend error we were getting (that I can't duplicate locally)
        if (value.length >= 1 && this.rawWorkoutData) {
            this.workoutData = this.rawWorkoutData.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
            this.workoutData = this.rawWorkoutData;
            this.workoutCombo.toggle(false);
        }
    }
}

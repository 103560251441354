import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AccordionModule, AlertModule, BsDropdownModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HAXProgramsLayoutComponent } from './layouts/hax-programs-layout/hax-programs-layout.component';

import { AppRoutingModule } from './app-routing.module';
import { SiteNavModule } from './site-nav/site-nav.module';
import { SettingsProvider } from './_services/settingsProvider.service';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AuthenticationService } from './_services/authentication.service';
import { IsAuthenticatedGuard } from './_guards/isAuthenticated.guard';
import { IsSuperUserGuard } from './_guards/isSuperUser.guard';
import { ToasterService } from './_services/toaster.service';
import { AssessmentService, ClientsService, UserService, ExerciseService, ProgramsService ,OrganizationService, SuperUserService, ExternalWorkoutService, ExternalDailyService, VideoService, FileService, ReportsService, MessagingService, TeamService, NotificationsService, TrainingLoadPlannerService, RacePlanService, StravaService, GarminService, PaidProgramService, SurveyService, BrandService, TermsAndConditionsService} from './_services/generatedServices';
import { EnumStringPipe, EnumValuePipeTransform, FromMilesToLocalMeasurementPipe, MilesKilometersToMilesPipe, MinutesDecimalToStringPipe } from './_helpers/extensions.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TitleCasePipe } from 'src/app/_helpers/extensions.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ActiveClientService } from './_services/active-client.service';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { BreadcrumbsService } from './_services/breadcrumbs.service';
import { ValidatorHelper } from './_helpers/validatorHelper.module';
import { DailyStatusesService } from './_services/dailyStatuses.service';
import { BlobStorageService } from './_services/blob-storage.service';
import { BLOB_STORAGE_TOKEN, IAzureStorage } from './_services/azureStorage';
import { ChartingService } from './_services/charting.service';
import { RoleGuard } from './_guards/role.guard';
import { ExportService } from './_services/export.service';
import { IsOrgActiveGuard } from './_guards/isOrgActive.guard';
import { IsUserActiveGuard } from './_guards/isUserActive.guard';
import { UserStatusService } from './_services/userStatus.service';
import { MediaBreakpointService } from './_services/mediaBreakpoint.service';
import { ClientCalendarService } from './_services/clientCalendar.service';
import { TeamCalendarService } from './_services/teamCalendar.service';
import { SharedModule } from './shared.module';
import { ProgramHelper } from './_helpers/programs';
import { MaxAthleteSubscriptionService } from './_services/maxAthleteSubscription.service';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { CanDeactivateGuard } from './_guards/canDeactivate.guard';
import { IsAthleteActiveGuard } from './_guards/isAthleteActive.guard';
import { SurveyHandlerService } from './_services/surveyHandler.service';
import { GeneratePDFService } from './_services/generatePDF.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OrgMarketplaceLayoutComponent } from './layouts/org-marketplace-layout/org-marketplace-layout.component';
import { GlobalMarketplaceLayoutComponent } from './layouts/global-marketplace-layout/global-marketplace-layout.component';
import { OrgMarketplaceService } from './_services/orgMarketplace.service';
import { IntlModule, IntlService } from '@progress/kendo-angular-intl';
import { CustomIntlService } from './_services/customIntl.service';
import { AiLoggingService, AppInsErrorHandlerService } from './_services/logging.service';


declare var AzureStorage: IAzureStorage;

/* rg4js('apiKey', 'ZbsEPBMju24dQYF8pP4BOQ');
rg4js('enablePulse', true); */

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SiteNavModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    NgbModule,
    DropDownsModule,
    DialogsModule,
    InputsModule,
    SortableModule,
    IntlModule,
    AlertModule.forRoot(),
    NgCircleProgressModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    HAXProgramsLayoutComponent,
    PublicLayoutComponent,
    OrgMarketplaceLayoutComponent,
    GlobalMarketplaceLayoutComponent,
    TitleCasePipe
    ],
  providers: [
    AuthenticationService,
    AssessmentService,
    ClientsService,
    OrganizationService,
    ExerciseService,
    ProgramsService,
    IsAuthenticatedGuard,
    ToasterService,
    UserService,
    ActiveClientService,
    IsSuperUserGuard,
    MessagingService,
    SettingsProvider,
    AiLoggingService,
    AppInsErrorHandlerService,
    BreadcrumbsService,
    ValidatorHelper,
    DailyStatusesService,
    BlobStorageService,
    SuperUserService,
    ExternalWorkoutService,
    ChartingService,
    MinutesDecimalToStringPipe,
    RoleGuard,
    ExternalDailyService,
    VideoService,
    FileService,
    ReportsService,
    ExportService,
    IsOrgActiveGuard,
    IsUserActiveGuard,
    TeamService,
    NotificationsService,
    TrainingLoadPlannerService,
    RacePlanService,
    UserStatusService,
    StravaService,
    MediaBreakpointService,
    MilesKilometersToMilesPipe,
    EnumStringPipe,
    ClientCalendarService,
    TeamCalendarService,
    GarminService,
    PaidProgramService,
    ProgramHelper,
    SurveyService,
    MaxAthleteSubscriptionService,
    CanDeactivateGuard,
    IsAthleteActiveGuard,
    SurveyHandlerService,
    BrandService,
    TermsAndConditionsService,
    GeneratePDFService,
    OrgMarketplaceService,
    FromMilesToLocalMeasurementPipe,
    {
      provide: BLOB_STORAGE_TOKEN,
      useValue: AzureStorage.Blob
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: init, deps: [SettingsProvider, HttpClient, AiLoggingService], multi: true },
    { provide: ErrorHandler, useClass: AppInsErrorHandlerService },
    { provide: IntlService, useClass: CustomIntlService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function init(settingsProvider: SettingsProvider, http: HttpClient, aiLogging: AiLoggingService) {
  return () =>
  
    settingsProvider.loadConfig(http).then(() => aiLogging.init(settingsProvider));
   
  
}

<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Mark {{event.isCompleted ? 'Incomplete' : 'Complete'}}</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <div class="card-body">
    <h5>{{event.workoutName}}</h5>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
      </div>
      <div class="col text-right">
        <button type="button" class="btn btn-primary" (click)="onSave()">Mark {{event.isCompleted ? 'Incomplete' : 'Complete'}}</button>
      </div>
    </div>
  </div>
</div>

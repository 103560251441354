<page-load-header *ngIf="!initialized"></page-load-header>

<ng-container *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <h6 class="text-white">{{externalWorkout.activityType}}</h6>
    </div>
    <div class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Distance</h6>
          <span class="mb-0" [ngClass]="{'h5 font-weight-bold': externalWorkout.distance}">{{externalWorkout.distance ? (externalWorkout.distance | number : '1.2-2') : 'No Data'}}</span><span *ngIf="externalWorkout.distance"> {{localMeasurementName}}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Time</h6>
          <span class="h5 font-weight-bold mb-0">{{externalWorkout.durationInSeconds | secsToHourMinSec}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="externalWorkout.activityTypeEnum != activityType.Biking" class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Avg Pace</h6>
          <span class="mb-0" [ngClass]="{'h5 font-weight-bold': externalWorkout.averagePace}">{{externalWorkout.averagePace ? (externalWorkout.averagePace | minutesDecimalToString) : 'No Data'}}</span><span *ngIf="externalWorkout.averagePace"> min/{{localMeasurementName}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="externalWorkout.activityTypeEnum == activityType.Biking" class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Avg Speed</h6>
          <span class="mb-0" [ngClass]="{'h5 font-weight-bold': externalWorkout.averageSpeed}">{{externalWorkout.averageSpeed ? (externalWorkout.averageSpeed | number : '1.2-2') : 'No Data'}}</span><span *ngIf="externalWorkout.averageSpeed"> {{localMeasurementName}}/hr</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Elev Gain</h6>
          <span class="mb-0" [ngClass]="{'h5 font-weight-bold': externalWorkout.totalElevationGain}">{{externalWorkout.totalElevationGain ? (externalWorkout.totalElevationGain | number : '1.0-0') : 'No Data'}}</span><span *ngIf="externalWorkout.totalElevationGain"> {{localMeasurementElevationName}}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Calories</h6>
          <span class="mb-0" [ngClass]="{'h5 font-weight-bold': externalWorkout.activeKilocalories}">{{externalWorkout.activeKilocalories ? externalWorkout.activeKilocalories : 'No Data'}}</span><span *ngIf="externalWorkout.activeKilocalories"> C</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-6">
      <div class="card card-stats">
        <div class="card-body text-center">
          <h6 class="card-title text-uppercase text-muted mb-0">Avg HR</h6>
          <span class="mb-0" [ngClass]="{'h5 font-weight-bold': externalWorkout.averageHeartRateInBeatsPerMinute}">{{externalWorkout.averageHeartRateInBeatsPerMinute ? externalWorkout.averageHeartRateInBeatsPerMinute : 'No Data'}}</span><span *ngIf="externalWorkout.averageHeartRateInBeatsPerMinute"> bpm</span>
        </div>
      </div>
    </div>
  </div>

  <external-workout-route-map [isModal]="isSummaryOnly" [coordinates]="externalWorkout.routeCoordinates" [polyline]="externalWorkout.polyline" [clientUserId]="clientUserId"></external-workout-route-map>

  <div *ngIf="externalWorkout.stravaObjectId" class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-body text-center">
          <a class="d-block" href="https://www.strava.com/activities/{{externalWorkout.stravaObjectId}}" target="_blank">View on Strava</a>
          <img class="d-inline-block" style="height: 35px;" src="../../../../../assets/img/strava/strava_powered.png" />
        </div>
      </div>
    </div>
  </div>
</ng-container>

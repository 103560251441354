<page-load-header *ngIf="!initialized"></page-load-header>

<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-4">
          <h6 class="h2 d-inline-block mb-0">Wearable Integrations</h6>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="initialized && !hasAthleteRoleInCurrentOrg" class="container-fluid mb-6 text-center">
  In order to link with Garmin or Strava, you must be an Athlete in the organization. <span *ngIf="hasAthleteRoleInOtherOrg">Switch organizations to link your Garmin or Strava account.</span>
</div>
<div *ngIf="initialized && hasAthleteRoleInCurrentOrg" class="container-fluid">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div>
        <label class="form-control-label mr-1" for="excludeGPSData">
          Exclude GPS Map Data?
        </label>
        <kendo-switch [(ngModel)]="excludeGPSData" (valueChange)="onGPSChange($event)"></kendo-switch>
      </div>
    </div>
    <div class="col-12">
      If you are active military, we highly recommend you exclude GPS map data from being imported. Any activities imported while this flag is set to true will not have the GPS map data imported into RunDNA; all other data will be imported.
    </div>
    <div class="col-12 mt-4">
      <i class="far fa-question-circle" style="vertical-align: text-top;"></i> View the <a href="https://rundnahelp.zendesk.com/hc/en-us/categories/14090594687383-RunDNA-Portal-Athletes" target="_blank"> <span>Help Center</span> </a>
      to see more information on how to connect your wearable device and view synced workouts.
    </div>
  </div>
  <div class="card mt-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8"><h3 class="mb-0">Garmin</h3></div>

        <div class="col-4 text-right">
          <a *ngIf="!user.garminLinked" role="button" class="btn btn-lg btn-primary" href="/garmin/initiate-garmin-oauth?access_token={{ auth.getToken() }}" type="button">Link Garmin</a>
          <button *ngIf="user.garminLinked" class="btn btn-lg btn-primary" type="button" (click)="unlinkGarmin()">Unlink</button>
        </div>
        <div class="col-12 mt-2">
          <alert *ngIf="user.garminLinked && !user.garminTrainingPermission" class="text-center" type="warning" [dismissible]="false">
            <div>
              To send your Garmin-compatible RunDNA workouts to Garmin, <a href="https://rundnahelp.zendesk.com/hc/en-us/articles/17051184152727-Connect-wearable-devices" target="_blank">click here</a> for instructions.
            </div>
          </alert>
        </div>
      </div>
    </div>
  </div>

  
  
  <div class="card mt-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8"><h3 class="mb-0">Strava</h3></div>

        <div class="col-4 text-right">
          <a  *ngIf="!user.stravaLinked"  role="button" class="" href="/strava/initiate-strava-oauth?access_token={{ auth.getToken() }}" type="button">
            <img src="../../../../../assets/img/strava/strava_connect.png" />
          </a>
         <button *ngIf="user.stravaLinked" class="btn btn-lg btn-primary" type="button" (click)="unlinkStrava()">Unlink</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-right">
      <img style="height: 35px;" src="../../../../../assets/img/strava/strava_powered.png" />
    </div>
  </div>
</div>

import { Component, Input, EventEmitter, Output, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxOption } from 'src/app/_models/models';

@Component({
  selector: 'flag-enum-button-control',
  templateUrl: 'flagEnumButtonsControl.component.html',
  styleUrls: ['flagEnumButtonsControl.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlagEnumButtonsControl),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FlagEnumButtonsControl),
      multi: true,
    }
  ],
})
export class FlagEnumButtonsControl implements ControlValueAccessor, OnInit {
  selectedValue: any;
  checkboxOptions: CheckboxOption[] = [];
  showError: boolean = false;

  @Input()
  enumValues: {[s: number]: number | string};

  @Input()
  enumAsMap: Map<number, string>;

  @Input()
  isRequired: boolean = false;

  @Input()
  disabled = false;

  @Output()
  valueChange = new EventEmitter<any>();

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: any): void {
    this.selectedValue = value;
    this.checkboxOptions.forEach(day => {
      let dayName = day.name.replace(' ', '');
      if (this.selectedValue & this.enumValues[dayName]) {
        day.selected = true;
      } else {
        day.selected = false;
      }
    });
  }

  registerOnChange(fn: any): void {
      this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.propagateTouch = fn;
  }

  onChange(value: any) {
    this.selectedValue = value;
    this.propagateChange(this.selectedValue);
    this.propagateTouch();
    this.valueChange.emit(value);
  }

  constructor() {}

  ngOnInit() {
    // create all checkbox options
    this.enumAsMap.forEach((value, key) => {
      this.checkboxOptions.push(new CheckboxOption(value, value, key, false));
      //this.checkboxOptions.push(new CheckboxOption(value, value.substr(0, 1), key, false));
    });
  }

  onOptionChange(option: any) {
    if (!this.disabled) {
      this.selectedValue = null;
      let selectedOptions = this.checkboxOptions.filter(x => x.selected);
      selectedOptions.forEach(day => {
        let dayName = day.name.replace(' ', '');
        this.selectedValue |= this.enumValues[dayName];
      });

      this.onChange(this.selectedValue);
    }
  }

  validate({ value }: FormControl) {
    if (this.isRequired && !this.checkboxOptions.some(x => x.selected)) {
      return { required: true };
    }
  }
}

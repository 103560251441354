import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { OrganizationBillingViewModel, OrganizationInvoiceViewModel } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ActivateSubscription } from './activate-subscription/activate-subscription.component';
import { UpdateCard } from './update-card/update-card.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'bxl-admin-billing',
  templateUrl: 'admin-billing.component.html',
})
export class AdminBillingComponent implements OnInit {
  initialized = false;
  organization: OrganizationBillingViewModel;
  invoices: OrganizationInvoiceViewModel[];
  subscriptionNote: string;

  constructor(private breadcrumbs: BreadcrumbsService, private organizationService: OrganizationService, private modalService: NgbModal, private toastr: ToasterService, private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Billing', 'billing', []);

    forkJoin([this.organizationService.getOrganizationBillingDetails(), this.organizationService.getInvoicesForOrganization()]).subscribe((results) => {
      this.organization = results[0];
      this.invoices = results[1];
      if (this.organization.complimentarySubscription) {
        this.subscriptionNote = 'Your organization has a complimentary subscription';
        this.subscriptionNote += this.organization.complimentarySubscriptionEndDate ? ' that expires on ' + formatDate(this.organization.complimentarySubscriptionEndDate, 'MMM d, y', 'en-US') : '';
      }
      this.initialized = true;
    });
  }

  activateSubscription() {
    if (this.organization.runnerSlotSubscriptionId && this.organization.subscriptionEndDate && this.organization.last4OfCard) {
      this.toastr.confirmDialog('Are you sure you want to re-activate your organization\'s subscription? (We will use the card on file)', 'Re-Activate Subscription', 'Re-Activate', 'Cancel').subscribe(result => {
        if (result) {
          this.organizationService.reActivateExistingSubscription().subscribe(results => {
            this.organization = results;
            // clear the user profile cache so that the user's subscription status is updated
            this.auth.fetchUserProfile(false, true).subscribe();
            this.updateInvoiceList();
            this.toastr.success('Subscription re-activated', 'Success');
          });
        }
      });
    } else {
      const modalRef = this.modalService.open(ActivateSubscription, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      modalRef.componentInstance.organization = this.organization;
      modalRef.result.then(
        (company: OrganizationBillingViewModel) => {
          this.organization = company;
          this.updateInvoiceList();
        },
        (reason) => {}
      );
    }
  }

  updateCard() {
    const modalRef = this.modalService.open(UpdateCard, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.result.then(
      (company: OrganizationBillingViewModel) => {
        this.organization = company;
      },
      (reason) => {}
    );
  }

  cancelSubscription() {
    this.organizationService.retrieveSubscription().subscribe(result => {
      var endDate = new Date(result.currentPeriodEnd);
      this.toastr.confirmModal('Are you sure you want to cancel your organization\'s subscription? You will continue to have access until ' + formatDate(endDate, 'MMM d, y', 'en-US') + ' when your billing cycle ends.' + 
          '<p class="text-danger font-weight-400">After the subscription is canceled and the billing cycle ends, all scheduled athlete and team calendar data for future dates (date after end of billing cycle) will be permanently deleted. Historical calendar data will remain and can be accessed again by re-starting the subscription.</p>', 'Cancel Subscription', 'Cancel Subscription', 'Cancel').subscribe(result => {
        if (result) {
          this.organizationService.cancelSubscription().subscribe(results => {
            this.organization = results;
            this.updateInvoiceList();
            this.toastr.success('Subscription canceled', 'Success');
          });
        }
      });
    });

  }

  updateInvoiceList() {
    this.organizationService.getInvoicesForOrganization().subscribe(result => {
      this.invoices = result;
    });
  }
}

<!-- <div class="multi-option">
  <span class="option-button" [ngClass]="{ active: leftOption.value == selectedValue, disabled: disabled, 'three-button': middleOption, 'two-button': !middleOption }" (click)="onOptionSelected(leftOption)">{{leftOption.label}}</span>
  <span *ngIf="middleOption" class="option-button three-button" [ngClass]="{ active: middleOption.value == selectedValue, disabled: disabled }" (click)="onOptionSelected(middleOption)">{{middleOption.label}}</span>
  <span class="option-button" [ngClass]="{ active: rightOption.value == selectedValue, disabled: disabled, 'three-button': middleOption, 'two-button': !middleOption }" (click)="onOptionSelected(rightOption)">{{rightOption.label}}</span>
</div> -->
<div class="btn-group checkbox-buttons">
  <ng-container *ngFor="let option of checkboxOptions">
    <label [ngClass]="{'active btn-primary': option.selected, 'btn-secondary' : !option.selected}" class="btn btn-spaced">
      <input type="checkbox" [(ngModel)]="option.selected" (ngModelChange)="onOptionChange($event)">{{option.displayName}}
    </label>
  </ng-container>
</div>
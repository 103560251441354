<div id="confirmModal">
  <div class="card mb-0">
    <!-- Login -->
    <div id="login" data-target-group="idForm">
      <!-- Header -->
      <header class="card-header bg-light">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="h5 mb-0">{{ title }}</h3>
​
          <button *ngIf="!showSpinner" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <!-- End Header -->
​
      <div class="card-body">
        <div class="row p-2">
          <div class="col-md-12" [ngClass]="{ 'text-center': showSpinner }" innerHTML="{{ message }}"></div>
          <page-load-header *ngIf="showSpinner" class="mx-auto"></page-load-header>
        </div>
      </div>
      <div *ngIf="!showSpinner" class="card-footer p-2">
        <div class="d-flex">
          <button type="button" *ngIf="!singleButton" class="btn btn-block btn-secondary transition-3d-hover mr-1" (click)="activeModal.dismiss('Cross click')">
            {{ cancel }}
          </button>
          <button type="button" class="btn btn-block btn-primary transition-3d-hover ml-1 mt-0" (click)="activeModal.close()">
            {{ ok }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bxl-workout-view-modal',
  templateUrl: 'workout-view-modal.component.html',
})
export class WorkoutViewModalComponent implements OnInit {
  initialized = false;

  @Input()
  workoutId: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initialized = true;

  }
}

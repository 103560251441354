import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-view-loading-levels',
  templateUrl: 'view-loading-levels.component.html',
})
export class ViewLoadingLevelsComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }

}

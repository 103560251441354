<page-load-header *ngIf="!initialized"></page-load-header>

<div class="card-body">
  <div class="row no-gutters">
    <div class="col-lg-12">
      <h3 class="mb-0 d-inline">Workouts</h3>
      <div class="float-right">
        <div>Workout Types<div class="d-inline text-center" kendoTooltip>
          <i class="fas fa-info-circle ml-2" title="Garmin workouts are formatted to allow the athlete to send the workout to their Garmin device."></i>
        </div></div>
        <div class="btn-group mr-3">
          <div class="btn-group" ngbDropdown role="group" aria-label="Workout dropdown">
            <button class="btn btn-primary" ngbDropdownToggle>Add Workout</button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button [routerLink]="['add', workoutType.Cardio]" ngbDropdownItem>Cardio Workout</button>
              <button [routerLink]="['add', workoutType.Strength]" ngbDropdownItem>Strength Workout</button>
              <button [routerLink]="['add', workoutType.GarminRunning]" ngbDropdownItem>Garmin Running Workout</button>
              <button [routerLink]="['add', workoutType.GarminCycling]" ngbDropdownItem>Garmin Cycling Workout</button>
              <button [routerLink]="['add', workoutType.GarminLap_Swimming]" ngbDropdownItem>Garmin Lap Swimming Workout</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="col">Build workouts to use for your athletes</div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-9 col-md-7">
      <div class="form-group">
        <label class="form-control-label"> &nbsp; </label>
        <custom-input-persisted class="form-control" [placeholder]="'Search by workout name or keyword'" [key]="'workouts'" [(ngModel)]="filter" (valueChange)="onFilterChanged($event)"></custom-input-persisted>
      </div>
    </div>
    <div class="col-lg-3 col-md-5">
      <div class="form-group">
        <i class="far fa-filter mr-1"></i><label class="form-control-label"> Created By </label>
        <coaches-library-dropdown-persisted class="form-control" [key]="'workouts'" [(ngModel)]="coachFilter" (valueChange)="onCoachFilterChange($event)"></coaches-library-dropdown-persisted>
      </div>
    </div>
    <div class="col-12 text-sm">
      <i class="fas fa-sort-alt"></i> = included in a Workout Progression
    </div>
  </div>
  <page-load-header *ngIf="searching"></page-load-header>
  <div class="row mt-2">
    <div class="col">
      <div  *ngIf="initialized && !searching" class="table-responsive">
        <table class="table align-items-center table-flush mobile-table">
          <thead class="thead-light">
            <tr class="header-row sortable">
              <th (click)="workouts.sort('Name')" [ngClass]="workouts.getCSSClass('Name')">Name</th>

              <th (click)="workouts.sort('ExpectedTime')" [ngClass]="workouts.getCSSClass('ExpectedTime')">Expected Time</th>

              <th>Workout Type</th>

              <th>Goal</th>

              <th>Keywords</th>

              <th (click)="workouts.sort('User.Credential.FullName')" [ngClass]="workouts.getCSSClass('User.Credential.FullName')">Created By</th>

              <th class="text-lg-center">Actions</th>
            </tr>
          </thead>
          <tbody class="list">
            <tr *ngFor="let workout of workouts">
              <td>
                <span class="mobile-grid-label">Name</span>
                <a href="javascript:void(0)" (click)="onPreview(workout.id)">{{workout.name}}</a><span *ngIf="workout.inWorkoutProgression"><i class="fas fa-sort-alt ml-1"></i></span></td>

              <td><span class="mobile-grid-label">Expected Time</span>
                {{ workout.expectedTime | secsToHourMinSec }}</td>

              <td><span class="mobile-grid-label">Workout Type</span>
                {{ workout.workoutType | enumString: workoutTypeEnum }}</td>

              <td style="white-space: normal;"><span class="mobile-grid-label">Goal</span>
                {{ workout.goal }}</td>

                <td style="white-space: normal;">
                  <span class="mobile-grid-label">Keywords</span>
                  <span *ngFor="let keyword of workout.workoutKeywords; let last = last">
                    <span>{{ keyword.name }}{{ last ? '' : ', ' }}</span>
                  </span>
                </td>

              <td><span class="mobile-grid-label">Created By</span>
                {{ workout.userFullName || 'RunDNA' }}</td>

              <td class="text-lg-center">
                <span class="mobile-grid-label">Actions</span>
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-lg-right">
                    <a *ngIf="workout.organizationId || canEditGlobalLibrary" [routerLink]="[editUrl, workout.id]" class="dropdown-item">
                      Edit
                    </a>
                    <a href="javascript:void(0)" (click)="onClone(workout.id)" class="dropdown-item">
                      Clone
                    </a>
                    <a href="javascript:void(0)" (click)="onShare(workout.id, workout.name)" class="dropdown-item"> Share with Athlete </a>
                    <a *ngIf="workout.organizationId || canEditGlobalLibrary" href="javascript:void(0)" (click)="onDelete(workout)" class="dropdown-item">
                      Delete
                    </a>
                    <!-- only displays if on large screen -->
                    <a href="javascript:void(0)" (click)="printPDF(workout.id)" class="dropdown-item d-none d-lg-block">
                      Print PDF
                    </a>
                    <a *ngIf="(workout.organizationId || canEditGlobalLibrary) && workout.workoutType == workoutType.Cardio" href="javascript:void(0)" (click)="onConvertToGarmin(workout.id)" class="dropdown-item">
                      Convert to Garmin Workout
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer py-4">
        <nav aria-label="...">
          <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
        </nav>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

// core components
@Component({
  selector: 'client-journal',
  templateUrl: 'client-journal.component.html',
  styleUrls: ['client-journal.component.scss'],
})
export class ClientJournalComponent implements OnInit {

  initialized: boolean;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router) {}

  ngOnInit() {
    this.clientUserId = parseInt(getParamFromRoute(this.route, 'id'));
    this.breadcrumbs.SetBreadcrumb('Journal', this.router.url, []);
    this.initialized = true;
  }
}

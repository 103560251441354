import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { dateWithoutTime } from 'src/app/_helpers/extensions.module';
import { UserCredentialJournalEntryViewModel } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'general-journal-dialog',
  templateUrl: 'general-journal-dialog.component.html'
})
export class GeneralJournalDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  fromUserCredentialId: number;
  today: Date;

  @Input()
    clientUserId: number;

  constructor(private fb: FormBuilder, private toastr: ToasterService, private auth: AuthenticationService, public activeModal: NgbActiveModal, public clientService: ClientsService) { }

  ngOnInit(): void {
    this.today = dateWithoutTime(new Date());
    this.auth.fetchUserProfile().subscribe(user => {
      this.fromUserCredentialId = user.userCredentialId;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      userId: [this.clientUserId],
      fromUserCredentialId: [this.fromUserCredentialId],
      note: [null, Validators.required]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: UserCredentialJournalEntryViewModel = Object.assign({}, this.formGroup.getRawValue());
      this.clientService.addUserCredentialJournalEntry(this.clientUserId, formData).subscribe(result => {
        this.toastr.success('Journal Entry Created', 'Success');
        this.activeModal.dismiss('saved');
      });
    });
  }
}

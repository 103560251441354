<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="entries.length === 0" class="mt-3 ml-3 mb-2">No daily statuses</div>
  <div *ngIf="entries && entries.length > 0" class="table-responsive">
    <table class="table align-items-left table-flush">
      <thead class="thead-light">
        <tr>
          <th class="sort" data-sort="name" scope="col">Date</th>
          <th class="sort" data-sort="name" scope="col">Run</th>
          <th class="sort" data-sort="budget" scope="col">Pain</th>
          <th class="sort" data-sort="budget" scope="col">Sick</th>
          <th class="sort d-none d-lg-none d-xl-block" data-sort="budget" scope="col">Max Effort</th>
        </tr>
      </thead>

      <tbody class="list">
        <tr *ngFor="let entry of entries">
          <td class="budget">
            <a href="javascript:void(0)" (click)="openDetails(entry.id)">{{ entry.dailyStatusDate | date: 'shortDate' }}</a>
          </td>
          <td class="journal-icon" *ngIf="entry.ableToRun"><i class="far fa-smile text-success"></i></td>
          <td class="journal-icon" *ngIf="!entry.ableToRun"><i class="far fa-frown text-danger"></i></td>
          <td class="journal-icon" *ngIf="!entry.hasPain"><i class="far fa-smile text-success"></i></td>
          <td class="journal-icon" *ngIf="entry.hasPain"><i class="far fa-frown text-danger"></i></td>
          <td class="journal-icon" *ngIf="!entry.isSick"><i class="far fa-smile text-success"></i></td>
          <td class="journal-icon" *ngIf="entry.isSick"><i class="far fa-frown text-danger"></i></td>
          <td class="d-none d-lg-none d-xl-block">{{entry.percentEffort}}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


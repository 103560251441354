<page-load-header *ngIf="!initialized"></page-load-header>

<!-- desktop view -->
<div *ngIf="initialized" class="d-none d-lg-block">
  <div class="">
    <div class="row">
      <div class="col">
        <div class="card card-calendar">
          <div class="card-header sticky-top">
            <div class="align-items-center d-flex justify-content-between">
              <a href="javascript:void(0)" (click)="onToday()" class="btn btn-sm btn-neutral"> Today </a>
                <h5 class="h3 mb-0">{{ monthName }} {{ yearName }}</h5>
              <div class="mt-3 mt-lg-0">
                <a href="javascript:void(0)" (click)="onPrevious()" class="fullcalendar-btn-prev btn btn-sm btn-neutral"><i class="fas fa-angle-left"></i></a>
                <a href="javascript:void(0)" (click)="onNext()" class="fullcalendar-btn-next btn btn-sm btn-neutral"><i class="fas fa-angle-right"></i></a>
                <a href="javascript:void(0)" (click)="onViewChange(true)" class="btn btn-sm" [ngClass]="{ 'btn-primary': isMonthView, 'btn-neutral': !isMonthView }"> Month </a>
                <a href="javascript:void(0)" (click)="onViewChange(false)" class="btn btn-sm btn-neutral" [ngClass]="{ 'btn-primary': !isMonthView, 'btn-neutral': isMonthView }"> Week </a>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="calendar fc fc-ltr fc-unthemed">
              <div class="fc-view-container">
                <div class="fc-view fc-dayGridMonth-view fc-dayGrid-view">
                  <table class="">
                    <thead class="fc-head sticky-top" style="top: 56px;">
                      <tr>
                        <td class="fc-head-container fc-widget-header">
                          <div class="fc-row fc-widget-header">
                            <table class="day-header">
                              <thead>
                                <tr>
                                  <th class="fc-day-header fc-widget-header fc-mon"><span>Mon</span></th>
                                  <th class="fc-day-header fc-widget-header fc-tue"><span>Tue</span></th>
                                  <th class="fc-day-header fc-widget-header fc-wed"><span>Wed</span></th>
                                  <th class="fc-day-header fc-widget-header fc-thu"><span>Thu</span></th>
                                  <th class="fc-day-header fc-widget-header fc-fri"><span>Fri</span></th>
                                  <th class="fc-day-header fc-widget-header fc-sat"><span>Sat</span></th>
                                  <th class="fc-day-header fc-widget-header fc-sun"><span>Sun</span></th>
                                  <th *ngIf="isMonthView" class="fc-day-header fc-widget-header fc-summary text-center totals-column">
                                    <span>Totals</span>
                                    <div class="d-inline text-center" kendoTooltip>
                                      <i class="fas fa-info-circle ml-2" title="{{ trainingLoadInfo }}"></i>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody class="fc-body">
                      <tr>
                        <td class="fc-widget-content">
                          <div class="fc-scroller fc-day-grid-container" style="overflow: hidden; height: 767px">
                            <div class="fc-day-grid">
                              <div *ngFor="let week of weeks; let w = index" class="fc-row fc-week fc-widget-content" style="min-height: 127px">
                                <div class="fc-bg">
                                  <table class="">
                                    <tbody>
                                      <tr>
                                        <td class="fc-day fc-widget-content fc-mon"></td>
                                        <td class="fc-day fc-widget-content fc-tue"></td>
                                        <td class="fc-day fc-widget-content fc-wed"></td>
                                        <td class="fc-day fc-widget-content fc-thu"></td>
                                        <td class="fc-day fc-widget-content fc-fri"></td>
                                        <td class="fc-day fc-widget-content fc-sat"></td>
                                        <td class="fc-day fc-widget-content fc-sun"></td>
                                        <td *ngIf="isMonthView" class="fc-day fc-widget-content fc-summary totals-column"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="fc-bg fc-content-skeleton" style="min-height: 127px">
                                  <table style="height: 100%; min-height: 127px">
                                    <thead>
                                      <tr>
                                        <ng-container *ngFor="let day of week.days; let d = index">
                                          <td class="fc-day-top" [ngClass]="{ 'fc-other-month': !day.isCurrentMonth }" (click)="onDayClick($event, day.calendarDate, w, d)">
                                            <span *ngIf="day.dayOfMonth == 1" class="fc-day-number month-name">{{ day.monthShortName }}</span>
                                            <span *ngIf="!day.isToday" class="fc-day-number">{{ day.dayOfMonth }}</span>
                                            <span id="todayElem" *ngIf="day.isToday" class="fc-day-number fc-day-number-today fa-stack" [ngClass]="{ 'fc-day-number-today-small': day.dayOfMonth < 10 }">
                                              <i class="fas fa-circle"></i>
                                              <span class="fa-inverse">{{ day.dayOfMonth }}</span>
                                            </span>
                                            <span *ngIf="d == 0 && hasCoachRole" class="float-right mr-1">
                                              <a href="javascript:void(0)" (click)="onCopyWeek($event, day.calendarDate)">
                                                <i class="fas fa-copy"></i>
                                              </a>
                                            </span>
                                          </td>
                                        </ng-container>
                                        <!-- <td class="fc-day-top fc-summary text-right"> -->
                                        <td *ngIf="isMonthView" class="fc-day-top fc-summary totals-column">
                                          <!-- <span class="fc-day-number"> -->
                                          <span class="fc-day-number px-2">
                                            <!-- <a href="javascript:void(0)" class="font-weight-400"> View More </a> -->
                                            Week Training Load
                                          </span>
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td *ngFor="let day of week.days; let d = index" (click)="onDayClick($event, day.calendarDate, w, d)" class="custom-event-container">
                                          <kendo-sortable [kendoSortableBinding]="day.events" class="d-flex flex-column" [emptyText]="''" [emptyItemStyle]="{ height: '100%' }" [activeItemStyle]="{ opacity: '0.7', border: '2px dashed black' }" (dragStart)="onDragStart($event, w, d)" (dragEnd)="onDragEnd($event, w, d)" (dragOver)="onDragOver($event)" zone="dayZone">
                                            <ng-template let-event="item" let-e="index">
                                              <div (click)="onEventClick($event, event)" class="custom-event" [ngClass]="{ 'completed': event.isCompleted, 'selected': event.eventId == selectedEventId }">
                                                <div class="pl-1 event-activity-type">
                                                  <i class="far {{ event.singleActivityType | customCalendarEventIcon: event.eventType:(event.workout ? event.workout.workoutType : null) }}"></i>
                                                  <span *ngIf="event.timeInSeconds != null">{{ event.timeInSeconds | secsToHourMinOnly }}</span>
                                                  <span *ngIf="event.miles">{{ (event.miles | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                                                </div>
                                                <div class="custom-event-title-wrapper">
                                                  <span *ngIf="event.recurringEvent" class="mr-1"><i class="far fa-repeat sized-icon"></i></span>
                                                  <span *ngIf="event.isTeamEvent" class="mr-1 team-icon"><i class="fas fa-user-friends sized-icon"></i></span>
                                                  <span *ngIf="event.coachNotes && event.eventType == eventType.RestDay" class="mr-1"><i class="far fa-sticky-note"></i></span>
                                                  <span class="event-title">{{ event.workoutName }}</span>
                                                </div>
                                                <div *ngIf="event.eventId == selectedEventId" (clickOutside)="onClickedOutsideMenu($event)" class="custom-event-actions d-flex justify-content-around align-items-center">
                                                  <a *ngIf="event.contextMenuOptions | flagEnumCompare:contextMenuOptions.View" href="javascript:void(0)" (click)="onEventInfoClicked($event, event)">
                                                    <i class="fas fa-info-circle"></i>
                                                  </a>
                                                  <a *ngIf="event.contextMenuOptions | flagEnumCompare:contextMenuOptions.Edit" href="javascript:void(0)" (click)="onEditEvent($event, event)">
                                                    <i class="fas fa-pencil"></i>
                                                  </a>
                                                  <a *ngIf="event.contextMenuOptions | flagEnumCompare:contextMenuOptions.Copy" href="javascript:void(0)" (click)="onCopyEvent($event, event)">
                                                    <i class="fas fa-copy"></i>
                                                  </a>
                                                  <a *ngIf="event.contextMenuOptions | flagEnumCompare:contextMenuOptions.ToggleComplete" href="javascript:void(0)" (click)="onToggleTaskComplete($event, event)">
                                                    <i class="fas fa-check-circle"></i>
                                                  </a>
                                                  <a *ngIf="event.contextMenuOptions | flagEnumCompare:contextMenuOptions.Delete" href="javascript:void(0)" (click)="onDeleteEvent($event, event)">
                                                    <i class="fas fa-times"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </ng-template>
                                          </kendo-sortable>
                                        </td>
                                        <td *ngIf="isMonthView" class="totals pt--4" style="word-wrap: break-word">
                                          <div class="mb-2">
                                            <div class="training-load planned">
                                              Planned: <span>{{ week.plannedTrainingLoad }}</span>
                                            </div>
                                            <div *ngIf="week.trainingLoadTargetLow != null || week.trainingLoadTargetHigh != null" class="training-load">
                                              Suggested: <span>{{ week.trainingLoadTargetLow }} - {{ week.trainingLoadTargetHigh }}</span>
                                            </div>
                                            <div *ngIf="week.actualTrainingLoad != null" class="training-load">
                                              Actual: <span>{{ week.actualTrainingLoad }}</span>
                                            </div>
                                          </div>
                                          <div class="activity-type-list">
                                            <div *ngFor="let type of week.activityTypeDurations" class="activity-type">
                                             <div class="planned">
                                                <i class="far {{ type.activityType | customCalendarEventIcon: null:null }}"></i>
                                                <span class="duration">{{ type.plannedDuration ? (type.plannedDuration | secsToHourMinOnly) : ' -- ' }}</span>
                                                <span class="distance">{{ type.plannedDuration ? ((type.plannedDistance | fromMilesToLocalMeasurement) | number: '1.2-2') + localMeasurementName : ' -- ' }}</span>
                                                <span>({{ type.plannedDuration ? type.plannedTrainingLoad : ' -- ' }})</span>
                                              </div>
                                              <div *ngIf="type.actualDuration" class="">
                                                <i class="far"></i>
                                                <span class="duration">{{ type.actualDuration | secsToHourMinOnly }}</span>
                                                <span class="distance">{{ (type.actualDistance | fromMilesToLocalMeasurement) | number: '1.2-2' }}{{localMeasurementName}}</span>
                                                <span>({{ type.actualTrainingLoad }})</span>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="!isMonthView">
              <hr class="m-0" />
              <div *ngFor="let week of weeks; let t = index" class="row week-totals m-0 d-flex justify-content-around">
                <div class="week-training-load">
                  <div class="mt-1 mb-2 ml-1">
                    <div class="font-weight-bold">
                      Week Training Load
                      <div class="d-inline text-center" kendoTooltip>
                        <i class="fas fa-info-circle ml-2" title="{{ trainingLoadInfo }}"></i>
                      </div>
                    </div>
                    <div *ngIf="week.actualTrainingLoad != null" class="training-load">
                      Actual: <span>{{ week.actualTrainingLoad }}</span>
                    </div>
                    <div class="training-loa planned">
                      Planned: <span>{{ week.plannedTrainingLoad }}</span>
                    </div>
                    <div *ngIf="week.trainingLoadTargetLow != null || week.trainingLoadTargetHigh != null" class="training-load">
                      Suggested: <span>{{ week.trainingLoadTargetLow }} - {{ week.trainingLoadTargetHigh }}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="week.activityTypeDurations.length > 0">
                  <div class="activity-type-list">
                    <div *ngFor="let type of week.activityTypeDurations" class="activity-type p-2">
                      <div class="planned">
                        <i class="far {{ type.activityType | customCalendarEventIcon: null:null }}"></i>
                        <span class="duration">{{ type.plannedDuration ? (type.plannedDuration | secsToHourMinOnly) : ' -- ' }}</span>
                        <span class="distance">{{ type.plannedDuration ? ((type.plannedDistance | fromMilesToLocalMeasurement) | number: '1.2-2') + localMeasurementName : ' -- ' }}</span>
                        <span>({{ type.plannedDuration ? type.plannedTrainingLoad : ' -- ' }})</span>
                      </div>
                      <div *ngIf="type.actualDuration" class="">
                        <i class="far"></i>
                        <span class="duration">{{ type.actualDuration | secsToHourMinOnly }}</span>
                        <span class="distance">{{ (type.actualDistance | fromMilesToLocalMeasurement) | number: '1.2-2' }}{{localMeasurementName}}</span>
                        <span>({{ type.actualTrainingLoad }})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isMonthView" class="card-footer">
            <div class="align-items-center d-flex justify-content-between">
              <a href="javascript:void(0)" (click)="onToday()" class="btn btn-sm btn-neutral"> Today </a>
                <h5 class="h3 mb-0">{{ monthName }} {{ yearName }}</h5>
              <div class="mt-3 mt-lg-0">
                <a href="javascript:void(0)" (click)="onPrevious()" class="fullcalendar-btn-prev btn btn-sm btn-neutral"><i class="fas fa-angle-left"></i></a>
                <a href="javascript:void(0)" (click)="onNext()" class="fullcalendar-btn-next btn btn-sm btn-neutral"><i class="fas fa-angle-right"></i></a>
                <a href="javascript:void(0)" (click)="onViewChange(true)" class="btn btn-sm" [ngClass]="{ 'btn-primary': isMonthView, 'btn-neutral': !isMonthView }"> Month </a>
                <a href="javascript:void(0)" (click)="onViewChange(false)" class="btn btn-sm btn-neutral" [ngClass]="{ 'btn-primary': !isMonthView, 'btn-neutral': isMonthView }"> Week </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- mobile view -->
<div *ngIf="initialized" class="d-lg-none">
  <div class="">
    <div class="row">
      <div class="col">
        <div class="card card-calendar">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-8">
                <h5 class="h4 mb-0">{{ monthName }} {{ yearName }}</h5>
              </div>
              <div class="col-4 text-right">
                <a href="javascript:void(0)" (click)="onPrevious()" class="fullcalendar-btn-prev btn btn-sm btn-neutral"><i class="fas fa-angle-left"></i></a>
                <a href="javascript:void(0)" (click)="onNext()" class="fullcalendar-btn-next btn btn-sm btn-neutral"><i class="fas fa-angle-right"></i></a>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="calendar mobile-calendar fc fc-ltr fc-unthemed">
              <div class="fc-view-container">
                <div class="fc-view fc-dayGridMonth-view fc-dayGrid-view">
                  <table class="">
                    <thead class="fc-head">
                      <tr>
                        <td class="fc-head-container fc-widget-header">
                          <div class="fc-row fc-widget-header">
                            <table class="">
                              <thead>
                                <tr>
                                  <th class="fc-day-header fc-widget-header fc-mon"><span>Mon</span></th>
                                  <th class="fc-day-header fc-widget-header fc-tue"><span>Tue</span></th>
                                  <th class="fc-day-header fc-widget-header fc-wed"><span>Wed</span></th>
                                  <th class="fc-day-header fc-widget-header fc-thu"><span>Thu</span></th>
                                  <th class="fc-day-header fc-widget-header fc-fri"><span>Fri</span></th>
                                  <th class="fc-day-header fc-widget-header fc-sat"><span>Sat</span></th>
                                  <th class="fc-day-header fc-widget-header fc-sun"><span>Sun</span></th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody class="fc-body">
                      <tr>
                        <td class="fc-widget-content">
                          <div class="fc-scroller fc-day-grid-container" style="overflow: hidden">
                            <div class="fc-day-grid">
                              <ng-container *ngFor="let week of weeks; let w = index">
                                <div class="fc-row fc-week fc-widget-content">
                                  <div class="fc-bg">
                                    <table class="">
                                      <tbody>
                                        <tr>
                                          <td class="fc-day fc-widget-content fc-mon"></td>
                                          <td class="fc-day fc-widget-content fc-tue"></td>
                                          <td class="fc-day fc-widget-content fc-wed"></td>
                                          <td class="fc-day fc-widget-content fc-thu"></td>
                                          <td class="fc-day fc-widget-content fc-fri"></td>
                                          <td class="fc-day fc-widget-content fc-sat"></td>
                                          <td class="fc-day fc-widget-content fc-sun"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <ng-container *ngFor="let day of week.days; let d = index">
                                            <td (click)="onMobileDayDetailsClick(w, d)" class="fc-day-top" [ngClass]="{ 'fc-other-month': !day.isCurrentMonth }">
                                              <span *ngIf="!day.isToday && !day.isSelectedDay" class="fc-day-number">{{ day.dayOfMonth }}</span>
                                              <span *ngIf="day.isToday" class="fc-day-number fc-day-number-today fa-stack" [ngClass]="{ 'fc-day-number-today-small': day.dayOfMonth < 10 }">
                                                <i class="fas fa-circle"></i>
                                                <span class="fa-inverse">{{ day.dayOfMonth }}</span>
                                              </span>
                                              <span *ngIf="!day.isToday && day.isSelectedDay" class="fc-day-number fc-day-number-selected fa-stack" [ngClass]="{ 'fc-day-number-selected-small': day.dayOfMonth < 10 }">
                                                <i class="fas fa-circle"></i>
                                                <span class="fa-inverse">{{ day.dayOfMonth }}</span>
                                              </span>
                                            </td>
                                          </ng-container>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td *ngFor="let day of week.days; let d = index" (click)="onMobileDayDetailsClick(w, d)">
                                            <div *ngFor="let event of day.events; let e = index" [ngClass]="{ completed: event.isCompleted }" class="mobile-event mt-2">
                                              <div class="event-icon">
                                                <span *ngIf="event.recurringEvent" class="mr-1"><i class="far fa-repeat sized-icon"></i></span>
                                                <i class="far {{ event.singleActivityType | customCalendarEventIcon: event.eventType:(event.workout ? event.workout.workoutType : null) }}"></i>
                                              </div>
                                              <div *ngIf="event.timeInSeconds != null">{{ event.timeInSeconds | secsToHourMinOnly }}</div>
                                              <div *ngIf="event.miles">{{ (event.miles | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</div>
                                              <hr class="mt-2 solid" />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="vertical-align: bottom" *ngFor="let day of week.days; let d = index">
                                            <div class="add-icon mt-2 mb-2" (click)="onDayClick($event, day.calendarDate, w, d)"><i class="fal fa-plus"></i></div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div *ngFor="let week of weeks; let t = index" class="row mobile-totals">
              <div class="col-12">
                <div class="mobile-training-load mt-1 mb-2 ml-1">
                  <div class="font-weight-bold">
                    <a class="mobile-totals-title" (click)="showMobileTotals = !showMobileTotals" href="javascript:void(0)">
                      <i class="fas mr-2" [ngClass]="{ 'fa-chevron-down': showMobileTotals, 'fa-chevron-right': !showMobileTotals }"></i>
                      <span>Week Training Load</span>
                    </a>
                    <div class="d-inline text-center" kendoTooltip>
                      <i class="fas fa-info-circle ml-2" title="{{ trainingLoadInfo }}"></i>
                    </div>
                  </div>
                  <div *ngIf="showMobileTotals">
                    <div *ngIf="week.actualTrainingLoad != null" class="training-load">
                      Actual: <span>{{ week.actualTrainingLoad }}</span>
                    </div>
                    <div class="training-load planned">
                      Planned: <span>{{ week.plannedTrainingLoad }}</span>
                    </div>
                    <div *ngIf="week.trainingLoadTargetLow != null || week.trainingLoadTargetHigh != null" class="training-load">
                      Suggested: <span>{{ week.trainingLoadTargetLow }} - {{ week.trainingLoadTargetHigh }}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="showMobileTotals" class="activity-type-list">
                  <div *ngFor="let type of week.activityTypeDurations" class="activity-type p-2">
                      <div class="planned">
                        <i class="far {{ type.activityType | customCalendarEventIcon: null:null }}"></i>
                        <span class="duration">{{ type.plannedDuration ? (type.plannedDuration | secsToHourMinOnly) : ' -- ' }}</span>
                        <span class="distance">{{ type.plannedDuration ? ((type.plannedDistance | fromMilesToLocalMeasurement) | number: '1.2-2') + localMeasurementName : ' -- ' }}</span>
                        <span>({{ type.plannedDuration ? type.plannedTrainingLoad : ' -- ' }})</span>
                      </div>
                      <div *ngIf="type.actualDuration" class="">
                        <i class="far"></i>
                        <span class="duration">{{ type.actualDuration | secsToHourMinOnly }}</span>
                        <span class="distance">{{ (type.actualDistance | fromMilesToLocalMeasurement) | number: '1.2-2' }}{{localMeasurementName}}</span>
                        <span>({{ type.actualTrainingLoad }})</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedDayDetails" class="card">
          <div id="1" class="card-header">
            <h5 class="h4 mb-0">{{ selectedDayDetails.calendarDate | date }}</h5>
          </div>
          <calendar-day-details [day]="selectedDayDetails" (actionTaken)="onTodaysWorkoutsAction($event)"></calendar-day-details>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/_models/generatedEnums';
import { JournalType, UserCredentialCombinedJournalSearchParams, UserCredentialCombinedJournalViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService } from 'src/app/_services/generatedServices';
import { RunnerWorkoutModalComponent } from '../runner-workout-modal/runner-workout-modal.component';
import { GeneralJournalDialogComponent } from '../general-journal-dialog/general-journal-dialog.component';
import { dateWithoutTime, getNumberOfDaysBetween } from 'src/app/_helpers/extensions.module';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';


@Component({
  selector: 'runner-journal-view',
  templateUrl: 'runner-journal-view.component.html',
  styleUrls: ['runner-journal-view.component.scss']
})
export class RunnerJournalViewComponent implements OnInit {
  initialized = false;
  searching = false;
  dateError = false;
  journals: UserCredentialCombinedJournalViewModel[];
  startDate: Date = new Date().addDays(-120);
  endDate: Date = new Date();
  journalTypeFilter: number = null;
  textFilter: string = null;
  textFilterChanged: Subject<string> = new Subject<string>();
  journalType = JournalType;
  journalTypeEnum = Enums.JournalTypeEnum;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;

  constructor(private modalService: NgbModal, private clientService: ClientsService, public auth: AuthenticationService, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.getData();

    this.textFilterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.textFilter = model;
      this.getData();
    });
  }

  getData() {
    this.searching = true;
    let model = new UserCredentialCombinedJournalSearchParams();
    model.startDate = dateWithoutTime(this.startDate);
    model.endDate = dateWithoutTime(this.endDate);
    model.journalType = this.journalTypeFilter;
    model.searchText = this.textFilter;

    this.clientService.searchCombinedJournalEntries(this.clientUserId, model).subscribe((results) => {
      this.journals = results;
      this.searching = false;
      this.initialized = true;
    });
  }

  onEventInfoClicked(clientProgramWorkoutDayId: number) {
    const modalRef = this.modalService.open(RunnerWorkoutModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.componentInstance.clientProgramWorkoutDayId = clientProgramWorkoutDayId;
    modalRef.componentInstance.isCoachView = this.isCoachView;
    modalRef.componentInstance.syncedWorkoutNameUpdated.subscribe((result) => {
      this.getData();
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason === 'saved') {
          this.getData();
        }
      }
    );
  }

  addJournal() {
    const modalRef = this.modalService.open(GeneralJournalDialogComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.getData();
        }
      }
    );
  }

  onDateChange(e: any) {
    this.dateError = false;
    let daysBetween = getNumberOfDaysBetween(this.startDate, this.endDate);
    if (daysBetween > 124) {
      this.dateError = true;
      return;
    }
    this.getData();
  }

  onTextFilterChanged(e: any) {
    this.textFilterChanged.next(e);
  }

  onJournalTypeChange(e: any) {
    this.getData();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, forkJoin } from 'rxjs';
import { DailyStatusModalComponent } from 'src/app/_components/runner-daily-status/daily-status-modal.component';
import { dynamicSort, FromMilesToLocalMeasurementPipe, MilesKilometersToMilesPipe } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { ActivityType, ClientDayEventType, ClientFinishWorkoutViewModel, ClientProgramWorkoutDayActivityTypeDurationViewModel, ClientProgramWorkoutViewModel, ClientUpdateWorkoutDurationsViewModel, ClientUpdateWorkoutRPEViewModel, MeasurementSystem, StringViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService } from 'src/app/_services/generatedServices';
import { DailyStatusesService } from 'src/app/_services/dailyStatuses.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'bxl-runner-workout-confirmation',
  templateUrl: 'runner-workout-confirmation.component.html',
})
export class RunnerWorkoutConfirmationComponent implements OnInit {
  initialized = false;
  clientProgramWorkoutDayId: number;
  hasDailyStatus: boolean = true;
  userId: number;
  RPERequired: boolean = false;
  clientWorkout: ClientProgramWorkoutViewModel;
  public durationFormGroup: FormGroup;
  activityTypeEnum = Enums.ActivityTypeEnum;
  activityType = ActivityType;
  eventType = ClientDayEventType;
  localMeasurementSystem: MeasurementSystem;
  localDistanceName: string;
  syncedWorkoutNameChanged: Subject<string> = new Subject<string>();

  constructor(private route: ActivatedRoute, private auth: AuthenticationService, private clientService: ClientsService, private modalService: NgbModal,
    private dailyStatusesService: DailyStatusesService, private toastr: ToasterService, private fb: FormBuilder, private fromMilesToLocalMeasurementPipe: FromMilesToLocalMeasurementPipe,
    private milesKilometersToMilesPipe: MilesKilometersToMilesPipe) {}

  ngOnInit(): void {
    this.clientProgramWorkoutDayId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    this.syncedWorkoutNameChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((name) => {
      let model = new StringViewModel();
      model.value = name;
      this.clientService.updateClientProgramWorkoutDaySyncedWorkoutName(this.clientProgramWorkoutDayId, model).subscribe(result => {
      });
    });
    
    let token = this.route.snapshot.queryParamMap.get('token');

    this.auth.refreshClaimsWithToken(token, true).subscribe((result) => {
      this.auth.fetchUserProfile().subscribe((user) => {
        this.userId = user.id;
        this.localMeasurementSystem = this.auth.getMeasurementSystem();
        this.localDistanceName = this.localMeasurementSystem === MeasurementSystem.Metric ? 'Kilometers' : 'Miles';
        const timeZoneOffset = new Date().getTimezoneOffset();
        return forkJoin([this.clientService.doesUserHaveDailyStatusToday(this.userId, timeZoneOffset), this.clientService.isRPERequiredForClientProgramWorkoutDay(this.clientProgramWorkoutDayId), this.clientService.getClientProgramWorkoutDetails(this.clientProgramWorkoutDayId)]).subscribe((results) => {
          this.hasDailyStatus = results[0];
          this.RPERequired = results[1];
          this.clientWorkout = results[2];
          this.setupForm();
          this.initialized = true;
        });
      });
    });
  }

  setupForm() {
    let activityTypeDurations = this.fb.array([]);

    this.clientWorkout.activityTypeDurations.sort(dynamicSort('activityType')).forEach(item => {
      let distance = item.distance;
      if (this.localMeasurementSystem === MeasurementSystem.Metric) {
        distance = this.fromMilesToLocalMeasurementPipe.transform(distance);
      }

      activityTypeDurations.push(this.fb.group({
        activityType: [item.activityType],
        duration: [item.duration, Validators.required],
        distance: [distance],
      }));
    });

    this.durationFormGroup = this.fb.group({
      activityTypeDurations: activityTypeDurations
    });
  }

  onDailyStatus() {
    const modalRef = this.modalService.open(DailyStatusModalComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.userId = this.userId;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason === 'saved') {
          this.dailyStatusesService.SendEntryAddedEvent();
          this.hasDailyStatus = true;
        }
      }
    );
  }

  onIncompleteWorkout() {
    this.toastr.confirmDialog('Are you sure you want to mark the workout as NOT complete?', 'Incomplete Workout', 'Mark Incomplete', 'Cancel').subscribe(result => {
      if (result) {
        const model: ClientFinishWorkoutViewModel = {
          messageBody: null,
          isMessageFromCoach: false,
          isCompleted: false,
          dateCompleted: null,
          ratePerceivedExertion: null,
          activityTypeDurations: []
        }

        this.clientService.updateClientProgramWorkoutDayIsCompleted(this.clientProgramWorkoutDayId, model).subscribe(results => {
          this.toastr.success('Workout Marked Incompelete', 'Success');
        });
      }
    });
  }

  onRPEChanged() {
    const model: ClientUpdateWorkoutRPEViewModel = {
      ratePerceivedExertion: this.clientWorkout.ratePerceivedExertion
    }

    this.clientService.updateClientProgramWorkoutDayRPE(this.clientProgramWorkoutDayId, model).subscribe(results => {
      this.toastr.success('Rate of Perceived Exertion Updated', 'Success');
    });
  }

  updateDurations() {
    let activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[] = Object.assign([], this.durationFormGroup.get('activityTypeDurations').value);
      if (this.localMeasurementSystem === MeasurementSystem.Metric) {
        activityTypeDurations.forEach(item => {
          item.distance = this.milesKilometersToMilesPipe.transform(0, item.distance);
        });
      }
    const model: ClientUpdateWorkoutDurationsViewModel = {
      activityTypeDurations: activityTypeDurations
    }

    this.clientService.updateClientProgramWorkoutDayActivityTypeDurations(this.clientProgramWorkoutDayId, model).subscribe(results => {
      this.toastr.success('Durations Updated', 'Success');
    });
  }

  onSyncedWorkoutNameChanged(name: string) {
    this.syncedWorkoutNameChanged.next(name);
  }
}

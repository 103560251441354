<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">

  <h3 class="d-inline pdf-spacing">{{quickWorkout.quickWorkoutName}}</h3>
  <div *ngIf="isForPDF" class="mb-2">This workout is on your calendar on {{ quickWorkout.workoutDate | date:'shortDate' }}
  </div>
  <div class="">
    <div>
      <span class="font-weight-bold">Activity Type: </span><span>{{quickWorkout.quickWorkoutActivityType | enumString: activityTypeEnum}}</span>
    </div>
    <div>
      <span class="font-weight-bold">Expected Duration: </span><span>{{quickWorkout.quickWorkoutDuration | secsToHourMinSec}}</span>
    </div>
    <div *ngIf="quickWorkout.quickWorkoutDistance">
      <span class="font-weight-bold">Expected Distance: </span><span>{{(quickWorkout.quickWorkoutDistance | fromMilesToLocalMeasurement) | number : '1.2-2'}}</span><span> {{localMeasurementName}}</span>
    </div>
    <div *ngIf="quickWorkout.quickWorkoutDescription" class="mt-2">
      <div class="font-weight-bold">Description:</div>
    <span [editorHTML]="quickWorkout.quickWorkoutDescription"></span>
    </div>
  </div>

</div>

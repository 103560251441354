import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { ImpairmentCategory, ImpairmentCategoryProgramViewModelRead, ImpairmentCategoryUpdateViewModel } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'running-category-change-dialog',
  templateUrl: 'running-category-change-dialog.component.html',
})
export class RunningCategoryChangeDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  programs: ImpairmentCategoryProgramViewModelRead[];
  impairmentCategoryEnum = Enums.ImpairmentCategoryEnum;
  impairmentCategory = ImpairmentCategory;
  selectedProgramName: string;

  @Input()
  clientUserId: number;

  @Input()
  clientFullName: string;

  @Output()
  saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private programsService: ProgramsService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.programsService.getImpairmentCategoryPrograms().subscribe(result => {
      this.programs = result;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientUserId: [this.clientUserId],
      impairmentCategory: [null, Validators.required],
      impairmentCategoryProgramId: [null, Validators.required],
      impairmentCategoryProgramStartDate: [null, Validators.required],
      rtrNotes: [null]
    });

    this.initialized = true;
  }

  onCategoryChange(selectedValue: string) {
    const selectedProgram = this.programs.find(x => x.impairmentCategory == this.impairmentCategory[selectedValue]).program;
    this.selectedProgramName = selectedProgram.name;
    this.formGroup.get('impairmentCategoryProgramId').setValue(selectedProgram.id);
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: ImpairmentCategoryUpdateViewModel = Object.assign({}, this.formGroup.getRawValue());
      formData.impairmentCategoryProgramStartDate = dateAsUTCNoTime(formData.impairmentCategoryProgramStartDate);
      this.programsService.assignCurrentImpairmentCategoryProgramToClient(formData).subscribe((result) => {
        this.toastr.success('Program Assigned', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }
}

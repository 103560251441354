import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuickWorkoutDisplayViewModelRead } from 'src/app/_models/generatedModels';

@Component({
  selector: 'bxl-quick-workout-view-modal',
  templateUrl: 'quick-workout-view-modal.component.html',
})
export class QuickWorkoutViewModalComponent implements OnInit {
  initialized = false;

  @Input()
  quickWorkout: QuickWorkoutDisplayViewModelRead;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initialized = true;

  }
}

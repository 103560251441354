import { Component, Input, OnChanges } from '@angular/core';
import { TrainingVolumeReportRowViewModel, TrainingVolumeReportViewModel } from 'src/app/_models/generatedModels';
import { SortableList } from 'src/app/_helpers/sortableList';
import { localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'app-training-volume-table',
  templateUrl: 'training-volume-table.component.html',
  styleUrls: ['training-volume-table.component.scss']
})
export class TrainingVolumeTableComponent implements OnChanges {
  initialized = false;
  tableData: SortableList<TrainingVolumeReportRowViewModel>;
  sortFieldName: string = 'totalTime';
  localMeasurementName: string;

  @Input()
  isPreview: boolean = false;

  @Input()
  data: TrainingVolumeReportViewModel;

  constructor() {}

  ngOnInit() {
    this.localMeasurementName = localMeasurementDistanceName();
  }

  ngOnChanges(): void {
    this.tableData = new SortableList<TrainingVolumeReportRowViewModel>(this.data.tableValues, "userFullName");
    this.initialized = true;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-gait-analysis-shared',
  templateUrl: 'gait-analysis-shared.component.html',
  styleUrls: ['gait-analysis-shared.component.scss']
})
export class GaitAnalysisSharedComponent implements OnInit {
  initialized = false;


  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }

}

import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable()
export class GeneratePDFService {

    // need to add following line to html where service is used:
    // <div id="my_mm" style="height:100mm;display:none;"></div>
    
    constructor() { }


    createPDF(divId: string, fileName: string): Promise<boolean> {
      let DATA: HTMLElement = document.getElementById(divId);

      var HTML_Height = this.pxTomm(DATA.clientHeight);
      var x_margin = 5;
      var y_margin = 5;
      var PDF_Width = 215.9; //mm
      var PDF_Height = 279.4; //mm
      
      var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
      
      var pdf = new jsPDF('p', 'mm',  [PDF_Width, PDF_Height]);

      return html2canvas(DATA,{allowTaint:true, useCORS:true}).then((function(canvas) {
        canvas.getContext('2d');
        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        
        pdf.addImage(imgData, 'JPG', x_margin, y_margin, PDF_Width-(x_margin*2), HTML_Height);
        
        for (var i = 1; i <= totalPDFPages; i++) { 
          pdf.addPage([PDF_Width, PDF_Height]);
          pdf.addImage(imgData, 'JPG', x_margin, -(PDF_Height*i)+(y_margin), PDF_Width-(x_margin*2), HTML_Height);
        }
  
        pdf.save(fileName + '.pdf');
        return true;
      }));
    }

    pxTomm(px) {   
      // this is to determine the size of pixels in mm for current screen
      // getting height this way since div is hidden
      let height = parseInt(window.getComputedStyle(document.getElementById('my_mm')).getPropertyValue("height"));
      return Math.floor(px/(height/100)); 
    }
}



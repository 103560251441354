<div *ngIf="initialized">
  <div class="card mb-0">
    <div class="bg-dark">
      <header class="card-header warning py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Preview of Level {{isLevelUp ? 'Up' : 'Down'}} Workout</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div>(confirm below)</div>
      </header>
      <div class="card-body p-0">
        <div class="container mt-3">
          <workout-view [isWorkoutPreview]="true" [isCoachView]="true" [workoutId]="levelChangeWorkoutId"></workout-view>
        </div>
      </div>
      <div class="card-footer warning">
        <div class="row">
          <div class="col mb-3">
            Are you sure you want to change {{includeCurrentWorkout ? 'the current workout occurrence and ': ''}}all future scheduled occurrences of the workout to this level?
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="button" [promiseBtn]="submitComplete" class="btn btn-primary" (click)="onLevelChange()">Level {{isLevelUp ? 'Up' : 'Down'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CldrIntlService } from '@progress/kendo-angular-intl';

@Injectable()
export class CustomIntlService extends CldrIntlService {
    constructor(@Inject(LOCALE_ID) localeId: string) {
        super(localeId);
    }

    public firstDay(localeId: string): number {
        return 1;
    }
}
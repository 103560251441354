<div *ngIf="initialized">
  <div class="card bg-dark mb-0">
    <div>
      <header class="card-header warning py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Preview of Level {{isLevelUp ? 'Up' : 'Down'}} Exercise</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div>(confirm below)</div>
      </header>
      <div class="card-body p-0">
        <div class="container mt-3">
          <div class="card">
            <div class="card-body">
              <exercise-view [exerciseId]="levelChangeExerciseId"></exercise-view>
            </div>
          </div>       
        </div>
      </div>
      <div class="card-footer warning">
        <div class="row">
          <div *ngIf="!fromMyLibrary" class="col mb-3">
            Are you sure you want to change this exercise in all occurrences of this workout to the new exercise level? You can change this again at any time.
          </div>
          <div *ngIf="fromMyLibrary" class="col mb-3">
            Are you sure you want to change this shared exercise to the new exercise level? You can change this again at any time.
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="button" [promiseBtn]="submitComplete" class="btn btn-primary" (click)="onLevelChange()">Level {{isLevelUp ? 'Up' : 'Down'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




import { Component, OnInit } from '@angular/core';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

// core components
@Component({
  selector: 'client-training-ideas',
  templateUrl: 'client-training-ideas.component.html',
})
export class ClientTrainingIdeasComponent implements OnInit {

  initialized: boolean;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router) {}

  ngOnInit() {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('My Library', this.router.url, []);
  }
}

import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService, ProgramsService } from 'src/app/_services/generatedServices';
import { LoadingLevelsProgramDisplayViewModel, LoadingLevelsProgramAssignmentViewModel } from 'src/app/_models/generatedModels';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'loading-levels-program-dialog',
  templateUrl: 'loading-levels-program-dialog.component.html'
})
export class LoadingLevelsProgramDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  model: LoadingLevelsProgramDisplayViewModel;

  @Input()
    assessmentId: number;

    @Output()
    saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private assessmentService: AssessmentService,
    private programsService: ProgramsService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.assessmentService.getLoadingLevelsAssessmentProgramInfoById(this.assessmentId).subscribe(result => {
      this.model = result;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      loadingLevelsAssessmentId: [this.model.loadingLevelsAssessmentId],
      clientUserId: [this.model.clientUserId],
      levelAssigned: [this.model.levelAssigned],
      loadingLevelProgramId: [this.model.loadingLevelProgramId],
      loadingLevelProgramName: [this.model.loadingLevelProgramName],
      loadingLevelProgramStartDate: [ null, Validators.required],
      optionalProgramId: [null],
      optionalProgramStartDate: [null]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: LoadingLevelsProgramAssignmentViewModel = Object.assign({}, this.formGroup.getRawValue());
      formData.loadingLevelProgramStartDate = formData.loadingLevelProgramStartDate ? dateAsUTCNoTime(formData.loadingLevelProgramStartDate) : null;
      formData.optionalProgramStartDate = formData.optionalProgramStartDate ? dateAsUTCNoTime(formData.optionalProgramStartDate) : null;
      this.programsService.assignLoadingLevelsAssessmentProgramsToClient(formData).subscribe(result => {
        this.toastr.success('Programs Assigned', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }

  onOptionalProgramChange() {
    if (this.formGroup.get('optionalProgramId').value) {
      this.formGroup.get('optionalProgramStartDate').setValidators([Validators.required]);
    } else {
      this.formGroup.get('optionalProgramStartDate').setValidators([]);
    }

    this.formGroup.get('optionalProgramStartDate').updateValueAndValidity();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserSummaryViewModel } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'clients-container',
  templateUrl: 'clients-container.component.html',
  styleUrls: ['clients-container.component.scss']
})
export class ClientsContainerComponent implements OnInit {
  initialized = false;
  clientId: number;
  client: UserSummaryViewModel;
  constructor(public route: ActivatedRoute, private clientService: ClientsService, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    if (this.clientId) {
      this.clientService.getClientByID(this.clientId).subscribe(result => {
        this.client = result;
      })
    }
    this.initialized = true;
  }
}

import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { WorkoutSummaryViewModelRead, WorkoutProgressionViewModelRead, WorkoutProgressionViewModel, WorkoutViewModelRead } from 'src/app/_models/generatedModels';
import { timer } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { map, switchMap } from 'rxjs/operators';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { WorkoutAddModalComponent } from 'src/app/_components/workout-add-modal/workout-add-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutViewModalComponent } from 'src/app/_components/workout-view-modal/workout-view-modal.component';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';


@Component({
  selector: 'bxl-add-edit-workout-progression',
  templateUrl: 'add-edit-workout-progression.component.html',
  styleUrls: ['add-edit-workout-progression.component.scss']
})
export class AddEditWorkoutProgressionComponent extends FormCanDeactivate implements OnInit {
  initialized = false;
  validating: boolean = false;
  public formGroup: FormGroup;
  public workoutForm: FormGroup;
  submitComplete: Promise<{}> | undefined;
  listUrl: string = '/library/workout-progressions';
  progressionId: number;
  progression: WorkoutProgressionViewModelRead;
  canEditGlobalLibrary: boolean = false;

  constructor(private route: ActivatedRoute, private exerciseService: ExerciseService, private auth: AuthenticationService, private router: Router, private fb: FormBuilder,
    private toastr: ToasterService, private breadcrumbs: BreadcrumbsService, private modalService: NgbModal, private cdr: ChangeDetectorRef, private elRef: ElementRef) {
      super();
    }

  ngOnInit(): void {
    this.progressionId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb('Workout Progressions', this.listUrl, []);
    this.breadcrumbs.AppendBreadcrumb((this.progressionId ? 'Edit ' : 'Add ') + 'Workout Progression', this.router.url, []);
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();

    if (this.progressionId) {
      this.exerciseService.getWorkoutProgressionById(this.progressionId).subscribe(results => {
        this.progression = results;

        // make sure the progression id is valid for this user
        if (!this.progression.organizationId && !this.canEditGlobalLibrary) {
          this.router.navigate([this.listUrl]);
        }

        this.setupForm();
      });
    } else {
      this.progression = new WorkoutProgressionViewModelRead();
        this.setupForm();
    }
  }

  setupForm() {
    this.formGroup = this.fb.group({
      name: [this.progression.name, { validators: [Validators.required], asyncValidators: [this.validateNameNotTaken.bind(this)], updateOn: 'change' }],
      notes: [this.progression.notes],
      isHidden: [this.progression.isHidden || false],
      levels: this.fb.array([], [Validators.required, Validators.minLength(2)])
    });

    if (this.progressionId) {
      let levels = this.formGroup.get('levels') as FormArray;
      this.progression.levels.sort(dynamicSort('level')).forEach(level => {
        levels.push(this.fb.group({
          id: [level.id],
          level: [level.level],
          workoutId: [level.workoutId, Validators.required],
          newlyCreatedAndSelectedWorkoutId: null
        }));
      });

    } else {
      this.onAddLevel(-1);
    }

    this.formGroup.markFormDirtyOnValueChange().subscribe();
    this.initialized = true;
  }

  onAddLevel(existingIndex: number) {
    let levels = this.formGroup.get('levels') as FormArray;
    levels.insert(existingIndex + 1, this.fb.group({
      id: [0],
      level: [existingIndex + 2],
      workoutId: [null, Validators.required],
      newlyCreatedAndSelectedWorkoutId: null
    }));

    this.updateLevelNumbers();
  }

  onAddLevelClone(existingIndex: number) {
    this.onAddLevel(existingIndex);

    let levels = this.formGroup.get('levels') as FormArray;
    this.onCreateWorkout(levels.at(existingIndex).get('workoutId').value, existingIndex + 1);
  }

  onWorkoutPreview(event: any, levelIndex: number) {
    let levels = this.formGroup.get('levels') as FormArray;
    const workoutId = levels.at(levelIndex).get('workoutId').value;
    if (workoutId) {
      const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
      modalRef.componentInstance.workoutId = workoutId;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    }
  }

  onDeleteLevel(index: number) {
    let levels = this.formGroup.get('levels') as FormArray;
    levels.removeAt(index);
    this.updateLevelNumbers();
  }

  onMoveLevel(index: number, moveDirection: number) {
    let levels = this.formGroup.get('levels') as FormArray;
    let newIndex: number = index + moveDirection;
    if(newIndex === -1) {
      newIndex = levels.length - 1;
    } else if(newIndex == levels.length) {
      newIndex = 0;
    }

    const currentLevel = levels.at(index);
    levels.removeAt(index);
    levels.insert(newIndex, currentLevel)

    this.updateLevelNumbers();
  }

  updateLevelNumbers() {
    let levels = this.formGroup.get('levels') as FormArray;
    let levelNumber = 1;
    levels.controls.forEach(level => {
      level.get('level').setValue(levelNumber);
      levelNumber += 1;
    });
  }

  onCreateWorkout(workoutIdToModify: number | null, index: number) {
    const modalRef = this.modalService.open(WorkoutAddModalComponent, { size: 'lg', windowClass: 'modal-xl-custom', backdrop: 'static' });
    modalRef.componentInstance.workoutIdToModify = workoutIdToModify;
    modalRef.componentInstance.workoutCopyDate = null;
    modalRef.componentInstance.savedObject.subscribe((workout: WorkoutViewModelRead) => {
      let levels = this.formGroup.get('levels') as FormArray;
      levels.at(index).get('workoutId').setValue(workout.id);
      levels.at(index).get('newlyCreatedAndSelectedWorkoutId').setValue(workout.id);
    });
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onWorkoutChange(changedWorkout: WorkoutSummaryViewModelRead, index: number) {
    this.validating = true;
    let levels = this.formGroup.get('levels') as FormArray;
    levels.at(index).get('newlyCreatedAndSelectedWorkoutId').setValue(null);

    if (changedWorkout) {

      // detectChanges is necessary bc of Kendo bug with using Dialog inside valueChange
      this.cdr.detectChanges();
      // and this is necessary bc of a NgbModal bug  https://github.com/ng-bootstrap/ng-bootstrap/issues/1604   https://github.com/ng-bootstrap/ng-bootstrap/issues/1252
      (this.elRef.nativeElement.ownerDocument.activeElement as any)['blur'].apply(this.elRef.nativeElement.ownerDocument.activeElement, [blur]);

      let errorMessage: string = null;

      if (!this.auth.isSuperUser() && !changedWorkout.organizationId) {
        errorMessage = 'You cannot select a RunDNA workout to use in a workout progression. Would you like to clone this workout so that it can be used?';
        this.handleWorkoutError(errorMessage, index, changedWorkout.id);
        this.validating = false;
      } else {
        // see if this progression already has this workout
        for (var i = 0; i < levels.controls.length; i++) {
          if (i != index) {
            if (levels.at(i).get('workoutId').value == changedWorkout.id) {
              errorMessage = 'This workout is already being used in this progression. Would you like to clone this workout so that it can be used?';
              this.handleWorkoutError(errorMessage, index, changedWorkout.id);
              break;
            }
          }
        }

        if (!errorMessage) {
          // check to see if workout exists in another progression for org
          this.exerciseService.isWorkoutInAnotherProgression(changedWorkout.id, this.progressionId || 0).subscribe(isDuplicate => {
            this.validating = false;
            if (isDuplicate) {
              errorMessage = 'This workout is already being used in another progression. Would you like to clone this workout so that it can be used?';
              this.handleWorkoutError(errorMessage, index, changedWorkout.id);
            }
          });
        } else {
          this.validating = false;
        }
      }
    }
  }

  handleWorkoutError(errorMessage: string, index: number, changedWorkoutId: number) {
    let levels = this.formGroup.get('levels') as FormArray;
    this.toastr.confirmDialog(errorMessage, 'Clone Workout', 'Clone Workout', 'Cancel').subscribe(result => {
      levels.at(index).get('workoutId').setValue(null);
      if (result) {
        this.onCreateWorkout(changedWorkoutId, index);
      }
    });
  }

  validateNameNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.exerciseService.isWorkoutProgressionNameDuplicate(control.value, this.progressionId || 0)),
      map((res)  => {
        return res ? { nameTaken: true } : null;
      })
    );
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe(result => {
        if (result) {
          this.router.navigate([this.listUrl]);
        }
      });
    } else {
      this.router.navigate([this.listUrl]);
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: WorkoutProgressionViewModel = Object.assign({}, this.progression, this.formGroup.getRawValue());

      if (this.progressionId) {
        this.update(formData, resetButton);
      } else {
        // save the user who is creating the progression
        formData.userId = this.auth.user.id;
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.exerciseService.addWorkoutProgression(formData).subscribe((result) => {
      this.toastr.success('Progression Added', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    });
  }

  update(formData: any, resetButton: () => any) {
    this.exerciseService.updateWorkoutProgression(this.progressionId, formData).subscribe((result) => {
      this.toastr.success('Progression Updated', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    });
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserAuthProfileViewModel } from '../_models/generatedModels';


@Injectable()
export class UserStatusService {
  public currentUserStatusValue: Subject<UserAuthProfileViewModel> = new Subject();
  public currentUserOrgChanged: Subject<UserAuthProfileViewModel> = new Subject();

    constructor() { }

    SendUserStatusValue(user: UserAuthProfileViewModel) {
      this.currentUserStatusValue.next(user);
    }

    SendUserOrgChange(user: UserAuthProfileViewModel) {
      this.currentUserOrgChanged.next(user);
    }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordViewModel } from 'src/app/_models/generatedModels';
import { UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'change-password',
  templateUrl: 'change-password-dialog.component.html'
})
export class ChangePasswordDialogComponent implements OnInit {
  initialized: boolean;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;


  constructor(public activeModal: NgbActiveModal, private userService: UserService, private toastr: ToasterService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      currentPassword: [null, Validators.required],
      newPassword: [null, [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: [null]
    },
    { validator: this.checkPasswords });

    this.initialized = true;
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmNewPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    const formData: ChangePasswordViewModel = Object.assign({}, this.formGroup.getRawValue());
    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.userService.changePassword(formData).subscribe(
        (result) => {
          if (result) {
            this.toastr.success('Password Changed', 'Success');
            this.activeModal.dismiss('saved');
          } else {
            this.toastr.error('Current Password is Incorrect', 'Error');
          }
          resetButton();
        },
        (error) => {
          resetButton();
          this.toastr.error(error.error[0], 'Error');
        }
      );

    });
  }
}

import { Injectable, OnInit } from '@angular/core';
import { UserAuthenticationViewModel } from '../_models/generatedModels';

@Injectable()
export class ActiveClientService {
  activeClient: UserAuthenticationViewModel;
  constructor() {}

  setActiveClient(user) {
    this.activeClient = user;
  }
}

<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Copy Workout</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <form [formGroup]="formGroup" novalidate>
    <div class="card-body">
      <h5>{{workoutName}}</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label" for="workoutDate"> Copy to Week # </label>
            <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="week" [decimals]="'0'" [format]="'n0'" min="1" max="99" type="number"></kendo-numerictextbox>
            <div class="invalid-message">
              <div>Week is required</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label" for="workoutDate"> Copy to Day # </label>
            <select formControlName="day" class="form-control medium-numeric d-block">
              <option value="">Please Choose...</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>
            <div class="invalid-message">
              <div>Day is required</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
        </div>
        <div class="col text-right">
          <button type="button" [promiseBtn]="submitComplete" (click)="onCopy(false)" class="btn btn-secondary">Copy and Modify</button>
          <button type="button" [promiseBtn]="submitComplete" (click)="onCopy(true)" class="btn btn-primary">Copy</button>
        </div>
      </div>
    </div>
  </form>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-8 col-12">
          <h6 class="h2 d-inline-block mb-0">Coaches Dashboard</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>
        </div>

        <div class="col-lg-4 col-12 text-right">
          <coach-athlete-group-dropdown class="form-control" (changed)="onCoachFilterChanged($event)"></coach-athlete-group-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div *ngIf="initialized" class="row">
    <div class="col-lg-3">
      <div class="card">
        <div class="card-header">
          <h5 class="h4 mb-0"><i class="far fa-lightbulb-on text-yellow mr-2"></i>Helpful Links</h5>
        </div>
        <div class="card-body links-card">

          <!-- <h5 class="d-block">Tutorials</h5>
          <div class="row">
            <div class="col-md-6 col-lg-12">
              <ul>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/run-community/using-the-rundna-app-with-your-athletes/" target="_blank">
                    RunDNA App Time Savers
                  </a>
                </li>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/business-of-running/3-ways-to-build-a-running-practice/" target="_blank">
                    Get the most out of RunDNA
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-12">
              <ul>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/run-training/defining-training-load/" target="_blank">
                    Assessments
                  </a>
                </li>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/acwr-and-injury-prevention/" target="_blank">
                    Programs
                  </a>
                </li>
              </ul>
            </div>
          </div> -->

          <h5 class="d-block">Blogs</h5>
          <div class="row">
            <div class="col-md-6 col-lg-12">
              <ul>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/run-community/using-the-rundna-app-with-your-athletes/" target="_blank">
                    Using the RunDNA app for your athletes
                  </a>
                </li>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/business-of-running/3-ways-to-build-a-running-practice/" target="_blank">
                    How to build a running practice
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-12">
              <ul>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/run-training/defining-training-load/" target="_blank">
                    Defining Training Load
                  </a>
                </li>
                <li class="blog-link">
                  <a href="https://rundna.com/resources/acwr-and-injury-prevention/" target="_blank">
                    ACWR and Injury Prevention
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <div *ngIf="initialized" class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0"><i class="far fa-frown text-danger mr-2"></i>Pain in last 7 days</h5>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of data.dashboard.painClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{user.fullName}}</a> ({{user.dailyStatusPainDays}} days)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0"><i class="fad fa-circle-notch text-primary mr-2"></i>High ACWR in last 7 days</h5>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of data.dashboard.highACWRClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{user.fullName}}</a> ({{user.acuteChronicPercentage}}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0 d-inline"><i class="ni ni-chart-bar-32 text-primary mr-2"></i>Low monthly Completion %</h5>
              <div class="d-inline text-center" kendoTooltip>
                <i class="fas fa-info-circle ml-3" title="Athletes who haven't had a scheduled workout for more than 45 days are excluded from this list."></i>
              </div>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of data.dashboard.lowCompletionClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{user.fullName}}</a> ({{user.completionPercentage}}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="h4 mb-0"><i class="fas fa-flag-checkered text-danger mr-2"></i>Event in next 30 days</h5>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col">
                  <div *ngFor="let user of data.dashboard.eventClients">
                    <a href="javascript:void(0)" [routerLink]="[athleteUrl, user.id]">{{user.fullName}}</a> ({{user.nextScheduledEventDays}} days)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="trainingVolumeInitialized" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5 class="h4 mb-0 d-inline">Training Volume Report</h5>
          <a [routerLink]="['/reports/training-volume']" href="javascript:void(0)" class="text-nowrap small-link"> View Full Report</a>
        </div>
        <div class="card-body training-volume-card">
          <div class="row">
            <div class="col">
              <app-training-volume-table [isPreview]="true" [data]="trainingVolumeData"></app-training-volume-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input} from '@angular/core';
import { UserService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-workout-route-map',
  templateUrl: 'external-workout-route-map.component.html',
  styleUrls: ['external-workout-route-map.component.scss']
})
export class ExternalWorkoutRouteMapComponent implements OnInit {
  initialized = false;
  bounds  = new google.maps.LatLngBounds();
  excludeGPS: boolean = false;
  mapCoordinates: any[] = [];

  @Input()
  coordinates: any[] = [];

  @Input()
  polyline: string;

  @Input()
  isModal: boolean = false;

  @Input()
  clientUserId: number;


  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getProfileById(this.clientUserId).subscribe(user => {
      if (user.excludeGPSData) {
        this.excludeGPS = true;
      }

      if (!this.excludeGPS && (this.coordinates.length > 0 || this.polyline)) {
        this.createMap();
      } else {
        this.initialized = true;
      }
    });

  }

  createMap() {
    const map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        mapTypeId: 'terrain',
        disableDefaultUI: true,
      }
    );

    if (this.polyline) {
      this.mapCoordinates = google.maps.geometry.encoding.decodePath(this.polyline);
    } else {
      this.mapCoordinates = this.coordinates;
    }

    this.mapCoordinates.forEach(coordinate => {
      this.bounds.extend(coordinate);
    });

    map.setCenter(this.bounds.getCenter());
    map.fitBounds(this.bounds);
    map.panToBounds(this.bounds);

    const route = new google.maps.Polyline({
      path: this.mapCoordinates,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    route.setMap(map);

    this.initialized = true;
  }
}

<div>
  <div class="row no-gutters mb-1">
    <div class="col-md-8">
      <h3 class="mb-0 d-inline">Training Volume Report</h3>
    </div>
    <div class="col-md-4">
      <coach-athlete-group-dropdown class="form-control" (changed)="onCoachFilterChanged($event)"></coach-athlete-group-dropdown>
    </div>
    <div class="col-md-9">The total duration and miles per time period; and the percent change from the previous time period</div>
    <div class="col-md-3">
      <div class="float-right">
        <button (click)="onExport()" [promiseBtn]="exportComplete" class="btn btn-primary">Export to Excel</button>
      </div>
    </div>
  </div>

  <page-load-header *ngIf="!initialized"></page-load-header>

  <ul ngbNav #tabNav="ngbNav" [destroyOnHide]="false" class="nav-pills">
    <li [ngbNavItem]="0">
      <a ngbNavLink>Summary Chart</a>
      <ng-template ngbNavContent>
        <div *ngIf="initialized && data.tableValues.length == 0" class="mt-3">
          There is no data for the selected filters
        </div>
        <div [hidden]="!initialized || (initialized && data.tableValues.length == 0)" class="row">
          <div class="col-xl-6">
            <div class="mt-3 chart-header">
              Count of athletes in each Duration Percent Change range
            </div>
            <div class="chart-container" id="timeChart"></div>
          </div>
          <div class="col-xl-6">
            <div class="mt-3 chart-header">
              Count of athletes in each Miles Percent Change range
            </div>
            <div class="chart-container" id="milesChart"></div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="1">
      <a ngbNavLink>Detail Table</a>
      <ng-template ngbNavContent>
        <app-training-volume-table *ngIf="initialized" [isPreview]="false" [data]="data"></app-training-volume-table>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="tabNav"></div>

</div>

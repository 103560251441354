
<page-load-header *ngIf="!initialized && !invalidURL"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <div class="mt-3">
        <img *ngIf="!organization || !organization.marketplaceLogo" class="custom-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-default.png" />
        <ng-container *ngIf="organization && organization.marketplaceLogo">
          <img *ngIf="!organization.organizationURL" class="custom-logo" src="/fetchimage?fileName={{ organization.marketplaceLogo }}" />
          <a *ngIf="organization.organizationURL" href="{{organization.organizationURL}}" target="_blank">
            <img *ngIf="organization && organization.marketplaceLogo" class="custom-logo" src="/fetchimage?fileName={{ organization.marketplaceLogo }}" />
          </a>
        </ng-container>
        
      </div>
    <p class="text-center mt-3 mb-0">powered by <img class="powered-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-default.png" /></p>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<div *ngIf="invalidURL">
  <div class="row">
    <div class="col-12 text-center">
      <div class="mt-3">
        <img class="custom-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-default.png" />
        
      </div>
    <p class="text-center mt-3 mb-0">Organization not found. View available RunDNA marketplace programs <a href="/global-marketplace">here.</a></p>
    </div>
  </div>
</div>


import { Component, OnInit, Input} from '@angular/core';
import { localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { ExternalWorkoutLapChartData } from 'src/app/_models/generatedModels';
import { ExternalWorkoutService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-lap-chart',
  templateUrl: 'lap-chart.component.html',
})
export class ExternalLapChartComponent implements OnInit {
  initialized = false;
  laps: ExternalWorkoutLapChartData[];
  localMeasurementName: string;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  showSpeedInsteadOfPace: boolean;

  @Input()
  isMetricSystem: boolean = false;


  constructor(private externalWorkoutService: ExternalWorkoutService) {}

  ngOnInit(): void {
    this.localMeasurementName = localMeasurementDistanceName();
    this.getData();
  }

  getData() {
    this.externalWorkoutService.getLapChartData(this.clientProgramWorkoutDayId, this.isMetricSystem).subscribe(result => {
      this.laps = result;
    });
    this.initialized = true;
  }
}

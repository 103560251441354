import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutAddModalComponent } from 'src/app/_components/workout-add-modal/workout-add-modal.component';
import { Enums } from 'src/app/_models/generatedEnums';
import { ClientDayEventType, ProgramWorkoutDayViewModelRead, WorkoutSummaryViewModelRead, WorkoutViewModelRead } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'add-program-event-dialog',
  templateUrl: 'add-program-event-dialog.component.html',
  styleUrls: ['add-program-event-dialog.component.scss'],
})
export class AddProgramEventDialogComponent implements OnInit {
  initialized: boolean;
  public workoutForm: FormGroup;
  submitComplete: Promise<{}> | undefined;
  eventType = ClientDayEventType;
  eventTypeEnum = Enums.ClientDayEventTypeEnum;
  existingWorkoutRecord: boolean;
  activityTypeEnum = Enums.ActivityTypeEnum;

  @Input()
  workoutDayItem: ProgramWorkoutDayViewModelRead;

  @Input()
  currentWeekIndex: number;

  @Input()
  currentWeekDayIndex: number;

  @Input()
  maxSortOrder: number;

  // this is an optional parameter that is only used if this dialog should immediately open the Create Workout modal with workout details populated
  @Input()
  workoutIdToModify: number;

  @Output()
  savedObject = new EventEmitter<ProgramWorkoutDayViewModelRead>();


  constructor(public activeModal: NgbActiveModal, private toastr: ToasterService, private fb: FormBuilder, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.workoutForm = this.fb.group({
      id: [this.workoutDayItem.id],
      eventType: [this.workoutDayItem.eventType ? this.workoutDayItem.eventType : ClientDayEventType.Workout, Validators.required],
      workoutId: [this.workoutDayItem.workoutId, this.workoutDayItem.eventType == ClientDayEventType.Workout ? Validators.required : null],
      workout: [this.workoutDayItem.workout],
      coachNotes: [this.workoutDayItem.coachNotes],
      eventName: [this.workoutDayItem.eventName, ((this.workoutDayItem.eventType == ClientDayEventType.Event) ? Validators.required : null)],
      taskDescription: [this.workoutDayItem.taskDescription],
      sortOrder: [this.workoutDayItem.sortOrder || (this.maxSortOrder + 1)],
      originalSortOrder: [this.workoutDayItem.sortOrder],
      quickWorkoutName: [this.workoutDayItem.quickWorkoutName, ((this.workoutDayItem.eventType == ClientDayEventType.QuickWorkout) ? Validators.required : null)],
      quickWorkoutDescription: [this.workoutDayItem.quickWorkoutDescription],
      quickWorkoutActivityType: [this.workoutDayItem.quickWorkoutActivityType, ((this.workoutDayItem.eventType == ClientDayEventType.QuickWorkout) ? Validators.required : null)],
      quickWorkoutDuration: [this.workoutDayItem.quickWorkoutDuration, ((this.workoutDayItem.eventType == ClientDayEventType.QuickWorkout) ? Validators.required : null)],
      quickWorkoutDistance: [this.workoutDayItem.quickWorkoutDistance]
    });
    this.existingWorkoutRecord = this.workoutDayItem.sortOrder > 0 ? true : false;
    this.initialized = true;

    if (this.workoutIdToModify) {
      this.onCreateWorkout();
    }
  }

  onWorkoutChange(value: WorkoutSummaryViewModelRead) {
    this.workoutForm.get('workout').setValue(value);
  }

  onEventTypeChange(event: any) {
    if (this.workoutForm.get('eventType').value != this.eventType.Workout) {
      this.workoutForm.get('workoutId').setValue(null);
      this.workoutForm.get('workout').setValue(null);
      this.workoutForm.get('workoutId').setValidators([]);
    } else {
      this.workoutForm.get('workoutId').setValidators([Validators.required]);
    }

    if (this.workoutForm.get('eventType').value == this.eventType.Event) {
      this.workoutForm.get('eventName').setValidators([Validators.required]);
    } else {
      this.workoutForm.get('eventName').setValue(null);
      this.workoutForm.get('eventName').setValidators([]);
    }

    if (this.workoutForm.get('eventType').value == this.eventType.Task) {
      this.workoutForm.get('taskDescription').setValidators([Validators.required]);
    } else {
      this.workoutForm.get('taskDescription').setValue(null);
      this.workoutForm.get('taskDescription').setValidators([]);
    }

    if (this.workoutForm.get('eventType').value == this.eventType.QuickWorkout) {
      this.workoutForm.get('quickWorkoutActivityType').setValidators([Validators.required]);
      this.workoutForm.get('quickWorkoutDuration').setValidators([Validators.required]);
      this.workoutForm.get('quickWorkoutName').setValidators([Validators.required]);
    } else {
      this.workoutForm.get('quickWorkoutActivityType').setValue(null);
      this.workoutForm.get('quickWorkoutActivityType').setValidators([]);
      this.workoutForm.get('quickWorkoutDuration').setValue(null);
      this.workoutForm.get('quickWorkoutDuration').setValidators([]);
      this.workoutForm.get('quickWorkoutName').setValue(null);
      this.workoutForm.get('quickWorkoutName').setValidators([]);

      this.workoutForm.get('quickWorkoutDescription').setValue(null);
      this.workoutForm.get('quickWorkoutDistance').setValue(null);
    }

    this.workoutForm.get('workoutId').updateValueAndValidity();
    this.workoutForm.get('workout').updateValueAndValidity();
    this.workoutForm.get('eventName').updateValueAndValidity();
    this.workoutForm.get('taskDescription').updateValueAndValidity();
    this.workoutForm.get('quickWorkoutActivityType').updateValueAndValidity();
    this.workoutForm.get('quickWorkoutDuration').updateValueAndValidity();
    this.workoutForm.get('quickWorkoutName').updateValueAndValidity();
  }

  onCreateWorkout() {
    const modalRef = this.modalService.open(WorkoutAddModalComponent, { size: 'lg', windowClass: 'modal-xl-custom', backdrop: 'static' });
    modalRef.componentInstance.workoutIdToModify = this.workoutIdToModify;
    modalRef.componentInstance.workoutCopyDate = null;
    modalRef.componentInstance.savedObject.subscribe((workout: WorkoutViewModelRead) => {
      //go ahead and save the record right now and close this modal
      this.workoutForm.get('workoutId').setValue(workout.id);
      this.onWorkoutChange(workout as WorkoutSummaryViewModelRead);
      this.sendEventData();
    });
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onChangeToWorkoutType() {
    this.workoutForm.get('eventType').setValue(this.eventType.Workout);
  }

  onSave() {
    if (!this.workoutForm.valid) {
      this.workoutForm.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.sendEventData();
      resetButton();
    });
  }

  sendEventData() {
    const formData: ProgramWorkoutDayViewModelRead = Object.assign({}, this.workoutForm.value);
    formData.quickWorkoutExpectedTrainingLoad = Math.round(((formData.quickWorkoutDuration ?? 0) / 60.0) * 5);
    formData.workoutName = formData.workout ? formData.workout.name : (formData.eventType == ClientDayEventType.RestDay ? 'Rest Day' : 
      (formData.eventType == ClientDayEventType.Event ? formData.eventName : (formData.eventType == ClientDayEventType.QuickWorkout ? formData.quickWorkoutName : formData.taskDescription)));

    this.savedObject.emit(formData);
    this.activeModal.dismiss('saved');
  }

}


<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="">
  <div class="row no-gutters mb-2">
    <div class="col">
      <h3 class="mt-1 mb-3">Athlete Notifications Setting</h3>
    </div>
  </div>

  <bxl-notifications [userCredentialId]="userCredentialId" [isCoachView]="true"></bxl-notifications>
</div>



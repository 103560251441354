
<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-md-8 col-4">
          <h6 class="h2 d-inline-block mb-0">Teams</h6>

          <rundna-breadcrumbs>
            <li class="breadcrumb-item">
              <a [routerLink]="['/teams']"> Teams </a>
            </li>
            <li *ngIf="team != null" class="breadcrumb-item">
              <a [routerLink]="['/teams/details/' + route.snapshot.url[2].toString()]"> {{team.name}} </a>
            </li>
          </rundna-breadcrumbs>

        </div>
        <div class="col-lg-6 col-md-4 col-8 text-right">
          <h3 *ngIf="team != null"> {{team.name}} </h3>
        </div>
      </div>

      <div *ngIf="team != null" class="flex-column flex-md-row tab-container" type="pills">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['dashboard']" [routerLinkActive]="'active'"><i class="fas fa-home mr-2"></i>Dashboard </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['calendar']" [routerLinkActive]="'active'"><i class="far fa-calendar-alt mr-2"></i>Calendar </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid clients-container">
  <router-outlet></router-outlet>
</div>



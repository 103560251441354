import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';


@Component({
  selector: 'bxl-client-profile',
  templateUrl: 'client-profile.component.html',
})
export class ClientProfileComponent implements OnInit {
  initialized = false;
  clientUserId: number;

  constructor(public auth: AuthenticationService, private breadcrumbs: BreadcrumbsService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Profile', this.router.url, []);
    this.initialized = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-superuser',
  templateUrl: 'superuser.component.html',
})
export class SuperUserComponent implements OnInit {
  initialized = false;
  constructor(private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetBreadcrumb('Superuser', '/superuser', []);
    this.initialized = true;
  }
}

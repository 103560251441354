import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Enums } from 'src/app/_models/generatedEnums';
import { UserRole, UserAuthProfileViewModel, OrganizationViewModelRead, ClientStatus } from 'src/app/_models/generatedModels';
import { coachUserRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { OrganizationService, UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-pending-invitations',
  templateUrl: 'pending-invitations.component.html',
})
export class PendingInvitationsComponent implements OnInit {
  initialized = false;
  user: UserAuthProfileViewModel;
  userRole = UserRole;
  clientStatus = ClientStatus;
  userRoleEnum = Enums.UserRoleEnum;
  organization: OrganizationViewModelRead;
  errorMessage: string;
  denySuccess: boolean;

  constructor(private router: Router, private route: ActivatedRoute, public auth: AuthenticationService, private userService: UserService, private orgService: OrganizationService,
    private toaster: ToasterService) {}

  ngOnInit(): void {
    let token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.errorMessage = 'invalid token';
      this.initialized = true;
      return;
    }
    this.auth.refreshClaimsWithToken(token, true).subscribe((result) => {
      this.auth.fetchUserProfile().subscribe((userResult) => {
        this.user = userResult;
        this.displayData();
      });
    }, (error) => {
      this.errorMessage = 'This invitation is no longer active.';
      this.initialized = true;
    });
  }

  displayData(): any {
    this.orgService.getOrganization(this.user.organizationId).subscribe((org) => {
      this.organization = org;
      this.initialized = true;
    });
  }

  onDeny() {
    this.toaster.confirmDialog('Are you sure you want to deny this invitation? This action cannot be undone.', 'Deny Invitation').subscribe((result) => {
      if (result) {
        this.userService.deleteInvitedUser(this.user.id).subscribe((result) => {
          this.toaster.success('Invitation denied', 'Success');
          this.denySuccess = true;
          this.auth.logout(true);
        }, (error) => {
          this.toaster.error('Error denying invitation - contact support@rundna.com', 'Error');
        });
      }
    });
  }

  onAccept() {
    this.userService.acceptInvitation().subscribe((result) => {
      this.toaster.success('Invitation accepted', 'Success');
      this.navigateBack();
    });
  }

  navigateBack() {
    if ((this.user.userRole & coachUserRole) != 0) {
      this.router.navigate(['/'], { relativeTo: this.route.parent });
    } else {
      this.router.navigate(['/runner/dashboard'], { relativeTo: this.route.parent });
    }
  }
}

<div *ngIf="initialized">
  <p style="text-align:center;">
    RUNDNA PRIVACY POLICY
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-size:11.0pt;"><i><strong>Last Revised on March 3<sup>rd</sup>, 2023</strong></i></span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-size:11.0pt;">This Privacy Policy for ACE Running LLC (“<strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>” “<strong>our</strong>”) describes how we collect, use and disclose information about users of the Company’s website (</span><a href="https://www.rundna.com/"><span style="font-size:11.0pt;">https://www.rundna.com/</span></a><span style="font-size:11.0pt;"> the “<strong>Website</strong>”), applications (including the desktop application available at&nbsp;</span><a href="https://app.rundna.com/"><span style="font-size:11.0pt;">https://app.rundna.com/</span></a><span style="font-size:11.0pt;">, and related mobile application), services, tools and features (collectively, the “<strong>Services</strong>”). &nbsp;For the purposes of this Privacy Policy, “<strong>you</strong>” and “<strong>your</strong>” means you as the user of the Services. &nbsp;Please note that the Services are designed for users in the United States only and are not intended for users located outside the United States.</span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">Please read this Privacy Policy carefully.&nbsp; By using, accessing, or downloading any of the services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy.&nbsp; If you do not agree to this Privacy Policy, please do not use, access or download any of the services.</span>
</p>
<ol>
    <li>
        <span style="font-size:11.0pt;">UPDATING THIS PRIVACY POLICY</span>
    </li>
</ol>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">We may modify this Privacy Policy from time to time in which case we will update the “Last Revised” date at the top of this Privacy Policy. &nbsp;If we make material changes to the way in which we use information we collect, we will use reasonable efforts to notify you (such as&nbsp;by emailing you at the last email address you provided us, by posting notice of such changes on the Services, or by other means consistent with applicable law) and will take additional steps as required by applicable law.&nbsp; </span><span style="background-color:white;color:black;font-size:11.0pt;">If you do not agree to&nbsp;any updates to this Privacy Policy please do not access or continue to use the services.</span>
</p>
<ol>
    <li>
        <p style="margin-left:0in;">
            <span style="font-size:11.0pt;">COMPANY’S COLLECTION AND USE OF INFORMATION</span>
        </p>
    </li>
</ol>
<p>
    <span style="color:black;font-size:11.0pt;">When you access or use the Services, we collect certain categories of information about you from a variety of sources.&nbsp;</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:black;font-size:11.0pt;"><i>Information We Collect Directly from You&nbsp;</i></span>
</p>
<p>
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">Some features of the Services may require you to directly enter certain information about yourself. &nbsp;You may elect not to provide this information, but doing so may prevent you from using or accessing these features. &nbsp;Information that you directly submit through our Services includes:</span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<ul style="list-style-type:disc;">
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;">Basic contact details, as follows: name and email. &nbsp;We collect basic contact details to communicate with you, provide you with products and services, and to market to you.</span>
        </p>
    </li>
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;">Account information, as follows: username, password, profile picture (if you choose to upload one), and security questions. &nbsp;We collect account information to maintain and secure your account with us. &nbsp;If you choose to use the Services and register an account, you are responsible for keeping your account credentials safe. &nbsp;We highly recommend that you do not share your username, password, or other access details with anyone else. &nbsp;If you believe your account has been compromised, please contact us immediately.</span>
        </p>
    </li>
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;">Information related to you, and your workouts and running, including but not limited to, year of birth, gender, weight, height, exercise goals, information on your bodily inclinations, running experience, mobility, strength, time spent and distance covered in each workout, and heart rate. We may collect this information in the form of photos and videos or as data.&nbsp; We collect this information to provide you with motion analysis and personalized running education and programming.</span>
        </p>
    </li>
</ul>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">&nbsp;<i>Information We Collect Automatically&nbsp;</i></span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">We may also collect certain information automatically from you. &nbsp;We use cookies and similar tracking technologies to track the activity on our Services and hold certain information. &nbsp;Cookies are files with small amounts of data that may include an anonymous unique identifier. &nbsp;Cookies are sent to your browser from a website and stored on your device. &nbsp;Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Services.&nbsp;</span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">We and the third parties we work with use cookies and similar tracking technologies to collect information about your use of the Services, such as your IP address, browser type, browser version, pages viewed, time spent on pages, links clicked, location and conversion information. &nbsp;This information may be used by us and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Website and other websites, provide customer support, troubleshoot issues with and improve the operation of our Website and Services, and better understand your online activity.</span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">If you do not want a cookie placed on your hard drive or mobile device, you may be able to turn that feature off on your computer or mobile device. &nbsp;Please consult your Internet browser’s documentation for information on how to do this. &nbsp;However, if you decide not to accept cookies from us, the Website and/or Services may not function properly.</span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">To opt out of tracking by Google Analytics, click&nbsp;</span><a href="https://tools.google.com/dlpage/gaoptout"><span style="font-size:11.0pt;">here</span></a><span style="font-size:11.0pt;">.</span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">We may use the information we collect about you to create anonymized and aggregated data sets that may be used for a variety of functions, including research, internal analysis, analytics, and other functions. &nbsp;For clarity, once anonymized and aggregated, your information will no longer be deemed personal to you.</span>
</p>
<ol>
    <li>
        <span style="font-size:11.0pt;">HOW THE COMPANY SHARES YOUR INFORMATION</span>
    </li>
</ol>
<p>
    <span style="font-size:11.0pt;">We may share your personal information that we have collected with the following persons/entities and in the following circumstances:</span>
</p>
<p>
    &nbsp;
</p>
<ul style="list-style-type:disc;">
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;"><i>Third Party Service Providers</i>. &nbsp;We may share personal information with our vendors and service providers who perform services on our behalf. &nbsp;These entities may also collect your personal information on our behalf. &nbsp;We also engage third party analytics providers to help us understand how users engage with the Website. &nbsp;These analytics providers may use cookies and similar technologies to collect information about your use of the Website as well as information about your use of other websites over time. &nbsp;We do not permit these parties to use your information except to provide services to us or otherwise as permitted by law.</span>
        </p>
    </li>
</ul>
<p style="text-align:justify;">
    &nbsp;
</p>
<ul style="list-style-type:disc;">
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;"><i>Legal Compliance and the Protection of Our Rights</i>. &nbsp;We will share information with regulators, government authorities, and third parties where we believe it is necessary to comply with a court order, subpoena, or regulatory request. &nbsp;We may disclose information when we believe in good faith that such disclosures will support our detection of, prevention of, or response to fraud or intellectual property infringement; help protect your safety or security; or protect the safety and security of the Website, the Services, or any individual.</span>
        </p>
    </li>
</ul>
<p style="text-align:justify;">
    &nbsp;
</p>
<ul style="list-style-type:disc;">
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;"><i>Transfer of Business Assets</i>. &nbsp;As we continue to develop our business, we might acquire or buy other businesses or assets. &nbsp;In such transactions, customer information generally is one of the transferred business assets. &nbsp;Also, we may transfer any information we have about you as an asset to third parties in connection with the consideration, negotiation, or completion of a merger or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of the Company, or as part of a corporate reorganization or stock sale or other change in corporate control, for the purposes of such third parties carrying on our business in relation to the continued provision of our services to you as described in this Privacy Policy.</span>
        </p>
    </li>
</ul>
<p style="text-align:justify;">
    &nbsp;
</p>
<ul style="list-style-type:disc;">
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;"><i>Others With Your Consent</i>. &nbsp;We may ask for your explicit consent to share certain information with third parties.</span>
        </p>
    </li>
</ul>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">In addition, we may share anonymized and aggregated data sets with third parties, alone or in combination with anonymized information of other users.</span>
</p>
<ol>
    <li>
        <span style="font-size:11.0pt;">DO NOT TRACK</span>
    </li>
</ol>
<p>
    <span style="font-size:11.0pt;"><i>Do Not Track Signals</i></span>
</p>
<p>
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">Your browser settings may allow you to transmit a “Do Not Track” signal when you visit various websites. &nbsp;Like many websites, our website is not designed to respond to “Do Not Track” signals received from browsers. To learn more about “Do Not Track” signals, you can visit&nbsp;</span><a href="http://www.allaboutdnt.com/"><span style="font-size:11.0pt;">http://www.allaboutdnt.com/.</span></a>
</p>
<ol>
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;">USER GENERATED CONTENT</span>
        </p>
    </li>
    <li>
        <p style="margin-left:0in;text-align:justify;">
            <span style="color:black;font-size:11.0pt;">The Services host discussion forums, bulletin boards, and chat rooms, which users may elect to join and/or participate in. &nbsp;The purpose of these features is to allow you to connect with other users of the Services. Through your participation, you may submit messages and other media content (“User-Generated Content” or “UGC”). &nbsp;We or others may store, display, reproduce, publish, or otherwise use UGC, and may or may not attribute it to you. &nbsp;Others may also have access to UGC and may have the ability to share it with third parties. &nbsp;If you choose to submit UGC to any public area of the Services, your UGC will be considered “public” and will be accessible by anyone, including the Company.</span>
        </p>
    </li>
    <li>
        <p style="margin-left:0in;text-align:justify;">
            <span style="color:black;font-size:11.0pt;">Please note that we do not control who will have access to the information that you choose to make available to others, and cannot ensure that parties who have access to such information will respect your privacy or keep it secure.&nbsp; We are not responsible for the privacy or security of any information that you make publicly available on the features permitting creation of UGC or what others do with information you share with them on such platforms. We are not responsible for the accuracy, use, or misuse of any UGC that you disclose or receive from third parties through the forums or email lists.</span>
        </p>
    </li>
    <li>
        <span style="font-size:11.0pt;">SOCIAL FEATURES</span>
    </li>
    <li>
        <p style="margin-left:0in;text-align:justify;">
            <span style="background-color:white;color:black;font-size:11.0pt;">Certain features of the Services permit you to initiate interactions between the Services and third-party services or platforms, such as social networks (“Social Features”). &nbsp;Social Features include features that allow you to click and access the Company’s pages on certain third-party platforms, such as Facebook and Twitter, and from there to “like” or “share” our content on those platforms. &nbsp;Use of Social Features may entail a third party’s collection and/or use of your data. &nbsp;If you use Social Features or similar third-party services, information you post or otherwise make accessible may be publicly displayed by the third-party service you are using. &nbsp;Both the Company and the third party may have access to information about you and your use of both the Services and the third-party service. &nbsp;For more information on third-party websites and platforms, see Section 7.</span>
        </p>
    </li>
    <li>
        <span style="font-size:11.0pt;">THIRD PARTY WEBSITES AND LINKS</span>
    </li>
</ol>
<p style="text-align:justify;">
    <span style="background-color:white;color:black;font-size:11.0pt;">We may provide links to websites or other online platforms operated by third parties. &nbsp;If you follow links to sites not affiliated or controlled by us, you should review their privacy and security policies and other terms and conditions. &nbsp;We do not guarantee and are not responsible for the privacy or security of these sites, including the accuracy, completeness, or reliability of information found on these sites. &nbsp;Information you provide on public or semi-public venues, including information you share on third-party social networking platforms (such as Facebook or Twitter) may also be viewable by other users of the Services and/or users of those third-party online platforms without limitation as to its use by us or by a third party. &nbsp;Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators, except as disclosed on the Services.&nbsp;</span>
</p>
<ol>
    <li>
        <span style="font-size:11.0pt;">CHILDREN’S PRIVACY</span>
    </li>
</ol>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">Children under the age of 13 are not permitted to use the Services, and we do not seek or knowingly collect any </span><span style="color:black;font-size:11.0pt;">personal information</span><span style="font-size:11.0pt;"> about children under 13 years of age. &nbsp;If we become aware that we have unknowingly collected </span><span style="color:black;font-size:11.0pt;">information</span><span style="font-size:11.0pt;"> about a child under 13 years of age, we will make commercially reasonable efforts to delete such information from our database.</span>
</p>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">If you are the parent or guardian of a child under 13 years of age who has provided us with </span><span style="color:black;font-size:11.0pt;">their personal information</span><span style="font-size:11.0pt;">, you may contact us using the below information to request that it be deleted.</span>
</p>
<p style="text-align:justify;">
    <span style="color:black;font-size:11.0pt;">We do not have actual knowledge that we sell or share personal information of individuals under 16 years of age.</span>
</p>
<ol>
    <li>
        <span style="font-size:11.0pt;">DATA SECURITY</span>
    </li>
</ol>
<p style="margin-left:0in;text-align:justify;">
    <span style="color:black;font-size:11.0pt;">Please be aware that, despite our reasonable efforts to protect your information, no security measures are perfect or impenetrable, and we cannot guarantee “perfect security.” &nbsp;Any information you send to us electronically, while using the Services or otherwise interacting with us, may not be secure while in transit. We recommend that you do not use unsecure channels to communicate sensitive or confidential information to us.&nbsp;</span>
</p>
<ol>
    <li>
        <span style="font-size:11.0pt;">CALIFORNIA PRIVACY RIGHTS</span>
    </li>
</ol>
<p>
    <span style="font-size:11.0pt;">This section applies only to California residents.</span>
</p>
<ol>
    <li>
        <p style="margin-left:0in;text-align:justify;">
            <span style="font-size:11.0pt;"><i>Sharing for Direct Marketing Purposes</i></span>
        </p>
    </li>
</ol>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">We do not share personal information as defined by California Civil Code Section 1798.83 (“<strong>Shine The Light Law</strong>”) with third parties for their direct marketing purposes.&nbsp;</span>
</p>
<p style="margin-left:.25in;text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;"><i>Users Under 18</i></span>
</p>
<p style="text-align:justify;">
    &nbsp;
</p>
<p style="text-align:justify;">
    <span style="background-color:white;color:black;font-size:11.0pt;">Any California residents under the age of eighteen 18 who have registered to use the Services and who have posted content or information on the Services can request that such information be removed from the Services by contacting us at the e-mail or address set forth in the “How to Contact Us” section below. &nbsp;Such request must state that they personally posted such content or information and detail where the content or information is posted. &nbsp;We will make reasonable good faith efforts to remove the post from prospective public view or anonymize it so the minor cannot be individually identified. &nbsp;This removal process cannot ensure complete or comprehensive removal. &nbsp;For instance, third parties may have republished the post and archived copies of it may be stored by search engines and others that we do not control.</span><span style="font-size:11.0pt;"><i>&nbsp;</i></span>
</p>
<ol>
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;">INTERNATIONAL USERS</span>
        </p>
    </li>
</ol>
<p style="text-align:justify;">
    <span style="font-size:11.0pt;">The Services are designed for users in the United States only and are not intended for users located outside the United States.&nbsp;</span>
</p>
<ol>
    <li>
        <p style="text-align:justify;">
            <span style="font-size:11.0pt;">HOW TO CONTACT US</span>
        </p>
    </li>
</ol>
<p>
    <span style="background-color:white;color:black;font-size:11.0pt;">Should you have any questions about our privacy practices or this Privacy Policy, please email us at&nbsp;</span><a href="mailto:support@rundna.com"><span style="font-size:11.0pt;">support@rundna.com</span></a><span style="font-size:11.0pt;"> </span><span style="background-color:white;color:black;font-size:11.0pt;"> or contact us at the following address: ACE Running LLC Attn: Privacy Policy/Legal</span><span style="font-size:11.0pt;">PO Box 14Freeport, ME&nbsp;04078-0014</span>
</p>
</div>

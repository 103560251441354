import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { ExternalWorkoutService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-cadence-chart',
  templateUrl: 'cadence-chart.component.html',
})
export class ExternalCadenceChartComponent implements OnInit, OnDestroy {
  initialized = false;
  hasData: boolean = true;

  @Input()
  clientProgramWorkoutDayId: number;


  constructor(private charting: ChartingService, private externalWorkoutService: ExternalWorkoutService) {}

  ngOnInit(): void {
    const title = 'Cadence (spm)';
    const chartHeaders: any[] = [{ type: 'timeofday', id: 'Time', label: 'Time' }, { type: 'number', id: 'Cadence', label: title }];


    this.externalWorkoutService.getCadenceChartData(this.clientProgramWorkoutDayId).subscribe(result => {
      this.hasData = result.length > 0;

      this.charting.queueCharts(
        this.charting.drawScatterChart.bind(this.charting),
        new ChartData(
            'cadenceChart',
            chartHeaders,
            result.map(x => [x.timerDuration, x.value]),
            title
        )
      );
      this.initialized = true;
    });
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { ExerciseViewModelRead } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-add-edit-exercise',
  templateUrl: 'add-edit-exercise.component.html',
})
export class AddEditExerciseComponent extends ComponentCanDeactivate implements OnInit {
  initialized = false;
  isDirtyValue: boolean;
  exerciseId: number;
  listUrl: string = '/library/exercises';

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router) {
    super();
  }

  canDeactivate(): boolean {
    return !this.isDirtyValue;
  }

  ngOnInit(): void {
    this.exerciseId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb('Exercises', this.listUrl, []);
    this.breadcrumbs.AppendBreadcrumb((this.exerciseId ? 'Edit ' : 'Add ') + 'Exercise', this.router.url, []);
    this.initialized = true;
  }

  onIsDirtyChange(isDirty: boolean) {
    this.isDirtyValue = isDirty;
  }

  onExerciseSaved(exercise: ExerciseViewModelRead) {
    this.router.navigate([this.listUrl]);
  }
}

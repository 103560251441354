<page-load-header *ngIf="!initialized"></page-load-header>

<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">My Messages</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb--3">
        <alert *ngIf="auth.user && auth.user.validUsersForUserCredential.length > 1" class="text-center" type="info" [dismissible]="true">
          To create or see messages for another organization, switch to that organization.
        </alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header bg-transparent">
            <div class="float-left"><h3 class="mb-0">History</h3></div>

            <div class="float-right">
              <button type="button" class="btn btn-primary" (click)="sendMessage()">New Message</button>
            </div>
          </div>
          <div class="card-header">
            <div class="row no-gutters">
              <div *ngIf="auth.isCoach()" class="col-md-3 mr-2">
                <label> Filter By User: </label>
                <kendo-combobox #userCombo [data]="clientComboData" [valuePrimitive]="true" [filterable]="true" valueField="id" textField="name" class="form-control" [(ngModel)]="clientFilter" (filterChange)="handleAthleteFilter($event)" (valueChange)="modelChanged($event)"></kendo-combobox>
              </div>
              <div class="col-md-2">
                <label> Folder: </label
                ><select [(ngModel)]="folder" class="form-control">
                  <option [ngValue]="1">Inbox</option>
                  <option [ngValue]="2">Sent</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div data-timeline-axis-style="dashed" data-timeline-content="axis" class="timeline timeline-one-side">
              <ng-container *ngFor="let message of messages">
                <div *ngIf="message.toUserId == auth.user.id && folder == 1" class="timeline-block">
                  <span [routerLink]="auth.isCoach() && message.fromUserIsActiveAthlete ? ['/athletes/details', message.fromUserId] : []" class="timeline-step badge-danger" style="cursor: pointer"><img class="img-fluid timeline-step badge-danger" src="fetch-avatar?userId={{ message.fromUserId }}" /></span>
                  <div class="timeline-content">
                    <small *ngIf="auth.isCoach() && message.fromUserIsActiveAthlete" class="text-muted d-block font-weight-bold">From: <a href="javascript:void(0)" [routerLink]="['/athletes/details', message.fromUserId]">{{ message.fromUserFirstName + ' ' + message.fromUserLastName }}</a></small>
                    <small *ngIf="!auth.isCoach() || !message.fromUserIsActiveAthlete" class="text-muted d-block font-weight-bold">From: {{ message.fromUserFirstName + ' ' + message.fromUserLastName }}</small>
                    <small *ngIf="message.toTeamId" class="text-muted d-block font-weight-bold"> (sent to team {{ message.toTeamName }}) </small>
                    <small class="text-muted d-block font-weight-bold">{{ message.createdDate | date: 'medium' }} </small>
                    <h5 class="mt-3 mb-0">{{ message.subject }}</h5>
                    <p class="text-sm mt-1 mb-0" [innerHTML]="message.body"></p>
                    <div class="mt-3">
                      <button class="btn btn-primary btn-sm" (click)="reply(message, false)">Reply</button>
                      <button *ngIf="message.toTeamId" class="btn btn-primary btn-sm ml-3" (click)="reply(message, true)">Reply to Team</button>
                      <!-- <span class="badge badge-pill badge-success mr-1"> reply </span> -->
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngFor="let message of sentMessagesGrouped">
                <div *ngIf="message.fromUserId == auth.user.id && folder==2" class="timeline-block">
                  <span [routerLink]="auth.isCoach() && message.toUserIsActiveAthlete ? ['/athletes/details', message.toUserId] : []" class="timeline-step badge-danger" style="cursor: pointer"><img class="img-fluid timeline-step badge-danger" src="fetch-avatar?userId={{ message.toUserId }}" /></span>
                  <div class="timeline-content">
                    <small *ngIf="auth.isCoach() && message.toUserIsActiveAthlete && !message.toTeamId" class="text-muted d-block font-weight-bold">To: <a href="javascript:void(0)" [routerLink]="['/athletes/details', message.toUserId]">{{ message.toUserFirstName + ' ' + message.toUserLastName }}</a></small>
                    <small *ngIf="!auth.isCoach() || !message.toUserIsActiveAthlete || message.toTeamId" class="text-muted d-block font-weight-bold">To: {{message.toTeamId ? ('(team) ' + message.toTeamName) : (message.toUserFirstName + ' ' + message.toUserLastName) }} </small>
                    <small class="text-muted d-block font-weight-bold">{{ message.createdDate | date: 'medium' }} </small>
                    <h5 class="mt-3 mb-0">{{ message.subject }}</h5>
                    <p class="text-sm mt-1 mb-0" [innerHTML]="message.body"></p>
                    <div class="mt-3">
                      <!-- <span class="badge badge-pill badge-success mr-1"> reply </span> -->
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-rebuild-reporting',
  templateUrl: 'rebuild-reporting.component.html',
})
export class RebuildReportingComponent implements OnInit {

  initialized = false;
  submitComplete: Promise<{}> | undefined;
  public formGroup: FormGroup;

  constructor(private reportsService: ReportsService, private fb: FormBuilder, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Rebuild Reporting', 'rebuild-reporting', []);

    this.setupForm();
    this.initialized = true;
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientUserCredentialId: [null, Validators.required],
      startDate: [null, Validators.required],
    });
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.toastr.confirmDialog('Are you sure you want to delete and rebuild all reporting tables for this user credential?', 'Rebuild Reporting').subscribe((result) => {
        if (result) {
          this.reportsService.rebuildReportingTablesForUserCredential(this.formGroup.get('clientUserCredentialId').value, new Date(this.formGroup.get('startDate').value)).subscribe((result) => {
            this.toastr.success('Reporting rebuilt', 'Success');
            resetButton();
          });
        }
      });
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Enums } from 'src/app/_models/generatedEnums';
import { DailyStatusSettingsViewModel, NotificationType, NotificationTypeRoleViewModelRead, UserCredentialNotificationViewModel, UserCredentialNotificationViewModelRead } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService, NotificationsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-notifications',
  templateUrl: 'notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  initialized = false;
  currentUserCredentialId: number;
  notificationTypes: NotificationTypeRoleViewModelRead[];
  userNotifications: UserCredentialNotificationViewModelRead[];
  dailyStatusSettings: DailyStatusSettingsViewModel;
  notificationTypeEnum = Enums.NotificationTypeEnum;

  @Input()
  isCoachView: boolean = false;

  @Input()
  userCredentialId: number;

  submitComplete: Promise<{}> | undefined;
  submitCompleteDailyStatuses: Promise<{}> | undefined;
  public formGroup: FormGroup;
  public formGroupDs: FormGroup;

  constructor(public auth: AuthenticationService, private notificationsService: NotificationsService, private clientService: ClientsService, private fb: FormBuilder, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.auth.fetchUserProfile().subscribe((user) =>
    {
      if (this.isCoachView) {
        this.currentUserCredentialId = this.userCredentialId;
      } else {
        this.currentUserCredentialId = user.userCredentialId;
      }

      forkJoin([this.notificationsService.getUserCredentialNotificationTypes(), this.notificationsService.getUserCredentialNotifications(), this.clientService.getDailyStatusSettings(this.currentUserCredentialId)]).subscribe((results) =>
      {
        this.notificationTypes = results[0];
        this.userNotifications = results[1];
        this.dailyStatusSettings = results[2];
        this.setupForm();
      });
    });
  }

  setupForm(): any {
    this.formGroup = this.fb.group({});
    this.formGroupDs = this.fb.group({});

    this.notificationTypes.forEach((type) => {
      this.formGroup.addControl(type.notificationType.toString(), this.fb.control(this.getNotificationSetting(type.notificationType)));
    });

    this.formGroupDs.addControl('1', this.fb.control(this.dailyStatusSettings.requestDailyStatus));

    this.initialized = true;
  }

  //assume the value is true if they haven't explicitly set it
  getNotificationSetting(notificationType: NotificationType) {
    return this.userNotifications.some((x) => x.notificationType === notificationType) ? this.userNotifications.find((x) => x.notificationType === notificationType).isActive : true;
  }

  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      let formData: UserCredentialNotificationViewModel[] = [];

      for (const field in this.formGroup.controls) {
        formData.push({
          userCredentialId: this.currentUserCredentialId,
            notificationType: Number(field),
            isActive: this.formGroup.get(field).value,
          }
        );
      }

      this.notificationsService.saveNotificationsForUserCredential(formData).subscribe(
        (user) => {
          this.toastr.success('Notification Settings Updated', 'Success');
          resetButton();
        },
        (error) => resetButton()
      );
    });
  }


  public onSaveDs()
  {
    if (!this.formGroupDs.valid) {
      this.formGroupDs.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitCompleteDailyStatuses = new Promise((resetButton: any, reject) =>
    {
      let formData: DailyStatusSettingsViewModel;

        formData = {
          userCredentialId: this.currentUserCredentialId,
          requestDailyStatus: this.formGroupDs.controls["1"].value,
        }

      this.clientService.updateDailyStatusSettings(formData).subscribe(
        (user) =>
        {
          this.toastr.success('Daily Statuses Settings Updated', 'Success');
          resetButton();
        },
        (error) => resetButton()
      );
    });
  }
}

import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { BooleanViewModel, ClientStatus, UserProfileViewModel, UserRole } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { GarminService, StravaService, UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'bxl-integrations',
  templateUrl: 'integrations.component.html',
  styleUrls: ['integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  initialized = false;
  user: UserProfileViewModel;
  hasAthleteRoleInCurrentOrg: boolean = false;
  hasAthleteRoleInOtherOrg: boolean = false;
  excludeGPSData: boolean;

  constructor(public auth: AuthenticationService, private stravaService: StravaService, private garminService: GarminService, private toastr: ToasterService, private userService: UserService) { }

  ngOnInit(): void {
    forkJoin([this.auth.fetchUserProfile(), this.userService.getProfile()]).subscribe(results => {
      this.user = results[1];
      this.excludeGPSData = this.user.excludeGPSData;
      this.hasAthleteRoleInCurrentOrg = (results[0].userRole & UserRole.Runner) != 0;
      this.hasAthleteRoleInOtherOrg = results[0].validUsersForUserCredential.some(u => (u.userRole & UserRole.Runner) != 0 && u.status == ClientStatus.Active);
      this.initialized = true;
    });
  }

  unlinkStrava() {
    this.stravaService.deauthorizeStravaAccount().subscribe(result => {
      this.userService.getProfile().subscribe(user => {
        this.user = user;
        this.toastr.success('Strava Unlinked', 'Success');
      });
    });
  }

  unlinkGarmin() {
    this.garminService.unlinkGarmin().subscribe(result => {
      this.userService.getProfile().subscribe(user => {
        this.user = user;
        this.toastr.success('Garmin Unlinked', 'Success');
      });
    });
  }

  onGPSChange(value: boolean) {
    this.toastr.confirmDialog('Are you sure you want to ' + (value ? 'exclude' : 'include') + ' GPS map data when importing activities?', 'Confirm GPS Change', 'Yes', 'No').subscribe(result => {
      if (result) {
        let model = new BooleanViewModel();
        model.value = value;
        this.userService.updateExcludeGPSData(this.user.userCredentialId, model).subscribe(result1 => {
          this.toastr.success('GPS Map Data ' + (value ? 'Excluded' : 'Included'), 'Success');
        });
      }
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-exercises',
  templateUrl: 'exercises.component.html',
})
export class ExercisesComponent implements OnInit {
  initialized = false;

  constructor(private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Exercises', 'exercises', []);
    this.initialized = true;
  }
}

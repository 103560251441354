import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientStatus, UserRole } from '../_models/generatedModels';
import { AuthenticationService } from '../_services/authentication.service';
import { UserStatusService } from '../_services/userStatus.service';

@Injectable()
export class IsUserActiveGuard implements CanActivateChild {

  constructor(private router: Router, private auth: AuthenticationService, private userStatus: UserStatusService) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let allowInactiveUser = (route.data['allowInactiveUser'] || false) as boolean;

    return this.auth.fetchUserProfile().pipe(map(user => {
      this.userStatus.SendUserStatusValue(user);
      if (user.status == ClientStatus.Active || allowInactiveUser) {
        return true;
      } else {
        if (user.userRole & UserRole.Runner) {
          this.router.navigateByUrl('/runner/assessments');
        } else {
          this.auth.logout();
        }

        return false;
      }
    }));
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-md-6 col-12">
          <h6 class="h2 d-inline-block mb-0">Plans</h6>
        </div>
        <div *ngIf="initialized" class="col-md-6 col-12 text-right">
          <a *ngIf="survey.isActive" class="btn btn-primary" href="javascript:void(0)" (click)="handleSurvey()" >Find a Plan</a>
          <a *ngIf="hasCoachRole" [routerLink]="['/athletes/details', clientUserId, 'race-plan']" class="btn btn-primary">Add Race Plan</a>
          <a *ngIf="hasCoachRole" [routerLink]="['/athletes/details', clientUserId, 'planner']" class="btn btn-primary">Add Annual Plan</a>
        </div>
      </div>
    </div>

    <div *ngIf="initialized">
      <!-- <div *ngIf="racePlans.length === 0 && trainingPlans.length === 0">
        No plans shared by your coach yet
      </div> -->

      <accordion>
        <accordion-group *ngIf="trainingPlans.length > 0">
          <h5 accordion-heading>
            <div class="pull-left float-left">Annual Training Plans</div>
            <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
          </h5>
          <div class="row">
            <div *ngFor="let plan of trainingPlans" class="col-md-6 col-lg-4">
              <div class="card mb-2">
                <div class="card-header">
                  <a class="header-link" (click)="onShowTrainingPlanDetails(plan.id)" href="javascript:void(0)">
                    {{ plan.name }}
                  </a>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="font-weight-bold">Start Date: </span>
                      <span [innerHTML]="plan.startDate | date"></span>
                    </div>
                    <div class="col-md-12">
                      <span class="font-weight-bold">Starting Training Load: </span>
                      <span [innerHTML]="plan.startTrainingLoad"></span>
                    </div>
                    <div class="col-md-12">
                      <span class="font-weight-bold"># Weeks: </span>
                      <span [innerHTML]="plan.weeksCount"></span>
                    </div>
                    <div class="col-md-12">
                      <span [innerHTML]="plan.startNotes"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </accordion-group>

        <accordion-group *ngIf="racePlans.length > 0">
          <h5 accordion-heading>
            <div class="pull-left float-left">Race Plans</div>
            <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
          </h5>
          <div class="row">
            <div *ngFor="let plan of racePlans" class="col-md-6 col-lg-4">
              <div class="card mb-2">
                <div class="card-header">
                  <a class="header-link" (click)="onShowRacePlanDetails(plan.id)" href="javascript:void(0)">
                    {{ plan.name }}
                  </a>
                  <div class="float-right ">
                    <div class="btn-group no-caret">
                      <button type="button" class="btn btn-sm btn-icon-only text-dark dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"> </i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a  href="javascript:void(0)" (click)="onExportRacePlan(plan.id, plan.name)" class="dropdown-item"> Export </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="font-weight-bold">Race Date: </span>
                      <span [innerHTML]="plan.raceDate | date"></span>
                    </div>
                    <div class="col-md-12">
                      <span class="font-weight-bold">Finish Time: </span>
                      <span [innerHTML]="plan.finishTime | secsToHourMinSec"></span>
                    </div>
                    <div class="col-md-12">
                      <span [innerHTML]="plan.notes"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </accordion-group>

        <accordion-group [isOpen]="true">
          <h5 accordion-heading>
            <div class="pull-left float-left">Assigned Programs</div>
            <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
          </h5>
          <div class="row">
            <div class="col-md-9 col-lg-6 col-xl-4">
              <runner-program-details [clientUserId]="clientUserId"></runner-program-details>
            </div>
          </div>
        </accordion-group>
      </accordion>
    </div>


  </div>
</div>

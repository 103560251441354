import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatShortDate, getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { RacePlanListViewModelRead } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ExportService } from 'src/app/_services/export.service';
import { RacePlanService } from 'src/app/_services/generatedServices';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/_services/toaster.service';
import { RacePlanCloneDialogComponent } from 'src/app/_components/race-plan-clone/race-plan-clone.component';
import { RacePlanViewModalComponent } from 'src/app/_components/race-plan-view-modal/race-plan-view-modal.component';

@Component({
  selector: 'bxl-list-race-plans',
  templateUrl: 'list-race-plans.component.html',
})
export class ListRacePlanComponent implements OnInit {
  clientUserId: number;
  initialized: boolean;
  clientFullName: string;
  plans: RacePlanListViewModelRead[];
  editUrl: string = '../race-plan/edit';

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router, private planService: RacePlanService,
    private exportService: ExportService, private modalService: NgbModal, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.clientUserId = parseInt(getParamFromRoute(this.route, 'id'));
    this.breadcrumbs.SetBreadcrumb('Race Plan', this.router.url, []);

    this.planService.getRacePlansByUser(this.clientUserId).subscribe(result => {
      this.plans = result;
      this.initialized = true;
    });
  }

  onExport(racePlanId: number, name: string) {
    this.exportService.ExportRacePlan(this.clientUserId, racePlanId).subscribe(file => {
      let date = formatShortDate(new Date());
      let fileName = `RacePlan_${name}_${date}.xlsx`;
      saveAs(file, fileName);
    });
  }

  onClone(racePlanId: number, name: string) {
    const modalRef = this.modalService.open(RacePlanCloneDialogComponent, { size: 'lg' });
    modalRef.componentInstance.racePlanId = racePlanId;
    modalRef.componentInstance.racePlanName = name;
    modalRef.componentInstance.defaultUserId = this.clientUserId;

    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onDelete(racePlanId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this plan? This action cannot be undone.', 'Delete Plan', 'Delete Plan', 'Cancel').subscribe(result => {
      if (result) {
        this.planService.deleteRacePlan(this.clientUserId, racePlanId).subscribe(result => {
          this.plans.remove(x => x.id === racePlanId);
          this.toastr.success('Plan Deleted', 'Success');
        });
      }
    });
  }

  onShowDetails(racePlanId: number) {
    const modalRef = this.modalService.open(RacePlanViewModalComponent, { size: 'lg' });
    modalRef.componentInstance.racePlanId = racePlanId;
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">

<!-- https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72
      donut-hole is the white in the middle; donut-ring is the filler if there aren't segments for the whole circle;
      donut-segment is a colored segment of the circle;
      stroke-dasharray: the first number is the % that is filled with that color, the 2nd number is the remainder to get to 100(%)
      stroke-dashoffset: 100 − (All preceding segments’ total length) + (First segment’s offset) = Current segment offset. First segment should start at 25 if you want it at the top. These move counter-clockwise 
    
    ** I had to add pathLength value to get this to work correctly with stroke-dashoffset -->

      
    <figure>
      <div class="figure-content">
        <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut" role="img">
          <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff" role="presentation"></circle>
          <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="3" role="presentation"></circle>

          <ng-container *ngFor="let row of chartData">
            <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" attr.stroke="{{row.color}}" stroke-width="3" attr.stroke-dasharray="{{row.percentage}} {{100-row.percentage}}" attr.stroke-dashoffset="{{row.dashoffset}}" pathLength="100">
            </circle>
          </ng-container>

          <g class="chart-text">
            <text x="50%" y="55%" [ngClass]="centerTextClass" class="chart-number">
              {{centerText}}
            </text>
            <!-- <text x="50%" y="50%" class="chart-label">
              Some text here
            </text> -->
          </g>
        </svg>
      </div>
      <figcaption class="figure-key">
        <ul class="figure-key-list" role="presentation">
          <li *ngFor="let row of chartData">
            <span class="shape-circle" [ngStyle]="{ 'background-color': row.color }"></span>{{row.name}}
          </li>
        </ul>
      </figcaption>
    </figure>
</div>


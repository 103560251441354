import { Component, OnInit } from '@angular/core';
import { WorkoutListViewModelRead, WorkoutSearchParams, WorkoutType } from 'src/app/_models/generatedModels';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WorkoutViewModalComponent } from 'src/app/_components/workout-view-modal/workout-view-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { ShareWorkoutDialogComponent } from 'src/app/_components/share-workout-dialog/share-workout-dialog.component';
import { PrintWorkoutDialogComponent } from 'src/app/_components/print-workout-dialog/print-workout-dialog.component';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'bxl-workouts',
  templateUrl: 'workouts.component.html',
})
export class WorkoutsComponent implements OnInit {
  initialized = false;
  workouts: ServerSortableList<WorkoutListViewModelRead>;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  editUrl: string = '../workouts/edit';
  workoutType = WorkoutType;
  workoutTypeEnum = Enums.WorkoutTypeEnum;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  coachFilter: number = null;
  canEditGlobalLibrary: boolean = false;

  constructor(private exerciseService: ExerciseService, public auth: AuthenticationService, private toastr: ToasterService, private modalService: NgbModal,
    private breadcrumbs: BreadcrumbsService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Workouts', 'workouts', []);
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
    this.workouts = new ServerSortableList<WorkoutListViewModelRead>([], 'Name', false);
    this.searchWorkouts();

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchWorkouts();
    });

    this.workouts.sortChanged.subscribe(result => {
      this.currentPage = 1;
      this.searchWorkouts();
    });
  }

  searchWorkouts() {
    this.searching = true;
    let model = new WorkoutSearchParams();
    model.searchText = this.filter;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.workouts.column;
    model.isSortDesc = this.workouts.isDesc;
    model.coachId = this.coachFilter;

    this.exerciseService.searchForWorkouts(model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.workouts.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  pageChanged(event: any): void {
    this.searchWorkouts();
  }

  onCoachFilterChange(event: any) {
    this.currentPage = 1;
    this.searchWorkouts();
  }

  onDelete(workout: WorkoutListViewModelRead) {
    if (workout.programWorkoutDayCount > 0) {
      this.toastr.error('Cannot delete workouts used in programs');
      return;
    }
    if (workout.clientProgramWorkoutDayCount > 0) {
      this.toastr.error('Cannot delete workouts added to an athlete calendar');
      return;
    }
    if (workout.inWorkoutProgression) {
      this.toastr.error('Cannot delete workouts used in workout progressions');
      return;
    }

    this.toastr.confirmDialog('Are you sure you want to delete this workout? This action cannot be undone.', 'Delete Workout', 'Delete Workout', 'Cancel').subscribe((result) => {
      if (result) {
        this.exerciseService.deleteWorkout(workout.id).subscribe((result) => {
          this.workouts.remove((x) => x.id === workout.id);
          this.toastr.success('Workout Deleted', 'Success');
        });
      }
    });
  }

  onClone(workoutId: number) {
    this.exerciseService.cloneWorkout(this.auth.user.id, workoutId).subscribe((result) => {
      this.toastr.success('Workout Cloned', 'Success');
      this.router.navigate([this.editUrl, result.id], { relativeTo: this.route });
    });
  }

  onPreview(workoutId: number) {
    if (workoutId) {
      const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
      modalRef.componentInstance.workoutId = workoutId;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    }
  }

  onShare(workoutId: number, workoutName: string) {
    const modalRef = this.modalService.open(ShareWorkoutDialogComponent, { size: 'lg' });
    modalRef.componentInstance.workoutId = workoutId;
    modalRef.componentInstance.workoutName = workoutName;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  printPDF(workoutId: number) {
    this.auth.fetchUserProfile().subscribe(user => {
      if (!user.activeSubscription) {
        this.toastr.okModal('The organization must have an active subscription to use this feature.', 'Subscription Required').subscribe();
      } else {
        const modalRef = this.modalService.open(PrintWorkoutDialogComponent, { size: 'lg', windowClass: 'modal-max-xl-custom' });
        modalRef.componentInstance.workoutId = workoutId;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
      }
    });
  }

  onConvertToGarmin(workoutId: number) {
    this.toastr.confirmModal('Are you sure you want to convert this workout to a Garmin-compatible workout? Converting the workout cannot be undone. <br/> Workout description will be converted to plain text and truncated to 1024 characters; images and links will be removed. <a target="blank" href="https://rundnahelp.zendesk.com/hc/en-us/articles/18119614795287-Convert-Cardio-Workout-to-Garmin-Workout">Learn More</a>', 'Convert to Garmin', 'Convert', 'Cancel').subscribe(result => {
      if (result) {
        this.exerciseService.convertCardioWorkoutToGarmin(workoutId).subscribe(result => {
          this.router.navigate([this.editUrl, result.id], { relativeTo: this.route });
          this.toastr.success('Workout Converted - Please Review', 'Success');
        },
        (error) => {
          this.toastr.okDialog('There was an error converting the workout to a Garmin-compatible workout. ' + error.error[0], 'Error');
        });
      }
    });
  }
}

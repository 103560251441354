import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { AssessmentTooltipViewModelRead } from 'src/app/_models/generatedModels';

@Component({
  selector: 'bxl-exercise-tool-tip-modal',
  templateUrl: 'exercise-tool-tip-modal.component.html',
})
export class ExerciseToolTipModalComponent implements OnInit {
  initialized = false;

  @Input()
  key: string;
  tooltip: AssessmentTooltipViewModelRead;

  constructor(public activeModal: NgbActiveModal, private assessmentService: AssessmentService) { }

  ngOnInit(): void {
    this.assessmentService.getAssessmentTooltipByKey(this.key).subscribe(result => {
      this.tooltip = result;
      this.initialized = true;
    });

  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientStatus } from '../_models/generatedModels';
import { UserService } from '../_services/generatedServices';

@Injectable()
export class IsAthleteActiveGuard implements CanActivate {
  redirectUrl: string = '/athletes';

  constructor(private router: Router, public userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    let userId = Number(route.paramMap.get('id'));
    if (isNaN(userId)) {
      this.goBackToAthleteList();
    }

    return this.userService.getProfileById(userId).pipe(map(result => {
      if (result.status == ClientStatus.Active) {
        return true;
      } else {
        this.goBackToAthleteList();
      }
    }));
  }

  goBackToAthleteList() {
    this.router.navigateByUrl(this.redirectUrl);
      return false;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutType, WorkoutViewModelRead } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-workout-add-modal',
  templateUrl: 'workout-add-modal.component.html',
})
export class WorkoutAddModalComponent implements OnInit {
  initialized = false;
  type: any;
  workoutType = WorkoutType;
  isWorkoutCopy: boolean = false;
  isDirtyValue: boolean = false;

  @Input()
    workoutIdToModify: number;

  @Input()
    workoutCopyDate: Date = null;

  @Output()
  savedObject = new EventEmitter<WorkoutViewModelRead>();

  constructor(public activeModal: NgbActiveModal, private toastr: ToasterService ) {}

  ngOnInit(): void {
    // if workout Id passed in, open workout modal with workout details populated and ready to be copied
    if (this.workoutIdToModify) {
      this.isWorkoutCopy = true;
      this.onSelectWorkout(null);
    }
  }

  onSelectWorkout(type: any) {
    this.type = type;
    this.initialized = true;
  }

  onWorkoutCreated(workout: WorkoutViewModelRead) {
    if (workout) {
      this.savedObject.emit(workout);
      this.activeModal.dismiss('saved');
    } else {
      this.onCancel();
    }
  }

  onIsDirtyChange(isDirty: boolean) {
    this.isDirtyValue = isDirty;
  }

  onCancel() {
    if (!this.isDirtyValue) {
      this.activeModal.dismiss('cancel');
    } else {
      this.toastr.confirmDialog('WARNING: You have unsaved changes. Are you sure you want to leave the page?', 'Are you sure?').subscribe(result => {
        if (result) {
          this.activeModal.dismiss('cancel');
        }
      });
    }

  }
}

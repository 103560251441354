<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Organizations</h3>
      </div>
      <div class="col text-right">
        <a [routerLink]="['add']" class="btn btn-primary">Add Organization</a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-10">
        <input type="text" class="form-control" placeholder="Search by organization name or dongle id" [(ngModel)]="filter" (ngModelChange)="onFilterChanged($event)" />
      </div>
      <div class="col-md-2">
        <select class="form-control" [(ngModel)]="isExpired" (ngModelChange)="onStatusChanged($event)">
          <option value="false">Active</option>
          <option value="true">Expired</option>
        </select>
      </div>
    </div>

    <div *ngIf="initialized" class="row mt-3">
      <div class="col">
        <div class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row">
                <th class="sort" data-sort="name" scope="col">Id</th>
                <th class="sort" data-sort="name" scope="col">Created</th>
                <th class="sort" data-sort="key" scope="col">Name</th>

                <th class="sort" data-sort="key" scope="col">Dongle Ids</th>
                <th class="sort" data-sort="key" scope="col">Admin <br/>Password</th>
                <th class="sort" data-sort="key" scope="col">Status</th>
                <th class="sort" data-sort="key" scope="col">Runners</th>
                <th class="sort" data-sort="key" scope="col">3D Count</th>
                <th class="sort" data-sort="key" scope="col">From Plan <br/>Builder</th>
                <th class="sort" data-sort="key" scope="col">Brand</th>
                <th class="sort" data-sort="key" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let org of organizations; let i = index">
                <td>
                  <span class="mobile-grid-label">Id</span>
                  {{ org.id }}
                </td>
                <td>
                  <span class="mobile-grid-label">Created</span>
                  {{ org.createdDate | date }}
                </td>
                <td>
                  <span class="mobile-grid-label">Name</span>
                  {{ org.name }}
                </td>
                <td>
                  <span class="mobile-grid-label">Dongle Id</span>
                  <span *ngFor="let dongleId of org.dongleIds">{{ dongleId }}, </span>
                </td>
                <td>
                  <span class="mobile-grid-label">Admin Password</span>
                  {{ org.isOrgAdminPasswordSet ? 'Yes' : 'No' }}
                </td>
                <td>
                  <span class="mobile-grid-label"> Status</span>
                  <span *ngIf="org.activeSubscription && !org.complimentarySubscription">Active</span>
                  <span *ngIf="!org.activeSubscription && !org.complimentarySubscription && org.trialDaysLeft > 0">Trial</span>
                  <span *ngIf="!org.activeSubscription && !org.complimentarySubscription && org.trialDaysLeft < 0">Expired</span>
                  <span *ngIf="org.complimentarySubscription">Complementary</span>
                </td>
                <td>
                  <span class="mobile-grid-label">Runners</span>
                  {{ org.runnerCount }}
                </td>
                <td>
                  <span class="mobile-grid-label">3D Count</span>
                  {{ org.threeDAssessmentCount }}
                </td>
                <td>
                  <span class="mobile-grid-label">From Plan Builder</span>
                  {{ org.fromPlanBuilder | booleanToYesNo }}
                </td>
                <td>
                  <span class="mobile-grid-label">Brand</span>
                  {{ org.brandName }}
                </td>

                <td class="text-lg-center">
                  <span class="mobile-grid-label">Actions</span>
                  <div class="btn-group no-caret">
                    <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"> </i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg-right">
                      <a [routerLink]="[editUrl, org.id]" class="dropdown-item"> Edit </a>
                      <a class="dropdown-item" href="javascript:void(0)" (click)="export(org.id, org.name)"> Export Report </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

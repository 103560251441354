import { Component, OnInit, Input } from '@angular/core';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { ExerciseListViewModelRead, ExerciseSearchParams } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExercisePreviewComponent } from './exercise-preview/exercise-preview.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { PrintExerciseDialogComponent } from 'src/app/_components/print-exercise-dialog/print-exercise-dialog.component';
import { ShareExerciseDialogComponent } from 'src/app/_components/share-exercise-dialog/share-exercise-dialog.component';

@Component({
  selector: 'bxl-exercise-list',
  templateUrl: 'exercise-list.component.html',
})
export class ExerciseListComponent implements OnInit {

  @Input()
  title: string = 'Exercise';

  initialized = false;
  exercises: ServerSortableList<ExerciseListViewModelRead>;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  canEditGlobalLibrary: boolean = false;

  editUrl: string = '../exercises/edit';
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  coachFilter: number = null;

  constructor(private exerciseService: ExerciseService, public auth: AuthenticationService, private toastr: ToasterService, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
    this.exercises = new ServerSortableList<ExerciseListViewModelRead>([], 'Name', false);
    this.searchExercises();

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchExercises();
    });

    this.exercises.sortChanged.subscribe(result => {
      this.currentPage = 1;
      this.searchExercises();
    });
  }

  searchExercises() {
    this.searching = true;
    let model = new ExerciseSearchParams();
    model.searchText = this.filter;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.exercises.column;
    model.isSortDesc = this.exercises.isDesc;
    model.coachId = this.coachFilter;

    this.exerciseService.searchForExercises(model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.exercises.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  pageChanged(event: any): void {
    this.searchExercises();
  }

  onCoachFilterChange(event: any) {
    this.currentPage = 1;
    this.searchExercises();
  }

  onDelete(exercise: ExerciseListViewModelRead) {
    if (exercise.workoutItemExerciseCount > 0) {
      this.toastr.error('Cannot delete ' + this.title.toLowerCase() + ' used in workouts');
      return;
    }
    if (exercise.inExerciseProgression) {
      this.toastr.error('Cannot delete exercises used in exercise progressions');
      return;
    }

    this.toastr.confirmDialog('Are you sure you want to delete this ' + this.title.toLowerCase() + '? This action cannot be undone.', 'Delete ' + this.title, 'Delete', 'Cancel').subscribe((result) => {
      if (result) {
        this.exerciseService.deleteExercise(exercise.id).subscribe((result) => {
          this.exercises.remove((x) => x.id === exercise.id);
          this.toastr.success(this.title + ' Deleted', 'Success');
        });
      }
    });
  }

  onPreview(exerciseId: number) {
    const modalRef = this.modalService.open(ExercisePreviewComponent, { size: 'lg' });
    modalRef.componentInstance.exerciseId = exerciseId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onClone(exerciseId: number) {
    this.exerciseService.cloneExercise(this.auth.user.id, exerciseId).subscribe(result => {
      this.toastr.success('Exercise Cloned', 'Success');
      this.router.navigate([this.editUrl, result.id], { relativeTo: this.route });
    });
  }

  printPDF(exerciseId: number) {
    this.auth.fetchUserProfile().subscribe(user => {
      if (!user.activeSubscription) {
        this.toastr.okModal('The organization must have an active subscription to use this feature.', 'Subscription Required').subscribe();
      } else {
        const modalRef = this.modalService.open(PrintExerciseDialogComponent, { size: 'lg', windowClass: 'modal-max-xl-custom' });
        modalRef.componentInstance.exerciseId = exerciseId;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
      }
    });
  }

  onShare(exerciseId: number, exerciseName: string) {
    const modalRef = this.modalService.open(ShareExerciseDialogComponent, { size: 'lg' });
    modalRef.componentInstance.exerciseId = exerciseId;
    modalRef.componentInstance.exerciseName = exerciseName;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatShortDate, getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { TrainingLoadPlannerListViewModelRead } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ExportService } from 'src/app/_services/export.service';
import { TrainingLoadPlannerService } from 'src/app/_services/generatedServices';
import { saveAs } from 'file-saver';
import { TrainingLoadPlanCloneDialogComponent } from 'src/app/_components/training-load-plan-clone/training-load-plan-clone.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/_services/toaster.service';
import { TrainingPlanViewModalComponent } from 'src/app/_components/training-plan-view-modal/training-plan-view-modal.component';

@Component({
  selector: 'bxl-list-planners',
  templateUrl: 'list-planners.component.html',
})
export class ListTrainingLoadPlannerComponent implements OnInit {
  clientUserId: number;
  initialized: boolean;
  clientFullName: string;
  plans: TrainingLoadPlannerListViewModelRead[];
  editUrl: string = '../planner/edit';

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router, private plannerService: TrainingLoadPlannerService,
    private exportService: ExportService, private modalService: NgbModal, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.clientUserId = parseInt(getParamFromRoute(this.route, 'id'));
    this.breadcrumbs.SetBreadcrumb('Planner', this.router.url, []);

    this.plannerService.getTrainingLoadPlansByUser(this.clientUserId).subscribe(result => {
      this.plans = result;
      this.initialized = true;
    });
  }

  onExport(trainingLoadPlannerId: number, name: string) {
    this.exportService.ExportTrainingLoadPlan(this.clientUserId, trainingLoadPlannerId).subscribe(file => {
      let date = formatShortDate(new Date());
      let fileName = `TrainingLoadPlan_${name}_${date}.xlsx`;
      saveAs(file, fileName);
    });
  }

  onClone(trainingLoadPlannerId: number, name: string) {
    const modalRef = this.modalService.open(TrainingLoadPlanCloneDialogComponent, { size: 'lg' });
    modalRef.componentInstance.trainingLoadPlannerId = trainingLoadPlannerId;
    modalRef.componentInstance.trainingLoadPlanName = name;
    modalRef.componentInstance.defaultUserId = this.clientUserId;

    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onDelete(trainingLoadPlannerId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this plan? This action cannot be undone.', 'Delete Plan', 'Delete Plan', 'Cancel').subscribe(result => {
      if (result) {
        this.plannerService.deleteTrainingLoadPlan(this.clientUserId, trainingLoadPlannerId).subscribe(result => {
          this.plans.remove(x => x.id === trainingLoadPlannerId);
          this.toastr.success('Plan Deleted', 'Success');
        });
      }
    });
  }

  onShowDetails(trainingPlanId: number) {
    const modalRef = this.modalService.open(TrainingPlanViewModalComponent, { size: 'lg' });
    modalRef.componentInstance.trainingPlanId = trainingPlanId;
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

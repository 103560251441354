<div>
  <div class="card">
    <div class="">
      <header class="card-header py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0"></h3>

          <button type="button" class="close" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>

      <div class="card-body p-0">
        <div *ngIf="initialized" class="container-fluid mt-3 mb-6">
          <bxl-edit-exercise (isDirtyChange)="onIsDirtyChange($event)" [exerciseIdInput]="exerciseIdToModify" [isExerciseCopy]="isExerciseCopy" (savedObject)="onExerciseCreated($event)"></bxl-edit-exercise>
        </div>
      </div>
    </div>
  </div>
</div>




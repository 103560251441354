import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'bxl-runner-daily-statuses-list',
  templateUrl: 'runner-daily-statuses-list.component.html',
})

export class RunnerDailyStatusesListComponent implements OnInit {
  initialized: boolean = false;
  userId: number;

  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.auth.fetchUserProfile().subscribe(user => {
      this.userId = user.id;
      this.initialized = true;
    });
  }
}

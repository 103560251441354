<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="row">
    <div class="col">
      <h3>{{ exercise.name }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ng-container *ngIf="!isPrintVersion">
        <embed-video-player *ngIf="exercise.videoLink && exercise.videoType == 2" [url]="exercise.videoLink" [size]="'small'"></embed-video-player>
      <embed-video-player *ngIf="exercise.videoType == 1" [videoId]="exercise.videoId"></embed-video-player>
      </ng-container>
      <ng-container *ngIf="isPrintVersion && exercise.videoLink && exercise.videoType == 2">
        <div>Video: {{exercise.videoLink}}</div>
        <div>
          <ngx-qrcode 
            [elementType]="'url'" 
            [value]="exercise.videoLink"
            errorCorrectionLevel="L">
          </ngx-qrcode>
        </div>
      </ng-container>
      <div *ngIf="exercise.instructions" class="font-weight-bold">Instructions:</div>
      <span [editorHTML]="exercise.instructions"></span>
    </div>
  </div>

</div>

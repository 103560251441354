<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized && survey.isActive" class="header pb-6" style="min-height: 500px">
  <div *ngIf="surveyIntroText && !isStarted && !isFinished" class="container-fluid pt-3 mb--4">
    <div class="header-body">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 text-center font-weight-normal" [editorHTML]="surveyIntroText">
        </div>
      </div>
    </div>
  </div>
  <div class="container pt-6">
    <div class="header-body">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 text-center">
          <h6 class="h2 font-weight-bold d-inline-block mb-0">{{survey.name}}</h6>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="initialized">
    <div class="container-fluid mt-3">
      <div *ngIf="!isFinished" class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <ng-container *ngIf="!isStarted && !isFinished">
            <div class="card">
              <div class="card-body">
                <span [editorHTML]="survey.description"></span>
              </div>
            </div>
            <div class="text-right">
              <button type="button" class="btn btn-primary" (click)="onStart()">Let's Get Started<i class="fas fa-arrow-right ml-2"></i></button>
            </div>
          </ng-container>
          <ng-container *ngIf="isStarted && currentQuestion">
            <div class="card">
              <div class="card-body">
                <h5>{{currentQuestion.question}}</h5>
                <span [editorHTML]="currentQuestion.description"></span>
                <div *ngIf="currentQuestion.videoId || currentQuestion.videoLink" class="mt-2">
                  <embed-video-player *ngIf="!loadingNextQuestion" [url]="currentQuestion.videoLink" [videoId]="currentQuestion.videoId" [size]="'small'"></embed-video-player>
                </div>
                <div class="d-flex flex-wrap mt-4">
                  <div (click)="onAnswerSelected(answer.id)" class="flex-even mt-2" [ngClass]="{'selected': selectedAnswers.includes(answer.id)}" *ngFor="let answer of currentQuestion.surveyQuestionAnswers">
                    <div class="mb-1 text-center answer-text">{{answer.answer}}</div>
                  </div>
                </div>
                <div *ngIf="multipleAnswerError" class="invalid-message-visible">
                  {{ multipleAnswerError }}
                </div>
                <div class="mt-2 mb--2">
                  <div>
                    Progress: {{currentQuestion.progressPercentage}}%
                  </div>
                  <ngb-progressbar class="dark-text" type="primary" height="15px" [value]="currentQuestion.progressPercentage"></ngb-progressbar>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="currentQuestionSortOrder > 1" class="col">
                <button type="button" class="btn btn-secondary" (click)="onBack()"><i class="fas fa-arrow-left mr-2"></i>Back</button>
              </div>
              <div *ngIf="currentQuestion.surveyQuestionType != surveyQuestionType.OneAnswerOnly" class="col text-right">
                <button type="button" class="btn btn-primary" (click)="onNextQuestion()">Next<i class="fas fa-arrow-right ml-2"></i></button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isStarted && !currentQuestion">
            <div class="card">
              <div class="card-body">
                <h5>Survey Complete!</h5>
                <div>
                  Press the submit button to see the personalized programs available for you to add to your calendar.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button" class="btn btn-secondary" (click)="onBack()"><i class="fas fa-arrow-left mr-2"></i>Back</button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="isFinished" class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <ng-container>
            <div class="card">
              <div class="card-body">
                <div [ngClass]="{'hide-loading': calculationsFinished}" class="text-center">
                  <span class="k-icon k-i-loading text-primary mr-2" style="font-size:24px;"></span> Preparing Programs...
                </div>
                <div *ngIf="showCalculatingText1" class="text-center mt-5">
                  We'll show you programs to select from
                  <span class="sub-text d-block">Read the descriptions</span>
                </div>
                <div *ngIf="showCalculatingText2" class="text-center mt-5">
                  Select the date you want to get started
                  <span class="sub-text d-block">Choose one or more programs</span>
                </div>
                <div *ngIf="showCalculatingText3" class="text-center mt-5">
                  Create Account
                  <span class="sub-text d-block">And start 14-day free trial</span>
                </div>
              </div>
            </div>
            <div *ngIf="calculationsFinished" class="text-center">
              <button type="button" class="btn btn-primary" (click)="onCalculatingSurveyDone()">Cool! Show me my results.</button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="initialized && !survey.isActive" class="mt-4 text-center">
  Survey is inactive.
</div>

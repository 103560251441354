import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-router-container',
  templateUrl: 'router-container.component.html',
})
export class RouterContainerComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }
}

<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Select Date Range</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div  class="col-12">
              <div class="form-group">
                <div class="mb-2">
                  Select the calendar date range to print. <br/>
                  The monthly calendar view and workout details for each scheduled workout will be downloaded as <strong>two</strong> PDFs.
                </div>
                <kendo-daterange class="d-block">
                  <label>
                    <span class="form-control-label d-block">Start</span>
                    <kendo-dateinput
                      kendoDateRangeStartInput
                      autoCorrectOn="blur"
                      formControlName="startDate"
                    ></kendo-dateinput>
                  </label>
                  <label>
                    <span class="form-control-label d-block">End</span>
                    <kendo-dateinput
                      kendoDateRangeEndInput
                      autoCorrectOn="blur"
                      formControlName="endDate"
                    ></kendo-dateinput>
                  </label>
                  <kendo-daterange-popup
                    [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
                    [popupAlign]="{ horizontal: 'left', vertical: 'top' }"></kendo-daterange-popup>
                </kendo-daterange>
              </div>
            </div>
            <div *ngIf="errorMessage" class="invalid-message-visible">
              {{ errorMessage }}
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">Download PDF</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="my_mm" style="height:100mm;display:none;"></div>


<div class="row my-0 mx-0">
  <div *ngIf="ready" class="col-12 text-center mb-7 mt-2">
    <page-load-header></page-load-header>
    <h3>Generating {{workouts.length > 0 ? 'two PDFs' : 'PDF'}} ... Do not close...</h3>
  </div>

  <div *ngIf="ready" id="calendarPrintDiv">
    <div class="row">
      <div class="col-12 px-5 mb--2">
        <div class="row">
          <div class="col-4">
            <div><img class="qr-code pl-4" src="../../../../assets/img/qr_code_app_rundna_com.png" /></div>
          </div>
          <div class="col-4 text-center">
            <img class="rundna-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-default.png" />
          </div>
          <div class="col-4">
            <div>{{coachInfo.organizationName}}</div>
            <div>{{coachInfo.coachFullName}}</div>
            <div>{{coachInfo.coachEmail}}</div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-3 text-center">
            For full details visit app.rundna.com
          </div>
          <div class="col-6 text-center">
            <h5 class="h4 mb-0">{{monthRange}}</h5>
          </div>
          <div class="col-3 text-center">
            &nbsp;
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card-calendar">
          <div class="p-0">
            <div class="calendar fc fc-ltr fc-unthemed">
              <div class="fc-view-container">
                <div class="fc-view fc-dayGridMonth-view fc-dayGrid-view">
                  <table class="">
                    <thead class="fc-head">
                      <tr>
                        <td class="fc-head-container fc-widget-header">
                          <div class="fc-row fc-widget-header">
                            <table class="">
                              <thead>
                                <tr>
                                  <th class="fc-day-header fc-widget-header fc-mon"><span>Mon</span></th>
                                  <th class="fc-day-header fc-widget-header fc-tue"><span>Tue</span></th>
                                  <th class="fc-day-header fc-widget-header fc-wed"><span>Wed</span></th>
                                  <th class="fc-day-header fc-widget-header fc-thu"><span>Thu</span></th>
                                  <th class="fc-day-header fc-widget-header fc-fri"><span>Fri</span></th>
                                  <th class="fc-day-header fc-widget-header fc-sat"><span>Sat</span></th>
                                  <th class="fc-day-header fc-widget-header fc-sun"><span>Sun</span></th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody class="fc-body">
                      <tr>
                        <td class="fc-widget-content">
                          <div class="fc-scroller fc-day-grid-container">
                            <div class="fc-day-grid">
                              <div *ngFor="let week of weeks; let w = index" class="fc-row fc-week fc-widget-content" style="min-height: 127px">
                                <div class="fc-bg">
                                  <table class="">
                                    <tbody>
                                      <tr>
                                        <td class="fc-day fc-widget-content fc-mon"></td>
                                        <td class="fc-day fc-widget-content fc-tue"></td>
                                        <td class="fc-day fc-widget-content fc-wed"></td>
                                        <td class="fc-day fc-widget-content fc-thu"></td>
                                        <td class="fc-day fc-widget-content fc-fri"></td>
                                        <td class="fc-day fc-widget-content fc-sat"></td>
                                        <td class="fc-day fc-widget-content fc-sun"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="fc-bg fc-content-skeleton" style="min-height: 127px">
                                  <table style="height: 100%; min-height: 127px">
                                    <thead>
                                      <tr>
                                        <ng-container *ngFor="let day of week.days; let d = index">
                                          <td class="fc-day-top" (click)="onDayClick($event, day.calendarDate, w, d)">
                                            <span *ngIf="day.dayOfMonth == 1" class="fc-day-number month-name">{{ day.monthShortName }}</span>
                                            <span class="fc-day-number">{{ day.dayOfMonth }}</span>
                                          </td>
                                        </ng-container>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td *ngFor="let day of week.days; let d = index" (click)="onDayClick($event, day.calendarDate, w, d)" class="custom-event-container">
                                          <kendo-sortable [kendoSortableBinding]="day.events" class="d-flex flex-column" [emptyText]="''" [emptyItemStyle]="{ height: '100%' }" [activeItemStyle]="{ opacity: '0.7', border: '2px dashed black' }" (dragStart)="onDragStart($event, w, d)" (dragEnd)="onDragEnd($event, w, d)" (dragOver)="onDragOver($event)" zone="dayZone">
                                            <ng-template let-event="item" let-e="index">
                                              <div (click)="onEventClick($event, event)" class="custom-event" [ngClass]="{ 'completed': event.isCompleted, 'selected': event.eventId == selectedEventId }">
                                                <div class="pl-1 event-activity-type">
                                                  <i class="far {{ event.singleActivityType | customCalendarEventIcon: event.eventType:(event.workout ? event.workout.workoutType : null) }}"></i>
                                                  <span *ngIf="event.timeInSeconds != null">{{ event.timeInSeconds | secsToHourMinOnly }}</span>
                                                  <span *ngIf="event.miles">{{ event.miles | number: '1.1-1' }}mi</span>
                                                </div>
                                                <div class="custom-event-title-wrapper">
                                                  <span *ngIf="event.isTeamEvent" class="mr-1 team-icon"><i class="fas fa-user-friends sized-icon"></i></span>
                                                  <span *ngIf="event.coachNotes && event.eventType == eventType.RestDay" class="mr-1"><i class="far fa-sticky-note"></i></span>
                                                  <span class="event-title">{{ event.workoutName }}</span>
                                                </div>
                                              </div>
                                            </ng-template>
                                          </kendo-sortable>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="ready" style="width: 800px;">
    <div *ngFor="let workout of workouts; let w = index" [id]="'workoutPrintDiv' + (w+1)" class="row">
      <div class="col-12">
        <workout-view-pdf [workout]="workout"></workout-view-pdf>
      </div>
    </div>
  </div>

  <div *ngIf="ready" style="width: 800px;">
    <div *ngFor="let quickWorkout of quickWorkouts; let w = index" [id]="'quickWorkoutPrintDiv' + (w+1)" class="row">
      <div class="col-12">
        <quick-workout-view-pdf [quickWorkout]="quickWorkout"></quick-workout-view-pdf>
      </div>
    </div>
  </div>

</div>



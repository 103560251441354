import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeamViewModelRead } from 'src/app/_models/generatedModels';
import { TeamService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'team-container',
  templateUrl: 'team-container.component.html',
  styleUrls: ['team-container.component.scss']
})
export class TeamContainerComponent implements OnInit {
  initialized = false;
  teamId: number;
  team: TeamViewModelRead;
  constructor(public route: ActivatedRoute, private teamService: TeamService, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.teamId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    if (this.teamId) {
      this.teamService.getTeamById(this.teamId).subscribe(result => {
        this.team = result;
      })
    }
    this.initialized = true;
  }
}

<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Edit {{recordType | titlecase}}</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div *ngIf="recordType == 'question'" class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="question"> Question </label>
                <input class="form-control" formControlName="question" maxlength="750" type="text" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="videoLink"> Video Type </label>
                <select formControlName="videoType" class="form-control">
                  <option value="0">(none)</option>
                  <option value="2">Public (YouTube, Vimeo, etc)</option>
                  <option value="1">Uploaded</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="formGroup.controls.videoType.value == 2" class="form-group">
                <label class="form-control-label" for="videoLink"> Link to Video </label>
                <input type="text" class="form-control" formControlName="videoLink" maxlength="1000" placeholder="YouTube/Vimeo Link" aria-label="Recipient's username" aria-describedby="button-addon2" />
              </div>
              <div *ngIf="formGroup.controls.videoType.value == 1" class="form-group">
                <video-upload formControlName="videoId" [label]="'Upload Video'"></video-upload>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="description"> Description </label>
                <custom-editor formControlName="description"></custom-editor>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="recordType == 'survey'" class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="name"> Survey Name </label>
                <input class="form-control" formControlName="name" maxlength="200" type="text" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="isActive"> Survey Is Active </label>
                <input class="d-block single-checkbox" formControlName="isActive" type="checkbox" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="description"> Description </label>
                <custom-editor formControlName="description"></custom-editor>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="recordType == 'result'" class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="name"> Survey Result Name </label>
                <input class="form-control" formControlName="name" maxlength="200" type="text" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="description"> Description </label>
                <custom-editor formControlName="description"></custom-editor>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngFor="let resultAnswer of surveyResult.surveyResultAnswers" class="col-md-12 mb-2">
              <div class="result-answer">
                <div><span class="font-weight-600">Question: </span>{{ resultAnswer.surveyQuestionAnswer.surveyQuestion.question }}</div>
                <div><span class="font-weight-600">Answer: </span>{{ resultAnswer.surveyQuestionAnswer.answer }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div formArrayName="surveyResultPrograms" class="col-lg-6 col-md-12" *ngFor="let program of formGroup.get('surveyResultPrograms').controls; let p = index">
              <div [formGroupName]="p" class="form-group">
                <div class="d-inline mr-2"><a href="javascript:void(0)" class="delete-icon" (click)="onDeleteProgram(p)"><i class="fa fa-trash"></i></a></div>
                  <label class="form-control-label" for="programId">
                    Program
                  </label>
                  <superuser-programs-dropdown class="form-control" formControlName="programId"></superuser-programs-dropdown>
                  <div class="invalid-message">
                    <div>Program is required</div>
                  </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12 mb-3 link">
              <a class="" href="javascript:void(0)" (click)="onAddProgram()">Add Program</a>
            </div>
          </div>
          <div class="invalid-message-visible" *ngIf="formGroup?.controls['surveyResultPrograms'].errors && formGroup?.controls['surveyResultPrograms'].errors.required">Must add at least 1 program</div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


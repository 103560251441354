import { Component, OnInit } from '@angular/core';
import { TermsAndConditionsService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'terms-and-conditions-text',
  templateUrl: 'terms-and-conditions-text.component.html',
  styleUrls: ['terms-and-conditions-text.component.scss'],
})
export class TermsAndConditionsTextComponent implements OnInit {
  termsText: any;
  initialized: boolean;


  constructor(private termsService: TermsAndConditionsService) {}

  ngOnInit(): void {
    this.termsService.getLatestTermsAndConditions().subscribe((result) => {
      this.termsText = result.termsText;
      this.initialized = true;
    });
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventViewModel, CalendarType, CalendarWeekViewModel, ClientDayEventType, QuickWorkoutDisplayViewModelRead, TeamProgramWorkoutDayViewModel } from 'src/app/_models/generatedModels';
import { CalendarAddEventParameters, CalendarDraggedEventParameters, CalendarParameters, CalendarWorkoutEvent } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TeamService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { DeleteCalendarEventDialogComponent } from '../_components/calendar-event-delete-dialog/delete-calendar-event-dialog.component';
import { CalendarWorkoutDialogComponent } from '../_components/calendar-workout-dialog/calendar-workout-dialog.component';
import { WorkoutViewModalComponent } from '../_components/workout-view-modal/workout-view-modal.component';
import { CopyCalendarEventDialogComponent } from '../_components/calendar-event-copy-dialog/copy-calendar-event-dialog.component';
import { QuickWorkoutViewModalComponent } from '../_components/quick-workout-view-modal/quick-workout-view-modal.component';
import { CopyCalendarWeekDialogComponent } from '../_components/calendar-week-copy-dialog/copy-calendar-week-dialog.component';


@Injectable()
export class TeamCalendarService {
  private currentCalendarParameters: CalendarParameters;
  private teamId: number;
  private eventType = ClientDayEventType;

  // this service will either just emit a bool saying data was updated; or actually update the data: based on the updateData boolean
  public updateData: boolean = false;
  public changed: Subject<boolean> = new Subject();
  public dataChanged: Subject<CalendarWeekViewModel[]> = new Subject();

    constructor(private teamService: TeamService, private modalService: NgbModal, private toastr: ToasterService, private auth: AuthenticationService) { }

    setInitialValues(teamId: number, updateData: boolean) {
      this.teamId = teamId;
      this.updateData = updateData;
    }

    calendarParametersChanged(parameters: CalendarParameters) {
      this.currentCalendarParameters = parameters;
      this.getData();
    }

    private getData() {
      if (this.updateData) {
          this.teamService.getTeamCalendarWeeks(this.teamId, this.currentCalendarParameters.selectedDate, this.currentCalendarParameters.today, this.currentCalendarParameters.isMonthView).subscribe(result => {
          this.dataChanged.next(result);
        });
      } else {
        this.changed.next(true);
      }

    }

    onEventInfoClicked(event: CalendarEventViewModel) {
      if (event.workout) {
        const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
        modalRef.componentInstance.workoutId = event.workout.id;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
      } else if (event.eventType === ClientDayEventType.RestDay) {
        this.toastr.okDialog(event.coachNotes ? ('Rest Day Notes: ' + event.coachNotes) : '', 'Rest Day');
      } else if (event.eventType === ClientDayEventType.QuickWorkout) {
        const model: QuickWorkoutDisplayViewModelRead = {
          workoutDate: null,
          quickWorkoutName: event.quickWorkoutName,
          text: null,
          autoCompleteUrl: null,
          hasDescription: true,
          quickWorkoutDescription: event.quickWorkoutDescription,
          quickWorkoutActivityType: event.quickWorkoutActivityType,
          quickWorkoutDistance: event.quickWorkoutDistance,
          quickWorkoutDuration: event.quickWorkoutDuration
        }
        
        const modalRef = this.modalService.open(QuickWorkoutViewModalComponent, { size: 'lg' });
        modalRef.componentInstance.quickWorkout = model;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
      }
    }

    onEventEditClicked(event: CalendarEventViewModel) {
      let calendarEvent = new CalendarWorkoutEvent(event.eventId, event.workout ? event.workout.id : null, event.workoutDate, event.sortOrder, event.eventName, event.isCompleted,
        event.coachNotes, event.taskDescription, event.eventType, event.recurringEvent, event.activityTypeDurations, event.ratePerceivedExertion, event.quickWorkoutName,
        event.quickWorkoutDescription, event.quickWorkoutActivityType, event.quickWorkoutDuration, event.quickWorkoutDistance);
      this.openCalendarEventDialog(calendarEvent);
    }

    onEventDeleteClicked(event: CalendarEventViewModel) {
      const modalRef = this.modalService.open(DeleteCalendarEventDialogComponent, { size: 'lg', windowClass: 'modal-sm-custom' });
      modalRef.componentInstance.isCoachView = true;
      modalRef.componentInstance.teamId = this.teamId;
      modalRef.componentInstance.event = event;
      modalRef.componentInstance.calendarType = CalendarType.Team;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
    }

    onEventAddClicked(parameters: CalendarAddEventParameters) {
      this.openCalendarEventDialog(new CalendarWorkoutEvent(null, null, parameters.selectedDate, parameters.maxExistingSortOrder + 1, null, false, null, null, ClientDayEventType.QuickWorkout, null,
        null, null, null, null, null, null, null));
    }

    onEventDragEnd(parameters: CalendarDraggedEventParameters) {
      const model: TeamProgramWorkoutDayViewModel = {
        workoutDate: parameters.eventDate,
        workoutId: parameters.draggedEvent.workout ? parameters.draggedEvent.workout.id : null,
        sortOrder: parameters.newEventIndex + 1,
        organizationId: this.auth.organizationId,
        teamId: this.teamId,
        assignedByUserId: this.auth.user.id,
        eventName: parameters.draggedEvent.eventName,
        coachNotes: parameters.draggedEvent.coachNotes,
        taskDescription: parameters.draggedEvent.taskDescription,
        eventType: parameters.draggedEvent.eventType,
        recurringEvent: parameters.draggedEvent.recurringEvent,
        quickWorkoutName: parameters.draggedEvent.quickWorkoutName,
        quickWorkoutDescription: parameters.draggedEvent.quickWorkoutDescription,
        quickWorkoutActivityType: parameters.draggedEvent.quickWorkoutActivityType,
        quickWorkoutDuration: parameters.draggedEvent.quickWorkoutDuration,
        quickWorkoutDistance: parameters.draggedEvent.quickWorkoutDistance
      };

      if (parameters.isCopyAndDrag) {
        model.recurringEvent = null;
        this.teamService.addTeamProgramWorkoutDay(this.teamId, model).subscribe((result) => {
          this.getData();
        });

      } else {
        this.teamService.updateTeamProgramWorkoutDay(this.teamId, parameters.draggedEvent.eventId, model).subscribe((result) => {
          this.getData();
        });
      }
    }

    onEventCopyClicked(event: CalendarEventViewModel) {
      if (event.workout || event.eventType == this.eventType.QuickWorkout) {
        const modalRef = this.modalService.open(CopyCalendarEventDialogComponent, { size: 'lg'});
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.teamId = this.teamId;
        modalRef.componentInstance.calendarType = CalendarType.Team;
        modalRef.result.then(
          (result) => {},
          (reason) => {
            if (reason == 'saved') {
              this.getData();
            }
          }
        );
      }
    }

    onWeekCopyClicked(date: Date) {
      const modalRef = this.modalService.open(CopyCalendarWeekDialogComponent, { size: 'lg'});
        modalRef.componentInstance.originalDate = date;
        modalRef.componentInstance.teamId = this.teamId;
        modalRef.componentInstance.calendarType = CalendarType.Team;
        modalRef.result.then(
          (result) => {},
          (reason) => {
            if (reason == 'saved') {
              this.getData();
            }
          }
        );
    }

    private openCalendarEventDialog(calendarEvent: CalendarWorkoutEvent) {
      const modalRef = this.modalService.open(CalendarWorkoutDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
      modalRef.componentInstance.isCoachView = true;
      modalRef.componentInstance.calendarType = CalendarType.Team;
      modalRef.componentInstance.teamId = this.teamId;
      modalRef.componentInstance.calendarEvent = calendarEvent;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
    }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.scss"]
})
export class PublicLayoutComponent implements OnInit {
  initialized: boolean = false;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.initialized = true;
  }
}

<nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
  <ol class="breadcrumb breadcrumb-links">
    <li class="breadcrumb-item">
      <a [routerLink]="['/']"><i class="fas fa-home"></i></a>
    </li>

    <ng-content></ng-content>

    <li *ngFor="let breadcrumb of breadcrumbs.Breadcrumbs; let last = last" class="breadcrumb-item" [ngClass]="{'active': last}">
      <a *ngIf="breadcrumb.route" [routerLink]="[breadcrumb.route]">{{breadcrumb.text}}</a>
      <a *ngIf="!breadcrumb.route">{{breadcrumb.text}}</a>
    </li>

  </ol>
</nav>

import { Component, Input, EventEmitter, Output, forwardRef, ElementRef, AfterContentInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { secondsToHoursMinutesSeconds } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'minutes-seconds-textbox',
  host: {
    '[class.rundnaDropdown]': 'someAttr',
},
  template: `<div class="{{cssClass}}">
    <kendo-textbox [(ngModel)]="displayValue" class="medium-textbox" placeholder="[h]:mm:ss" (valueChange)="onChange($event)" (blur)="onBlur()" [disabled]="disabled"></kendo-textbox>
    <span> {{unitOfmeasurement}}</span>
    </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinutesSecondsTextbox),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinutesSecondsTextbox),
      multi: true,
    }
  ],
})
export class MinutesSecondsTextbox implements ControlValueAccessor, AfterContentInit, Validator {
  cssClass: string = '';
  // valid formats are: hh:mm:ss, h:mm:ss, mm:ss, m:ss, ss
  minSecRegEx = new RegExp('^(?:(?:[0-1]?[0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?|(?:[0-5]?[0-9]:)?[0-5][0-9]:[0-5][0-9]|^[0-5][0-9])$');
  displayValue: string;
  selectedValue: number;
  unitOfmeasurement: string;

  @Input()
  disabled: boolean;

  @Output()
  blur = new EventEmitter<number>();

  @Output()
  valueChange = new EventEmitter<number>();

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: number): void {
    this.selectedValue = value;
    const hrsMinAndSec = secondsToHoursMinutesSeconds(this.selectedValue);
    this.displayValue = null;

    if (this.selectedValue) {
      let minutesString = (hrsMinAndSec.hours > 0 || hrsMinAndSec.minutes > 9) ? hrsMinAndSec.minutes.toString().padStart(2, '0') : hrsMinAndSec.minutes.toString().padStart(1, '0');
      this.displayValue = hrsMinAndSec.hours == 0 && hrsMinAndSec.minutes == 0 ? hrsMinAndSec.seconds.toString().padStart(2, '0') : ((hrsMinAndSec.hours > 0 ? (hrsMinAndSec.hours.toString() + ':') : '') + minutesString + ':' + hrsMinAndSec.seconds.toString().padStart(2, '0'));
      this.setUnitOfMeasurement(hrsMinAndSec.hours, hrsMinAndSec.minutes);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange(value: any) {
    this.calculateSeconds();
    this.propagateChange(this.selectedValue);
    this.propagateTouch();
    this.valueChange.emit(this.selectedValue);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor(elementRef: ElementRef) {
    this.cssClass = elementRef.nativeElement.getAttribute('class');
    elementRef.nativeElement.setAttribute('class', '');
  }

  ngAfterContentInit(): void {

  }

  onBlur() {
    this.blur.emit(this.selectedValue);
  }

  calculateSeconds() {
    if (this.minSecRegEx.test(this.displayValue)) {

      const formattedValue = this.displayValue.length == 4 ? this.displayValue.padStart(8, '00:0') : (this.displayValue.length == 5 ? this.displayValue.padStart(8, '00:') : this.displayValue.padStart(8, '00'));
      const seconds = parseInt(formattedValue.substr(6,2));
      const minutes = parseInt(formattedValue.substr(3,2));
      const hours = parseInt(formattedValue.substr(0,2));;
      this.selectedValue = ((hours || 0) * 3600) + ((minutes || 0) * 60) + (seconds || 0);
      this.setUnitOfMeasurement(hours, minutes);
    } else {
      this.selectedValue = null;
    }
  }

  setUnitOfMeasurement(hours: number, minutes: number) {
    this.unitOfmeasurement = hours > 0 ? 'hr' : (minutes > 0 ? 'min' : 'sec');
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const duration = control.value;
    if (this.displayValue && !this.minSecRegEx.test(this.displayValue)) {
      return {
        invalidFormat: {
          duration
        }
      };
    }
  }
}


import { Component, Input, EventEmitter, Output, forwardRef, AfterContentInit } from '@angular/core';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AzureBlobSASViewModel, StringViewModel, VideoViewModel } from 'src/app/_models/generatedModels';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISasToken } from 'src/app/_services/azureStorage';
import { BlobStorageService } from 'src/app/_services/blob-storage.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExerciseService, VideoService } from 'src/app/_services/generatedServices';
interface IUploadProgress {
  filename: string;
  progress: number;
}

@Component({
  selector: 'video-upload',
  templateUrl: 'video-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoUploadComponent),
      multi: true,
    },
  ],
})
export class VideoUploadComponent implements ControlValueAccessor, AfterContentInit {
  initialized = false;
  selectedValue: number;

  fileName: string;
  fileNameDisplay: string;
  currentFile: any;
  uploading = false;
  percentComplete: number = -1;
  uploadProgress$: Observable<IUploadProgress[]>;
  public uploadData: AzureBlobSASViewModel;

  @Input()
  label: string = "Upload Video";

  @Output()
  valueChange = new EventEmitter<number>();

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: number): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange(value: any) {
    this.writeValue(value);
    this.propagateChange(this.selectedValue);
    this.propagateTouch();
    this.valueChange.emit(value);
  }

  constructor(private toastr: ToasterService, private blobStorage: BlobStorageService, private videoService: VideoService) {}

  ngAfterContentInit(): void {
    if (this.selectedValue) {
      this.videoService.getVideoById(this.selectedValue).subscribe(result => {
        this.fileNameDisplay = result.fileNameDisplay;
        this.initialized = true;
      });
    } else {
      this.initialized = true;
    }
  }

  onFileChange(event) {
    this.currentFile = event.target.files[0];
    this.percentComplete = 1;

    let model = new StringViewModel();
    model.value = this.currentFile.name;
    this.videoService.getSASForVideoContainer(model).subscribe((result) => {
      this.uploadData = result;

      this.uploadFile(this.currentFile).subscribe(
        (progress) => {
          if (progress.progress === 0) {
            this.percentComplete = 1;
          } else {
            this.percentComplete = progress.progress;
          }
        },
        (error) => {},
        () => {
          this.successEventHandler(null);
        }
      );
    });
  }

  uploadFile(file: File): Observable<IUploadProgress> {
    const accessToken: ISasToken = {
      container: this.uploadData.containerName,
      filename: this.uploadData.fileName,
      storageAccessToken: this.uploadData.sasToken,
      storageUri: this.uploadData.storageUri,
    };

    return this.blobStorage.uploadToBlobStorage(accessToken, file).pipe(map((progress) => this.mapProgress(file, progress)));
  }

  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress,
    };
  }

  successEventHandler(e) {
    this.fileName = this.uploadData.fileName;
    this.fileNameDisplay = this.currentFile.name;

    const model: VideoViewModel = {
      fileNameDisplay: this.fileNameDisplay,
      fileName: this.fileName
    }
    this.videoService.addVideo(model).subscribe(result => {
      this.onChange(result.id);
      this.percentComplete = -1;
    });
  }
}

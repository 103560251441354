import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

export class Color {
  constructor(color, value) {
    this.color = color;
    this.value = value;
  }
  color: string;
  value: number;
}

@Component({
  selector: 'status-bar',
  templateUrl: 'status-bar.component.html',
})
export class StatusBarComponent implements OnInit {
  initialized = false;
  @Input()
  maxValue: number;

  @Input()
  minValue: number;

  @Input()
  maxValueAbbr: string;

  @Input()
  minValueAbbr: string;

  @Input()
  valueLeft: string;

  @Input()
  valueRight: string;

  @Input()
  redPoints: number[];

  @Input()
  greenPoints: number[];

  @Input()
  yellowPoints: number[];

  @Input()
  // this is used for Peak IR because it uses abbrev ext for external, not extended and in this case ext should NOT be a negative value
  ingoreExt: boolean = false;

  @Input()
  // this is used for Pelvic Drop bc we are showing Left and Right values separately, but still want to use the L and R notations
  showAsLeft: boolean = false;

  percentageLeft: number;
  percentageRight: number;
  styles: any;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    let numberPattern = /\d+/g;
    let parenthesisPattern = /\(([^)]+)\)/;
    let totalRange = this.maxValue - this.minValue;

    let valueLeftStripped = parseFloat(this.valueLeft.match(numberPattern)?.join('.'));
    let valueLeftText = this.valueLeft.match(parenthesisPattern);
    if (valueLeftText) {
      valueLeftStripped = this.getValueBasedOnText(valueLeftStripped, valueLeftText[1]);
    }
    this.percentageLeft = ((valueLeftStripped - this.minValue) / totalRange) * 100;
    this.percentageLeft = (this.percentageLeft < 0 ? 0 : (this.percentageLeft > 100 ? 100 : this.percentageLeft));


    if (this.valueRight) {
      let valueRightStripped = parseFloat(this.valueRight.match(numberPattern).join('.'));
      let valueRightText = this.valueRight.match(parenthesisPattern);
      if (valueRightText) {
        valueRightStripped = this.getValueBasedOnText(valueRightStripped, valueRightText[1]);
      }
      this.percentageRight = ((valueRightStripped - this.minValue) / totalRange) * 100;
      this.percentageRight = (this.percentageRight < 0 ? 0 : (this.percentageRight > 100 ? 100 : this.percentageRight));
      
    }


    // background: linear-gradient(90deg, ';
    let colors: Color[] = [];
    this.redPoints.forEach((x) => {
      colors.push(new Color('rgba(255, 0, 0, 1)', ((x - this.minValue) / totalRange) * 100));
    });

    this.greenPoints.forEach((x) => {
      colors.push(new Color('rgba(7, 145, 7, 1)', ((x - this.minValue) / totalRange) * 100));
    });

    this.yellowPoints.forEach((x) => {
      colors.push(new Color('rgba(245, 255, 0, 1)', ((x - this.minValue) / totalRange) * 100));
    });

    colors = colors.sort((a, b) => {
      return a.value - b.value;
    });

    let gradient = '';
    colors.forEach((x) => {
      gradient += ' ' + x.color + ' ' + x.value + '%,';
    });

    //   this.redPoints.forEach((x) => {
    //     gradient += ' rgba(255, 0, 0, 1) ' + x + '%,';
    //  });

    //   this.greenPoints.forEach((x) => {
    //     gradient += ' rgba(7, 145, 7, 1) ' + x + '%,';
    //   });

    //   this.yellowPoints.forEach((x) => {
    //     gradient += ' rgba(245, 255, 0, 1) ' + x + '%,';
    //   });

    let index = gradient.lastIndexOf(',');
    gradient = gradient.substr(0, index);
    gradient += ')';

    this.styles = {
      width: '100%',
      background: 'linear-gradient(90deg,' + gradient,
    };

    // this.styleString = this.sanitizer.bypassSecurityTrustStyle(styles);
    ('linear-gradient(90deg, rgba(245, 255, 0, 1) 0%, rgba(7, 145, 7, 1) 4%, rgba(245, 255, 0, 1) 4%, rgba(255, 0, 0, 1) 70%, rgba(7, 145, 7, 1) 70%, rgba(255, 0, 0, 1) 100%)');

    this.initialized = true;
  }

  getValueBasedOnText(value: number, text: string): number {
    if (text.toLowerCase() == 'df' || (!this.ingoreExt && text.toLowerCase() == 'ext') || text.toLowerCase() == 'sup' || text.toLowerCase() == 'add' || text.toLowerCase() == 'int' || text.toLowerCase() == 'lft up') {
      return value * -1;
    }
    return value;
  }
}

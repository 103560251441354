<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Intensity Chart</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <div class="card-body">
    <div class="row justify-content-center">
      <div class="col text-center">
        <img *ngIf="selectedCardioIntensityType && selectedCardioIntensityType == cardioIntensityType.RateOfPerceivedExertion" class="rpe-image" src="../../../../assets/img/workout/rpe-chart.png" />
        <img *ngIf="(selectedGarminWorkoutStepTargetType && selectedGarminWorkoutStepTargetType == garminWorkoutStepTargetType.HEART_RATE_ZONE) || (selectedCardioIntensityType && selectedCardioIntensityType == cardioIntensityType.HeartRateZone)" class="rpe-image" src="../../../../assets/img/workout/heart-rate-zones.png" />
        <img *ngIf="selectedGarminWorkoutStepTargetType && selectedGarminWorkoutStepTargetType == garminWorkoutStepTargetType.POWER_ZONE" class="rpe-image" src="../../../../assets/img/workout/cycling-power-zones.png" />
      </div>
    </div>
    <div *ngIf="selectedCardioIntensityType && selectedCardioIntensityType == cardioIntensityType.RateOfPerceivedExertion" class="row">
      <div class="col-12 mt-1">
        <a href="https://rundna.com/resources/run-training/how-was-your-run/" target="_blank">Learn more about RPE</a>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col">
      </div>
      <div class="col text-right">
        <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('cancel')">OK</button>
      </div>
    </div>
  </div>
</div>

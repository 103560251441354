import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClientsService } from '../_services/generatedServices';
import { AuthenticationService } from '../_services/authentication.service';
import { ClientSummaryDTO } from '../_models/generatedModels';
import { dynamicSort } from '../_helpers/extensions.module';

@Component({
  selector: 'runners-bulk-select',
  host: {
    '[class.rundnaDropdown]': 'someAttr',
  },
  templateUrl: 'runnersBulkSelect.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RunnerBulkSelectControl),
      multi: true,
    },
  ],
})
export class RunnerBulkSelectControl implements ControlValueAccessor, AfterContentInit {
  someAttr: string = 'anyValue';

  constructor(elementRef: ElementRef, private clientData: ClientsService, private auth: AuthenticationService) {
    this.cssClass = elementRef.nativeElement.getAttribute('class');
    elementRef.nativeElement.setAttribute('class', '');
  }

  @Input()
  isDisabled: boolean;

  @Input()
  unselectedText: string = '';

  @Input()
  selectedText: string = '';

  @Output()
  selectedChanged = new EventEmitter<number[]>();

  @Output()
  unselectedChanged = new EventEmitter<number[]>();

  initialized: boolean;
  selectedValues: number[] = [];
  unselectedValues: number[] = [];
  tempSelectedIds: number[] = [];
  tempUnselectedIds: number[] = [];

  runners: ClientSummaryDTO[] = [];
  selectedRunners: ClientSummaryDTO[] = [];
  unselectedRunners: ClientSummaryDTO[] = [];
  @ViewChild('multiselect') public multiselect: MultiSelectComponent;
  cssClass: string = '';

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: any): void {
    this.selectedValues = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange(value: any) {
    this.writeValue(value);
    this.propagateChange(this.selectedValues);
    this.propagateTouch();
    this.selectedChanged.emit(value);
    this.unselectedChanged.emit(this.unselectedValues);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngAfterContentInit(): void {
    this.auth.fetchUserProfile().subscribe((user) => {
      this.clientData.getActiveClientsForUser(user.id).subscribe((results) => {
        this.runners = results.sort(dynamicSort('userFullName'));
        this.updateAssignments(false);
        this.initialized = true;
      });
    });
  }

  updateAssignments(sendChange: boolean) {
    this.selectedRunners = this.runners.filter((x) => this.selectedValues.indexOf(x.userId) != -1).sort(dynamicSort('userFullName'));
    this.unselectedRunners = this.runners.filter((x) => this.selectedValues.indexOf(x.userId) == -1).sort(dynamicSort('userFullName'));
    this.unselectedValues = this.runners.filter((x) => this.selectedValues.indexOf(x.userId) == -1).sort(dynamicSort('userFullName')).map(x => x.userId);
    if (sendChange) {
      this.onChange(this.selectedValues);
    }
  }

  assign() {
    if (!this.tempUnselectedIds) return;

    this.tempUnselectedIds.forEach((element) => {
      this.selectedValues.push(element);
    });

    this.updateAssignments(true);
  }

  unassign() {
    if (!this.tempSelectedIds) return;
    this.tempSelectedIds.forEach((element) => {
      var index = this.selectedValues.indexOf(element);
      this.selectedValues.splice(index,1);
    });

    this.updateAssignments(true);
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="p-3">
  <div class="mb-2">
    <div class="row">
      <div class="col-12 text-center">
        <img class="rundna-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-default.png" />
      </div>
      <div class="col-12 text-center mb-1">
        <div>
          For full details visit app.rundna.com
        </div>
      </div>
    </div>
  </div>

  <h3 class="d-inline pdf-spacing">{{workout.name}}</h3>
  <div *ngIf="workout.workoutInstances.length > 0" class="mb-2">This workout is on your calendar for: 
    <span *ngFor="let instance of workout.workoutInstances; let last = last">
      <span>{{ instance.workoutDate | date:'shortDate' }}{{ last ? '' : ', ' }}</span>
    </span>
  </div>
  <div *ngIf="workoutInstancesWithNotes.length > 0" class="mb-2">Workout notes: 
    <div *ngFor="let instance of workoutInstancesWithNotes; let last = last">
      <span>{{ instance.workoutDate | date:'shortDate' }}: {{instance.coachNotes}}</span>
    </div>
  </div>
  <div>
    <div *ngIf="workout.workoutCategory">
      <span class="font-weight-bold">Category: </span><span>{{workout.workoutCategory | enumString: workoutCategoryEnum}}</span>
    </div>
    <div *ngIf="workout.expectedTime">
      <span class="font-weight-bold">Expected Duration: </span><span>{{workout.expectedTime | secsToHourMinSec}}</span>
    </div>
    <div *ngIf="workout.expectedRPE">
      <span class="font-weight-bold">Expected RPE: </span><span>{{workout.expectedRPE}}</span>
    </div>
    <div *ngIf="workout.workoutKeywords.length > 0">
      <span class="font-weight-bold">Keywords: </span>
      <span *ngFor="let keyword of workout.workoutKeywords; let last = last">
        <span>{{ keyword.name }}{{ last ? '' : ', ' }}</span>
      </span>
    </div>
    <div>
      <span class="font-weight-bold">Goal: </span><span>{{workout.goal}}</span>
    </div>
    <ng-container *ngIf="workout.videoLink && workout.videoType == 2">
      <div>Video: {{workout.videoLink}}</div>
      <div>
        <ngx-qrcode 
          [elementType]="'url'" 
          [value]="workout.videoLink"
          errorCorrectionLevel="L">
        </ngx-qrcode>
      </div>
    </ng-container>
    <div class="font-weight-bold">Description:</div>
    <span [editorHTML]="workout.description"></span>
    <div *ngIf="workout.garminPoolLength">
      <span class="font-weight-bold">Pool Length: </span><span>{{workout.garminPoolLength}}</span><span *ngIf="workout.garminPoolLengthUnit"> {{workout.garminPoolLengthUnit | enumString: garminPoolLengthUnitEnum}}</span>
    </div>
  </div>

  <ng-container *ngFor="let item of workoutItems">
    <div class="">
      <workout-item-view [isWorkoutPreview]="true" [disabled]="true" [workoutItem]="item" [summaryOnly]="true"></workout-item-view>
      
      <ng-container *ngFor="let detail of item.workoutItemDetails">
        <workout-item-detail-view [detail]="detail" [isWorkoutPreview]="true" [isPDF]="true" [disabled]="true" [workoutItem]="item"></workout-item-detail-view>
      </ng-container>
    </div>
  </ng-container>

</div>

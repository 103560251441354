import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'teams-container',
  templateUrl: 'teams-container.component.html',
})
export class TeamsContainerComponent implements OnInit {
  initialized = false;

  constructor(private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetBreadcrumb('Teams', '/teams', []);
    this.initialized = true;

  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { LevelOfRunning, RunningExperience, UpdateProfileWithPasswordViewModel, UserRole, UserProfileViewModel, MeasurementSystem } from 'src/app/_models/generatedModels';
import { coachUserRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-complete-registration',
  templateUrl: 'complete-registration.component.html',
})
export class CompleteRegistrationComponent implements OnInit {
  initialized = false;
  submitComplete: Promise<{}> | undefined;
  public formGroup: FormGroup;
  userRole = UserRole;
  user: UserProfileViewModel;
  measurementSystemEnum = Enums.MeasurementSystemEnum;
  isMetricSystem: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, public auth: AuthenticationService, private userService: UserService, private fb: FormBuilder,
    private toaster: ToasterService, private validatorHelper: ValidatorHelper) {}

  ngOnInit(): void {
    let token = this.route.snapshot.queryParamMap.get('token');
    this.auth.refreshClaimsWithToken(token).subscribe((result) => {
      this.userService.getProfile().subscribe(user => {
        this.user = user;
        this.isMetricSystem = this.user.measurementSystem == MeasurementSystem.Metric;
        this.setupForm();
      });
    });
  }

  setupForm(): any {
    let weight = this.user.weightInPounds;
    let height = this.user.heightInInches;
    if (this.isMetricSystem) {
      weight = weight ? Math.round(weight * 0.453592) : null;
      height = height ? Math.round(height * 2.54) : null;
    }

    this.formGroup = this.fb.group({
      firstName: [this.user.firstName, [Validators.required]],
      yearOfBirth: [this.user.yearOfBirth],
      gender: [this.user.gender, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      email: [this.user.email, [Validators.required, Validators.email], [this.validatorHelper.validateEmailNotUsed(this.user.email)]],
      phoneNumber: [this.user.phoneNumber],
      weightInPounds: [weight, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      heightInInches: [height, [Validators.required]],
      yearsOfExperience: [RunningExperience[this.user.yearsOfExperience], [Validators.required]],
      highestLevelOfRunning: [LevelOfRunning[this.user.highestLevelOfRunning], [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [''],
      termsAndConditionsVersion: [null, [Validators.required]],
      measurementSystem: [this.user.measurementSystem],
    },
    { validator: this.checkPasswords });

    this.initialized = true;
  }

  onMeasurementSystemChanged() {
    this.isMetricSystem = this.formGroup.get('measurementSystem').value == MeasurementSystem.Metric;
    if (this.isMetricSystem) {
      this.formGroup.get('weightInPounds').setValue(this.formGroup.get('weightInPounds').value ? Math.round(this.formGroup.get('weightInPounds').value * 0.453592) : null);
      this.formGroup.get('heightInInches').setValue(this.formGroup.get('heightInInches').value ? Math.round(this.formGroup.get('heightInInches').value * 2.54) : null);
    } else {
      this.formGroup.get('weightInPounds').setValue(this.formGroup.get('weightInPounds').value ? Math.round(this.formGroup.get('weightInPounds').value / 0.453592) : null);
      this.formGroup.get('heightInInches').setValue(this.formGroup.get('heightInInches').value ? Math.round(this.formGroup.get('heightInInches').value / 2.54) : null);
    }
  }


  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toaster.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: UpdateProfileWithPasswordViewModel = Object.assign({}, this.user, this.formGroup.value);
      formData.base64Image = null;

      if (this.isMetricSystem) {
        formData.heightInInches = Math.round(formData.heightInInches / 2.54);
        formData.weightInPounds = Math.round(formData.weightInPounds / 0.453592);
      }

      this.update(formData, resetButton);
    });
  }

  navigateBack(userRole: UserRole) {
    if ((userRole & coachUserRole) != 0) {
      this.router.navigate(['/'], { relativeTo: this.route.parent });
    } else {
      this.router.navigate(['/runner/dashboard'], { relativeTo: this.route.parent });
    }
  }

  update(formData: UpdateProfileWithPasswordViewModel, resetButton: () => any) {
    this.userService.completeRegistration(formData).subscribe(
      (user) => {
        this.auth.fetchUserProfile().subscribe((userResult) => {
          this.navigateBack(userResult.userRole);
        this.toaster.success('Profile Updated', 'Success');
        resetButton();
        });
      },
      (error) => resetButton()
    );
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  get f() {
    return this.formGroup;
  }
}

import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { dateWithoutTime } from '../_helpers/extensions.module';

declare var google: any;

// Service to render graphs. Usage: ask for this service, and add an element to your component's markup with an ID attribute.
// In the afterviewinit callback, use the queueChart method to pass in a charting function from this service, as well as the
// data/configuration options in the form of the ChartData object.

@Injectable()
export class ChartingService {

    onChartsLoaded: Subject<void> = new Subject<void>();
    initialized: boolean = false;
    activeSubscription: Subscription;
    defaultColor: string = "#0560bd";
    NAcolor: string = "gray";

    constructor() {
        google.charts.load('current', {packages: ['corechart']});

        google.charts.setOnLoadCallback(() => {
            this.initialized = true;
            this.onChartsLoaded.next();
        });
    }

    queueChart(chartingFunction: (chartData: ChartData) => void, chartData: ChartData) {
        if (!this.initialized) {
            this.activeSubscription = this.onChartsLoaded.subscribe(() => {
                setTimeout(() => {
                    chartingFunction(chartData);
                }, 10);
                this.activeSubscription.unsubscribe();
            })
        } else {
            setTimeout(() => {
                chartingFunction(chartData);
            }, 10);
        }
    }

    //use this if you are going to need to build more than one chart; and then call the unsubscribe function when the component is destroyed
    queueCharts(chartingFunction: (chartData: ChartData) => void, chartData: ChartData) {
        if (!this.initialized) {
            this.activeSubscription = this.onChartsLoaded.subscribe(() => {
                setTimeout(() => {
                    chartingFunction(chartData);
                }, 10);
            })
        } else {
            setTimeout(() => {
                chartingFunction(chartData);
            }, 10);
        }
    }

    unsubscribe() {
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
    }

    buildOptions(chartData: ChartData, pointSize: number): any {
      let options: any = {
        legend: { position: 'none' },
        pointSize: pointSize,
        vAxis: { minValue: 0 },
        hAxis: { gridlines: { color: 'white' } },
        title: chartData.title,
        titlePosition: 'out',
        titleTextStyle: { bold: true, fontSize: 20 },
        chartArea: { right: '30', left: '50' }
      };

      if (chartData.columns[0].type == "date") {
        options.hAxis.format = 'M/d/yy';
      } else {
        options.hAxis.format =  'HH:mm:ss'
      }

      if (chartData.columns[1].type == "timeofday") {
        options.vAxis.format = 'mm:ss'
      }

      if (chartData.reverseYAxis) {
        options.vAxis.direction = -1;
      }

      // will have to make this more dynamic when we do charts other than last 7 days
      if (chartData.columns[0].type == "date" && chartData.columns[1].id != "ACWR %") {
        const today = dateWithoutTime(new Date());
        options.hAxis.minValue = today.addDays(-6);
        options.hAxis.maxValue = today;
        options.hAxis.baseline = new Date(options.hAxis.minValue);
      }

      return options;
    }

    drawAreaChart(chartData: ChartData) {
        let elem = document.getElementById(chartData.targetElementId);
        let dataTable = google.visualization.arrayToDataTable([
            chartData.columns,
            ...chartData.data
        ]);

        let options = this.buildOptions(chartData, 0);
        let chart = new google.visualization.AreaChart(elem);
        chart.draw(dataTable, options);
    }

    drawScatterChart(chartData: ChartData) {
      let elem = document.getElementById(chartData.targetElementId);
      let dataTable = google.visualization.arrayToDataTable([
          chartData.columns,
          ...chartData.data
      ]);

      let options = this.buildOptions(chartData, 1);
      let chart = new google.visualization.ScatterChart(elem);
      chart.draw(dataTable, options);
  }

  drawLineChart(chartData: ChartData) {
    let elem = document.getElementById(chartData.targetElementId);
    let dataTable = google.visualization.arrayToDataTable([
        chartData.columns,
        ...chartData.data
    ]);

    let options = this.buildOptions(chartData, 3);
    let chart = new google.visualization.LineChart(elem);
    chart.draw(dataTable, options);
  }

  drawStackedColumnChart(chartData: ChartData) {
    let elem = document.getElementById(chartData.targetElementId);
    let dataTable = google.visualization.arrayToDataTable([
        chartData.columns,
        ...chartData.data
    ]);

    var options = {
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '75%' },
      isStacked: true,
      title: chartData.title,
      titlePosition: 'out',
      titleTextStyle: { bold: true, fontSize: 20 },
      series: {},
      chartArea: {right: '10', left: '50'},
      intervals: { style: 'bars', color: '#000000' },
      annotations: {}
    };

    switch(chartData.seriesName) {
      case 'completionPercentage': {
        options.series = {
          0:{color:'#f5365c'},
          1:{color:'#fb6340'},
          2:{color:'#2dce89'}
        }
         break;
      }
      case 'trainingVolume': {
        options.series = {
          0:{color:'#c20329'},
          1:{color:'#d73f1c'},
          2:{color:'#2dce89'},
          3:{color:'#fb6340'},
          4:{color:'#f5365c'}
        }
         break;
      }
      case 'intensityRPE': {
        options.series = {
          0:{color:'#007BFF'},
          1:{color:'#28A745'},
          2:{color:'#FFC107'},
          3:{color:'#fb6340'},
          4:{color:'#c20329'},
          5:{color: '#000000'},
          6:{color: '#000000'}
        };
        options.titleTextStyle.fontSize = 14;
        options.titleTextStyle.bold = false;
        options.annotations = {
          alwaysOutside: true,
          stem: {
            color: "transparent"
          },
          textStyle: {
            color: "#000000",
            fontSize: 12
          }
        };
        break;
      }
   }

    let chart = new google.visualization.ColumnChart(elem);

    // move annotations
    if (chartData.seriesName == 'intensityRPE') {
      google.visualization.events.addListener(chart, 'ready', function () {
        document.querySelectorAll('#intensityRPEChart text[fill="#000000"][font-size="12"]').forEach(function (annotation, index) {
          if (!isNaN(parseFloat(annotation.textContent))) {
            annotation.setAttribute('y', '50');
          }
        });
      });
    }

    chart.draw(dataTable, options);
  }
}

export class ChartData {
    public targetElementId: string;
    //data table column names in array
    public columns: any[];
    // 2D array containing the data we're going to use
    public data: any[];
    // Title for the chart
    public title: string;
    public seriesName: string;
    public reverseYAxis: boolean = false;

    constructor(element: string, columns: any[], data: any[], title: string, seriesName: string = null, reverseYAxis: boolean = false) {
        this.targetElementId = element;
        this.columns = columns;
        this.data = data;
        this.title = title;
        this.seriesName = seriesName;
        this.reverseYAxis = reverseYAxis;
    }
}

<div *ngIf="initialized">
  <div class="card">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">{{tooltip.title}}</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <div class="text-center">
          <div *ngIf="tooltip.videoLink">
            <embed-video-player [url]="tooltip.videoLink" [size]="'large'"></embed-video-player>
          </div>
        </div>
        <div class="row">
          <div class="col" [innerHTML]="tooltip.description">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

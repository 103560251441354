import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-assessments-container',
  templateUrl: 'assessments-container.component.html',
})
export class AssessmentsContainerComponent implements OnInit {
  initialized = false;
  constructor(private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Assessments', 'assessments', []);
    this.initialized = true;
  }
}

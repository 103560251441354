import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'clients-list-container',
  templateUrl: 'clients-list-container.component.html',
})
export class ClientsListContainerComponent implements OnInit {
  initialized = false;

  constructor(private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetBreadcrumb('Athletes', '/athletes', []);
    this.initialized = true;

  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ClientsService } from 'src/app/_services/generatedServices';
import { WorkoutItemType, CardioIntensityType, CardioType, GarminWorkoutStepTargetType, ExerciseResistanceType, WorkoutItemDisplayViewModelRead, WorkoutItemDisplayDetailsViewModelRead, ClientProgramWorkoutDayItemViewModel } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IntensityChartDialogComponent } from '../intensity-chart-dialog/intensity-chart-dialog.component';
import { ExerciseLevelChangeModalComponent } from '../exercise-level-change-modal/exercise-level-change-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExerciseLevelChangedParameters } from 'src/app/_models/models';

@Component({
  selector: 'workout-item-detail-view',
  templateUrl: 'workout-item-detail-view.component.html',
  styleUrls: ['workout-item-detail-view.component.scss']
})
export class WorkoutItemDetailViewComponent implements OnInit {
  initialized = false;
  workoutItemType = WorkoutItemType;
  cardioIntensityTypeEnum = Enums.CardioIntensityTypeEnum;
  cardioIntensityType = CardioIntensityType;
  cardioType = CardioType;
  distanceUOMEnum = Enums.CardioDistanceUOMEnum;
  showDetails: boolean = false;
  currentDetailsId: number;
  garminWorkoutStepTargetType = GarminWorkoutStepTargetType;
  public exerciseSetFormGroup: FormGroup;


  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  workoutItem: WorkoutItemDisplayViewModelRead;

  @Input()
  detail: WorkoutItemDisplayDetailsViewModelRead;

  @Input()
  exerciseProgressSortOrder: number;

  @Input()
  disabled: boolean;

  @Input()
  isWorkoutPreview: boolean = false;

  @Input()
  isPDF: boolean = false;

  @Input()
  summaryOnly: boolean = false;

  @Output()
  levelChanged = new EventEmitter<ExerciseLevelChangedParameters>();

  @Output()
  toggleCompleteChanged = new EventEmitter<ClientProgramWorkoutDayItemViewModel>();

  constructor(private clientService: ClientsService, private modalService: NgbModal, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.setupForm();
    this.initialized = true;
  }

  toggleDetails(id: number) {
    if (id === this.currentDetailsId) {
      this.currentDetailsId = 0;
    } else {
      this.currentDetailsId = id;
    }
  }

  onIntensityChart(cardioIntensityType: CardioIntensityType, garminWorkoutStepTargetType: GarminWorkoutStepTargetType) {
    const modalRef = this.modalService.open(IntensityChartDialogComponent, { size: 'lg', windowClass: 'modal-md-custom' });
    modalRef.componentInstance.selectedCardioIntensityType = cardioIntensityType;
    modalRef.componentInstance.selectedGarminWorkoutStepTargetType = garminWorkoutStepTargetType;
  }

  levelChange(changeType: string, levelChangeExerciseId: number, defaultExerciseId: number, detailId: number) {
    const modalRef = this.modalService.open(ExerciseLevelChangeModalComponent, { size: 'lg' });
    modalRef.componentInstance.userCredentialId = this.workoutItem.userCredentialId;
    modalRef.componentInstance.workoutId = this.workoutItem.workoutId;
    modalRef.componentInstance.defaultExerciseId = defaultExerciseId;
    modalRef.componentInstance.levelChangeExerciseId = levelChangeExerciseId;
    modalRef.componentInstance.isLevelUp = changeType == 'up';
    modalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason == 'saved') {
          this.clientService.getClientWorkoutItemExerciseDisplayDetails(this.clientProgramWorkoutDayId, this.workoutItem.id, detailId, levelChangeExerciseId, this.exerciseProgressSortOrder).subscribe(result => {
            let itemDetail = this.workoutItem.workoutItemDetails.find(x => x.id == detailId);
            itemDetail = result;
            this.detail = result;
            this.levelChanged.emit(new ExerciseLevelChangedParameters(this.workoutItem.id, this.detail.id, itemDetail.exerciseProgress));
          });
        }
      }
    );
  }

  setupForm() {
    let exerciseProgressSets = this.fb.array([]);

    exerciseProgressSets.push(this.fb.group({
      setNumber: [1],
      reps: [10],
      weight: [null],
      isCompleted: [false],
      resistanceType: [ExerciseResistanceType.Resistance]
    }));

    exerciseProgressSets.push(this.fb.group({
      setNumber: [2],
      reps: [10],
      weight: [null],
      isCompleted: [false],
      resistanceType: [ExerciseResistanceType.Resistance]
    }));

    this.exerciseSetFormGroup = this.fb.group({
      exerciseProgressSets: exerciseProgressSets
    });
  }

  toggleComplete(event: any) {
    const model: ClientProgramWorkoutDayItemViewModel = {
      clientProgramWorkoutDayId: this.clientProgramWorkoutDayId,
      workoutItemId: this.workoutItem.id,
      isCompleted: this.workoutItem.isCompleted,
      completedDate: (this.workoutItem.isCompleted ? new Date() : null)
    }

    this.clientService.addUpdateClientProgramWorkoutDayItem(model).subscribe(result => {
      this.toggleCompleteChanged.emit(model);
    });
  }
}

import { Component } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
    selector: 'rundna-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

    constructor(public breadcrumbs: BreadcrumbsService) { }

}

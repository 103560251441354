import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-administration',
  templateUrl: 'administration.component.html',
})
export class AdministrationComponent implements OnInit {
  initialized = false;
  constructor(private breadcrumbs: BreadcrumbsService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetBreadcrumb('Administration', '/admin', []);
    this.initialized = true;
  }
}

<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-12">
          <h6 class="h2 d-inline-block mb-0">Reports</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>

          <div class="float-right">
            <div class="btn-group" #myDrop="ngbDropdown" ngbDropdown role="group" aria-label="Select a report">
              <button class="btn btn-primary" ngbDropdownToggle>Select a report</button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button [routerLink]="['/reports/completion-percentage']" (click)="myDrop.close();" ngbDropdownItem>Completion Percentage Report</button>
                <button [routerLink]="['/reports/training-volume']" (click)="myDrop.close();" ngbDropdownItem>Training Volume Report</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-3">
  <router-outlet></router-outlet>
</div>

import { Component, Input, OnInit} from '@angular/core';
import { BasicDonutPieData } from 'src/app/_models/models';


@Component({
  selector: 'acute-chronic-donut-chart',
  templateUrl: 'acute-chronic-donut-chart.component.html',
})
export class AcuteChronicDonutChartComponent implements OnInit {
  initialized = false;
  hasData: boolean = false;
  chartData: BasicDonutPieData[];

  @Input()
  acwrPercentage: number;

  @Input()
  intensity7Days: number;

  @Input()
  intensityTrailing7to34Days: number;

  @Input()
  isTrainingLoadTargetIncomplete: boolean = false;

  @Input()
  title: string;


  constructor() {}

  ngOnInit(): void {
    this.chartData = this.getACWRPieChartData(this.intensity7Days, this.intensityTrailing7to34Days);
    this.initialized = true;
  }

  getACWRPieChartData(acuteValue:number, chronicTotalValue: number): BasicDonutPieData[] {
    let chartData = [];
    let chronicAvgValue = Math.round(chronicTotalValue / 4);
    chartData.push(new BasicDonutPieData('Acute (' + acuteValue + ')', acuteValue, '#007BFF'));
    chartData.push(new BasicDonutPieData('Chronic (' + chronicAvgValue + ')', chronicAvgValue, '#4DC8FF'));
    return chartData;
  }
}

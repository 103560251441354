import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { UserRole } from '../_models/generatedModels';
import { coachUserRoleArray } from '../_models/models';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let roles = route.data['roles'] as Array<UserRole>;
    if (this.auth.hasClaimForRole(roles)) {
      return true;
    } else {
      if (this.auth.hasClaimForRole(coachUserRoleArray)) {
        this.router.navigateByUrl('');
      } else {
        this.router.navigateByUrl('/runner');
      }

      return false;
    }
  }
}

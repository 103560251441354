<page-load-header *ngIf="!initialized"></page-load-header>

<div class="row" [ngClass]="(workoutItem.workoutItemType == workoutItemType.Circuit || workoutItem.workoutItemType == workoutItemType.Interval || workoutItem.workoutItemType == workoutItemType.GarminWorkoutRepeatStep) ? 'interval' : ''" *ngIf="initialized">
  <div class="col-md-12 col-lg-9" *ngIf="workoutItem.workoutItemType == workoutItemType.Circuit || workoutItem.workoutItemType == workoutItemType.Interval || workoutItem.workoutItemType == workoutItemType.GarminWorkoutRepeatStep">
    {{workoutItem.stepText}}{{workoutItem.text}}
  </div>
  <div class="col-md-12 col-lg-3" *ngIf="(workoutItem.workoutItemType == workoutItemType.Circuit || workoutItem.workoutItemType == workoutItemType.Interval || workoutItem.workoutItemType == workoutItemType.GarminWorkoutRepeatStep) && !isWorkoutPreview">
    <label class="toggle-label">
      Done
    </label>
    <kendo-switch [disabled]="disabled" [(ngModel)]="workoutItem.isCompleted" (valueChange)="toggleComplete($event)"></kendo-switch>
  </div>

</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6">
  <div class="container-fluid">
    <!-- <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-md-12">
          <h6 class="h2 d-inline-block mb-0">Favorite Workouts</h6>
        </div>
      </div>
    </div> -->
    <runner-my-library-view [clientUserId]="clientUserId" [isCoachView]="hasCoachRole"></runner-my-library-view>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventViewModel, CalendarType, ClientDayEventType } from 'src/app/_models/generatedModels';
import { ClientsService, TeamService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'delete-calendar-event-dialog',
  templateUrl: 'delete-calendar-event-dialog.component.html',
  styleUrls: ['delete-calendar-event-dialog.component.scss'],
})
export class DeleteCalendarEventDialogComponent implements OnInit {
  isRecurring = false;
  cannotDelete = false;
  cannotDeleteError: string;
  deleteEventType: string = 'event';

  // this component can be used to delete client or team events; based on Input parameters provided

  @Input()
  isCoachView: number;

  @Input()
  clientUserId: number;

  @Input()
  teamId: number;

  @Input()
  calendarType: CalendarType;

  @Input()
  event: CalendarEventViewModel;

  constructor(public activeModal: NgbActiveModal, private clientsService: ClientsService, private teamsService: TeamService) {}

  ngOnInit(): void {
    if (this.event.isCompleted && (this.event.eventType == ClientDayEventType.Task || this.event.eventType == ClientDayEventType.Workout)) {
      this.cannotDelete = true;
      this.cannotDeleteError = 'Cannot delete a workout/task that is already completed';
      return;
    } else if (!this.isCoachView && this.event.eventType != ClientDayEventType.GarminSyncedWorkout && this.event.eventType != ClientDayEventType.StravaSyncedWorkout
        && this.event.eventType != ClientDayEventType.ManualWorkout && this.event.eventType != ClientDayEventType.Task) {
      this.cannotDelete = true;
      this.cannotDeleteError = 'Cannot delete this event (contact your coach if it needs to be removed).';
      return;
    }

    this.isRecurring = this.event.recurringEvent != undefined;
  }

  onDelete() {
    const deleteSeries = this.deleteEventType == 'series';

    if (this.calendarType == CalendarType.Client) {
      this.clientsService.deleteClientProgramWorkoutDay(this.clientUserId, this.event.eventId, deleteSeries).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    } else if (this.calendarType == CalendarType.Team) {
      this.teamsService.deleteTeamProgramWorkoutDay(this.teamId, this.event.eventId, deleteSeries).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    }
  }
}

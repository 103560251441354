import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[editorHTML]',
})

export class EditorHTMLDirective implements OnInit, OnChanges {
  @Input() editorHTML: string;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'ckembed');
    this.setPropertyValue();
  }

  ngOnChanges() {
    this.setPropertyValue();
  }

  setPropertyValue() {
    let withLineBreaks = this.editorHTML ? this.editorHTML.split(/\r?\n/) : null;
    this.editorHTML = withLineBreaks ? withLineBreaks.join('<br/>') : this.editorHTML;

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.editorHTML);
    Array.from(this.el.nativeElement.querySelectorAll('a'))
        .forEach((el: any) => {
          el.setAttribute('target', '_blank');
        });
  }

}
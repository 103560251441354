import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgramViewModelRead } from 'src/app/_models/generatedModels';
import { ClientsService, ProgramsService } from 'src/app/_services/generatedServices';
import { ProgramWeekExtended } from 'src/app/_models/models';
import { ProgramHelper } from 'src/app/_helpers/programs';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'program-reschedule-dialog',
  templateUrl: 'program-reschedule-dialog.component.html',
  styleUrls: ['program-reschedule-dialog.component.scss']
})
export class ProgramRescheduleDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  program: ProgramViewModelRead;
  programWeeks: ProgramWeekExtended[] = [];
  maxProgramWeeks: number;
  isExpandedDescription: boolean;

  @Input()
    clientProgramId: number;

  @Input()
    refProgramId: number;

  @Input()
    userId: number;

  @Output()
    programRescheduled = new EventEmitter<boolean>();


  constructor(private fb: FormBuilder, private toastr: ToasterService, public activeModal: NgbActiveModal, private clientsService: ClientsService, private programService: ProgramsService) { }

  ngOnInit(): void {

    this.programService.getProgramById(this.refProgramId, false).subscribe(result => {
      this.program = result;
      this.programWeeks = ProgramHelper.populateProgramWeeks(this.program).map(x => x as ProgramWeekExtended);
      this.maxProgramWeeks = this.programWeeks.length;
      this.programWeeks[0].isExpanded = true;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      startDate: [null, Validators.required],
      startWeek: [null, Validators.required]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.toastr.confirmDialog('Any future workouts from this program that are currently on your calendar will be removed before rescheduling the program, but it will not delete past program-related workouts.', 'Are you sure?', null, null, 300).subscribe(result => {
      if (result) {
        this.submitComplete = new Promise((resetButton:any, reject) => {
          this.clientsService.rescheduleClientProgram(this.clientProgramId, dateAsUTCNoTime(new Date(this.formGroup.get('startDate').value)), this.formGroup.get('startWeek').value).subscribe((results) => {
            this.programRescheduled.emit(true);
            this.activeModal.dismiss('saved');
          });
        });
      }
    });
  }

}

<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters mb-2">
      <div class="col">
        <h3 class="mb-0">Survey Maintenance</h3>
      </div>
    </div>

    <ul ngbNav #tabNav="ngbNav" [destroyOnHide]="false" class="nav-pills">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Survey / Questions</a>
        <ng-template ngbNavContent>
          <div *ngIf="initialized" class="row mt-3">
            <div class="col-12">Surveys</div>
            <div class="col-12">
              <div class="table-responsive">
                <table class="table align-items-center table-flush mobile-table">
                  <thead class="thead-light">
                    <tr class="header-row">
                      <th>Survey Name</th>

                      <th>Description</th>

                      <th class="text-lg-center" scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr *ngFor="let survey of surveys">
                      <td>
                        <span class="mobile-grid-label">Survey Name</span>
                        {{survey.name}}
                      </td>

                      <td class="text-wrap">
                        <span class="mobile-grid-label">Description</span>
                        {{survey.description}}
                      </td>

                      <td class="text-lg-center">
                        <span class="mobile-grid-label">Actions</span>
                        <div class="btn-group no-caret">
                          <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"> </i>
                          </button>
                          <div class="dropdown-menu dropdown-menu-lg-right">
                            <a href="javascript:void(0)" (click)="onSurveyEdit(survey.id)" class="dropdown-item">
                              Edit
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">Survey Questions</div>
            <div class="col-12">
              <div class="table-responsive">
                <table class="table align-items-center table-flush mobile-table">
                  <thead class="thead-light">
                    <tr class="header-row">
                      <th>Survey Name</th>

                      <th>Survey Question</th>

                      <th>Description</th>

                      <th>Video Type</th>

                      <th class="text-lg-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <ng-container *ngFor="let survey of surveys">
                      <tr *ngFor="let surveyQuestion of survey.surveyQuestions">
                      <td>
                        <span class="mobile-grid-label">Survey Name</span>
                        {{survey.name}}
                      </td>

                      <td class="text-wrap">
                        <span class="mobile-grid-label">Survey Question</span>
                        {{surveyQuestion.question}}
                      </td>

                      <td class="text-wrap">
                        <span class="mobile-grid-label">Description</span>
                        {{surveyQuestion.description}}
                      </td>

                      <td>
                        <span class="mobile-grid-label">Video Type</span>
                        {{surveyQuestion.videoType | enumString:videoTypeEnum}}
                      </td>

                      <td class="text-lg-center">
                        <span class="mobile-grid-label">Actions</span>
                        <div class="btn-group no-caret">
                          <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"> </i>
                          </button>
                          <div class="dropdown-menu dropdown-menu-lg-right">
                            <a href="javascript:void(0)" (click)="onSurveyQuestionEdit(surveyQuestion.id)" class="dropdown-item">
                              Edit
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="1">
        <a ngbNavLink>Survey Results</a>
        <ng-template ngbNavContent>
          <div *ngIf="initialized" class="row mt-3">
            <div class="col-12">Survey Results</div>
            <div class="col-lg-9 col-md-7">
              <div class="form-group">
                <label class="form-control-label"> &nbsp; </label>
                <custom-input-persisted class="form-control" [placeholder]="'Search by result name'" [key]="'surveyResults'" [(ngModel)]="surveyResultSearchText" (valueChange)="onResultSearchTextChanged($event)"></custom-input-persisted>
              </div>
            </div>
            <div class="col-lg-3 col-md-5">
              <div class="form-group">
                <label class="form-control-label"> Filter by Survey </label>
                <kendo-combobox [data]="surveys" [valuePrimitive]="true" valueField="id" textField="name" [(ngModel)]="selectedSurveyId" class="form-control" (valueChange)="onSurveyFilterChange($event)"></kendo-combobox>
              </div>
            </div>
            <page-load-header *ngIf="searching"></page-load-header>
            <div class="col-12">
              <div *ngIf="!searching" class="table-responsive">
                <table class="table align-items-center table-flush mobile-table">
                  <thead class="thead-light">
                    <tr class="header-row">
                      <th>Survey Name</th>

                      <th>Sort Order</th>

                      <th>Survey Result Name</th>

                      <th>Description</th>

                      <th class="text-lg-center" scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr *ngFor="let result of surveyResults">
                      <td>
                        <span class="mobile-grid-label">Survey Name</span>
                        {{result.survey.name}}
                      </td>

                      <td>
                        <span class="mobile-grid-label">Sort Order</span>
                        {{result.sortOrder}}
                      </td>

                      <td>
                        <span class="mobile-grid-label">Survey Result Name</span>
                        {{result.name}}
                      </td>

                      <td class="text-wrap">
                        <span class="mobile-grid-label">Description</span>
                        {{result.description}}
                      </td>

                      <td class="text-lg-center">
                        <span class="mobile-grid-label">Actions</span>
                        <div class="btn-group no-caret">
                          <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"> </i>
                          </button>
                          <div class="dropdown-menu dropdown-menu-lg-right">
                            <a href="javascript:void(0)" (click)="onSurveyResultEdit(result.id)" class="dropdown-item">
                              Edit
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer py-4">
                <nav aria-label="...">
                  <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
                </nav>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="tabNav"></div>

  </div>
</div>

<page-load-header *ngIf="!initialized || !activityInitialized"></page-load-header>

<div *ngIf="initialized && activityInitialized">

  <accordion>
    <accordion-group [isOpen]="true">
      <h5 accordion-heading>
        <div class="pull-left float-left">ACWR By Sport</div>
        <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
      </h5>
      <div class="row">
        <div *ngIf="acwrByActivity.acuteChronicWorkloadRatioPercentage == null" class="col-md-6 col-lg-4 col-xl-3">
          Not enough data to show
        </div>
        <div *ngIf="acwrByActivity.acuteChronicWorkloadRatioPercentage != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acuteChronicWorkloadRatioPercentage" [intensity7Days]="acwrByActivity.intensityInSeconds7Days" [title]="'Overall'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityInSecondsTrailing7to34Days" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
        <div *ngIf="acwrByActivity.acwrRunning != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card activity-card">
            <div class="card-body">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acwrRunning" [intensity7Days]="acwrByActivity.intensity7DaysRunning" [title]="'Running'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityTrailing7to34DaysRunning" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
        <div *ngIf="acwrByActivity.acwrBiking != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card activity-card">
            <div class="card-body">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acwrBiking" [intensity7Days]="acwrByActivity.intensity7DaysBiking" [title]="'Biking'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityTrailing7to34DaysBiking" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
        <div *ngIf="acwrByActivity.acwrStrength != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card activity-card">
            <div class="card-body activity-card">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acwrStrength" [intensity7Days]="acwrByActivity.intensity7DaysStrength" [title]="'Strength'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityTrailing7to34DaysStrength" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
        <div *ngIf="acwrByActivity.acwrSwimming != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card activity-card">
            <div class="card-body activity-card">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acwrSwimming" [intensity7Days]="acwrByActivity.intensity7DaysSwimming" [title]="'Swimming'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityTrailing7to34DaysSwimming" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
        <div *ngIf="acwrByActivity.acwrWalking != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card activity-card">
            <div class="card-body activity-card">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acwrWalking" [intensity7Days]="acwrByActivity.intensity7DaysWalking" [title]="'Walking'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityTrailing7to34DaysWalking" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
        <div *ngIf="acwrByActivity.acwrOther != null" class="col-md-6 col-lg-4 col-xl-3">
          <div class="card activity-card">
            <div class="card-body activity-card">
              <acute-chronic-donut-chart [acwrPercentage]="acwrByActivity.acwrOther" [intensity7Days]="acwrByActivity.intensity7DaysOther" [title]="'Other'"
                [intensityTrailing7to34Days]="acwrByActivity.intensityTrailing7to34DaysOther" [isTrainingLoadTargetIncomplete]="isTrainingLoadTargetIncomplete"></acute-chronic-donut-chart>
            </div>
          </div>
        </div>
      </div>
    </accordion-group>
    <accordion-group [isOpen]="true">
      <h5 accordion-heading>
        <div class="pull-left float-left">ACWR History</div>
        <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
      </h5>
      <div *ngIf="history.length === 0 || chartData.length == 0" class="mt-3 ml-3 mb-2">No history for this date range</div>

      <div [hidden]="!initialized || chartData.length == 0" class="chart-container mb-5">
        <div id="acwrChart"></div>
      </div>

      <div *ngIf="history && history.length > 0" class="card-footer py-4">
        <nav aria-label="...">
        <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
        </nav>
      </div>

      <div *ngIf="history && history.length > 0" class="row">
        <div class="col-md-6">
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Date</th>
                  <th>ACWR %</th>
                </tr>
              </thead>

              <tbody class="list">
                <tr *ngFor="let item of history">
                  <td class="">
                    {{ item.activityDate | date: 'shortDate' }}
                  </td>
                  <td class="">
                    {{ item.acuteChronicWorkloadRatioPercentage ? (item.acuteChronicWorkloadRatioPercentage * 100 | number: '1.0-0') + '%' : 'N/A' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </accordion-group>
  </accordion>
  
</div>

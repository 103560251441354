import { Component, Input, EventEmitter, Output, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'multi-option-control',
  templateUrl: 'multiOptionControl.component.html',
  styleUrls: ['multiOptionControl.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiOptionControl),
      multi: true,
    },
  ],
})
export class MultiOptionControl implements ControlValueAccessor, OnInit {
  @Input()
  leftOption: any = { label: 'Yes', value: 'yes' };

  @Input()
  middleOption: any;

  @Input()
  rightOption: any = { label: 'No', value: 'no' };

  @Input()
  selectedValue: string = '';

  @Input()
  disabled = false;

  @Output()
  change = new EventEmitter<string>();

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  /* writeValue(value: string): void {
    this.selectedValue = value;
  } */

  writeValue(obj: string): void {
    if (obj == null || obj == undefined) return;

    if (obj.toString() === 'false') {
      this.selectedValue = 'false';
      return;
    }

    this.selectedValue = (obj || '') && obj + '';
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange(value: any) {
    this.writeValue(value);
    this.propagateChange(this.selectedValue);
    this.propagateTouch();
    this.change.emit(value);
  }

  constructor() {}

  ngOnInit() {

  }

  onOptionSelected(option: any) {
    if (!this.disabled) {
      if (this.selectedValue == option.value) {
        this.selectedValue = '';
      } else {
        this.selectedValue = option.value;
      }

      this.onChange(this.selectedValue);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'bxl-client-calendar',
  templateUrl: 'client-calendar.component.html',
  styleUrls: ['client-calendar.component.scss'],
})
export class ClientCalendarComponent implements OnInit {
  initialized = false;
  clientUserId: number;
  isSelf: boolean = false;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router, private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Calendar', this.router.url, []);
    this.auth.fetchUserProfile().subscribe((user) => {
      this.isSelf = user.id == Number(this.clientUserId);
      this.initialized = true;
    });
  }
}

<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Add General Journal Entry</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <h5>{{ today | date }}</h5>
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div  class="col-12">
            </div>
            <div  class="col-12">
              <div class="form-group">
                <label class="form-control-label" for="note">
                  Notes
                </label>
                <textarea class="form-control" formControlName="note" type="text" rows="5"></textarea>
                <div class="invalid-message">
                  Note is required
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


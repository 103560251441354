<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Share Exercise with Athlete</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-md-12 mb-2">
              This exercise will be available to the athlete in their “My Library” tab. 
              <br/>If you wish to remove it, you can do so from there.
            </div>
          </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="exerciseName">
                      Exercise:
                    </label>
                    {{exerciseName}}
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="clientUserId">
                      Athlete
                    </label>
                    <runners-dropdown formControlName="clientUserId"></runners-dropdown>
                    <div class="invalid-message">
                      <div>Athlete is required</div>
                    </div>
                  </div>
                </div>
              </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button form="form" type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Share Exercise</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


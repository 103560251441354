import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamProgramListViewModel } from 'src/app/_models/generatedModels';
import { Router } from '@angular/router';
import { TeamService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';


@Component({
  selector: 'team-program-details',
  templateUrl: 'team-program-details.component.html',
  styleUrls: ['team-program-details.component.scss']
})
export class TeamProgramDetailsComponent implements OnInit {

  currentProgramId: number = 0;
  programs: TeamProgramListViewModel[];
  initialized: boolean;

  @Input()
  teamId: number;

  @Input()
  isCoachView: boolean = false;

  @Output()
  changed = new EventEmitter<boolean>();


  constructor(private router: Router, private teamService: TeamService, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.teamService.getCurrentProgramsForTeam(this.teamId).subscribe(result => {
      this.programs = result;
      this.initialized = true;
    });
  }

  onShowDetails(programId: number) {
    if (this.currentProgramId === programId) {
      this.currentProgramId = 0;
    } else {
      this.currentProgramId = programId;
    }
  }

  deleteProgram(teamProgramId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this program and all the related future workouts from the team AND athletes\' calendars? This action cannot be undone.', 'Delete Program', 'Delete Program', 'Cancel').subscribe(result => {
      if (result) {
        this.teamService.deleteTeamProgram(this.teamId, teamProgramId).subscribe(results => {
          this.programs.remove(x => x.id === teamProgramId);
          this.toastr.success('Program deleted', 'Success');
          this.changed.emit(true);
        });
      }
    });
  }
}

import { Injectable, ViewContainerRef, TemplateRef } from "@angular/core";
import { ToastrService, Toast, ActiveToast } from 'ngx-toastr';
import { DialogService, DialogRef, DialogCloseResult, DialogAction } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../_components/confirm-modal/confirm-modal.component';

@Injectable()
export class ToasterService {
    //public RootPageVC: ViewContainerRef | undefined;

  constructor(public toastr: ToastrService, private dialogService: DialogService, private modalService: NgbModal) {

    }

    init(vc: ViewContainerRef): void {
      //  this.toastr.setRootViewContainerRef(vc);
    }

  error(message: string, title?: string, options?: any) {
        this.toastr.error(message, title, options);
    }

    info(message: string, title?: string, options?: any) {
        this.toastr.info(message, title, options);
    }

    success(message: string, title?: string, options?: any) {
        this.toastr.success(message, title, options);
    }

    warning(message: string, title?: string, options?: any) {
         this.toastr.warning(message, title, options);
    }

    //custom(message: string, title?: string, options?: any) {
    //     this.toastr.custom(message, title, options);
    //}

    confirmDialog(message: string, title: string, primaryActionText: string | null = null, secondaryActionText: string | null = null, height: number | null = null): Observable<boolean>  {
      // Kendo doesn't support adding additional classes to your dialog if generating it based on the service,
      // so we need to make the dialog not 'primary' and then target the last non-primary button for styling.
      let denyAction: DialogAction = { text: secondaryActionText || 'No' };
      let approveAction: DialogAction = { text: primaryActionText || 'Yes', primary: true};

      const dialog: DialogRef = this.dialogService.open({
          title: title,
          content: message,
          actions: [
              denyAction,
              approveAction
          ],
          width: 375,
          height: height || 250,
          minWidth: 250
      });

      return dialog.result.pipe(map((result: any) => {
        if (result instanceof DialogCloseResult) {
          return false;
        } else {
          return result.primary || result.text === approveAction.text;
        }
      }));
  }

  okDialog(message: string, title: string): Observable<boolean> {
      let actions = [{ text: 'OK', primary: true }];

      const dialog: DialogRef = this.dialogService.open({
          title: title,
          content: message,
          actions: actions,
          width: 375,
          minWidth: 250
      });

      return dialog.result.pipe(map((result: any) => {
        if (result instanceof DialogCloseResult) {
          return false;
        } else {
          return result.primary;
        }
      }));
  }

  confirmModal(message: string, title: string, ok: string = 'Yes', cancel: string = 'No'): Observable<boolean> {
    let x = new Observable<boolean>((observer) => {
      const modalRef = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true });
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.ok = ok;
      modalRef.componentInstance.cancel = cancel;
      modalRef.componentInstance.singleButton = false;
​
      modalRef.result.then(
        (result) => {
          observer.next(true);
          observer.complete();
        },
        (reason) => {
          observer.next(false);
          observer.complete();
        }
      );
    });
    return x;
  }
​
  okModal(message: string, title: string, ok: string = 'Ok'): Observable<boolean> {
    let x = new Observable<boolean>((observer) => {
      const modalRef = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true });
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.ok = ok;
      modalRef.componentInstance.singleButton = true;
      modalRef.componentInstance.showSpinner = false;
​
      modalRef.result.then(
        (result) => {
          observer.next(true);
          observer.complete();
        },
        (reason) => {
          observer.next(false);
          observer.complete();
        }
      );
    });
    return x;
  }

}

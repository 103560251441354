<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="programs.length === 0" class="container mt-3 mb-2">No programs assigned</div>
  <div  *ngFor="let program of programs" class="container mt-3">
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="isCoachView">
              <a style="vertical-align: text-bottom;" href="javascript:void(0)" (click)="deleteProgram(program.id)">
                <i class="mr-1 fas fa-trash"> </i>
              </a>
              <h5 class="d-inline">{{program.name}}</h5>
            </ng-container>

            <a *ngIf="!isCoachView" class="main-toggle" (click)="onShowDetails(program.id)" href="javascript:void(0)">
              <i class="fas mr-1" [ngClass]="{ 'fa-minus-circle': program.id === currentProgramId, 'fa-plus-circle' : program.id !== currentProgramId }"></i>
              <h5 class="d-inline">{{program.name}}</h5>
            </a>
          </div>
          <div class="col-md-12">starting {{program.startDate | date}}
          </div>
          <div class="col-md-12 mt-1">
            <ngb-progressbar class="dark-text" type="primary" height="15px" [value]="program.expectedWorkoutProgressPercentage">Expected: {{program.expectedWorkoutProgressPercentage}}%</ngb-progressbar>
          </div>

          <div class="col-md-12" [hidden]="program.id !== currentProgramId">
            <div *ngIf="program.videoLink">
              <embed-video-player [url]="program.videoLink" [size]="'large'"></embed-video-player>
            </div>
            <div *ngIf="program.videoId">
              <embed-video-player [videoId]="program.videoId" [size]="'large'"></embed-video-player>
            </div>
            <div class="font-weight-bold">Description:</div>
            <span [editorHTML]="program.description"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


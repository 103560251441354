<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized && laps && laps.length > 0">
  <div class="row">
    <div class="col-md-6">
      <div class="table-responsive">
        <table class="table align-items-center table-flush">
          <thead class="thead-light">
            <tr>
              <th>Lap</th>
              <th>Distance</th>
              <th>Time</th>
              <th>{{ showSpeedInsteadOfPace ? 'Speed' : 'Pace' }}</th>
            </tr>
          </thead>

          <tbody class="list">
            <tr *ngFor="let lap of laps">
              <td class="">
                {{ lap.lapNumber }}
              </td>
              <td class="">
                {{ lap.distance | number: '1.2-2' }} {{ localMeasurementName }}
              </td>
              <td class="">
                {{ lap.duration | secsToHourMinSec }}
              </td>
              <td *ngIf="!showSpeedInsteadOfPace" class="">
                {{ lap.pace | secsToMinSecOnly }} min/{{ localMeasurementName }}
              </td>
              <td *ngIf="showSpeedInsteadOfPace" class="">
                {{ lap.speed | number: '1.2-2' }} {{ localMeasurementName }}/hr
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

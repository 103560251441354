import { Component, OnInit } from '@angular/core';
import { ClientsService, OrganizationService, UserService } from 'src/app/_services/generatedServices';
import { ClientSearchParams, ClientSummaryDTO, StringViewModel, UserRole, UpdateUserRoleViewModel, ClientStatus, MarketplaceStatus, AthleteType } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { coachUserRole } from 'src/app/_models/models';
import { Router } from '@angular/router';
import { MaxAthleteSubscriptionService } from 'src/app/_services/maxAthleteSubscription.service';

@Component({
  selector: 'bxl-clients',
  templateUrl: 'clients.component.html',
})
export class ClientsComponent implements OnInit {
  initialized = false;
  clients: ServerSortableList<ClientSummaryDTO>;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  status = 1;
  athleteType = 1;
  currentUserId: number;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  runnerCount: number;
  clientStatus = ClientStatus;
  showAthleteTypeFilter: boolean = false;

  constructor(private clientsService: ClientsService, private breadcrumbs: BreadcrumbsService, private toastr: ToasterService, private userService: UserService,
    private auth: AuthenticationService, private orgService: OrganizationService, private router: Router, private maxAthleteService: MaxAthleteSubscriptionService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('List', 'list', []);
    this.auth.fetchUserProfile().subscribe(user => {
      this.currentUserId = user.id;
      this.clients = new ServerSortableList<ClientSummaryDTO>([], 'Credential.FullName', false);

      this.showAthleteTypeFilter = user.organizationMarketplaceStatus == MarketplaceStatus.Active;
      if (!this.showAthleteTypeFilter) {
        this.orgService.doesOrganizationHaveMarketplaceUsers().subscribe(result => {
          this.showAthleteTypeFilter = result;
        });
      }

      this.clients.sortChanged.subscribe(result => {
        this.currentPage = 1;
        this.searchClients();
      });

      this.updateAthleteCount();
      this.searchClients();
    });

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchClients();
    });
  }

  searchClients() {
    this.searching = true;
    let model = new ClientSearchParams();
    model.searchText = this.filter;
    model.status = <any>this.status;
    model.teamId = null;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.clients.column;
    model.isSortDesc = this.clients.isDesc;
    model.athleteType = <any>this.athleteType;

    this.clientsService.searchClients(this.currentUserId, model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.clients.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  updateAthleteCount() {
    this.orgService.getOrganizationBillingDetails().subscribe(org => {
      this.runnerCount = org.runnerCount;
    });
  }

  onStatusChanged(query: string) {
    this.currentPage = 1;
    this.searchClients();
  }

  onTypeChanged(query: string) {
    this.currentPage = 1;
    this.searchClients();
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  pageChanged(event: any): void {
    this.searchClients();
  }

  onAddAthlete() {
    this.isMaxActiveAthletes().subscribe(isMax => {
      if (!isMax) {
        this.router.navigate(['/athletes/add']);
      }
    });
  }

  isMaxActiveAthletes(): Observable<boolean> {
    return this.maxAthleteService.isMaxActiveAthletes(false).pipe(map(isMax => {
      return isMax;
    }));
  }

  onSendPasswordReset(userId: number, email: string, name: string) {
    this.toastr.confirmDialog('Are you sure you want to send a password reset email to ' + name + ' (' + email + ')?', 'Send Password Reset').subscribe((result) => {
      if (result) {
        const model = new StringViewModel();
        model.value = email;
        this.userService.initiatePasswordReset(model).subscribe((results) => {
          this.toastr.success('Password Reset email sent', 'Success');
        });
      }
    });
  }

  onSendInvitation(userId: number, email: string, name: string) {
    this.toastr.confirmDialog('Are you sure you want to re-send an invitation to ' + name + ' (' + email + ')?', 'Re-send Invitation').subscribe((result) => {
      if (result) {
        this.clientsService.resendRunnerWelcomeEmail(userId).subscribe((results) => {
          this.toastr.success('Invitation email sent', 'Success');
        });
      }
    });
  }

  deactivate(userId: number, userRole: UserRole, athleteType: AthleteType) {
    // if athlete is also admin/coach
    if ((userRole & coachUserRole) != 0) {
      this.toastr.confirmDialog('This athlete cannot be deactivated here because they are also a coach. Would you like to instead remove the Athlete role from this account?', 'Deactivate User').subscribe((result) => {
        if (result) {
          const model: UpdateUserRoleViewModel = {
            userRole: (userRole & ~UserRole.Runner)
          }
          this.userService.updateUserRole(userId, model).subscribe(result => {
            this.toastr.success('User Updated', 'Success');
            this.updateAthleteCount();
            this.searchClients();
          });
        }
      });
    } else if ((athleteType & AthleteType.Marketplace) != 0) {
      var coachedAthleteText = '';
      if ((athleteType & AthleteType.Coached) != 0) {
        coachedAthleteText = 'To remove them as a Coached athlete, go to their Profile and uncheck the Coached Athlete checkbox.';
      }
      this.toastr.okDialog('This athlete cannot be deactivated because they purchased a program that grants them access for 12 months (Marketplace athlete). ' + coachedAthleteText, 'Deactivate Athlete').subscribe(result => {

      });

    } else {
      this.toastr.confirmModal('Are you sure you want to deactivate this athlete? <p class="text-danger font-weight-400">All scheduled calendar data for future dates for this athlete will be permanently deleted. Historical calendar data will remain and can be accessed again by re-activating the athlete.</p>', 'Deactivate Athlete', 'Deactivate Athlete', 'Cancel').subscribe(result => {
        if (result) {
          this.clientsService.deactivateClient(userId).subscribe((result) => {
            this.toastr.success('Successfully Deactivated Athlete', 'Success');
            this.updateAthleteCount();
            this.searchClients();
          });
        }
      });
    }
  }

  deleteAthlete(userId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this athlete? This action cannot be undone.', 'Delete Athlete', 'Delete Athlete', 'Cancel').subscribe(result => {
      if (result) {
        this.userService.deleteInvitedUser(userId).subscribe((result) => {
          this.toastr.success('Successfully Deleted Athlete', 'Success');
          this.updateAthleteCount();
          this.searchClients();
        });
      }
    });
  }

  viewEmailAddress(fullName: string, email: string) {
    this.toastr.okDialog('Email Address: ' + email, fullName);
  }

  activate(userId: number) {
    this.isMaxActiveAthletes().subscribe(isMax => {
      if (!isMax) {
        this.clientsService.activateClient(userId, true).subscribe((result) => {
          this.toastr.success('Successfully Activated Athlete', 'Success');
          this.updateAthleteCount();
          this.searchClients();
        });
      }
    });
  }
}

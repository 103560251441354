<page-load-header *ngIf="!initialized"></page-load-header>

<h3 class="mt-2">Marketplace</h3>

<ul ngbNav #tabNav="ngbNav" [destroyOnHide]="false" class="nav-pills my-2">
  <li [ngbNavItem]="0">
    <a ngbNavLink>Settings</a>
    <ng-template ngbNavContent>
      <div *ngIf="initialized" class="card">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col">
              <h3 class="mb-0">Marketplace Settings</h3>
            </div>
          </div>
      
          <alert *ngIf="!org.activeSubscription" class="text-center" type="danger" [dismissible]="false">
            <div>
              <span class="font-weight-bold">You must have a paid subscription to access Marketplace.</span>
            </div>
          </alert>
      
          <alert *ngIf="currentMarketplaceStatus == marketplaceStatus.PendingStripeSetup" class="text-center" type="warning" [dismissible]="false">
            <div>
              <span class="font-weight-bold">To use Marketplace, Finish setting up your Stripe account by clicking button at the bottom of screen.</span>
            </div>
          </alert>
          <alert *ngIf="currentMarketplaceStatus == marketplaceStatus.Active && isStripeReturn" class="text-center" type="info" [dismissible]="false">
            <div>
              <span class="font-weight-bold">Congrats, your account is set up! The next step will be to create your first program, and/or update existing programs to mark them as paid programs. Please view the marketplace tutorials for additional guidance.</span>
            </div>
          </alert>
      
          <div class="row mt-3">
            <div class="col-12">
              <p>Marketplace allows you to sell your programs to individual athletes and automate the delivery of the programs by utilizing the RunDNA web portal. 
                Users who buy your program will have 1 year access to the program and the web portal. 
                After signing up for RunDNA Marketplace, you will be guided on how to create a Stripe account to receive payment for the programs you sell, 
                minus a {{transactionFee | number: '1.0-0'}}% transaction fee to cover development and customer support costs of the web portal.</p>
              <p>You can find additional information and tutorials <a href="https://rundnahelp.zendesk.com/hc/en-us/articles/16202490772503-RunDNA-Marketplace" target="_blank">here</a>.</p>
            </div>
          </div>
      
          <form *ngIf="org.activeSubscription" [hidden]="stripeLink" class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label mr-1" for="marketplaceStatus">
                    Opt-in to Program Marketplace <sup>*</sup>
                  </label>
                  <kendo-switch class="d-flex" formControlName="marketplaceStatus" (valueChange)="onMarketplaceStatusChange()"></kendo-switch>
                  <div><sup>*</sup> By opting-in, you agree to the <a href="javascript:void(0)" (click)="onViewTerms()">Terms and Conditions</a></div>
                </div>
                <ng-container *ngIf="formGroup.get('marketplaceStatus').value == true">
                  <div class="form-group">
                    <label class="form-control-label" for="marketplaceURLSlug">Marketplace URL</label>
                    <small class="">   (cannot be changed once it's set)</small>
                    <div *ngIf="currentMarketplaceStatus != marketplaceStatus.Active" class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">https://app.rundna.com/marketplace/</span>
                      </div>
                      <input style="text-transform: lowercase;" type="text" class="form-control" placeholder="your-organization-name" formControlName="marketplaceURLSlug" maxlength="100" />
                      <div *ngIf="formGroup.controls['marketplaceURLSlug'].dirty && formGroup.controls['marketplaceURLSlug'].errors?.required" class="invalid-message-visible">
                        <div>Marketplace URL is required</div>
                      </div>
                      <div *ngIf="formGroup.controls['marketplaceURLSlug'].dirty && formGroup.controls['marketplaceURLSlug'].errors?.nameTaken" class="invalid-message-visible">
                        <div>This name is already taken.</div>
                      </div>
                      <div *ngIf="formGroup.controls['marketplaceURLSlug'].dirty && formGroup.controls['marketplaceURLSlug'].errors?.invalidFormatRunDNA" class="invalid-message-visible">
                        <div>Cannot contain string "rundna"</div>
                      </div>
                      <div *ngIf="formGroup.controls['marketplaceURLSlug'].dirty && formGroup.controls['marketplaceURLSlug'].errors?.invalidFormat" class="invalid-message-visible">
                        <div>Must be at least 5 characters. Can only include letters, numbers, and dashes (no spaces or special characters)</div>
                      </div>
                    </div>
                    <div *ngIf="currentMarketplaceStatus == marketplaceStatus.Active">
                      <a href="{{orgMarketplaceURL}}" target="_blank">{{orgMarketplaceURL}}</a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label" for="marketplaceCategoryDisplayType">Marketplace Category Display</label>
                    <small class="">   (select 1 or more)</small>
                    <flag-enum-button-control class="d-block" formControlName="marketplaceCategoryDisplayType" [isRequired]="true" [enumValues]="marketplaceCategoryDisplayType" [enumAsMap]="marketplaceCategoryDisplayTypeEnum"></flag-enum-button-control>
                    <div class="invalid-message">
                      <div>At least 1 selection is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label" for="marketplaceCategoryDisplayType">Marketplace Transaction Fee</label>
                    <div class="d-inline text-center" kendoTooltip>
                      <i class="fas fa-info-circle ml-1" title="The percentage fee that RunDNA keeps from each program sale to cover development and customer support costs of the web portal. Please price your programs accordingly, to accommodate for the transaction fee."></i>
                    </div>
                    <div>
                      {{transactionFee | number: '1.0-0'}}%
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label" for="organizationURL">Organization Website URL</label>
                    <small class="">   (your logo will link to this)</small>
                    <input type="text" class="form-control" formControlName="organizationURL" maxlength="100" />
                    <div class="invalid-message">
                      <div>Organization URL is required and must start with www. or http:// or https://</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label" for="marketplaceDescription">Marketplace Description</label>
                    <textarea class="form-control" rows="6" formControlName="marketplaceDescription" maxlength="1000" type="text"></textarea>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-6">
                <ng-container *ngIf="formGroup.get('marketplaceStatus').value">
                <label class="form-control-label mt-3" for="marketplaceLogo">Organization Logo for Marketplace</label>
                <div class="mb-1">
                  Upload a 1006x268px image of your organization's logo; displayed at the top of the marketplace web page. (Logo will be shown on a white background.)
                  <a class="d-block" href="https://rundnahelp.zendesk.com/hc/en-us/articles/16538198038935-RunDNA-Marketplace-Logo-Sizing-Instructions" target="_blank">Logo sizing instructions </a>
                </div>
                <div>
                  <azure-file-upload #uploader formControlName="marketplaceLogo" src="/fetchimage?fileName={{ formGroup.controls.marketplaceLogo.value }}" [useDarkBackground]="false" [requiredDimensions]="'1006x268'" [immediateUpload]="true" name="logo"></azure-file-upload>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['marketplaceLogo'].errors && formGroup?.controls['marketplaceLogo'].errors.invalidDimensions">
                  <div>Invalid Dimensions</div>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['marketplaceLogo'].errors && formGroup?.controls['marketplaceLogo'].errors.invalidFileSize">
                  <div>Invalid File Size - must be less than 200KB</div>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['marketplaceLogo'].errors && formGroup?.controls['marketplaceLogo'].errors.invalidFileType">
                  <div>Invalid File Type</div>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['marketplaceLogo'].errors && formGroup?.controls['marketplaceLogo'].errors.required">
                  <div>Marketplace Logo is required</div>
                </div>
                </ng-container>
                <ng-container *ngIf="currentMarketplaceStatus == marketplaceStatus.Active">
                  <div *ngIf="stripeBalanceInfo">
                    <div class="row mt-5 mb-2">
                      <div class="col-12 text-center">
                        <h5>Stripe Payment Info</h5>
                      </div>
                      <div class="col-lg-4 col-md-6 text-center">
                        <p><a target="_blank" href="{{ stripeDashboard }}">View Stripe Account</a></p>
                      </div>
                      <div class="col-lg-4 col-md-6 text-center">
                        Your Balance:
                        <h3 class="mb-0">{{ stripeBalanceInfo.pending + stripeBalanceInfo.available | currency }}</h3>
                        <p class="text-muted">{{ stripeBalanceInfo.available | currency }} Available</p>
                      </div>
                      <div class="col-lg-4 col-md-6 text-center">
                        <button [promiseBtn]="downloadComplete" (click)="downloadPurchaseHistory()" class="btn btn-primary">Download Transaction History</button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-6">
                <div class="row form-btns">
                  <div class="col">
                    <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
                  </div>
                  <div class="col text-right">
                    <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">{{(currentMarketplaceStatus != marketplaceStatus.Active && formGroup.get('marketplaceStatus').value) ? 'Save and Create Stripe Account' : 'Save'}}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div *ngIf="stripeLink" class="col text-center">
            <a class="btn btn-primary" href="{{stripeLink}}">Create Stripe Connected Account</a>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="1">
    <a ngbNavLink>Promo Codes</a>
    <ng-template ngbNavContent>
      <div *ngIf="initialized && initializedPromoCode && org.activeSubscription && currentMarketplaceStatus == marketplaceStatus.Active" class="card">
        <div class="card-body">
          <form class="form" (ngSubmit)="onPromoCodeSave()" [formGroup]="promoCodeFormGroup" novalidate>
            <div class="row mt-4">
              <div class="col-md-12 mb-2">
                <div class="row form-btns">
                  <div class="col text-right">
                    <button type="submit" [promiseBtn]="submitPromoComplete" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <a href="javascript:void(0)" (click)="initializePromoCode()">Add Promo Code</a>
              </div>
              <div class="col-12">
                <ng-container formArrayName="promoCodes">
                  <div *ngFor="let promoCode of promoCodeFormGroup.get('promoCodes').controls; index as i">
                    <ng-container [formGroupName]="i">
                      <div class="card">
                        <div class="row card-body">
                          <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                              <label class="form-control-label" for="code">Promo Code</label>
                              <input type="text" class="form-control" formControlName="code" maxlength="50" />
                              <div class="invalid-message">
                                <div>Promo Code is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-2">
                            <div class="form-group">
                              <label class="form-control-label" for="code">Percentage Discount</label>
                              <kendo-numerictextbox noScroll placeholder="" class="form-control small-numeric d-block" formControlName="percentageDiscount" [decimals]="'0'" [format]="'n0'" min="1" max="99" type="number"></kendo-numerictextbox>
                              <div class="invalid-message">
                                <div>Percentage Discount is required and must be between 1 and 99</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 col-lg-3">
                            <div class="form-group">
                              <label class="form-control-label" for="startDate">Start Date/Time ({{timezone}})</label>
                              <kendo-datetimepicker class="form-control" [format]="dateTimeFormat" formControlName="startDate"></kendo-datetimepicker>
                              <div class="invalid-message">
                                <div>Start Date is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 col-lg-3">
                            <div class="form-group">
                              <label class="form-control-label" for="endDate">End Date/Time ({{timezone}})</label>
                              <kendo-datetimepicker class="form-control" [format]="dateTimeFormat" formControlName="endDate"></kendo-datetimepicker>
                              <div class="invalid-message">
                                <div>End Date is required</div>
                              </div>
                              <div *ngIf="promoCode.errors && promoCode.errors.invalidDateRange" class="invalid-message-visible">
                                End Date must be after Start Date
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1">
                            <label class="form-control-label">&nbsp;</label>
                            <a class="text-left d-block" href="javascript:void(0)" (click)="onDeletePromoCode(i)"><i class="fa fa-trash trash-icon"></i></a>
                          </div>
                          <div class="col-md-12 col-lg-6">
                            <div class="form-group">
                              <label class="form-control-label" for="programKeywords"> Limit to Program Keywords </label>
                              <small class="">   (optional)</small>
                              <div class="d-inline text-center" kendoTooltip>
                                <i class="fas fa-info-circle ml-1"
                                  title="If you want to limit the promo code to only be valid for programs with certain keywords, select one or more keywords below. Leave blank to allow the promo code to apply to all programs."></i>
                              </div>
                              <kendo-multiselect [valuePrimitive]="true" [textField]="'name'" [valueField]="'id'" [data]="allProgramKeywords" formControlName="programKeywords"></kendo-multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="tabNav"></div>



import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'training-volume-list',
  templateUrl: 'training-volume-list.component.html'
})
export class TrainingVolumeListComponent implements OnInit {

  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number = 30;
  initialized: boolean;

  @Input()
  clientUserId: number;


  constructor(private router: Router, private clientService: ClientsService, private toastr: ToasterService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.initialized = true;
  }

  pageChanged(event: any): void {

  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="d-flex flex-wrap mt-2">
    <div class="flex-even mt-2" *ngFor="let day of week.programDays">
      <div class="font-weight-bold mb-1 text-center">Day {{day.weekDayNumber}}</div>
      <div>
        <div class="custom-event-wrapper" *ngFor="let event of day.workoutDays">
          <ng-container>
            <div class="custom-event">
              <div class="event-activity-type">
                <i class="far {{ (event.workout ? event.workout.singleActivityType : (event.eventType == eventType.QuickWorkout ? event.quickWorkoutActivityType : null)) | customCalendarEventIcon: event.eventType:(event.workout ? event.workout.workoutType : null) }}"></i>
                <span *ngIf="event.workout && event.workout.expectedTime != null">{{ event.workout.expectedTime | secsToHourMinOnly }}</span>
                <span *ngIf="event.workout && (event.workout.expectedMiles || event.workout.expectedKilometers)">{{ ((event.workout.expectedMiles | milesKilometersToMiles: event.workout.expectedKilometers) | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                <span *ngIf="event.eventType == eventType.QuickWorkout && event.quickWorkoutDuration != null">{{ event.quickWorkoutDuration | secsToHourMinOnly }}</span>
                <span *ngIf="event.eventType == eventType.QuickWorkout && event.quickWorkoutDistance">{{ (event.quickWorkoutDistance | fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
              </div>
              <div>
                <span class="event-title">{{ event.workoutName }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="totals flex-even mt-2" style="word-wrap: break-word">
      <div class="font-weight-bold mb-1 text-center">
        Week Totals
      </div>
      <div class="activity-type-list">
        <div *ngFor="let type of week.activityTypeDurations; let t = index" class="activity-type pb-2">
          <i class="far {{ type.activityType | customCalendarEventIcon: null:null }}"></i>
          <span>{{ type.duration | secsToHourMinOnly }}</span>
          <span>{{ (type.distance | fromMilesToLocalMeasurement) | number: '1.2-2' }}{{localMeasurementName}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportsService } from 'src/app/_services/generatedServices';
import { CoachDashboardViewModel, DynamicReportFilterViewModel, TrainingVolumeReportViewModel } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

// core components
@Component({
  selector: 'app-alternative',
  templateUrl: 'coaches-dashboard.component.html',
  styleUrls: ['coaches-dashboard.component.scss']
})
export class CoachesDashboardComponent implements OnInit, OnDestroy {
  data: CoachDashboardViewModel;
  currentFilterValue: DynamicReportFilterViewModel;
  initialized: boolean;
  trainingVolumeInitialized: boolean;
  trainingVolumeData: TrainingVolumeReportViewModel;
  athleteUrl: string = '/athletes/details';

  constructor(private reportsService: ReportsService, private breadcrumbs: BreadcrumbsService) {}

  ngOnInit() {
    this.breadcrumbs.SetBreadcrumb('Coaches Dashboard', '/dashboard', []);
  }

  onCoachFilterChanged(value: number | null) {
    this.initialized = false;
    this.currentFilterValue = new DynamicReportFilterViewModel();
    this.currentFilterValue.coachId = value;

    this.reportsService.getCoachDashboardData(this.currentFilterValue).subscribe(results => {
      this.data = results;
      this.initialized = true;
    });

    this.reportsService.getTrainingVolumeReport(this.currentFilterValue).subscribe(results => {
      this.trainingVolumeData = results;
      this.trainingVolumeInitialized = true;
    })
  }

  ngOnDestroy() {}
}

import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AFFitnessAssessmentCardioExerciseType, AFFitnessAssessmentMuscular1ExerciseType, AFFitnessAssessmentMuscular2ExerciseType, AFFitnessAssessmentViewModel, UserProfileViewModel } from 'src/app/_models/generatedModels';
import { AssessmentService, UserService } from 'src/app/_services/generatedServices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { dateAsUTCNoTime, dateWithoutTime, getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ExerciseToolTipModalComponent } from '../../coaches-admin/Clients/assessments/exercise-tool-tip-modal.component';
import moment from 'moment-timezone';
import { Enums } from 'src/app/_models/generatedEnums';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';

@Component({
  selector: 'bxl-add-air-force-fitness',
  templateUrl: 'add-air-force-fitness.component.html',
  styleUrls: ['add-air-force-fitness.component.scss']
})
export class AddAirForceFitnessComponent extends FormCanDeactivate implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  clientUserId: number;
  userProfile: UserProfileViewModel;
  healthRiskCategoryEnum = Enums.AFHealthRiskCategoryEnum;
  compositeScoreCategoryEnum = Enums.AFCompositeScoreCategoryEnum;
  trueOption: any = { label: 'Yes', value: 'true' };
  falseOption: any = { label: 'No', value: 'false' };
  isCoachView: boolean = false;

  cardioExerciseTypeEnum = Enums.AFFitnessAssessmentCardioExerciseTypeEnum;
  cardioExerciseType = AFFitnessAssessmentCardioExerciseType;
  muscular1ExerciseTypeEnum = Enums.AFFitnessAssessmentMuscular1ExerciseTypeEnum;
  muscular1ExerciseType = AFFitnessAssessmentMuscular1ExerciseType;
  muscular2ExerciseTypeEnum = Enums.AFFitnessAssessmentMuscular2ExerciseTypeEnum;
  muscular2ExerciseType = AFFitnessAssessmentMuscular2ExerciseType;


  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToasterService, private modalService: NgbModal, private assessmentService: AssessmentService,
    private fb: FormBuilder, private breadcrumbs: BreadcrumbsService, private userService: UserService, private auth: AuthenticationService) {
      super();
    }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Add Fitness Test', this.router.url, []);
    this.clientUserId = getParamFromRoute(this.route, 'id');

    // clientUserId will be null if coming from athlete side instead of coach side
    if (!this.clientUserId) {
      this.isCoachView = false;
      this.auth.fetchUserProfile().subscribe(user => {
        this.clientUserId = user.id;
        this.getUserProfile();
      });
    } else {
      this.isCoachView = true;
       this.getUserProfile();
    }
  }

  getUserProfile() {
    this.userService.getProfileById(this.clientUserId).subscribe(result => {
      this.userProfile = result;
      this.setupForm();
    });
  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      age: [null, Validators.required],
      gender: [this.userProfile.gender, Validators.required],
      dateTime: [dateWithoutTime(new Date()), Validators.required],
      healthRiskCategory: [null, Validators.required],
      compositeScoreCategory: [null, Validators.required],
      compositePoints: [null, Validators.required],
      cardioExerciseType: [null, Validators.required],
      muscular1ExerciseType: [null, Validators.required],
      muscular2ExerciseType: [null, Validators.required],
      cardioValue: [null, Validators.required],
      muscular1Value: [null, Validators.required],
      muscular2Value: [null, Validators.required],
      cardioMinimumMet: [null, Validators.required],
      muscular1MinimumMet: [null, Validators.required],
      muscular2MinimumMet: [null, Validators.required],
      cardioPoints: [null, Validators.required],
      muscular1Points: [null, Validators.required],
      muscular2Points: [null, Validators.required],
      notes: [null]
    });

    this.formGroup.markFormDirtyOnValueChange().subscribe();
    this.initialized = true;
  }

  onCardioExerciseChanged() {
    this.formGroup.get('cardioValue').setValue(null);
  }

  onMuscular1ExerciseChanged() {
    this.formGroup.get('muscular1Value').setValue(null);
  }

  onMuscular2ExerciseChanged() {
    this.formGroup.get('muscular2Value').setValue(null);
  }

  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: AFFitnessAssessmentViewModel = Object.assign({}, this.formGroup.value, this.formGroup.value);
      formData.dateTime = dateAsUTCNoTime(formData.dateTime);
      this.save(formData, resetButton);
    });
  }

  showHelp(key: string) {
    const modalRef = this.modalService.open(ExerciseToolTipModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.key = key;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  save(formData: AFFitnessAssessmentViewModel, resetButton: () => any) {
    this.assessmentService.createAFFitnessAssessment(this.clientUserId, formData).subscribe(
      (assessment) => {
        this.toastr.success('Fitness Test Saved', 'Success');
        this.navigateBack();
        resetButton();
      },
      (error) => resetButton()
    );
  }

  onPointsChange(event: any) {
    const totalPoints = (this.formGroup.get('cardioPoints').value ?? 0) + (this.formGroup.get('muscular1Points').value ?? 0) + (this.formGroup.get('muscular2Points').value ?? 0);
    this.formGroup.get('compositePoints').setValue(totalPoints.toFixed(1));
  }

  navigateBack() {
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }
}

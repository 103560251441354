import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bxl-runner-workout-modal',
  templateUrl: 'runner-workout-modal.component.html',
})
export class RunnerWorkoutModalComponent implements OnInit {
  initialized = false;

  @Input()
  clientUserId: number;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  isCoachView: boolean;

  @Output()
  syncedWorkoutNameUpdated = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initialized = true;
  }

  workoutSaved() {
    this.activeModal.dismiss('saved');
  }

  onSyncedWorkoutNameUpdated() {
    this.syncedWorkoutNameUpdated.emit(true);
  }
}

import { Component, Input, OnDestroy, OnChanges} from '@angular/core';
import moment from 'moment-timezone';
import { dateWithoutTime } from 'src/app/_helpers/extensions.module';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { ReportsService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'intensity-rpe-range-chart',
  templateUrl: 'intensity-rpe-range-chart.component.html',
})
export class IntensityRPERangeChartComponent implements OnChanges, OnDestroy {
  initialized = false;
  hasData: boolean = false;

  @Input()
  clientUserId: number;

  @Input()
  page: number;


  constructor(private charting: ChartingService, private reportsService: ReportsService) {}

  ngOnChanges(): void {
    const today = dateWithoutTime(new Date());
    this.reportsService.getRPEIntensityTrainingVolumeChart(this.clientUserId, this.page, today).subscribe(result => {
      this.hasData = result.chartData.length > 0;
      var chartHeaders: any[] = [];
      result.chartHeaders.forEach(header => {
        if (header == 'interval') {
          chartHeaders.push({ role: 'interval' });
        } else if (header == 'annotation') {
          chartHeaders.push({ role: 'annotation' });
        } else {
          chartHeaders.push(header);
        }
      });

      this.charting.queueCharts(
        this.charting.drawStackedColumnChart.bind(this.charting),
        new ChartData(
          'intensityRPEChart',
          chartHeaders,
          result.chartData,
          'RPE Range',
          'intensityRPE'
        )
      );
      this.initialized = true;
    });
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

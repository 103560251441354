import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyProgramAssignmentViewModel, SurveyResultViewModelRead, SurveySummaryViewModelRead } from 'src/app/_models/generatedModels';
import { ProgramSummaryViewModelReadExtended } from 'src/app/_models/models';
import { SurveyService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Enums } from 'src/app/_models/generatedEnums';
import { dateAsUTCNoTime, dynamicSort } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'survey-results',
  templateUrl: 'survey-results.component.html',
  styleUrls: ['survey-results.component.scss']
})
export class SurveyResultsComponent implements OnInit {
  initialized = false;
  submitComplete: Promise<{}> | undefined;
  survey: SurveySummaryViewModelRead;
  surveyResultPrograms: ProgramSummaryViewModelReadExtended[];
  programCategoryEnum = Enums.ProgramCategoryEnum;

  @Input()
  surveyId: number;

  @Input()
  surveyResult: SurveyResultViewModelRead;

  @Input()
  isPublicAccess: boolean;

  @Output()
  surveyProgramsAdded = new EventEmitter<SurveyProgramAssignmentViewModel[]>();

  constructor(private surveyService: SurveyService, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.surveyResultPrograms = this.surveyResult?.surveyResultPrograms.map(x => x.program).sort(dynamicSort('programCategory')) as ProgramSummaryViewModelReadExtended[];

    this.surveyService.getSurveySummaryById(this.surveyId).subscribe(results => {
      this.survey = results;
      this.initialized = true;
    });
  }

  onAddPrograms() {
    var atLeastOneStartDate = this.surveyResultPrograms.some(x => x.programStartDate);
    if (!atLeastOneStartDate) {
      this.toastr.error('Select at least one Program Start Date', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      var model = this.surveyResultPrograms.filter(x => x.programStartDate).map(x => { return { programId: x.id, startDate: dateAsUTCNoTime(x.programStartDate) } as SurveyProgramAssignmentViewModel});
      this.surveyProgramsAdded.emit(model);
      resetButton();
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { OrganizationType, SurveySummaryViewModelRead, UserRole } from 'src/app/_models/generatedModels';
import { coachUserRoleArray } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SurveyService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-runner-assessments',
  templateUrl: 'runner-assessments.component.html',
})
export class RunnerAssessmentsComponent implements OnInit {
  initialized = false;
  userId: number;
  organizationType = OrganizationType;
  hasUSAFFitnessAssessment: boolean = false;
  showPlanGenieSurvey: boolean = false;
  surveyId: number = 1;
  survey: SurveySummaryViewModelRead;
  hasCoachRole: boolean;

  constructor(private auth: AuthenticationService, private surveyService: SurveyService) {}

  ngOnInit(): void {
    this.auth.fetchUserProfile().subscribe(user => {
      this.userId = user.id;
      this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);
      if (user.organizationType & this.organizationType.HasUSAFFitnessAssessment) {
        this.hasUSAFFitnessAssessment = true;
      }
      if (this.auth.hasClaimForRole([UserRole.Runner]) && this.auth.hasClaimForRole(coachUserRoleArray)) {
        this.showPlanGenieSurvey = true;
      }

      this.surveyService.getSurveySummaryById(this.surveyId).subscribe(result => {
        this.survey = result;
        this.initialized = true;
      });
    });
  }
}

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SettingsProvider } from './settingsProvider.service';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class AiLoggingService
{
  appInsights: ApplicationInsights;

  init(settingsProvider: SettingsProvider): Promise<any>
  {
    return new Promise((resolve) =>
    {
      if (settingsProvider.aiInstrumentationKey) {

        this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: settingsProvider.aiInstrumentationKey,
            enableAutoRouteTracking: true
          }
        });

        this.appInsights.loadAppInsights();
      }

      resolve(this);
    });
  }

  logPageView(name?: string, url?: string)
  { // option to call manually
    if (this.appInsights) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any })
  {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any })
  {
    if (this.appInsights) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number)
  {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any })
  {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }
}


@Injectable()
export class AppInsErrorHandlerService extends ErrorHandler
{
  constructor(private aiLoggingService: AiLoggingService)
  {
    super();
  }

  handleError(error: Error)
  {
    this.aiLoggingService.logException(error); 
  }
}

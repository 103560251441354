import { Component, OnInit } from '@angular/core';
import { ExerciseProgressionListViewModelRead, ExerciseProgressionSearchParams } from 'src/app/_models/generatedModels';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { ExerciseProgressionPreviewComponent } from './preview/exercise-progression-preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bxl-exercise-progressions',
  templateUrl: 'exercise-progressions.component.html',
})
export class ExerciseProgressionsComponent implements OnInit {

  initialized = false;
  progressions: ServerSortableList<ExerciseProgressionListViewModelRead>;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  editUrl: string = '../exercise-progressions/edit';
  currentProgressionId: number;
  currentProgressionName: string;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  coachFilter: number = null;
  canEditGlobalLibrary: boolean = false;

  constructor(private exerciseService: ExerciseService, public auth: AuthenticationService, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Exercise Progressions', 'exercise-progressions', []);
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
    this.progressions = new ServerSortableList<ExerciseProgressionListViewModelRead>([], 'Name', false);
    this.searchProgressions();

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchProgressions();
    });

    this.progressions.sortChanged.subscribe(result => {
      this.currentPage = 1;
      this.searchProgressions();
    });
  }

  searchProgressions() {
    this.searching = true;
    let model = new ExerciseProgressionSearchParams();
    model.searchText = this.filter;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.progressions.column;
    model.isSortDesc = this.progressions.isDesc;
    model.coachId = this.coachFilter;

    this.exerciseService.searchForExerciseProgressions(model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.progressions.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  onPreview(exerciseProgressionId: number) {
    const modalRef = this.modalService.open(ExerciseProgressionPreviewComponent, { size: 'lg' });
    modalRef.componentInstance.exerciseProgressionId = exerciseProgressionId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  pageChanged(event: any): void {
    this.searchProgressions();
  }

  onCoachFilterChange(event: any) {
    this.currentPage = 1;
    this.searchProgressions();
  }

  onDelete(exerciseProgressionId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this exercise progression? This action cannot be undone.', 'Delete Progression', 'Delete Progression', 'Cancel').subscribe(result => {
      if (result) {
        this.exerciseService.deleteExerciseProgression(exerciseProgressionId).subscribe(result => {
          this.progressions.remove(x => x.id === exerciseProgressionId);
          this.toastr.success('Progression Deleted', 'Success');
        });
      }
    });
  }
}

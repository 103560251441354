<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div>
    <intensity-rpe-range-chart [clientUserId]="clientUserId" [page]="currentPage"></intensity-rpe-range-chart>
  </div>
  <div class=" card-footer py-4">
    <nav aria-label="...">
      <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
    </nav>
  </div>
</div>


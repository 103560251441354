<page-load-header *ngIf="!initialized"></page-load-header>

<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col">
          <h6 class="h2 d-inline-block mb-0">Teams Dashboard</h6>
        </div>
        <div class="d-inline-block float-right">
          <a class="btn btn-primary mr-3" href="javascript:void(0)" (click)="onAddTeam()"> Add Team</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div *ngIf="initialized" class="row">
    <div class="col-lg-12">
      <div *ngIf="(teams && teams.length == 0)" class="row mt-3">
        <div class="col text-center">
          <h6>Click "Add Team" button above to add your first Team</h6>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let team of teams" class="col-lg-4 col-md-6 same-height-cards">
          <div class="card">
            <div class="card-header">
              <a class="header-link" href="javascript:void(0)" [routerLink]="['../details', team.id]" href="javascript:void(0)">
                {{ team.name }}
              </a>
              <div class="float-right ">
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-dark dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="javascript:void(0)" [routerLink]="['../details', team.id]" class="dropdown-item"> View Details </a>
                    <a href="javascript:void(0)" (click)="onDeleteTeam(team.id)" class="dropdown-item"> Delete </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body fixed-height-card">
              <div class="row">
                <div class="col-12">
                  <div class="card-item">
                    <i class="far fa-calendar-alt"></i>Scheduled until {{ team.lastScheduledWorkoutDate ? (team.lastScheduledWorkoutDate | date) : 'N/A' }}
                  </div>
                </div>
                <div class="col-12">
                  <div class="card-item">
                    <i class="fas fa-flag-checkered"></i>Next team event on {{ team.nextScheduledEvent ? (team.nextScheduledEvent | date) : 'N/A' }}
                  </div>
                </div>
                <div class="col-12">
                  <div class="card-item">
                    <i class="far fa-running"></i>{{team.activeAthleteCount}} Athletes
                  </div>
                </div>
                <div class="col-12">
                  <div class="font-weight-bold mt-2">Coaches</div>
                    <div *ngFor="let user of team.coaches">
                      {{user.fullName}}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

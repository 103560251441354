import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { ClientSummaryDTO, MessageViewModel } from 'src/app/_models/generatedModels';
import { BasicObject } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { MessagingService } from 'src/app/_services/generatedServices';
import Enumerable from 'linq';
import { SendMessageModalComponent } from 'src/app/coaches-admin/messaging/send-message/send-message-modal.component';


@Component({
  selector: 'runner-my-messages-view',
  templateUrl: 'runner-my-messages.component.html',
  styleUrls: ['runner-my-messages.component.scss']
})
export class RunnerMyMessagesViewComponent implements OnInit {
  initialized = false;
  messagesGrouped: MessageViewModel[];
  clients: BasicObject[];
  clientComboData: BasicObject[];
  clientFilter: number = null;
  folder: string;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;

  constructor(private modalService: NgbModal, private messagingService: MessagingService, public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.folder = 'both';
    this.getData();
  }

  getData() {
    this.messagingService.getMessagesBetweenTwoUsers(this.clientUserId, this.folder).subscribe((messages) => {
      this.messagesGrouped = Enumerable.from(messages).groupBy(x => x.batchKey).select(x =>  x.first()).toArray();
      this.initialized = true;
    });
  }

  onFolderChange(e: any) {
    this.getData();
  }

  sendMessage() {
    const modalRef = this.modalService.open(SendMessageModalComponent, { size: 'lg', windowClass: 'modal-full', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.componentInstance.to = this.clientUserId;
    modalRef.componentInstance.toTeamId = null;
    modalRef.result.then(
      (result) => {
        this.getData();
      },
      (reason) => {
        this.getData();
      }
    );
    return;
  }

  reply(message: MessageViewModel, isReplyToTeam: boolean) {
    const modalRef = this.modalService.open(SendMessageModalComponent, { size: 'lg', windowClass: 'modal-full', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.componentInstance.to = isReplyToTeam ? null : message.fromUserId;
    modalRef.componentInstance.toTeamId = isReplyToTeam ? message.toTeamId : null;
    modalRef.componentInstance.subject = message.subject;
    modalRef.componentInstance.clientProgramWorkoutDayId = message.clientProgramWorkoutDayId;
    modalRef.result.then(
      (result) => {
        this.getData();
      },
      (reason) => {
        this.getData();
      }
    );
    return;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { ProgramWorkoutDayViewModelRead } from 'src/app/_models/generatedModels';

@Component({
  selector: 'bxl-program-quick-workout-view-modal',
  templateUrl: 'program-quick-workout-view-modal.component.html',
})
export class ProgramQuickWorkoutViewModalComponent implements OnInit {
  initialized = false;
  activityTypeEnum = Enums.ActivityTypeEnum;
  localMeasurementName: string;

  @Input()
  programWorkoutDay: ProgramWorkoutDayViewModelRead;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.localMeasurementName = localMeasurementDistanceName();
    this.initialized = true;
  }
}

import { Component, OnInit } from "@angular/core";
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  currentDate: Date = new Date();

  constructor(public settings: SettingsProvider) {}

  ngOnInit() {}
}

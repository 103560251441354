import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'privacy-policy-text',
  templateUrl: 'privacy-policy-text.component.html',
  styleUrls: ['privacy-policy-text.component.scss'],
})
export class PrivacyPolicyTextComponent implements OnInit {
  initialized: boolean;


  constructor() {}

  ngOnInit(): void {
    this.initialized = true;
  }
}

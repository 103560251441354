import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { RaceDistanceType, RacePlanSplitViewModelRead, RacePlanViewModelRead } from 'src/app/_models/generatedModels';
import { RacePlanService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-race-plan-view-modal',
  templateUrl: 'race-plan-view-modal.component.html',
  styleUrls: ['race-plan-view-modal.component.scss']
})
export class RacePlanViewModalComponent implements OnInit {
  initialized = false;
  plan: RacePlanViewModelRead;
  raceDistanceType = RaceDistanceType;
  raceDistanceTypeEnum = Enums.RaceDistanceTypeEnum;

  @Input()
  clientUserId: number;

  @Input()
  racePlanId: number;

  constructor(public activeModal: NgbActiveModal, private racePlanService: RacePlanService) { }

  ngOnInit(): void {
    this.racePlanService.getRacePlanById(this.clientUserId, this.racePlanId).subscribe(result => {
      this.plan = result;
      this.plan.racePlanSplits.sort(dynamicSort('splitNumber'));
      this.initialized = true;
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { OrganizationType } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-list-assessments',
  templateUrl: 'list-assessments.component.html',
})
export class ListAssessmentsComponent implements OnInit {
  clientUserId: number;
  initialized: boolean;
  organizationType = OrganizationType;
  hasUSAFFitnessAssessment: boolean = false;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Assessments', this.router.url, []);

    this.auth.fetchUserProfile().subscribe(user => {
      if (user.organizationType & this.organizationType.HasUSAFFitnessAssessment) {
        this.hasUSAFFitnessAssessment = true;
      }
      this.initialized = true;
    });
  }
}

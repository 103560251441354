import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { WorkoutViewModelRead } from 'src/app/_models/generatedModels';


@Component({
  selector: 'bxl-add-edit-workout',
  templateUrl: 'add-edit-workout.component.html'
})
export class AddEditWorkoutComponent extends ComponentCanDeactivate implements OnInit {
  workoutId: number;
  type: any;
  listUrl: string = '/library/workouts';
  isDirtyValue: boolean;


  constructor(private route: ActivatedRoute, private router: Router) {
    super();
  }

  canDeactivate(): boolean {
    return !this.isDirtyValue;
  }

  ngOnInit(): void {
    this.workoutId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.type = this.route.snapshot.params ? this.route.snapshot.params['workoutType'] : null;
  }

  onWorkoutSaved(workout: WorkoutViewModelRead) {
    this.router.navigate([this.listUrl]);
  }

  onIsDirtyChange(isDirty: boolean) {
    this.isDirtyValue = isDirty;
  }
}

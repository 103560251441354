<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-6">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-12 alert alert-warning" role="alert">
            Admin users are users who can manage athletes and organization settings.
          </div>
          <div class="col-lg-6 col-7"></div>

          <div class="col-lg-6 col-5 text-right">
            <a class="btn btn-primary" href="javascript:void(0)" (click)="addUser()"> New User</a>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Search by name or email" [(ngModel)]="filter" (ngModelChange)="onFilterChanged($event)" />
          </div>
          <div class="col-md-2">
            <select class="form-control" [(ngModel)]="status" (ngModelChange)="onStatusChanged($event)">
              <option value="1">Active</option>
              <option value="2">In-Active</option>
              <option value="3">Invited</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt--6">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header border-0">
            <h3 class="mb-0">Admin Users</h3>
          </div>

          <page-load-header *ngIf="searching"></page-load-header>
          <div [hidden]="searching" class="table-responsive">
            <table class="table align-items-center table-flush mobile-table">
              <thead class="thead-light">
                <tr class="header-row">
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th class="text-lg-center">Actions</th>
                </tr>
              </thead>

              <tbody class="list">
                <tr *ngFor="let client of clients">
                  <th scope="row">
                    <div class="media align-items-center" style="cursor: pointer">
                      <a class="avatar rounded-circle mr-3" href="javascript:void(0)">
                        <img alt="Image placeholder" src="fetch-avatar?userId={{client.id}}" />
                      </a>

                      <div class="media-body">
                        <span class="name mb-0 text-sm"> {{ client.firstName }} {{ client.lastName }} </span>
                      </div>
                    </div>
                  </th>
                  <td>
                    <span class="mobile-grid-label">Email</span>
                    {{ client.email }}
                  </td>
                  <td>
                    <span class="mobile-grid-label">Role</span>
                    {{ client.userRole | flagEnumString: userRoleEnum }}
                  </td>

                  <td class="text-lg-center">
                    <span class="mobile-grid-label">Actions</span>
                    <div class="btn-group no-caret">
                      <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"> </i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-lg-right">
                        <a *ngIf="client.status != clientStatus.Invited" href="javascript:void(0)" (click)="editUserRole(client)" class="dropdown-item"> Edit User Role</a>
                        <a *ngIf="client.status != clientStatus.Invited" href="javascript:void(0)" (click)="onSendPasswordReset(client.id, client.email, client.fullName)" class="dropdown-item"> Send Password Reset Email</a>
                        <a *ngIf="client.status == clientStatus.Active && (client.userRole | flagEnumCompare:userRole.Runner)" href="javascript:void(0)" (click)="removeCoachRole(client.id)" class="dropdown-item"> Remove Admin/Coach Role </a>
                        <a *ngIf="client.status == clientStatus.Active" href="javascript:void(0)" (click)="deactivateUser(client)" class="dropdown-item"> De-Activate</a>
                        <a *ngIf="client.status == clientStatus.InActive" href="javascript:void(0)" (click)="activateUser(client.id)" class="dropdown-item"> Activate</a>
                        <a *ngIf="client.status == clientStatus.Invited" href="javascript:void(0)" (click)="onSendInvitation(client)" class="dropdown-item"> Re-send Invitation </a>
                        <a *ngIf="client.status == clientStatus.Invited" href="javascript:void(0)" (click)="deleteUser(client.id)" class="dropdown-item"> Delete </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-footer py-4">
            <nav aria-label="..."></nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <kendo-dialog title="Deactivate User" *ngIf="showDeactivateDialog" (close)="closeDeactivateDialog('cancel')" [minWidth]="250" [width]="350">
      <form [formGroup]="deactivateFormGroup" novalidate>
          <div class="row">
            <div class="col-lg-12 mb-3">
              Are you sure you want to deactivate this user? Any athletes currently assigned to this coach will be unassigned.
              <br/><br/>
              Optionally, select a new coach to be assigned to the affected athletes.
            </div>
            <div class="col-lg-12 mb-3">
              <div class="form-group">
                <label class="form-control-label" for="input-first-name">
                  New Assigned Coach
                </label>
                <coaches-dropdown class="form-control" formControlName="coachUserId"></coaches-dropdown>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="text-danger font-weight-400">If this coach is also an athlete, all scheduled calendar data for future dates for this athlete will be permanently deleted. Historical calendar data will remain and can be accessed again by re-activating the user.</p>
            </div>
          </div>
      </form>
      <kendo-dialog-actions class="k-button-group k-dialog-buttongroup k-dialog-button-layout-stretched">
          <button kendoButton (click)="closeDeactivateDialog('cancel')" class="k-button">Cancel</button>
          <button kendoButton (click)="closeDeactivateDialog('save')" primary="true" class="k-button k-primary">Deactivate User</button>
      </kendo-dialog-actions>
  </kendo-dialog>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventViewModel, ClientProgramWorkoutViewModel, ExternalWorkoutSummaryViewModelRead, MeasurementSystem } from 'src/app/_models/generatedModels';
import { ClientsService, ExternalWorkoutService } from 'src/app/_services/generatedServices';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'match-synced-workout-dialog',
  templateUrl: 'match-synced-workout-dialog.component.html',
  styleUrls: ['match-synced-workout-dialog.component.scss']
})
export class MatchSyncedWorkoutDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  externalWorkout: ExternalWorkoutSummaryViewModelRead;
  matchableWorkouts: CalendarEventViewModel[];
  localMeasurementName: string;

  @Input()
    workoutToMatch: ClientProgramWorkoutViewModel;

  @Output()
    workoutMatched = new EventEmitter<boolean>();


  constructor(private fb: FormBuilder, private toastr: ToasterService, public activeModal: NgbActiveModal, private clientService: ClientsService,
    private externalWorkoutService: ExternalWorkoutService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    const isMetricSystem = this.auth.getMeasurementSystem() == MeasurementSystem.Metric;
    this.localMeasurementName = localMeasurementDistanceName();
    forkJoin([this.externalWorkoutService.getExternalWorkoutSummary(this.workoutToMatch.id, isMetricSystem),
        this.clientService.getMatchableClientProgramWorkoutDays(this.workoutToMatch.garminActivityId ?? 0, this.workoutToMatch.stravaActivityId ?? 0)]).subscribe(results => {
          this.externalWorkout = results[0];
          this.matchableWorkouts = results[1];
          this.setupForm();
        });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientProgramWorkoutDayId: [null, Validators.required],
      ratePerceivedExertion: [null, Validators.required]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields.', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      this.clientService.matchClientProgramWorkoutDay(this.formGroup.get('clientProgramWorkoutDayId').value, this.workoutToMatch.id, this.formGroup.get('ratePerceivedExertion').value).subscribe((results) => {
        this.workoutMatched.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { TrainingLoadPlannerService } from 'src/app/_services/generatedServices';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'training-load-plan-clone-dialog',
  templateUrl: 'training-load-plan-clone.component.html'
})
export class TrainingLoadPlanCloneDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  @Input()
    trainingLoadPlannerId: number;

  @Input()
    trainingLoadPlanName: string;

  @Input()
    defaultUserId: number;


  constructor(private fb: FormBuilder, private toastr: ToasterService, private trainingLoadPlannerService: TrainingLoadPlannerService, public activeModal: NgbActiveModal,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientUserId: [this.defaultUserId, Validators.required],
      trainingLoadPlannerId: [this.trainingLoadPlannerId],
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const newClientUserId = this.formGroup.get('clientUserId').value;
      this.trainingLoadPlannerService.cloneTrainingLoadPlan(this.trainingLoadPlannerId, newClientUserId).subscribe(result => {
        this.toastr.success('Plan Cloned', 'Success');
        this.activeModal.dismiss('saved');
        // need these extra router settings so that the Athlete name in the breadcrumb changes if necessary
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([`/athletes/details/${newClientUserId}/planner/edit/${result.id}`]);
      });
    });
  }
}

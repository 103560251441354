<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Training Load</h3>
    </div>
  </div>
  <training-volume-list [clientUserId]="clientUserId"></training-volume-list>
</div>


<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="!isPrintVersion" class="row">
    <div class="col-lg-12 mt-1 mb-2">
      <div class="float-right d-none d-lg-block">
        <a href="javascript:void(0)" class="btn btn-sm btn-primary" (click)="onPrintPDF()">Print PDF</a>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Summary</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h3>{{ assessment.dateTime | date }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>Assigned Loading Level: {{ assessment.levelAssigned }}</h3>
          </div>
        </div>
        <div *ngIf="assessment.notes" class="row">
          <div class="col">Notes: {{ assessment.notes }}</div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Level 1 - Lateral Step Down</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Able Painfree
                      <div class="ml-4">
                        Left:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.lateralStepDownAblePainfreeLeft, 'text-danger fa-times': !assessment.lateralStepDownAblePainfreeLeft }"></i>
                        Right:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.lateralStepDownAblePainfreeRight, 'text-danger fa-times': !assessment.lateralStepDownAblePainfreeRight }"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Alignment
                      <div class="ml-4">
                        Left:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.lateralStepDownAlignmentLeft, 'text-danger fa-times': !assessment.lateralStepDownAlignmentLeft }"></i>
                        Right:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.lateralStepDownAlignmentRight, 'text-danger fa-times': !assessment.lateralStepDownAlignmentRight }"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Symmetry: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.lateralStepDownSymmetry, 'text-danger fa-times': !assessment.lateralStepDownSymmetry }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8">
            <div *ngIf="!isPrintVersion && (assessment.lateralStepDownLeftVideoId || assessment.lateralStepDownRightVideoId)" class="row" style="font-weight: 500">
              <div class="col-md-6">
                Left Video:
                <embed-video-player *ngIf="assessment.lateralStepDownLeftVideoId" [size]="'small'" [videoId]="assessment.lateralStepDownLeftVideoId"></embed-video-player>
              </div>
              <div class="col-md-6">
                Right Video:
                <embed-video-player *ngIf="assessment.lateralStepDownRightVideoId" [size]="'small'" [videoId]="assessment.lateralStepDownRightVideoId"></embed-video-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Qualifier - Bilateral Jump Rope</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Able Painfree:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.qualifierBilateralJumpRopeAblePainfree, 'text-danger fa-times': !assessment.qualifierBilateralJumpRopeAblePainfree }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Level 2 - Stationary Jump - Bilateral</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Able Painfree:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpBilateralAblePainfree, 'text-danger fa-times': !assessment.stationaryJumpBilateralAblePainfree }"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Alignment:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpBilateralAlignment, 'text-danger fa-times': !assessment.stationaryJumpBilateralAlignment }"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Symmetry:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpBilateralSymmetry, 'text-danger fa-times': !assessment.stationaryJumpBilateralSymmetry }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8">
            <div *ngIf="!isPrintVersion && assessment.stationaryJumpBilateralVideoId" class="row" style="font-weight: 500">
              <div class="col-md-6">
                Video:
                <embed-video-player *ngIf="assessment.stationaryJumpBilateralVideoId" [size]="'small'" [videoId]="assessment.stationaryJumpBilateralVideoId"></embed-video-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Level 3 - Depth Jump Over Barrier</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Able Painfree:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.depthJumpOverBarrierAblePainfree, 'text-danger fa-times': !assessment.depthJumpOverBarrierAblePainfree }"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Alignment:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.depthJumpOverBarrierAlignment, 'text-danger fa-times': !assessment.depthJumpOverBarrierAlignment }"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Symmetry:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.depthJumpOverBarrierSymmetry, 'text-danger fa-times': !assessment.depthJumpOverBarrierSymmetry }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8">
            <div *ngIf="!isPrintVersion && assessment.depthJumpOverBarrierVideoId" class="row" style="font-weight: 500">
              <div class="col-md-6">
                Video:
                <embed-video-player *ngIf="assessment.depthJumpOverBarrierVideoId" [size]="'small'" [videoId]="assessment.depthJumpOverBarrierVideoId"></embed-video-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Qualifier - Unilateral Jump Rope</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Left:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.qualifierUnilateralJumpRopeAblePainfreeLeft, 'text-danger fa-times': !assessment.qualifierUnilateralJumpRopeAblePainfreeLeft }"></i>
                      Right:
                      <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.qualifierUnilateralJumpRopeAblePainfreeRight, 'text-danger fa-times': !assessment.qualifierUnilateralJumpRopeAblePainfreeRight }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Level 4 - Stationary Jump - Unilateral</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Able Painfree
                      <div class="ml-4">
                        Left:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpUnilateralAblePainfreeLeft, 'text-danger fa-times': !assessment.stationaryJumpUnilateralAblePainfreeLeft }"></i>
                        Right:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpUnilateralAblePainfreeRight, 'text-danger fa-times': !assessment.stationaryJumpUnilateralAblePainfreeRight }"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Alignment
                      <div class="ml-4">
                        Left:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpUnilateralAlignmentLeft, 'text-danger fa-times': !assessment.stationaryJumpUnilateralAlignmentLeft }"></i>
                        Right:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpUnilateralAlignmentRight, 'text-danger fa-times': !assessment.stationaryJumpUnilateralAlignmentRight }"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Symmetry: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.stationaryJumpUnilateralSymmetry, 'text-danger fa-times': !assessment.stationaryJumpUnilateralSymmetry }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8" style="background-color: white;">
            <div *ngIf="!isPrintVersion && (assessment.stationaryJumpUnilateralLeftVideoId || assessment.stationaryJumpUnilateralRightVideoId)" class="row" style="font-weight: 500">
              <div class="col-md-6">
                Left Video:
                <embed-video-player *ngIf="assessment.stationaryJumpUnilateralLeftVideoId" [size]="'small'" [videoId]="assessment.stationaryJumpUnilateralLeftVideoId"></embed-video-player>
              </div>
              <div class="col-md-6">
                Right Video:
                <embed-video-player *ngIf="assessment.stationaryJumpUnilateralRightVideoId" [size]="'small'" [videoId]="assessment.stationaryJumpUnilateralRightVideoId"></embed-video-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Level 5 - Triple Hop - Unilateral</h5></div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <table class="table table-responsive-md">
              <tbody class="table-bordered">
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Able Painfree
                      <div class="ml-4">
                        Left:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.tripleHopUnilateralAblePainfreeLeft, 'text-danger fa-times': !assessment.tripleHopUnilateralAblePainfreeLeft }"></i>
                        Right:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.tripleHopUnilateralAblePainfreeRight, 'text-danger fa-times': !assessment.tripleHopUnilateralAblePainfreeRight }"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Alignment
                      <div class="ml-4">
                        Left:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.tripleHopUnilateralAlignmentLeft, 'text-danger fa-times': !assessment.tripleHopUnilateralAlignmentLeft }"></i>
                        Right:
                        <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.tripleHopUnilateralAlignmentRight, 'text-danger fa-times': !assessment.tripleHopUnilateralAlignmentRight }"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 16px; padding: 0.5rem">
                    <div style="font-weight: 500">
                      Symmetry: <i class="assessment-result fas" [ngClass]="{ 'text-success fa-check': assessment.tripleHopUnilateralSymmetry, 'text-danger fa-times': !assessment.tripleHopUnilateralSymmetry }"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8">
            <div *ngIf="!isPrintVersion && (assessment.tripleHopUnilateralLeftVideoId || assessment.tripleHopUnilateralRightVideoId)" class="row" style="font-weight: 500">
              <div class="col-md-6">
                Left Video:
                <embed-video-player *ngIf="assessment.tripleHopUnilateralLeftVideoId" [size]="'small'" [videoId]="assessment.tripleHopUnilateralLeftVideoId"></embed-video-player>
              </div>
              <div class="col-md-6">
                Right Video:
                <embed-video-player *ngIf="assessment.tripleHopUnilateralRightVideoId" [size]="'small'" [videoId]="assessment.tripleHopUnilateralRightVideoId"></embed-video-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

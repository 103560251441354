import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter  } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExerciseService } from '../_services/generatedServices';
import { AuthenticationService } from '../_services/authentication.service';
import { ExerciseListViewModelRead } from '../_models/generatedModels';
import { dynamicSort } from '../_helpers/extensions.module';


@Component({
    selector: 'exercises-dropdown',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <kendo-combobox #exerciseCombo [data]="exerciseData" [disabled]="isDisabled" [valuePrimitive]="true" [filterable]="true" [popupSettings]="{popupClass: 'df-list-scroller'}" valueField="id" textField="name" [(ngModel)]="selectedValue" class="{{cssClass}}" (filterChange)="handleExerciseFilter($event)" (valueChange)="onChange($event)">
      <ng-template kendoComboBoxItemTemplate let-dataItem>
          <div>
            <span>{{dataItem.name}}</span><span *ngIf="dataItem.inExerciseProgression"><i class="fas fa-sort-alt ml-1"></i></span>
          </div>
      </ng-template>
    </kendo-combobox>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExerciseDropdownControl),
            multi: true
        }
    ]
})

export class ExerciseDropdownControl implements ControlValueAccessor, AfterContentInit {

    someAttr: string = 'anyValue';

    constructor(elementRef: ElementRef, private data: ExerciseService, private auth: AuthenticationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    selectedValue: number;

    @Input()
    isDisabled: boolean;

    // this is used if the exercises dropdown is on a page that also allows an exercise to be dynamically created and auto-selected
    @Input()
     newlyCreatedAndSelectedExerciseId: number;

    @Output()
    changed = new EventEmitter<string>();

    @Output()
    changedObject = new EventEmitter<ExerciseListViewModelRead>();

    exerciseData: ExerciseListViewModelRead[];
    rawExerciseData: ExerciseListViewModelRead[];
    @ViewChild('exerciseCombo') public exerciseCombo: ComboBoxComponent | undefined;
    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: number): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.changedObject.emit(this.rawExerciseData.find(x => value === x.id));
        this.changed.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngAfterContentInit(): void {
        this.data.getAllExercisesForOrganization().subscribe(results => {
            this.rawExerciseData = results.sort(dynamicSort('name'));
            this.exerciseData = results.sort(dynamicSort('name'));
        });
    }

    ngOnChanges() {
        if (this.newlyCreatedAndSelectedExerciseId) {
          // have to refresh the list if a new exercise was created and then select it from the list
        this.data.getAllExercisesForOrganization().subscribe(results => {
          this.rawExerciseData = results.sort(dynamicSort('name'));
          this.exerciseData = results.sort(dynamicSort('name'));
          this.selectedValue = this.newlyCreatedAndSelectedExerciseId;
        });
        }
      }

    handleExerciseFilter(value: any) {
        if (value.length >= 1) {
            this.exerciseData = this.rawExerciseData.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
            this.exerciseData = this.rawExerciseData;
            this.exerciseCombo.toggle(false);
        }
    }
}

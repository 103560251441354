import { HostListener, Injectable, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ToasterService } from '../_services/toaster.service';


@Directive()
export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      if (!this.canDeactivate()) {
          $event.returnValue =true;
      }
  }
}

// if a component can't use FormCanDeactivate (form is named something else or they don't have a form, then just extend ComponentCanDeactivate and add canDeactivate() method to component)
@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate{
  abstract get formGroup(): FormGroup;

  canDeactivate(): boolean{
       return !this.formGroup.dirty;
   }
 }

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private toastr: ToasterService) {
  }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {
    if (!component.canDeactivate()) {
      return this.toastr.confirmDialog('WARNING: You have unsaved changes. Are you sure you want to leave the page?', 'Are you sure?');
    } else {
      return true;
    }
  }
}



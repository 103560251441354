import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class MaxAthleteSubscriptionService {
  maxActiveAthleteCount: number = 500;

    constructor(private orgService: OrganizationService, private toastr: ToasterService, private router: Router) { }

    isMaxActiveAthletes(navigateToAthleteList: boolean): Observable<boolean> {
      return this.orgService.getOrganizationBillingDetails().pipe(map(org => {
        if (!org.complimentarySubscription && org.runnerCount >= this.maxActiveAthleteCount) {
          this.toastr.okDialog('You organization has reached the maximum number of active athletes (' + this.maxActiveAthleteCount.toString() +
            '). Please reach out to support@rundna.com if you would like a custom subscription plan.', 'Max Athletes Reached').subscribe(result => {
              if (navigateToAthleteList) {
                this.router.navigate(['/athletes']);
              }
            });
            return true;
        } else {
          return false;
        }
      }));
    }
}

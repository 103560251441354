import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { ClientSummaryDTO, MessageViewModel } from 'src/app/_models/generatedModels';
import { BasicObject } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { MessagingService } from 'src/app/_services/generatedServices';
import { SendMessageModalComponent } from '../send-message/send-message-modal.component';
import Enumerable from 'linq';

@Component({
  selector: 'bxl-message-list',
  templateUrl: 'message-list.component.html',
  styleUrls: ['message-list.component.scss']
})
export class MessageListComponent implements OnInit {
  initialized = false;
  messages: MessageViewModel[];
  sentMessagesGrouped: MessageViewModel[];
  clients: BasicObject[];
  clientComboData: BasicObject[];
  clientFilter: number = null;
  folder: number;

  @ViewChild('userCombo') public userCombo: ComboBoxComponent | undefined;

  constructor(private breadCrumbs: BreadcrumbsService, private modalService: NgbModal, private messagingService: MessagingService, public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.folder = 1;
    this.breadCrumbs.SetBreadcrumb('My Messages', '/message-list', []);
    this.getData();
  }

  getData() {
    this.messagingService.getMyMessages(undefined).subscribe((messages) => {
      this.messagingService.getUserFilterListForUserMessages().subscribe((clients) => {
        this.messages = messages;
        this.sentMessagesGrouped = Enumerable.from(this.messages).where((x => x.fromUserId == this.auth.user.id)).groupBy(x => x.batchKey).select(x =>  x.first()).toArray();
        this.setupUserList(clients);
        this.initialized = true;
      });
    });
  }

  setupUserList(clients: ClientSummaryDTO[]) {
    this.clients = clients.map(x => new BasicObject(x.userId, x.userFullName)).sort(dynamicSort('name'));
    this.clients.unshift(new BasicObject(null, 'All Users'));
    this.clientComboData = this.clients;
  }

  handleAthleteFilter(value: any) {
    if (value.length >= 1) {
        this.clientComboData = this.clients.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
        this.clientComboData = this.clients;
        this.userCombo.toggle(false);
    }
  }

  sendMessage() {
    const modalRef = this.modalService.open(SendMessageModalComponent, { size: 'lg', windowClass: 'modal-full', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.result.then(
      (result) => {
        this.getData();
      },
      (reason) => {
        this.getData();
      }
    );
    return;
  }

  reply(message: MessageViewModel, isReplyToTeam: boolean) {
    const modalRef = this.modalService.open(SendMessageModalComponent, { size: 'lg', windowClass: 'modal-full', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.componentInstance.to = isReplyToTeam ? null : message.fromUserId;
    modalRef.componentInstance.toTeamId = isReplyToTeam ? message.toTeamId : null;
    modalRef.componentInstance.subject = message.subject;
    modalRef.componentInstance.clientProgramWorkoutDayId = message.clientProgramWorkoutDayId;
    modalRef.result.then(
      (result) => {
        this.getData();
      },
      (reason) => {
        this.getData();
      }
    );
    return;
  }

  modelChanged(evt) {
    this.messagingService.getMyMessages(this.clientFilter).subscribe((messages) => {
      this.messages = messages;
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container" style="margin-top: 100px">
  <h3 class="text-center">RunDNA</h3>
  <div class="alert alert-warning text-center">This organization is inactive. Please ask your organization's administrator to reactivate it.</div>
  <div class="col-12 text-center mt-3">
    <button class="btn btn-primary" [routerLink]="['']">Refresh</button>
    <button class="btn btn-primary" type="button" (click)="logout()">Logout</button>
  </div>
</div>

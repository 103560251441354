<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Edit Tooltip: {{tooltip.key}}</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" #ngForm="ngForm" novalidate>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="title">
                  Title
                </label>
                <input class="form-control" formControlName="title" maxlength="50" type="text" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="videoLink">
                  Video Link
                </label>
                <input class="form-control" formControlName="videoLink" maxlength="1000" type="text" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="description">
                  Description
                </label>
                <custom-editor formControlName="description"></custom-editor>
              </div>
            </div>
          </div>

          <div class="row form-btns">
            <div class="col">
              <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
            </div>
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Tooltip</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>

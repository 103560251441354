<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="float-left">
                <label class="form-control-label mr-2"> Mailbox Folder: </label>
                  <select [(ngModel)]="folder" class="form-control d-inline" style="width: 100px;" (ngModelChange)="onFolderChange($event)">
                  <option [ngValue]="'both'">Both</option>
                  <option [ngValue]="'inbox'">Inbox</option>
                  <option [ngValue]="'sent'">Sent</option>
                </select>
              </div>
              <div class="float-right">
                <button type="button" class="btn btn-primary" (click)="sendMessage()">New Message</button>
              </div>
            </div>
            
          </div>
        </div>
        <div class="card-body">
          <div *ngIf="messagesGrouped.length == 0  && folder == 'both'" class="mb-4">
            There are no messages to/from this athlete.
          </div>
          <div *ngIf="messagesGrouped.length == 0  && folder == 'inbox'" class="mb-4">
            There are no messages from this athlete in your inbox.
          </div>
          <div *ngIf="messagesGrouped.length == 0  && folder == 'sent'" class="mb-4">
            You have not sent any messages to this athlete.
          </div>
          <div>
            <ng-container *ngFor="let message of messagesGrouped">
              <div class="message" [ngClass]="{'coach-message': message.fromUserId == auth.user.id}">
                <small class="text-muted d-block font-weight-bold">From: {{ message.fromUserFirstName + ' ' + message.fromUserLastName }}</small>
                <small *ngIf="message.toTeamId" class="text-muted d-block font-weight-bold"> (sent to team {{ message.toTeamName }}) </small>
                <small class="text-muted d-block font-weight-bold">{{ message.createdDate | date: 'medium' }} </small>
                <h6 class="mt-1 mb-0">{{ message.subject }}</h6>
                <p class="text-sm mt-1 mb-0" [innerHTML]="message.body"></p>
                <div class="mt-3">
                  <ng-container *ngIf="message.toUserId == auth.user.id">
                    <button class="btn btn-primary btn-sm" (click)="reply(message, false)">Reply</button>
                    <button *ngIf="message.toTeamId" class="btn btn-primary btn-sm ml-3" (click)="reply(message, true)">Reply to Team</button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

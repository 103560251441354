import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicReportFilterViewModel } from '../_models/generatedModels';
import { SettingsProvider } from './settingsProvider.service';

@Injectable()
export class ExportService {
  private baseUrl;
  fileExtension = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(private http: HttpClient, settings: SettingsProvider) {
    this.baseUrl = settings.configuration.baseUrl;
  }

  ExportCompletionPercentageReport(filter: DynamicReportFilterViewModel): Observable<any> {
    return this.http.put(`${this.baseUrl}/reports/completionPercentageReport/export`, filter, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }

  ExportTrainingVolumeReport(filter: DynamicReportFilterViewModel): Observable<any> {
    return this.http.put(`${this.baseUrl}/reports/trainingVolumeReport/export`, filter, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }

  ExportTrainingLoadPlan(userId: number, trainingLoadPlannerId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/planner/users/${userId}/trainingLoadPlanners/${trainingLoadPlannerId}/export`, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }

  ExportRacePlan(userId: number, racePlanId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/${userId}/racePlans/${racePlanId}/export`, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }

  ExportSuperuserOrganizationReport(organizationId: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.baseUrl}/reports/superuserOrganization/organizations/${organizationId}/export?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }

  ExportMonthlyReporting(): Observable<any> {
    return this.http.put(`${this.baseUrl}/superuser/organizations/exportMonthlyReporting`, null, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }

  ExportProgramPurchaseHistory(): Observable<any> {
    return this.http.put(`${this.baseUrl}/paidPrograms/programPurchaseHistory/export`, null, { responseType: 'blob' }).pipe(
      map((result: Blob) => {
        var blob = new Blob([result], { type: this.fileExtension });
        return blob;
      })
    );
  }
}

<div *ngIf="initialized">
  <div class="form-group">
    <label class="form-control-label" for="videoLink"> {{label}} </label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" readonly [(ngModel)]="fileNameDisplay" maxlength="1000" placeholder="Filename" />
      <input type="hidden" [(ngModel)]="fileName" />
      <div class="input-group-append">
        <label class="btn btn-primary mb-0">{{fileNameDisplay? 'Change' : 'Upload a'}} video <input hidden [promiseBtn]="uploading" (change)="onFileChange($event)" class="btn btn-outline-secondary" type="file" /> </label>
      </div>
    </div>
  </div>
  <div *ngIf="percentComplete >= 0" class="mb-0">
    <div class="alert alert-danger">Uploading: Please do not close this window</div>
    <div class="d-flex align-items-center pb-3">
      <div *ngIf="percentComplete >= 0" class="progress mt-4" style="height: 20px; width: 100%">
        <div class="progress-bar bg-alternate" role="progressbar" [ngStyle]="{ width: percentComplete + '%' }" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{ percentComplete }}%</div>
      </div>
    </div>
  </div>
</div>

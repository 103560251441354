<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6">
  <div class="container">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-12">
          <h5 class="mb-2">{{clientWorkout.workoutDate | date}} <span *ngIf="clientWorkout.eventType != eventType.GarminSyncedWorkout && clientWorkout.eventType != eventType.StravaSyncedWorkout"> - {{clientWorkout.workoutName}}</span></h5>
          <ng-container *ngIf="!clientWorkout.garminActivityId && !clientWorkout.stravaActivityId">
            <h5 class="mb-0">Your workout has been marked complete!</h5>
            <a (click)="onIncompleteWorkout()" href="javascript:void(0)" class="ml-2 toggle-link">Undo This Action</a>
          </ng-container>
        </div>
        
        <div *ngIf="clientWorkout.eventType == eventType.GarminSyncedWorkout || clientWorkout.eventType == eventType.StravaSyncedWorkout" class="col-12">
          <input class="form-control" style="max-width: 325px;" [(ngModel)]="clientWorkout.syncedWorkoutName" (ngModelChange)="onSyncedWorkoutNameChanged($event)" maxlength="100" type="text" />
        </div>

        <div *ngIf="RPERequired" class="col-lg-12 mt-3 mb-2">
          <div *ngIf="!clientWorkout.ratePerceivedExertion">
            Please rate your level of perceived exertion for this workout:
          </div>
          <div *ngIf="clientWorkout.ratePerceivedExertion">
            Your RPE has been recorded! You can change it below if needed:
          </div>
          <rate-perceived-exertion [(ngModel)]="clientWorkout.ratePerceivedExertion" (valueChange)="onRPEChanged()"></rate-perceived-exertion>
        </div>
        <div class="col-lg-6 col-md-12 mt-3 mb-2 mx-1 bg-white" *ngIf="(clientWorkout.workoutId || clientWorkout.eventType == eventType.QuickWorkout) && !clientWorkout.garminActivityId && !clientWorkout.stravaActivityId">
          <div>
            {{clientWorkout.isCompleted ? 'Actual durations have been recorded! You can change them below if needed:' : 'Please update your actual workout durations here:'}}
          </div>
          <div class="">
            <form class="form" [formGroup]="durationFormGroup" #ngForm="ngForm" novalidate>
              <ng-container formArrayName="activityTypeDurations">
                <div *ngFor="let activityTypeDuration of durationFormGroup.get('activityTypeDurations').controls; index as e">
                  <div class="row">
                    <ng-container [formGroupName]="e">
                      <div class="col-md-4 col-12">
                        <div class="form-group mb-0 mb-md-3">
                          <label class="form-control-label d-none d-sm-block" for="activityType"> &nbsp; </label>
                          <div class="form-control-text">{{activityTypeDuration.get('activityType').value | enumString:activityTypeEnum}}</div>
                        </div>
                      </div>
                      <div class="col-md-4 col-6">
                        <div class="form-group">
                          <label class="form-control-label" for="duration">
                            Duration ([h]:mm:ss)
                          </label>
                          <minutes-seconds-textbox formControlName="duration"></minutes-seconds-textbox>
                          <div class="invalid-message">
                            <div>format must be h:mm:ss or m:ss</div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="activityTypeDuration.get('activityType').value != activityType.StrengthTraining" class="col-md-4 col-6">
                        <div class="form-group">
                          <label class="form-control-label" for="distance">
                            Distance ({{localDistanceName}})
                          </label>
                          <kendo-numerictextbox noScroll class="form-control medium-textbox" formControlName="distance" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number" ></kendo-numerictextbox>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </form>
          </div>
          <div class="mb-1">
            <button *ngIf="clientWorkout.isCompleted" type="button" class="btn btn-primary" (click)="updateDurations()">Update Actual Durations</button>
          </div>
        </div>
        <div *ngIf="!hasDailyStatus" class="col-lg-12 mt-3 mb-2">
          <div class="text-white">
            Please fill out your daily status for today:
          </div>
          <a href="javascript:void(0)" (click)="onDailyStatus()" class="btn btn-primary mt-1"><i class="fas fa-plus mr-1"></i>Daily Status</a>
        </div>
        <div class="col-12 mt-2">
          <a href="javascript:void(0)" [routerLink]="['/runner/dashboard']" class="btn btn-primary mt-2">Go to Dashboard</a>
        </div>
      </div>
    </div>
  </div>
</div>


import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { last } from 'rxjs/operators';
import { Enums } from 'src/app/_models/generatedEnums';
import { ClientProgramWorkoutDayExerciseProgressViewModel, ClientProgramWorkoutDayItemViewModel, ExerciseResistanceType, WorkoutDisplayParams, WorkoutDisplayViewModelRead, WorkoutItemDisplayViewModelRead } from 'src/app/_models/generatedModels';
import { ExerciseLevelChangedParameters } from 'src/app/_models/models';
import { ClientsService, ExerciseService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'workout-view',
  templateUrl: 'workout-view.component.html',
  styleUrls: ['workout-view.component.scss']
})
export class WorkoutViewComponent implements OnInit, OnChanges {
  initialized = false;
  workout: WorkoutDisplayViewModelRead;
  showWorkoutInfo: boolean = true;
  workoutItems: WorkoutItemDisplayViewModelRead[];
  workoutCategoryEnum = Enums.WorkoutCategoryEnum;
  garminPoolLengthUnitEnum = Enums.GarminPoolLengthUnitEnum;
  exerciseResistanceTypeEnum = Enums.ExerciseResistanceTypeEnum;
  exerciseResistanceType = ExerciseResistanceType;
  weightUOMEnum = Enums.WeightUOMEnum;
  showSets: boolean = false;

  // only used when looking at workout scheduled for a runner
  @Input()
  clientProgramWorkoutDayId: number;

  // only used when looking at workout scheduled for a runner
  @Input()
  isCoachView: boolean = false;

  // only used when looking at workout scheduled for a runner
  @Input()
  clientWorkoutIsCompleted: boolean = false;

  @Input()
  workoutId: number;

  @Input()
  isWorkoutPreview: boolean = false;

  @Input()
  saveExerciseProgress: boolean = false;

  @Output()
  progressSaved = new EventEmitter<boolean>();

  constructor(private exerciseService : ExerciseService, private toastr: ToasterService, private clientService: ClientsService) { }

  ngOnInit(): void {
    let model = new WorkoutDisplayParams();
    model.workoutId = this.workoutId;
    model.forCurrentUser = this.clientProgramWorkoutDayId ? true : false;
    model.clientProgramWorkoutDayId = this.clientProgramWorkoutDayId ?? null;
    this.exerciseService.getWorkoutDisplayDetails(model).subscribe(result => {
      this.workout = result;
      this.workoutItems = this.workout.workoutItems;
      this.showSets = (!this.clientWorkoutIsCompleted && this.workout.hasExercises);
      this.initialized = true;
    });
  }

  ngOnChanges() {
    if (this.saveExerciseProgress) {
      this.saveExerciseProgress = false;
      let itemDetails = this.workoutItems.map(x => x.workoutItemDetails).reduce((a, b) => { return a.concat(b); }, []);
      let model = itemDetails.filter(x => x.exerciseProgress != null).map(y => y.exerciseProgress).reduce((a, b) => { return a.concat(b); }, []) as ClientProgramWorkoutDayExerciseProgressViewModel[];
      this.clientService.addClientWorkoutDayExerciseProgresses(this.clientProgramWorkoutDayId, model).subscribe(result => {
        this.progressSaved.emit(true);
      });
    }
  }

  onLevelChanged(event: ExerciseLevelChangedParameters) {
    this.workoutItems.find(x => x.id == event.workoutItemId).workoutItemDetails.find(x => x.id == event.workoutItemDetailsId).exerciseProgress = event.exerciseProgress;
  }

  onToggleCompleteChanged(event: ClientProgramWorkoutDayItemViewModel) {
    let workoutItem = this.workoutItems.find(x => x.id == event.workoutItemId);
    workoutItem.workoutItemDetails.forEach(itemDetail => {
      if (itemDetail.exerciseProgress) {
        itemDetail.exerciseProgress.exerciseProgressSets.forEach(set => {
          set.isCompleted = event.isCompleted;
        });
      }
    });
  }

  onResistanceTypeChanged(event: any, workoutItemId: number, workoutItemDetailId: number) {
    let workoutItemDetail = this.workoutItems.find(x => x.id == workoutItemId).workoutItemDetails.find(x => x.id == workoutItemDetailId);
    workoutItemDetail.exerciseProgress.exerciseProgressSets.forEach(set => {
      set.resistance = null;
      set.weight = null;
      set.hold = null;
    });
  }

  onAddSet(exerciseProgress: ClientProgramWorkoutDayExerciseProgressViewModel) {
    let lastSet = exerciseProgress.exerciseProgressSets[exerciseProgress.exerciseProgressSets.length - 1];
    exerciseProgress.exerciseProgressSets.push({ id: 0, setNumber: lastSet.setNumber + 1, reps: lastSet.reps, weight: lastSet.weight, hold: lastSet.hold, resistance: lastSet.resistance, isCompleted: false, clientProgramWorkoutDayExerciseProgressId: lastSet.clientProgramWorkoutDayExerciseProgressId });
  }

  onDeleteSet(exerciseProgress: ClientProgramWorkoutDayExerciseProgressViewModel) {
    if (exerciseProgress.exerciseProgressSets.length <= exerciseProgress.expectedSets) {
      this.toastr.okDialog('You cannot remove prescribed sets. If you did not complete, do not mark as Done.', 'Cannot Remove Set');
    } else {
      this.toastr.confirmDialog('Are you sure you want to remove the last set?', 'Remove Set').subscribe(result => {
        exerciseProgress.exerciseProgressSets.pop();
      });
    }
  }
}

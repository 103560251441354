<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <h3 class="mb-3 mt-1">Race Planner</h3>

  <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="title"> Plan Name </label>
              <input class="form-control" formControlName="name" maxlength="100" type="text" />
              <div class="invalid-message">
                <div>Name is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-control-label" for="raceDate">
                Race Date
              </label>
              <kendo-datepicker placeholder="" class="form-control" formControlName="raceDate"></kendo-datepicker>
              <div class="invalid-message">
                <div>Race Date is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-control-label" for="finishTime"> Finish Time </label>
              <div class="form-control-text">{{finishTime | secsToHourMinSec}}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="title"> Race Distance </label>
              <select class="form-control" formControlName="raceDistanceType" (ngModelChange)="onRaceDistanceTypeChange($event)">
                <option [ngValue]="null">(select one)</option>
                <ng-container *ngFor="let mapEntry of raceDistanceTypeEnum | keyvalue">
                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                </ng-container>
              </select>
              <div class="invalid-message">
                <div>Race Distance is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="form-group">
              <label class="form-control-label d-block" for="defaultPaceInSeconds">
                Default Pace (min/mile)
              </label>
              <minutes-seconds-textbox class="d-inline-flex" formControlName="defaultPaceInSeconds" (valueChange)="onDefaultPaceChange($event)"></minutes-seconds-textbox>
              <button type="button" class="btn btn-secondary align-top ml-1" (click)="onDefaultPaceUpdate()">Update</button>
              <button type="button" class="btn btn-secondary align-top ml-1" (click)="onClearAllPaces()">Clear All</button>
              <div *ngIf="formGroup.controls['defaultPaceInSeconds'].dirty && formGroup.controls['defaultPaceInSeconds'].errors?.invalidFormat" class="invalid-message-visible">
                <div>format must be h:mm:ss or m:ss</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="form-group">
              <label class="form-control-label" for="description"> Notes </label>
              <input class="form-control" formControlName="notes" maxlength="100" type="text" />
            </div>
          </div>
          <div class="col-12">
            <div *ngIf="formGroup.get('raceDistanceType').value == raceDistanceType.Custom" class="form-group">
              <label class="form-control-label" for="title"> Custom Distance (miles) </label>
              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="customDistance" (valueChange)="onCustomDistanceChange($event)" [decimals]="'2'" [format]="'n2'" min="1" max="20000" type="number"></kendo-numerictextbox>
              <div class="invalid-message">
                <div>Custom Distance is required</div>
              </div>
            </div>
          </div>
        </div>

        <h5>Splits</h5>
        <div class="row">
          <div class="col-12">
            <div formArrayName="racePlanSplits" class="formGroupContainer split-row" *ngFor="let split of formGroup.get('racePlanSplits').controls; let w = index">
              <div class="row formGroupName sortable-show-hover" [formGroupName]="w">
                <div class="col-lg-1 col-md-2 col-6 p-0">
                  <div class="form-group">
                    <label class="form-control-label" for="splitNumber"> &nbsp; </label>
                    <div class="form-control-text-no-label">Split {{split.get('splitNumber').value}}</div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-6 p-0">
                  <div class="form-group">
                    <label class="form-control-label" for="splitDistance"> Distance </label>
                    <div class="form-control-text">{{split.get('splitDistance').value | number : '1.2-2'}} mile</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4">
                  <div class="form-group">
                    <label class="form-control-label" for="splitPaceInSeconds">
                      Split Pace (min/mile)
                    </label>
                    <minutes-seconds-textbox formControlName="splitPaceInSeconds" (valueChange)="onSplitPaceChange($event,  w)"></minutes-seconds-textbox>
                    <div class="invalid-message">
                      <div>format must be h:mm:ss or m:ss</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-6 p-0">
                  <div class="form-group">
                    <label class="form-control-label" for="cumulativeDurationInSeconds"> Total Time </label>
                    <div class="form-control-text">{{split.get('cumulativeDurationInSeconds').value | secsToHourMinSec}}</div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label class="form-control-label" for="description"> Notes </label>
                    <input class="form-control" formControlName="notes" maxlength="100" type="text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>
      <div class="col text-right">
        <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>
</div>




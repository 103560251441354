import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime, parseDateString } from 'src/app/_helpers/extensions.module';
import { CalendarEventViewModel } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'move-calendar-event-dialog',
  templateUrl: 'move-calendar-event-dialog.component.html',
  styleUrls: ['move-calendar-event-dialog.component.scss'],
})
export class MoveCalendarEventDialogComponent implements OnInit {
  initialized: boolean;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  @Input()
  event: CalendarEventViewModel;

  constructor(public activeModal: NgbActiveModal, private clientsService: ClientsService, private toastr: ToasterService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      workoutDate: [parseDateString(this.event.workoutDate), Validators.required],
    });
    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const date = dateAsUTCNoTime(this.formGroup.get('workoutDate').value);
      this.clientsService.updateClientProgramWorkoutDayDate(this.event.eventId, date).subscribe((result) => {
        this.activeModal.dismiss('saved');
      });
    });
  }
}

import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { GaitAnalysisViewModel, StringViewModel } from 'src/app/_models/generatedModels';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { ToasterService } from 'src/app/_services/toaster.service';

declare var flowplayer: any;
import { Flowplayer } from '@flowplayer/player';
import Player = Flowplayer.Player;

@Component({
  selector: 'gait-analysis-result-details',
  templateUrl: 'gait-analysis-result-details.component.html',
  styleUrls: ['gait-analysis-result-details.component.scss']
})
export class GaitAnalysisResultDetailsComponent implements OnInit, OnDestroy {
  initialized = false;
  analysisId: any;
  analysis: GaitAnalysisViewModel;
  isWowzaVideo = false;
  wowzaPlayer: Player;
  livePLayer: amp.Player;
  shareableLink: string;

  @Input()
  isCoachView: boolean = false;

  @Input()
  isShared: boolean = false;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService, private settingsProvider: SettingsProvider, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.analysisId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    let pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let isGuid = this.analysisId.match(pattern) ? true : false;

    if (isGuid && this.isShared) {
      const model = new StringViewModel();
      model.value = this.analysisId;
      this.assessmentData.getSpecificGaitAnalysisByGuid(model).subscribe((result) => {
        this.analysis = result;
        if (result.assetPath && result.assetPath.includes('wowza'))
          this.isWowzaVideo = true;

        this.analysis.uniqueGuidId
        this.initialized = true;
        this.initializePlayer();
      });
    } else if (!this.isShared) {
      this.assessmentData.getSpecificGaitAnalysis(this.analysisId).subscribe((result) => {
        this.analysis = result;
        if (result.assetPath && result.assetPath.includes('wowza'))
          this.isWowzaVideo = true;

        this.shareableLink = this.settingsProvider.siteUrl +  '/gait-analysis/' + this.analysis.uniqueGuidId;
        this.initialized = true;
        this.initializePlayer();
      });
    }
  }

  initializePlayer() {
    of(true)
      .pipe(
        delay(500),
        tap(() => {
          this.setupPlayer();
        })
      )
      .subscribe();
  }

  setupPlayer()
  {
    if (this.isWowzaVideo) {

      this.wowzaPlayer = flowplayer('#wowzaPlayer',
        {
          src: this.analysis.assetPath,
          autoplay: flowplayer.autoplay.ON,
          autopause: true,
          controls: true,
          loop: true,
          muted: true,
          ui: flowplayer.ui.NO_VOLUME_CONTROL | flowplayer.ui.NO_MUTE | flowplayer.ui.USE_DRAG_HANDLE,
          start_quality: 4,
          qsel: {},
          token: this.settingsProvider.wowzaPlayerToken
        });

      return;
    }

    let source = { src: this.analysis.assetPath, type: 'video/mp4' };
    let options = {
      autoplay: true,
      controls: true,
      loop: true,
      hotKeys: {
        enableVolumeScroll: false,
      },
      fluid: true,
      logo: { enabled: false },
      ampAds: {
        mainProgram: {
          source: source,
          // tracks: tracks,
        },
      },
    };
    this.livePLayer = amp('livePLayer', options);
  }

  onCopyLink() {
    //copy link to clipboard
    let link = document.createElement('textarea');
    link.value = this.shareableLink;
    document.body.appendChild(link);
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    this.toastr.success('Link copied to clipboard', 'Success');
  }

  ngOnDestroy(): void {

    if (this.livePLayer) {
      this.livePLayer.dispose();
    }

    if (this.wowzaPlayer) {
      this.wowzaPlayer.destroy();
    }
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="entries.length === 0" class="mt-3 ml-3 mb-2">No daily statuses</div>
  <div *ngIf="entries && entries.length > 0" class="table-responsive">
    <table class="table align-items-center table-flush">
      <thead class="thead-light">
        <tr>
          <th>Date</th>
          <th>Able To Run</th>
          <th>Pain</th>
          <th>Sick</th>
          <th>Max Effort</th>
        </tr>
      </thead>

      <tbody class="list">
        <tr *ngFor="let entry of entries">
          <td class="">
            <a href="javascript:void(0)" (click)="openDetails(entry.id)">{{ entry.dailyStatusDate | date: 'shortDate' }}</a>
          </td>
          <td class="journal-icon" *ngIf="entry.ableToRun"><i class="far fa-smile text-success"></i></td>
          <td class="journal-icon" *ngIf="!entry.ableToRun"><i class="far fa-frown text-danger"></i></td>
          <td class="journal-icon" *ngIf="!entry.hasPain"><i class="far fa-smile text-success"></i></td>
          <td class="journal-icon" *ngIf="entry.hasPain"><i class="far fa-frown text-danger"></i></td>
          <td class="journal-icon" *ngIf="!entry.isSick"><i class="far fa-smile text-success"></i></td>
          <td class="journal-icon" *ngIf="entry.isSick"><i class="far fa-frown text-danger"></i></td>
          <td class="">{{entry.percentEffort}}%</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class=" card-footer py-4">
    <nav aria-label="...">
      <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
    </nav>
  </div>
</div>


<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">{{workoutId ? 'Edit' : 'Add'}} {{selectedWorkoutType | enumString:workoutTypeEnum}} Workout</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" #ngForm="ngForm" novalidate>
          <div *ngIf="workout.relatedWorkoutProgressionName && !isWorkoutCopy" class="row">
            <div class="col-12">
              <alert class="text-center" type="info" [dismissible]="true">
                <div>
                  <span class="font-weight-bold">This workout is part of a progression: {{workout.relatedWorkoutProgressionName}}</span>
                </div>
              </alert>
            </div>
          </div>
          <div *ngIf="workoutId && !isWorkoutCopy" class="row">
            <div class="col-12">
              <alert class="text-center" type="info" [dismissible]="true">
                <div>
                  <span class="font-weight-bold">Note: Editing a workout will also update all related workout instances on an athlete's calendar. You can Clone the workout instead if you want to create a similar workout.</span>
                </div>
              </alert>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="name">
                  Workout Name
                </label>
                <input class="form-control" formControlName="name" maxlength="100" type="text" />
                <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.required">
                  <div>Name is required</div>
                </div>
                <div class='invalid-message' *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.nameTaken">
                  Workout with this name already exists
              </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="workoutKeywords"> Keywords </label>
                <kendo-multiselect [data]="keywordsList" formControlName="workoutKeywords" [allowCustom]="true"></kendo-multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="videoType"> Video Type </label>
                <select formControlName="videoType" class="form-control">
                  <option value="2">Public (YouTube, Vimeo, etc)</option>
                  <option value="1">Uploaded</option>
                  <option value="0">None</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="formGroup.controls.videoType.value == 2" class="form-group">
                <label class="form-control-label" for="videoLink"> Link to Video </label>
                <input type="text" class="form-control" formControlName="videoLink" maxlength="1000" placeholder="YouTube/Vimeo Link" aria-label="Recipient's username" aria-describedby="button-addon2" />
              </div>
              <div *ngIf="formGroup.controls.videoType.value == 1" class="form-group">
                <video-upload formControlName="videoId" [label]="'Upload Video'"></video-upload>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="workoutCategory">
                  Workout Category
                </label>
                <select class="form-control" formControlName="workoutCategory">
                  <option [ngValue]="null">(none)</option>
                  <ng-container *ngFor="let mapEntry of workoutCategoryEnum | keyvalue">
                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
                <div class="invalid-message">
                  <div>Workout Category is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="goal">
                  Goal
                </label>
                <input class="form-control" formControlName="goal" maxlength="100" type="text" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                  <label class="form-control-label" for="expectedRPE">
                    Expected RPE
                  </label>
                  <div class="d-inline text-center" kendoTooltip>
                    <i class="fas fa-info-circle ml-2" title="The Expected RPE is required to calculate an athlete's expected training load and is used to give an estimate to the athlete."></i>
                  </div>
                  <select class="form-control" formControlName="expectedRPE">
                    <option [ngValue]="null">(none)</option>
                    <option [ngValue]="1">1</option>
                    <option [ngValue]="2">2</option>
                    <option [ngValue]="3">3</option>
                    <option [ngValue]="4">4</option>
                    <option [ngValue]="5">5</option>
                    <option [ngValue]="6">6</option>
                    <option [ngValue]="7">7</option>
                    <option [ngValue]="8">8</option>
                    <option [ngValue]="9">9</option>
                    <option [ngValue]="10">10</option>
                  </select>
              </div>
            </div>
            <div *ngIf="formGroup.get('workoutType').value == workoutType.Strength || isGarminWorkoutType" class="col-md-3">
              <div class="form-group">
                <label class="form-control-label" for="duration">
                  Expected Time
                </label>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="The Expected Time is required to calculate an athlete's expected training load and is used to give an estimate to the athlete."></i>
                </div>
                <minutes-seconds-textbox formControlName="expectedTime"></minutes-seconds-textbox>
                <div class="invalid-message">
                  <div>format must be h:mm:ss or m:ss</div>
                </div>
                <div *ngIf="formGroup.controls['expectedTime'].dirty && formGroup.controls['expectedTime'].errors && formGroup.controls['expectedTime'].errors.expectedTimeIsZero" class="custom-invalid-message">
                  Time must be greater than 0
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" class="col-md-3">
              <div class="form-group">
                <label class="form-control-label" for="expectedDuration">
                  Expected Time
                </label>
                <div>
                  {{totalExpectedTime | secsToHourMinSec}}
                </div>
              </div>
            </div>
            <div *ngIf="(formGroup.get('workoutType').value == workoutType.Cardio || isGarminWorkoutType) && totalExpectedDistanceMiles" class="col-md-3">
              <div class="form-group">
                <label class="form-control-label" for="expectedDuration">
                  Expected Distance - Miles
                </label>
                <div>
                  {{totalExpectedDistanceMiles | number : '1.2'}}
                </div>
              </div>
            </div>
            <div *ngIf="(formGroup.get('workoutType').value == workoutType.Cardio || isGarminWorkoutType) && totalExpectedDistanceKM" class="col-md-3">
              <div class="form-group">
                <label class="form-control-label" for="expectedDuration">
                  Expected Distance - Kilometers
                </label>
                <div>
                  {{totalExpectedDistanceKM | number : '1.2'}}
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('workoutType').value == workoutType.GarminLap_Swimming" class="col-lg-4 col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="garminPoolLength">
                  Pool Length
                </label>
                <div>
                  <kendo-numerictextbox noScroll class="form-control medium-numeric d-inline-block mr-1" formControlName="garminPoolLength" [decimals]="'0'" [format]="'####'" [min]="1" type="number"></kendo-numerictextbox>
                  <select formControlName="garminPoolLengthUnit" class="form-control d-inline-block" style="width: 100px;">
                    <option value="">Please Choose...</option>
                    <ng-container *ngFor="let mapEntry of garminPoolLengthUnitEnum | keyvalue">
                      <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-message">
                    <div>Must select pool length and unit</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="canEditGlobalLibrary" class="col-lg-6 col-md-12">
              <div class="form-group">
                <label class="form-control-label mr-1" for="isHidden">
                  Is Hidden Workout?
                </label>
                <kendo-switch formControlName="isHidden"></kendo-switch>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label" for="description">
                  Description {{isGarminWorkoutType ? '(max 1024 chars)' : ''}}
                </label>
                <ng-container *ngIf="!isGarminWorkoutType">
                  <custom-editor formControlName="description"></custom-editor>
                </ng-container>
                <ng-container *ngIf="isGarminWorkoutType">
                  <textarea class="form-control" formControlName="description" maxlength="1024" type="text" rows="6"></textarea>
                </ng-container>
              </div>
            </div>
          </div>

        <div class="btn-group split-button mr-3">
            <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Strength" (click)="onAddExerciseToWorkout(-1)" type="button" class="btn btn-primary btn-sm">Add Item to Workout</button>
            <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" (click)="onAddStepToWorkout(-1)"  type="button" class="btn btn-primary btn-sm">Add Item to Workout</button>
            <button type="button" *ngIf="isGarminWorkoutType" (click)="onAddGarminStepToWorkout(-1)"  type="button" class="btn btn-primary btn-sm">Add Step to Workout</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Workout Item dropdown">
              <button type="button" class="btn btn-primary btn-sm dropdown-toggle-split" ngbDropdownToggle></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <ng-container *ngIf="!isGarminWorkoutType">
                  <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" (click)="onAddStepToWorkout(-1)" ngbDropdownItem>Add Cardio Step</button>
                  <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" (click)="onAddIntervalToWorkout(-1)" ngbDropdownItem>Add Cardio Interval</button>
                  <button type="button" (click)="onAddExerciseToWorkout(-1)"  ngbDropdownItem>Add Exercise</button>
                  <button type="button" (click)="onAddCircuitToWorkout(-1)" ngbDropdownItem>Add Exercise Circuit</button>
                </ng-container>
                <ng-container *ngIf="isGarminWorkoutType">
                  <button type="button" (click)="onAddGarminStepToWorkout(-1)"  ngbDropdownItem>Add Step</button>
                  <button type="button" (click)="onAddGarminRepeatStepToWorkout(-1)" ngbDropdownItem>Add Repeat Step</button>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container formArrayName="workoutItems">
            <div *ngFor="let item of formGroup.get('workoutItems').controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="workout-item">
                  <div class="row">
                    <div class="col-md-1"><a href="javascript:void(0)" (click)="onDeleteWorkoutItem(i)"><i class="fa fa-trash"></i></a></div>
                    <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Circuit" class="col-md-2">
                      Circuit
                    </div>
                    <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Interval" class="col-md-2">
                      Interval
                    </div>
                    <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.GarminWorkoutRepeatStep" class="col-md-2">
                      Repeat Step
                    </div>
                    <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Circuit || item.get('workoutItemType').value == workoutItemTypeEnum.Interval || item.get('workoutItemType').value == workoutItemTypeEnum.GarminWorkoutRepeatStep" class="col-md-2">
                      <div class="form-group">
                        <label class="form-control-label" for="repeat">
                          Repeat
                        </label>
                        <kendo-numerictextbox noScroll class="form-control" formControlName="repeat" (valueChange)="onRepeatChange($event)" [decimals]="'2'" [format]="'n0'" min="0" max="9999.99" type="number" ></kendo-numerictextbox>
                        <div class="invalid-message">
                          <div>Repeat value is required</div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="ngForm.submitted && item.status == 'INVALID' && item.get('workoutItemType').value == workoutItemTypeEnum.Exercise" class="custom-invalid-message">
                      Delete this section or fill in the required fields
                    </div>
                  </div>
                  <ng-container formArrayName="workoutItemExercises">
                    <div *ngFor="let exercise of item.get('workoutItemExercises').controls; index as e">
                      <div class="row">
                        <ng-container [formGroupName]="e">
                          <div class="col-xl-3 col-lg-5 col-md-6">
                            <div class="form-group">
                              <a class="exercise-link" (click)="onCreateExercise(i, e)" href="javascript:void(0)">Create Exercise</a><span> or </span><label class="form-control-label">Select Exercise</label>
                              <div class="input-group">
                                <exercises-dropdown formControlName="exerciseId" [newlyCreatedAndSelectedExerciseId]="exercise.get('newlyCreatedAndSelectedExerciseId').value"></exercises-dropdown>
                                <div class="invalid-message">
                                  <div>Exercise is required</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Exercise" class="col-xl-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="set">
                                Sets
                              </label>
                              <kendo-numerictextbox noScroll class="form-control" formControlName="set" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                            </div>
                          </div>
                          <div class="col-xl-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="rep">
                                Reps
                              </label>
                              <kendo-numerictextbox noScroll class="form-control" formControlName="rep" [decimals]="'0'" [format]="'n0'" min="0" max="999" type="number" ></kendo-numerictextbox>
                            </div>
                          </div>
                          <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Circuit" class="d-none d-md-block col-md-3 d-xl-none">
                            <!-- placeholder for spacing for exercise circuits-->
                          </div>
                          <div class="col-lg-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="resistanceType">
                                Resistance Type
                              </label>
                              <select formControlName="resistanceType" class="form-control" (ngModelChange)="onResistanceTypeChange($event, i, e)">
                                <option [ngValue]="null">Please Choose...</option>
                                <ng-container *ngFor="let mapEntry of exerciseResistanceTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div *ngIf="exercise.get('resistanceType').value == exerciseResistanceType.Resistance" class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="resistance">
                                Resistance
                              </label>
                              <input class="form-control" formControlName="resistance" maxlength="50" type="text" />
                            </div>
                          </div>
                          <div *ngIf="exercise.get('resistanceType').value == exerciseResistanceType.Hold" class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="hold">
                                Hold (secs)
                              </label>
                              <kendo-numerictextbox noScroll class="form-control" formControlName="hold" [decimals]="'0'" [format]="'n0'" min="0" max="999" type="number" ></kendo-numerictextbox>
                            </div>
                          </div>
                          <div *ngIf="exercise.get('resistanceType').value == exerciseResistanceType.Weight" class="col-lg-2 col-md-4 col-6">
                            <div class="form-group">
                              <label class="form-control-label" for="weight">
                                Weight
                              </label>
                              <kendo-numerictextbox noScroll class="form-control" formControlName="weight" [decimals]="'1'" [format]="'#.#'" min="0" max="999" type="number" ></kendo-numerictextbox>
                            </div>
                          </div>
                          <div *ngIf="exercise.get('resistanceType').value == exerciseResistanceType.Weight" class="col-xl-1 col-md-2 col-6">
                            <div class="form-group">
                              <label class="form-control-label" for="weightUOM">
                                UOM
                              </label>
                              <select formControlName="weightUOM" class="form-control">
                                <ng-container *ngFor="let mapEntry of weightUOMEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" class="col-lg-4 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="durationForCardio">
                                Time
                              </label>
                              <div class="d-inline text-center" kendoTooltip>
                                <i class="fas fa-info-circle ml-2" title="The Time is used to calculate total Expected Time for the workout, which is used to estimate training load for the workout."></i>
                              </div>
                              <minutes-seconds-textbox formControlName="durationForCardio" (valueChange)="onDurationTimeChange($event, i, e, false)"></minutes-seconds-textbox>
                              <div class="invalid-message">
                                <div>format must be h:mm:ss or m:ss</div>
                              </div>
                              <div *ngIf="exercise.errors && exercise.errors.durationForCardioIsZero" class="custom-invalid-message">
                                Time must be greater than 0
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-5 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="notes">
                                Notes
                              </label>
                              <input class="form-control" formControlName="notes" maxlength="100" type="text" />
                            </div>
                          </div>
                          <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Circuit" class="col-md-1">
                            <div class="form-group">
                              <a href="javascript:void(0)" (click)="onDeleteExercise(i, e)"><i class="fa fa-trash"></i></a>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container formArrayName="workoutItemCardios">
                    <div [ngClass]="{ 'interval-step': item.get('workoutItemType').value == workoutItemTypeEnum.Interval }" *ngFor="let cardio of item.get('workoutItemCardios').controls; index as c">
                      <ng-container [formGroupName]="c">
                        <div class="row">
                          <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Interval" class="col-lg-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="cardioType">
                                Cardio Type
                              </label>
                              <div class="input-group">
                                <select formControlName="cardioType" class="form-control">
                                  <option value="">Please Choose...</option>
                                  <option value="1">Work</option>
                                  <option value="2">Recovery</option>
                                </select>
                                <div class="invalid-message">
                                  <div>Cardio Type is required</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="activityType">
                                Activity Type
                              </label>
                              <select formControlName="activityType" class="form-control">
                                <ng-container *ngFor="let mapEntry of activityTypeEnum | keyvalue">
                                  <option *ngIf="mapEntry.key != activityType.StrengthTraining" [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                              <div class="invalid-message">
                                <div>Activity Type is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="intensityType">
                                Intensity Type
                              </label>
                              <a *ngIf="cardio.get('intensityType').value == intensityType.RateOfPerceivedExertion" class="ml-1" style="font-size: 14px;" href="javascript:void(0)" (click)="showZoneChart(null, cardio.get('intensityType').value)">(RPE Chart)</a>
                              <select formControlName="intensityType" class="form-control" (ngModelChange)="onIntensityTypeChange($event, i, c)">
                                <option value="">Please Choose...</option>
                                <ng-container *ngFor="let mapEntry of intensityTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div *ngIf="cardio.get('intensityType').value == intensityType.HeartRateZone" class="col-lg-3 col-md-4">
                            <div class="form-group">
                              <label class="form-control-label" for="intensityValue">
                                Heart Rate Zone
                              </label>
                              <a class="ml-1" style="font-size: 14px;" href="javascript:void(0)" (click)="showZoneChart(null, cardio.get('intensityType').value)">(Zone Chart)</a>
                              <select formControlName="intensityValue" class="form-control">
                                <option value="">Please Choose...</option>
                                <option value="1">Zone 1</option>
                                <option value="2">Zone 2</option>
                                <option value="3">Zone 3</option>
                                <option value="4">Zone 4</option>
                                <option value="5">Zone 5</option>
                              </select>
                            </div>
                          </div>
                          <div *ngIf="cardio.get('intensityType').value == intensityType.RateOfPerceivedExertion" class="col-lg-3 col-md-4">
                            <div class="form-group">
                              <label class="form-control-label" for="intensityValue">
                                Min Rate of Perceived Exertion
                              </label>
                              <kendo-slider
                                  [fixedTickWidth]="10"
                                  [min]="1"
                                  [max]="10"
                                  [smallStep]="smallStep"
                                  formControlName = "intensityValue">
                                  <kendo-slider-messages
                                      increment="Right"
                                      decrement="Left"
                                  ></kendo-slider-messages>
                              </kendo-slider>
                              <span style="vertical-align: top;">&nbsp;&nbsp; {{ cardio.get('intensityValue').value }}</span>
                            </div>
                          </div>
                          <div *ngIf="cardio.get('intensityType').value == intensityType.Pace" class="col-lg-3 col-md-4">
                            <div class="form-group">
                              <label class="form-control-label" for="intensityValue">
                                Fastest Minutes per {{cardio.get('distanceUOM').value | enumString:distanceUOMEnum}}
                              </label>
                              <minutes-seconds-textbox formControlName="intensityValue" (valueChange)="onIntensityValueChange($event, i, c)"></minutes-seconds-textbox>
                              <div class="invalid-message">
                                <div>format must be h:mm:ss or m:ss</div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="cardio.get('intensityType').value == intensityType.VDOTPace" class="col-lg-3 col-md-4">
                            <div class="form-group">
                              <label class="form-control-label" for="vdotPaceType">
                                VDOT Pace Type
                              </label>
                              <select formControlName="vdotPaceType" class="form-control">
                                <option value="">Please Choose...</option>
                                <ng-container *ngFor="let mapEntry of vdotPaceTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-4">
                            <div class="form-group">
                              <div *ngIf="cardio.get('intensityType').value == intensityType.RateOfPerceivedExertion" >
                                <label class="form-control-label" for="intensityValue2">
                                  Max Rate of Perceived Exertion
                                </label>
                                <kendo-slider
                                    [fixedTickWidth]="10"
                                    [min]="1"
                                    [max]="10"
                                    [smallStep]="smallStep"
                                    formControlName = "intensityValue2">
                                    <kendo-slider-messages
                                        increment="Right"
                                        decrement="Left"
                                    ></kendo-slider-messages>
                                </kendo-slider>
                                <span style="vertical-align: top;">&nbsp;&nbsp; {{ cardio.get('intensityValue2').value }}</span>
                              </div>
                              <div *ngIf="cardio.get('intensityType').value == intensityType.Pace">
                                <label class="form-control-label" for="intensityValue2">
                                  Slowest Minutes per {{cardio.get('distanceUOM').value | enumString:distanceUOMEnum}}
                                </label>
                                <minutes-seconds-textbox formControlName="intensityValue2" (valueChange)="onIntensityValueChange($event, i, c)"></minutes-seconds-textbox>
                                <div class="invalid-message">
                                  <div>format must be h:mm:ss or m:ss</div>
                                </div>
                              </div>
                              <div *ngIf="cardio.errors && cardio.errors.invalidIntensityRange && cardio.get('intensityType').value != intensityType.Pace" class="custom-invalid-message">
                                The max intensity value cannot be smaller than the min value
                              </div>
                              <div *ngIf="cardio.errors && cardio.errors.invalidIntensityRange && cardio.get('intensityType').value == intensityType.Pace" class="custom-invalid-message">
                                The slowest pace cannot be faster than the fastest pace
                              </div>
                              <div *ngIf="cardio.errors && cardio.errors.intensityValueRequired" class="custom-invalid-message">
                                Min intensity is required if max intensity is filled out
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="durationDistance">
                                Distance
                              </label>
                              <div>
                                <kendo-numerictextbox noScroll class="form-control medium-numeric d-inline-block mr-1" formControlName="durationDistance" (valueChange)="onDistanceChange($event, i, c)" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number" ></kendo-numerictextbox>
                                <select formControlName="distanceUOM" class="form-control d-inline-block" style="width: 120px;" (ngModelChange)="onDistanceUOMChange($event, i, c)">
                                  <ng-container *ngFor="let mapEntry of distanceUOMEnum | keyvalue">
                                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                  </ng-container>
                                </select>
                                <!-- <ng-container *ngFor="let mapEntry of distanceUOMEnum | keyvalue">
                                  <div><input type="radio" formControlName="distanceUOM" (change)="onDistanceUOMChange($event, i, c)" [value]="mapEntry.key"><span>{{ mapEntry.value }}</span></div>
                                </ng-container> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="durationTime">
                                Time
                              </label>
                              <div class="d-inline text-center" kendoTooltip>
                                <i class="fas fa-info-circle ml-2" title="The Time is used to calculate total Expected Time for the workout, which is used to estimate training load for the workout."></i>
                              </div>
                              <minutes-seconds-textbox formControlName="durationTime" (valueChange)="onDurationTimeChange($event, i, c, true)"></minutes-seconds-textbox>
                              <div class="invalid-message">
                                <div>format must be h:mm:ss or m:ss</div>
                              </div>
                              <div *ngIf="cardio.errors && cardio.errors.durationTimeIsZero" class="custom-invalid-message">
                                Time must be greater than 0
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-5 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="notes">
                                Notes
                              </label>
                              <input class="form-control" formControlName="notes" maxlength="100" type="text" />
                            </div>
                          </div>
                          <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Interval" class="col-md-1">
                            <div class="form-group">
                              <a href="javascript:void(0)" (click)="onDeleteStep(i, c)"><i class="fa fa-trash"></i></a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container formArrayName="workoutItemGarminSteps">
                    <div [ngClass]="{ 'interval-step': item.get('workoutItemType').value == workoutItemTypeEnum.GarminWorkoutRepeatStep }" *ngFor="let garminStep of item.get('workoutItemGarminSteps').controls; index as g">
                      <ng-container [formGroupName]="g">
                        <div *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.GarminWorkoutRepeatStep" class="row">
                          <div class="col-md-1">
                            <div>
                              <a href="javascript:void(0)" (click)="onDeleteGarminStep(i, g)"><i class="fa fa-trash"></i></a>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="intensity">
                                Step Type
                              </label>
                              <div class="input-group">
                                <select formControlName="intensity" class="form-control">
                                  <ng-container *ngFor="let mapEntry of garminWorkoutStepIntensityEnum | keyvalue">
                                    <option *ngIf="mapEntry.key != 2 && ((formGroup.get('workoutType').value == workoutType.GarminLap_Swimming && mapEntry.key != 3) || formGroup.get('workoutType').value != workoutType.GarminLap_Swimming)" [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                    <option *ngIf="mapEntry.key == 2" [ngValue]="mapEntry.key">{{ selectedWorkoutTypeActiveName }}</option>
                                  </ng-container>
                                </select>
                                <div class="invalid-message">
                                  <div>Step Type is required</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="durationType">
                                Duration Type
                              </label>
                              <div class="input-group">
                                <select formControlName="durationType" class="form-control" (ngModelChange)="onGarminDurationTypeChange($event, i, g)">
                                  <ng-container *ngFor="let mapEntry of garminWorkoutStepDurationTypeEnum | keyvalue">
                                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                  </ng-container>
                                </select>
                                <div class="invalid-message">
                                  <div>Duration Type is required</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="garminStep.get('durationType').value == garminWorkoutStepDurationType.DISTANCE" class="col-lg-4 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="durationValue">
                                Distance
                              </label>
                              <div>
                                <kendo-numerictextbox noScroll class="form-control medium-numeric d-inline-block mr-1" formControlName="durationValue" (valueChange)="onGarminDurationValueDistanceChange($event, i, g)" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number" ></kendo-numerictextbox>
                              <select formControlName="durationValueType" class="form-control d-inline-block" style="width: 120px;" (ngModelChange)="onGarminDurationValueTypeChange($event, i, g)">
                                <ng-container *ngFor="let mapEntry of garminWorkoutStepDurationValueTypeEnum | keyvalue">
                                  <option *ngIf="(formGroup.get('workoutType').value == workoutType.GarminLap_Swimming && mapEntry.key != 1 && mapEntry.key != 2) || formGroup.get('workoutType').value != workoutType.GarminLap_Swimming" [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                              <div class="invalid-message-visible" *ngIf="garminStep.controls['durationValue'].dirty && garminStep.controls['durationValue'].errors">
                                <div>Distance is required</div>
                              </div>
                              </div>                              
                            </div>
                          </div>
                          <div *ngIf="garminStep.get('durationType').value == garminWorkoutStepDurationType.TIME" class="col-lg-2 col-md-3">
                            <div class="form-group">
                              <label class="form-control-label" for="durationValue">
                                Time
                              </label>
                              <minutes-seconds-textbox formControlName="durationValue"></minutes-seconds-textbox>
                              <div class="invalid-message">
                                <div>Time is required and format must be h:mm:ss or m:ss</div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="garminStep.get('durationType').value == garminWorkoutStepDurationType.OPEN" class="col-lg-2 col-md-3">
                           <!--  just a placeholder since OPEN doesn't have a durationValue -->
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="description">
                                Description
                              </label>
                              <input class="form-control" formControlName="description" maxlength="512" type="text" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="targetType">
                                Intensity Target
                              </label>
                              <select formControlName="targetType" class="form-control" (ngModelChange)="onGarminTargetTypeChange($event, i, g)">
                                <option [ngValue]="null">(no target)</option>
                                <ng-container *ngFor="let mapEntry of garminWorkoutStepTargetTypeEnum | keyvalue">
                                  <option *ngIf="(selectedWorkoutType == workoutType.GarminCycling && mapEntry.key != 2) || 
                                    ((selectedWorkoutType == workoutType.GarminRunning || selectedWorkoutType == workoutType.GarminLap_Swimming ) && mapEntry.key != 1 && mapEntry.key !=5 && mapEntry.key !=6)" 
                                    [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.HEART_RATE_ZONE || garminStep.get('targetType').value == garminWorkoutStepTargetType.POWER_ZONE" class="col-lg-3 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="targetValue">
                                Target Value
                              </label> <a class="ml-1" style="font-size: 14px;" href="javascript:void(0)" (click)="showZoneChart(garminStep.get('targetType').value, null)">(Zone Chart)</a>
                              <select formControlName="targetValue" class="form-control">
                                <option [ngValue]="null">Please choose...</option>
                                <option [ngValue]="1">Zone 1</option>
                                <option [ngValue]="2">Zone 2</option>
                                <option [ngValue]="3">Zone 3</option>
                                <option [ngValue]="4">Zone 4</option>
                                <option [ngValue]="5">Zone 5</option>
                                <option *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.POWER_ZONE" [ngValue]="6">Zone 6</option>
                                <option *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.POWER_ZONE" [ngValue]="7">Zone 7</option>
                              </select>
                            </div>
                          </div>
                          <div *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.PACE || garminStep.get('targetType').value == garminWorkoutStepTargetType.SPEED" class="col-lg-2 col-md-6">
                            <div class="form-group">
                              <label class="form-control-label" for="targetValueType">
                                Target UOM
                              </label>
                              <select formControlName="targetValueType" class="form-control">
                                <option [ngValue]="null">Please choose...</option>
                                <ng-container *ngFor="let mapEntry of garminWorkoutStepTargetValueTypeEnum | keyvalue">
                                  <option *ngIf="(selectedWorkoutType == workoutType.GarminRunning && (mapEntry.key == 1 || mapEntry.key == 2)) || 
                                    (selectedWorkoutType == workoutType.GarminCycling && (mapEntry.key == 3 || mapEntry.key == 4)) ||
                                    (selectedWorkoutType == workoutType.GarminLap_Swimming && (mapEntry.key == 5 || mapEntry.key == 6))" 
                                    [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <ng-container *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.PACE">
                            <div class="col-lg-2 col-md-4 col-6">
                              <div class="form-group">
                                <label class="form-control-label" for="targetValueLow">
                                  Fastest Pace
                                </label>
                                <minutes-seconds-textbox formControlName="targetValueLow"></minutes-seconds-textbox>
                                <div class="invalid-message">
                                  <div>format must be h:mm:ss or m:ss</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                              <div class="form-group">
                                <label class="form-control-label" for="targetValueHigh">
                                  Slowest Pace
                                </label>
                                <minutes-seconds-textbox formControlName="targetValueHigh"></minutes-seconds-textbox>
                                <div class="invalid-message">
                                  <div>format must be h:mm:ss or m:ss</div>
                                </div>
                                <div *ngIf="garminStep.errors && garminStep.errors.invalidTargetRange" class="custom-invalid-message">
                                  The max target value cannot be smaller than the min value
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.SPEED">
                            <div class="col-lg-2 col-md-4 col-6">
                              <div class="form-group">
                                <label class="form-control-label" for="targetValueLow">
                                  Slowest Speed
                                </label>
                                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="targetValueLow" [decimals]="'2'" [format]="'n2'" min="1" max="9999.99" type="number" ></kendo-numerictextbox>
                                <div class="invalid-message">
                                  <div>Slowest Speed is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                              <div class="form-group">
                                <label class="form-control-label" for="targetValueHigh">
                                  Fastest Speed
                                </label>
                                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="targetValueHigh" [decimals]="'2'" [format]="'n2'" min="1" max="9999.99" type="number" ></kendo-numerictextbox>
                                <div class="invalid-message">
                                  <div>Fastest Speed is required</div>
                                </div>
                                <div *ngIf="garminStep.errors && garminStep.errors.invalidTargetRange" class="custom-invalid-message">
                                  The max target value cannot be smaller than the min value
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="garminStep.get('targetType').value == garminWorkoutStepTargetType.HEART_RATE_CUSTOM || garminStep.get('targetType').value == garminWorkoutStepTargetType.POWER_CUSTOM">
                            <div class="col-lg-2 col-md-3 col-6">
                              <div class="form-group">
                                <label class="form-control-label" for="targetValueLow">
                                  Min {{ garminStep.get('targetType').value == garminWorkoutStepTargetType.HEART_RATE_CUSTOM ? 'bpm' : 'watts' }}
                                </label>
                                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="targetValueLow" [decimals]="'0'" [format]="'n0'" min="1" max="999" type="number" ></kendo-numerictextbox>
                                <div class="invalid-message">
                                  <div>Minimum value is required</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-6">
                              <div class="form-group">
                                <label class="form-control-label" for="targetValueHigh">
                                  Max {{ garminStep.get('targetType').value == garminWorkoutStepTargetType.HEART_RATE_CUSTOM ? 'bpm' : 'watts' }}
                                </label>
                                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="targetValueHigh" [decimals]="'0'" [format]="'n0'" min="1" max="999" type="number" ></kendo-numerictextbox>
                                <div class="invalid-message">
                                  <div>Maximum value is required</div>
                                </div>
                                <div *ngIf="garminStep.errors && garminStep.errors.invalidTargetRange" class="custom-invalid-message">
                                  The max target value cannot be smaller than the min value
                                </div>
                              </div>
                            </div>
                          </ng-container>      
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div class="row" *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Circuit">
                    <div class="col-md-12">
                      <a class="btn-sm" href="javascript:void(0)" (click)="onAddExerciseToCircuit(i)">Add Exercise to Circuit</a>
                    </div>
                  </div>
                  <div class="row" *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.Interval">
                    <div class="col-md-12">
                      <a class="btn-sm" href="javascript:void(0)" (click)="onAddStepToInterval(i)">Add Step to Interval</a>
                    </div>
                  </div>
                  <div class="row" *ngIf="item.get('workoutItemType').value == workoutItemTypeEnum.GarminWorkoutRepeatStep">
                    <div class="col-md-12">
                      <a class="btn-sm" href="javascript:void(0)" (click)="onAddStepToRepeatStep(i)">Add Step to Repeat</a>
                    </div>
                  </div>
                </div>

                <div class="btn-group split-button mr-3">
                  <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Strength" (click)="onAddExerciseToWorkout(i)" type="button" class="btn btn-primary btn-sm">Add Item to Workout</button>
                  <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" (click)="onAddStepToWorkout(i)"  type="button" class="btn btn-primary btn-sm">Add Item to Workout</button>
                  <button type="button" *ngIf="isGarminWorkoutType" (click)="onAddGarminStepToWorkout(i)"  type="button" class="btn btn-primary btn-sm">Add Step to Workout</button>
                  <div class="btn-group" ngbDropdown role="group" aria-label="Workout Item dropdown">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle-split" ngbDropdownToggle></button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <ng-container *ngIf="!isGarminWorkoutType">
                        <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" (click)="onAddStepToWorkout(i)" ngbDropdownItem>Add Cardio Step</button>
                        <button type="button" *ngIf="formGroup.get('workoutType').value == workoutType.Cardio" (click)="onAddIntervalToWorkout(i)" ngbDropdownItem>Add Cardio Interval</button>
                        <button type="button" (click)="onAddExerciseToWorkout(i)"  ngbDropdownItem>Add Exercise</button>
                        <button type="button" (click)="onAddCircuitToWorkout(i)" ngbDropdownItem>Add Exercise Circuit</button>
                      </ng-container>
                      <ng-container *ngIf="isGarminWorkoutType">
                        <button type="button" (click)="onAddGarminStepToWorkout(i)"  ngbDropdownItem>Add Step</button>
                        <button type="button" (click)="onAddGarminRepeatStepToWorkout(i)" ngbDropdownItem>Add Repeat Step</button>
                      </ng-container>
                    </div>
                  </div>
                </div>

              </ng-container>
            </div>
          </ng-container>
          <div class="row form-btns">
            <div class="col">
              <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
            </div>
            <div class="col text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Workout</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>

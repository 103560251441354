import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DailyStatusListViewModel } from 'src/app/_models/generatedModels';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyStatusModalComponent } from '../runner-daily-status/daily-status-modal.component';
import { DailyStatusesService } from 'src/app/_services/dailyStatuses.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'runner-daily-statuses',
  templateUrl: 'runner-daily-statuses.component.html',
  styleUrls: ['runner-daily-statuses.component.scss']
})
export class RunnerDailyStatusesComponent implements OnInit, OnDestroy {

  entries: DailyStatusListViewModel[];
  initialized: boolean;
  subscription: Subscription;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;


  constructor(private router: Router, private clientService: ClientsService, private toastr: ToasterService, private modalService: NgbModal, private dailyStatusesService: DailyStatusesService) { }

  ngOnInit(): void {
    this.getData();

    this.subscription = this.dailyStatusesService.entryAdded.subscribe(result => {
      this.getData();
    });
  }

  getData() {
    this.clientService.getRecentDailyStatusesForUser(this.clientUserId).subscribe(result => {
      this.entries = result;
      this.initialized = true;
    });
  }

  openDetails(entryId: number) {
    const modalRef = this.modalService.open(DailyStatusModalComponent, { size: 'lg', windowClass: 'modal-sm-custom', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.dailyStatusId = entryId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-reports',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss']
})
export class ReportsComponent implements OnInit {
  initialized = false;
  userId: number;
  reportSelection: string = 'completionPercentage';
  constructor(private breadcrumbs: BreadcrumbsService, private router: Router) { }

  ngOnInit(): void {
    this.breadcrumbs.SetBreadcrumb('Reports', '/reports', []);
    this.initialized = true;
  }

  onReportChanged(value: string) {
    switch(value) {
      case 'completionPercentage': {
        this.router.navigate(['/reports/completion-percentage']);
         break;
      }
      case 'trainingVolume': {
        this.router.navigate(['/reports/training-volume']);
         break;
      }
   }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ClientsService, ReportsService } from 'src/app/_services/generatedServices';
import { CalendarDayViewModel, ClientDetailsViewModelRead, TrainingLoadTargetViewModelRead } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { dateAsUTCNoTime, dateWithoutTime, getDifferenceInDays, localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';


@Component({
  selector: 'runner-dashboard-view',
  templateUrl: 'runner-dashboard-view.component.html',
  styleUrls: ['runner-dashboard-view.component.scss']
})

export class RunnerDashboardViewComponent implements OnInit {
  initialized = false;
  todaysWorkouts: CalendarDayViewModel = undefined;
  clientDetails: ClientDetailsViewModelRead;
  ImpairmentCategoryEnum = Enums.ImpairmentCategoryEnum;
  getDifferenceInDays = getDifferenceInDays;
  trainingLoadTargetData: TrainingLoadTargetViewModelRead = new TrainingLoadTargetViewModelRead();
  today: Date;
  localMeasurementName: string;

  @Input()
  clientUserId: number;

  @Input()
  clientUserCredentialId: number;

  @Input()
  isCoachView: boolean;

  @Input()
  hasCoachRole: boolean = false;

  constructor(private clientService: ClientsService, private auth: AuthenticationService, private reportService: ReportsService) {}

  ngOnInit(): void {
     this.today = dateWithoutTime(new Date());
     this.localMeasurementName = localMeasurementDistanceName();
    // load everything separately
    this.loadTodaysWorkouts();

    this.clientService.getClientDetailsByID(this.clientUserId).subscribe(result => {
      this.clientDetails = result;
    });

    this.reportService.getCurrentWeekTrainingLoadTargetData(this.clientUserId, this.today).subscribe(result => {
      this.trainingLoadTargetData = result;
    });
  }

  loadTodaysWorkouts() {
    this.clientService.getClientCalendarDaysByDateRange(this.clientUserCredentialId, dateAsUTCNoTime(this.today), dateAsUTCNoTime(this.today), this.hasCoachRole).subscribe(result => {
      this.todaysWorkouts = result ? result[0] : null;
    });
  }

}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-global-marketplace-layout",
  templateUrl: "./global-marketplace-layout.component.html",
  styleUrls: ["./global-marketplace-layout.component.scss"]
})
export class GlobalMarketplaceLayoutComponent implements OnInit, OnDestroy {


  constructor(private router: Router) {}

  ngOnInit() {

  }

  ngOnDestroy() {
  }
}

<div *ngIf="initialized">
  <div class="card  mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Match Synced Workout</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-12 mb-3 font-weight-bold">
              Synced Workout
              <div>
                <span>{{externalWorkout.activityType}}</span>
                <span class="ml-2" *ngIf="externalWorkout.durationInSeconds != null">{{externalWorkout.durationInSeconds | secsToHourMinSec}}</span>
                <span class="ml-2" *ngIf="externalWorkout.distance">{{externalWorkout.distance | number : '1.2-2'}}</span><span *ngIf="externalWorkout.distance"> {{localMeasurementName}}</span>
              </div>
            </div>
            <div *ngIf="matchableWorkouts.length > 0" class="col-md-12">
              <div class="form-group">
                <div style="height:22px;">&nbsp;</div>
                <div class="mb-2">Select a workout below to match with the synced workout. The synced workout will be associated with the selected workout, and synced workouts durations will be used for the actual durations.</div>
                <ng-container *ngFor="let workout of matchableWorkouts">
                  <div><input type="radio" formControlName="clientProgramWorkoutDayId" [value]="workout.eventId"><span>{{workout.workoutName }}</span>
                    <span class="ml-2" *ngIf="workout.timeInSeconds != null">{{ workout.timeInSeconds | secsToHourMinOnly }}</span>
                    <span class="ml-2" *ngIf="workout.miles">{{ (workout.miles| fromMilesToLocalMeasurement) | number: '1.1-1' }}{{localMeasurementName}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div *ngIf="matchableWorkouts.length > 0" class="col-lg-12 mt-2">
              <div class="form-group">
                <label class="form-control-label mr-2" for="rpe"> Rate of Perceived Exertion </label>
                <rate-perceived-exertion formControlName="ratePerceivedExertion"></rate-perceived-exertion>
                <div class="invalid-message">
                  <div>Rate of Perceived Exertion is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="matchableWorkouts.length == 0" class="col-md-12">
              <div class="form-group">
                <div style="height:22px;">&nbsp;</div>
                <div>There are no workouts available to match to (workout must be on same day, same activity type, and not completed)</div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">Match Workout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

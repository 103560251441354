import { Component, OnInit } from '@angular/core';
import { SurveyResultSearchParams, SurveyResultSummaryViewModelRead, SurveyViewModelRead } from 'src/app/_models/generatedModels';
import { SurveyService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditSurveyModalComponent } from './edit-survey-modal/edit-survey-modal.component';
import { Enums } from 'src/app/_models/generatedEnums';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'bxl-survey-list',
  templateUrl: 'survey-list.component.html',
})
export class SurveyListComponent implements OnInit {
  initialized = false;
  surveys: SurveyViewModelRead[];
  surveyResults: SurveyResultSummaryViewModelRead[];
  videoTypeEnum = Enums.VideoTypeEnum;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  surveyResultSearchText: string = '';
  surveyResultSearchChanged: Subject<string> = new Subject<string>();
  selectedSurveyId: number;
  searching: boolean = false;

  constructor(private surveyService: SurveyService, private breadcrumbs: BreadcrumbsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Surveys', 'surveys', []);
    this.loadSurveys();
    this.searchSurveyResults();

    this.surveyResultSearchChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.surveyResultSearchText = model;
      this.currentPage = 1;
      this.searchSurveyResults();
    });
  }

  loadSurveys() {
    this.surveyService.getAllSurveysWithDetails().subscribe(results => {
      this.surveys = results;
      this.initialized = true;
    });
  }

  searchSurveyResults() {
    this.searching = true;
    let model = new SurveyResultSearchParams();
    model.surveyId = this.selectedSurveyId;
    model.searchText = this.surveyResultSearchText;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    this.surveyService.searchSurveyResultSummaries(model).subscribe(results => {
      this.surveyResults = results.results;
      this.totalItems = results.rowCount;
      this.searching = false;
    });
  }

  pageChanged(event: any): void {
    this.searchSurveyResults();
  }

  onSurveyFilterChange(event: any) {
    this.currentPage = 1;
    this.searchSurveyResults();
  }

  onResultSearchTextChanged(query: string) {
    this.surveyResultSearchChanged.next(query);
  }

  onSurveyEdit(surveyId: number) {
    this.openModal('survey', surveyId);
  }

  onSurveyQuestionEdit(surveyQuestionId: number) {
    this.openModal('question', surveyQuestionId);
  }

  onSurveyResultEdit(surveyResultId: number) {
    this.openModal('result', surveyResultId);
  }

  openModal(recordType: string, id: number) {
    const modalRef = this.modalService.open(EditSurveyModalComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
    modalRef.componentInstance.recordType = recordType;
    modalRef.componentInstance.id = id;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          if (recordType == 'result') {
            this.searchSurveyResults();
          } else {
            this.loadSurveys();
          }
        }
      }
    );
  }
}

import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@progress/kendo-angular-sortable';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { arrayMax, dateAsUTCNoTime, localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { ActivityType, CalendarContextMenuOptions, CalendarDayViewModel, CalendarEventViewModel, CalendarWeekViewModel, ClientDayEventType, WorkoutType } from 'src/app/_models/generatedModels';
import { CalendarAddEventParameters, CalendarDraggedEventParameters, CalendarEventActionParameters, CalendarParameters } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { MediaBreakpointService } from 'src/app/_services/mediaBreakpoint.service';

@Component({
  selector: 'custom-calendar',
  templateUrl: 'custom-calendar.component.html',
  styleUrls: ['custom-calendar.component.scss'],
})
export class CustomCalendarComponent implements OnInit, OnDestroy {
  initialized: boolean;
  isMonthView: boolean = true;
  weekViewBreakpoints: string[] = ['', 'sm', 'md'];
  eventType = ClientDayEventType;
  workoutType = WorkoutType;
  activityType = ActivityType;
  weeks: CalendarWeekViewModel[];
  selectedDate: moment.Moment;
  today: moment.Moment;
  monthName: string;
  yearName: string;
  draggedEvent: CalendarEventViewModel;
  selectedDayDetails: CalendarDayViewModel;
  windowSizeSubscription: Subscription;
  currentBreakpoint: string;
  showMobileTotals: boolean = true;
  selectedEventId: number;
  contextMenuOptions = CalendarContextMenuOptions;
  trainingLoadInfo: string = 'Weekly Training Load shows how much exercise you have done in a week based on duration (min) and  intensity (RPE). This number is used to compare weeks to inform decisions that will maximize fitness gains and reduce injury risk.';
  scrollToToday: boolean = false;
  localMeasurementName: string;
  shiftKeyDown: boolean = false;

  @Input() set data(_data: CalendarWeekViewModel[]) {
    this.weeks = _data;
    this.selectedDayDetails = this.weeks.length > 0 ? this.weeks[0].days.find((x) => moment(x.calendarDate).isSame(this.selectedDate, 'day')) : null;
    this.initialized = true;

    if (this.isMonthView && this.scrollToToday) {
      this.scrollToToday = false;

      setTimeout(() => {
        var element = document.getElementById('todayElem');
        if (element) {
          //element.scrollIntoView(true);
          //element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          let dims = element.getBoundingClientRect();
          window.scrollTo({
            top: dims.top - document.body.getBoundingClientRect().top - 100,
            behavior: "smooth"
          });
        }
     }, 250)
    }
  }
  get data() {
    return this.weeks;
  }

  @Input()
  hasCoachRole: boolean = false;

  @Output()
  calendarParametersChanged = new EventEmitter<CalendarParameters>();

  @Output()
  eventInfoClicked = new EventEmitter<CalendarEventViewModel>();

  @Output()
  eventEditClicked = new EventEmitter<CalendarEventViewModel>();

  @Output()
  eventDeleteClicked = new EventEmitter<CalendarEventViewModel>();

  @Output()
  eventAddClicked = new EventEmitter<CalendarAddEventParameters>();

  @Output()
  eventDragEnd = new EventEmitter<CalendarDraggedEventParameters>();

  @Output()
  eventToggleCompleteClicked = new EventEmitter<CalendarEventViewModel>();

  @Output()
  eventCopyClicked = new EventEmitter<CalendarEventViewModel>();

  @Output()
  weekCopyClicked = new EventEmitter<Date>();


  @HostListener('window:keyup', ['$event'])
  upKeyEvent(event: KeyboardEvent) {
    if (!event.shiftKey) {
      this.shiftKeyDown = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  downKeyEvent(event: KeyboardEvent) {
    if (event.shiftKey) {
      this.shiftKeyDown = true;
    }
  }


  constructor(private toastr: ToasterService, private auth: AuthenticationService, private mediaBreakpointService: MediaBreakpointService) {}

  ngOnInit() {
    this.selectedDate = moment();
    this.currentBreakpoint = this.mediaBreakpointService.currentBreakpoint;
    this.scrollToToday = true;
    this.localMeasurementName = localMeasurementDistanceName();
    this.getData();

    this.windowSizeSubscription = this.mediaBreakpointService.mediaBreakpointChanged.subscribe((result) => {
      this.currentBreakpoint = result;
      if ((this.weekViewBreakpoints.some((x) => x == result) && this.isMonthView) || (!this.weekViewBreakpoints.some((x) => x == result) && !this.isMonthView)) {
        if (!this.weekViewBreakpoints.some((x) => x == result) && !this.isMonthView) {
          // default to month view if they switch to a larger screen view
          this.isMonthView = true;
        }
        this.getData();
      }
    });
  }

  getData() {
    this.monthName = this.selectedDate.format('MMMM');
    this.yearName = this.selectedDate.format('YYYY');
    this.isMonthView = this.weekViewBreakpoints.some((x) => x == this.currentBreakpoint) ? false : this.isMonthView;

    const today = dateAsUTCNoTime(new Date());
    let parameters = new CalendarParameters(dateAsUTCNoTime(this.selectedDate.toDate()), today, this.isMonthView);
    this.calendarParametersChanged.emit(parameters);
  }

  onPrevious() {
    if (this.isMonthView) {
      this.selectedDate.subtract(1, 'months');
    } else {
      this.selectedDate.subtract(7, 'days');
    }

    this.initialized = false;
    this.scrollToToday = true;
    this.getData();
  }

  onNext() {
    if (this.isMonthView) {
      this.selectedDate.add(1, 'months');
    } else {
      this.selectedDate.add(7, 'days');
    }

    this.initialized = false;
    this.scrollToToday = true;
    this.getData();
  }

  onToday() {
    this.selectedDate = moment();

    this.initialized = false;
    this.scrollToToday = true;
    this.getData();
  }

  onViewChange(isMonthView: boolean) {
    this.isMonthView = isMonthView;
    this.scrollToToday = true;
    this.getData();
  }

  onTodaysWorkoutsAction(event: CalendarEventActionParameters) {
    if ((event.menuOption & CalendarContextMenuOptions.View) != 0) {
      this.eventInfoClicked.emit(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Edit) != 0) {
      this.eventEditClicked.emit(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.ToggleComplete) != 0) {
      this.eventToggleCompleteClicked.emit(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Delete) != 0) {
      this.eventDeleteClicked.emit(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Copy) != 0) {
      this.eventCopyClicked.emit(event.event);
    }
  }

  onClickedOutsideMenu(event: Event) {
    this.closeAllMenus();
  }

  closeAllMenus() {
    this.selectedEventId = null;
  }

  onEventInfoClicked(clickEvent: Event, event: CalendarEventViewModel) {
    clickEvent.stopPropagation();
    this.closeAllMenus();
    this.eventInfoClicked.emit(event);
  }

  onEventClick(clickEvent: Event, event: CalendarEventViewModel) {
    clickEvent.stopPropagation();

    if (this.selectedEventId == event.eventId) {
      this.closeAllMenus();
    } else {
      this.selectedEventId = event.eventId;
    }
  }

  onEditEvent(clickEvent: Event, event: CalendarEventViewModel) {
    clickEvent.stopPropagation();
    this.closeAllMenus();
    this.eventEditClicked.emit(event);
  }

  onToggleTaskComplete(clickEvent: Event, event: CalendarEventViewModel) {
    clickEvent.stopPropagation();
    this.closeAllMenus();
    this.eventToggleCompleteClicked.emit(event);
  }

  onDeleteEvent(clickEvent: Event, event: CalendarEventViewModel) {
    clickEvent.stopPropagation();
    this.closeAllMenus();
    this.eventDeleteClicked.emit(event);
  }

  onDayClick(clickEvent: Event, date: Date, weekIndex: number, dayIndex: number) {
    // don't show modal if someone is just clicking off of an event context menu
    if (this.selectedEventId) {
      return;
    }

    clickEvent.stopPropagation();
    const maxSortOrder = arrayMax(this.weeks[weekIndex].days[dayIndex].events.map((x) => x.sortOrder));
    const parameters = new CalendarAddEventParameters(date, maxSortOrder);
    this.eventAddClicked.emit(parameters);
  }

  onMobileDayDetailsClick(weekIndex: number, dayIndex: number) {
    this.selectedDayDetails = this.weeks[weekIndex].days[dayIndex];
    this.selectedDate = moment(this.selectedDayDetails.calendarDate);
    this.weeks[weekIndex].days.forEach((x) => (x.isSelectedDay = false));
    this.weeks[weekIndex].days[dayIndex].isSelectedDay = true;
  }

  onDragStart(e: DragStartEvent, weekIndex: number, dayIndex: number): void {
    this.draggedEvent = this.weeks[weekIndex].days[dayIndex].events[e.index];
  }

  // technically this should be refactored out of this component...
  onDragOver(e: DragOverEvent) {
    // checking this here instead of dragStart/dragEnd to avoid issues where clicking triggers the dragStart bc of similar mouse movements
    if (this.shiftKeyDown && !this.hasCoachRole) {
      e.preventDefault();
      this.toastr.okDialog('Only coaches can shift-drag to copy a calendar event', 'Cannot Drag/Copy').subscribe((result) => {});
    } else if (this.draggedEvent.isCompleted && this.draggedEvent.eventType != this.eventType.ManualWorkout && !this.shiftKeyDown) {
      e.preventDefault();
      this.toastr.okDialog('Cannot move a workout/task that is already completed', 'Cannot Move').subscribe((result) => {});
    } else if (this.shiftKeyDown && this.draggedEvent.eventType != this.eventType.Workout && this.draggedEvent.eventType != this.eventType.QuickWorkout && this.draggedEvent.eventType != this.eventType.RestDay) {
      e.preventDefault();
      this.toastr.okDialog('Can only shift-drag to copy Workouts, Quick Workouts, and Rest Days', 'Cannot Drag/Copy').subscribe((result) => {});
    }
  }

  onDragEnd(dragEvent: DragEndEvent, weekIndex: number, dayIndex: number) {
    // have to do it this way because of DragEnd bug
    let draggedEventNewIndex = this.weeks[weekIndex].days[dayIndex].events.findIndex((x) => x.eventId == this.draggedEvent.eventId);

    // don't do anything if they dragged it to the original location
    if (!this.draggedEvent || (this.draggedEvent.sortOrder == draggedEventNewIndex + 1 && this.draggedEvent.workoutDate == this.weeks[weekIndex].days[dayIndex].calendarDate)) {
      return;
    }

    // when shift key is held down during drag, event should be copied, not moved
    const eventDate = dateAsUTCNoTime(new Date(this.weeks[weekIndex].days[dayIndex].calendarDate));
      const parameters = new CalendarDraggedEventParameters(draggedEventNewIndex, eventDate, this.draggedEvent, this.shiftKeyDown);
      this.eventDragEnd.emit(parameters);
  }

  onCopyEvent(clickEvent: Event, event: CalendarEventViewModel) {
    clickEvent.stopPropagation();
    this.closeAllMenus();
    this.eventCopyClicked.emit(event);
  }

  onCopyWeek(clickEvent: Event, date: Date) {
    clickEvent.stopPropagation();
    this.weekCopyClicked.emit(date);
  }

  ngOnDestroy() {
    this.windowSizeSubscription.unsubscribe();
  }
}

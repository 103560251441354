import { HttpClient } from '@angular/common/http';
import { Component, Input, forwardRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Enumerable from 'linq';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AzureBlobSASViewModel, StringViewModel } from '../_models/generatedModels';
import { ISasToken } from '../_services/azureStorage';
import { BlobStorageService } from '../_services/blob-storage.service';
import { FileService } from '../_services/generatedServices';

interface IUploadProgress {
  filename: string;
  progress: number;
}

//https://www.talkingdotnet.com/show-image-preview-before-uploading-using-angular-7/
//https://blog.angular-university.io/angular-file-upload/

@Component({
  selector: 'azure-file-upload',
  template: `
    <div class="upload-wrapper">
      <div *ngIf="percentComplete >= 0" class="upload-progress">
        <circle-progress [responsive]="true" [percent]="percentComplete" [startFromZero]="false" [radius]="100" [outerStrokeWidth]="16" [innerStrokeWidth]="8" [outerStrokeColor]="'#2e3f50'" [innerStrokeColor]="'#c6c6c6'" [animation]="true" [animationDuration]="300"></circle-progress>
      </div>
      <div *ngIf="imagePreview || value" class="img-tools text-center d-flex">
        <div class="toolbar px-2">
          <button type="button" (click)="clearImage()" class="btn-lg my-auto mx-auto"><i class="fas fa-trash"></i></button>
          <button *ngIf="imagePreview || value" type="button" (click)="downloadFile()" class="btn-lg my-auto mx-auto"><i class="fas fa-download"></i></button>
        </div>
        <img [ngClass]="{'bg-default': useDarkBackground}" class="img-fluid" *ngIf="value && !imagePreview && canPreview" [src]="sanitizedSrc" />
        <img [ngClass]="{'bg-default': useDarkBackground}" class="img-fluid" *ngIf="imagePreview && canPreview" [src]="imagePreview" />
        <div class="my-auto mx-auto" *ngIf="!canPreview">
          <h1><i [ngClass]="mimeType.icon" [style.color]="mimeType.color"></i></h1>
          <h6>{{ existingFileName }}</h6>
        </div>
      </div>
      <div *ngIf="!imagePreview && !value" fileDrop (fileDropped)="onFileDropped($event)" style="width: 100%; height: 100%; min-height: 200px; border: 2px dashed #ccc; border-radius: 20px" class="drop-zone d-flex">
        <div class="my-auto mx-auto">
          <input type="file" class="" [accept]="acceptedFileTypes" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event)" />
          <h3><i class="fas fa-upload"></i></h3>
          <h3>drop file here</h3>
          <h3>or</h3>
          <button type="button" class="btn btn-primary">Browse for file</button>
          <span *ngIf="requiredDimensions" class="d-block text-muted mt-2">(Required dimensions: {{ requiredDimensions }})</span>
        </div>
      </div>
    </div>
    <div *ngIf="allowErroredFileClear">
      <a href="javascript:void(0)" (click)="clearImage()">
        <i style="margin-top:10px; font-size:2rem;" class="fas fa-trash"> </i>
      </a>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AzureFileUploadControl),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AzureFileUploadControl),
      multi: true,
    },
  ],
})
export class AzureFileUploadControl implements ControlValueAccessor {
  value: string;
  @Input() azureContainer = 'images';
  @Input() requiredDimensions: string;
  @Input() isRequired: boolean;
  @Input() src: string;
  @Input() existingFileType: string;
  @Input() existingFileName: string;
  @Input() immediateUpload: boolean;
  @Input() acceptedFileTypes: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  @Input() maxFileSizeKB: number = 200;
  @Input() useDarkBackground: boolean = false;
  @Output() uploadComplete: EventEmitter<any> = new EventEmitter();
  @Output() invalidFileType: EventEmitter<any> = new EventEmitter();

  mimeTypes = Enumerable.from([
    { name: 'image/png', canPreview: true, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/jpeg', canPreview: true, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/gif', canPreview: true, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/svg+xml', canPreview: false, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/vnd.adobe.photoshop', canPreview: false, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/webp', canPreview: true, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/bmp', canPreview: true, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'image/tiff', canPreview: true, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'application/pdf', canPreview: false, icon: 'fas fa-file-pdf', color: 'darkred' },
    { name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', canPreview: false, icon: 'fas fa-file-word', color: '#183153' },
    { name: 'application/msword', canPreview: false, icon: 'fas fa-file-word', color: '#183153' },
    { name: 'application/postscript', canPreview: false, icon: 'fas fa-file-image', color: '#183153' },
    { name: 'video/mp4', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/quicktime', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/x-ms-wmv', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/x-flv', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/x-ms-asf', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/x-msvideo', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/webm', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
    { name: 'video/mpeg', canPreview: false, icon: 'fas fa-file-video', color: '#183153' },
  ]);

  selectedFile: any;
  sanitizedSrc: SafeUrl;
  canPreview = false;
  isNotImageError = false;
  imagePreview: string | ArrayBuffer | SafeUrl;
  dimensions: string;
  fileSizeKB: number = 0;
  percentComplete: number;
  allowErroredFileClear = false;

  constructor(private http: HttpClient, elementRef: ElementRef, private blobStorage: BlobStorageService, private sanitizer: DomSanitizer, private fileService: FileService) {}

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  get mimeType() {
    return this.mimeTypes.firstOrDefault((x) => x.name === this.existingFileType);
  }

  writeValue(value: string): void {
    this.dimensions = this.requiredDimensions;

    if (this.existingFileType == null || this.mimeTypes.firstOrDefault((x) => x.name == this.existingFileType)?.canPreview) {
      this.canPreview = true;
      this.sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    } else {
      this.canPreview = false;
    }

    this.value = value;
  }

  downloadFile() {
    if (this.selectedFile) {
      const objectUrl = URL.createObjectURL(this.selectedFile);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = this.existingFileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    } else {
      this.http.get(this.src, { responseType: 'blob' }).subscribe((result) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(result);
        a.href = objectUrl;
        a.download = this.existingFileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  clearImage() {
    this.value = null;
    this.imagePreview = null;
    this.existingFileName = null;
    this.selectedFile = null;
    this.existingFileType = null;
    this.dimensions = this.requiredDimensions;
    this.fileSizeKB = 0;
    this.propagateChange(this.value);
  }

  validate({ value }: FormControl) {
    const isNotValid = this.requiredDimensions && this.dimensions != this.requiredDimensions;
    this.allowErroredFileClear = false;

    if (isNotValid) {
      this.allowErroredFileClear = true;
      return { invalidDimensions: true };
    }

    if (this.fileSizeKB > this.maxFileSizeKB) {
      this.allowErroredFileClear = true;
      return { invalidFileSize: true };
    }

    if (this.isNotImageError) {
      this.allowErroredFileClear = true;
      return { invalidFileType: true };
    }

    if (this.isRequired && !this.selectedFile && !this.value) {
      return { isRequired: true };
    }
  }

  onChange() {
    this.propagateChange(this.value);
    this.propagateTouch();
  }

  onTouch() {
    this.propagateTouch();
  }

  onFileDropped($event) {
    this.previewImage($event[0]);
  }

  public Upload(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (!this.selectedFile) {
        resolve(this.value);
        return;
      }

      let model = new StringViewModel();
      model.value = this.selectedFile;
      this.fileService.getSASForFileContainer(this.selectedFile.name, this.azureContainer).subscribe((sasResult) => {
        this.uploadFile(this.selectedFile, sasResult).subscribe(
          (progress) => {
            if (progress.progress === 0) {
              this.percentComplete = 1;
            } else {
              this.percentComplete = progress.progress;
            }
          },
          (error) => {
            resolve(this.value);
            console.log('upload error', error);
          },
          () => {
            this.value = sasResult.fileName;
            this.percentComplete = -1;

            let result = {} as any;
            result.name = sasResult.fileName;
            result.filename = this.selectedFile.name;
            result.mimeType = this.selectedFile.type;
            this.propagateChange(this.value);
            resolve(this.value);
            this.uploadComplete.emit(result);
          }
        );
      });
    });
  }

  uploadFile(file: File, sasResult: AzureBlobSASViewModel): Observable<IUploadProgress> {
    const sasToken: ISasToken = {
      container: sasResult.containerName,
      filename: sasResult.fileName,
      storageAccessToken: sasResult.sasToken,
      storageUri: sasResult.storageUri,
    };

    return this.blobStorage.uploadToBlobStorage(sasToken, file).pipe(map((progress) => this.mapProgress(file, progress)));
  }

  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress,
    };
  }
  // public Upload(): Promise<string> {
  //   return new Promise<string>((resolve) => {
  //     if (this.selectedFile) {
  //       const formData = new FormData();
  //       formData.append('files', this.selectedFile);
  //       this.http.post(this.uploadUrl, formData).subscribe((result: any) => {
  //         this.value = result.name;
  //         result.filename = this.selectedFile.name;
  //         result.mimeType = this.selectedFile.type;
  //         this.propagateChange(this.value);
  //         resolve(this.value);
  //         this.uploadComplete.emit(result);
  //       });
  //     } else {
  //       resolve(this.value);
  //     }
  //   });
  // }

  previewImage(file: any) {
    var mimeType = file.type;

    if (this.acceptedFileTypes && this.acceptedFileTypes.length > 0 && !Enumerable.from(this.acceptedFileTypes).any((x) => x == mimeType)) {
      this.isNotImageError = true;
      this.onChange();
      this.invalidFileType.emit(file);
      return;
    } else {
      this.isNotImageError = false;
    }

    this.selectedFile = file;
    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    var parent = this;
    reader.onload = (_event) => {
      this.existingFileType = file.type;
      this.existingFileName = file.name;
      this.fileSizeKB = file.size / Math.pow(1024,1);

      this.mimeTypes.firstOrDefault((x) => x.name == file.type)?.canPreview;

      if (this.mimeTypes.firstOrDefault((x) => x.name == file.type)?.canPreview) {
        this.canPreview = true;
        var img = new Image();
        img.onload = () => {
          parent.dimensions = `${img.width}x${img.height}`;
          parent.onChange();

          const isNotValid = parent.requiredDimensions && parent.dimensions != parent.requiredDimensions;
          const isNotValidSize = this.fileSizeKB > this.maxFileSizeKB;
          if (isNotValid || isNotValidSize) {
            img.src = '';
            parent.imagePreview = '';
            return null;
          }

          if (this.immediateUpload) {
            this.Upload();
          }
        };
        img.src = <any>reader.result;
        this.imagePreview = this.sanitizer.bypassSecurityTrustResourceUrl(<string>_event.target.result);
      } else {
        this.dimensions = this.requiredDimensions;
        this.canPreview = false;
        if (this.immediateUpload) {
          this.Upload();
        }
      }
    };
  }

  fileBrowseHandler($event) {
    var file = $event.target.files[0];
    this.previewImage(file);
  }
}
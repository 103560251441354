import { Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarContextMenuOptions, CalendarDayViewModel } from 'src/app/_models/generatedModels';
import { CalendarEventActionParameters } from 'src/app/_models/models';
import { ManualWorkoutModalComponent } from '../manual-workout-modal/manual-workout-modal.component';
import { ClientCalendarService } from 'src/app/_services/clientCalendar.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'todays-workouts',
  templateUrl: 'todays-workouts.component.html',
  styleUrls: ['todays-workouts.component.scss']
})
export class TodaysWorkoutsComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  @Input()
  todaysWorkouts: CalendarDayViewModel = undefined;

  @Input()
  isCoachView: boolean;

  @Input()
  clientUserId: number;

  @Output()
  changed = new EventEmitter<boolean>();


  constructor(private modalService: NgbModal, private clientCalendarService: ClientCalendarService, private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.clientCalendarService.setInitialValues(this.clientUserId, this.isCoachView, this.isCoachView, false, (this.auth.user?.id == this.clientUserId));
    this.subscription = this.clientCalendarService.changed.subscribe(result => {
      this.changed.emit(result);
    });
  }

  onTodaysWorkoutsChanged(event: CalendarEventActionParameters) {
    if ((event.menuOption & CalendarContextMenuOptions.View) != 0) {
      this.clientCalendarService.onEventInfoClicked(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Edit) != 0) {
      this.clientCalendarService.onEventEditClicked(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.ToggleComplete) != 0) {
      this.clientCalendarService.onEventToggleCompleteClicked(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Delete) != 0) {
      this.clientCalendarService.onEventDeleteClicked(event.event);
    }
  }

  addManualWorkout() {
    const modalRef = this.modalService.open(ManualWorkoutModalComponent, { size: 'lg', windowClass: 'entry-modal', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.componentInstance.clientProgramWorkoutId = null;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.changed.emit(true);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

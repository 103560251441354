
<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Teams</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
  <router-outlet></router-outlet>
</div>




<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="">
  <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Athlete Dashboard</h3>
    </div>
  </div>

  <runner-dashboard-view [clientUserId]="clientUserId" [clientUserCredentialId]="clientUserCredentialId" [isCoachView]="true" [hasCoachRole]="true"></runner-dashboard-view>
</div>



<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-6">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-md-6 col-12">
            <h6 class="h2 d-inline-block mb-0">Race Plans</h6>
          </div>

          <div class="col-md-6 col-12 text-right">
            <a [routerLink]="['add']"  class="btn btn-primary">Add Plan</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt--6">
    <div class="row">
      <div class="col">
        <h5 *ngIf="!plans || plans.length == 0" class="mb-3 ml-3 mt-3">No Race Plans</h5>
        <div *ngIf="plans && plans.length > 0" class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row">
                <th>Race Date</th>
                <th>Name</th>
                <th>Finish Time</th>
                <th>Notes</th>
                <th class="text-lg-center">Actions</th>
              </tr>
            </thead>

            <tbody class="list">
              <tr *ngFor="let plan of plans">
                <td>
                  <span class="mobile-grid-label">Race Date</span>
                  <a href="javascript:void(0)" (click)="onShowDetails(plan.id)">{{ plan.raceDate | date }}</a>
                </td>
                <td>
                  <span class="mobile-grid-label">Name</span>
                  {{ plan.name }}
                </td>
                <td>
                  <span class="mobile-grid-label">Finish Time</span>
                  {{ plan.finishTime | secsToHourMinSec }}
                </td>
                <td>
                  <span class="mobile-grid-label">Notes</span>
                  {{ plan.notes }}
                </td>
                <td class="text-lg-center">
                  <span class="mobile-grid-label">Actions</span>
                  <div class="btn-group no-caret">
                    <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"> </i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg-right">
                      <a href="javascript:void(0)" [routerLink]="[editUrl, plan.id]"  class="dropdown-item"> Edit </a>
                      <a href="javascript:void(0)" (click)="onExport(plan.id, plan.name)" class="dropdown-item"> Export </a>
                      <a href="javascript:void(0)" (click)="onClone(plan.id, plan.name)" class="dropdown-item"> Clone </a>
                      <a href="javascript:void(0)" (click)="onDelete(plan.id)" class="dropdown-item"> Delete </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-md-8 col-4">
          <h6 class="h2 d-inline-block mb-0">Athletes</h6>

          <rundna-breadcrumbs>
            <li class="breadcrumb-item">
              <a [routerLink]="['/athletes']"> Athletes </a>
            </li>
            <li *ngIf="client != null" class="breadcrumb-item">
              <a [routerLink]="['/athletes/details/' + route.snapshot.url[2].toString()]"> {{client.firstName}} {{client.lastName}} </a>
            </li>
          </rundna-breadcrumbs>

        </div>
        <div class="col-lg-6 col-md-4 col-8 text-right">
          <h3 *ngIf="client != null">{{client.firstName}} {{client.lastName}}</h3>
        </div>
      </div>

      <div *ngIf="client != null" class="flex-column flex-md-row tab-container" type="pills">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['dashboard']" [routerLinkActive]="'active'"><i class="fas fa-home mr-2"></i>Dashboard </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['calendar']" [routerLinkActive]="'active'"><i class="far fa-calendar-alt mr-2"></i>Calendar </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['my-library']" [routerLinkActive]="'active'"><i class="far fa-star mr-2"></i>My Library </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['assessments']" [routerLinkActive]="'active'"><i class="far fa-object-ungroup mr-2"></i>Assessments </a>
          </li>
          <li class="nav-item">
            <div class="btn-group" #myDrop="ngbDropdown" ngbDropdown role="group" aria-label="Resources">
              <button class="btn btn-white nav-link" routerLinkActive="active" [ngClass]="{'active': trainingPlanActive.isActive || racePlanActive.isActive }" ngbDropdownToggle><i class="far fa-calculator-alt mr-2"></i>Resources</button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button [routerLink]="['planner']" routerLinkActive #trainingPlanActive="routerLinkActive" (click)="myDrop.close();" ngbDropdownItem>Annual Planner</button>
                <button [routerLink]="['race-plan']" routerLinkActive #racePlanActive="routerLinkActive" (click)="myDrop.close();" ngbDropdownItem>Race Planner</button>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['athlete-journal']" [routerLinkActive]="'active'"><i class="far fa-book-open mr-2"></i>Journal </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['athlete-messages']" [routerLinkActive]="'active'"><i class="far fa-envelope mr-2"></i>Messages </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['athlete-profile']" [routerLinkActive]="'active'"><i class="far fa-address-card mr-2"></i>Profile </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['athlete-notifications']" [routerLinkActive]="'active'"><i class="far fa-address-card mr-2"></i>Notifications </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid clients-container">
  <router-outlet></router-outlet>
</div>



<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container" style="margin-top: 100px">
  <h3 class="text-center">Welcome to RunDNA!</h3>
  <div class="alert alert-warning text-center">Welcome to RunDNA! Your coach has set up an account for you. Please complete your registration below to log in!</div>
  <form class="form" [formGroup]="formGroup" (ngSubmit)="onSave()" novalidate>
    <div>
      <h6 class="heading-small text-muted mb-4">User information</h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-first-name"> First name </label>
              <input class="form-control" id="input-first-name" formControlName="firstName" placeholder="First name" type="text" />
              <div class="invalid-message">
                <div>First Name is Required.</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-last-name"> Last name </label>
              <input class="form-control" formControlName="lastName" id="input-last-name" placeholder="Last name" type="text" value="Jesse" />
              <div class="invalid-message">
                <div>Last Name is Required.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label  d-block" for="input-username"> Year Of Birth </label>
              <kendo-numerictextbox noScroll class="form-control medium-numeric" formControlName="yearOfBirth" [decimals]="'0'" [format]="'####'" min="1900" max="2900" type="number"></kendo-numerictextbox>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-email"> Email address </label>
              <input class="form-control" id="input-email" formControlName="email" placeholder="jesse@example.com" type="email" />
              <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.required">
                <div>Email Address is required</div>
              </div>
              <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.emailTaken">
                <div>Email address is already taken</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label"> Password </label>

              <input class="form-control" formControlName="password" type="password" />
              <div class="invalid-message">
                <div>Please enter a valid password.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label"> Confirm Password </label>

              <input class="form-control" formControlName="confirmPassword" type="password" />
              <div *ngIf="f.errors?.notSame" class="invalid-message-visible">
                <div>Passwords do not match.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="example3cols1Input"> Gender </label>
              <select formControlName="gender" class="form-control">
                <option value="">Please Choose...</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="0">Other</option>
              </select>
              <div class="invalid-message">
                <div>Gender is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-4" />

      <h6 class="heading-small text-muted mb-4">Other information</h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label class="form-control-label" for="measurementSystem"> Measurement System</label>
              <div class="d-inline text-center" kendoTooltip>
                <i class="fas fa-info-circle ml-2" title="Choose a measurement system for distances to be displayed in."></i>
              </div>
              <select class="form-control" formControlName="measurementSystem" (ngModelChange)="onMeasurementSystemChanged()">
                <ng-container *ngFor="let mapEntry of measurementSystemEnum | keyvalue">
                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label class="form-control-label">Weight</label>
              <div class="input-group mb-3">
                <input formControlName="weightInPounds" type="number" class="form-control" />

                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{{isMetricSystem ? 'KG' : 'LBS'}}</span>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['weightInPounds'].errors && formGroup?.controls['weightInPounds'].errors.required">
                  <div>Weight is required</div>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['weightInPounds'].errors && formGroup?.controls['weightInPounds'].errors.pattern">
                  <div>Weight cannot contain a decimal</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label class="form-control-label" for="example3cols1Input"> Height </label>

              <div *ngIf="isMetricSystem" class="input-group mb-3">
                <input formControlName="heightInInches" type="number" class="form-control" />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">CM</span>
                </div>
                <div class="invalid-message-visible" *ngIf="formGroup?.controls['heightInInches'].errors && formGroup?.controls['heightInInches'].errors.required">
                  <div>Height is required</div>
                </div>
              </div>

              <select *ngIf="!isMetricSystem" formControlName="heightInInches" class="form-control">
                <option value="">Please Choose...</option>
                <option value="48">4ft</option>
                <option value="49">4ft, 1in</option>
                <option value="50">4ft, 2in</option>
                <option value="51">4ft, 3in</option>
                <option value="52">4ft, 4in</option>
                <option value="53">4ft, 5in</option>
                <option value="54">4ft, 6in</option>
                <option value="55">4ft, 7in</option>
                <option value="56">4ft, 8in</option>
                <option value="57">4ft, 9in</option>
                <option value="58">4ft, 10in</option>
                <option value="59">4ft, 11in</option>
                <option value="60">5ft</option>
                <option value="61">5ft, 1in</option>
                <option value="62">5ft, 2in</option>
                <option value="63">5ft, 3in</option>
                <option value="64">5ft, 4in</option>
                <option value="65">5ft, 5in</option>
                <option value="66">5ft, 6in</option>
                <option value="67">5ft, 7in</option>
                <option value="68">5ft, 8in</option>
                <option value="69">5ft, 9in</option>
                <option value="70">5ft, 10in</option>
                <option value="71">5ft, 11in</option>
                <option value="72">6ft</option>
                <option value="73">6ft, 1in</option>
                <option value="74">6ft, 2in</option>
                <option value="75">6ft, 3in</option>
                <option value="76">6ft, 4in</option>
                <option value="77">6ft, 5in</option>
                <option value="78">6ft, 6in</option>
                <option value="79">6ft, 7in</option>
                <option value="80">6ft, 8in</option>
                <option value="81">6ft, 9in</option>
                <option value="82">6ft, 10in</option>
                <option value="83">6ft, 11in</option>
                <option value="84">7ft</option>
                <option value="85">7ft, 1in</option>
                <option value="86">7ft, 2in</option>
                <option value="87">7ft, 3in</option>
                <option value="88">7ft, 4in</option>
                <option value="89">7ft, 5in</option>
                <option value="90">7ft, 6in</option>
                <option value="91">7ft, 7in</option>
                <option value="92">7ft, 8in</option>
                <option value="93">7ft, 9in</option>
                <option value="94">7ft, 10in</option>
                <option value="95">7ft, 11in</option>
              </select>
              <div class="invalid-message">
                <div>Height is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label class="form-control-label" for="example3cols2Input"> Years of Running Experience </label>

              <select formControlName="yearsOfExperience" class="form-control">
                <option value="">Please Choose...</option>
                <option value="Beginner">Beginner</option>
                <option value="OneToTwo">1-2 Years</option>
                <option value="ThreeToFive">3-5 Years</option>
                <option value="FiveToSeven">5-7 Years</option>
                <option value="SevenToTen">7-10 Years</option>
                <option value="TenPlus">10+ Years</option>
              </select>
              <div class="invalid-message">
                <div>Experience is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label class="form-control-label" for="example3cols3Input"> Highest Level of running </label>

              <select formControlName="highestLevelOfRunning" class="form-control">
                <option value="">Please Choose...</option>
                <option value="Recreational">Recreational</option>
                <option value="HighSchool">High School</option>
                <option value="College">College</option>
                <option value="Professional">Professional</option>
              </select>
              <div class="invalid-message">
                <div>Running Level is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <terms-conditions-checkbox formControlName="termsAndConditionsVersion"></terms-conditions-checkbox>
              <div class="invalid-message p-1">
                <div>Field is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-12 text-center">
        <button class="btn btn-primary" [promiseBtn]="submitComplete" type="submit">Complete Registration</button>
      </div>
    </div>
  </form>
</div>

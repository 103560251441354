<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card">
    <div class="bg-dark">
      <header class="card-header py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Race Plan Details</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body p-0">
        <div class="container mt-3">
          <div *ngIf="initialized">
            <h3 class="text-white">{{ plan.raceDate | date }}</h3>
            <h3 class="text-white mb-2">{{ plan.name }}</h3>
            <div class="card p-3">
              <div>
                <span class="font-weight-bold">Race Distance: </span><span>{{ plan.raceDistanceType == raceDistanceType.Custom ? ((plan.customDistance | number : '1.2-2') + ' miles') : (plan.raceDistanceType | enumString: raceDistanceTypeEnum ) }}</span>
              </div>
              <div>
                <span class="font-weight-bold">Finish Time: </span><span>{{ plan.finishTime | secsToHourMinSec }}</span>
              </div>
              <div>
                <span>{{ plan.notes }}</span>
              </div>
            </div>

            <ng-container *ngFor="let split of plan.racePlanSplits">
              <div class="card detail-card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <span class="font-weight-bold">Split {{split.splitNumber}}: </span>{{split.splitDistance | number : '1.2-2'}} miles &nbsp; &nbsp; &nbsp; Pace: {{split.splitPaceInSeconds | secsToHourMinSec }}
                        &nbsp; &nbsp; &nbsp; Total Time: {{split.cumulativeDurationInSeconds | secsToHourMinSec }}
                    </div>
                    <div class="col-md-12 ml-3" *ngIf="split.notes">
                      {{ split.notes }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

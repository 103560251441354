<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Copy Workout</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <form [formGroup]="formGroup" novalidate>
    <div class="card-body">
      <h5>{{event.workoutName}}</h5>
      <div class="col-md-12 col-lg-6">
        <div class="form-group">
          <label class="form-control-label" for="workoutDate"> Copy to Date </label>
          <kendo-datepicker placeholder="" class="form-control" formControlName="workoutDate" [focusedDate]="focusedDate"></kendo-datepicker>
          <div class="invalid-message">
            <div>Event Date is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row justify-content-between">
        <div>
          <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
        </div>
        <div>
          <button type="button" [promiseBtn]="submitComplete" (click)="onCopyAndModify()" class="btn btn-secondary">Copy & Modify</button>
          <button type="button" [promiseBtn]="submitComplete" (click)="onCopyAndRepeat()" class="btn btn-secondary">Copy & Repeat</button>
          <button type="button" [promiseBtn]="submitComplete" (click)="onCopy()" class="btn btn-primary">Copy</button>
        </div>
      </div>
    </div>
  </form>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { ClientDayEventType, WorkoutActivityTypeDurationViewModelRead } from 'src/app/_models/generatedModels';
import { dynamicSort, localMeasurementDistanceName, MilesKilometersToMilesPipe } from 'src/app/_helpers/extensions.module';
import { ProgramWeek } from 'src/app/_models/models';


@Component({
  selector: 'program-week-preview',
  templateUrl: 'program-week-preview.component.html',
  styleUrls: ['program-week-preview.component.scss']
})
export class ProgramWeekPreviewComponent implements OnInit {
  initialized: boolean;
  eventType = ClientDayEventType;
  localMeasurementName: string;

  @Input()
  week: ProgramWeek;


  constructor(private milesKilometersToMilesPipe: MilesKilometersToMilesPipe) { }

  ngOnInit(): void {
    this.localMeasurementName = localMeasurementDistanceName();
    this.updateWeekDurations();
    this.initialized = true;
  }

  updateWeekDurations() {
    // summarize durations by activity type
    let durations = this.week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout).map(x => x.workout.activityTypeDurations.map(duration => Object.assign(duration, {rateOfPerceivedExertion: x.workout.expectedRPE ?? 5}))).reduce((a, b) => { return a.concat(b); }, []);
    let quickWorkoutDurations = this.week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.eventType == this.eventType.QuickWorkout).map(x => { return {workoutId: 0, activityType: x.quickWorkoutActivityType, expectedTime: x.quickWorkoutDuration, expectedMiles: x.quickWorkoutDistance, expectedKilometers: 0, rateOfPerceivedExertion: 5 } as WorkoutActivityTypeDurationViewModelRead}).reduce((a, b) => { return a.concat(b); }, []);
    durations.push(...quickWorkoutDurations);

    let result = [];
    durations.reduce((res, value) => {
      if (!res[value.activityType]) {
        res[value.activityType] = { activityType: value.activityType, duration: 0, distance: 0, trainingLoad: 0 };
        result.push(res[value.activityType])
      }
      res[value.activityType].duration += value.expectedTime;
      res[value.activityType].distance += this.milesKilometersToMilesPipe.transform(value.expectedMiles, value.expectedKilometers) ?? 0;
      res[value.activityType].trainingLoad += Math.round(value.rateOfPerceivedExertion * (value.expectedTime / 60.0));
      return res;
    }, {});
    this.week.activityTypeDurations = result.sort(dynamicSort('activityType'));
  }

}

import { Component, Input, EventEmitter, Output, forwardRef, AfterContentInit } from '@angular/core';
import { StringViewModel } from 'src/app/_models/generatedModels';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'image-upload-control',
  templateUrl: 'image-upload.component.html',
  styleUrls: ['image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor, AfterContentInit {
  initialized = false;
  selectedValue: string;

  croppedImage: any = '';
  initialCroppedImage: string;
  showImageUpload: boolean = false;
  percentComplete: number = -1;


  @Input()
  label: string = "Upload Image";

  @Output()
  valueChange = new EventEmitter<string>();

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: string): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange(value: any) {
    this.writeValue(value);
    this.propagateChange(this.selectedValue);
    this.propagateTouch();
    this.valueChange.emit(value);
  }

  constructor(private fileService: FileService) {}

  ngAfterContentInit(): void {
    this.initialized = true;
  }

  editImage() {
    this.showImageUpload = true;
  }

  onImageChanged(image: any) {
    this.croppedImage = image;
    this.percentComplete = 50;

    let model = new StringViewModel();
    model.value = this.croppedImage;
    this.fileService.addImage(model).subscribe(result => {
      this.onChange(result.value);
      this.percentComplete = -1;
    });
  }

  deleteImage() {
    this.croppedImage = null;
    this.onChange(null);
  }

}

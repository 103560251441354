<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Library</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>
        </div>
      </div>
      <div class="flex-column flex-md-row tab-container" type="pills">
        <ul class="nav nav-pills">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['exercises']" [routerLinkActive]="'active'"><i class="fas fa-dumbbell mr-2"></i>Exercises </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['exercise-progressions']" [routerLinkActive]="'active'"><i class="fas fa-sort-alt mr-2"></i>Exercise Progressions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['workouts']" [routerLinkActive]="'active'"><i class="fas fa-running mr-2"></i>Workouts </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['workout-progressions']" [routerLinkActive]="'active'"><i class="fas fa-sort-alt mr-2"></i>Workout Progressions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['programs']" [routerLinkActive]="'active'"><i class="far fa-map mr-2"></i>Programs </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>

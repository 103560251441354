import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {

  constructor(private router: Router, public auth: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.checkIfLoggedIn()) {
      return true;
    }

    let token = route.queryParamMap.get('token');
    if (token) {
      this.auth.refreshClaimsWithToken(token).subscribe((result) => {
        if (this.checkIfLoggedIn()) {
          // for some reason, refreshing the claim seems to mess up the guard's ability to route, so explicitly routing here
          this.router.navigateByUrl(state.url);
          return true;
        }

        this.redirectNotLoggedIn(state.url);
      });
    } else {
      this.redirectNotLoggedIn(state.url);
    }

    return false;
  }

  checkIfLoggedIn() {
    if (localStorage.getItem('rundna-currentUser')) {
      // logged in so return true
      return true;
    }
  }

  redirectNotLoggedIn(stateUrl: string) {
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: stateUrl } });
  }
}

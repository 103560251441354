import { Component, OnInit } from '@angular/core';
import { UserSearchParams, UserSearchResultViewModel } from 'src/app/_models/generatedModels';
import { SuperUserService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Enums } from 'src/app/_models/generatedEnums';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'bxl-user-list',
  templateUrl: 'user-list.component.html',
})
export class AllUserListComponent implements OnInit {

  initialized = false;
  users: ServerSortableList<UserSearchResultViewModel>;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  userRoleEnum = Enums.UserRoleEnum;
  clientStatusEnum = Enums.ClientStatusEnum;

  constructor(private superUserService: SuperUserService, private breadcrumbs: BreadcrumbsService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Users', 'users', []);
    this.users = new ServerSortableList<UserSearchResultViewModel>([], 'Credential.FullName', false);
    this.searchUsers();

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchUsers();
    });
  }

  searchUsers() {
    this.searching = true;
    let model = new UserSearchParams();
    model.searchText = this.filter;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;

    this.superUserService.searchAllUsers(model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.users.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  pageChanged(event: any): void {
    this.searchUsers();
  }

  onImpersonateUser(userId: number) {
    this.superUserService.impersonateUser(userId).subscribe(result => {
      this.auth.impersonateUser(result);
    });
  }
}

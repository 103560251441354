<page-load-header *ngIf="!initialized"></page-load-header>

<page-load-header *ngIf="searching"></page-load-header>

<div *ngIf="initialized">
  <div class="row no-gutters mb-2">
    <div class="col">
      <h3 class="mt-1 mb-3">Athlete Journal</h3>
      <div>A combined view of general journals, workout journals, and daily statuses</div>
    </div>
    <div class="float-right">
      <button type="button" class="btn btn-primary" (click)="addJournal()">Add Entry</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-12">
              <div class="float-left">
                <div>
                  <kendo-daterange class="d-block">
                    <label>
                      <span class="form-control-label d-block">Start</span>
                      <kendo-dateinput
                        kendoDateRangeStartInput
                        autoCorrectOn="change"
                        [(ngModel)]="startDate"
                        (valueChange)="onDateChange($event)"
                      ></kendo-dateinput>
                    </label>
                    <label>
                      <span class="form-control-label d-block">End</span>
                      <kendo-dateinput
                        kendoDateRangeEndInput
                        autoCorrectOn="change"
                        [(ngModel)]="endDate"
                        (valueChange)="onDateChange($event)"
                      ></kendo-dateinput>
                    </label>
                    <kendo-daterange-popup
                      [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
                      [popupAlign]="{ horizontal: 'left', vertical: 'top' }"></kendo-daterange-popup>
                  </kendo-daterange>
                  <div *ngIf="dateError" class="invalid-message-visible">
                    The date range cannot be more than 4 months.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-xl-2 col-md-4">
              <div>
                <label class="form-control-label mr-2"> Journal Type: </label>
                <select [(ngModel)]="journalTypeFilter" class="form-control" (ngModelChange)="onJournalTypeChange($event)">
                  <option [ngValue]="null">All</option>
                  <ng-container *ngFor="let mapEntry of journalTypeEnum | keyvalue">
                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-md-8">
              <div>
                <label class="form-control-label"> &nbsp; </label>
              <kendo-textbox class="form-control" placeholder="Search by text" [(ngModel)]="textFilter" (valueChange)="onTextFilterChanged($event)" [clearButton]="true"></kendo-textbox>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!searching && !dateError" class="card-body">
          <div *ngIf="journals.length == 0">
            No results found for the selected filters
          </div>
          <div *ngFor="let journal of journals" class="message mb-1" [ngClass]="{'coach-message': !journal.isFromUserCredentialId}">
            <div class="message-header">
              <div class="font-weight-bold">{{journal.journalType | enumString: journalTypeEnum}}</div>
              <div *ngIf="journal.journalType == journalType.Workout">
                <span class="font-weight-bold"><a href="javascript:void(0)" (click)="onEventInfoClicked(journal.clientProgramWorkoutDayId)">{{journal.workoutName}}</a></span> on {{journal.workoutDate | date}}
              </div>
              {{journal.noteDate | date}} from {{journal.fromUserFirstName}} {{journal.fromUserLastName}}:
            </div>
            
            <div *ngIf="journal.journalType == journalType.DailyStatus" class="mb-1">
              <table class="table table-sm w-auto align-items-center">
                <thead class="table-bordered">
                  <tr>
                    <th>Run</th>
                    <th>Pain</th>
                    <th>Sick</th>
                    <th>Max Effort</th>
                  </tr>
                </thead>
          
                <tbody class="table-bordered">
                  <tr>
                    <td class="journal-icon" *ngIf="journal.dailyStatusAbleToRun"><i class="far fa-smile text-success"></i></td>
                    <td class="journal-icon" *ngIf="!journal.dailyStatusAbleToRun"><i class="far fa-frown text-danger"></i></td>
                    <td class="journal-icon" *ngIf="!journal.dailyStatusHasPain"><i class="far fa-smile text-success"></i></td>
                    <td class="journal-icon" *ngIf="journal.dailyStatusHasPain"><i class="far fa-frown text-danger"></i></td>
                    <td class="journal-icon" *ngIf="!journal.dailyStatusIsSick"><i class="far fa-smile text-success"></i></td>
                    <td class="journal-icon" *ngIf="journal.dailyStatusIsSick"><i class="far fa-frown text-danger"></i></td>
                    <td class="">{{journal.dailyStatusPercentEffort}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div [innerHTML]="journal.note"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

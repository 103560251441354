import { Component, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-image-upload-modal',
  templateUrl: 'image-upload-modal.component.html',
  styleUrls: ['image-upload-modal.component.scss']
})
export class ImageUploadModalComponent implements AfterViewInit {
  imageChangedEvent: any = '';
  modalImage: any;
  openDialogValue: boolean;
  @ViewChild('content', { static: false }) private content;

  @Input()
  initialCroppedImage: any;

  @Input()
  get openDialog() {
      return this.openDialogValue;
  }

  set openDialog(val) {
      this.openDialogValue = val;
      this.openDialogChange.emit(this.openDialogValue);
  }

  @Output()
  openDialogChange = new EventEmitter<boolean>();

  @Output()
  changedImage = new EventEmitter<any>();

  constructor(private modalService: NgbModal) { }

  ngAfterViewInit(): void {
    this.editImage(this.content);
  }

  editImage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then(
      (result) => {
      },
      (reason) => {
        if (reason === 'saved') {
          this.changedImage.emit(this.modalImage);
        }
        this.initialCroppedImage = null;
        this.openDialog = false;
      }
    );
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.modalImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

}

<div *ngIf="initialized">
  <div class="form-group">
    <label class="form-control-label"> {{label}} </label>
    <div class="input-group no-border mb-3">
      <div class="avatar-lg mr-3 image-placeholder" [ngClass]="{'no-image': !croppedImage}">
        {{!croppedImage ? 'no image' : ''}}
        <img *ngIf="croppedImage" class="img-fluid" [src]="croppedImage" alt="Image Description" />
      </div>
      <div class="d-inline">
        <button type="button" (click)="editImage()" class="btn btn-sm btn-primary transition-3d-hover mb-1 mr-2 mb-sm-0">Upload New Picture</button>
        <button type="button" (click)="deleteImage()" class="btn btn-sm btn-soft-secondary transition-3d-hover mb-1 mb-sm-0">Delete</button>
      </div>
    </div>
  </div>
  <div *ngIf="percentComplete >= 0" class="mb-0">
    <div class="alert alert-danger">Uploading: Please do not close this window</div>
    <div class="d-flex align-items-center pb-3">
      <div *ngIf="percentComplete >= 0" class="progress mt-4" style="height: 20px; width: 100%">
        <div class="progress-bar bg-alternate" role="progressbar" [ngStyle]="{ width: percentComplete + '%' }" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{ percentComplete }}%</div>
      </div>
    </div>
  </div>
</div>

<app-image-upload-modal *ngIf="showImageUpload" (changedImage)="onImageChanged($event)" [(openDialog)]="showImageUpload" [initialCroppedImage]="initialCroppedImage"></app-image-upload-modal>

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/_services/toaster.service';
import { UserService } from 'src/app/_services/generatedServices';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UpdateProfileViewModel, RunningExperience, LevelOfRunning, UserProfileViewModel, TeamUserRole, TeamUserViewModel, MarketplaceStatus, AthleteType, MeasurementSystem } from 'src/app/_models/generatedModels';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { Observable, forkJoin, of, timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { coachUserRole } from 'src/app/_models/models';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'rundna-user-profile',
  templateUrl: 'user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  initialized = false;
  submitComplete: Promise<{}> | undefined;
  public formGroup: FormGroup;
  user: UserProfileViewModel;
  showCoachBio: boolean = false;
  athleteType = AthleteType;
  isMarketplaceAthlete: boolean = false;

  croppedImage: any = '';
  initialCroppedImage: string;
  showImageUpload: boolean = false;
  measurementSystemEnum = Enums.MeasurementSystemEnum;
  isMetricSystem: boolean = false;

  @Input()
  userId: number;

  @Input()
  isCoachView: boolean;

  @Input()
  isSelf: boolean;

  constructor(public auth: AuthenticationService, private route: ActivatedRoute, private router: Router, private toastr: ToasterService, private userService: UserService,
    private fb: FormBuilder, private validatorHelper: ValidatorHelper, private modalService: NgbModal) {}

  ngOnInit(): void {
    forkJoin([this.userService.getProfileById(this.userId), this.auth.fetchUserProfile()]).subscribe((results) => {
      this.user = results[0];
      this.isMetricSystem = this.user.measurementSystem == MeasurementSystem.Metric;
      
      if (this.isSelf && (results[1].organizationMarketplaceStatus & MarketplaceStatus.Active) != 0 && (this.user.userRole & coachUserRole) != 0) {
        this.showCoachBio = true;
      }
      this.isMarketplaceAthlete = (results[1].organizationMarketplaceStatus & MarketplaceStatus.Active) != 0 && (this.user.athleteType & this.athleteType.Marketplace) != 0;

      if (this.user.avatar) {
        this.userService.getImageAsBase64(this.userId).subscribe((image) => {
          this.croppedImage = image.value;
          this.initialCroppedImage = image.value;
          this.setupForm();
        });
      } else {
        this.setupForm();
      }
    });
  }

  setupForm(): any {
    let weight = this.user.weightInPounds;
    let height = this.user.heightInInches;
    if (this.isMetricSystem) {
      weight = weight ? Math.round(weight * 0.453592) : null;
      height = height ? Math.round(height * 2.54) : null;
    }

    this.formGroup = this.fb.group({
      firstName: [this.user.firstName, [Validators.required]],
      yearOfBirth: [this.user.yearOfBirth],
      gender: [this.user.gender, !this.isCoachView ? Validators.required : null],
      lastName: [this.user.lastName, [Validators.required]],
      email: [{value: this.user.email, disabled: (!this.isSelf && this.user.hasPassword)}, [Validators.required, Validators.email], [this.validatorHelper.validateEmailNotUsed(this.user.email)]],
      phoneNumber: [this.user.phoneNumber],
      weightInPounds: [weight, [Validators.pattern(/^[0-9]*$/)]],
      heightInInches: [height],
      yearsOfExperience: [RunningExperience[this.user.yearsOfExperience]],
      highestLevelOfRunning: [LevelOfRunning[this.user.highestLevelOfRunning]],
      coachUserId: [{value: this.user.coachUserId, disabled:!this.isCoachView}],
      teamIds: [this.user.teams ? this.user.teams.filter(x => x.teamUserRole & TeamUserRole.Runner).map(x => x.teamId) : []],
      biography: [this.user.biography],
      isCoachedAthlete: [(this.user.athleteType & this.athleteType.Coached) != 0 ? true : false],
      maxHeartRate: [this.user.maxHeartRate],
      cyclingFunctionalThresholdPower: [this.user.cyclingFunctionalThresholdPower],
      measurementSystem: [this.user.measurementSystem],
    });

    this.initialized = true;
  }

  editImage() {
    this.showImageUpload = true;
  }

  onImageChanged(image: any) {
    this.croppedImage = image;
  }

  onMeasurementSystemChanged() {
    this.isMetricSystem = this.formGroup.get('measurementSystem').value == MeasurementSystem.Metric;
    if (this.isMetricSystem) {
      this.formGroup.get('weightInPounds').setValue(this.formGroup.get('weightInPounds').value ? Math.round(this.formGroup.get('weightInPounds').value * 0.453592) : null);
      this.formGroup.get('heightInInches').setValue(this.formGroup.get('heightInInches').value ? Math.round(this.formGroup.get('heightInInches').value * 2.54) : null);
    } else {
      this.formGroup.get('weightInPounds').setValue(this.formGroup.get('weightInPounds').value ? Math.round(this.formGroup.get('weightInPounds').value / 0.453592) : null);
      this.formGroup.get('heightInInches').setValue(this.formGroup.get('heightInInches').value ? Math.round(this.formGroup.get('heightInInches').value / 2.54) : null);
    }
  }

  deleteImage() {

    this.toastr.confirmDialog('Are you sure you want to delete this image?', 'Delete Image', 'Delete Image', 'Cancel').subscribe(result => {
      if (result) {
        this.croppedImage = null;
      }
    });
  }

  navigateBack() {
    this.router.navigate(['.'], { relativeTo: this.route.parent });
  }

  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      var updatedAthleteTeamIds = this.formGroup.get('teamIds').value as number[];
      var updatedTeams: TeamUserViewModel[] = JSON.parse(JSON.stringify(Object.assign([], this.user.teams.map(x => x as TeamUserViewModel))));
      updatedTeams = updatedTeams.concat(updatedAthleteTeamIds.filter(x => this.user.teams.map(y => y.teamId).indexOf(x) == -1).map(x => {return { teamId: x, userId: this.userId, teamUserRole: TeamUserRole.Runner } as TeamUserViewModel}));
      updatedTeams.forEach(team => {
        if (updatedAthleteTeamIds.indexOf(team.teamId) != -1) {
          team.teamUserRole = team.teamUserRole | TeamUserRole.Runner;
        } else {
          team.teamUserRole = team.teamUserRole & ~TeamUserRole.Runner;
        }
      });
      updatedTeams.removeRange(x => x.teamUserRole == TeamUserRole.None);


      const formData: UpdateProfileViewModel = Object.assign({}, this.user, this.formGroup.getRawValue());
      formData.teams = updatedTeams;
      
      if (this.formGroup.get('isCoachedAthlete').value) {
        formData.athleteType = formData.athleteType | this.athleteType.Coached;
      } else {
        formData.athleteType = formData.athleteType &~ this.athleteType.Coached;
      }

      if (this.initialCroppedImage !== this.croppedImage) {
        formData.base64Image = this.croppedImage;
      } else {
        formData.base64Image = null;
      }
      if (this.croppedImage === null) {
        formData.avatar = null;
      }

      if (this.isMetricSystem) {
        formData.heightInInches = Math.round(formData.heightInInches / 2.54);
        formData.weightInPounds = Math.round(formData.weightInPounds / 0.453592);
      }

      this.update(formData, resetButton);
    });
  }

  update(formData: UpdateProfileViewModel, resetButton: () => any) {
    this.userService.updateProfile(this.userId, formData).subscribe(
      (user) => {
        this.user = user;
        this.auth.updateMeasurementSystem(user.measurementSystem);
        //this.navigateBack();
        this.toastr.success('Profile Updated', 'Success');
        resetButton();
      },
      (error) => resetButton()
    );
  }

  onChangePassword() {
    const modalRef = this.modalService.open(ChangePasswordDialogComponent, { size: 'lg' });
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { MarketplaceStatus, OrganizationType, ProgramKeywordViewModelRead, ProgramListViewModelRead, ProgramSearchParams } from 'src/app/_models/generatedModels';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';

@Component({
  selector: 'bxl-programs',
  templateUrl: 'programs.component.html',
})
export class ProgramsComponent implements OnInit {

  initialized = false;
  programs: ServerSortableList<ProgramListViewModelRead>;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  editUrl: string = '../programs/edit';
  previewUrl: string = '../programs/view';
  openAssignProgramDialog: boolean = false;
  openAssignTeamProgramDialog: boolean = false;
  currentProgramId: number;
  currentProgramName: string;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  marketplaceStatus = MarketplaceStatus;
  hasMarketplace: boolean = false;
  canEditGlobalLibrary: boolean = false;

  programKeywordsInitialized: boolean = false;
  programKeywords: ProgramKeywordViewModelRead[];
  programKeywordFilter: number;
  coachFilter: number = null;

  constructor(private programService: ProgramsService, public auth: AuthenticationService, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Programs', 'programs', []);
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
    this.programs = new ServerSortableList<ProgramListViewModelRead>([], 'Name', false);
    this.searchPrograms();
    this.loadProgramKeywords();

    this.auth.fetchUserProfile().subscribe(user => {
      if (user.organizationMarketplaceStatus & this.marketplaceStatus.Active) {
        this.hasMarketplace = true;
      }
    });

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchPrograms();
    });

    this.programs.sortChanged.subscribe(result => {
      this.currentPage = 1;
      this.searchPrograms();
    });
  }

  searchPrograms() {
    this.searching = true;
    let model = new ProgramSearchParams();
    model.searchText = this.filter;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.programs.column;
    model.isSortDesc = this.programs.isDesc;
    model.programKeywordId = this.programKeywordFilter || null;
    model.coachId = this.coachFilter;

    this.programService.searchForPrograms(model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.programs.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  loadProgramKeywords() {
    this.programService.getProgramKeywordsForOrganization().subscribe(result => {
      this.programKeywords = result;
      this.programKeywordsInitialized = true;
    });
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  onKeywordFilterChange(event: any) {
    this.currentPage = 1;
    this.searchPrograms();
  }

  onCoachFilterChange(event: any) {
    this.currentPage = 1;
    this.searchPrograms();
  }

  pageChanged(event: any): void {
    this.searchPrograms();
  }

  onDelete(programId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this program? This action cannot be undone.', 'Delete Program', 'Delete Program', 'Cancel').subscribe(result => {
      if (result) {
        this.programService.deleteProgram(programId).subscribe(result => {
          this.programs.remove(x => x.id === programId);
          this.toastr.success('Program Deleted', 'Success');
        });
      }
    });
  }

  onAssign(programId: number, programName: string) {
    this.currentProgramId = programId;
    this.currentProgramName = programName;
    this.openAssignProgramDialog = true;
  }

  onAssignToTeam(programId: number, programName: string) {
    this.currentProgramId = programId;
    this.currentProgramName = programName;
    this.openAssignTeamProgramDialog = true;
  }

  onClone(programId: number) {
    this.programService.cloneProgram(this.auth.user.id, programId).subscribe(result => {
      this.toastr.success('Program Cloned', 'Success');
      this.router.navigate([this.editUrl, result.id], { relativeTo: this.route });
    });
  }
}

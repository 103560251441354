import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-view-assessment',
  templateUrl: 'view-assessment.component.html',
})
export class ViewAssessmentComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }

}

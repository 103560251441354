import { Injectable } from '@angular/core';



@Injectable()
export class BreadcrumbsService {

    public Breadcrumbs: PRouteLink[] = [];

    constructor() { }

    SetBreadcrumb(text: string, route: string, params: { [k: string]: any; }) {
        this.ClearBreadcrumb();
        this.AppendBreadcrumb(text, route, params);
    }

    SetSecondaryBreadcrumb(text: string, route: string, params: { [k: string]: any; }) {
      this.ClearSecondaryBreadcrumbs();
      this.AppendBreadcrumb(text, route, params);
    }

    AppendBreadcrumb(text: string, route: string, params: { [k: string]: any; }) {
      const rl = new PRouteLink(text, route, params);
      this.Breadcrumbs.push(rl);
  }

    ClearBreadcrumb() {
      this.Breadcrumbs = [];
  }

  ClearSecondaryBreadcrumbs() {
    this.Breadcrumbs = this.Breadcrumbs.length > 0 ? [this.Breadcrumbs[0]] : [];
  }

}

export class PRouteLink {

    public text: string;
    public route: string;
    params: {
        [k: string]: any;
    }

    constructor(text: string, route: string, params: { [k: string]: any }) {
        this.text = text;
        this.route = route;
        this.params = params;
    }

}

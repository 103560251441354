<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="programs.length === 0" [ngClass]="{ 'text-white': useCards }" class="container mt-3 mb-2">No programs assigned</div>
  <div  *ngFor="let program of programs" class="container mt-3">
    <div [ngClass]="{ 'card': useCards }" class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <ng-container>
              <a style="vertical-align: text-bottom;" href="javascript:void(0)" (click)="deleteProgram(program.id)">
                <i class="mr-2 fas fa-trash"> </i>
              </a>
              <h5 class="d-inline">{{program.name}}</h5>
              <div *ngIf="!program.deactivated && program.refProgramId && (!program.isPaidProgram || (program.isPaidProgram && (program.createdDate | date: 'yMMdd') > (aYearAgo | date: 'yMMdd')))" class="mt--1">
                <a  href="javascript:void(0)" class="small-link" (click)="rescheduleProgram(program)"> Reschedule </a>
                <div class="d-inline text-center small-link" kendoTooltip>
                  <i class="fas fa-info-circle ml-1" title="Fallen behind in the program? You can reschedule the program and select the week where you want to pick back up."></i>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12">starting {{program.startDate | date}}
          </div>
          <div class="col-md-12 mt-1">
            <ngb-progressbar class="dark-text mb-1" type="success" height="15px" [value]="program.actualWorkoutProgressPercentage">Actual: {{program.actualWorkoutProgressPercentage}}%</ngb-progressbar>
            <ngb-progressbar class="dark-text" type="primary" height="15px" [value]="program.expectedWorkoutProgressPercentage">Expected: {{program.expectedWorkoutProgressPercentage}}%</ngb-progressbar>
          </div>

          <div *ngIf="!summaryOnly" class="col-md-12 mt-2">
            <div *ngIf="program.videoLink">
              <embed-video-player [url]="program.videoLink" [size]="'small'"></embed-video-player>
            </div>
            <div *ngIf="program.videoId">
              <embed-video-player [videoId]="program.videoId" [size]="'small'"></embed-video-player>
            </div>
            <div class="font-weight-bold">Description:</div>
            <div [ngClass]="{'collapsed-description': program.id !== currentProgramId}">
              <span [editorHTML]="program.description"></span>
            </div>
          </div>
          <a *ngIf="!summaryOnly" class="small-link ml-2" (click)="onShowDetails(program.id)" href="javascript:void(0)">
            {{ program.id === currentProgramId ? 'Less...' : 'More...'}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<page-load-header *ngIf="!initialized"></page-load-header>

<div class="row" [ngClass]="(workoutItem.workoutItemType == workoutItemType.Circuit || workoutItem.workoutItemType == workoutItemType.Interval || workoutItem.workoutItemType == workoutItemType.GarminWorkoutRepeatStep) ? 'interval' : ''" *ngIf="initialized">

  <div class="col-md-12 col-lg-9" [ngClass]="(workoutItem.workoutItemType == workoutItemType.Circuit || workoutItem.workoutItemType == workoutItemType.Interval || workoutItem.workoutItemType == workoutItemType.GarminWorkoutRepeatStep) ? 'interval-item' : ''">
    <span *ngIf="(workoutItem.workoutItemType == workoutItemType.Circuit || workoutItem.workoutItemType == workoutItemType.Interval || workoutItem.workoutItemType == workoutItemType.GarminWorkoutRepeatStep)">{{detail.sortOrder}}. </span>
    <span *ngIf="detail.cardioType == cardioType.Recovery">(Recovery) </span>  
    <span class="workout-item-text">{{detail.text}}</span>
    <span *ngIf="!isWorkoutPreview && (detail.exerciseLevelDownId || detail.exerciseLevelUpId)" class="d-block d-md-inline">
      <a *ngIf="detail.exerciseLevelDownId" class="ml-1 level-change" href="javascript:void(0)" (click)="levelChange('down', detail.exerciseLevelDownId, detail.defaultExerciseId, detail.id)" title="Level Down"><i class="fas fa-arrow-circle-down"></i></a>
      <a *ngIf="detail.exerciseLevelUpId" class="ml-1 level-change" href="javascript:void(0)" (click)="levelChange('up', detail.exerciseLevelUpId, detail.defaultExerciseId, detail.id)" title="Level Up"><i class="fas fa-arrow-circle-up"></i></a>
    <div class="d-inline text-center" style="vertical-align: top;" kendoTooltip>
      <i class="fas fa-info-circle ml-2" title="Select Down to make this exercise in the workout easier, or select Up to make this exercise more challenging. This selection will be used for all occurrences of this workout, until changed."></i>
    </div>
    </span>
    <span *ngIf="isWorkoutPreview && detail.inExerciseProgression" class="d-block d-md-inline">
      <i class="fas fa-sort-alt ml-1"></i>
    </span>
  </div>

  <div class="col-md-12 col-lg-3" *ngIf="(workoutItem.workoutItemType != workoutItemType.Circuit && workoutItem.workoutItemType != workoutItemType.Interval && workoutItem.workoutItemType != workoutItemType.GarminWorkoutRepeatStep) && !isWorkoutPreview">
    <label class="toggle-label">
      Done
    </label>
    <kendo-switch [disabled]="disabled" [(ngModel)]="workoutItem.isCompleted" (valueChange)="toggleComplete($event)"></kendo-switch>
  </div>
  <div class="col-md-12 details">
    {{detail.details}} 
    <a *ngIf="detail.vdotChartLink" href="{{detail.vdotChartLink}}" target="_blank">(VDOT Chart)</a>
    <a *ngIf="detail.cardioIntensityType == cardioIntensityType.RateOfPerceivedExertion" href="javascript:void(0)" (click)="onIntensityChart(detail.cardioIntensityType, detail.garminWorkoutStepTargetType)">(RPE Chart)</a>
    <a *ngIf="detail.garminWorkoutStepTargetType == garminWorkoutStepTargetType.HEART_RATE_ZONE || detail.garminWorkoutStepTargetType == garminWorkoutStepTargetType.POWER_ZONE" href="javascript:void(0)" (click)="onIntensityChart(detail.cardioIntensityType, detail.garminWorkoutStepTargetType)">(Zone Chart)</a>
  </div>
  <div class="col-md-12 details" *ngIf="detail.notes">
    {{detail.notes}}
  </div>

  <ng-container *ngIf="isPDF && detail.videoLink && detail.videoType == 2">
    <div class="col-md-12">
      <div>Video: {{detail.videoLink}}</div>
      <div class="text-left">
        <ngx-qrcode 
          [elementType]="'url'" 
          [value]="detail.videoLink"
          errorCorrectionLevel="L">
        </ngx-qrcode>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isPDF && (detail.videoType == 1 || (detail.videoLink && detail.videoType == 2))">
    <div class="col-md-12 mt-1" *ngIf="detail.videoLink && detail.videoType == 2">
      <embed-video-player [url]="detail.videoLink" [size]="'small'"></embed-video-player>
    </div>
    <div class="col-md-12 mt-1" *ngIf="detail.videoType == 1">
      <embed-video-player [videoId]="detail.videoId"></embed-video-player>
    </div>
  </ng-container>
  <div *ngIf="!isPDF && !summaryOnly && detail.instructions" class="col-md-12">
    <a (click)="toggleDetails(detail.id)" href="javascript:void(0)" class="toggle-link">{{ detail.id == currentDetailsId ? 'Hide' : 'View' }} Instructions</a>
  </div>
  <ng-container *ngIf="detail.id == currentDetailsId">
    <div *ngIf="detail.instructions" class="font-weight-bold col-md-12">
      Instructions:
    </div>
    <div class="col-md-12">
      <span [editorHTML]="detail.instructions"></span>
    </div>
  </ng-container>

  

</div>

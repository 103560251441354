import { Component, OnInit } from '@angular/core';
import { AssessmentTooltipViewModelRead } from 'src/app/_models/generatedModels';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-assessment-tooltip-list',
  templateUrl: 'assessment-tooltip-list.component.html',
})
export class AssessmentTooltipListComponent implements OnInit {

  initialized = false;
  tooltips: AssessmentTooltipViewModelRead[];
  editUrl: string = '../assessmentTooltips/edit';

  constructor(private assessmentService: AssessmentService, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Asessment Tooltips', 'assessmentTooltips', []);
    this.loadAssessments();
  }

  loadAssessments() {
    this.assessmentService.getAssessmentTooltips().subscribe(results => {
      this.tooltips = results;
      this.initialized = true;
    });
  }
}

<div *ngIf="initialized">
  <div>
      <kendo-dialog title="Assign Program to Team" *ngIf="openAssignProgram" (close)="closeAssignProgramDialog('cancel')" [minWidth]="250" [width]="350">
          <form [formGroup]="formGroup" novalidate>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="programName">
                      Program:
                    </label>
                    {{programName}}
                    <div class="invalid-message">
                      <div>Program is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="teamId">
                      Team
                    </label>
                    <team-dropdown formControlName="teamId"></team-dropdown>
                    <div class="invalid-message">
                      <div>Team is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="form-control-label" for="startDate">
                      First Day of Program
                    </label>
                    <custom-recent-datepicker placeholder="" class="form-control" formControlName="startDate"></custom-recent-datepicker>
                    <div class="invalid-message">
                      <div>First Day is required</div>
                    </div>
                  </div>
                </div>
              </div>
          </form>
          <kendo-dialog-actions class="k-button-group k-dialog-buttongroup k-dialog-button-layout-stretched">
              <button kendoButton (click)="closeAssignProgramDialog('cancel')" class="k-button">Cancel</button>
              <button kendoButton (click)="closeAssignProgramDialog('save')" primary="true" class="k-button k-primary">Assign Program</button>
          </kendo-dialog-actions>
      </kendo-dialog>
  </div>

</div>

// #docregion
import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Enums } from '../_models/generatedEnums';
import { HoursMinutesSeconds, MinutesSeconds, BasicObject, KILOMETERS_PER_MILE } from '../_models/models';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import moment from 'moment';
import { ActivityType, ClientDayEventType, MeasurementSystem, WorkoutType } from '../_models/generatedModels';
import { Observable, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Pipe({ name: 'duration', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class DurationPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }

    let val = input.split(':');
    let hours = parseInt(val[0]);
    let min = parseInt(val[1]);
    let totalMins = hours * 60 + min;

    if (totalMins < 60) {
      return totalMins + ' minutes';
    }

    if (totalMins === 60) {
      return '1 hour';
    }

    if (hours > 1 && min === 0) {
      return hours + ' hours';
    }

    if (hours > 1 && min > 0) {
      return hours + ' hours, ' + min + ' mins';
    }

    if (hours > 0 && min > 0) {
      return hours + ' hour, ' + min + ' mins';
    }
  }
}

@Pipe({ name: 'durationClean', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class DurationCleanPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }

    let val = input.split(':');
    let hours = parseInt(val[0]);
    let min = parseInt(val[1]);
    let sec = parseInt(val[2]);

    let returnVal = '';

    if (hours === 0 && min === 0 && sec > 0) {
      return `${sec}`;
    }

    if (hours === 0 && min > 0) {
      return `${min}:${sec}`;
    }

    return `${hours}:${min}:${sec}`;
  }
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (value == null) {
      return value;
    }
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    if (value.substr(0, limit).length < value.length) {
      return `${value.substr(0, limit)}${ellipsis}`;
    } else {
      return `${value.substr(0, limit)}`;
    }
  }
}

@Pipe({ name: 'titlecase', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class TitleCasePipe implements PipeTransform {
  transform(input: string): string {
    return !input || input.length === 0 ? '' : input.replace(/\w\S*/g, txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase());
  }
}

@Pipe({ name: 'pluralizer', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class PluralizerPipe implements PipeTransform {
  transform(input: number, unpluralValue: string, pluralValue: string): string {
    return input + ' ' + (input === 1 ? unpluralValue : pluralValue);
  }
}

@Pipe({ name: 'stripHtml', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class StripHtmlPipe implements PipeTransform {
  transform(html: any): string {
    return html.replace(/<[^>]*>/g, '').replace('&nbsp;', ' ');
  }
}

@Pipe({ name: 'camelCaseToSpaces', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }
    let result = input.replace(/[A-Z]/g, txt => ' ' + txt);
    return result.length === 1 ? result[0].toUpperCase() : result[0].toUpperCase() + result.substr(1);
  }
}

@Pipe({ name: 'booleanToText', pure: false })
/** Transform nullable boolean value to text.*/
export class BooleanToTextPipe implements PipeTransform {
  transform(input: boolean | null): string {
    if (input == null || input === undefined) {
      return '';
    }
    return input ? 'True' : 'False';
  }
}

@Pipe({ name: 'booleanToYesNo', pure: false })
/** Transform nullable boolean value to yes / no.*/
export class BooleanToYesNoPipe implements PipeTransform {
  transform(input: boolean | null): string {
    if (input == null || input === undefined) {
      return '';
    }
    return input ? 'Yes' : 'No';
  }
}

@Pipe({ name: 'enumValue', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class EnumValuePipeTransform implements PipeTransform {
  transform(enumVal: any, theEnum: any, description: any): string {
    let result = description.get(parseInt(theEnum[enumVal]));
    return result;
  }
}

@Pipe({ name: 'noDataDisplay', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class NoDataDisplayPipe implements PipeTransform {
  transform(input: any): string {
    if (!input) {
      return 'No Data';
    }
    return input;
  }
}

@Pipe({ name: 'enumString', pure: true })
/** Get string value of enum.*/
export class EnumStringPipe implements PipeTransform {
  transform(enumVal: any, theEnum: any): string {
    if (enumVal === null || enumVal === undefined) {
      return '';
    }
    return theEnum.get(enumVal);
  }
}

@Pipe({ name: 'flagEnumString', pure: true })
/** Get string value of enum.*/
export class FlagEnumStringPipe implements PipeTransform {
  transform(enumVal: any, theEnum: any): string {
    if (enumVal === null || enumVal === undefined) {
      return '';
    }

    let values: string[] = [];
    theEnum.forEach((value, key) => {
      if (enumVal & key) {
        values.push(value);
      }
    });
    return values.join(', ');
  }
}

@Pipe({ name: 'secsToHourMinSec', pure: true })
/** Convert seconds to hours, minutes, seconds string*/
export class SecsToHourMinSecPipe implements PipeTransform {
  transform(secondsVal: any): string {
    if (secondsVal) {
      const minAndSec = secondsToHoursMinutesSeconds(secondsVal);
      return minAndSec.hours.toString().padStart(2, '0') + ':' + minAndSec.minutes.toString().padStart(2, '0') + ':' + minAndSec.seconds.toString().padStart(2, '0');
    }
    return '00:00:00';
  }
}

@Pipe({ name: 'secsToHourMinOnly', pure: true })
/** Convert seconds to hours, minutes - truncates seconds, no rounding*/
export class SecsToHourMinOnlyPipe implements PipeTransform {
  transform(secondsVal: any): string {
    if (secondsVal) {
      const minAndSec = secondsToHoursMinutesSeconds(secondsVal);
      return minAndSec.hours.toString().padStart(1, '0') + ':' + minAndSec.minutes.toString().padStart(2, '0') + (minAndSec.hours > 0 ? 'h' : 'm');
    }
    return '0:00m';
  }
}

@Pipe({ name: 'secsToMinSecOnly', pure: true })
/** Convert seconds to minutes, seconds*/
export class SecsToMinSecOnlyPipe implements PipeTransform {
  transform(secondsVal: any): string {
    if (secondsVal) {
      const minAndSec = secondsToHoursMinutesSeconds(secondsVal);
      return ((minAndSec.hours * 60) + minAndSec.minutes).toString().padStart(2, '0') + ':' + minAndSec.seconds.toString().padStart(2, '0');
    }
    return '00:00';
  }
}

@Pipe({ name: 'minutesDecimalToString', pure: true })
/** Convert seconds to hours, minutes, seconds string*/
export class MinutesDecimalToStringPipe implements PipeTransform {
  transform(minutesDecimal: any): string {
    if (minutesDecimal) {
      let minutes = Math.floor(minutesDecimal);
      const secondsDecimal = minutesDecimal % 1;
      let seconds = secondsDecimal * 60;
      if (seconds.toFixed(0) == '60') {
        minutes = minutes + 1;
        seconds = 0;
      }
      return minutes.toString() + ':' + seconds.toFixed(0).toString().padStart(2, '0');
    }
    return '0:00';
  }
}

@Pipe({ name: 'singular', pure: true })
/** Intended to remove the last character in a string*/
export class SingularPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.substring(0, value.length - 1);
    }
    return null;
  }
}

@Pipe({ name: 'completionPercentRangeColor', pure: true })
/** returns the color associated with the percent range */
export class CompletionPercentRangeColorPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      if (value >= 80) {
        return 'text-success';
      } else if (value >= 60) {
        return 'text-warning';
      } else {
        return 'text-danger';
      }
    }
    return 'text-dark';
  }
}

@Pipe({ name: 'acuteChronicRatioColor', pure: true })
/** returns the color associated with the percent range */
export class AcuteChronicRatioColorPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      if (value >= .90 && value < 1.20) {
        return 'text-success';
      } else if ((value >= .80 && value < .90) || (value >= 1.20 && value <= 1.30)) {
        return 'text-warning';
      } else {
        return 'text-danger';
      }
    }
    return 'text-danger';
  }
}

@Pipe({ name: 'acuteChronicRatioText', pure: true })
/** returns the text associated with the percent range */
export class AcuteChronicRatioTextPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      if (value > 1.30) {
        return "Training load is progressing rapidly, take note of how you're feeling";
      }
      else if (value >= 1.20 && value <= 1.30) {
        return 'Training load is progressing moderately';
      }
      else if (value >= .90 && value < 1.20) {
        return 'Training load is progressing normally';
      } else if (value >= .80 && value < .90) {
        return 'Training load is reducing moderately';
      } else if (value < 80) {
        return 'Training load is reducing rapidly';
      }
    }
    return 'Training load is reducing rapidly';
  }
}

@Pipe({ name: 'customCalendarEventIcon', pure: true })
/** returns the icon associated with the event details or activity type*/
export class CustomCalendarEventIconPipe implements PipeTransform {
  transform(activityType: ActivityType | null, eventType: ClientDayEventType | null, workoutType: WorkoutType | null): string {
    let iconClass: string = null;

    if (activityType != null) {
      switch(activityType) {
        case ActivityType.Biking: {
          iconClass = 'fa-biking';
          break;
        }
        case ActivityType.Other: {
          iconClass = 'fa-circle';
          break;
        }
        case ActivityType.Running: {
          iconClass = 'fa-running';
          break;
        }
        case ActivityType.StrengthTraining: {
          iconClass = 'fa-dumbbell';
          break;
        }
        case ActivityType.Swimming: {
          iconClass = 'fa-swimmer';
          break;
        }
        case ActivityType.Walking: {
          iconClass = 'fa-shoe-prints';
          break;
        }
      }
    } else if (workoutType != null && workoutType == WorkoutType.Cardio) {
      iconClass = 'fa-heart';
    } else if (eventType != null) {
      switch(eventType) {
        case ClientDayEventType.Event: {
          iconClass = 'fa-flag-checkered';
          break;
        }
        case ClientDayEventType.RestDay: {
          iconClass = 'fa-bed';
          break;
        }
        case ClientDayEventType.Task: {
          iconClass = 'fa-list-alt';
          break;
        }
      }
    }

    return iconClass;

  }
}

@Pipe({ name: 'milesKilometersToMiles', pure: true })
/** Combines miles and kilometers to total miles **/
export class MilesKilometersToMilesPipe implements PipeTransform {
  transform(miles: number | null | undefined, kilometers: number | null | undefined): number | null {
    if (!miles && !kilometers) {
      return null;
    }

    return parseFloat(((miles ?? 0) + ((kilometers ?? 0) * KILOMETERS_PER_MILE)).toFixed(4));
  }
}

@Pipe({ name: 'flagEnumCompare', pure: true })
export class flagEnumComparePipe implements PipeTransform {
  transform(value: any, compareValue: any): boolean {
    return (value & compareValue) == compareValue;
  }
}

@Pipe({ name: 'fromMilesToLocalMeasurement', pure: true })
export class FromMilesToLocalMeasurementPipe implements PipeTransform {
  transform(value: number): number {
    let localMeasurementSystem = localStorage.getItem(MeasurementSystemStorageName);
    if (localMeasurementSystem && parseInt(localMeasurementSystem) == MeasurementSystem.Metric){
      return parseFloat(( ((value ?? 0) / KILOMETERS_PER_MILE)).toFixed(4));
    }
    return value;
  }
}

declare module '@angular/forms' {
  interface FormGroup {
    markAllControlsDirty: () => FormGroup;
    markFormDirtyOnValueChange: () => Observable<boolean>;
  }
}

declare module '@angular/forms' {
  interface FormArray {
    markAllControlsDirty: () => FormArray;
  }
}

FormArray.prototype.markAllControlsDirty = function(): FormArray {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.markAllControlsDirty();
    } else {
      abstractControl.markAsDirty();
    }
  });
  return this;
};

FormGroup.prototype.markAllControlsDirty = function(): FormGroup {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.markAllControlsDirty();
    } else {
      abstractControl.markAsDirty();
    }
  });
  return this;
};

FormGroup.prototype.markFormDirtyOnValueChange = function(): Observable<boolean> {
  let isDirty = false;
  let resultObservable: Subject<boolean> = new Subject();

  this.valueChanges.pipe(takeWhile(x => !isDirty)).subscribe(result => {
    this.markAsDirty();
    isDirty = this.dirty;
    resultObservable.next(isDirty);
  });

  return resultObservable;
};

export function dynamicSort(property: any) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  let propertyArray = property.split('.');

  return function(a: any, b: any) {
    //handles up to 3 levels of properties
    let aProperty = a[propertyArray[0]] == null || a[propertyArray[0]].length == 0 ? null : a[propertyArray[0]];
    let bProperty = b[propertyArray[0]] == null || b[propertyArray[0]].length == 0 ? null : b[propertyArray[0]];

    if (propertyArray.length > 1) {
      aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]] == null || a[propertyArray[0]][propertyArray[1]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]];
      bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]] == null || b[propertyArray[0]][propertyArray[1]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]];
    }
    if (propertyArray.length == 3) {
      aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]][propertyArray[2]];
      bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]][propertyArray[2]];
    }

    aProperty = typeof aProperty === 'string' ? aProperty.toLowerCase() : aProperty;
    bProperty = typeof bProperty === 'string' ? bProperty.toLowerCase() : bProperty;

    if (bProperty == null) {
      return 1 * sortOrder;
    }
    if (aProperty == null) {
      return -1 * sortOrder;
    } else if (aProperty < bProperty) {
      return -1 * sortOrder;
    } else if (aProperty > bProperty) {
      return 1 * sortOrder;
    } else {
      return 0;
    }
  };
}

export function onlyUniqueById(value: any, index: any, self: any) {
  return self.map((x: any) => x['id']).indexOf(value['id']) === index;
}

export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

declare global {
  interface Array<T> {
    remove(callbackfn: (this: void, value: T, index: number, array: T[]) => any): this;
    removeRange(callbackfn: (this: void, value: T, index: number, array: T[]) => any): this;
  }
}

Array.prototype.remove = function (callbackfn) {
  let index = this.findIndex(callbackfn);
  if (index != -1) {
      this.splice(index, 1);
  }
  return this;
}

Array.prototype.removeRange = function (callbackfn) {
  let elements = this.filter(callbackfn);
  elements.forEach(x => {
      let index = this.findIndex(y => y == x);
      if (index != -1) {
          this.splice(index, 1);
      }
  })
  return this;
}

export function secondsToHoursMinutesSeconds(totalSeconds: number | null): HoursMinutesSeconds {
  const hours = totalSeconds !== null ? Math.floor(totalSeconds / 3600) : null;
  const minutes = totalSeconds !== null ? Math.floor(totalSeconds % 3600 / 60) : null;
  const seconds = totalSeconds !== null ? (totalSeconds % 3600 % 60) : null;
  return new HoursMinutesSeconds(hours, minutes, seconds);
}

export function secondsToMinutesAndSeconds(totalSeconds: number | null): MinutesSeconds {
  const minutes = totalSeconds !== null ? Math.floor(totalSeconds / 60) : null;
  const seconds = totalSeconds !== null ? (totalSeconds - (minutes * 60)) : null;
  return new MinutesSeconds(minutes, seconds);
}

export function localMeasurementDistanceName() {
  let localMeasurementSystem = localStorage.getItem(MeasurementSystemStorageName);
  if (localMeasurementSystem && parseInt(localMeasurementSystem) == MeasurementSystem.Metric){
    return 'km';
  }
  return 'mi';
}

export function localMeasurementElevationName() {
  let localMeasurementSystem = localStorage.getItem(MeasurementSystemStorageName);
  if (localMeasurementSystem && parseInt(localMeasurementSystem) == MeasurementSystem.Metric){
    return 'm';
  }
  return 'ft';
}

export function arrayMax(arr) {
  return arr.length ? Math.max(...arr) : 0;
}

export function dateWithoutTime(date: Date) {
  return moment(date).startOf('day').toDate();
}

export function dateAsUTCNoTime(date: Date): Date {
  let hour = moment().utcOffset() < 0 ? moment().utcOffset() / -60 : 0;
  return new Date(moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), hour]).format());
}

export function parseDateString(dateString) {
  const arr = dateString.split(/\D/);
  return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
}

export function parseDateStringAsUTC(dateString) {
  const arr = dateString.split(/\D/);
  return new Date(Date.UTC(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]));
}

export function enumToArray(enumName: Map<number, string>): BasicObject[] {
  const enumArray: BasicObject[] = [];
  enumName.forEach((value, key) => {
    enumArray.push(new BasicObject(key, value));
  });
  return enumArray;
}

export function getFlagEnumValueAsArray(enumValue: any, theEnum: Map<number, string>) : number[] {
  if (enumValue === null || enumValue === undefined) {
    return [];
  }

  let values: number[] = [];
  theEnum.forEach((value, key) => {
    if (enumValue & key) {
      values.push(key);
    }
  });
  return values;
}

export function getDifferenceInDays(theDate : Date | null | undefined) : number | null {
  if (!theDate) {
    return null;
  }
  return Math.round(Math.abs(dateWithoutTime(theDate).getTime() - dateWithoutTime(new Date()).getTime()) / (1000 * 60 * 60 * 24));
}

export function getNumberOfDaysBetween(startDate : Date, endDate: Date) : number | null {
  if (!startDate || !endDate) {
    return null;
  }
  return Math.round(Math.abs(dateWithoutTime(endDate).getTime() - dateWithoutTime(startDate).getTime()) / (1000 * 60 * 60 * 24));
}

// finds first instance of route param of the specified name (allows access to parent/ancestor route data)
export function getParamFromRoute(activatedRoute: ActivatedRoute, paramName: string): any {
  return activatedRoute.pathFromRoot.concat(activatedRoute).map(route => route.snapshot.params[paramName]).find(x => x);
}

export function getFullURLFromRoute(activatedRoute: ActivatedRouteSnapshot): any {
  return '/' + activatedRoute.pathFromRoot.map(r => r.url).filter(f => !!f[0]).map(([f]) => f.path).join('/');
}


export const formatShortDate = (date: Date) => {
  let dateSplit = date.toLocaleDateString().split('/');

  return `${dateSplit[2]}-${("0" + dateSplit[0]).slice(-2)}-${("0" + dateSplit[1]).slice(-2)}`;
}

declare global {
  interface Date {
    addDays(days: number, useThis?: boolean): Date;
  }
}

Date.prototype.addDays = function (days: number) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const DayOfWeekName = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
export const MeasurementSystemStorageName = 'rundna-measurementSystem';

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventViewModel, ClientFinishWorkoutViewModel } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'calendar-event-toggle-complete-dialog',
  templateUrl: 'calendar-event-toggle-complete-dialog.component.html',
  styleUrls: ['calendar-event-toggle-complete-dialog.component.scss'],
})
export class CalendarEventToggleCompleteDialogComponent implements OnInit {
  initialized: boolean;

  @Input()
  event: CalendarEventViewModel;

  constructor(public activeModal: NgbActiveModal, private clientsService: ClientsService, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.initialized = true;
  }

  onSave() {
    // this should only be used for Tasks
    const model: ClientFinishWorkoutViewModel = {
      messageBody: null,
      isMessageFromCoach: false,
      isCompleted: !this.event.isCompleted,
      dateCompleted: (this.event.isCompleted ? null : new Date()),
      ratePerceivedExertion: null,
      activityTypeDurations: []
    }

    this.clientsService.updateClientProgramWorkoutDayIsCompleted(this.event.eventId, model).subscribe(results => {
      this.toastr.success('Task Marked ' + (results.isCompleted ? 'Complete' : 'Incomplete'), 'Success');
      this.activeModal.dismiss('saved');
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="mt-1">
        <athlete-calendar [clientUserId]="clientUserId" [hasCoachRole]="hasCoachRole" [isCoachView]="false"></athlete-calendar>
      </div>

    </div>
  </div>
</div>

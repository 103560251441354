import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CardioIntensityType, GarminWorkoutStepTargetType } from 'src/app/_models/generatedModels';

@Component({
  selector: 'intensity-chart-dialog',
  templateUrl: 'intensity-chart-dialog.component.html',
  styleUrls: ['intensity-chart-dialog.component.scss'],
})
export class IntensityChartDialogComponent implements OnInit {
  initialized: boolean;
  cardioIntensityType = CardioIntensityType;
  garminWorkoutStepTargetType = GarminWorkoutStepTargetType;

  @Input()
  selectedCardioIntensityType: CardioIntensityType;

  @Input()
  selectedGarminWorkoutStepTargetType: GarminWorkoutStepTargetType;


  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.initialized = true;
  }

}

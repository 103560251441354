<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="d-flex">
  <div class="p-2" style="flex: 1">
    <label for="country">{{unselectedText}}</label>
    <select class="form-control" style="height: 375px" multiple [(ngModel)]="tempUnselectedIds" [ngModelOptions]="{ standalone: true }">
      <option *ngFor="let item of unselectedRunners" [value]="item.userId">{{ item.userFullName }}</option>
    </select>
  </div>
  <div class="p-2 d-flex align-self-center">
    <div>
      <button (click)="assign()" type="button" class="d-block btn btn-icon btn-success transition-3d-hover mb-3">
        <span class="fas fa-arrow-right btn-icon__inner"></span>
      </button>

      <button (click)="unassign()" type="button" class="d-block btn btn-icon btn-danger transition-3d-hover">
        <span class="fas fa-arrow-left btn-icon__inner"></span>
      </button>
    </div>
  </div>
  <div class="p-2" style="flex: 1">
    <label for="country">{{selectedText}}</label>
    <select class="form-control" style="height: 375px" multiple [(ngModel)]="tempSelectedIds" [ngModelOptions]="{ standalone: true }">
      <option *ngFor="let item of selectedRunners" [value]="item.userId">{{ item.userFullName }}</option>
    </select>
  </div>
</div>

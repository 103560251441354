import { Component, OnInit, Input } from '@angular/core';
import { ClientWorkoutByCategoryViewModelRead, ExerciseViewModelRead, ProgramSummaryViewModelReadExtended, WorkoutCategory } from 'src/app/_models/generatedModels';
import { Router } from '@angular/router';
import { ClientsService, PaidProgramService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutViewModalComponent } from '../workout-view-modal/workout-view-modal.component';
import { Enums } from 'src/app/_models/generatedEnums';
import { WorkoutAddToCalendarComponent } from '../workout-add-to-calendar-dialog/workout-add-to-calendar.component';
import { forkJoin } from 'rxjs';
import { ProgramAddToCalendarComponent } from '../program-add-to-calendar-dialog/program-add-to-calendar.component';
import { ExerciseLevelChangeModalComponent } from '../exercise-level-change-modal/exercise-level-change-modal.component';


@Component({
  selector: 'runner-my-library-view',
  templateUrl: 'runner-my-library.component.html',
  styleUrls: ['runner-my-library.component.scss']
})
export class RunnerMyLibraryViewComponent implements OnInit {
  clientWorkoutCategories: ClientWorkoutByCategoryViewModelRead[];
  programs: ProgramSummaryViewModelReadExtended[];
  workoutCategoryEnum = Enums.WorkoutCategoryEnum;
  initialized: boolean;
  currentProgramId: number = 0;
  exercises: ExerciseViewModelRead[];
  currentExerciseId: number = 0;
  exercisesInitialized: boolean = false;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;


  constructor(private router: Router, private clientService: ClientsService, private toastr: ToasterService, private modalService: NgbModal, private paidProgramsService: PaidProgramService) { }

  ngOnInit(): void {
    forkJoin([this.clientService.getClientWorkoutsByCategory(this.clientUserId), this.paidProgramsService.getPurchasedProgramsByUser(this.clientUserId), this.clientService.getClientExercises(this.clientUserId)]).subscribe(results => {
      this.clientWorkoutCategories = results[0];
      this.programs = results[1];
      this.exercises = results[2];
      this.exercisesInitialized = true;
      this.initialized = true;
    });
  }

  onShowDetails(workoutId: number) {
    const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
      modalRef.componentInstance.workoutId = workoutId;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
  }

  onShowProgramDetails(programId: number) {
    if (this.currentProgramId === programId) {
      this.currentProgramId = 0;
    } else {
      this.currentProgramId = programId;
    }
  }

  toggleExerciseInstructions(exerciseId: number) {
    if (this.currentExerciseId === exerciseId) {
      this.currentExerciseId = 0;
    } else {
      this.currentExerciseId = exerciseId;
    }
  }

  onRemoveWorkout(category: WorkoutCategory, workoutId: number) {
    const message = 'Are you sure you want to remove this workout from ' + (this.isCoachView ? 'the athlete\'s' : 'your' ) + ' library?';

    this.toastr.confirmDialog(message, 'Remove Workout', 'Remove Workout', 'Cancel').subscribe(result => {
      if (result) {
        this.clientService.deleteClientWorkout(this.clientUserId, workoutId).subscribe(results => {
          this.clientWorkoutCategories.find(x => x.workoutCategory == category).workouts.remove(x => x.id === workoutId);
          this.toastr.success('Workout removed', 'Success');
        });
      }
    });
  }

  onRemoveExercise(exerciseId: number) {
    const message = 'Are you sure you want to remove this exercise from ' + (this.isCoachView ? 'the athlete\'s' : 'your' ) + ' library?';

    this.toastr.confirmDialog(message, 'Remove Exercise', 'Remove Exercise', 'Cancel').subscribe(result => {
      if (result) {
        this.clientService.deleteClientExercise(this.clientUserId, exerciseId).subscribe(results => {
          this.exercises.remove(x => x.id === exerciseId);
          this.toastr.success('Exercise removed', 'Success');
        });
      }
    });
  }

  onAddWorkoutToCalendar(workoutId: number, workoutName: string) {
    const modalRef = this.modalService.open(WorkoutAddToCalendarComponent, { size: 'md' });
    modalRef.componentInstance.workoutId = workoutId;
    modalRef.componentInstance.workoutName = workoutName;
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onAddProgramToCalendar(programId: number, programName: string, purchaseDate: Date) {
    const modalRef = this.modalService.open(ProgramAddToCalendarComponent, { size: 'md' });
    modalRef.componentInstance.programId = programId;
    modalRef.componentInstance.programName = programName;
    modalRef.componentInstance.clientUserId = this.clientUserId;
    modalRef.componentInstance.isPurchasedProgram = true;
    modalRef.componentInstance.purchaseDate = purchaseDate;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  exerciseLevelChange(changeType: string, levelChangeExerciseId: number, defaultExerciseId: number) {
    const modalRef = this.modalService.open(ExerciseLevelChangeModalComponent, { size: 'lg' });
    modalRef.componentInstance.userId = this.clientUserId
    modalRef.componentInstance.defaultExerciseId = defaultExerciseId;
    modalRef.componentInstance.levelChangeExerciseId = levelChangeExerciseId;
    modalRef.componentInstance.isLevelUp = changeType == 'up';
    modalRef.componentInstance.fromMyLibrary = true;
    modalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason == 'saved') {
          this.exercisesInitialized = false;
          this.clientService.getClientExercises(this.clientUserId).subscribe(exercises => {
            this.exercises = exercises;
            this.exercisesInitialized = true;
          });
        }
      }
    );
  }
}

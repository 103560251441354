import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class SettingsProvider {
  private config: any;

  public loadConfig(http): Promise<any> {
    return this.getJSON(http)
      .pipe(
        tap(env => {
          this.config = env;
          (<any>window).Stripe.setPublishableKey(this.stripePublishableKey);

        })
      )
      .toPromise();
  }

  public getJSON(http): Observable<any> {
    return http.get('/api/settings/client');
  }

  public get configuration(): any {
    return this.config;
  }

  public get clientIP(): string {
    return this.config.clientIP;
  }

  public get backendUrl(): string {
    return this.config.backendUrl;
  }

  public get siteUrl(): string {
    return this.config.siteUrl;
  }

  public get office365ClientId(): string {
    return this.config.office365ClientId;
  }

  public get stripePublishableKey(): string {
    return this.config.stripePublishableKey;
  }

  public get stripeClientKey(): string {
    return this.config.stripeClientKey;
  }

  public get linkedInClientKey(): string {
    return this.config.linkedInClientKey;
  }

  public get aiInstrumentationKey(): string
  {
    return this.config.openTelemetryConfig.applicationInsightsInstrumentationKey;
  }

  public get wowzaPlayerToken(): string
  {
    return this.config.wowzaPlayerToken;
  }

}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6" style="min-height: 500px">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-12">
          <h6 class="h2 d-inline-block mb-0">Gait Analysis Results</h6>
        </div>
        <div class="col-12">
          <a href="javascript:void(0)" [routerLink]="['/runner/assessments']"> &#60; Back to all Assessments</a>
        </div>
      </div>
    </div>
  </div>
  <gait-analysis-result-details [isCoachView]="false"></gait-analysis-result-details>
</div>

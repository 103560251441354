import { Component, OnInit, Input } from '@angular/core';
import { ClientDayEventType, ProgramSummaryViewModelRead } from 'src/app/_models/generatedModels';
import { ProgramWeek } from 'src/app/_models/models';
import { ProgramHelper } from 'src/app/_helpers/programs';


@Component({
  selector: 'program-first-week-preview',
  templateUrl: 'program-first-week-preview.component.html',
  styleUrls: ['program-first-week-preview.component.scss']
})
export class ProgramFirstWeekPreviewComponent implements OnInit {
  initialized: boolean;
  weeks: ProgramWeek[] = [];
  firstWeek: ProgramWeek;
  eventType = ClientDayEventType;

  @Input()
  program: ProgramSummaryViewModelRead;


  constructor() { }

  ngOnInit(): void {
    this.weeks = ProgramHelper.populatePogramWeeksFromSummary(this.program);
    this.firstWeek = this.weeks[0];
    this.initialized = true;
  }
}

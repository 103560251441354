<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <h3 class="mb-3 mt-1">Annual Training Load Planner</h3>

  <a (click)="showPeriods = !showPeriods" href="javascript:void(0)">{{ showPeriods ? 'Hide' : 'Show' }} Training Load Periods</a>
  <div class="mb-3" [hidden]="!showPeriods">
    <table class="table table-sm w-auto">
      <thead class="table-bordered">
        <tr>
          <th>Period</th>
          <th>Training Load Progression</th>
          <th>RPE 1-4 %</th>
          <th>RPE 5-6 %</th>
          <th>RPE 7-8 %</th>
          <th>RPE 9-10 %</th>
        </tr>
      </thead>
      <tbody class="table-bordered">
        <tr *ngFor="let period of periods; let c = index">
          <td>{{ period.trainingLoadPeriodType | enumString: trainingLoadPeriodEnum }}</td>
          <td class="text-right">{{ period.trainingLoadMultiplier * 100 | number : '1.0-0' }}%</td>
          <td class="text-right">{{ period.rpE1To4Multiplier * 100 | number : '1.0-0' }}%</td>
          <td class="text-right">{{ period.rpE5To6Multiplier * 100 | number : '1.0-0' }}%</td>
          <td class="text-right">{{ period.rpE7To8Multiplier * 100 | number : '1.0-0' }}%</td>
          <td class="text-right">{{ period.rpE9To10Multiplier * 100 | number : '1.0-0' }}%</td>
        </tr>
      </tbody>
    </table>
  </div>

  <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="title"> Plan Name </label>
              <input class="form-control" formControlName="name" maxlength="100" type="text" />
              <div class="invalid-message">
                <div>Name is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6"></div>
          <div class="col-lg-3 col-md-4">
            <div class="form-group">
              <label class="form-control-label" for="title"> Start Date (Monday) </label>
              <kendo-datepicker placeholder="" class="form-control" formControlName="startDate" (valueChange)="onStartDateChange($event)" [disabledDates]="disabledDates"></kendo-datepicker>
              <div class="invalid-message">
                <div>Start Date is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="form-group">
              <label class="form-control-label" for="title"> Starting Training Load </label>
              <kendo-numerictextbox noScroll class="form-control" formControlName="startTrainingLoad" (valueChange)="onStartLoadChange($event)" [decimals]="'0'" [format]="'n0'" min="1" max="20000" type="number"></kendo-numerictextbox>
              <div class="invalid-message">
                <div>Starting Training Load is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="form-group">
              <label class="form-control-label" for="description"> Notes </label>
              <input class="form-control" formControlName="startNotes" maxlength="100" type="text" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div formArrayName="trainingLoadPlannerWeeks" class="formGroupContainer week-row" *ngFor="let week of formGroup.get('trainingLoadPlannerWeeks').controls; let w = index">
              <div class="row formGroupName sortable-show-hover" [formGroupName]="w">
                <div class="col-lg-1 col-md-2 col-6 p-0">
                  <div class="form-group">
                    <a href="javascript:void(0)" class="delete-icon" (click)="onDeleteWeek(w)"><i class="fa fa-trash"></i></a>
                    <label class="form-control-label" for="description"> &nbsp; </label>
                    <div class="form-control-text">Week {{w + 1}}</div>
                  </div>
                </div>
                <div class="col-lg-1 col-md-3 col-6 p-0">
                  <div class="form-group">
                    <label class="form-control-label" for="startDate"> &nbsp; </label>
                    <div class="form-control-text">{{week.get('startDate').value | date:'shortDate'}}</div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4">
                  <div class="form-group">
                    <label class="form-control-label" for="description"> Period </label>
                    <select class="form-control" formControlName="trainingLoadPeriodType" (ngModelChange)="onPeriodTypeChange($event, w)">
                      <ng-container *ngFor="let mapEntry of trainingLoadPeriodEnum | keyvalue">
                        <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="col-lg-8 col-md-12">
                  <table class="table table-sm w-auto">
                    <thead class="table-bordered">
                      <tr>
                        <th>Suggested</th>
                        <th>Low</th>
                        <th>High</th>
                        <th class="d-none d-sm-none d-md-table-cell">RPE 1-4</th>
                        <th class="d-none d-sm-none d-md-table-cell">RPE 5-6</th>
                        <th class="d-none d-sm-none d-md-table-cell">RPE 7-8</th>
                        <th class="d-none d-sm-none d-md-table-cell">RPE 9-10</th>
                      </tr>
                    </thead>
                    <tbody class="table-bordered">
                      <tr>
                        <td class="text-right">{{week.get('suggestedTrainingLoad').value}}</td>
                        <td class="text-right">{{week.get('trainingLoadLow').value}}</td>
                        <td class="text-right">{{week.get('trainingLoadHigh').value}}</td>
                        <td class="text-right d-none d-sm-none d-md-table-cell">{{week.get('rpE1To4Load').value}}</td>
                        <td class="text-right d-none d-sm-none d-md-table-cell">{{week.get('rpE5To6Load').value}}</td>
                        <td class="text-right d-none d-sm-none d-md-table-cell">{{week.get('rpE7To8Load').value}}</td>
                        <td class="text-right d-none d-sm-none d-md-table-cell">{{week.get('rpE9To10Load').value}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label class="form-control-label" for="description"> Notes </label>
                    <input class="form-control" formControlName="notes" maxlength="100" type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12 mb-3 link video-link">
                <a class="" href="javascript:void(0)" (click)="onAddWeek()">Add Week</a>
              </div>
            </div>
            <div class="invalid-message-visible" *ngIf="formGroup?.controls['trainingLoadPlannerWeeks'].errors && formGroup?.controls['trainingLoadPlannerWeeks'].errors.required">Must add at least 1 week</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>
      <div class="col text-right">
        <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>
</div>




<nav class="navbar navbar-expand-lg navbar-dark bg-default border-bottom" id="navbar-main">
  <div class="container-fluid">
    <button class="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="d-none d-md-block text-white ml-auto mr-3 nav-logo">
      <img *ngIf="brand" class="{{brand.cssStyleName}}" src="../../../../assets/img/brand/{{brand.navImageName}}" />
      <img *ngIf="user && !user.organizationAvatar" class="rundna-logo" src="../../../../assets/img/brand/rundna-rgb-lockup-horz-reverse.png" />
      <img *ngIf="user && user.organizationAvatar" class="rundna-logo" src="/fetchimage?fileName={{ user.organizationAvatar }}" />
    </div>

    <div *ngIf="hasCoachRole" class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Search form -->
      <ul class="navbar-nav mr-auto">
        <li *ngIf="hasAthleteRole" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/runner']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }"> <i class="ni ni-user-run"></i> Me <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/dashboard']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Dashboard <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/library']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Library</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/athletes']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Athletes <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/teams']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Teams <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/reports']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Reports</a>
        </li>
        <li *ngIf="initialized && auth.isOrganizationAdmin()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/admin']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Admin</a>
        </li>
        <li *ngIf="initialized && auth.isSuperUser()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/superuser']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">SuperUser</a>
        </li>
      </ul>
    </div>

    <div *ngIf="!hasCoachRole" class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="javascript:void(0)" [routerLink]="['/runner/dashboard']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Dashboard <span class="sr-only">(current)</span></a></li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="javascript:void(0)" [routerLink]="['/runner/calendar']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Calendar</a></li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="javascript:void(0)"[routerLink]="['/runner/my-library']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">My Library</a></li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="javascript:void(0)"[routerLink]="['/runner/assessments']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Assessments</a></li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="javascript:void(0)"[routerLink]="['/runner/plans']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Plans</a></li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="javascript:void(0)"[routerLink]="['/runner/journal']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">Journal</a></li>
      </ul>
    </div>

    <ul *ngIf="hasCoachRole && auth.user && (!auth.user.activeSubscription || auth.user.complimentarySubscriptionEndDate) && auth.user.trialDaysLeft < 91" class="navbar-nav align-items-lg-center ml-md-auto d-none d-md-inline mr-2">
      <li class="nav-item my-auto">
        <a href="javascript:void(0)" class="text-danger font-weight-500" [routerLink]="['/admin/billing']">{{ auth.user.trialDaysLeft < 0 ? 'Access Expired' : (auth.user.trialDaysLeft + ' Days of Access Left') }}. Activate</a>
      </li>
    </ul>
    

    <!-- Messages -->
    <ul *ngIf="messages" class="navbar-nav align-items-lg-center ml-md-auto mr-2">
      <li class="nav-item dropdown" dropdown placement="bottom-right">
        <a class="nav-link pr-2 pr-lg-0" style="cursor: pointer;" role="button"  dropdownToggle>
          <i class="fas fa-envelope" style="font-size: 20px;"></i><span *ngIf="unreadCount > 0" class="badge badge-pill badge-danger">{{unreadCount}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden" style="z-index: 9999;" *dropdownMenu>
          <div class="px-3 py-3">
            <h6 class="text-sm text-muted m-0">You have <strong class="text-primary small-link">{{unreadCount}}</strong> unread messages.</h6>
          </div>
          <div *ngIf='unreadCount > 0'  class="list-group list-group-flush">
            <a *ngFor="let message of messages" [routerLink]="['/message-list']" class="list-group-item list-group-item-action">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img alt="Image placeholder" src="fetch-avatar?userId={{message.fromUserId}}" class="avatar rounded-circle" />
                </div>
                <div class="col ml--2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0 text-sm">{{ message.fromUserFirstName + ' ' + message.fromUserLastName }}</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>{{ message.createdDate | date: 'medium' }}</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">{{ message.subject }}</p>
                </div>
              </div>
            </a>

          </div>
          <a [routerLink]="['/message-list']" class="dropdown-item text-center text-primary font-weight-bold py-3">View / Create Messages</a>
        </div>
      </li>
    </ul>


    <div class="navbar-brand">
      <ul *ngIf="initialized && auth.user" class="navbar-nav ml-auto ml-md-0" style="cursor: pointer">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="fetch-avatar?userId={{ auth.user.id }}" />
              </span>
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{ auth.user.firstName }}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" style="max-width: 14rem; z-index: 9999;" *dropdownMenu>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item"> <i class="ni ni-single-02"></i> <span>My profile</span> </a>
            <a routerLinkActive="active" [routerLink]="['/integrations']" class="dropdown-item"> <i class="ni ni-settings-gear-65"></i> <span>Integrations</span> </a>
            <a routerLinkActive="active" [routerLink]="['/user-notifications']" class="dropdown-item"> <i class="ni ni-time-alarm"></i> <span>Notifications</span> </a>
            <a href="https://rundnahelp.zendesk.com" target="_blank" class="dropdown-item"> <i class="fas fa-question" style="vertical-align: text-top;"></i> <span>Help Center</span> </a>

            <ng-container *ngIf="user.validUsersForUserCredential.length > 1">
              <div class="dropdown-divider"></div>
              <div class="dropdown-header noti-title">
                <h6 class="text-overflow m-0">Select Organization</h6>
              </div>
              <ng-container *ngFor="let org of user.validUsersForUserCredential">
                <a href="javascript:void(0)" (click)="switchOrganizations(org.organizationId)" class="dropdown-item org-name pl-2"> <i *ngIf="user.organizationId == org.organizationId" class="far fa-check mr-1"></i> <span [ngClass]="{'no-icon': user.organizationId != org.organizationId}">{{org.organizationName}}</span> </a>
              </ng-container>
            </ng-container>
            <div class="dropdown-divider"></div>
            <a [routerLink]="[]" (click)="logout()" class="dropdown-item"> <i class="far fa-sign-out align-text-bottom"></i> <span>Logout</span> </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="sidenavOpen === true" class="backdrop d-xl-none" (click)="toggleSidenav()"></div>

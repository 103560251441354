import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { MinutesDecimalToStringPipe, localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { ExternalWorkoutService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-pace-chart',
  templateUrl: 'pace-chart.component.html',
})
export class ExternalPaceChartComponent implements OnInit, OnDestroy {
  initialized = false;
  hasData: boolean = true;
  localMeasurementName: string;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  isMetricSystem: boolean = false;


  constructor(private charting: ChartingService, private externalWorkoutService: ExternalWorkoutService, private minutesDecimalToStringPipe: MinutesDecimalToStringPipe) {}

  ngOnInit(): void {
    this.localMeasurementName = localMeasurementDistanceName();
    const title = 'Pace (min/' + this.localMeasurementName + ')';
    const chartHeaders: any[] = [{ type: 'timeofday', id: 'Time', label: 'Time' }, { type: 'timeofday', id: 'Pace', label: title }];

    this.externalWorkoutService.getPaceChartData(this.clientProgramWorkoutDayId, this.isMetricSystem).subscribe(result => {
      this.hasData = result.length > 0;

      this.charting.queueCharts(
        this.charting.drawScatterChart.bind(this.charting),
        new ChartData(
            'paceChart',
            chartHeaders,
            result.map(x => [x.timerDuration, x.valueAsTime]),
            title,
            null,
            true
        )
      );
      this.initialized = true;
    });
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { dateAsUTCNoTime, dateWithoutTime, getDifferenceInDays, getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { CalendarEventActionParameters } from 'src/app/_models/models';
import { CalendarContextMenuOptions, CalendarDayViewModel, ClientSearchParams, ClientStatus, ClientSummaryDTO, TeamDashboardViewModel, TeamSummaryViewModelRead, TeamUserRole } from 'src/app/_models/generatedModels';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageTeamDialogComponent } from 'src/app/_components/manage-team-dialog/manage-team-dialog.component';
import { ClientsService, TeamService } from 'src/app/_services/generatedServices';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { TeamCalendarService } from 'src/app/_services/teamCalendar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bxl-team-dashboard',
  templateUrl: 'team-dashboard.component.html',
  styleUrls: ['team-dashboard.component.scss'],
})
export class TeamDashboardComponent implements OnInit, OnDestroy {
  initialized = false;
  dashboardInitialized = false;
  teamId: number;
  team: TeamSummaryViewModelRead;
  dashboard: TeamDashboardViewModel;
  athleteUrl: string = '/athletes/details';
  todaysWorkouts: CalendarDayViewModel = undefined;
  subscription: Subscription;
  getDifferenceInDays = getDifferenceInDays;

  clients: ServerSortableList<ClientSummaryDTO>;
  searching: boolean = false;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  currentUserId: number;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private router: Router, private modalService: NgbModal, private teamService: TeamService,
    private auth: AuthenticationService, private clientsService: ClientsService, private toastr: ToasterService, private teamCalendarService: TeamCalendarService) {}

  ngOnInit(): void {
    this.teamId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Dashboard', this.router.url, []);

    this.teamCalendarService.setInitialValues(this.teamId, false);
    this.subscription = this.teamCalendarService.changed.subscribe(result => {
      this.loadTodaysWorkouts();
    });

    this.getTeamData();
    this.getAthleteDashboardData();
    this.loadTodaysWorkouts();

    this.auth.fetchUserProfile().subscribe(user => {
      this.currentUserId = user.id;
      this.clients = new ServerSortableList<ClientSummaryDTO>([], 'Credential.FullName', false);

      this.clients.sortChanged.subscribe(result => {
        this.currentPage = 1;
        this.searchClients();
      });

      this.searchClients();
    });
  }

  loadTodaysWorkouts() {
    const today = dateWithoutTime(new Date());
    this.teamService.getTeamCalendarDaysByDateRange(this.teamId, dateAsUTCNoTime(today), dateAsUTCNoTime(today)).subscribe(result => {
      this.todaysWorkouts = result ? result[0] : null;
    });
  }

  getTeamData() {
    this.teamService.getTeamSummaryByIdWithPermissions(this.teamId).subscribe(result => {
      // don't let someone come to this page if they don't have permissions for this team
      if (!result) {
        this.router.navigateByUrl('/teams');
      } else {
        this.team = result;
        this.initialized = true;
      }
    });
  }

  getAthleteDashboardData() {
    this.teamService.getTeamDashboardData(this.teamId).subscribe(result => {
      this.dashboard = result;
    });
  }

  searchClients() {
    this.searching = true;
    let model = new ClientSearchParams();
    model.searchText = null;
    model.status = ClientStatus.Active;
    model.teamId = this.teamId;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.clients.column;
    model.isSortDesc = this.clients.isDesc;

    this.clientsService.searchClients(this.currentUserId, model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.clients.updateData(results.results);
      this.searching = false;
    });
  }

  onEditTeam() {
    const modalRef = this.modalService.open(ManageTeamDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
      modalRef.componentInstance.teamId = this.teamId;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getTeamData();
            this.getAthleteDashboardData();
            this.searchClients();
          }
        }
      );
  }

  onRemoveCoach(userId: number) {
    this.toastr.confirmDialog('Are you sure you want to remove this coach from the team?', 'Remove Coach', 'Remove Coach', 'Cancel').subscribe(result => {
      if (result) {
        this.teamService.deleteRoleFromTeamUser(this.teamId, userId, TeamUserRole.Coach).subscribe(results => {
          this.getTeamData();
        });
      }
    });
  }

  onRemoveAthlete(userId: number) {
    this.toastr.confirmDialog('Are you sure you want to remove this athlete from the team?', 'Remove Athlete', 'Remove Athlete', 'Cancel').subscribe(result => {
      if (result) {
        this.teamService.deleteRoleFromTeamUser(this.teamId, userId, TeamUserRole.Runner).subscribe(result => {
          this.getAthleteDashboardData();
          this.searchClients();
        });
      }
    });
  }

  onTodaysWorkoutsChanged(event: CalendarEventActionParameters) {
    if ((event.menuOption & CalendarContextMenuOptions.View) != 0) {
      this.teamCalendarService.onEventInfoClicked(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Edit) != 0) {
      this.teamCalendarService.onEventEditClicked(event.event);
    } else if ((event.menuOption & CalendarContextMenuOptions.Delete) != 0) {
      this.teamCalendarService.onEventDeleteClicked(event.event);
    }
  }

  onProgramChanged() {
    this.getAthleteDashboardData();
    this.getTeamData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div class="row">
  <div class="col-lg-12 mt-1 mb-2">
    <a *ngIf="hasCurrentPrograms && !isCoachView" class="btn btn-sm btn-primary mt-1 mr-3" href="javascript:void(0)" [routerLink]="['/runner/plans']">Reschedule Programs</a>
    <div class="float-right d-none d-lg-block">
      <a href="javascript:void(0)" class="btn btn-sm btn-primary" (click)="printPDF()">Print PDF</a>
    </div>
  </div>
</div>

<custom-calendar [data]="data"
  (calendarParametersChanged)="calendarParametersChanged($event)"
  (eventInfoClicked)="onEventInfoClicked($event)"
  (eventEditClicked)="onEventEditClicked($event)"
  (eventDeleteClicked)="onEventDeleteClicked($event)"
  (eventAddClicked)="onEventAddClicked($event)"
  (eventDragEnd)="onEventDragEnd($event)"
  (eventToggleCompleteClicked)="onEventToggleCompleteClicked($event)"
  (eventCopyClicked)="onEventCopyClicked($event)"
  (weekCopyClicked)="onWeekCopyClicked($event)"
  [hasCoachRole]="hasCoachRole">
</custom-calendar>

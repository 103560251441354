import { Component, Input, forwardRef, ElementRef, Output, EventEmitter, ViewChild, OnInit, OnChanges  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PaginationComponent } from 'ngx-bootstrap/pagination';
import { Subscription } from 'rxjs';
import { MediaBreakpointService } from '../_services/mediaBreakpoint.service';


@Component({
    selector: 'custom-pagination',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <pagination id="paginationComp" #paginationComp [(ngModel)]="selectedValue" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="onPageChange($event)" class="{{cssClass}} justify-content-end mb-0" [maxSize]="maxSize" [boundaryLinks]="showBoundaryLinks" previousText="<i class='fas fa-angle-left'></i>" nextText="<i class='fas fa-angle-right'></i>" firstText="<i class='fas fa-angle-double-left'></i>" lastText="<i class='fas fa-angle-double-right'></i>"> </pagination>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomPaginationControl),
            multi: true
        }
    ]
})

export class CustomPaginationControl implements ControlValueAccessor, OnInit, OnChanges {

    someAttr: string = 'anyValue';
    selectedValue: any; //this is currentPage
    showBoundaryLinks: boolean;
    maxSize: number = 20;
    windowSizeSubscription: Subscription;
    currentBreakpoint: string;

    constructor(private elementRef: ElementRef, private mediaBreakpointService: MediaBreakpointService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    totalItems: number;

    @Input()
    itemsPerPage: number;

    @Output()
    pageChanged = new EventEmitter<any>();


    cssClass: string = '';

    @ViewChild(PaginationComponent) paginationComp: PaginationComponent;

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onPageChange(event: any) {
      this.writeValue(event.page);
      this.propagateChange(this.selectedValue);
      this.propagateTouch();
      this.pageChanged.emit(event);
    }

    ngOnInit() {
      this.currentBreakpoint = this.mediaBreakpointService.currentBreakpoint;
      this.setMaxSizeAndBoundaryLinks();

      this.windowSizeSubscription = this.mediaBreakpointService.mediaBreakpointChanged.subscribe((result) => {
        this.currentBreakpoint = result;
        this.setMaxSizeAndBoundaryLinks();
      });
    }

    setMaxSizeAndBoundaryLinks() {
      /* // using element width in case pagination element is less than window width (not working if in accordion so just bailing on this for now)
      let thisElement: HTMLElement = document.getElementById('paginationComp');
      var width = thisElement.offsetWidth;

      this.maxSize = 3;
      if (width >= 576 && width < 768) {
        this.maxSize = 7;
      } else if (width >= 768 && width < 992) {
        this.maxSize = 10;
      } else if (width >= 992 && width < 1200) {
        this.maxSize = 15;
      } else if (width >= 1200) {
        this.maxSize = 20;
      } */

      if (this.currentBreakpoint == 'md') {
        this.maxSize = 10;
      } else if (this.currentBreakpoint == 'lg') {
        this.maxSize = 15;
      } else if (this.currentBreakpoint == 'xl') {
        this.maxSize = 20;
      }

      // pagination component doesn't allow dynamic maxSize changes via parameter so have to do it like this
      if (this.paginationComp && this.paginationComp.maxSize != this.maxSize) {
        this.paginationComp.maxSize = this.maxSize;
        this.paginationComp.selectPage(this.paginationComp.page)
      }

      if ((this.totalItems / this.itemsPerPage) > this.maxSize) {
        this.showBoundaryLinks = true;
      } else {
        this.showBoundaryLinks = false;
      }
    }

    ngOnChanges(): void {
      if (!this.paginationComp) {
        return;
      }

      this.setMaxSizeAndBoundaryLinks();
    }
}

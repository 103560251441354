import { Component, OnInit } from '@angular/core';
import { dateAsUTCNoTime, dynamicSort, getParamFromRoute, secondsToMinutesAndSeconds } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RaceDistanceType, RacePlanViewModel, RacePlanViewModelRead } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RacePlanService } from 'src/app/_services/generatedServices';
import { raceDistanceTypeValues } from 'src/app/_models/models';

@Component({
  selector: 'add-edit-race-plan',
  templateUrl: 'add-edit-race-plan.component.html',
  styleUrls: ['add-edit-race-plan.component.scss']
})
export class AddEditRacePlanComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  planId: number;
  clientUserId: number;
  plan: RacePlanViewModelRead;
  finishTime: number = 0;
  raceDistanceType = RaceDistanceType;
  raceDistanceTypeEnum = Enums.RaceDistanceTypeEnum;


  constructor(private route: ActivatedRoute, private toastr: ToasterService , private auth: AuthenticationService, private fb: FormBuilder,
    private planService: RacePlanService, private router: Router) {}

  ngOnInit() {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.planId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    if (this.planId) {
      this.planService.getRacePlanById(this.clientUserId, this.planId).subscribe(results => {
        this.plan = results;
        this.setupForm();
      });

    } else {
      this.plan = new RacePlanViewModelRead();
      this.setupForm();
    }
  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      name: [this.plan.name, Validators.required],
      raceDistanceType: [this.plan.raceDistanceType, Validators.required],
      customDistance: [this.plan.customDistance],
      defaultPaceInSeconds: [this.plan.defaultPaceInSeconds],
      notes: [this.plan.notes],
      raceDate: [this.plan.raceDate ? new Date(this.plan.raceDate) : null, Validators.required],
      racePlanSplits: this.fb.array([]),
    });


    if (this.planId && this.plan.racePlanSplits.length > 0) {
      let splits = this.formGroup.get('racePlanSplits') as FormArray;

      this.plan.racePlanSplits.sort(dynamicSort('splitNumber')).forEach(split => {
        const splitMinAndSec = secondsToMinutesAndSeconds(split.splitPaceInSeconds || null);
        splits.push(this.fb.group({
          id: [split.id],
          splitNumber: [split.splitNumber],
          splitDistance: [split.splitDistance, Validators.required],
          splitPaceInSeconds: [split.splitPaceInSeconds, Validators.required],
          splitDurationInSeconds: [split.splitDurationInSeconds],
          cumulativeDurationInSeconds: [split.cumulativeDurationInSeconds],
          notes: [split.notes]
        }));
      });
    }

    this.calculateTotalTimes();
    this.initialized = true;
  }

  onDefaultPaceChange() {
    this.populateDefaultPace();
  }

  onDefaultPaceUpdate() {
    this.populateDefaultPace();
  }

  onClearAllPaces() {
    let splits = this.formGroup.get('racePlanSplits') as FormArray;
    for (var i = 0; i < splits.length; i++) {
      splits.at(i).get('splitPaceInSeconds').setValue(null);
      this.calculateSplitDuration(i);
    }

    this.calculateTotalTimes();
  }

  populateDefaultPace() {
    if (this.formGroup.get('defaultPaceInSeconds').value != null) {
      const defaultPace = this.formGroup.get('defaultPaceInSeconds').value;

      let splits = this.formGroup.get('racePlanSplits') as FormArray;
      for (var i = 0; i < splits.length; i++) {
        if (splits.at(i).get('splitPaceInSeconds').value == null) {
          splits.at(i).get('splitPaceInSeconds').setValue(defaultPace);
          this.calculateSplitDuration(i);
        }
      }

      this.calculateTotalTimes();
    }
  }

  onRaceDistanceTypeChange(event: any) {
    if (event != RaceDistanceType.Custom) {
      this.formGroup.get('customDistance').setValue(null);
      this.formGroup.get('customDistance').setValidators([]);
      this.onDistanceChange(raceDistanceTypeValues.find(x => x.raceDistanceType == event).raceDistanceValue);
    } else {
      this.formGroup.get('customDistance').setValidators([Validators.required]);
    }
    this.formGroup.get('customDistance').updateValueAndValidity();
  }

  onCustomDistanceChange(event: any) {
    this.onDistanceChange(event);
  }

  onDistanceChange(distance: number) {
    const totalFullSplits = Math.trunc(distance);
    const remainderSplit = distance - totalFullSplits;
    const totalSplits = totalFullSplits + (remainderSplit ? 1 : 0);

    // first make sure the number of splits is correct
    let splits = this.formGroup.get('racePlanSplits') as FormArray;
    const currentLength = splits.length;
    if (currentLength > totalSplits) {
      for (var i = currentLength - 1; i >= totalSplits; i--) {
        splits.removeAt(i);
      }
    } else if (currentLength < totalSplits) {
      for (var i = currentLength + 1; i <= totalSplits; i++) {
        splits.push(this.fb.group({
          id: [0],
          splitNumber: [i],
          splitDistance: [null, Validators.required],
          splitPaceInSeconds: [null, Validators.required],
          splitDurationInSeconds: [null],
          cumulativeDurationInSeconds: [null],
          notes: [null]
        }));
      }
    }

    // then make sure split distances and durations are updated
    for (var i = 0; i < splits.length; i++) {
      const splitNumber = splits.at(i).get('splitNumber').value;
      splits.at(i).get('splitDistance').setValue(splitNumber <= totalFullSplits ? 1 : remainderSplit);
      this.calculateSplitDuration(i);
    }

    this.populateDefaultPace();
    this.calculateTotalTimes();
  }

  calculateSplitDuration(splitIndex: number) {
    let splits = this.formGroup.get('racePlanSplits') as FormArray;
    const distance = splits.at(splitIndex).get('splitDistance').value;
    const pace = splits.at(splitIndex).get('splitPaceInSeconds').value;
    splits.at(splitIndex).get('splitDurationInSeconds').setValue(Math.round((distance || 0) * (pace || 0)));
  }

  onSplitPaceChange(event: any, index: number) {
    this.calculateSplitDuration(index);
    this.calculateTotalTimes();
  }

  calculateTotalTimes() {
    let splits = this.formGroup.get('racePlanSplits') as FormArray;
      let totalTime = 0;
      for (var i = 0; i < splits.length; i++) {
        const splitDuration = (splits.at(i).get('splitDurationInSeconds').value || 0);
        totalTime += splitDuration;
        splits.at(i).get('cumulativeDurationInSeconds').setValue(totalTime);
      }
      this.finishTime = totalTime;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      let formData: RacePlanViewModel = this.formGroup.value;
      formData.raceDate = dateAsUTCNoTime(formData.raceDate);

      if (this.planId) {
        this.update(formData, resetButton);
      } else {
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.planService.createRacePlan(this.clientUserId, formData).subscribe((result) => {
      this.toastr.success('Plan Added', 'Success');
      resetButton();
      this.navigateBack();
    });
  }

  update(formData: any, resetButton: () => any) {
    this.planService.updateRacePlan(this.planId, formData).subscribe((result) => {
      this.toastr.success('Plan Updated', 'Success');
      resetButton();
      this.navigateBack();
    });
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe(result => {
        if (result) {
          this.navigateBack();
        }
      });
    } else {
      this.navigateBack();
    }
  }

  navigateBack() {
    if (this.planId) {
      this.router.navigate(['../../'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}

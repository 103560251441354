import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class DailyStatusesService {
  public entryAdded: Subject<boolean> = new Subject();

    constructor() { }

    SendEntryAddedEvent() {
      this.entryAdded.next(true);
    }

}

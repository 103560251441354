<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-12">
          <h6 class="h2 d-inline-block mb-1">My Dashboard</h6>
        </div>
        <div class="col-lg-12">
          <a *ngIf="!hasDailyStatus" href="javascript:void(0)" (click)="onDailyStatus()" class="btn btn-primary mt-1 mr-3"><i class="fas fa-plus mr-1"></i>Daily Status</a>
          <a *ngIf="survey.isActive" class="btn btn-primary mt-1 mr-3" href="javascript:void(0)" (click)="handleSurvey()" >Find a Plan</a>
        </div>
      </div>
    </div>
    <runner-dashboard-view [clientUserId]="userId" [clientUserCredentialId]="userCredentialId" [isCoachView]="false" [hasCoachRole]="hasCoachRole"></runner-dashboard-view>
  </div>
</div>

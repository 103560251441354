import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveyService } from 'src/app/_services/generatedServices';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SurveySummaryViewModelRead, UserRole } from '../_models/generatedModels';
import { AuthenticationService } from './authentication.service';
import { coachUserRoleArray } from '../_models/models';
import { ToasterService } from './toaster.service';

@Injectable()
export class SurveyHandlerService {
  surveyId: number = 1;
  survey: SurveySummaryViewModelRead;
  showSurvey: boolean = false;

    constructor(private surveyService: SurveyService, private auth: AuthenticationService, private router: Router, private toastr: ToasterService) { }

    getSurvey(): Observable<SurveySummaryViewModelRead> {
      return this.surveyService.getSurveySummaryById(this.surveyId).pipe(map(survey => {
        this.survey = survey;
        return survey;
      }));
    }

    handleSurvey() {
      this.auth.fetchUserProfile().subscribe(user => {
        if (this.auth.hasClaimForRole([UserRole.Runner]) && this.auth.hasClaimForRole(coachUserRoleArray)) {
          this.showSurvey = true;
        }

        if (this.showSurvey) {
          this.router.navigate(['/runner/assessments/surveys/add/', this.survey.id]);
        } else {
          if (this.auth.user.hasActiveCoachAthleteOrg) {
            this.toastr.okModal('To access this feature, switch to your coach account.', this.survey.name).subscribe();
          } else {
            this.toastr.okModal('To access this feature, you will need your own paid account. Go <a target="_blank" href="/public/survey">here<a/> to learn more about ' + this.survey.name + ' and create your own account.', this.survey.name).subscribe();
          }
        }
      });
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'coach-loading-levels-details',
  templateUrl: 'loading-levels-details.component.html',
})
export class LoadingLevelsDetailsComponent implements OnInit {
  initialized = false;
  assessmentId: number;
  listURL: string;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.listURL = '/athletes/details/' + getParamFromRoute(this.route, 'id') + '/assessments';
    this.breadcrumbs.SetSecondaryBreadcrumb('Loading Levels Results', 'assessments/loading-levels/view/' + this.assessmentId, []);
    this.initialized = true;
  }
}

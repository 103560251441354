<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Send Message</h5>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="onSave()">
    <div class="modal-body">
      <div class="row no-gutters">
        <div class="col-md-6">
          <label> To user: </label>
          <kendo-combobox #userCombo [placeholder]="'(select user)'" [data]="userComboData" [valuePrimitive]="true" [filterable]="true" valueField="id" textField="name" class="form-control" formControlName="to" (filterChange)="handleUserFilter($event)"></kendo-combobox>
        </div>
      </div>
      <div class="row no-gutter mt-2">
        <div class="col-md-6">
          <label> Or entire team: </label>
          <team-dropdown [placeholder]="'(select team)'" formControlName="toTeamId"></team-dropdown>
        </div>
        <div class="col-md-12">
          <div *ngIf="formGroup.dirty && formGroup.errors?.toRequired" class="invalid-message-visible">
            <div>Must select a user or a team to message.</div>
          </div>
          <div *ngIf="formGroup.dirty && formGroup.errors?.toDuplicate" class="invalid-message-visible">
            <div>Cannot select both a team and user to message at same time.</div>
          </div>
        </div>
      </div>
      <div class="row no-gutters mt-2">
        <div class="col-md-12">
          <label> Subject: </label>
          <input class="form-control" formControlName="subject" type="text" />
          <div class="invalid-message">
            <div>Subject is required</div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <label> Body: </label>
          <textarea class="form-control" formControlName="body"> </textarea>
          <div class="invalid-message">
            <div>Body is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col text-left pl-0">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cross click')">Cancel</button>
      </div>
      <div class="col text-right pr-0">
        <button type="submit" class="btn btn-primary" [promiseBtn]="submitComplete">Send</button>
      </div>
    </div>
  </form>
</div>

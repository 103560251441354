<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Event</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="isRecurring && !cannotDelete" class="modal-body">
    <div class="col-md-12 col-lg-6">
      <div class="form-group">
        <label class="d-block">
          <input type="radio" value="event" [(ngModel)]="deleteEventType" />
          <span>This event</span>
        </label>
        <label class="d-block">
          <input type="radio" value="series" [(ngModel)]="deleteEventType" />
          <span>All events in series</span>
        </label>
      </div>
    </div>
    <div *ngIf="deleteEventType == 'series'" class="col-md-12">Are you sure you want to delete all (non-completed) events in this series? This action cannot be undone.</div>
  </div>
  <div *ngIf="!isRecurring && !cannotDelete" class="modal-body">
    <div class="col-md-12">Are you sure you want to delete this event? This action cannot be undone.</div>
  </div>
  <div *ngIf="cannotDelete" class="modal-body">
    <div class="col-md-12">{{cannotDeleteError}}</div>
  </div>
  <div *ngIf="!cannotDelete" class="modal-footer">
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
      </div>
      <div class="col text-right">
        <button type="button" class="btn btn-primary" (click)="onDelete()">Delete</button>
      </div>
    </div>
  </div>
  <div *ngIf="cannotDelete" class="modal-footer">
    <div class="row">
      <div class="col">
      </div>
      <div class="col text-right">
        <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('cancel')">OK</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientsService, GarminService } from 'src/app/_services/generatedServices';
import { ClientProgramWorkoutViewModel, ClientFinishWorkoutViewModel, ClientDayEventType, ActivityType, ClientProgramWorkoutDayActivityTypeDurationViewModel, ClientUpdateWorkoutRPEViewModel, ClientAddWorkoutMessageViewModel, ClientUpdateWorkoutDurationsViewModel, ClientAddWorkoutNoteViewModel, WorkoutType, MeasurementSystem, ExerciseResistanceType, ClientProgramWorkoutDayExerciseProgressViewModelRead, ClientProgramWorkoutDayExerciseProgressViewModel, ClientUpdateWorkoutExerciseProgressesViewModel, StringViewModel } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { Enums } from 'src/app/_models/generatedEnums';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromMilesToLocalMeasurementPipe, MilesKilometersToMilesPipe, dynamicSort, localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { WorkoutLevelChangeModalComponent } from '../workout-level-change-modal/workout-level-change-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatchSyncedWorkoutDialogComponent } from '../match-synced-workout-dialog/match-synced-workout-dialog.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'runner-workout-view',
  templateUrl: 'runner-workout-view.component.html',
  styleUrls: ['runner-workout-view.component.scss']
})
export class RunnerWorkoutViewComponent implements OnInit {
  initialized = false;
  clientWorkout: ClientProgramWorkoutViewModel;
  activityTypeEnum = Enums.ActivityTypeEnum;
  activityType = ActivityType;
  eventType = ClientDayEventType;
  public durationFormGroup: FormGroup;
  newWorkoutJournal: string;
  workoutType = WorkoutType;
  isGarminWorkoutType: boolean = false;
  isMetricSystem: boolean = false;
  localDistanceName: string;
  saveExerciseProgress: boolean = false;
  exerciseResistanceType = ExerciseResistanceType;
  exerciseResistanceTypeEnum = Enums.ExerciseResistanceTypeEnum;
  weightUOMEnum = Enums.WeightUOMEnum;
  public exerciseProgressFormGroup: FormGroup;
  syncedWorkoutNameChanged: Subject<string> = new Subject<string>();

  RPERequired: boolean = false;
  showRPEError: boolean = false;

  // explicitly need this incase current Org view has different clientUserId than the clientProgramWorkoutDay
  @Input()
  clientUserId: number;

  @Input()
  clientProgramWorkoutDayId: number;

  @Input()
  isCoachView: boolean = false;

  @Input()
  isSummaryOnly: boolean = false;

  @Output()
  saved = new EventEmitter<boolean>();

  @Output()
  syncedWorkoutNameUpdated = new EventEmitter<boolean>();

  constructor(private clientService: ClientsService, private toastr: ToasterService, private router: Router, private fb: FormBuilder, private modalService: NgbModal,
      private garminService: GarminService, private auth: AuthenticationService, private fromMilesToLocalMeasurementPipe: FromMilesToLocalMeasurementPipe, private milesKilometersToMilesPipe: MilesKilometersToMilesPipe) { }

  ngOnInit(): void {
    this.isMetricSystem = this.auth.getMeasurementSystem() == MeasurementSystem.Metric;
    this.localDistanceName = localMeasurementDistanceName();

    this.syncedWorkoutNameChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((name) => {
      let model = new StringViewModel();
      model.value = name;
      this.clientService.updateClientProgramWorkoutDaySyncedWorkoutName(this.clientProgramWorkoutDayId, model).subscribe(result => {
        // seems clunky to do it this way, but we need to refresh the name on the calendar and there really isn't a way to wait until the modal is closed
        this.syncedWorkoutNameUpdated.emit(true);
      });
    });

    this.loadData();
  }

  loadData() {
    forkJoin([this.clientService.getClientProgramWorkoutDetails(this.clientProgramWorkoutDayId), this.clientService.isRPERequiredForClientProgramWorkoutDay(this.clientProgramWorkoutDayId)]).subscribe(result => {
      this.clientWorkout = result[0];
      this.isGarminWorkoutType = (this.clientWorkout.workoutId && (this.clientWorkout.workout.workoutType == WorkoutType.GarminCycling || this.clientWorkout.workout.workoutType == WorkoutType.GarminRunning || this.clientWorkout.workout.workoutType == WorkoutType.GarminLap_Swimming));
      this.RPERequired = result[1];
      this.setupForms();
      this.initialized = true;
    });
  }

  setupForms() {
    let activityTypeDurations = this.fb.array([]);

    this.clientWorkout.activityTypeDurations.sort(dynamicSort('activityType')).forEach(item => {
      let distance = item.distance;
      if (this.isMetricSystem) {
        distance = this.fromMilesToLocalMeasurementPipe.transform(distance);
      }

      activityTypeDurations.push(this.fb.group({
        activityType: [item.activityType],
        duration: [item.duration, Validators.required],
        distance: [distance],
      }));
    });

    this.durationFormGroup = this.fb.group({
      activityTypeDurations: activityTypeDurations
    });

    //exercise Progresses
    let exerciseProgresses = this.fb.array([]);

    this.clientWorkout.exerciseProgresses.sort(dynamicSort('sortOrder')).forEach(item => {

      let exerciseProgressSets = this.fb.array([]);
      item.exerciseProgressSets.forEach(set => {
        exerciseProgressSets.push(this.fb.group({
          id: [set.id],
          setNumber: [set.setNumber],
          reps: [set.reps],
          weight: [set.weight],
          resistance: [set.resistance],
          hold: [set.hold],
          isCompleted: [set.isCompleted]
        }));
      });

      exerciseProgresses.push(this.fb.group({
        id: [item.id],
        exerciseName: [item.exerciseName],
        resistanceType: [item.resistanceType],
        weightUOM: [item.weightUOM],
        exerciseProgressSets: exerciseProgressSets
      }));
    });

    this.exerciseProgressFormGroup = this.fb.group({
      exerciseProgresses: exerciseProgresses
    });

  }

  saveMessage() {
    const model: ClientAddWorkoutMessageViewModel = {
      messageBody: this.newWorkoutJournal,
      isMessageFromCoach: this.isCoachView
    }

    this.clientService.addClientProgramWorkoutDayMessage(this.clientProgramWorkoutDayId, model).subscribe(result => {
      this.toastr.success('Message Sent', 'Success');
      this.refreshJournals();
    });
  }

  saveNote() {
    const model: ClientAddWorkoutNoteViewModel = {
      note: this.newWorkoutJournal
    }

    this.clientService.addClientProgramWorkoutDayNote(this.clientProgramWorkoutDayId, model).subscribe(result => {
      this.toastr.success('Note Added', 'Success');
      this.refreshJournals();
    });
  }

  refreshJournals() {
    // clear message field and refresh message list
    this.newWorkoutJournal = null;
    this.clientService.getWorkoutJournalsForClientProgramWorkoutDayId(this.clientProgramWorkoutDayId).subscribe(journals => {
      this.clientWorkout.workoutJournals = journals;
    });
  }

  finishWorkout() {
    if (this.RPERequired && !this.clientWorkout.ratePerceivedExertion) {
      this.showRPEError = true;
      return;
    }

    if (this.newWorkoutJournal) {
      this.toastr.okDialog('Please add workout journal, or clear the field before continuing.', 'Workout Journal').subscribe(result => {
      });
      return;
    }

    if (!this.durationFormGroup.valid) {
      this.durationFormGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.toastr.confirmDialog('Are you sure you want to mark the workout as finished?', 'Finish Workout', 'Finish Workout', 'Cancel').subscribe(result => {
      if (result) {
        if (this.clientWorkout.workoutId) {
          // after exercise progress is saved (if there is any to save) and event is emitted, then the workout will be marked as completed
          this.saveExerciseProgress = true;
        } else {
          this.onExerciseProgressSaved();
        }
      }
    });
  }

  onExerciseProgressSaved() {
    const activityTypeDurations = this.getDurationsInMiles();
    const model: ClientFinishWorkoutViewModel = {
      messageBody: null,
      isMessageFromCoach: this.isCoachView,
      isCompleted: true,
      dateCompleted: new Date(),
      ratePerceivedExertion: (this.RPERequired ? this.clientWorkout.ratePerceivedExertion : null),
      activityTypeDurations: activityTypeDurations
    }

    this.clientService.updateClientProgramWorkoutDayIsCompleted(this.clientProgramWorkoutDayId, model).subscribe(results => {
      this.toastr.success('Workout Finished', 'Success');
      this.clientWorkout.isCompleted = true;
      this.saved.emit(true);
    });
  }

  incompleteWorkout() {
    if (this.newWorkoutJournal) {
      this.toastr.okDialog('Please add workout journal, or clear the field before continuing.', 'Workout Journal').subscribe(result => {
      });
      return;
    }

    let exerciseProgressMessage = '';
    if (this.clientWorkout.exerciseProgresses && this.clientWorkout.exerciseProgresses.length > 0) {
      exerciseProgressMessage = ' This will also clear any exercise sets you have recorded for this workout.';
    }

    this.toastr.confirmDialog('Are you sure you want to mark the workout as NOT complete?' + exerciseProgressMessage, 'Incomplete Workout', 'Mark Incomplete', 'Cancel').subscribe(result => {
      if (result) {
        const model: ClientFinishWorkoutViewModel = {
          messageBody: null,
          isMessageFromCoach: this.isCoachView,
          isCompleted: false,
          dateCompleted: null,
          ratePerceivedExertion: null,
          activityTypeDurations: []
        }

        this.clientService.updateClientProgramWorkoutDayIsCompleted(this.clientProgramWorkoutDayId, model).subscribe(results => {
          this.toastr.success('Workout Marked Incompelete', 'Success');
          this.clientWorkout.isCompleted = false;
          this.saved.emit(true);
        });
      } else {
        // do nothing
      }
    });
  }

  viewDetails() {
    // using this to close the modal
    this.saved.emit(false);
    if (this.isCoachView) {
      this.router.navigate(['/athletes/details', this.clientUserId, 'workout', this.clientProgramWorkoutDayId]);
    } else {
      this.router.navigate(['/runner/workout', this.clientProgramWorkoutDayId]);
    }

  }

  onRPEChanged() {
    this.showRPEError = false;
  }

  updateRPE() {
    const model: ClientUpdateWorkoutRPEViewModel = {
      ratePerceivedExertion: this.clientWorkout.ratePerceivedExertion,
    }

    this.clientService.updateClientProgramWorkoutDayRPE(this.clientProgramWorkoutDayId, model).subscribe(results => {
      this.toastr.success('Rate of Perceived Exertion Updated', 'Success');
      this.saved.emit(true);
    });
  }

  updateDurations() {
    const activityTypeDurations = this.getDurationsInMiles();
    const model: ClientUpdateWorkoutDurationsViewModel = {
      activityTypeDurations: activityTypeDurations
    }

    this.clientService.updateClientProgramWorkoutDayActivityTypeDurations(this.clientProgramWorkoutDayId, model).subscribe(results => {
      this.toastr.success('Durations Updated', 'Success');
      this.saved.emit(true);
    });
  }

  onUnmatchWorkout() {
    this.toastr.confirmDialog('Would you like to unmatch this workout? The scheduled workout and synced workout will be separated into two workouts.', 'Unmatch Workout', 'Unmatch Workout', 'Cancel').subscribe(result => {
      if (result) {
        this.clientService.unmatchClientProgramWorkoutDay(this.clientProgramWorkoutDayId).subscribe(result => {
          this.toastr.success('Workout Unmatched', 'Success');
          this.loadData();
          this.saved.emit(true);
        });
      }
    });
  }

  onMatchWorkout() {
    const modalRef = this.modalService.open(MatchSyncedWorkoutDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
    modalRef.componentInstance.workoutToMatch = this.clientWorkout;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.saved.emit(true);
        }
      }
    );
  }

  levelChange(changeType: string, levelChangeWorkoutId) {
    const modalRef = this.modalService.open(WorkoutLevelChangeModalComponent, { size: 'lg' });
    modalRef.componentInstance.levelChangeWorkoutId = levelChangeWorkoutId;
    modalRef.componentInstance.isLevelUp = changeType == 'up';
    modalRef.componentInstance.currentClientProgramWorkoutDay = this.clientWorkout;
    modalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason == 'saved') {
          this.saved.emit(true);
        }
      }
    );
  }

  onSendWorkoutToGarmin() {
    this.garminService.createGarminTrainingWorkoutScheduleAndCheckForWorkout(this.clientWorkout.workoutId, this.clientProgramWorkoutDayId).subscribe(result => {
      this.toastr.success('Workout Sent to Garmin Connect', 'Success');
      this.loadData();
    });
  }

  getDurationsInMiles() : ClientProgramWorkoutDayActivityTypeDurationViewModel[] {
    let activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[] = Object.assign([], this.durationFormGroup.get('activityTypeDurations').value);
    if (this.isMetricSystem) {
      activityTypeDurations.forEach(item => {
        item.distance = this.milesKilometersToMilesPipe.transform(0, item.distance);
      });
    }
    return activityTypeDurations;
  }

  onResistanceTypeChanged(event: any, exerciseProgressIndex: number) {
    let exerciseProgresses = this.exerciseProgressFormGroup.get('exerciseProgresses') as FormArray;
    let exerciseProgressSets = exerciseProgresses.at(exerciseProgressIndex).get('exerciseProgressSets') as FormArray;

    for (var i = 0; i < exerciseProgressSets.length; i++) {
      exerciseProgressSets.at(i).get('resistance').setValue(null);
      exerciseProgressSets.at(i).get('weight').setValue(null);
      exerciseProgressSets.at(i).get('hold').setValue(null);
    }
  }

  updateExerciseSets() {
    let exerciseProgresses: ClientProgramWorkoutDayExerciseProgressViewModelRead[] = Object.assign([], this.exerciseProgressFormGroup.get('exerciseProgresses').value);
    const model: ClientUpdateWorkoutExerciseProgressesViewModel = {
      exerciseProgresses: exerciseProgresses
    }

    this.clientService.updateClientProgramWorkoutDayExerciseProgresses(this.clientProgramWorkoutDayId, model).subscribe(results => {
      this.toastr.success('Exercise Sets Updated', 'Success');
    });
  }

  onSyncedWorkoutNameChanged(name: string) {
    this.syncedWorkoutNameChanged.next(name);
  }
}

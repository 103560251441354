<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div *ngIf="clientProgramWorkoutDayId" class="float-right" >
    <span *ngIf="!clientWorkoutIsCompleted && workout.hasExercises" class="text-white"><label class="toggle-label"> Track Sets</label> <kendo-switch  [(ngModel)]="showSets"></kendo-switch></span>
  </div>
  <div>
    <a class="main-toggle" (click)="showWorkoutInfo = !showWorkoutInfo" href="javascript:void(0)"><i class="fas white" [ngClass]="{ 'fa-chevron-down': showWorkoutInfo, 'fa-chevron-right' : !showWorkoutInfo }"></i></a>
  <h3 class="text-white d-inline">{{workout.name}}</h3> 
  </div>
  
  <div class="card p-3" [hidden]="!showWorkoutInfo">
    <div *ngIf="workout.workoutCategory">
      <span class="font-weight-bold">Category: </span><span>{{workout.workoutCategory | enumString: workoutCategoryEnum}}</span>
    </div>
    <div *ngIf="workout.expectedTime">
      <span class="font-weight-bold">Expected Duration: </span><span>{{workout.expectedTime | secsToHourMinSec}}</span>
    </div>
    <div *ngIf="workout.expectedRPE">
      <span class="font-weight-bold">Expected RPE: </span><span>{{workout.expectedRPE}}</span>
    </div>
    <div *ngIf="workout.workoutKeywords.length > 0">
      <span class="font-weight-bold">Keywords: </span>
      <span *ngFor="let keyword of workout.workoutKeywords; let last = last">
        <span>{{ keyword.name }}{{ last ? '' : ', ' }}</span>
      </span>
    </div>
    <div>
      <span class="font-weight-bold">Goal: </span><span>{{workout.goal}}</span>
    </div>
    <div class="font-weight-bold">Description:</div>
    <span [editorHTML]="workout.description"></span>
    <div *ngIf="workout.videoId || workout.videoLink">
      <embed-video-player [url]="workout.videoLink" [videoId]="workout.videoId" [size]="'small'"></embed-video-player>
    </div>
    <div *ngIf="workout.garminPoolLength">
      <span class="font-weight-bold">Pool Length: </span><span>{{workout.garminPoolLength}}</span><span *ngIf="workout.garminPoolLengthUnit"> {{workout.garminPoolLengthUnit | enumString: garminPoolLengthUnitEnum}}</span>
    </div>
  </div>

  <ng-container *ngFor="let item of workoutItems">
    <div class="card">
      <div class="card-body">
        <workout-item-view [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [isWorkoutPreview]="isWorkoutPreview" [disabled]="clientWorkoutIsCompleted || isWorkoutPreview" [workoutItem]="item" (toggleCompleteChanged)="onToggleCompleteChanged($event)"></workout-item-view>

        <ng-container *ngFor="let detail of item.workoutItemDetails">
          <workout-item-detail-view [detail]="detail" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [exerciseProgressSortOrder]="detail.exerciseProgress ? detail.exerciseProgress.sortOrder : 0" [isWorkoutPreview]="isWorkoutPreview" [disabled]="clientWorkoutIsCompleted || isWorkoutPreview" [workoutItem]="item" (levelChanged)="onLevelChanged($event)" (toggleCompleteChanged)="onToggleCompleteChanged($event)"></workout-item-detail-view>

          <ng-container *ngIf="detail.exerciseProgress && showSets">
            <div class="row">
              <div class="col-lg-1 col-2">
                Set
              </div>
              <div class="col-lg-2 col-3">
                Reps
              </div>
              <div class="col-md-4 col-5">
                <select  class="resistance-type" [(ngModel)]="detail.exerciseProgress.resistanceType" (ngModelChange)="onResistanceTypeChanged($event, item.id, detail.id)">
                  <ng-container *ngFor="let mapEntry of exerciseResistanceTypeEnum | keyvalue">
                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
                <span *ngIf="detail.exerciseProgress.resistanceType == exerciseResistanceType.Weight" class="ml-2">{{ detail.exerciseProgress.weightUOM | enumString: weightUOMEnum }}</span>
                <span *ngIf="detail.exerciseProgress.resistanceType == exerciseResistanceType.Hold" class="ml-2">(sec)</span>
              </div>
              <div class="col-md-2 col-2 mobile-padding">
                Done
              </div>
            </div>
            <ng-container *ngFor="let exerciseSet of detail.exerciseProgress.exerciseProgressSets">
              <div class="row">
                <div class="col-lg-1 col-2 set-number">
                  {{ exerciseSet.setNumber }}
                </div>
                <div class="col-lg-2 col-3">
                  <kendo-numerictextbox noScroll class="form-control no-dial xs-textbox" [(ngModel)]="exerciseSet.reps" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                </div>
                <div *ngIf="detail.exerciseProgress.resistanceType == exerciseResistanceType.Resistance" class="col-md-4 col-5">
                  <input class="form-control" [(ngModel)]="exerciseSet.resistance" maxlength="50" type="text" />
                </div>
                <div *ngIf="detail.exerciseProgress.resistanceType == exerciseResistanceType.Weight" class="col-md-4 col-5">
                  <kendo-numerictextbox noScroll class="form-control no-dial small-textbox" [(ngModel)]="exerciseSet.weight" [decimals]="'1'" [format]="'#.#'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                </div>
                <div *ngIf="detail.exerciseProgress.resistanceType == exerciseResistanceType.Hold" class="col-md-4 col-5">
                  <kendo-numerictextbox noScroll class="form-control no-dial xs-textbox" [(ngModel)]="exerciseSet.hold" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                </div>
                <div class="col-md-2 col-2">
                  <input style="vertical-align: bottom;" class="d-inline-block single-checkbox" [(ngModel)]="exerciseSet.isCompleted" type="checkbox" />
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-12 progress-icons">
                <a href="javascript:void(0)" (click)="onDeleteSet(detail.exerciseProgress)">
                  <i class="fas fa-minus-square"></i>
                </a>
                <a href="javascript:void(0)" (click)="onAddSet(detail.exerciseProgress)">
                  <i class="fas fa-plus-square ml-2"></i>
                </a>
              </div>
            </div>
          </ng-container>
          
        </ng-container>
      </div>
    </div>
  </ng-container>

</div>

import { Component, Input, Output, AfterContentInit, ViewChild, forwardRef, ElementRef, EventEmitter  } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrganizationService } from '../_services/generatedServices';
import { AuthenticationService } from '../_services/authentication.service';
import { UserProfileViewModel } from '../_models/generatedModels';
import { dynamicSort } from '../_helpers/extensions.module';
import { BasicObject } from '../_models/models';


@Component({
    selector: 'coaches-library-dropdown-persisted',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <kendo-combobox #combo [data]="coachData" [disabled]="isDisabled" [valuePrimitive]="true" [filterable]="true" [popupSettings]="{popupClass: 'df-list-scroller'}" valueField="id" textField="name" [(ngModel)]="selectedValue" class="{{cssClass}}" (filterChange)="handleFilter($event)" (valueChange)="onChange($event)">

    </kendo-combobox>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoachLibraryDropdownPersistedControl),
            multi: true
        }
    ]
})

export class CoachLibraryDropdownPersistedControl implements ControlValueAccessor, AfterContentInit {

    someAttr: string = 'anyValue';
    selectedValue: any;
    localStorageKey: string;

    constructor(elementRef: ElementRef, private orgService: OrganizationService, private auth: AuthenticationService) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }


    @Input()
    key: string = null;

    @Input()
    isDisabled: boolean;

    @Output()
    valueChange = new EventEmitter<string>();

    coachData: BasicObject[] = [];
    coaches: BasicObject[] = [];
    @ViewChild('combo') public combo: ComboBoxComponent | undefined;
    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        if (this.localStorageKey) {
            localStorage.setItem(this.localStorageKey, value);
        }
        this.valueChange.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngAfterContentInit(): void {
      this.orgService.getOrgLibraryUsers().subscribe(results => {
        this.coaches = results.sort(dynamicSort('fullName')).map(x => new BasicObject(x.id, x.fullName));
        this.coaches.push(new BasicObject(0, 'RunDNA'));
        this.coachData = this.coaches;

        // if there is a key and a persisted value, default the field to that
        this.localStorageKey = this.key ? 'rundna-persistedCoachLibrary-' + this.key : null;
        if (this.localStorageKey && localStorage.getItem(this.localStorageKey)) {
            this.onChange(Number(localStorage.getItem(this.localStorageKey)));
        }
      });
  }

    handleFilter(value: any) {
      if (value.length >= 1) {
        this.coachData = this.coaches.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
        this.coachData = this.coaches;
        this.combo.toggle(false);
    }
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 d-inline">Athlete List</h3> <span class="ml-3">(Org's Active Athlete Count: {{runnerCount}})</span>
      </div>

      <div class="d-inline-block float-right">
        <a href="javascript:void(0)" class="btn btn-primary" (click)="onAddAthlete()"> New Athlete</a>
      </div>
    </div>
    <div class="row mt-3">
      <div [ngClass]="{ 'col-lg-6 col-md-7': showAthleteTypeFilter, 'col-md-9': !showAthleteTypeFilter }">
        <div class="form-group">
          <label class="form-control-label"> &nbsp; </label>
          <input type="text" class="form-control" placeholder="Search by athlete name or email" [(ngModel)]="filter" (ngModelChange)="onFilterChanged($event)" />
        </div>
      </div>
      <div [ngClass]="{ 'col-lg-3 col-md-5': showAthleteTypeFilter, 'col-md-3': !showAthleteTypeFilter }">
        <div class="form-group">
          <label class="form-control-label"> Status </label>
          <select class="form-control" [(ngModel)]="status" (ngModelChange)="onStatusChanged($event)">
            <option value="1">Active</option>
            <option value="2">In-Active</option>
            <option value="3">Invited</option>
          </select>
        </div>
      </div>
      <div *ngIf="showAthleteTypeFilter" class="col-lg-3 col-md-5">
        <div class="form-group">
          <label class="form-control-label"> Athlete Type </label>
          <select class="form-control" [(ngModel)]="athleteType" (ngModelChange)="onTypeChanged($event)">
            <option value="1">Coached</option>
            <option value="2">Marketplace</option>
          </select>
        </div>
      </div>
    </div>
    <page-load-header *ngIf="searching"></page-load-header>
    <div *ngIf="!searching && (clients && clients.length == 0)" class="row mt-3">
      <div class="col text-center">
        <h6>Click "New Athlete" button above to add your first Athlete</h6>
      </div>
    </div>
    <div [hidden]="searching" *ngIf="(clients && clients.length > 0)" class="row mt-3">
      <div class="col">
        <div class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row sortable two-line-header">
                <th (click)="clients.sort('Credential.FullName')" [ngClass]="clients.getCSSClass('Credential.FullName')">Name</th>

                <th (click)="clients.sort('Credential.Stats.NextScheduledEvent')" [ngClass]="clients.getCSSClass('Credential.Stats.NextScheduledEvent')">Next Event</th>

                <!-- <th class="sort" data-sort="status" scope="col">Status</th> -->

                <!-- <th scope="col">Teammates</th> -->

                <th (click)="clients.sort('Credential.Stats.CompletionPercentage')" [ngClass]="clients.getCSSClass('Credential.Stats.CompletionPercentage')">
                  Completion % <br />
                  (month)
                </th>

                <th (click)="clients.sort('Credential.Stats.DailyStatusPainDays')" [ngClass]="clients.getCSSClass('Credential.Stats.DailyStatusPainDays')">
                  Reported Pain <br />
                  Days (week)
                </th>

                <!-- <th (click)="clients.sort('DailyStatusPercentEffortMin')" [ngClass]="clients.getCSSClass('DailyStatusPercentEffortMin')">
                  Reported Max Effort <br />
                  (week)
                </th> -->

                <th (click)="clients.sort('Credential.Stats.AcuteChronicWorkloadRatioPercentage')" [ngClass]="clients.getCSSClass('Credential.Stats.AcuteChronicWorkloadRatioPercentage')">
                  ACWR %
                </th>

                <th (click)="clients.sort('Credential.Stats.LastScheduledWorkoutDate')" [ngClass]="clients.getCSSClass('Credential.Stats.LastScheduledWorkoutDate')">
                  Scheduled <br />
                  Until
                </th>

                <th (click)="clients.sort('CoachUser.Credential.FullName')" [ngClass]="clients.getCSSClass('CoachUser.Credential.FullName')">Coach</th>

                <th class="text-lg-center">Actions</th>
              </tr>
            </thead>

            <tbody class="list">
              <tr *ngFor="let client of clients">
                <th scope="row">
                  <div [routerLink]="client.status == clientStatus.Active ? ['../details', client.userId] : []" class="media align-items-center" style="cursor: pointer">
                    <a class="avatar rounded-circle mr-3" href="javascript:void(0)">
                      <img alt="Image placeholder" src="fetch-avatar?userId={{ client.userId }}" />
                    </a>

                    <div class="media-body">
                      <span class="name mb-0 text-sm"> {{ client.userFullName }} </span>
                      <div class="font-weight-normal">{{ client.email }}</div>
                    </div>
                  </div>
                </th>

                <td class="budget">
                  <span class="mobile-grid-label">Next Event</span>
                  {{ client.nextScheduledEvent | date }}
                </td>

                <!--                 <td>
                  <span class="badge badge-dot mr-4">
                    <i class="bg-sucess"> </i>

                    <span class="status"> Active </span>
                  </span>
                </td> -->

                <!--                 <td>
                  <div class="avatar-group">
                    <a class="avatar avatar-sm rounded-circle" tooltip="Ryan Tompson" placement="top" href="javascript:void(0)">
                      <img alt="Image placeholder" src="assets/img/theme/team-1.jpg" />
                    </a>

                    <a class="avatar avatar-sm rounded-circle" tooltip="Romina Hadid" placement="top" href="javascript:void(0)">
                      <img alt="Image placeholder" src="assets/img/theme/team-2.jpg" />
                    </a>

                    <a class="avatar avatar-sm rounded-circle" tooltip="Alexander Smith" placement="top" href="javascript:void(0)">
                      <img alt="Image placeholder" src="assets/img/theme/team-3.jpg" />
                    </a>

                    <a class="avatar avatar-sm rounded-circle" tooltip="Jessica Doe" placement="top" href="javascript:void(0)">
                      <img alt="Image placeholder" src="assets/img/theme/team-4.jpg" />
                    </a>
                  </div>
                </td> -->

                <td>
                  <span class="mobile-grid-label">Completion % (month)</span>
                  <div class="d-flex align-items-center">
                    <span class="completion mr-2" [ngClass]="(client.completionPercentage != null ? (client.completionPercentage * 100) : null) | completionPercentRangeColor">{{ client.completionPercentage != null ? (client.completionPercentage * 100 | number: '1.2-2') + '%' : 'N/A' }}</span>
                    <div>
                      <ngb-progressbar *ngIf="client.completionPercentage != null" type="dark" [value]="client.completionPercentage * 100"></ngb-progressbar>
                    </div>
                  </div>
                </td>

                <td>
                  <span class="mobile-grid-label">Reported Pain Days (week)</span>
                  {{ client.dailyStatusPainDays }}
                </td>

                <!-- <td>
                  <span class="mobile-grid-label">Reported Max Effort (week)</span>
                  {{ client.dailyStatusPercentEffortMin }} - {{ client.dailyStatusPercentEffortMax }}%
                </td> -->

                <td>
                  <span class="mobile-grid-label">ACWR %</span>
                  {{ client.acuteChronicWorkloadRatioPercentage != null ? (client.acuteChronicWorkloadRatioPercentage * 100 | number: '1.0-0') + '%' : 'N/A'}}
                </td>

                <td>
                  <span class="mobile-grid-label">Scheduled Until</span>
                  {{ client.lastScheduledWorkoutDate | date }}
                </td>

                <td class="budget">
                  <span class="mobile-grid-label">Coach</span>
                  {{ client.coachFullName }}
                </td>

                <td class="text-lg-center">
                  <span class="mobile-grid-label">Actions</span>
                  <div class="btn-group no-caret">
                    <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"> </i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg-right">
                      <a *ngIf="client.status == clientStatus.Active" href="javascript:void(0)" [routerLink]="['../details', client.userId]" class="dropdown-item"> View Details </a>
                      <a *ngIf="client.status != clientStatus.Active" href="javascript:void(0)" (click)="viewEmailAddress(client.userFullName, client.email)" class="dropdown-item"> View Email Address </a>
                      <a *ngIf="client.status != clientStatus.Invited" href="javascript:void(0)" (click)="onSendPasswordReset(client.userId, client.email, client.userFullName)" class="dropdown-item"> Send Password Reset Email </a>
                      <a *ngIf="client.status == clientStatus.Invited" href="javascript:void(0)" (click)="onSendInvitation(client.userId, client.email, client.userFullName)" class="dropdown-item"> Re-send Invitation </a>
                      <a *ngIf="client.status == clientStatus.Active" href="javascript:void(0)" (click)="deactivate(client.userId, client.userRole, client.athleteType)" class="dropdown-item"> De-Activate </a>
                      <a *ngIf="client.status == clientStatus.InActive" href="javascript:void(0)" (click)="activate(client.userId)" class="dropdown-item"> Activate </a>
                      <a *ngIf="client.status == clientStatus.Invited" href="javascript:void(0)" (click)="deleteAthlete(client.userId)" class="dropdown-item"> Delete </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
            <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="initialized">
  <div class="card">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Daily Status</h3>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>

      <div class="card-body">
        <div  class="row">
          <div class="col-lg-8">
            <h5 *ngIf="!dailyStatusId">Please fill out your daily status for today</h5>
            <h5 *ngIf="dailyStatusId">{{entry.dailyStatusDate | date}} - {{entry.userFullName}}</h5>
          </div>
          <div *ngIf="lastDayStatusId" class="col text-right">
            <button (click)="onCopyLastDay()" class="btn btn-primary">Copy last day</button>
          </div>
        </div>
        
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="ableToRun"> Are you physically able to run? </label>
                <div class="btn-group btn-group-toggle">
                  <label [ngClass]="{'active': formGroup.get('ableToRun').value == true}" class="btn btn-secondary"> <input type="radio" (change)="onChangeAbleToRun()" formControlName="ableToRun" [value]="true" /> Yes </label>
                  <label [ngClass]="{'active': formGroup.get('ableToRun').value == false}" class="btn btn-secondary"> <input type="radio" (change)="onChangeAbleToRun()" formControlName="ableToRun" [value]="false" /> No </label>
                </div>
                <div *ngIf="formGroup.controls['ableToRun'].dirty && formGroup.controls['ableToRun'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('ableToRun').value == false" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="runningInjury"> Is it a running related injury? </label>
                <div class="btn-group btn-group-toggle">
                  <label [ngClass]="{'active': formGroup.get('runningInjury').value == true}" class="btn btn-secondary"> <input type="radio" formControlName="runningInjury" [value]="true" /> Yes </label>
                  <label [ngClass]="{'active': formGroup.get('runningInjury').value == false}" class="btn btn-secondary"> <input type="radio" formControlName="runningInjury" [value]="false" /> No </label>
                </div>
                <div *ngIf="formGroup.controls['runningInjury'].dirty && formGroup.controls['runningInjury'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="isSick"> Are you sick? </label>
                <div class="btn-group btn-group-toggle">
                  <label [ngClass]="{'active': formGroup.get('isSick').value == true}" class="btn btn-secondary"> <input type="radio" formControlName="isSick" [value]="true" /> Yes </label>
                  <label [ngClass]="{'active': formGroup.get('isSick').value == false}" class="btn btn-secondary"> <input type="radio" formControlName="isSick" [value]="false" /> No </label>
                </div>
                <div *ngIf="formGroup.controls['isSick'].dirty && formGroup.controls['isSick'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="percentEffort"> If you had to run at your highest level today, what percentage would you be able to give? </label>
                <select class="form-control" style="width: 150px;" formControlName="percentEffort">
                  <option [ngValue]="null">(select one)</option>
                  <option [ngValue]="100">100%</option>
                  <option [ngValue]="95">95%</option>
                  <option [ngValue]="90">90%</option>
                  <option [ngValue]="85">85%</option>
                  <option [ngValue]="80">80%</option>
                  <option [ngValue]="75">75%</option>
                  <option [ngValue]="70">70%</option>
                  <option [ngValue]="65">65%</option>
                  <option [ngValue]="60">60%</option>
                  <option [ngValue]="55">55%</option>
                  <option [ngValue]="50">50%</option>
                  <option [ngValue]="45">45%</option>
                  <option [ngValue]="40">40%</option>
                  <option [ngValue]="35">35%</option>
                  <option [ngValue]="30">30%</option>
                  <option [ngValue]="25">25%</option>
                  <option [ngValue]="20">20%</option>
                  <option [ngValue]="15">15%</option>
                  <option [ngValue]="10">10%</option>
                  <option [ngValue]="5">5%</option>
                  <option [ngValue]="0">0%</option>
                </select>
                <div *ngIf="formGroup.controls['percentEffort'].dirty && formGroup.controls['percentEffort'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="hasPain"> Do you have pain, either at rest or with activity? </label>
                <div class="btn-group btn-group-toggle">
                  <label [ngClass]="{'active': formGroup.get('hasPain').value == true}" class="btn btn-secondary"> <input type="radio" (change)="onChangePain()" formControlName="hasPain" [value]="true" /> Yes </label>
                  <label [ngClass]="{'active': formGroup.get('hasPain').value == false}" class="btn btn-secondary"> <input type="radio" (change)="onChangePain()" formControlName="hasPain" [value]="false" /> No </label>
                </div>
                <div *ngIf="formGroup.controls['hasPain'].dirty && formGroup.controls['hasPain'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="formGroup.get('hasPain').value == true" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="painType"> Is the pain during rest, activity, or both? </label>
                <select class="form-control" formControlName="painType">
                  <option [ngValue]="null">(select one)</option>
                  <ng-container *ngFor="let mapEntry of painType | keyvalue">
                    <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
                <div *ngIf="formGroup.controls['painType'].dirty && formGroup.controls['painType'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="hasPain"> Do you have any muscle soreness? </label>
                <div class="btn-group btn-group-toggle">
                  <label [ngClass]="{'active': formGroup.get('hasMuscleSoreness').value == true}" class="btn btn-secondary"> <input type="radio" formControlName="hasMuscleSoreness" [value]="true" /> Yes </label>
                  <label [ngClass]="{'active': formGroup.get('hasMuscleSoreness').value == false}" class="btn btn-secondary"> <input type="radio" formControlName="hasMuscleSoreness" [value]="false" /> No </label>
                </div>
                <div *ngIf="formGroup.controls['hasMuscleSoreness'].dirty && formGroup.controls['hasMuscleSoreness'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="changedDailyRoutine"> Have you changed any of your daily routine? </label>
                <div class="btn-group btn-group-toggle">
                  <label [ngClass]="{'active': formGroup.get('changedDailyRoutine').value == true}" class="btn btn-secondary"> <input type="radio" formControlName="changedDailyRoutine" [value]="true" /> Yes </label>
                  <label [ngClass]="{'active': formGroup.get('changedDailyRoutine').value == false}" class="btn btn-secondary"> <input type="radio" formControlName="changedDailyRoutine" [value]="false" /> No </label>
                </div>
                <div *ngIf="formGroup.controls['changedDailyRoutine'].dirty && formGroup.controls['changedDailyRoutine'].errors?.required" class="invalid-message-visible">
                  <div>Value is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="formGroup.get('changedDailyRoutine').value == true">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label">
                  Rate each category on a 5 point scale: <br />
                  <span class="legend">Less (-2) to Same (0) to More (2)</span>
                </label>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="dailyStress"> Daily Stress </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="dailyStress"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('dailyStress') ? formGroup.get('dailyStress').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Healthy Eating </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="ateWell"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('ateWell') ? formGroup.get('ateWell').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Slept </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="slept"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('slept') ? formGroup.get('slept').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Caffeine Consumption </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="caffeineConsumption"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('caffeineConsumption') ? formGroup.get('caffeineConsumption').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Water Consumption </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="waterConsumption"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('waterConsumption') ? formGroup.get('waterConsumption').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Alcohol Consumption </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="alcoholConsumption"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('alcoholConsumption') ? formGroup.get('alcoholConsumption').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Sexual Activity </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="sexualActivity"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('sexualActivity') ? formGroup.get('sexualActivity').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Meditation </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="meditation"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('meditation') ? formGroup.get('meditation').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Exercise Amount </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="exerciseAmount"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('exerciseAmount') ? formGroup.get('exerciseAmount').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Exercise Intensity </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="exerciseIntensity"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('exerciseIntensity') ? formGroup.get('exerciseIntensity').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Steps </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="steps"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('steps') ? formGroup.get('steps').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Travel </label>
                <div class="slider-control">
                  <kendo-slider [fixedTickWidth]="45" [min]="-2" [max]="2" [smallStep]="smallStep" formControlName="travel"> </kendo-slider>
                  <span style="vertical-align: top">&nbsp;&nbsp; {{ formGroup.get('travel') ? formGroup.get('travel').value : '' }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label slider-label" for="ateWell"> Other: Please specify </label>
                <input class="form-control" formControlName="other" maxlength="100" type="text" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="notes"> Notes </label>
                <textarea class="form-control" formControlName="notes" maxlength="1000" type="text" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div *ngIf="!dailyStatusId" class="row">
            <div class="col">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Entry</button>
            </div>
            <div class="col text-right">
              <div class="form-group">
                <label class="form-control-label mr-2"> Hide Daily Status Prompt </label>
                <kendo-switch formControlName="hideDailyStatus" (valueChange)="onhideDailyStatusChange($event)"></kendo-switch>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

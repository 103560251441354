<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <form class="form" [formGroup]="formGroup" (ngSubmit)="onSave()" novalidate>
    <div *ngIf="!isCoachView"class="card mt-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-8"><h3 class="mb-0">Email Notifications</h3></div>

          <div class="col-4 text-right">
            <button class="btn btn-sm btn-primary" [promiseBtn]="submitComplete" type="submit">Save</button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <h6 class="heading-small text-muted mb-4">Manage notification settings</h6>

        <div class="pl-lg-4">
          <div class="row">
            <div *ngFor="let type of notificationTypes" class="col-md-12">
              <div class="form-group">
                <label class="form-control-label mr-2"> {{type.notificationType | enumString: notificationTypeEnum}} </label>

                <kendo-switch [formControlName]="type.notificationType.toString()"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form class="form" [formGroup]="formGroupDs" (ngSubmit)="onSaveDs()" novalidate>
    <div class="card mt-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-8"><h3 class="mb-0">Daily Statuses</h3></div>

          <div class="col-4 text-right">
            <button class="btn btn-sm btn-primary" [promiseBtn]="submitCompleteDailyStatuses" type="submit">Save</button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <h6 class="heading-small text-muted mb-4">Manage daily statuses settings</h6>

        <div class="pl-lg-4">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label mr-2"> Show Daily Status Prompt </label>

                <kendo-switch [formControlName]="1"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { ExternalWorkoutService } from 'src/app/_services/generatedServices';


@Component({
  selector: 'external-heart-rate-chart',
  templateUrl: 'heart-rate-chart.component.html',
})
export class ExternalHeartRateChartComponent implements OnInit, OnDestroy {
  initialized = false;
  hasData: boolean = true;

  @Input()
  clientProgramWorkoutDayId: number;


  constructor(private charting: ChartingService, private externalWorkoutService: ExternalWorkoutService) {}

  ngOnInit(): void {
    const title = 'Heart Rate (bpm)';
    const chartHeaders: any[] = [{ type: 'timeofday', id: 'Time', label: 'Time' }, { type: 'number', id: 'Heart Rate', label: title }];

    this.externalWorkoutService.getHeartRateChartData(this.clientProgramWorkoutDayId).subscribe(result => {
      this.hasData = result.length > 0;

      this.charting.queueCharts(
        this.charting.drawAreaChart.bind(this.charting),
        new ChartData(
            'heartRateChart',
            chartHeaders,
            result.map(x => [x.timerDuration, x.value]),
            title
        )
      );
      this.initialized = true;
    });
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

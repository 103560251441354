import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeViewModel } from 'src/app/_models/generatedModels';

@Component({
  selector: 'date-range-dialog',
  templateUrl: 'date-range-dialog.component.html',
  styleUrls: ['date-range-dialog.component.scss'],
})
export class DateRangeDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  @Input()
    headerText: string;

  @Output()
    saved = new EventEmitter<DateRangeViewModel>();

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const dateRange = new DateRangeViewModel();
      dateRange.startDate = this.formGroup.get('startDate').value;
      dateRange.endDate = this.formGroup.get('endDate').value;
      this.saved.emit(dateRange);
      this.activeModal.dismiss('saved');
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AssessmentTooltipViewModelRead, AssessmentTooltipViewModel } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';



@Component({
  selector: 'bxl-add-edit-assessment-tooltip',
  templateUrl: 'add-edit-assessment-tooltip.component.html',
})
export class AddEditAssessmentTooltipComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  tooltipId: number;
  tooltip: AssessmentTooltipViewModelRead;
  listUrl: string = '/superuser/assessmentTooltips';

  constructor(private assessmentService: AssessmentService, private fb: FormBuilder, private toastr: ToasterService, private route: ActivatedRoute,
    private auth: AuthenticationService, private router: Router, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.tooltipId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb('Asessment Tooltips', 'assessmentTooltips', []);
    this.breadcrumbs.AppendBreadcrumb((this.tooltipId ? 'Edit ' : 'Add ') + 'Tooltip', this.router.url, []);

    if (this.tooltipId) {
      this.assessmentService.getAssessmentTooltipById(this.tooltipId).subscribe(results => {
        this.tooltip = results;
        this.setupForm();
      });
    }
  }


  setupForm() {
    this.formGroup = this.fb.group({
      title: [this.tooltip.title],
      description: [this.tooltip.description],
      videoLink: [this.tooltip.videoLink]
    });

    this.initialized = true;
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe(result => {
        if (result) {
          this.router.navigate([this.listUrl]);
        }
      });
    } else {
      this.router.navigate([this.listUrl]);
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      let formData: AssessmentTooltipViewModel = this.formGroup.value;

      this.update(formData, resetButton);
    });
  }

  update(formData: any, resetButton: () => any) {
    this.assessmentService.updateAssessmentTooltip(this.tooltipId, formData).subscribe((result) => {
      this.toastr.success('Tooltip Updated', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    });
  }
}

<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Administration</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>
        </div>
      </div>
      <div *ngIf="initialized" class="flex-column flex-md-row tab-container" type="pills">
        <ul class="nav nav-pills">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['users']" [routerLinkActive]="'active'"><i class="fas fa-users mr-2"></i>Users </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['settings']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Settings </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['billing']" [routerLinkActive]="'active'"><i class="fas fa-credit-card-front mr-2"></i>Billing </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['marketplace']" [routerLinkActive]="'active'"><i class="fas fa-dollar-sign mr-2"></i> Marketplace </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>

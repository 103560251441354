import { Component, OnInit, NgZone, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { CreatePaymentMethodViewModel, CreateSubscriptionViewModel, OrganizationBillingViewModel, PricingTierViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'add-card',
  templateUrl: 'activate-subscription.component.html',
})
export class ActivateSubscription implements OnInit {
  tiers: PricingTierViewModel;
  constructor(public fb: FormBuilder, public toastr: ToasterService, public activeModal: NgbActiveModal, public zone: NgZone, public orgService: OrganizationService,
    private auth: AuthenticationService) {}

  initialized = false;
  saving = false;
  contactForm: any;
  errorMessage: '';
  useCardOnFile: boolean = false;
  brandKeyName: string;

  @Input()
  organization: OrganizationBillingViewModel;

  ngOnInit(): void {
    this.orgService.getPricingTiers().subscribe((tiers) => {
      this.tiers = tiers;
      if (this.organization.last4OfCard && this.organization.stripeCustomerId) {
        this.useCardOnFile = true;
      }

      this.contactForm = this.fb.group({
        isMonthly: [true, [Validators.required]],
        cardNumber: ['', (this.useCardOnFile ? null : Validators.required)],
        expMo: ['', (this.useCardOnFile ? null : Validators.required)],
        expYr: ['', (this.useCardOnFile ? null : Validators.required)],
        cvv: ['', (this.useCardOnFile ? null : Validators.required)],
      });

      this.initialized = true;
    });
  }

  onUseCardOnFileChange(event: any) {
    if (this.useCardOnFile) {
      this.contactForm.get('cardNumber').setValidators([]);
      this.contactForm.get('expMo').setValidators([]);
      this.contactForm.get('expYr').setValidators([]);
      this.contactForm.get('cvv').setValidators([]);
    } else {
      this.contactForm.get('cardNumber').setValidators([Validators.required]);
      this.contactForm.get('expMo').setValidators([Validators.required]);
      this.contactForm.get('expYr').setValidators([Validators.required]);
      this.contactForm.get('cvv').setValidators([Validators.required]);
    }

    this.contactForm.get('cardNumber').updateValueAndValidity();
    this.contactForm.get('expMo').updateValueAndValidity();
    this.contactForm.get('expYr').updateValueAndValidity();
    this.contactForm.get('cvv').updateValueAndValidity();
  }

  save(): void {
    if (!this.contactForm.valid) {
      this.contactForm.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.brandKeyName = this.auth.user.organizationBrandKeyName;

    if (this.useCardOnFile) {
      this.createSubscription();
    } else {
      this.createPaymentAndSubscription();
    }
  }

  createSubscription() {
    this.saving = true;
    let model = new CreateSubscriptionViewModel();
    model.isMonthly = this.contactForm.controls.isMonthly.value;
    model.brandKeyName = this.brandKeyName;
    console.log(model);
    this.orgService.createSubscriptionWithExistingCard(model).subscribe(
      (company) => {
        this.activeModal.close(company);
        this.saving = false;
      },
      (error) => {
        this.errorMessage = error.error[0];
        this.saving = false;
      }
    );
  }

  createPaymentAndSubscription() {
    this.saving = true;
    let card = { number: this.contactForm.controls.cardNumber.value, exp_month: this.contactForm.controls.expMo.value, exp_year: this.contactForm.controls.expYr.value, cvc: this.contactForm.controls.cvv.value };
    (<any>window).Stripe.card.createToken(card, (status: number, response: any) => {
      if (status === 200) {
        console.log(response.id);
        let model = new CreatePaymentMethodViewModel();
        model.stripeToken = response.id;
        model.last4 = response.card.last4;
        model.cardType = response.card.brand;
        model.isMonthly = this.contactForm.controls.isMonthly.value;
        model.brandKeyName = this.brandKeyName;
        this.zone.run(() => {
          this.orgService.addPaymentMethodAndCreateSubscription(model).subscribe(
            (company) => {
              this.activeModal.close(company);
              this.saving = false;
            },
            (error) => {
              this.errorMessage = error.error[0];
              this.saving = false;
            }
          );
        });
      } else {
        this.errorMessage = response.error.message;
        this.saving = false;
        console.error(response.error.message);
      }
    });
  }

  get f() {
    return this.contactForm.controls;
  }
}

<div *ngIf="initialized">
  <div class="card">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Exercise Preview</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <exercise-view [exerciseId]="exerciseId"></exercise-view>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventViewModel, CalendarType, CalendarWeekViewModel, ClientDayEventType, ClientProgramWorkoutDayActivityTypeDurationViewModel, ClientProgramWorkoutDayViewModel } from 'src/app/_models/generatedModels';
import { CalendarAddEventParameters, CalendarDraggedEventParameters, CalendarParameters, CalendarWorkoutEvent } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { RunnerWorkoutModalComponent } from '../_components/runner-workout-modal/runner-workout-modal.component';
import { DeleteCalendarEventDialogComponent } from '../_components/calendar-event-delete-dialog/delete-calendar-event-dialog.component';
import { CalendarWorkoutDialogComponent } from '../_components/calendar-workout-dialog/calendar-workout-dialog.component';
import { MoveCalendarEventDialogComponent } from '../_components/calendar-event-move-dialog/move-calendar-event-dialog.component';
import { CalendarEventToggleCompleteDialogComponent } from '../_components/calendar-event-toggle-complete-dialog/calendar-event-toggle-complete-dialog.component';
import { CopyCalendarEventDialogComponent } from '../_components/calendar-event-copy-dialog/copy-calendar-event-dialog.component';
import { CopyCalendarWeekDialogComponent } from '../_components/calendar-week-copy-dialog/copy-calendar-week-dialog.component';


@Injectable()
export class ClientCalendarService {
  private currentCalendarParameters: CalendarParameters;
  private clientUserId: number;
  private isCoachView: boolean = false;
  private hasCoachRole: boolean = false;
  private isSelf: boolean = false;
  private eventType = ClientDayEventType;

  // this service will either just emit a bool saying data was updated; or actually update the data: based on the updateData boolean
  public updateData: boolean = false;
  public changed: Subject<boolean> = new Subject();
  public dataChanged: Subject<CalendarWeekViewModel[]> = new Subject();

    constructor(private clientsService: ClientsService, private modalService: NgbModal, private toastr: ToasterService, private auth: AuthenticationService) { }

    setInitialValues(clientUserId: number, isCoachView: boolean, hasCoachRole: boolean, updateData: boolean, isSelf: boolean) {
      this.clientUserId = clientUserId;
      this.isCoachView = isCoachView;
      this.hasCoachRole = hasCoachRole;
      this.updateData = updateData;
      this.isSelf = isSelf;
    }

    calendarParametersChanged(parameters: CalendarParameters) {
      this.currentCalendarParameters = parameters;
      this.getData();
    }

    private getData() {
      if (this.updateData) {
        this.clientsService.getClientCalendarWeeks(this.clientUserId, this.currentCalendarParameters.selectedDate, this.currentCalendarParameters.today, this.currentCalendarParameters.isMonthView, this.hasCoachRole).subscribe(result => {
          this.dataChanged.next(result);
        });
      } else {
        this.changed.next(true);
      }

    }

    onEventInfoClicked(event: CalendarEventViewModel) {
      let backdrop: any = (!event.isCompleted && this.isSelf) ? 'static' : true;
      const modalRef = this.modalService.open(RunnerWorkoutModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: backdrop });
      modalRef.componentInstance.clientUserId = this.clientUserId;
      modalRef.componentInstance.clientProgramWorkoutDayId = event.eventId;
      modalRef.componentInstance.isCoachView = this.isCoachView;
      modalRef.componentInstance.syncedWorkoutNameUpdated.subscribe((result) => {
        this.getData();
      });

      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason === 'saved') {
            this.getData();
          }
        }
      );
    }

    onEventEditClicked(event: CalendarEventViewModel) {
      let editableAthleteEventTypes = [ClientDayEventType.Task, ClientDayEventType.Event, ClientDayEventType.ManualWorkout];

      if (this.hasCoachRole || (!this.hasCoachRole && editableAthleteEventTypes.includes(event.eventType))) {
        let calendarEvent = new CalendarWorkoutEvent(event.eventId, event.workout ? event.workout.id : null, event.workoutDate, event.sortOrder, event.eventName, event.isCompleted,
          event.coachNotes, event.taskDescription, event.eventType, event.recurringEvent, event.activityTypeDurations, event.ratePerceivedExertion, event.quickWorkoutName,
          event.quickWorkoutDescription, event.quickWorkoutActivityType, event.quickWorkoutDuration, event.quickWorkoutDistance);
        this.openCalendarEventDialog(calendarEvent);
      } else {
        // athlete view
        if (!event.isCompleted) {
          this.openMoveCalendarEventDialog(event);

        }
      }
    }

    onEventDeleteClicked(event: CalendarEventViewModel) {
      const modalRef = this.modalService.open(DeleteCalendarEventDialogComponent, { size: 'lg', windowClass: 'modal-sm-custom' });
      modalRef.componentInstance.isCoachView = this.hasCoachRole;
      modalRef.componentInstance.clientUserId = this.clientUserId;
      modalRef.componentInstance.event = event;
      modalRef.componentInstance.calendarType = CalendarType.Client;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
    }

    onEventAddClicked(parameters: CalendarAddEventParameters) {
      let selectedEventType = this.hasCoachRole ? ClientDayEventType.QuickWorkout : ClientDayEventType.ManualWorkout;
      this.openCalendarEventDialog(new CalendarWorkoutEvent(null, null, parameters.selectedDate, parameters.maxExistingSortOrder + 1, null, false, null, null, selectedEventType, null, null, null,
          null, null, null, null, null));
    }

    onEventDragEnd(parameters: CalendarDraggedEventParameters) {
      const model: ClientProgramWorkoutDayViewModel = {
        workoutDate: parameters.eventDate,
        workoutId: parameters.draggedEvent.workout ? parameters.draggedEvent.workout.id : null,
        sortOrder: parameters.newEventIndex + 1,
        organizationId: this.auth.organizationId,
        clientUserId: this.clientUserId,
        assignedByUserId: this.auth.user.id,
        eventName: parameters.draggedEvent.eventName,
        isCompleted: parameters.draggedEvent.isCompleted,
        coachNotes: parameters.draggedEvent.coachNotes,
        taskDescription: parameters.draggedEvent.taskDescription,
        eventType: parameters.draggedEvent.eventType,
        recurringEvent: parameters.draggedEvent.recurringEvent,
        activityTypeDurations: [],
        quickWorkoutName: parameters.draggedEvent.quickWorkoutName,
        quickWorkoutDescription: parameters.draggedEvent.quickWorkoutDescription,
        quickWorkoutActivityType: parameters.draggedEvent.quickWorkoutActivityType,
        quickWorkoutDuration: parameters.draggedEvent.quickWorkoutDuration,
        quickWorkoutDistance: parameters.draggedEvent.quickWorkoutDistance
      };

      if (parameters.isCopyAndDrag) {
        model.recurringEvent = null;
        model.isCompleted = false;
        this.clientsService.addClientProgramWorkoutDay(this.clientUserId, model).subscribe((result) => {
          this.getData();
        });

      } else {
        // this is the only event type that can be moved after it's completed and with durations
        if (model.eventType == ClientDayEventType.ManualWorkout) {
          model.activityTypeDurations = parameters.draggedEvent.activityTypeDurations.map(x => { return { clientProgramWorkoutDayId: 0, duration: x.duration, distance: x.distance, activityType: x.activityType } as ClientProgramWorkoutDayActivityTypeDurationViewModel});
        }

        this.clientsService.updateClientProgramWorkoutDay(this.clientUserId, parameters.draggedEvent.eventId, model).subscribe((result) => {
          this.getData();
        });
      }
    }

    onEventToggleCompleteClicked(event: CalendarEventViewModel) {
      this.openToggleCalendarEventCompleteDialog(event);
    }

    onEventCopyClicked(event: CalendarEventViewModel) {
      if ((event.workout || event.eventType == this.eventType.QuickWorkout) && this.hasCoachRole) {
        const modalRef = this.modalService.open(CopyCalendarEventDialogComponent, { size: 'lg'});
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.clientUserId = this.clientUserId;
        modalRef.componentInstance.calendarType = CalendarType.Client;
        modalRef.result.then(
          (result) => {},
          (reason) => {
            if (reason == 'saved') {
              this.getData();
            }
          }
        );
      }
    }

    onWeekCopyClicked(date: Date) {
      if (this.hasCoachRole) {
        const modalRef = this.modalService.open(CopyCalendarWeekDialogComponent, { size: 'lg'});
        modalRef.componentInstance.originalDate = date;
        modalRef.componentInstance.clientUserId = this.clientUserId;
        modalRef.componentInstance.calendarType = CalendarType.Client;
        modalRef.result.then(
          (result) => {},
          (reason) => {
            if (reason == 'saved') {
              this.getData();
            }
          }
        );
      }
    }

    private openCalendarEventDialog(calendarEvent: CalendarWorkoutEvent) {
      const modalRef = this.modalService.open(CalendarWorkoutDialogComponent, { size: 'lg', windowClass: 'modal-xl-custom' });
      modalRef.componentInstance.isCoachView = this.hasCoachRole;
      modalRef.componentInstance.isSelf = this.isSelf;
      modalRef.componentInstance.calendarType = CalendarType.Client;
      modalRef.componentInstance.clientUserId = this.clientUserId;
      modalRef.componentInstance.calendarEvent = calendarEvent;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
    }

    private openMoveCalendarEventDialog(event: CalendarEventViewModel) {
      const modalRef = this.modalService.open(MoveCalendarEventDialogComponent, { size: 'lg', windowClass: 'modal-sm-custom' });
      modalRef.componentInstance.event = event;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
    }

    private openToggleCalendarEventCompleteDialog(event: CalendarEventViewModel) {
      const modalRef = this.modalService.open(CalendarEventToggleCompleteDialogComponent, { size: 'lg', windowClass: 'modal-sm-custom' });
      modalRef.componentInstance.event = event;
      modalRef.result.then(
        (result) => {},
        (reason) => {
          if (reason == 'saved') {
            this.getData();
          }
        }
      );
    }

}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="row no-gutters">
    <div class="col">
      <h3 class="mt-1 mb-3">Daily Statuses</h3>
    </div>
  </div>
  <daily-status-list [clientUserId]="clientUserId" [isCoachView]="true"></daily-status-list>
</div>


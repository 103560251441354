<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Users</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <input type="text" class="form-control" placeholder="Search by name or email" [(ngModel)]="filter" (ngModelChange)="onFilterChanged($event)" />
      </div>
    </div>

    <page-load-header *ngIf="searching"></page-load-header>
    <div [hidden]="searching" *ngIf="initialized" class="row mt-3">
      <div class="col">
        <div class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row">
                <th class="sort" data-sort="name" scope="col">Id</th>
                <th class="sort" data-sort="name" scope="col">Credential Id</th>
                <th class="sort" data-sort="name" scope="col">Created</th>
                <th class="sort" data-sort="key" scope="col">Name</th>

                <th class="sort" data-sort="key" scope="col">Email</th>
                <th class="sort" data-sort="key" scope="col">Role</th>
                <th class="sort" data-sort="key" scope="col">Status</th>
                <th class="sort" data-sort="key" scope="col">Org Id</th>
                <th class="sort" data-sort="key" scope="col">Organization Name</th>
                <th class="sort" data-sort="key" scope="col">Org Active?</th>
                <th class="sort" data-sort="key" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let user of users; let i = index">
                <td>
                  <span class="mobile-grid-label">Id</span>
                  {{ user.id }}
                </td>
                <td>
                  <span class="mobile-grid-label">Credential Id</span>
                  {{ user.userCredentialId }}
                </td>
                <td>
                  <span class="mobile-grid-label">Created</span>
                  {{ user.createdDate | date }}
                </td>
                <td>
                  <span class="mobile-grid-label">Name</span>
                  {{ user.fullName }}
                </td>
                <td>
                  <span class="mobile-grid-label">Email</span>
                  {{ user.email }}
                </td>
                <td>
                  <span class="mobile-grid-label">Role</span>
                  {{ user.userRole | flagEnumString: userRoleEnum }}
                </td>
                <td>
                  <span class="mobile-grid-label">Status</span>
                  {{ user.status | enumString: clientStatusEnum }}
                </td>
                <td>
                  <span class="mobile-grid-label">Org Id</span>
                  {{ user.organizationId }}
                </td>
                <td>
                  <span class="mobile-grid-label">Organization Name</span>
                  {{ user.organizationName }}
                </td>
                <td>
                  <span class="mobile-grid-label">Org Active?</span>
                  {{ user.organizationActiveOrTrialSubscription | booleanToYesNo }}
                </td>

                <td class="text-lg-center">
                  <span class="mobile-grid-label">Actions</span>
                  <div class="btn-group no-caret">
                    <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"> </i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg-right">
                      <a href="javascript:void(0)" (click)="onImpersonateUser(user.id)" class="dropdown-item"> Impersonate User </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
            <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>


<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="">
  <div class="row no-gutters mb-2">
    <div class="col">
      <h3 class="mt-1 mb-3">Athlete Messages</h3>
      <div>This tab only shows messages to/from this athlete. To view all of your messages, reference your <a href="/message-list">Mailbox.</a></div>
    </div>
  </div>

  <runner-my-messages-view [clientUserId]="clientUserId" [isCoachView]="true"></runner-my-messages-view>
</div>



import { Component, OnInit, Input } from '@angular/core';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { WorkoutProgressionViewModelRead } from 'src/app/_models/generatedModels';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutViewModalComponent } from 'src/app/_components/workout-view-modal/workout-view-modal.component';

@Component({
  selector: 'app-workout-progression-preview',
  templateUrl: 'workout-progression-preview.component.html',
})
export class WorkoutProgressionPreviewComponent implements OnInit {
  initialized = false;
  progression: WorkoutProgressionViewModelRead;

  @Input()
  workoutProgressionId: number;

  constructor(private exerciseData: ExerciseService, public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.exerciseData.getWorkoutProgressionById(this.workoutProgressionId).subscribe((result) => {
      this.progression = result;
      this.initialized = true;
    });
  }

  onWorkoutPreview(workoutId: number) {
    const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
    modalRef.componentInstance.workoutId = workoutId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row mt-3">
      <div class="col">

        <custom-calendar [data]="data"
          (calendarParametersChanged)="calendarParametersChanged($event)"
          (eventInfoClicked)="onEventInfoClicked($event)"
          (eventEditClicked)="onEventEditClicked($event)"
          (eventDeleteClicked)="onEventDeleteClicked($event)"
          (eventAddClicked)="onEventAddClicked($event)"
          (eventDragEnd)="onEventDragEnd($event)"
          (eventCopyClicked)="onEventCopyClicked($event)"
          (weekCopyClicked)="onWeekCopyClicked($event)"
          [hasCoachRole]="true">
        </custom-calendar>

      </div>
    </div>
  </div>
</div>



import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-client-acute-chronic',
  templateUrl: 'client-acute-chronic.component.html'
})
export class ClientAcuteChronicComponent implements OnInit {
  initialized = false;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private router: Router, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Acute-Chronic Workload', this.router.url, []);
    this.initialized = true;
  }
}

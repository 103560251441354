import { Component, OnInit, Input } from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import {  QuickWorkoutDisplayViewModelRead } from 'src/app/_models/generatedModels';

@Component({
  selector: 'quick-workout-view-pdf',
  templateUrl: 'quick-workout-view-pdf.component.html',
  styleUrls: ['quick-workout-view-pdf.component.scss']
})
export class QuickWorkoutViewPDFComponent implements OnInit {
  initialized = false;
  activityTypeEnum = Enums.ActivityTypeEnum;

  @Input()
  quickWorkout: QuickWorkoutDisplayViewModelRead;


  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }
}

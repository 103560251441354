import { Injectable } from '@angular/core';
import { OrganizationViewModelRead } from '../_models/generatedModels';


@Injectable()
export class OrgMarketplaceService {
  public organization: OrganizationViewModelRead;

    constructor() { }

    SetOrganizationInfo(organization: OrganizationViewModelRead) {
      this.organization = organization;
    }

}

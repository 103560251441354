import { Component, Input, forwardRef, ElementRef, Output, EventEmitter, OnInit  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxOption } from '../_models/models';
import { CardioIntensityType } from '../_models/generatedModels';
import { IntensityChartDialogComponent } from '../_components/intensity-chart-dialog/intensity-chart-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'rate-perceived-exertion',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <div><a class="ml-1" style="font-size: 14px;" href="javascript:void(0)" (click)="showRPEChart()">RPE Chart</a></div>
    <div class="btn-group checkbox-buttons rpe">
        <ng-container *ngFor="let score of RPEOptions">
            <label [ngClass]="{ 'active btn-primary': score.selected, 'btn-secondary': !score.selected }" class="btn btn-narrow"> <input type="checkbox" [(ngModel)]="score.selected" (ngModelChange)="onRPEChanged(score.id)" [ngModelOptions]="{ standalone: true }" />{{ score.displayName }} </label>
        </ng-container>
    </div>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RatePerceivedExertionControl),
            multi: true
        }
    ]
})

export class RatePerceivedExertionControl implements ControlValueAccessor, OnInit {

    someAttr: string = 'anyValue';
    selectedValue: any;
    RPEOptions: CheckboxOption[] = [];

    constructor(private elementRef: ElementRef, private modalService: NgbModal) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    disabled: boolean = false;

    @Output()
    valueChange = new EventEmitter<number>();


    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: any): void {
        this.selectedValue = value;
        if (this.selectedValue) {
            this.RPEOptions.find((x) => x.id == this.selectedValue).selected = true;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.valueChange.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnInit(): void {
        for (let i = 1; i < 11; i++) {
            this.RPEOptions.push(new CheckboxOption(i.toString(), i.toString(), i, false));
        }
    }

    onRPEChanged(id: number) {
        for (var i = 0; i < this.RPEOptions.length; i++) {
          if (this.RPEOptions[i].id != id) {
            this.RPEOptions[i].selected = false;
          }
        }
        this.onChange(id);
    }

    showRPEChart() {
        const modalRef = this.modalService.open(IntensityChartDialogComponent, { size: 'lg', windowClass: 'modal-md-custom' });
        modalRef.componentInstance.selectedCardioIntensityType = CardioIntensityType.RateOfPerceivedExertion;
        modalRef.componentInstance.selectedGarminWorkoutStepTargetType = null;
    }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block mb-0">Loading Levels Assessment</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-9 col-lg-7 col-xl-6">
      <form class="form" [formGroup]="formGroup" novalidate>

        <div class="card mb-4">
          <div class="card-header">
            <h5 class="mb-0">Level 1 - Lateral Step Down<button type="button" (click)="showHelp('ll-lateralStepDown')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
          </div>

          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4">
                    <h5>Able Painfree</h5>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col text-center">
                        <span class="ml-2">Left</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="lateralStepDownAblePainfreeLeft" />
                        </div>
                      </div>
                      <div class="col text-center">
                        <span class="ml-2">Right</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="lateralStepDownAblePainfreeRight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4">
                    <h5>Alignment</h5>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col text-center">
                        <span class="ml-2 d-block d-sm-none">Left</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="lateralStepDownAlignmentLeft" />
                        </div>
                      </div>
                      <div class="col text-center">
                        <span class="ml-2 d-block d-sm-none">Right</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="lateralStepDownAlignmentRight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Symmetry</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="lateralStepDownSymmetry" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="lateralStepDownLeftVideoId" [label]="'Lateral Step Down Video - Left side'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('lateralStepDownLeftVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('lateralStepDownLeftVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="lateralStepDownRightVideoId" [label]="'Lateral Step Down Video - Right side'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('lateralStepDownRightVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('lateralStepDownRightVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-xl-8 col-12">
                    <h5>Qualifier - Bilateral Jump Rope<button type="button" (click)="showHelp('ll-qualifierBilateralJumpRope')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-4 col-5">
                        <h5>Able Painfree</h5>
                      </div>
                      <div class="col-md-8 col-7">
                        <div class="row">
                          <div class="col text-sm-center text-left">
                            <div class="form-group">
                              <input type="checkbox" class="medium-checkbox" formControlName="qualifierBilateralJumpRopeAblePainfree" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            <h5 class="mb-0">Level 2 - Stationary Jump - Bilateral<button type="button" (click)="showHelp('ll-stationaryJumpBilateral')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
          </div>

          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Able Painfree</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpBilateralAblePainfree" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Alignment</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpBilateralAlignment" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Symmetry</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpBilateralSymmetry" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="stationaryJumpBilateralVideoId" [label]="'Stationary Jump Bilateral Video'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('stationaryJumpBilateralVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('stationaryJumpBilateralVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            <h5 class="mb-0">Level 3 - Depth Jump Over Barrier<button type="button" (click)="showHelp('ll-depthJumpOverBarrier')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
          </div>

          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Able Painfree</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="depthJumpOverBarrierAblePainfree" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Alignment</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="depthJumpOverBarrierAlignment" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Symmetry</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="depthJumpOverBarrierSymmetry" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="depthJumpOverBarrierVideoId" [label]="'Depth Jump Over Barrier Video'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('depthJumpOverBarrierVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('depthJumpOverBarrierVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-xl-8 col-12">
                    <h5>Qualifier - Unilateral Jump Rope<button type="button" (click)="showHelp('ll-qualifierUnilateralJumpRope')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center pt-1 pb-1">
                      <div class="col-md-4">
                        <h5>Able Painfree</h5>
                      </div>
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col text-center">
                            <span class="ml-2">Left</span>
                            <div class="form-group">
                              <input type="checkbox" class="medium-checkbox" formControlName="qualifierUnilateralJumpRopeAblePainfreeLeft" />
                            </div>
                          </div>
                          <div class="col text-center">
                            <span class="ml-2">Right</span>
                            <div class="form-group">
                              <input type="checkbox" class="medium-checkbox" formControlName="qualifierUnilateralJumpRopeAblePainfreeRight" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            <h5 class="mb-0">Level 4 - Stationary Jump - Unilateral<button type="button" (click)="showHelp('ll-stationaryJumpUnilateral')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
          </div>

          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4">
                    <h5>Able Painfree</h5>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col text-center">
                        <span class="ml-2">Left</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpUnilateralAblePainfreeLeft" />
                        </div>
                      </div>
                      <div class="col text-center">
                        <span class="ml-2">Right</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpUnilateralAblePainfreeRight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4">
                    <h5>Alignment</h5>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col text-center">
                        <span class="ml-2 d-block d-sm-none">Left</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpUnilateralAlignmentLeft" />
                        </div>
                      </div>
                      <div class="col text-center">
                        <span class="ml-2 d-block d-sm-none">Right</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpUnilateralAlignmentRight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Symmetry</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="stationaryJumpUnilateralSymmetry" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="stationaryJumpUnilateralLeftVideoId" [label]="'Stationary Jump Unilateral Video - Left side'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('stationaryJumpUnilateralLeftVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('stationaryJumpUnilateralLeftVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="stationaryJumpUnilateralRightVideoId" [label]="'Stationary Jump Unilateral Video - Right side'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('stationaryJumpUnilateralRightVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('stationaryJumpUnilateralRightVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            <h5 class="mb-0">Level 5 - Triple Hop - Unilateral<button type="button" (click)="showHelp('ll-tripleHopUnilateral')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
          </div>

          <div class="card-body">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4">
                    <h5>Able Painfree</h5>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col text-center">
                        <span class="ml-2">Left</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="tripleHopUnilateralAblePainfreeLeft" />
                        </div>
                      </div>
                      <div class="col text-center">
                        <span class="ml-2">Right</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="tripleHopUnilateralAblePainfreeRight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4">
                    <h5>Alignment</h5>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col text-center">
                        <span class="ml-2 d-block d-sm-none">Left</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="tripleHopUnilateralAlignmentLeft" />
                        </div>
                      </div>
                      <div class="col text-center">
                        <span class="ml-2 d-block d-sm-none">Right</span>
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="tripleHopUnilateralAlignmentRight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-md-4 col-5">
                    <h5>Symmetry</h5>
                  </div>
                  <div class="col-md-8 col-7">
                    <div class="row">
                      <div class="col text-sm-center text-left">
                        <div class="form-group">
                          <input type="checkbox" class="medium-checkbox" formControlName="tripleHopUnilateralSymmetry" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="tripleHopUnilateralLeftVideoId" [label]="'Triple Hop Unilateral Video - Left side'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('tripleHopUnilateralLeftVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('tripleHopUnilateralLeftVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center pt-1 pb-1">
                  <div class="col-12">
                    <video-upload formControlName="tripleHopUnilateralRightVideoId" [label]="'Triple Hop Unilateral Video - Right side'"></video-upload>
                    <div *ngIf="assessmentId && formGroup.get('tripleHopUnilateralRightVideoId').value" class="pt-2">
                      <embed-video-player [size]="'small'" [videoId]="formGroup.get('tripleHopUnilateralRightVideoId').value"></embed-video-player>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <h5>Notes: </h5><textarea class="form-control" formControlName="notes" maxlength="250" type="text"></textarea>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
          </div>
          <div class="col text-right">
            <button *ngIf="!assessmentId || assessment?.status == assessmentStatus.InProgress" (click)="onSave(true)" [promiseBtn]="submitComplete" class="btn btn-secondary">Save for Later</button>
            <button (click)="onSave(false)" [promiseBtn]="submitComplete" class="btn btn-primary">Finish</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-6 d-sm-none">
    </div>
  </div>
</div>
<div class="header pb-2">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Superuser</h6>

          <rundna-breadcrumbs></rundna-breadcrumbs>
        </div>
      </div>
      <div class="flex-column flex-md-row tab-container" type="pills">
        <ul class="nav nav-pills">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['organizations']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Organizations </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['programs']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Categories / Programs </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['loadingLevelsPrograms']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Loading Levels Programs </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['assessmentTooltips']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Assessment Tooltips </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['surveys']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Surveys </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['users']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Users </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['stats']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Stats </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['rebuild-reporting']" [routerLinkActive]="'active'"><i class="fas fa-cog mr-2"></i>Rebuild Reporting </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>

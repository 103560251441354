<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <div *ngIf="brand && brandBannerActive" class="row">
    <div class="col px-0">
      <div class="alert alert-dismissable alert-banner mb-0" style="border-radius: 0" role="alert">
        <div class="row">
          <div class="col-12 text-center">
          <div class="d-inline">
            <a *ngIf="brand.bannerUrl" href="{{brand.bannerUrl}}" target="_blank'" class="text-banner">{{ brand.bannerText }}</a>
            <span *ngIf="!brand.bannerUrl" class="text-banner">{{ brand.bannerText }}</span>
          </div>
          <button type="button" class="close float-right" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav id="navbar-main" class="navbar navbar-expand-lg navbar-light navbar-custom d-flex justify-content-between">
    <div class="navbar-brand mx-auto order-0 order-lg-1">
      <img class="hax-logo" src="../../../../assets/img/hammer-and-axe/Hammer-Axe-Tall-logo.png" />
    </div>
      <button class="navbar-toggler navbar-toggler-left" type="button" data-toggle="collapse" data-target=".dual-nav">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse dual-nav order-1 order-lg-0" role="navigation">
        <ul class="navbar-nav mr-auto align-items-center d-flex justify-content-between">
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="https://www.hammer-and-axe.com/meet-the-coaches">Meet the Coaches</a></li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="https://www.hammer-and-axe.com/coaching">Coaching Options</a></li>
        </ul>
      </div>

      <div class="collapse navbar-collapse dual-nav order-2 order-lg-2" role="navigation">
        <ul class="navbar-nav ml-auto align-items-center d-flex justify-content-between">
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="https://www.hammer-and-axe.com/coaching-roster">Apply for Coaching</a></li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link" href="/auth/login">Log In</a></li>
        </ul>
      </div>
  </nav>
  <router-outlet></router-outlet>
</div>


<div *ngIf="initialized">
  <div class="card  mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">{{ isAdd ? 'Add' : 'Edit' }} Admin User</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div *ngIf="isAdd" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="input-first-name"> First Name </label>
                <input class="form-control" formControlName="firstName" type="text" maxlength="75" />
                <div class="invalid-message">
                  <div>First Name is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="isAdd" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="input-last-name"> Last Name </label>
                <input class="form-control" formControlName="lastName" type="text" maxlength="75" />
                <div class="invalid-message">
                  <div>Last Name is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="isAdd" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="input-email"> Email Address </label>
                <input class="form-control" formControlName="email" maxlength="75" type="email" />
                <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.required">
                  <div>Email Address is required</div>
                </div>
                <div class="invalid-message" *ngIf="formGroup?.controls['email'].errors && formGroup?.controls['email'].errors.emailInOrg">
                  <div>This email address already exists in your organization</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="input-email"> Role </label>
                <div class="d-inline text-center" kendoTooltip>
                  <i
                    class="fas fa-info-circle ml-2"
                    title="Administrator: All coaching privledges, ability to see all athletes, plus the ability to modify organization settings, billing, and add coaches.
                        Global Coach: All coaching privledges plus ability to see all athletes in the organization.
                        Head Coach: All coaching privledges; only able to see their teams and unassigned athletes.
                        Assistant Coach: All coaching privledges; only able to see their teams and unassigned athletes."
                  ></i>
                </div>
                <select class="form-control" formControlName="userRole" id="exampleFormControlSelect1">
                  <option [ngValue]="null">(select one)</option>
                  <ng-container *ngFor="let mapEntry of userRoleEnum | keyvalue">
                    <option *ngIf="mapEntry.key != userRole.Runner" [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                  </ng-container>
                </select>
                <div class="invalid-message" *ngIf="formGroup?.controls['userRole'].errors && formGroup?.controls['userRole'].errors.required">
                  <div>Role is required</div>
                </div>
                <div class="invalid-message" *ngIf="formGroup?.controls['userRole'].errors && formGroup?.controls['userRole'].errors.onlyAdmin">
                  <div>Cannot change the only Admin to another role</div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="isAthlete"> This user is also an Athlete </label>
                <div class="d-inline text-center" kendoTooltip>
                  <i class="fas fa-info-circle ml-2" title="Checking this box also gives the coach access to the Athlete View of the app (and they will be included in the athlete count for subscription purposes)."></i>
                </div>
                <input class="d-block single-checkbox" formControlName="isAthlete" type="checkbox" />
              </div>
            </div>
            <div *ngIf="formGroup?.controls['userRole'].value == userRole.AssistantCoach || formGroup?.controls['userRole'].value == userRole.HeadCoach" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="teamsList"> Teams </label>
                <teams-multiselect formControlName="teamIds"></teams-multiselect>
              </div>
            </div>
            <div *ngIf="isAdd" class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label" for="isOfAge"> This user is at least 14 years old </label>
                <input class="d-block single-checkbox" formControlName="isOfAge" type="checkbox" />
                <div class="invalid-message p-1">
                  <div>Field is required</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">{{ isAdd ? 'Add' : 'Update' }} User</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="header bg-dark pb-2">
  <div class="container">
    <div class="header-body">
      <div class="row align-items-center py-3">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-white d-inline-block mb-0">{{clientWorkout.workoutDate | date}} {{clientWorkout.isCompleted ? ' - Completed' : ''}} {{clientWorkout.eventType == eventType.RestDay ? ' - Rest Day' : ''}}</h6>
          <span *ngIf="clientWorkout.garminWorkoutScheduleId" class="text-white mb-0 sync-icon ml-1" title="Workout is synced to Garmin Connect"><i class="far fa-check-circle ml-1"></i></span>
          <span *ngIf="!clientWorkout.garminWorkoutScheduleId && clientWorkout.clientUserHasGarminTrainingPermission && isGarminWorkoutType" class="text-white mb-0 sync-icon ml-1" title="Send workout to Garmin Connect"><a href="javascript:void(0)" (click)="onSendWorkoutToGarmin()"><i class="far fa-cloud-upload ml-1"></i></a></span>
          <div *ngIf="isSummaryOnly && (clientWorkout.garminActivityId || clientWorkout.stravaActivityId)" class="float-right">
            <button type="button" class="btn btn-primary float-right" (click)="viewDetails()">View More Details</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="clientWorkout.eventType == eventType.GarminSyncedWorkout || clientWorkout.eventType == eventType.StravaSyncedWorkout" class="container">
    <div class="row">
      <div class="col-12">
        <input class="form-control" style="max-width: 325px;" [(ngModel)]="clientWorkout.syncedWorkoutName" (ngModelChange)="onSyncedWorkoutNameChanged($event)" maxlength="100" type="text" />
      </div>
    </div>
  </div>

  <div *ngIf="clientWorkout.eventType == eventType.ManualWorkout" class="container mb-2 mt-2">
    <div class="row">
      <div class="col-12">
        <h6 class="text-white">{{clientWorkout.activityTypeDurations[0].activityType | enumString: activityTypeEnum}}</h6>
      </div>
      <div *ngIf="clientWorkout.activityTypeDurations[0].distance" class="col-lg-4 col-6">
        <div class="card card-stats">
          <div class="card-body text-center">
            <h6 class="card-title text-uppercase text-muted mb-0">Distance</h6>
            <span class="h5 font-weight-bold mb-0">{{(clientWorkout.activityTypeDurations[0].distance | fromMilesToLocalMeasurement) | number : '1.2-2'}}</span><span> {{localDistanceName}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-6">
        <div class="card card-stats">
          <div class="card-body text-center">
            <h6 class="card-title text-uppercase text-muted mb-0">Time</h6>
            <span class="h5 font-weight-bold mb-0">{{clientWorkout.activityTypeDurations[0].duration | secsToHourMinSec}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="clientWorkout.eventType == eventType.QuickWorkout" class="container mb-2 mt-2">
    <div class="row">
      <div class="col-12">
        <h3 class="text-white d-inline">{{clientWorkout.quickWorkoutName}}</h3>
      </div>
      <div class="col-12">
        <div class="card p-3">
          <div>
            <span class="font-weight-bold">Activity Type: </span><span>{{clientWorkout.quickWorkoutActivityType | enumString: activityTypeEnum}}</span>
          </div>
          <div>
            <span class="font-weight-bold">Expected Duration: </span><span>{{clientWorkout.quickWorkoutDuration | secsToHourMinSec}}</span>
          </div>
          <div *ngIf="clientWorkout.quickWorkoutDistance">
            <span class="font-weight-bold">Expected Distance: </span><span>{{(clientWorkout.quickWorkoutDistance | fromMilesToLocalMeasurement) | number : '1.2-2'}}</span><span> {{localDistanceName}}</span>
          </div>
          <div *ngIf="clientWorkout.quickWorkoutDescription" class="mt-2">
            <div class="font-weight-bold">Description:</div>
          <span [editorHTML]="clientWorkout.quickWorkoutDescription"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-2">
    <div *ngIf="clientWorkout.coachNotes" class="card p-3 mb-3">
      <div class="font-weight-bold">Workout notes from your coach:</div>
      {{clientWorkout.coachNotes}}
    </div>

    <workout-view *ngIf="clientWorkout.workoutId" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [clientWorkoutIsCompleted]="clientWorkout.isCompleted" [isCoachView]="isCoachView" [workoutId]="clientWorkout.workoutId" [saveExerciseProgress]="saveExerciseProgress" (progressSaved)="onExerciseProgressSaved()"></workout-view>

    <accordion class="white-header" *ngIf="clientWorkout.isCompleted && clientWorkout.exerciseProgresses && clientWorkout.exerciseProgresses.length > 0">
      <accordion-group [isOpen]="false">
        <div accordion-heading>
          <h5 >
            <div class="pull-left float-left">Recorded Exercise Sets</div>
            <i class="far fa-chevron-right mr-1 float-right pull-right"></i>
          </h5>
          <span class="ml-2">(Expand to view/update)</span>
        </div>
        
        <div class="">
          <ng-container>
            <div class="">
              <form class="form" [formGroup]="exerciseProgressFormGroup" #ngForm="ngForm" novalidate>
                <ng-container formArrayName="exerciseProgresses">
                  <div *ngFor="let exerciseProgress of exerciseProgressFormGroup.get('exerciseProgresses').controls; index as e">
                    <div class="row mb-4">
                      <ng-container [formGroupName]="e">
                        <div class="col-12">
                          <div class="form-group mb-0 mb-md-3">
                            <div class="form-control-text">{{exerciseProgress.get('exerciseName').value}}</div>
                          </div>
                          <div class="row">
                            <div class="col-lg-1 col-2">
                              Set
                            </div>
                            <div class="col-lg-2 col-3">
                              Reps
                            </div>
                            <div class="col-md-4 col-5">
                              <select  class="resistance-type" formControlName="resistanceType" (ngModelChange)="onResistanceTypeChanged($event, e)">
                                <ng-container *ngFor="let mapEntry of exerciseResistanceTypeEnum | keyvalue">
                                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                                </ng-container>
                              </select>
                              <span *ngIf="exerciseProgress.get('resistanceType').value == exerciseResistanceType.Weight" class="ml-2">{{ exerciseProgress.get('weightUOM').value | enumString: weightUOMEnum }}</span>
                              <span *ngIf="exerciseProgress.get('resistanceType').value == exerciseResistanceType.Hold" class="ml-2">(sec)</span>
                            </div>
                            <div class="col-md-2 col-2 mobile-padding">
                              Done
                            </div>
                          </div>
                          <ng-container formArrayName="exerciseProgressSets">
                            <div *ngFor="let exerciseProgressSet of exerciseProgress.get('exerciseProgressSets').controls; index as s">
                              <div class="row">
                                <ng-container [formGroupName]="s">
                                  <div class="col-lg-1 col-2 set-number">
                                    {{ exerciseProgressSet.get('setNumber').value }}
                                  </div>
                                  <div class="col-lg-2 col-3">
                                    <kendo-numerictextbox noScroll class="form-control no-dial xs-textbox" formControlName="reps" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                                  </div>
                                  <div *ngIf="exerciseProgress.get('resistanceType').value == exerciseResistanceType.Resistance" class="col-md-4 col-5">
                                    <input class="form-control" formControlName="resistance" maxlength="50" type="text" />
                                  </div>
                                  <div *ngIf="exerciseProgress.get('resistanceType').value == exerciseResistanceType.Weight" class="col-md-4 col-5">
                                    <kendo-numerictextbox noScroll class="form-control no-dial small-textbox" formControlName="weight" [decimals]="'1'" [format]="'#.#'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                                  </div>
                                  <div *ngIf="exerciseProgress.get('resistanceType').value == exerciseResistanceType.Hold" class="col-md-4 col-5">
                                    <kendo-numerictextbox noScroll class="form-control no-dial xs-textbox" formControlName="hold" [decimals]="'0'" [format]="'n0'" min="0" max="9999" type="number" ></kendo-numerictextbox>
                                  </div>
                                  <div class="col-md-2 col-2">
                                    <input style="vertical-align: bottom;" class="d-inline-block single-checkbox" formControlName="isCompleted" type="checkbox" />
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </form>
            </div>
            <div class="mt-2 mb-4">
              <button *ngIf="clientWorkout.isCompleted" type="button" class="btn btn-sm btn-primary" (click)="updateExerciseSets()">Update Exercise Sets</button>
            </div>
          </ng-container>
        </div>
      </accordion-group>
    </accordion>

    <div class="card card--collapse--f p-3">
      <ng-container *ngIf="RPERequired">
        <div>
          {{clientWorkout.isCompleted && clientWorkout.ratePerceivedExertion ? 'Your RPE has been recorded! You can change it below if needed:' : 'Please rate your level of perceived exertion for this workout:'}}
        </div>
        <rate-perceived-exertion [ngClass]="{'ng-invalid ng-touched': showRPEError}" [(ngModel)]="clientWorkout.ratePerceivedExertion" (valueChange)="onRPEChanged()"></rate-perceived-exertion>
        <div *ngIf="showRPEError" class="invalid-message-visible">
          <div>Rate of Perceived Exertion is required</div>
        </div>
        <div class="mb-4">
          <button *ngIf="clientWorkout.isCompleted" type="button" class="btn btn-sm btn-primary" (click)="updateRPE()">Update Rate Perceived Exertion</button>
        </div>
      </ng-container>
      <ng-container *ngIf="((clientWorkout.workoutId || clientWorkout.eventType == eventType.QuickWorkout) && !clientWorkout.garminActivityId && !clientWorkout.stravaActivityId)">
        <div>
          {{clientWorkout.isCompleted ? 'Actual workout values have been recorded! You can change them below if needed:' : 'Please update your workout actuals here:'}}
        </div>
        <div class="">
          <form class="form" [formGroup]="durationFormGroup" #ngForm="ngForm" novalidate>
            <ng-container formArrayName="activityTypeDurations">
              <div *ngFor="let activityTypeDuration of durationFormGroup.get('activityTypeDurations').controls; index as e">
                <div class="row">
                  <ng-container [formGroupName]="e">
                    <div class="col-md-4 col-12">
                      <div class="form-group mb-0 mb-md-3">
                        <label class="form-control-label d-none d-sm-block" for="activityType"> &nbsp; </label>
                        <div class="form-control-text">{{activityTypeDuration.get('activityType').value | enumString:activityTypeEnum}}</div>
                      </div>
                    </div>
                    <div class="col-md-4 col-6">
                      <div class="form-group">
                        <label class="form-control-label" for="duration">
                          Duration ([h]:mm:ss)
                        </label>
                        <minutes-seconds-textbox formControlName="duration"></minutes-seconds-textbox>
                        <div class="invalid-message">
                          <div>format must be h:mm:ss or m:ss</div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="activityTypeDuration.get('activityType').value != activityType.StrengthTraining" class="col-md-4 col-6">
                      <div class="form-group">
                        <label class="form-control-label" for="distance">
                          Distance ({{localDistanceName}})
                        </label>
                        <kendo-numerictextbox noScroll class="form-control medium-textbox" formControlName="distance" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number" ></kendo-numerictextbox>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
        <div class="mb-4">
          <button *ngIf="clientWorkout.isCompleted" type="button" class="btn btn-sm btn-primary" (click)="updateDurations()">Update Actual Durations</button>
        </div>
      </ng-container>

      <div class="font-weight-bold">Workout Journal</div>
        <div *ngFor="let journal of clientWorkout.workoutJournals" class="message mb-1">
          <div class="message-header">
            {{journal.createdDate | date}} from {{journal.fromUserFirstName}} {{journal.fromUserLastName}}:
          </div>
          <div [innerHTML]="journal.body"></div>
        </div>
      <div class="mb-4">
        <textarea class="mt-1 mb-1 w-100" placeholder="{{isCoachView ? 'Send a message to your athlete' : 'Add a note or send a message to your coach'}}" [(ngModel)]="newWorkoutJournal" maxlength="500" type="text"></textarea>
        <div *ngIf="!isCoachView" class="btn-group" ngbDropdown role="group" aria-label="Workout Journal">
          <button class="btn btn-sm btn-primary" ngbDropdownToggle>Add Workout Journal</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button (click)="saveNote()" ngbDropdownItem>Add Note</button>
            <button (click)="saveMessage()" ngbDropdownItem>Notify Coach</button>
          </div>
        </div>
        <button *ngIf="isCoachView" class="btn btn-sm btn-primary" (click)="saveMessage()">Notify Athlete</button>
      </div>


      <ng-container *ngIf="clientWorkout.workoutId || clientWorkout.eventType == eventType.QuickWorkout">
        <button *ngIf="!clientWorkout.isCompleted" type="button" class="btn btn-primary" (click)="finishWorkout()">Finish Workout</button>
        <button *ngIf="clientWorkout.isCompleted && !clientWorkout.garminActivityId && !clientWorkout.stravaActivityId" type="button" class="btn btn-primary" (click)="incompleteWorkout()">Mark Workout Incomplete</button>
      </ng-container>
    </div>

    <div *ngIf="clientWorkout.workoutId && (clientWorkout.workoutLevelUpId || clientWorkout.workoutLevelDownId)" class="card card--collapse--f p-3">
      <ng-container>
        <div class="align-items-center d-flex justify-content-between">
          <button *ngIf="clientWorkout.workoutLevelDownId" type="button" class="btn btn-sm btn-primary" (click)="levelChange('down', clientWorkout.workoutLevelDownId)">Level Down</button>
          <div *ngIf="!clientWorkout.workoutLevelDownId" class="blank-button"></div>
          <div>This workout is part of a progression
            <div class="d-inline text-center" kendoTooltip>
              <i class="fas fa-info-circle ml-2" title="Selecting Level Up or Level Down will dynamically change future scheduled occurrences of the same workout (and this workout occurrence if not completed yet) to the next harder or easier workout in the progression."></i>
            </div>
          </div>
          <button *ngIf="clientWorkout.workoutLevelUpId" type="button" class="btn btn-sm btn-primary" (click)="levelChange('up', clientWorkout.workoutLevelUpId)">Level Up</button>
          <div *ngIf="!clientWorkout.workoutLevelUpId" class="blank-button"></div>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="clientWorkout.garminActivityId || clientWorkout.stravaActivityId" class="container mb-2 mt-2">
    <external-workout-summary [isSummaryOnly]="isSummaryOnly" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [clientUserId]="clientWorkout.clientUserId"></external-workout-summary>
  </div>

  <div *ngIf="!isSummaryOnly && (clientWorkout.garminActivityId || clientWorkout.stravaActivityId)" class="container mb-2 mt-2">
    <external-elevation-chart *ngIf="clientWorkout.activityTypeDurations[0].distance" class="mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [isMetricSystem]="isMetricSystem"></external-elevation-chart>
    <external-pace-chart *ngIf="clientWorkout.activityTypeDurations[0].distance && clientWorkout.activityTypeDurations[0].activityType != activityType.Biking" class="mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [isMetricSystem]="isMetricSystem"></external-pace-chart>
    <external-speed-chart *ngIf="clientWorkout.activityTypeDurations[0].distance && clientWorkout.activityTypeDurations[0].activityType == activityType.Biking" class="mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [isMetricSystem]="isMetricSystem"></external-speed-chart>
    <external-heart-rate-chart class="mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId"></external-heart-rate-chart>
    <external-cadence-chart *ngIf="clientWorkout.activityTypeDurations[0].distance" class="mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId"></external-cadence-chart>
    <external-stride-length-chart *ngIf="clientWorkout.activityTypeDurations[0].distance && (clientWorkout.activityTypeDurations[0].activityType == activityType.Running || clientWorkout.activityTypeDurations[0].activityType == activityType.Walking)" class="mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId"></external-stride-length-chart>
    <div class="mt-4"></div>
    <external-lap-chart *ngIf="clientWorkout.activityTypeDurations[0].distance" class="mt-2 mb-1" [clientProgramWorkoutDayId]="clientProgramWorkoutDayId" [showSpeedInsteadOfPace]="clientWorkout.activityTypeDurations[0].activityType == activityType.Biking" [isMetricSystem]="isMetricSystem"></external-lap-chart>
  </div>

  <div *ngIf="(clientWorkout.garminActivityId || clientWorkout.stravaActivityId) && (clientWorkout.workoutId || clientWorkout.eventType == eventType.QuickWorkout)" class="container mt-4">
    <a class="text-white" href="javascript:void(0)" (click)="onUnmatchWorkout()">Need to unmatch this synced workout?</a>
  </div>
  <div *ngIf="(clientWorkout.garminActivityId || clientWorkout.stravaActivityId) && !clientWorkout.workoutId && clientWorkout.eventType != eventType.QuickWorkout" class="container mt-4">
    <a class="text-white" href="javascript:void(0)" (click)="onMatchWorkout()">Need to match this synced workout to a scheduled workout?</a>
  </div>

</div>

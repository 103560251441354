import { Component, OnInit, Input } from '@angular/core';
import { DailyStatusListViewModel } from 'src/app/_models/generatedModels';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyStatusModalComponent } from '../runner-daily-status/daily-status-modal.component';


@Component({
  selector: 'daily-status-list',
  templateUrl: 'daily-status-list.component.html',
  styleUrls: ['daily-status-list.component.scss']
})
export class DailyStatusListComponent implements OnInit {

  entries: DailyStatusListViewModel[];
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  initialized: boolean;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;


  constructor(private router: Router, private clientService: ClientsService, private toastr: ToasterService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.clientService.getDailyStatusesForClient(this.clientUserId, this.currentPage, this.itemsPerPage).subscribe(result => {
      this.totalItems = result.rowCount;
      this.entries = result.results;
      this.initialized = true;
    });
  }

  pageChanged(event: any): void {
    this.getData();
  }

  openDetails(entryId: number) {
    const modalRef = this.modalService.open(DailyStatusModalComponent, { size: 'lg', windowClass: 'modal-sm-custom', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.dailyStatusId = entryId;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ClientsService } from '../../../_services/generatedServices';

// core components
@Component({
  selector: 'client-notifications',
  templateUrl: 'client-notifications.component.html'
})
export class ClientNotificationsComponent implements OnInit {

  initialized: boolean;
  userCredentialId: number;

  constructor(private route: ActivatedRoute, private breadcrumbs: BreadcrumbsService, private clientData: ClientsService, private router: Router) {}

  ngOnInit()
  {
    this.clientData.getClientByID(parseInt(getParamFromRoute(this.route, 'id'))).subscribe((result) =>
    {
      this.userCredentialId = result.userCredentialId;
      this.breadcrumbs.SetBreadcrumb('Messages', this.router.url, []);
      this.initialized = true;
    });
  }
}

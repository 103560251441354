import { Subject } from 'rxjs';

export class ServerSortableList<T> implements Iterable<T> {
  private fullList: T[];
  _isDesc: boolean = false;
  _column: string;
  _isDescIndex: boolean = false;
  _columnIndex: number;
  sortChanged: Subject<string> = new Subject<string>();

  constructor(
    data: T[],
    initialSortedColumn: string,
    isInitialSortedDesc: boolean = false
  ) {
    this.fullList = data;
    this._column = initialSortedColumn;
    this._isDesc = isInitialSortedDesc;
  }

  [Symbol.iterator]() {
    let pointer = 0;
    let fullList = this.fullList;
    return {
      next(): IteratorResult<T> {
        return {
          done: pointer === fullList.length,
          value: fullList[pointer++],
        };
      },
    };
  }

  public updateData(data: T[]) {
    this.fullList = data;
  }

  get length(): number {
    return this.fullList.length;
  }

  get column(): string {
    return this._column;
  }

  get isDesc(): boolean {
    return this._isDesc;
  }

  indexOf(searchElement: T, fromIndex?: number): number {
    return this.fullList.indexOf(searchElement, fromIndex);
  }

  sort(columnName: string) {
    if (columnName == this._column) {
      this._isDesc = !this._isDesc;
    } else {
      this._isDesc = false;
    }
    this._column = columnName;
    this.sortChanged.next();
  }

  getCSSClass(columnName: string, none: string = 'sortable-none', desc: string = 'sortable-desc', asc: string = 'sortable-asc') {
    if (columnName != this._column) {
      return none;
    } else if (columnName == this._column && !this._isDesc) {
      return asc;
    } else if (columnName == this._column && this._isDesc) {
      return desc;
    }
  }

  getCSSClassIndex(index: number, none: string = 'sortable-none', desc: string = 'sortable-desc', asc: string = 'sortable-asc') {
    if (index != this._columnIndex) {
      return none;
    } else if (index == this._columnIndex && !this._isDescIndex) {
      return asc;
    } else if (index == this._columnIndex && this._isDescIndex) {
      return desc;
    }
  }

  remove(callbackfn: (this: void, value: T, index: number, array: T[]) => any) {
    let index = this.fullList.findIndex(callbackfn); //find index in your array
    this.fullList.splice(index, 1);
  }

  find(callbackfn: (this: void, value: T, index: number, array: T[]) => any): T {
    let index = this.fullList.findIndex(callbackfn); //find index in your array
    return this.fullList[index];
  }

  toArray(): T[] {
    return this.fullList;
  }
}

<div *ngIf="initialized">
  <div class="card  mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Reschedule Program</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-12 mb-3">
              First, determine when you are ready to restart the program and select that date. Next, choose what week of the program that you want to start (see program schedule below).
              You can start the entire program over by selecting week 1, or pick back up where you left off by selecting a different week.
            </div>
            <div class="col-lg-4 col-md-6 mb-2">
              <div class="form-group">
                <label class="form-control-label" for="workoutDate"> Reschedule Start Date </label>
                <custom-recent-datepicker placeholder="" class="form-control" formControlName="startDate"></custom-recent-datepicker>
                <div class="invalid-message">
                  <div>Date is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label" for="workoutDate"> Week of Program to Re-start from </label>
                <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="startWeek" [decimals]="'0'" [format]="'n0'" min="1" [max]="maxProgramWeeks" type="number"></kendo-numerictextbox>
                <div class="invalid-message">
                  <div>Week is required</div>
                </div>
              </div>
            </div>
          </div>
      </form>
      <div class="row">
        <div class="col-12 mb-2">
          <h5>{{program.name}}</h5>
          <div [ngClass]="{'collapsed-description': !isExpandedDescription}">
            <span [editorHTML]="program.description"></span>
          </div>
          <div *ngIf="program.description" class="text-link" (click)="isExpandedDescription = !isExpandedDescription">
            {{ isExpandedDescription ? 'Less...' : 'More...' }}
          </div>
        </div>
        <div *ngFor="let week of programWeeks" class="col-12">
          <div [ngClass]="{'mb-2': !week.isExpanded}"><span class="font-weight-bold">Week {{week.weekNumber}}</span> <a (click)="week.isExpanded = !week.isExpanded" href="javascript:void(0)" class="ml-2 toggle-link">({{ week.isExpanded ? 'Hide' : 'Show' }} Details)</a></div>
          <div [hidden]="!week.isExpanded" class="mt--2 mb-4">
            <program-week-preview [week]="week"></program-week-preview>
          </div>
        </div>
      </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button type="submit" form="form" [promiseBtn]="submitComplete" class="btn btn-primary">Reschedule</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

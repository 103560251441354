<page-load-header *ngIf="!initialized"></page-load-header>

<ng-container *ngIf="initialized">
  <div *ngIf="subscriptionNote" class="row">
    <div class="col-12">
      <alert class="text-center" type="info" [dismissible]="false">
        <div>
          <span class="font-weight-bold">{{subscriptionNote}}</span>
        </div>
      </alert>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-3">
      <h3>Billing</h3>
      <p>Pay for athletes</p>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body p-3">
          <div *ngIf="organization.stripeCustomerId" class="row">
            <div class="col mt-auto mb-auto">
              <h4>
                <i *ngIf="organization.cardBrand === 'American Express'" class="fab fa-cc-amex"></i>
                <i *ngIf="organization.cardBrand === 'Visa'" class="fab fa-cc-visa"></i>
                <i *ngIf="organization.cardBrand === 'MasterCard'" class="fab fa-cc-mastercard"></i> ending in {{ organization.last4OfCard }}
              </h4>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-primary" (click)="updateCard()">Update Card</button>
              <a *ngIf="organization.runnerSlotSubscriptionId && !organization.subscriptionEndDate" class="d-block text-sm mt-2" href="javascript:void(0)" (click)="cancelSubscription()">Cancel Subscription</a>
            </div>
          </div>
          <div *ngIf="!organization.runnerSlotSubscriptionId || organization.subscriptionEndDate" class="row mt-2">
            <div *ngIf="!organization.subscriptionEndDate" class="col mt-auto mb-auto">No active subscription</div>
            <div *ngIf="organization.subscriptionEndDate" class="col mt-auto mb-auto">Subscription ends on {{organization.subscriptionEndDate | date}}</div>
            <div class="col text-right">
              <button type="button" class="btn btn-danger" (click)="activateSubscription()">{{organization.subscriptionEndDate ? 'Re-activate' : 'Activate'}} Subscription</button>
            </div>
          </div>
          <ng-container *ngIf="organization.runnerSlotSubscriptionId">
            <hr />
            <div class="row">
              <div class="col mt-auto mb-auto">
                <h4 *ngIf="organization.isMonthly">{{ organization.runnerSlots }} Athletes ({{ organization.subscriptionPrice | currency }}/month)</h4>
                <h4 *ngIf="!organization.isMonthly">{{ organization.runnerSlots }} Athletes ({{ organization.subscriptionPrice | currency }}/year)</h4>
              </div>
              <div class="col text-right">
                <span>Add or deactivate athletes to change count, <br />and subscription tier will change automatically.</span>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col">
              <a href="https://rundna.com/rundna-portal/#pricing" target="_blank">Pricing Tiers</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-3">
      <h3>Invoices</h3>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body p-3">
          <div class="row no-gutters no-candidates" *ngIf="invoices.length === 0">
            <div class="col-sm-12 my-auto">
              <h4>No Invoices</h4>
            </div>
          </div>
          <table class="table">
            <tr *ngFor="let invoice of invoices">
              <td>{{ invoice.periodEnd | date }}</td>
              <td>{{ invoice.status | titlecase }}</td>
              <td>{{ invoice.billingReason | titlecase }}</td>
              <td>{{ invoice.total | currency }}</td>
              <td><a href="{{invoice.invoicePdf}}" target="_blank">Download Invoice PDF</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { VideoPlayerComponent } from './_components/video-player/video-player.component';
import { CustomCalendarEventIconPipe, EnumStringPipe, FlagEnumStringPipe, MilesKilometersToMilesPipe, SecsToHourMinOnlyPipe, SecsToMinSecOnlyPipe, FromMilesToLocalMeasurementPipe } from './_helpers/extensions.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ProgramFirstWeekPreviewComponent } from './_components/program-first-week-preview/program-first-week-preview.component';
import { AddSurveyComponent } from './_components/survey-add/add-survey.component';
import { SurveyResultsComponent } from './_components/survey-results/survey-results.component';
import { PageLoadHeaderComponent } from './site-nav/pageLoadHeader.component';
import { CustomPaginationControl } from './_controls/customPagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgramWeekPreviewComponent } from './_components/program-week-preview/program-week-preview.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { KendoNumericTextboxDirective } from './_directives/kendo-numerictextbox.directive';
import { KendoDateInputDirective } from './_directives/kendo-dateinput.directive';
import { LoginControlComponent } from './_components/login/login-control.component';
import { RouterModule } from '@angular/router';
import { RatePerceivedExertionControl } from './_controls/ratePerceivedExertionControl';
import { TermsConditionsControl } from './_controls/termsAndConditionsCheckbox/termsConditionsCheckbox.component';
import { TermsAndConditionsDialogComponent } from './_components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { TermsAndConditionsTextComponent } from './_components/terms-and-conditions-text/terms-and-conditions-text.component';
import { PrivacyPolicyTextComponent } from './_components/privacy-policy-text/privacy-policy-text.component';
import { PrivacyPolicyDialogComponent } from './_components/privacy-policy-dialog/privacy-policy-dialog.component';
import { EditorHTMLDirective } from './_directives/editor-html.directive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CustomRecentDatepickerControl } from './_controls/customRecentDatepicker';
import { FileDropDirective } from './_directives/file-drop.directive';
import { MarketplaceProgramCategoriesComponent } from './marketplace-paid-programs/marketplace-program-categories/marketplace-program-categories.component';
import { MarketplaceKeywordProgramListComponent } from './marketplace-paid-programs/marketplace-keyword-program-list/marketplace-keyword-program-list.component';
import { MarketplaceCheckoutComponent } from './marketplace-paid-programs/marketplace-checkout/marketplace-checkout.component';
import { MarketplaceCoachProgramListComponent } from './marketplace-paid-programs/marketplace-coach-program-list/marketplace-coach-program-list.component';
import { MarketplaceProgramPreviewComponent } from './marketplace-paid-programs/marketplace-program-preview/marketplace-program-preview.component';
import { RunDNAWebsiteWrapperComponent } from './_components/rundna-website-wrapper/rundna-website-wrapper.component';
import { IntensityChartDialogComponent } from './_components/intensity-chart-dialog/intensity-chart-dialog.component';
import { RunDNABasicWebsiteWrapperComponent } from './_components/rundna-basic-website-wrapper/rundna-basic-website-wrapper.component';

@NgModule({
    declarations: [
        KendoDateInputDirective,
        VideoPlayerComponent,
        CustomCalendarEventIconPipe,
        SecsToHourMinOnlyPipe,
        SecsToMinSecOnlyPipe,
        MilesKilometersToMilesPipe,
        ProgramFirstWeekPreviewComponent,
        AddSurveyComponent,
        SurveyResultsComponent,
        EnumStringPipe,
        FlagEnumStringPipe,
        PageLoadHeaderComponent,
        CustomPaginationControl,
        ProgramWeekPreviewComponent,
        KendoNumericTextboxDirective,
        LoginControlComponent,
        RatePerceivedExertionControl,
        TermsConditionsControl,
        TermsAndConditionsDialogComponent,
        TermsAndConditionsTextComponent,
        PrivacyPolicyTextComponent,
        PrivacyPolicyDialogComponent,
        EditorHTMLDirective,
        CustomRecentDatepickerControl,
        FileDropDirective,
        MarketplaceProgramCategoriesComponent,
        MarketplaceKeywordProgramListComponent,
        MarketplaceProgramPreviewComponent,
        MarketplaceCheckoutComponent,
        MarketplaceCoachProgramListComponent,
        RunDNAWebsiteWrapperComponent,
        IntensityChartDialogComponent,
        FromMilesToLocalMeasurementPipe,
        RunDNABasicWebsiteWrapperComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DateInputsModule,
        TooltipModule,
        InputsModule,
        DropDownsModule,
        NgbModule,
        RouterModule,
        CKEditorModule,
        PaginationModule.forRoot(),
        Angular2PromiseButtonModule.forRoot({
            // your custom config goes here
            spinnerTpl: '<span class="promise-btn-spinner"><i class="fas fa-spinner ml-1"></i></span>',
            // disable buttons when promise is pending
            disableBtn: true,
            // the class used to indicate a pending promise
            btnLoadingClass: 'is-loading',
            // only disable and show is-loading class for clicked button,
            // even when they share the same promise
            handleCurrentBtnOnly: false,
        }),
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DateInputsModule,
        TooltipModule,
        InputsModule,
        DropDownsModule,
        NgbModule,
        CKEditorModule,
        PaginationModule,
        Angular2PromiseButtonModule,
        KendoDateInputDirective,
        VideoPlayerComponent,
        CustomCalendarEventIconPipe,
        SecsToHourMinOnlyPipe,
        SecsToMinSecOnlyPipe,
        MilesKilometersToMilesPipe,
        ProgramFirstWeekPreviewComponent,
        AddSurveyComponent,
        SurveyResultsComponent,
        EnumStringPipe,
        FlagEnumStringPipe,
        PageLoadHeaderComponent,
        CustomPaginationControl,
        ProgramWeekPreviewComponent,
        KendoNumericTextboxDirective,
        LoginControlComponent,
        RatePerceivedExertionControl,
        TermsConditionsControl,
        TermsAndConditionsDialogComponent,
        TermsAndConditionsTextComponent,
        PrivacyPolicyTextComponent,
        PrivacyPolicyDialogComponent,
        EditorHTMLDirective,
        CustomRecentDatepickerControl,
        FileDropDirective,
        MarketplaceProgramCategoriesComponent,
        MarketplaceKeywordProgramListComponent,
        MarketplaceProgramPreviewComponent,
        MarketplaceCheckoutComponent,
        MarketplaceCoachProgramListComponent,
        RunDNAWebsiteWrapperComponent,
        IntensityChartDialogComponent,
        FromMilesToLocalMeasurementPipe,
        RunDNABasicWebsiteWrapperComponent,
    ]
})
export class SharedModule {

}

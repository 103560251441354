<page-load-header *ngIf="!initialized"></page-load-header>

<div>
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">App Stats</h3>
      </div>
    </div>

    <div *ngIf="initialized" class="row mt-3">
      <div class="col-12">
        <span class="stat-value">{{stats.trialsStartedInLast30Days}}</span> trials started in last 30 days
      </div>
      <div class="col-12">
        <span class="stat-value">{{stats.paidSubscriptionsStartedInLast30Days}}</span> paid subscriptions started in last 30 days
      </div>
      <div class="col-12">
        <span class="stat-value">{{stats.totalActiveOrgs}}</span> total active organizations
      </div>
      <div class="col-12">
        <span class="stat-value">{{stats.totalActiveAthletes}}</span> total active athletes
      </div>
      <div class="col-12">
        <span class="stat-value">{{stats.totalActiveAthletesInMultipleOrgs}}</span> total active athletes in more than 1 organization
      </div>
      <div class="col-12">
        <span class="stat-value">{{stats.totalActiveCoachesInMultipleOrgs}}</span> total active coaches in more than 1 organization
      </div>
      <div class="col-12">
        <a href="https://dashboard.stripe.com/products/prod_IrvoGY3WzNWgzG" target="_blank">Stripe MRR (monthly recurring revenue) data for App</a>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <button [promiseBtn]="submitComplete" (click)="onExportMonthlyData()" class="btn btn-primary">Export Monthly Reporting</button>
      </div>
    </div>

    <div *ngIf="initialized" class="row mt-5">
      <div class="col-md-4">
        <h5>All Paid Subscriptions</h5>
      </div>
      <div class="col-md-4">
        <h5>Total Subscriptions: {{stats.totalPaidSubscriptions}}</h5>
      </div>
      <div class="col-md-4">
        <h5>Total Monthly Subscription Price: ${{stats.totalMonthlySubscriptionPrice | number:'1.2-2'}}</h5>
      </div>
      <div class="col">
        <div class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row">
                <th class="sort" data-sort="name" scope="col">Id</th>
                <th class="sort" data-sort="name" scope="col">Subscribed On</th>
                <th class="sort" data-sort="key" scope="col">Name</th>
                <th class="sort" data-sort="key" scope="col">Runner Slots</th>
                <th class="sort" data-sort="key" scope="col">Subscription Price</th>
                <th class="sort" data-sort="key" scope="col">Subscription Type</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let org of stats.organizationsWithSubscription; let i = index">
                <td>
                  <span class="mobile-grid-label">Id</span>
                  {{ org.id }}
                </td>
                <td>
                  <span class="mobile-grid-label">Subscribed On</span>
                  {{ org.activationDate | date }}
                </td>
                <td>
                  <span class="mobile-grid-label">Name</span>
                  {{ org.name }}
                </td>
                <td>
                  <span class="mobile-grid-label">Runner Slots</span>
                  {{ org.runnerSlots }}
                </td>
                <td>
                  <span class="mobile-grid-label">Subscription Price</span>
                  ${{ org.subscriptionPrice | number:'1.2-2' }}
                </td>
                <td>
                  <span class="mobile-grid-label">Subscription Type</span>
                  {{ org.isMonthly ? 'Monthly' : 'Yearly' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

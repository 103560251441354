import { Component, OnInit } from '@angular/core';
import { DateRangeViewModel, OrganizationListViewModelRead, OrganizationSearchParams } from 'src/app/_models/generatedModels';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ExportService } from 'src/app/_services/export.service';
import { saveAs } from 'file-saver';
import { formatShortDate } from 'src/app/_helpers/extensions.module';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeDialogComponent } from 'src/app/_components/date-range-dialog/date-range-dialog.component';

@Component({
  selector: 'bxl-organization-list',
  templateUrl: 'organization-list.component.html',
})
export class OrganizationListComponent implements OnInit {

  initialized = false;
  organizations: OrganizationListViewModelRead[];
  editUrl: string = '../organizations/edit';
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  isExpired = false;

  constructor(private organizationService: OrganizationService, private breadcrumbs: BreadcrumbsService, private exportService: ExportService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Organizations', 'organizations', []);
    this.searchOrganizations();

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.searchOrganizations();
    });
  }

  searchOrganizations() {
    this.searching = true;
    let model = new OrganizationSearchParams();
    model.searchText = this.filter;
    model.isExpired = <any>this.isExpired;

    this.organizationService.searchOrganizations(model).subscribe((result) => {
      this.organizations = result;
      this.searching = false;
      this.initialized = true;
    });
  }

  onStatusChanged(query: string) {
    this.searchOrganizations();
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  export(organizationId: number, organizationName: string) {
    const modalRef = this.modalService.open(DateRangeDialogComponent, { size: 'lg' });
    modalRef.componentInstance.headerText = 'Select a date range for the report';
    modalRef.componentInstance.saved.subscribe((result) => {
      const dateRange = result as DateRangeViewModel;
      this.exportService.ExportSuperuserOrganizationReport(organizationId, dateRange.startDate, dateRange.endDate).subscribe(file => {
        let fileName = `SuperuserOrganizationReport_${organizationName}_${formatShortDate(dateRange.startDate)}_${formatShortDate(dateRange.endDate)}.xlsx`;
        saveAs(file, fileName);
      });
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}

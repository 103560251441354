import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DynamicReportFilterViewModel, DynamicReportRowViewModel, DynamicReportViewModel } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ReportsService } from 'src/app/_services/generatedServices';
import { saveAs } from 'file-saver';
import { ExportService } from 'src/app/_services/export.service';
import { formatShortDate } from 'src/app/_helpers/extensions.module';
import { ChartData, ChartingService } from 'src/app/_services/charting.service';
import { SortableList } from 'src/app/_helpers/sortableList';

@Component({
  selector: 'app-report-completion-percentage',
  templateUrl: 'completion-percentage.component.html',
  styleUrls: ['completion-percentage.component.scss']
})
export class ReportCompletionPercentageComponent implements OnInit, OnDestroy {
  initialized = false;
  data: DynamicReportViewModel;
  currentFilterValue: DynamicReportFilterViewModel;
  exportComplete: Promise<{}> | undefined;
  tableData: SortableList<DynamicReportRowViewModel>;

  @ViewChild('tabNav') tabNav;

  constructor(private reportsService: ReportsService, private breadcrumbs: BreadcrumbsService, private exportService: ExportService,
    private charting: ChartingService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Completion Percentage Report', 'completion-percentage', []);
  }

  onCoachFilterChanged(value: number | null) {
    this.initialized = false;
    this.tabNav.select(0);
    this.currentFilterValue = new DynamicReportFilterViewModel();
    this.currentFilterValue.coachId = value;
    this.reportsService.getCompletionPercentageReport(this.currentFilterValue).subscribe(results => {
      this.data = results;
      this.tableData = new SortableList<DynamicReportRowViewModel>(results.tableValues, "userFullName");
      this.displayChart();
      this.initialized = true;
    });
  }

  displayChart() {
    this.charting.queueCharts(
      this.charting.drawStackedColumnChart.bind(this.charting),
      new ChartData(
          'completionChart',
          this.data.chartHeaders,
          this.data.chartData,
          'Completion Percentage',
          'completionPercentage'
      )
    );
  }

  onExport() {
    this.exportComplete = new Promise((resetButton:any, reject) => {
      this.exportService.ExportCompletionPercentageReport(this.currentFilterValue).subscribe(file => {
        let date = formatShortDate(new Date());
        let fileName = `CompletionPercentageReport_${date}.xlsx`;
        saveAs(file, fileName);
        resetButton();
      });
    });
  }

  ngOnDestroy() {
    this.charting.unsubscribe();
  }
}

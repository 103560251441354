import { Component, OnInit } from '@angular/core';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientProgramListViewModel } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ClientsService } from 'src/app/_services/generatedServices';

// core components
@Component({
  selector: 'client-dashboard',
  templateUrl: 'client-dashboard.component.html',
})
export class ClientDashboardComponent implements OnInit {

  initialized: boolean;
  programs: ClientProgramListViewModel[];
  clientUserId: number;
  clientUserCredentialId: number;

  constructor(private route: ActivatedRoute, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService, private router: Router, private clientService: ClientsService) {}

  ngOnInit() {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.breadcrumbs.SetBreadcrumb('Dashboard', this.router.url, []);

    this.clientService.getClientByID(this.clientUserId).subscribe(user => {
      this.clientUserCredentialId = user.userCredentialId;
      this.initialized = true;
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="row mt-3">
  <div *ngIf="!isPreview" class="col-lg-4 form-group d-none d-lg-block">
    <label class="form-control-label">Select value to sort by, then click column header to sort:</label>
    <select [(ngModel)]="sortFieldName" class="form-control">
      <option value="totalTime">Total Duration</option>
      <option value="totalMiles">Total Distance</option>
      <option value="timePercentageChange">Duration % Change</option>
      <option value="milesPercentageChange">Distance % Change</option>
    </select>
  </div>
  <div class="col-12">
    <div class="table-responsive">
      <table class="table align-items-center table-flush mobile-xs-table">
        <thead class="thead-light">
          <tr class="header-row sortable">
            <th class="sticky-top" (click)="tableData.sort('userFullName')" [ngClass]="tableData.getCSSClass('userFullName')">Athlete Name</th>
            <ng-container *ngFor="let header of data.tableHeaders; let x = index">
              <th class="sticky-top" (click)="tableData.sortByIndex('tableCellValues', x, sortFieldName)" [ngClass]="tableData.getCSSClassIndex(x)">{{ header }}</th>
            </ng-container>
          </tr>
        </thead>
        <tbody class="list">
          <tr *ngFor="let row of tableData">
            <td><span class="mobile-grid-label">Athlete Name</span>{{ row.userFullName }}</td>

            <ng-container *ngFor="let cell of row.tableCellValues; let i = index">
              <td>
                <span class="mobile-grid-label">{{ data.tableHeaders[i] }}</span>
                <span class="volume-cell volume-time">{{ cell.totalTime | secsToHourMinSec }}</span>
                <span class="volume-cell volume-divider"> / </span>
                <span class="volume-cell volume-miles">{{ (cell.totalMiles | fromMilesToLocalMeasurement) | number: '1.2-2' }} {{localMeasurementName}}</span>
                <br />
                <span class="volume-cell volume-time">{{ cell.timePercentageChange | number: '1.0-0' }}{{ cell.timePercentageChange != null ? '%' : '' }} <i class="fa" [ngClass]="{ 'fa-arrow-down text-danger': cell.isUpTrendTime == false, 'fa-arrow-up text-success': cell.isUpTrendTime }"></i></span>
                <span class="volume-cell volume-divider">{{ cell.timePercentageChange != null ? ' / ' : '' }}</span>
                <span class="volume-cell volume-miles">{{ cell.milesPercentageChange | number: '1.0-0' }}{{ cell.milesPercentageChange != null ? '%' : '' }} <i class="fa" [ngClass]="{ 'fa-arrow-down text-danger': cell.isUpTrendMiles == false, 'fa-arrow-up text-success': cell.isUpTrendMiles }"></i></span>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="data.tableValues.length == 0">There is no data for the selected filters</div>
  </div>
</div>

<div>
  <div class="card">
    <div class="">
      <header class="card-header py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0"><span *ngIf="!initialized">Create New Library Workout</span></h3>

          <button type="button" class="close" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>

      <div class="card-body p-0">
        <div *ngIf="!initialized" class="row">

          <div class="mt-3 mb-3 col-12 text-center">
            <div>Workout Types<div class="d-inline text-center" kendoTooltip>
              <i class="fas fa-info-circle ml-2" title="Garmin workouts are formatted to allow the athlete to send the workout to their Garmin device."></i>
            </div></div>
            <div class="btn-group mr-3">
              <div class="btn-group" ngbDropdown role="group" aria-label="Workout dropdown">
                <button class="btn btn-primary" ngbDropdownToggle>Create Workout</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button (click)="onSelectWorkout(workoutType.Cardio)" ngbDropdownItem>Cardio Workout</button>
                  <button (click)="onSelectWorkout(workoutType.Strength)" ngbDropdownItem>Strength Workout</button>
                  <button (click)="onSelectWorkout(workoutType.GarminRunning)" ngbDropdownItem>Garmin Running Workout</button>
                  <button (click)="onSelectWorkout(workoutType.GarminCycling)" ngbDropdownItem>Garmin Cycling Workout</button>
                  <button (click)="onSelectWorkout(workoutType.GarminLap_Swimming)" ngbDropdownItem>Garmin Lap Swimming Workout</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="initialized" class="container-fluid mt-3 mb-6">
          <add-edit-workout-component [workoutIdInput]="workoutIdToModify" [isWorkoutCopy]="isWorkoutCopy" [workoutCopyDate]="workoutCopyDate" [workoutTypeInput]="type" (savedObject)="onWorkoutCreated($event)" (isDirtyChange)="onIsDirtyChange($event)"></add-edit-workout-component>
        </div>
      </div>
    </div>
  </div>
</div>




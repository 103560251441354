import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LevelChangeClientExerciseViewModel } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-exercise-level-change-modal',
  templateUrl: 'exercise-level-change-modal.component.html',
  styleUrls: ['exercise-level-change-modal.component.scss']
})
export class ExerciseLevelChangeModalComponent implements OnInit {
  initialized = false;

  // userCredentialId used for level change from workout
  @Input()
  userCredentialId: number;
  
  // workoutId used for level change from workout
  @Input()
  workoutId: number;

  @Input()
  defaultExerciseId: number;

  @Input()
  levelChangeExerciseId: number;

  @Input()
  isLevelUp: boolean;

  // userId used for level change from My Library
  @Input()
  userId: number;

  @Input()
  fromMyLibrary: boolean;

  


  constructor(public activeModal: NgbActiveModal, private clientService: ClientsService, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.initialized = true;
  }

  onLevelChange() {
    if (this.fromMyLibrary) {
      let model = new LevelChangeClientExerciseViewModel();
      model.clientUserId = this.userId;
      model.originalExerciseId = this.defaultExerciseId;
      model.newExerciseId = this.levelChangeExerciseId;
      this.clientService.levelChangeClientExercise(model).subscribe(result => {
        this.toastr.success('Exercise Leveled ' + (this.isLevelUp ? 'Up' : 'Down'), 'Success');
        this.activeModal.dismiss('saved');
    });
    } else {
      this.clientService.makeUserCredentialWorkoutExerciseChanges(this.userCredentialId, this.workoutId, this.defaultExerciseId, this.levelChangeExerciseId).subscribe(result => {
        this.toastr.success('Exercise Leveled ' + (this.isLevelUp ? 'Up' : 'Down'), 'Success');
        this.activeModal.dismiss('saved');
      });
    }
  }
}

import { Component, OnInit, Input} from '@angular/core';
import { BasicDonutPieData } from 'src/app/_models/models';


@Component({
  selector: 'donut-pie-chart',
  templateUrl: 'donut-pie-chart.component.html',
  styleUrls: ['donut-pie-chart.component.scss']
})
export class DonutPieChartComponent implements OnInit {
  initialized = false;
  blue_color: string = '#007BFF';
  green_color: string = '#2dce89';

  @Input()
  chartData: BasicDonutPieData[];

  @Input()
  centerText: string;

  @Input()
  centerTextClass: string;

  constructor() {}

  ngOnInit(): void {
    let totalValue = this.chartData.map(x => x.value).reduce((a, b) => a + b, 0);
    let runningTotal = 0;
    let index = 0;

    this.chartData.forEach(data => {
      data.percentage = Math.round((data.value / totalValue) * 100);
      data.dashoffset = (index == 0 ? 75 : (100 - runningTotal + 75));
      runningTotal += data.percentage;
      index += 1;
    });
    this.initialized = true;
  }

}

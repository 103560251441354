<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div [ngClass]="{'no-padding': isCoachView}" class="container mt-3">
    <h4 class="mb-1">{{analysis.name ? analysis.name : '3D Gait Analysis'}}</h4>
    <h5 class="d-inline">{{analysis.date | date}}</h5>
    <h5 *ngIf="analysis.shoeManufacturer || analysis.shoeModel" class="d-inline">  ({{ analysis.shoeManufacturer + ' ' + analysis.shoeModel }})</h5>
    <div *ngIf="!isShared" class="mb-1"><a href="{{shareableLink}}" target="_blank">Shareable Link</a> <a href="javascript:void(0)" (click)="onCopyLink()" class="btn btn-sm btn-primary ml-2"> Copy Link </a></div>
    <div *ngIf="analysis.notes" class="row mb-1">
      <div class="col">
        Notes: {{analysis.notes}}
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">Summary</h5></div>
      <div class="card-body">
        <div class="row justify-content-center shared-row">
          <div class="col-lg-9 col-xl-8 shared-col">
            <div>
              <h3>Impairment Category - {{analysis.runningCategory}}</h3>
            </div>
            <div>
              <video *ngIf="!isWowzaVideo" #livePLayer id="livePLayer" class="azuremediaplayer amp-default-skin embed-responsive" style="z-index: 0" autoplay></video>
              <div *ngIf="isWowzaVideo" #wowzaPlayer id="wowzaPlayer" class="azuremediaplayer amp-default-skin embed-responsive live-player live-player-fluid hide-fullscreen" style="z-index: 0; background-color: black; display:flex; align-items: center"></div>
              </div>
            <table class="table align-items-center table-flush mobile-xs-table">
              <tr class="bg-light">
                <td>General Parameters</td>
                <td class="text-center d-none d-lg-table-cell">Left</td>
                <td class="text-center d-none d-lg-table-cell">Right</td>
              </tr>
              <tr>
                <td>
                  <a [routerLink]="'.'" [fragment]="'cadence'">Cadence</a></td>
                <td colspan="2" class="text-lg-center">{{ analysis.cadence }}</td>
              </tr>
              <tr>
                <td>
                  <a [routerLink]="'.'" [fragment]="'verticalOscillation'">Vertical Occilation</a></td>
                <td colspan="2" class="text-lg-center">{{ analysis.verticalOscillation }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'groundContactTime'">Ground Contact Time</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.groundContactTime.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.groundContactTime.right }}</td>
              </tr>
              <tr *ngIf="analysis.frontSideStance">
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'frontSideStance'">Front Side Stance</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.frontSideStance.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.frontSideStance.right }}</td>
              </tr>
              <tr *ngIf="analysis.dutyFactor">
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'dutyFactor'">Duty Factor</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.dutyFactor.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.dutyFactor.right }}</td>
              </tr>
              <tr *ngIf="analysis.legStiffness">
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'legStiffness'">Leg Stiffness</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.legStiffness.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.legStiffness.right }}</td>
              </tr>
              <tr class="bg-light">
                <td>1/Initial Contact Phase</td>
                <td class="text-center d-none d-lg-table-cell">Left</td>
                <td class="text-center d-none d-lg-table-cell">Right</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'tibialInclination'">Tibial Inclination</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.tibialInclination.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.tibialInclination.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'footInclination'">Foot Inclination</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.footInclination.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.footInclination.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'strikeFromCom'">Strike from COM</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.strikeFromCom.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.strikeFromCom.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'crossover'">Crossover</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.crossover.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.crossover.right }}</td>
              </tr>
              <tr class="bg-light">
                <td>2/Mid-Stance Phase</td>
                <td class="text-center d-none d-lg-table-cell">Left</td>
                <td class="text-center d-none d-lg-table-cell">Right</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'ankleDorsiflexion'">Ankle Dorsiflexion/Plantarflexion</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.ankleDorsiflexionPlantarflexion.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.ankleDorsiflexionPlantarflexion.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'footPronationSupination'">Foot Pronation/Supination</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.footPronationSupination.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.footPronationSupination.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'peakKneeFlexion'">Peak Knee flexion</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.peakKneeFlexion.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.peakKneeFlexion.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'peakHipAdd'">Peak Hip ADD</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.peakHipAdd.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.peakHipAdd.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'peakIr'">Peak IR</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.peakIr.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.peakIr.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'contralateralPelvicDrop'">Contalateral pelvic drop</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.contralateralPelvicDrop.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.contralateralPelvicDrop.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'forwardLeanAngle'">Forward lean angle</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.forwardLeanAngle.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.forwardLeanAngle.right }}</td>
              </tr>
              <tr class="bg-light">
                <td>3/Terminal Stance Phase</td>
                <td class="text-center d-none d-lg-table-cell">Left</td>
                <td class="text-center d-none d-lg-table-cell">Right</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'hipExtension'">Hip Extension</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.hipExtension.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.hipExtension.right }}</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'ankleSupination'">Ankle Supination</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.ankleSupination.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.ankleSupination.right }}</td>
              </tr>
              <tr class="bg-light">
                <td>4/Swing Phase</td>
                <td class="text-center d-none d-lg-table-cell">Left</td>
                <td class="text-center d-none d-lg-table-cell">Right</td>
              </tr>
              <tr>
                <td class="d-block d-md-table-cell"><a [routerLink]="'.'" [fragment]="'hipFlexion'">Hip Flexion</a></td>
                <td class="text-lg-center text-info"><span class="mobile-grid-label">Left</span>{{ analysis.hipFlexion.left }}</td>
                <td class="text-lg-center text-danger"><span class="mobile-grid-label">Right</span>{{ analysis.hipFlexion.right }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">General Parameters</h5></div>
      <div class="card-body">
        <div [attr.id]="'cadence'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/cadience.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Cadence: {{ analysis.cadence }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="140" [maxValue]="200" [valueLeft]="analysis.cadence" [valueRight]="''" [yellowPoints]="[161, 168]" [redPoints]="[140, 160]" [greenPoints]="[169, 200]"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Cadence</strong> is the number of steps per minute you take measured in Steps Per Minute (spm).</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'verticalOscillation'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/vertical-occilation.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Vertical oscillation: {{ analysis.verticalOscillation }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="4" [maxValue]="15" [valueLeft]="analysis.verticalOscillation" [valueRight]="''" [yellowPoints]="[4, 5.5, 10]" [redPoints]="[11, 15]" [greenPoints]="[6.5, 9]"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Vertical Oscillation</strong> is the amount of motion spent going up and down.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'groundContactTime'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/ground-contact-time.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Ground Contact Time: {{ analysis.groundContactTime.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Ground Contact Time: {{ analysis.groundContactTime.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="100" [maxValue]="350" [yellowPoints]="[205, 230]" [redPoints]="[260, 350]" [greenPoints]="[100, 175]" [valueLeft]="analysis.groundContactTime.left" [valueRight]="analysis.groundContactTime.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Ground Contact time</strong> is the time spent with your foot on the ground in milliseconds.</div>
            </div>
          </div>
        </div>

        <div *ngIf="analysis.frontSideStance" [attr.id]="'frontSideStance'" class="row mb-5">
          <div class="col">

          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Front Side Stance: {{ analysis.frontSideStance.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Front Side Stance: {{ analysis.frontSideStance.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="0" [maxValue]="50" [yellowPoints]="[26, 29]" [redPoints]="[30, 50]" [greenPoints]="[0, 25]" [valueLeft]="analysis.frontSideStance.left" [valueRight]="analysis.frontSideStance.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Front Side Stance</strong> is the percentage of stance phase that happens in front of your center of mass. Higher front side stance can indicate additional braking and slowing.</div>
            </div>
          </div>
        </div>

        <div *ngIf="analysis.dutyFactor" [attr.id]="'dutyFactor'" class="row mb-5">
          <div class="col">

          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Duty Factor: {{ analysis.dutyFactor.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Duty Factor:: {{ analysis.dutyFactor.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="0" [maxValue]="50" [yellowPoints]="[26, 29]" [redPoints]="[30, 50]" [greenPoints]="[0, 25]" [valueLeft]="analysis.dutyFactor.left" [valueRight]="analysis.dutyFactor.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Duty Factor</strong> is the percentage of time spent on the ground (stance) versus the percentage of time spent in the air (swing). A lower value is desirable and it is beneficial to have similar duty cycle values across different speeds.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'legStiffness'" class="row mb-5">
          <div class="col">
            
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Leg Stiffness: {{ analysis.legStiffness.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Leg Stiffness: {{ analysis.legStiffness.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="5" [maxValue]="30" [yellowPoints]="[10, 13.5]" [redPoints]="[5, 7]" [greenPoints]="[14.5, 25]" [valueLeft]="analysis.legStiffness.left" [valueRight]="analysis.legStiffness.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Leg Stiffness</strong> is a measure of how “springy” your leg is while running. Higher stiffness means that your body goes through less motion and lower stiffness means your body goes through more motion. From a performance standpoint, higher stiffness can mean improved performance. Asymmetrical stiffness may indicate past or future injury.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">1/Initial Contact Phase</h5></div>
      <div class="card-body">

        <div [attr.id]="'tibialInclination'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/foot-inclination.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Tibial Inclination: {{ analysis.tibialInclination.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Tibial Inclination: {{ analysis.tibialInclination.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-10" [maxValue]="15" [minValueAbbr]="'ext'" [maxValueAbbr]="'flex'" [greenPoints]="[-10, 3]" [yellowPoints]="[4, 5]" [redPoints]="[8, 15]" [valueLeft]="analysis.tibialInclination.left" [valueRight]="analysis.tibialInclination.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Tibial Inclination</strong> is the angle of your shin bone in relationship to the ground.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'footInclination'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/foot-inclination.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Foot Inclination: {{ analysis.footInclination.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Foot Inclination: {{ analysis.footInclination.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-10" [maxValue]="35" [minValueAbbr]="'DF'" [maxValueAbbr]="'PF'" [greenPoints]="[-10, 5]" [yellowPoints]="[8, 15]" [redPoints]="[18, 30]" [valueLeft]="analysis.footInclination.left" [valueRight]="analysis.footInclination.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Foot Inclination</strong> is the angle of your foot in relationship to the ground.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'strikeFromCom'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/com-strike.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Strike from COM: {{ analysis.strikeFromCom.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Strike from COM: {{ analysis.strikeFromCom.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="15" [maxValue]="45" [greenPoints]="[15, 27]" [yellowPoints]="[28, 29]" [redPoints]="[31, 45]" [valueLeft]="analysis.strikeFromCom.left" [valueRight]="analysis.strikeFromCom.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col">The <strong>distance from your Center of Mass (COM) to where your foot hits the ground</strong> is a measure that is related to the forces going through your lower body.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'crossover'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/crossover.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Crossover: {{ analysis.crossover.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Crossover: {{ analysis.crossover.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-5" [maxValue]="20" [minValueAbbr]="'add'" [maxValueAbbr]="'abd'" [greenPoints]="[6, 11]" [yellowPoints]="[1, 5, 12, 15]" [redPoints]="[-5, 0, 16, 20]" [valueLeft]="analysis.crossover.left" [valueRight]="analysis.crossover.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Crossover</strong> Crossover describes the position of the foot at IC in relationship to the midline of your body.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">2/Mid-Stance Phase</h5></div>
      <div class="card-body">
        <div [attr.id]="'ankleDorsiflexion'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/angle-dorsiflexion.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Ankle Dorsiflexion/Plantarflexion: {{ analysis.ankleDorsiflexionPlantarflexion.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Ankle Dorsiflexion/Plantarflexion: {{ analysis.ankleDorsiflexionPlantarflexion.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-10" [maxValue]="10" [minValueAbbr]="'DF'" [maxValueAbbr]="'PF'" [yellowPoints]="[-2, 0]" [redPoints]="[1, 10]" [greenPoints]="[-10, -3]" [valueLeft]="analysis.ankleDorsiflexionPlantarflexion.left" [valueRight]="analysis.ankleDorsiflexionPlantarflexion.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Ankle Dorsiflexion</strong> is the amount of motion occurring at the ankle that contributes to force absorption.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'footPronationSupination'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/foot-pronation.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Foot Pronation/Supination: {{ analysis.footPronationSupination.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Foot Pronation/Supination: {{ analysis.footPronationSupination.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-5" [maxValue]="15" [minValueAbbr]="'sup'" [maxValueAbbr]="'pron'" [redPoints]="[-10, -6, 9, 15]" [greenPoints]="[4, 0]" [yellowPoints]="[-4, -1, 6, 7]" [valueLeft]="analysis.footPronationSupination.left" [valueRight]="analysis.footPronationSupination.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Pronation </strong> is a normal part of running. When we pronate, it allows our foot to come in contact with the ground and absorb forces.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'peakKneeFlexion'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/peak-knee.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Peak Knee Flexion: {{ analysis.peakKneeFlexion.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Peak Knee Flexion: {{ analysis.peakKneeFlexion.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="10" [maxValue]="60" [redPoints]="[10, 15, 46, 60]" [greenPoints]="[21, 35]" [yellowPoints]="[16, 20, 36, 45]" [valueLeft]="analysis.peakKneeFlexion.left" [valueRight]="analysis.peakKneeFlexion.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Peak Knee Flexion </strong> is another measure at mid stance that is a gauge of how much your body is sinking in to the ground.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'peakHipAdd'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/hip-add.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Peak Hip Adduction: {{ analysis.peakHipAdd.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Peak Hip Adduction: {{ analysis.peakHipAdd.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-5" [maxValue]="20" [minValueAbbr]="'add'" [maxValueAbbr]="'abd'" [redPoints]="[11, 20]" [greenPoints]="[0, 5]" [yellowPoints]="[-5, -1, 6, 10]" [valueLeft]="analysis.peakHipAdd.left" [valueRight]="analysis.peakHipAdd.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Hip Adduction</strong>  is when the hip moves towards the midline of the body and the femur bones get closer together. This is an important part of absorbing forces, but excessive motion can put increased strain on the body.</div>
            </div>
          </div>
        </div>
        <div [attr.id]="'peakIr'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/hip-add.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Peak Hip Internal Rotation: {{ analysis.peakIr.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Peak Hip Internal Rotation: {{ analysis.peakIr.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-5" [maxValue]="15" [minValueAbbr]="'int'" [maxValueAbbr]="'ext'" [ingoreExt]="true" [redPoints]="[9, 15]" [greenPoints]="[0, 5]" [yellowPoints]="[-5, -1, 6, 8]" [valueLeft]="analysis.peakIr.left" [valueRight]="analysis.peakIr.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Internal Rotation</strong> is when the hip rotates inwards towards the midline of the body. This is an important part of absorbing forces, but excessive motion can put increased strain on the body.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'contralateralPelvicDrop'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/pelvic-drop.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Contralateral Pelvic Drop</h4>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h5>Left Contralateral Pelvic Drop: {{ analysis.contralateralPelvicDrop.left }}</h5>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-15" [maxValue]="15" [minValueAbbr]="'lft up'" [maxValueAbbr]="'rgt up'" [showAsLeft]="true" [redPoints]="[-12, -5, 9, 12]" [greenPoints]="[0, 3]" [yellowPoints]="[-3, -1, 5, 7]" [valueLeft]="analysis.contralateralPelvicDrop.left" [valueRight]="''"></status-bar>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <h5>Right Contralateral Pelvic Drop: {{ analysis.contralateralPelvicDrop.right }}</h5>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-15" [maxValue]="15" [minValueAbbr]="'lft up'" [maxValueAbbr]="'rgt up'"  [redPoints]="[-12, -9, 5, 12]" [greenPoints]="[-3, 0]" [yellowPoints]="[-7, -5, 1, 3]" [valueLeft]="''" [valueRight]="analysis.contralateralPelvicDrop.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Contralateral Pelvic Drop </strong> is often seen in association with excessive hip, knee and ankle collapse. It is often debated whether this excessive collapse is generated from the bottom down (i.e. the hip drop causes changes in the joints below) or from the bottom up (i.e a lower joint weakness causes changes in the joints above).</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'forwardLeanAngle'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/lean-angle.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Forward Lean Angle: {{ analysis.forwardLeanAngle.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Forward Lean Angle: {{ analysis.forwardLeanAngle.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="0" [maxValue]="12" [redPoints]="[0, 4]" [greenPoints]="[7, 12]" [yellowPoints]="[5, 6]" [valueLeft]="analysis.forwardLeanAngle.left" [valueRight]="analysis.forwardLeanAngle.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Forward Lean Angle </strong> measures how far forward or backwards you lean during running.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">3/Terminal Stance Phase</h5></div>
      <div class="card-body">
        <div [attr.id]="'hipExtension'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/hip-flexion.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Hip Extension: {{ analysis.hipExtension.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Hip Extension: {{ analysis.hipExtension.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-20" [maxValue]="5" [minValueAbbr]="'ext'" [maxValueAbbr]="'flex'" [redPoints]="[-5, 0]" [greenPoints]="[-8, -18]" [yellowPoints]="[-25, -19, -7, -6]" [valueLeft]="analysis.hipExtension.left" [valueRight]="analysis.hipExtension.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Hip Extension </strong> the hip extends at the end of stance phase as the body moves forward.</div>
            </div>
          </div>
        </div>

        <div [attr.id]="'ankleSupination'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/foot-pronation.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Ankle Supination: {{ analysis.ankleSupination.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Ankle Supination: {{ analysis.ankleSupination.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-10" [maxValue]="15" [minValueAbbr]="'sup'" [maxValueAbbr]="'pron'" [yellowPoints]="[-10, -9, -1, 0]" [redPoints]="[2, 15]" [greenPoints]="[-7, -2]" [valueLeft]="analysis.ankleSupination.left" [valueRight]="analysis.ankleSupination.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Ankle Supination</strong> when you body pushes off it should be in a supinated position to create a rigid lever at the foot.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5 class="h4 mb-0">4/Swing Phase</h5></div>
      <div class="card-body">
        <div [attr.id]="'hipFlexion'" class="row mb-5">
          <div class="col">
            <img class="img-fluid" src="../../../../assets/img/assessment/hip-flexion.png" />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col">
                <h4>Left Hip Flexion: {{ analysis.hipFlexion.left }}</h4>
              </div>
              <div class="col">
                <h4>Right Hip Flexion: {{ analysis.hipFlexion.right }}</h4>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col mb-2 mt-2">
                <status-bar [minValue]="-10" [maxValue]="50" [minValueAbbr]="'ext'" [maxValueAbbr]="'flex'" [redPoints]="[-10, 10]" [greenPoints]="[26, 40]" [yellowPoints]="[0, 25, 41, 50]" [valueLeft]="analysis.hipFlexion.left" [valueRight]="analysis.hipFlexion.right"></status-bar>
              </div>
            </div>
            <div class="row">
              <div class="col"><strong>Hip Flexion </strong> at mid swing has been linked to the rate at which the foot accelerates towards the ground at IC. If you are limited in hip flexion, it may change how you absorb forces on the ground. Normal range HF = 10-20º.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CalendarEventViewModel, CalendarWeekViewModel } from 'src/app/_models/generatedModels';
import { CalendarAddEventParameters, CalendarDraggedEventParameters, CalendarParameters } from 'src/app/_models/models';
import { ClientCalendarService } from 'src/app/_services/clientCalendar.service';
import { Subscription } from 'rxjs';
import { ClientsService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintCalendarDialogComponent } from '../print-calendar-dialog/print-calendar-dialog.component';

@Component({
  selector: 'athlete-calendar',
  templateUrl: 'athlete-calendar.component.html'
})
export class AthleteCalendarComponent implements OnInit, OnDestroy {
  data: CalendarWeekViewModel[] = [];
  subscription: Subscription;
  hasCurrentPrograms: boolean = false;

  @Input()
  clientUserId: number;

  @Input()
  isCoachView: boolean = false;

  @Input()
  hasCoachRole: boolean = false;

  constructor(private clientCalendarService: ClientCalendarService, private clientService: ClientsService, private auth: AuthenticationService, private toastr: ToasterService,
    private modalService: NgbModal) {}

  ngOnInit() {
    this.clientCalendarService.setInitialValues(this.clientUserId, this.isCoachView, this.hasCoachRole, true, (this.auth.user?.id == this.clientUserId));
    this.subscription = this.clientCalendarService.dataChanged.subscribe(result => {
      this.data = result;
    });
    
    this.clientService.getCurrentProgramsForClient(this.clientUserId).subscribe(result => {
      this.hasCurrentPrograms = result.length > 0;
      
    });
  }

  calendarParametersChanged(event: CalendarParameters) {
    this.clientCalendarService.calendarParametersChanged(event);
  }

  onEventInfoClicked(event: CalendarEventViewModel) {
    this.clientCalendarService.onEventInfoClicked(event);
  }

  onEventEditClicked(event: CalendarEventViewModel) {
    this.clientCalendarService.onEventEditClicked(event);
  }

  onEventDeleteClicked(event: CalendarEventViewModel) {
    this.clientCalendarService.onEventDeleteClicked(event);
  }

  onEventAddClicked(parameters: CalendarAddEventParameters) {
    this.clientCalendarService.onEventAddClicked(parameters);
  }

  onEventDragEnd(parameters: CalendarDraggedEventParameters) {
    this.clientCalendarService.onEventDragEnd(parameters);
  }

  onEventToggleCompleteClicked(event: CalendarEventViewModel) {
    this.clientCalendarService.onEventToggleCompleteClicked(event);
  }

  onEventCopyClicked(event: CalendarEventViewModel) {
    this.clientCalendarService.onEventCopyClicked(event);
  }

  onWeekCopyClicked(date: Date) {
    this.clientCalendarService.onWeekCopyClicked(date);
  }

  printPDF() {
    this.auth.fetchUserProfile().subscribe(user => {
      if (!user.activeSubscription) {
        this.toastr.okModal('The organization must have an active subscription to use this feature.', 'Subscription Required').subscribe();
      } else {
        const modalRef = this.modalService.open(PrintCalendarDialogComponent, { size: 'lg', windowClass: 'modal-max-xl-custom' });
        modalRef.componentInstance.clientUserId = this.clientUserId;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

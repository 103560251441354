import { Component, Input, AfterContentInit, forwardRef, ElementRef, Output, EventEmitter, AfterViewInit, ViewChild, OnInit  } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
    selector: 'custom-recent-datepicker',
    host: {
        '[class.rundnaDropdown]': 'someAttr',
    },
    template: `
    <kendo-datepicker class="{{cssClass}}" [(ngModel)]="selectedValue" placeholder="" (valueChange)="onChange($event)" [min]="min" [max]="max" [disabled]="disabled"></kendo-datepicker>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomRecentDatepickerControl),
            multi: true
        },
        {
          provide: NG_VALIDATORS,
          useExisting: forwardRef(() => CustomRecentDatepickerControl),
          multi: true,
        },
    ]
})

export class CustomRecentDatepickerControl implements ControlValueAccessor, OnInit {

    someAttr: string = 'anyValue';
    selectedValue: any;
    min: Date;
    max: Date;

    constructor(private elementRef: ElementRef) {
        this.cssClass = elementRef.nativeElement.getAttribute('class');
        elementRef.nativeElement.setAttribute('class', '');
    }

    @Input()
    placeholder: string = null;

    @Input()
    disabled: boolean = false;

    @Output()
    valueChange = new EventEmitter<number>();


    cssClass: string = '';

    propagateChange = (_: any) => { };
    propagateTouch = () => { };

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    onChange(value: any) {
        this.writeValue(value);
        this.propagateChange(this.selectedValue);
        this.propagateTouch();
        this.valueChange.emit(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnInit(): void {
      this.min = new Date().addDays(-90);
      this.max = new Date().addDays(270);
    }

    validate({ value }: FormControl) {
        if (value && value instanceof Date) {
            if (value < this.min || value > this.max) {
                return { invalidDate: true };
            }
        }
      }
}

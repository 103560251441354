<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Change Running Category</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <h5>Change Running Category for {{clientFullName}}</h5>
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>

          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div  class="col-md-12">
                  <div class="form-group">
                    <div>Current programs assigned to client. Remove any programs here if needed:</div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-7 mt--3">
                  <runner-program-details [clientUserId]="clientUserId" [summaryOnly]="true"></runner-program-details>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-1">
            <div class="card-body">
              <div class="row">
                <div  class="col-md-12">
                  <div class="form-group">
                    <div>Change the category and select a start date for the new program:</div>
                  </div>
                </div>
                <div  class="col-md-12 col-lg-6">
                  <div class="form-group">
                    <label class="form-control-label" for="impairmentCategory">
                      Running Category
                    </label>
                    <select class="form-control" formControlName="impairmentCategory" (ngModelChange)="onCategoryChange($event)">
                      <option [ngValue]="">N/A</option>
                      <option [ngValue]="'Bouncer'">Bouncer</option>
                      <option [ngValue]="'Collapser'">Collapser</option>
                      <option [ngValue]="'CollapserBouncer'">Collapser Bouncer</option>
                      <option [ngValue]="'CollapserCPD'">Collapser CPD</option>
                      <option [ngValue]="'CollapserHip'">Collapser Hip</option>
                      <option [ngValue]="'GeneralCadence'">General Cadence</option>
                      <option [ngValue]="'GluteAmnesiac'">Glute Amnesiac</option>
                      <option [ngValue]="'OverstriderCadence'">Overstrider Cadence</option>
                      <option [ngValue]="'OverstriderCollapsing'">Overstrider Collapsing</option>
                      <option [ngValue]="'OverstriderGeneral'">Overstrider General</option>
                      <option [ngValue]="'OverstriderGluteAmnesiac'">Overstrider Glute Amnesiac</option>
                      <option [ngValue]="'OverstriderKneeDrive'">Overstrider Knee Drive</option>
                      <option [ngValue]="'Weaver'">Weaver</option>
                      <option [ngValue]="'Uncategorized'">Uncategorized</option>
                    </select>
                    <div class="invalid-message">
                      <div>Running Category is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6">
                  <div class="form-group">
                    <label class="form-control-label" for="impairmentCategoryProgramName">
                      Program to be Assigned
                    </label>
                    <div>{{selectedProgramName}}</div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-5">
                  <div class="form-group">
                    <label class="form-control-label" for="impairmentCategoryProgramStartDate">
                      Running Program Start Date
                    </label>
                    <kendo-datepicker placeholder="" class="form-control" formControlName="impairmentCategoryProgramStartDate"></kendo-datepicker>
                    <div class="invalid-message">
                      <div>Running Program start date is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="rtrNotes">
                      Specific Gait Retraining Instructions
                    </label>
                    <textarea placeholder="Display an optional note to the athlete on Return to Run workouts in this program" class="form-control" formControlName="rtrNotes" type="text" rows="4"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
          </div>
          <div class="col text-right">
            <button form="form" type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save Category</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


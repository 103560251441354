import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';

@Component({
  selector: 'bxl-client-workout',
  templateUrl: 'client-workout.component.html'
})
export class ClientWorkoutComponent implements OnInit {
  initialized = false;
  clientProgramWorkoutDayId: number;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private router: Router, private breadcrumbs: BreadcrumbsService) { }

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.clientProgramWorkoutDayId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetBreadcrumb('Workout', this.router.url, []);
    this.initialized = true;
  }

  workoutSaved() {
    this.router.navigate(['/athletes/details/' + this.clientUserId + '/calendar']);
  }
}

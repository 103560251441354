import { Component, OnInit, NgZone, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { CreatePaymentMethodViewModel } from 'src/app/_models/generatedModels';

@Component({
  selector: 'update-card',
  templateUrl: 'update-card.component.html',
})
export class UpdateCard implements OnInit {
  constructor(public fb: FormBuilder, public toastr: ToasterService, public activeModal: NgbActiveModal, public zone: NgZone, public orgService: OrganizationService) {}
  initialized = false;
  saving = false;
  contactForm: any;
  errorMessage: '';

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      cardNumber: ['', [Validators.required]],
      expMo: ['', [Validators.required]],
      expYr: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    });

    this.initialized = true;
  }

  save(): void {
    if (!this.contactForm.valid) {
      this.contactForm.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }
    this.saving = true;
    let card = { number: this.contactForm.controls.cardNumber.value, exp_month: this.contactForm.controls.expMo.value, exp_year: this.contactForm.controls.expYr.value, cvc: this.contactForm.controls.cvv.value };
    (<any>window).Stripe.card.createToken(card, (status: number, response: any) => {
      if (status === 200) {
        let model = new CreatePaymentMethodViewModel();
        model.stripeToken = response.id;
        model.last4 = response.card.last4;
        model.cardType = response.card.brand;
        this.zone.run(() => {
          this.orgService.updatePaymentMethod(model).subscribe(
            (company) => {
              this.activeModal.close(company);
              this.saving = false;
            },
            (error) => {
              this.errorMessage = error.error[0];
              this.saving = false;
            }
          );
        });
      } else {
        this.errorMessage = response.error.message;
        this.saving = false;
        console.error(response.error.message);
      }
    });
  }

  get f() {
    return this.contactForm.controls;
  }
}

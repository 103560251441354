import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { ExerciseViewModelRead } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-exercise-add-modal',
  templateUrl: 'exercise-add-modal.component.html',
})
export class ExerciseAddModalComponent extends ComponentCanDeactivate implements OnInit {
  initialized = false;
  isExerciseCopy: boolean = false;
  isDirtyValue: boolean = false;

  @Input()
  exerciseIdToModify: number;


  @Output()
  savedObject = new EventEmitter<ExerciseViewModelRead>();

  constructor(public activeModal: NgbActiveModal, private toastr: ToasterService ) {
    super();
  }

  canDeactivate(): boolean {
    return !this.isDirtyValue;
  }

  ngOnInit(): void {
    // if exercise Id passed in, open exercise modal with exercise details populated and ready to be copied
    if (this.exerciseIdToModify) {
      this.isExerciseCopy = true;
    }
    this.initialized = true;
  }

  onExerciseCreated(exercise: ExerciseViewModelRead) {
    if (exercise) {
      this.savedObject.emit(exercise);
      this.activeModal.dismiss('saved');
    } else {
      this.onCancel();
    }
  }

  onIsDirtyChange(isDirty: boolean) {
    this.isDirtyValue = isDirty;
  }

  onCancel() {
    if (!this.isDirtyValue) {
      this.activeModal.dismiss('cancel');
    } else {
      this.toastr.confirmDialog('WARNING: You have unsaved changes. Are you sure you want to leave the page?', 'Are you sure?').subscribe(result => {
        if (result) {
          this.activeModal.dismiss('cancel');
        }
      });
    }

  }
}

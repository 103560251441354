import { Component, OnInit } from '@angular/core';
import { UserProfileViewModel, UserRole, StringViewModel, ClientSearchParams, UpdateUserRoleViewModel, ClientStatus } from 'src/app/_models/generatedModels';
import { UserService, OrganizationService } from 'src/app/_services/generatedServices';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Enums } from 'src/app/_models/generatedEnums';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminUserAddEditModalComponent } from 'src/app/_components/admin-user-add-edit-modal/admin-user-add-edit-modal.component';
import { MaxAthleteSubscriptionService } from 'src/app/_services/maxAthleteSubscription.service';

@Component({
  selector: 'bxl-admin-users',
  templateUrl: 'admin-users.component.html',
})
export class AdminUsersComponent implements OnInit {
  initialized = false;
  clients: UserProfileViewModel[];
  showDeactivateDialog: boolean = false;
  userRoleEnum = Enums.UserRoleEnum;
  userRole = UserRole;
  clientStatus = ClientStatus;
  public deactivateFormGroup: FormGroup;
  selectedUser: UserProfileViewModel;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  status = 1;

  constructor(private breadcrumbs: BreadcrumbsService, private usersService: UserService, private toastr: ToasterService, private organizationService: OrganizationService,
    private fb: FormBuilder, private modalService: NgbModal, private maxAthleteService: MaxAthleteSubscriptionService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Users', 'users', []);

    this.searchUsers();

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.searchUsers();
    });
  }

  searchUsers() {
    this.searching = true;
    let model = new ClientSearchParams();
    model.searchText = this.filter;
    model.status = <any>this.status;

    this.organizationService.searchOrgUsers(model).subscribe((results) => {
      this.clients = results;
      this.searching = false;
      this.initialized = true;
    });
  }

  editUserRole(user: UserProfileViewModel) {
    const modalRef = this.modalService.open(AdminUserAddEditModalComponent, { size: 'lg' });
    modalRef.componentInstance.selectedOrganizationId = null;
    modalRef.componentInstance.user = user;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.searchUsers();
        }
      }
    );
  }

  addUser() {
    const modalRef = this.modalService.open(AdminUserAddEditModalComponent, { size: 'lg' });
    modalRef.componentInstance.selectedOrganizationId = null;
    modalRef.componentInstance.user = null;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.searchUsers();
        }
      }
    );
  }

  onSendPasswordReset(userId: number, email: string, name: string) {
    this.toastr.confirmDialog('Are you sure you want to send a password reset email to ' + name + ' (' + email + ')?', 'Send Password Reset').subscribe((result) => {
      if (result) {
        const model = new StringViewModel();
        model.value = email;
        this.usersService.initiatePasswordReset(model).subscribe((results) => {
          this.toastr.success('Password Reset email sent', 'Success');
        });
      }
    });
  }

  onStatusChanged(query: string) {
    this.searchUsers();
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  deactivateUser(user: UserProfileViewModel) {
    if (user.userRole & UserRole.Administrator) {
      let totalActiveAdmins = this.clients.filter(x => x.status == ClientStatus.Active && (x.userRole & UserRole.Administrator)).length;
      if (totalActiveAdmins == 1) {
        this.toastr.error('Cannot deactivate the only active Admin');
        return;
      }
    }

    this.selectedUser = user;

    this.deactivateFormGroup = this.fb.group({
      coachUserId: [null],
    });
    this.showDeactivateDialog = true;
  }

  closeDeactivateDialog(status: string) {
    if (status === 'save') {
      if (!this.deactivateFormGroup.valid) {
        this.deactivateFormGroup.markAllControlsDirty();
        return;
      }

      this.usersService.deactivateUser(this.selectedUser.id, this.deactivateFormGroup.get('coachUserId').value).subscribe((results) => {
        this.toastr.success('Successfully Deactivated User', 'Success');
        this.searchUsers();
        this.showDeactivateDialog = false;
      });
    } else {
      this.showDeactivateDialog = false;
    }
  }

  activateUser(userId: number) {
    let user = this.clients.find(x => x.id == userId);

    if (user.userRole & UserRole.Runner) {
      this.maxAthleteService.isMaxActiveAthletes(false).subscribe(isMax => {
        if (!isMax) {
          this.doActivateUser(userId);
        }
      });
    } else {
      this.doActivateUser(userId);
    }
  }

  doActivateUser(userId: number) {
    this.usersService.activateUser(userId).subscribe((results) => {
      this.toastr.success('Successfully Activated User', 'Success');
      this.searchUsers();
    });
  }

  deleteUser(userId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this user? This action cannot be undone.', 'Delete User', 'Delete User', 'Cancel').subscribe(result => {
      if (result) {
        this.usersService.deleteInvitedUser(userId).subscribe((result) => {
          this.toastr.success('Successfully Deleted User', 'Success');
          this.searchUsers();
        });
      }
    });
  }

  onSendInvitation(user: UserProfileViewModel) {
    this.toastr.confirmDialog('Are you sure you want to re-send an invitation to ' + user.fullName + ' (' + user.email + ')?', 'Re-send Invitation').subscribe((result) => {
      if (result) {
        this.usersService.resendCoachWelcomeEmail(user.id).subscribe((results) => {
          this.toastr.success('Invitation email sent', 'Success');
        });
      }
    });
  }

  removeCoachRole(userId: number) {
    this.toastr.confirmDialog('Are you sure you want to remove Admin/Coach access for this user? They will only have athlete access.', 'Remove Admin/Coach Role').subscribe((result) => {
      if (result) {
        const model: UpdateUserRoleViewModel = {
          userRole: UserRole.Runner
        }
        this.usersService.updateUserRole(userId, model).subscribe(result => {
          this.toastr.success('User Updated', 'Success');
          this.searchUsers();
        });
      }
    });
  }
}

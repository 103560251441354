import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ClientsService} from 'src/app/_services/generatedServices';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientWorkoutViewModel } from 'src/app/_models/generatedModels';

@Component({
  selector: 'share-workout-dialog',
  templateUrl: 'share-workout-dialog.component.html',
})
export class ShareWorkoutDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  @Input()
    workoutId: number;

  @Input()
    workoutName: string;

  @Output()
  saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private clientService: ClientsService, private auth: AuthenticationService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientUserId: [null, Validators.required],
      workoutId: [this.workoutId],
      assignedByUserId: [this.auth.user.id],
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: ClientWorkoutViewModel = Object.assign({}, this.formGroup.getRawValue());
      this.clientService.addUpdateClientWorkout(formData).subscribe((result) => {
        this.toastr.success('Workout Shared', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { ClientSummaryDTO, CurrentMessageMode, SendMessageViewModel, UserAuthProfileViewModel, UserProfileViewModel } from 'src/app/_models/generatedModels';
import { BasicObject, coachUserRoleArray } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClientsService, MessagingService, OrganizationService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-send-message-modal',
  templateUrl: 'send-message-modal.component.html',
})
export class SendMessageModalComponent implements OnInit {
  initialized = false;
  users: BasicObject[];
  userComboData: BasicObject[];
  formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  @ViewChild('userCombo') public userCombo: ComboBoxComponent | undefined;

  @Input()
  to: number;
  @Input()
  toTeamId: number;
  @Input()
  subject: string;
  @Input()
  clientProgramWorkoutDayId: number = null;

  constructor(private messageService: MessagingService, public auth: AuthenticationService, private orgService: OrganizationService, public activeModal: NgbActiveModal, private clientService: ClientsService, private fb: FormBuilder, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.auth.fetchUserProfile().subscribe((user) => {
      this.clientService.getActiveUsersForUser(user.id).subscribe((users) => {
        this.setupUserList(users);
        this.setupForm(user.coachUserId != user.id ? user.coachUserId : null);
      });
    });
  }

  setupForm(coachUserId: number | null) {
    var subject = this.subject ? ('RE: ' + this.subject) : '';
    this.formGroup = this.fb.group({
      to: [this.to ? this.to : (this.toTeamId ? null : coachUserId)],
      toTeamId: [this.toTeamId ? this.toTeamId : null],
      subject: [subject, Validators.required],
      body: ['', Validators.required],
      clientProgramWorkoutDayId: [this.clientProgramWorkoutDayId]
    },
    { validator: this.validateTo });

    this.initialized = true;
  }

  setupUserList(users: ClientSummaryDTO[]) {
    this.users = users.map(x => new BasicObject(x.userId, x.userFullName)).sort(dynamicSort('name'));
    this.users.unshift(new BasicObject(-1, 'All Coaches'));
    if (this.auth.hasClaimForRole(coachUserRoleArray)) {
      this.users.unshift(new BasicObject(0, 'All Athletes'));
    }
    this.users.unshift(new BasicObject(null, ''));
    this.userComboData = this.users;
  }

  handleUserFilter(value: any) {
    if (value.length >= 1) {
        this.userComboData = this.users.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
        this.userComboData = this.users;
        this.userCombo.toggle(false);
    }
  }

  validateTo(control: AbstractControl) {
    if (control.get('to').value == null && control.get('toTeamId').value == null) {
      return { toRequired: true };
    } else if (control.get('to').value != null && control.get('toTeamId').value != null) {
      return { toDuplicate: true }
    }
    return null;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    const formData: SendMessageViewModel = Object.assign({}, this.formGroup.value);
    formData.to = (formData.to < 1 ? null : formData.to);
    if (formData.toTeamId) {
      formData.currentMessageMode = CurrentMessageMode.SpecificTeam;
    } else {
      formData.currentMessageMode = formData.to > 0 ? CurrentMessageMode.SpecificUser : (formData.to == 0 ? CurrentMessageMode.AllAthletes : CurrentMessageMode.AllCoaches);
    }
    
    this.messageService.sendMessage(formData).subscribe((result) => {
      this.toastr.success('Message Sent', 'Success');
      this.activeModal.dismiss('saved');
    });
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ExerciseViewModelRead, ExerciseViewModel, ExerciseTagViewModel, ExerciseEquipmentExerciseViewModel, VideoType } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { forkJoin, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BasicObject } from 'src/app/_models/models';
import { enumToArray } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'bxl-edit-exercise',
  templateUrl: 'edit-exercise.component.html',
})
export class EditExerciseComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  exerciseId: number;
  exercise: ExerciseViewModelRead;
  VideoType = VideoType;
  tagsList: string[];
  exerciseEquipmentList: BasicObject[];
  canEditGlobalLibrary: boolean = false;

  @Input()
  exerciseIdInput: number;

  @Input()
  isExerciseCopy: boolean = false;

  @Output()
  savedObject = new EventEmitter<ExerciseViewModelRead>();

  @Output()
  isDirtyChange = new EventEmitter<boolean>();

  constructor(private exerciseData: ExerciseService, private fb: FormBuilder, private toastr: ToasterService, private auth: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    this.exerciseId = this.exerciseIdInput;
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();

    this.exerciseEquipmentList = enumToArray(Enums.ExerciseEquipmentEnum);

    if (this.exerciseId) {
      forkJoin([this.exerciseData.getExerciseById(this.exerciseId), this.exerciseData.getExerciseTagsForOrganization()]).subscribe((results) => {
        this.exercise = results[0];
        this.tagsList = results[1].map(x => x.name);

        // make sure the exercise id is valid for this user
        if ((!this.exercise.organizationId && !this.canEditGlobalLibrary && !this.isExerciseCopy) || (this.exercise.organizationId && this.auth.user.organizationId != this.exercise.organizationId)) {
          this.savedObject.emit(null);
        }

        this.setupForm();

        if (this.isExerciseCopy) {
          this.exerciseId = null;
        }
      });
    } else {
      this.exerciseData.getExerciseTagsForOrganization().subscribe((results) => {
        this.exercise = new ExerciseViewModelRead();
        this.exercise.videoType = VideoType.Public;
        this.exercise.exerciseTags = [];
        this.tagsList = results.map(x => x.name);

        this.setupForm();
      });
    }
  }

  setupForm() {
    var name = this.exercise.name;
    if (this.isExerciseCopy) {
      name = name + ' - Copy';
    }

    this.formGroup = this.fb.group({
      name: [name, { validators: [Validators.required], asyncValidators: [this.validateNameNotTaken.bind(this)], updateOn: 'change' }],
      instructions: [this.exercise.instructions, Validators.required],
      videoLink: [this.exercise.videoLink],
      videoType: [this.exercise.videoType],
      videoId: [this.exercise.videoId],
      isHidden: [this.exercise.isHidden || false],
      exerciseEquipments: [this.exercise.exerciseEquipments ? this.exercise.exerciseEquipments.map((x) => x.exerciseEquipment) : []],
      exerciseTags: [this.exercise.exerciseTags ? this.exercise.exerciseTags.map((x) => x.name) : []],
    });

    this.formGroup.markFormDirtyOnValueChange().subscribe(result => {
      this.isDirtyChange.emit(result);
    });

    this.initialized = true;
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe((result) => {
        if (result) {
          this.savedObject.emit(null);
        }
      });
    } else {
      this.savedObject.emit(null);
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.isDirtyChange.emit(this.formGroup.dirty);
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: ExerciseViewModel = this.formGroup.value;
      formData.exerciseTags = (this.formGroup.get('exerciseTags').value as string[]).map(x => {return { name: x } as ExerciseTagViewModel});
      formData.exerciseEquipments = (this.formGroup.get('exerciseEquipments').value as number[]).map((x) => {
        return { exerciseEquipment: x, exerciseId: this.exerciseId || 0 } as ExerciseEquipmentExerciseViewModel;
      });

      if (this.exerciseId) {
        this.update(formData, resetButton);
      } else {
        // save the user who is creating the exercise
        formData.userId = this.auth.user.id;
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.exerciseData.addExercise(formData).subscribe((result) => {
      this.toastr.success('Exercise Added', 'Success');
      resetButton();
      this.savedObject.emit(result);
    });
  }

  update(formData: any, resetButton: () => any) {
    this.exerciseData.updateExercise(this.exerciseId, formData).subscribe((result) => {
      this.toastr.success('Exercise Updated', 'Success');
      resetButton();
      this.savedObject.emit(result);
    });
  }

  validateNameNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.exerciseData.isExerciseNameDuplicate(control.value, this.exerciseId || 0)),
      map((res)  => {
        return res ? { nameTaken: true } : null;
      })
    );
  }
}

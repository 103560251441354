import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService, ProgramsService } from 'src/app/_services/generatedServices';
import { AssessmentType, AssessmentNotesViewModelRead, AssessmentNotesViewModel } from 'src/app/_models/generatedModels';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'assessment-notes-dialog',
  templateUrl: 'assessment-notes-dialog.component.html'
})
export class AssessmentNotesDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  assessmentTypeEnum = Enums.AssessmentTypeEnum;
  submitComplete: Promise<{}> | undefined;
  model: AssessmentNotesViewModelRead;

  @Input()
    assessmentId: number;

  @Input()
    assessmentType: AssessmentType;

  @Output()
    saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private assessmentService: AssessmentService,
    private programsService: ProgramsService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.assessmentService.getAssessmentNotes(this.assessmentId, this.assessmentType).subscribe(result => {
      this.model = result;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      notes: [this.model.notes],
      id: [this.assessmentId],
      assessmentType: [this.assessmentType]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: AssessmentNotesViewModel = Object.assign({}, this.formGroup.getRawValue());
      this.assessmentService.updateAssessmentNotes(formData).subscribe(result => {
        this.toastr.success('Notes Updated', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'bxl-runner-acute-chronic-list',
  templateUrl: 'runner-acute-chronic-list.component.html',
})

export class RunnerAcuteChronicListComponent implements OnInit {
  initialized: boolean = false;
  userId: number;

  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.auth.fetchUserProfile().subscribe(user => {
      this.userId = user.id;
      this.initialized = true;
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card-body">
  <div class="row no-gutters">
    <div class="col-lg-12">
      <h3 class="mb-0 d-inline">Workout Progressions</h3>
      <div class="float-right">
        <a [routerLink]="['add']" class="btn btn-primary">Add Progression</a>
      </div>
    </div>
    <div class="col">Build a progression of workouts that an athlete can use to dynamically level up their workouts
      <div class="d-inline text-center" kendoTooltip>
        <i class="fas fa-info-circle ml-2" title="If an athlete is assigned a workout that is part of a progression, they will be able to dynamically change the workout to an easier or harder workout in the same progression."></i>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-9 col-md-7">
      <div class="form-group">
        <label class="form-control-label"> &nbsp; </label>
        <custom-input-persisted class="form-control" [placeholder]="'Search by progression name'" [key]="'progressions'" [(ngModel)]="filter" (valueChange)="onFilterChanged($event)"></custom-input-persisted>
      </div>
    </div>
    <div class="col-lg-3 col-md-5">
      <div class="form-group">
        <i class="far fa-filter mr-1"></i><label class="form-control-label"> Created By </label>
        <coaches-library-dropdown-persisted class="form-control" [key]="'progressions'" [(ngModel)]="coachFilter" (valueChange)="onCoachFilterChange($event)"></coaches-library-dropdown-persisted>
      </div>
    </div>
  </div>
  <page-load-header *ngIf="searching"></page-load-header>
  <div class="row mt-3">
    <div class="col">
      <div *ngIf="initialized && !searching" class="table-responsive">
        <table class="table align-items-center table-flush mobile-table">
          <thead class="thead-light">
            <tr class="header-row sortable">
              <th (click)="progressions.sort('Name')" [ngClass]="progressions.getCSSClass('Name')">Name</th>

              <th>Notes</th>

              <th (click)="progressions.sort('User.Credential.FullName')" [ngClass]="progressions.getCSSClass('User.Credential.FullName')">Created By</th>

              <th class="text-lg-center">Actions</th>
            </tr>
          </thead>
          <tbody class="list">
            <tr *ngFor="let progression of progressions">
              <td>
                <span class="mobile-grid-label">Name</span>
                <a href="javascript:void(0)" (click)="onPreview(progression.id)">{{ progression.name }}</a>
              </td>

              <td style="white-space: normal;">
                <span class="mobile-grid-label">Notes</span>
                {{ progression.notes }}
              </td>

              <td>
                <span class="mobile-grid-label">Created By</span>
                {{ progression.userFullName || 'RunDNA' }}
              </td>

              <td class="text-lg-center">
                <span class="mobile-grid-label">Actions</span>
                <div class="btn-group no-caret">
                  <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"> </i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-lg-right">
                    <a *ngIf="progression.organizationId || canEditGlobalLibrary" [routerLink]="[editUrl, progression.id]" class="dropdown-item"> Edit </a>
                    <a *ngIf="progression.organizationId || canEditGlobalLibrary" href="javascript:void(0)" (click)="onDelete(progression.id)" class="dropdown-item"> Delete </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer py-4">
        <nav aria-label="...">
          <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
        </nav>
      </div>
    </div>
  </div>
</div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">{{ programId ? 'Edit' : 'Add' }} Exercise Progression</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label class="form-control-label" for="name"> Exercise Progression Name </label>
                <input class="form-control" formControlName="name" maxlength="100" type="text" />
                <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.required">
                  <div>Name is required</div>
                </div>
                <div class="invalid-message" *ngIf="formGroup?.controls['name'].errors && formGroup?.controls['name'].errors.nameTaken">Exercise Progression with this name already exists</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label" for="notes"> Notes </label>
                <textarea class="form-control" formControlName="notes" maxlength="500" rows="3"></textarea>
              </div>
            </div>
            <div *ngIf="canEditGlobalLibrary" class="col-lg-6 col-md-12">
              <div class="form-group">
                <label class="form-control-label mr-1" for="isHidden">
                  Is Hidden?
                </label>
                <kendo-switch formControlName="isHidden"></kendo-switch>
              </div>
            </div>
          </div>

          <h5 class="mt-3">Exercises</h5>
          <div class="row">
            <div class="col-xl-6 col-lg-9 col-12">
              <div>
                <!-- <a class="" href="javascript:void(0)" (click)="onAddLevel(-1)">Add Level</a> -->
                <button type="button" (click)="onAddLevel(-1)" type="button" class="btn btn-primary btn-sm">Add Level</button>
              </div>
              <div formArrayName="levels" class="formGroupContainer" *ngFor="let level of formGroup.get('levels').controls; let w = index">
                <div class="row formGroupName level-row" [formGroupName]="w">
                  <div class="col-xl-3 col-lg-4 col-4 p-0">
                    <div class="form-group">
                      <div *ngIf="!isPreview" class="dropdown no-caret" dropdown>
                        <a aria-controls="dropdown-basic" class="btn btn-sm btn-icon-only dropdown-toggle" dropdownToggle href="javascript:void(0)" role="button">
                          <i class="fas fa-ellipsis-v"> </i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow" *dropdownMenu>
                          <a href="javascript:void(0)" (click)="onDeleteLevel(w)" class="dropdown-item"> Delete Level </a>
                          <a *ngIf="level.get('level').value < formGroup.get('levels').controls.length" href="javascript:void(0)" (click)="onMoveLevel(w, 1)" class="dropdown-item"> Move Level Down </a>
                          <a *ngIf="level.get('level').value > 1" href="javascript:void(0)" (click)="onMoveLevel(w, -1)" class="dropdown-item"> Move Level Up </a>
                        </div>
                      </div>
                      <label class="form-control-label" for="level"> &nbsp; </label>
                      <div class="form-control-text-no-label">Level {{ level.get('level').value }}</div>
                    </div>
                  </div>
                  <div class="col-xl-9 col-lg-8 col-8 p-0">
                    <div class="form-group">
                      <a (click)="onCreateExercise(null, w)" href="javascript:void(0)" class="link">Create Exercise</a><span class="link"> or </span><label class="form-control-label" for="exerciseId">Select Exercise</label>
                      <exercises-dropdown class="form-control" formControlName="exerciseId" (changedObject)="onExerciseChange($event, w)" [newlyCreatedAndSelectedExerciseId]="level.get('newlyCreatedAndSelectedExerciseId').value"></exercises-dropdown>
                      <a *ngIf="level.get('exerciseId').value" class="link ml-2" href="javascript:void(0)" (click)="onExercisePreview($event, w)">Preview</a>
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  <!-- <a class="" href="javascript:void(0)" (click)="onAddLevel(w)">Add Level</a> -->
                  <div class="btn-group split-button mr-3">
                    <button type="button" (click)="onAddLevel(w)" type="button" class="btn btn-primary btn-sm">Add Level</button>
                    <div class="btn-group" ngbDropdown role="group" aria-label="Exercise Item dropdown">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <button type="button" (click)="onAddLevel(w)"  ngbDropdownItem>Add Level</button>
                        <button type="button" (click)="onAddLevelClone(w)" ngbDropdownItem>Clone From Previous Level</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="invalid-message-visible" *ngIf="formGroup?.controls['levels'].dirty && formGroup?.controls['levels'].errors && (formGroup?.controls['levels'].errors.required || formGroup?.controls['levels'].errors.minlength)">Must add at least 2 levels</div>
            </div>
          </div>

          <div class="row form-btns">
            <div class="col">
              <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
            </div>
            <div class="col text-right">
              <button type="submit" [disabled]="validating" [promiseBtn]="submitComplete" class="btn btn-primary">Save Progression</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-view-gait-analysis',
  templateUrl: 'view-gait-analysis.component.html',
})
export class ViewGaitAnalysisComponent implements OnInit {
  initialized = false;
  analysisId: any;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.analysisId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.initialized = true;
  }
}

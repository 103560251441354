<div *ngIf="initialized">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">{{existingWorkoutRecord ? 'Edit' : 'Add'}} event for Week {{ currentWeekIndex + 1 }}, Day {{ currentWeekDayIndex + 1 }}</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <form [formGroup]="workoutForm" novalidate>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label class="form-control-label" for="eventType">
            Event Type
          </label>
          <select class="form-control" formControlName="eventType" (ngModelChange)="onEventTypeChange($event)">
            <ng-container *ngFor="let mapEntry of eventTypeEnum | keyvalue">
              <option *ngIf="mapEntry.key != eventType.GarminSyncedWorkout && mapEntry.key != eventType.StravaSyncedWorkout && mapEntry.key != eventType.ManualWorkout" [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
            </ng-container>
          </select>
        </div>
        <div *ngIf="workoutForm.get('eventType').value == eventType.Event" class="col-md-12">
          <div class="form-group">
            <label class="form-control-label" for="eventName">
              Event Name
            </label>
            <input class="form-control" formControlName="eventName" maxlength="100" type="text" />
            <div class="invalid-message">
              <div>Event Name is required</div>
            </div>
          </div>
        </div>
        <div *ngIf="workoutForm.get('eventType').value == eventType.Task" class="col-md-12">
          <div class="form-group">
            <label class="form-control-label" for="taskDescription">
              Task Description
            </label>
            <input class="form-control" formControlName="taskDescription" maxlength="150" type="text" />
            <div class="invalid-message">
              <div>Task Description is required</div>
            </div>
          </div>
        </div>
        <div *ngIf="workoutForm.get('eventType').value == eventType.Workout" class="form-group col-md-12">
          <a (click)="onCreateWorkout()" href="javascript:void(0)">Create Workout</a><span> or </span><label class="form-control-label"></label>
          <label>Select Workout</label>
          <workouts-dropdown (changedObject)="onWorkoutChange($event)" formControlName="workoutId"></workouts-dropdown>
        </div>
        <div class="form-group col-md-6">
          <label>Event Order</label>
          <kendo-numerictextbox noScroll class="form-control" formControlName="sortOrder" [decimals]="'0'" [format]="'n0'" min="1" max="10" type="number"></kendo-numerictextbox>
        </div>
        <ng-container *ngIf="workoutForm.get('eventType').value == eventType.QuickWorkout">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label" for="quickWorkoutName">
                Workout Name
              </label>
              <ng-container *ngIf="!isLimitedEditing"><span class="link"> or </span><a (click)="onChangeToWorkoutType()" href="javascript:void(0)" class="link">Create/Select Workout From Library</a></ng-container>
              <input class="form-control" formControlName="quickWorkoutName" maxlength="100" type="text" />
              <div class="invalid-message">
                <div>Workout Name is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label" for="quickWorkoutDescription">
                Workout Description
              </label>
              <custom-editor formControlName="quickWorkoutDescription"></custom-editor>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label class="form-control-label" for="quickWorkoutActivityType"> Workout Type </label>
              <select class="form-control" formControlName="quickWorkoutActivityType">
                <option [ngValue]="null">(select one)</option>
                <ng-container *ngFor="let mapEntry of activityTypeEnum | keyvalue">
                  <option [ngValue]="mapEntry.key">{{ mapEntry.value }}</option>
                </ng-container>
              </select>
              <div *ngIf="workoutForm.controls['quickWorkoutActivityType'].dirty && workoutForm.controls['quickWorkoutActivityType'].errors?.required" class="invalid-message-visible">
                <div>Workout Type is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label class="form-control-label" for="quickWorkoutDuration"> Duration </label>
              <minutes-seconds-textbox formControlName="quickWorkoutDuration"></minutes-seconds-textbox>
              <div class="invalid-message">
                <div>format must be h:mm:ss or m:ss</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label class="form-control-label" for="quickWorkoutDistance"> Distance (miles)</label>
              <kendo-numerictextbox noScroll class="form-control medium-numeric d-block" formControlName="quickWorkoutDistance" [decimals]="'2'" [format]="'n2'" min="0" max="9999.99" type="number"></kendo-numerictextbox>
            </div>
          </div>
          <div class="col-md-6 d-lg-none">
          </div>
        </ng-container>
        <div *ngIf="workoutForm.get('eventType').value == eventType.Workout || workoutForm.get('eventType').value == eventType.RestDay" class="col-md-12">
          <div class="form-group">
            <label class="form-control-label" for="coachNotes">
              Event Notes
            </label>
            <textarea placeholder="Display a note to the athlete for this event" class="form-control" formControlName="coachNotes" type="text" rows="4"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>
        </div>
        <div class="col text-right">
          <button type="button" [promiseBtn]="submitComplete" (click)="onSave()" class="btn btn-primary">{{existingWorkoutRecord ? 'Update' : 'Add'}} Event</button>
        </div>
      </div>
    </div>
  </form>
</div>

import { Component, OnInit } from '@angular/core';
import { dateAsUTCNoTime, dynamicSort, getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TrainingLoadPlannerService } from 'src/app/_services/generatedServices';
import { TrainingLoadPeriodType, TrainingLoadPeriodViewModelRead, TrainingLoadPlannerViewModel, TrainingLoadPlannerViewModelRead } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'add-edit-planner',
  templateUrl: 'add-edit-planner.component.html',
  styleUrls: ['add-edit-planner.component.scss']
})
export class AddEditPlannerComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  plannerId: number;
  clientUserId: number;
  plan: TrainingLoadPlannerViewModelRead;
  periods: TrainingLoadPeriodViewModelRead[];
  trainingLoadPeriodEnum = Enums.TrainingLoadPeriodTypeEnum;
  showPeriods: boolean = false;
  trainingLoadPeriodType = TrainingLoadPeriodType;

  public disabledDates = (date: Date): boolean => {
    return date.getDay() != 1;
  };

  constructor(private route: ActivatedRoute, private toastr: ToasterService , private auth: AuthenticationService, private fb: FormBuilder,
    private plannerService: TrainingLoadPlannerService, private router: Router) {}

  ngOnInit() {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.plannerId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    if (this.plannerId) {
      forkJoin([this.plannerService.getTrainingLoadPeriods(), this.plannerService.getTrainingLoadPlanById(this.clientUserId, this.plannerId)]).subscribe(results => {
        this.periods = results[0];
        this.plan = results [1];
        this.setupForm();
      });

    } else {
      this.plannerService.getTrainingLoadPeriods().subscribe(result => {
        this.periods = result;
        this.plan = new TrainingLoadPlannerViewModelRead();
        this.setupForm();
      });
    }

  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      name: [this.plan.name, Validators.required],
      startDate: [this.plan.startDate ? new Date(this.plan.startDate) : null, Validators.required],
      startTrainingLoad: [this.plan.startTrainingLoad, Validators.required],
      startNotes: [this.plan.startNotes],
      trainingLoadPlannerWeeks: this.fb.array([], [Validators.required, Validators.minLength(1)])
    });


    if (this.plannerId && this.plan.trainingLoadPlannerWeeks.length > 0) {
      let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;

      this.plan.trainingLoadPlannerWeeks.sort(dynamicSort('sortOrder')).forEach(week => {
        weeks.push(this.fb.group({
          id: [week.id],
          sortOrder: [week.sortOrder],
          startDate: [week.startDate],
          trainingLoadPeriodType: [week.trainingLoadPeriodType, Validators.required],
          suggestedTrainingLoad: [week.suggestedTrainingLoad],
          trainingLoadLow: [week.trainingLoadLow],
          trainingLoadHigh: [week.trainingLoadHigh],
          rpE1To4Load: [week.rpE1To4Load],
          rpE5To6Load: [week.rpE5To6Load],
          rpE7To8Load: [week.rpE7To8Load],
          rpE9To10Load: [week.rpE9To10Load],
          notes: [week.notes]
        }));
      });
    }

    this.initialized = true;
  }

  onAddWeek() {
    if (this.formGroup.get('startDate').errors || this.formGroup.get('startTrainingLoad').errors) {
      this.toastr.okDialog('Start Date and Starting Training Load are required before adding a week.', 'Required Fields').subscribe(result => {
      });
      return;
    }

    let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;
    const startDate = new Date(this.formGroup.get('startDate').value).addDays(7 * (weeks.length));
    weeks.push(this.fb.group({
      id: [0],
      sortOrder: [0],
      startDate: [startDate],
      trainingLoadPeriodType: [null, Validators.required],
      suggestedTrainingLoad: [null],
      trainingLoadLow: [null],
      trainingLoadHigh: [null],
      rpE1To4Load: [null],
      rpE5To6Load: [null],
      rpE7To8Load: [null],
      rpE9To10Load: [null],
      notes: [null]
    }));
  }

  updateWeekValues(weekIndex: number) {
    const startingTrainingLoad = this.formGroup.get('startTrainingLoad').value;
    let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;
    let currentWeek = weeks.at(weekIndex);
    const period = this.periods.find(x => x.trainingLoadPeriodType == currentWeek.get('trainingLoadPeriodType').value);

    // can't update the week's values yet if a period hasn't been selected
    if (!period) {
      return;
    }

    // if the previous week is a Skip week, keep going back until you find a non-skip week
    let previousWeekSuggestedLoad;
    let previousWeek = weeks.at(weekIndex - 1);
    if (previousWeek && previousWeek.get('trainingLoadPeriodType').value == this.trainingLoadPeriodType.Skip) {
      let index = weekIndex - 2;
      while (weeks.at(index) && !previousWeekSuggestedLoad) {
        if (weeks.at(index).get('trainingLoadPeriodType').value != this.trainingLoadPeriodType.Skip) {
          previousWeekSuggestedLoad = weeks.at(index).get('suggestedTrainingLoad').value;
        }
        index--;
      }
      previousWeekSuggestedLoad = previousWeekSuggestedLoad || startingTrainingLoad;
    } else {
      previousWeekSuggestedLoad = (previousWeek ? previousWeek.get('suggestedTrainingLoad').value : startingTrainingLoad);
    }

    // calculate all the values
    const suggestedTrainingLoad = Math.round(period.trainingLoadMultiplier * previousWeekSuggestedLoad);
    currentWeek.get('suggestedTrainingLoad').setValue(suggestedTrainingLoad);
    currentWeek.get('trainingLoadLow').setValue(Math.round(0.8 * previousWeekSuggestedLoad));
    currentWeek.get('trainingLoadHigh').setValue(Math.round(1.3 * previousWeekSuggestedLoad));
    currentWeek.get('rpE1To4Load').setValue(Math.round(period.rpE1To4Multiplier * suggestedTrainingLoad));
    currentWeek.get('rpE5To6Load').setValue(Math.round(period.rpE5To6Multiplier * suggestedTrainingLoad));
    currentWeek.get('rpE7To8Load').setValue(Math.round(period.rpE7To8Multiplier * suggestedTrainingLoad));
    currentWeek.get('rpE9To10Load').setValue(Math.round(period.rpE9To10Multiplier * suggestedTrainingLoad));

    if (period.trainingLoadPeriodType == this.trainingLoadPeriodType.Skip) {
      currentWeek.get('trainingLoadLow').setValue(0);
      currentWeek.get('trainingLoadHigh').setValue(0);
    }
  }

  onPeriodTypeChange(event: any, weekIndex: number) {
    // calculate current week and any future weeks
    let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;
    for (var i = weekIndex; i < weeks.length; i++) {
      this.updateWeekValues(i);
    }
  }

  onStartLoadChange(value: any) {
    let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;
    for (var i = 0; i < weeks.length; i++) {
      this.updateWeekValues(i);
    }
  }

  updateWeekStartDates() {
    const startDate = new Date(this.formGroup.get('startDate').value);
    let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;

    for (var i = 0; i < weeks.length; i++) {
      weeks.at(i).get('startDate').setValue(startDate.addDays(7 * (i)));
    }
  }

  onStartDateChange(value: any) {
    this.updateWeekStartDates();
  }

  onDeleteWeek(index: number) {
    this.toastr.confirmDialog('Are you sure you want to remove this week from the plan?', 'Delete Week', 'Delete Week', 'Cancel').subscribe(result => {
      if (result) {
        let weeks = this.formGroup.get('trainingLoadPlannerWeeks') as FormArray;
        weeks.removeAt(index);
        this.updateWeekStartDates();
      }
    });
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      let formData: TrainingLoadPlannerViewModel = this.formGroup.value;
      formData.startDate = dateAsUTCNoTime(formData.startDate);
      formData = this.updateSortOrderAndFormatDate(formData);

      if (this.plannerId) {
        this.update(formData, resetButton);
      } else {
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.plannerService.createTrainingLoadPlan(this.clientUserId, formData).subscribe((result) => {
      this.toastr.success('Plan Added', 'Success');
      resetButton();
      this.navigateBack();
    });
  }

  update(formData: any, resetButton: () => any) {
    this.plannerService.updateTrainingLoadPlan(this.plannerId, formData).subscribe((result) => {
      this.toastr.success('Plan Updated', 'Success');
      resetButton();
      this.navigateBack();
    });
  }

  updateSortOrderAndFormatDate(plan: TrainingLoadPlannerViewModel): TrainingLoadPlannerViewModel {
    let sort = 1;
    plan.trainingLoadPlannerWeeks.forEach(week => {
      week.sortOrder = sort;
      week.startDate = dateAsUTCNoTime(week.startDate);
      sort += 1;
    });

    return plan;
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe(result => {
        if (result) {
          this.navigateBack();
        }
      });
    } else {
      this.navigateBack();
    }
  }

  navigateBack() {
    if (this.plannerId) {
      this.router.navigate(['../../'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'privacy-policy-dialog',
  templateUrl: 'privacy-policy-dialog.component.html',
  styleUrls: ['privacy-policy-dialog.component.scss'],
})
export class PrivacyPolicyDialogComponent implements OnInit {
  initialized: boolean;


  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.initialized = true;
  }
}

<div>
  <div class="row no-gutters mb-1">
    <div class="col-md-8">
      <h3 class="mb-0 d-inline">Completion Percentage Report</h3>
    </div>
    <div class="col-md-4">
      <coach-athlete-group-dropdown class="form-control" (changed)="onCoachFilterChanged($event)"></coach-athlete-group-dropdown>
    </div>
    <div class="col">
      <div class="float-right">
        <button (click)="onExport()" [promiseBtn]="exportComplete" class="btn btn-primary">Export to Excel</button>
      </div>
    </div>
  </div>

  <page-load-header *ngIf="!initialized"></page-load-header>

  <ul ngbNav #tabNav="ngbNav" [destroyOnHide]="false" class="nav-pills">
    <li [ngbNavItem]="0">
      <a ngbNavLink>Summary Chart</a>
      <ng-template ngbNavContent>
        <div *ngIf="initialized && data.tableValues.length == 0" class="mt-3">
          There is no data for the selected filters
        </div>
        <div [hidden]="!initialized || (initialized && data.tableValues.length == 0)">
          <div class="mt-3 chart-header">
            Count of athletes in each Completion Percentage range
          </div>
          <div class="chart-container" id="completionChart"></div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="1">
      <a ngbNavLink>Detail Table</a>
      <ng-template ngbNavContent>
        <div *ngIf="initialized" class="row mt-3">
          <div class="col-12 mb-2">The workout completion percentage per time period; and whether it was an increase or decrease from previous time period</div>
          <div class="col-12">
            <div class="table-responsive">
              <table class="table align-items-center table-flush mobile-xs-table">
                <thead class="thead-light">
                  <tr class="header-row sortable">
                    <th class="sticky-top" (click)="tableData.sort('userFullName')" [ngClass]="tableData.getCSSClass('userFullName')">Athlete Name</th>
                    <ng-container *ngFor="let header of data.tableHeaders; let x=index">
                      <th class="sticky-top" (click)="tableData.sortByIndex('tableCellValues', x, 'value')" [ngClass]="tableData.getCSSClassIndex(x)">{{header}}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let row of tableData">
                    <td><span class="mobile-grid-label">Athlete Name</span>{{row.userFullName}}</td>

                    <ng-container *ngFor="let cell of row.tableCellValues; let i=index">
                      <td><span class="mobile-grid-label">{{data.tableHeaders[i]}}</span>{{cell.value ? (cell.value | number : '1.2-2') + '%' : 'N/A'}}
                        <i class="fa ml-1" [ngClass]="{'fa-arrow-down text-danger': cell.isUpTrend == false, 'fa-arrow-up text-success': cell.isUpTrend}"></i>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="data.tableValues.length == 0">
              There is no data for the selected filters
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="tabNav"></div>

</div>

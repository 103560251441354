<div class="wrapper">
  <div class="d-block d-flex justify-content-between">
    <div><strong>{{ minValue }}</strong><span *ngIf="minValueAbbr"> ({{minValueAbbr}})</span></div>
    <div><span *ngIf="minValueAbbr">({{maxValueAbbr}}) </span><strong>{{ maxValue }}</strong></div>
  </div>
  <div *ngIf="valueLeft && (valueRight || showAsLeft)" style="position: absolute; color: black; font-size: 18px; font-weight: bold;" [ngStyle]="{ left: percentageLeft + '%' }">L</div>
  <div *ngIf="valueLeft && !valueRight && !showAsLeft" style="position: absolute; color: black" [ngStyle]="{ left: percentageLeft + '%' }"><i class="fas fa-diamond"></i></div>

  <div *ngIf="valueRight" style="position: absolute; color: black; font-size: 18px; font-weight: bold;" [ngStyle]="{ left: percentageRight + '%' }">R</div>

   <div class="d-block" [ngStyle]="styles">&nbsp;</div>
</div>

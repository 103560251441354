import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime, getNumberOfDaysBetween } from 'src/app/_helpers/extensions.module';
import { CalendarWeekViewModel, ClientDayEventType, DateRangeViewModel, QuickWorkoutDisplayViewModelRead, UserCoachViewModel, WorkoutDisplayExtendedViewModelRead } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { forkJoin } from 'rxjs';
import moment from 'moment-timezone';

@Component({
  selector: 'print-calendar-dialog',
  templateUrl: 'print-calendar-dialog.component.html',
  styleUrls: ['print-calendar-dialog.component.scss'],
})
export class PrintCalendarDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  eventType = ClientDayEventType;
  submitComplete: Promise<{}> | undefined;
  errorMessage: string = null;
  weeks: CalendarWeekViewModel[];
  workouts: WorkoutDisplayExtendedViewModelRead[];
  quickWorkouts: QuickWorkoutDisplayViewModelRead[];
  coachInfo: UserCoachViewModel;
  ready: boolean;
  monthRange: string;

  @Input()
    clientUserId: number;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private clientService: ClientsService) { }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required]
    });

    this.initialized = true;
  }

  onSave() {
    this.errorMessage = null;

    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    const dateRange = new DateRangeViewModel();
    dateRange.startDate = this.formGroup.get('startDate').value;
    dateRange.endDate = this.formGroup.get('endDate').value;
    this.monthRange = dateRange.startDate.toLocaleDateString('default', { month: 'long' }) + ' ' + dateRange.startDate.getFullYear() + ' - ' + dateRange.endDate.toLocaleDateString('default', { month: 'long' }) + ' ' + dateRange.endDate.getFullYear();

    let daysBetween = getNumberOfDaysBetween(dateRange.startDate, dateRange.endDate);
    if (daysBetween > 93) {
      this.errorMessage = "The date range cannot be more than 3 months.";
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      forkJoin([this.clientService.getClientCalendarWeeksByDateRange(this.clientUserId, dateAsUTCNoTime(dateRange.startDate), dateAsUTCNoTime(dateRange.endDate), dateAsUTCNoTime(new Date())),
        this.clientService.getClientWorkoutsWithDisplayDetails(this.clientUserId, dateAsUTCNoTime(dateRange.startDate), dateAsUTCNoTime(dateRange.endDate)),
        this.clientService.getClientQuickWorkoutsWithDisplayDetails(this.clientUserId, dateAsUTCNoTime(dateRange.startDate), dateAsUTCNoTime(dateRange.endDate)),
        this.clientService.getClientCoachInfoById(this.clientUserId)]).subscribe(result => {
        
        // first populate the divs with data
        this.weeks = result[0];
        this.workouts = result[1];
        this.quickWorkouts = result[2];
        this.coachInfo = result[3];
        this.ready = true;
        let dateRangeString = moment(dateRange.startDate).format("YYYY-MM-DD") + ' _ ' + moment(dateRange.endDate).format("YYYY-MM-DD");

        setTimeout(() => {
          this.createPDF('calendarPrintDiv', 'RunDNA Calendar ' + dateRangeString, true);
          if (this.workouts.length > 0 || this.quickWorkouts.length > 0) {
            this.createWorkoutPDF('RunDNA Workouts ' + dateRangeString);
          }
          
        }, 250);
        
      });
      
    });
  }

  createPDF(divId: string, fileName: string, landscape: boolean) {
    let DATA: HTMLElement = document.getElementById(divId);

    var HTML_Height = this.pxTomm(DATA.clientHeight);
    var x_margin = 5;
    var y_margin = 3;
    var PDF_Width = 215.9; //mm
    var PDF_Height = 279.4; //mm
    if (landscape) {
      PDF_Width = 279.4; //mm
      PDF_Height = 215.9; //mm
    }
    
    var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
    
    var pdf = new jsPDF(landscape ? 'l' : 'p', 'mm',  [PDF_Width, PDF_Height]);

    // for some reason have to set these now because inside the html2canvas loop they are undefined
    var totalWorkouts = this.workouts.length;
    var activeModal = this.activeModal;

    html2canvas(DATA,{allowTaint:true, useCORS:true}).then(function(canvas) {
      canvas.getContext('2d');
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      
      pdf.addImage(imgData, 'JPG', x_margin, y_margin, PDF_Width-(x_margin*2), HTML_Height);
      
      for (var i = 1; i <= totalPDFPages; i++) { 
        pdf.addPage([PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', x_margin, -(PDF_Height*i)+(y_margin), PDF_Width-(x_margin*2), HTML_Height);
      }

      pdf.save(fileName + '.pdf');

      if (totalWorkouts == 0) {
        activeModal.dismiss('saved');
      }
    });
  }

  async createWorkoutPDF(fileName: string) {
    var top_left_margin = 5;
    var PDF_Width = 215.9; //mm
    var PDF_Height = 279.4; //mm
   
    var pdf = new jsPDF('p', 'mm',  [PDF_Width, PDF_Height]);

    // for some reason have to set these now because inside the html2canvas loop they are undefined
    var totalWorkouts = this.workouts.length + this.quickWorkouts.length;
    var workoutsLength = this.workouts.length;
    var activeModal = this.activeModal;

    for (var x = 1; x <= totalWorkouts; x++) {

      let elementId = '';
      if (x <= workoutsLength) {
        elementId = 'workoutPrintDiv' + x;
      } else {
        elementId = 'quickWorkoutPrintDiv' + (x - workoutsLength).toString();
      }

      let DATA: HTMLElement = document.getElementById(elementId);
      var HTML_Height = this.pxTomm(DATA.clientHeight);
      var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;


      await html2canvas(DATA,{allowTaint:true, useCORS:true}).then(function(canvas) {
        canvas.getContext('2d');
        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, PDF_Width-(top_left_margin*2), HTML_Height);
        
        for (var i = 1; i <= totalPDFPages; i++) { 
          pdf.addPage([PDF_Width, PDF_Height]);
          pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin), PDF_Width-(top_left_margin*2), HTML_Height);
        }
  
        if (x == totalWorkouts) {
          pdf.save(fileName + '.pdf');
          activeModal.dismiss('saved');
          
        } else {
          pdf.addPage([PDF_Width, PDF_Height]);
        }
      });
    }
  }

  pxTomm(px) {   
    // this is to determine the size of pixels in mm for current screen
    // getting height this way since div is hidden
    let height = parseInt(window.getComputedStyle(document.getElementById('my_mm')).getPropertyValue("height"));
    return Math.floor(px/(height/100)); 
  }
}

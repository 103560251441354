import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { OrganizationViewModelRead, OrganizationViewModel, OrganizationType } from 'src/app/_models/generatedModels';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';

@Component({
  selector: 'bxl-admin-settings',
  templateUrl: 'admin-settings.component.html',
})
export class AdminSettingsComponent implements OnInit {
  initialized = false;
  org: OrganizationViewModelRead;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  /* croppedImage: any = '';
  initialCroppedImage: string;
  showImageUpload: boolean = false; */

  constructor(private orgService: OrganizationService, private breadcrumbs: BreadcrumbsService, private fb: FormBuilder, private toastr: ToasterService,
    private auth: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Settings', 'settings', []);
    this.auth.fetchUserProfile().subscribe(profile => {
      this.orgService.getOrganization(profile.organizationId).subscribe(result => {
        this.org = result;
        this.setupForm();
        /* if (result.avatar) {
          this.orgService.getLogo().subscribe((image) => {
            this.croppedImage = image.value;
            this.initialCroppedImage = image.value;
            this.setupForm();
          });
        } else {
          this.setupForm();
        } */
      });
    })

  }

  setupForm() {
    this.formGroup = this.fb.group({
      name: [this.org.name, { validators: [Validators.required] }],
      organizationURL: [this.org.organizationURL, ValidatorHelper.validateURLSimple()],
      street1: [this.org.street1],
      street2: [this.org.street2],
      city: [this.org.city],
      stateCode: [this.org.stateCode],
      postalCode: [this.org.postalCode],
      avatar: [this.org.avatar],
      requestDailyStatus: [this.org.requestDailyStatus],
    });

    this.initialized = true;
  }

  /* editImage(content) {
    this.showImageUpload = true;
  }

  onImageChanged(image: any) {
    this.croppedImage = image;
  }

  deleteImage() {
    this.croppedImage = null;
  } */

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton: any, reject) => {
      const formData: OrganizationViewModel = Object.assign({}, this.org, this.formGroup.value);

      /* if (this.initialCroppedImage !== this.croppedImage) {
        formData.base64Image = this.croppedImage;
      } else {
        formData.base64Image = null;
      }
      if (this.croppedImage === null) {
        formData.avatar = null;
      } */

      this.orgService.updateOrganization(formData).subscribe((result) => {
        this.toastr.success('Settings Updated', 'Success');
        resetButton();

        //reload page in case org logo changed
        window.location.reload();
      });
    });
  }
}

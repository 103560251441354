import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgramWorkoutDayCopy } from 'src/app/_models/models';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'copy-program-event-dialog',
  templateUrl: 'copy-program-event-dialog.component.html',
  styleUrls: ['copy-program-event-dialog.component.scss'],
})
export class CopyProgramEventDialogComponent implements OnInit {
  initialized: boolean;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  @Input()
  workoutName: string;

  @Output()
  savedObject = new EventEmitter<ProgramWorkoutDayCopy>();


  constructor(public activeModal: NgbActiveModal, private toastr: ToasterService, private fb: FormBuilder, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      week: [null, Validators.required],
      day: [null, Validators.required]
    });
    this.initialized = true;
  }

  onCopy(isCopyOnly: boolean) {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const data = new ProgramWorkoutDayCopy(this.formGroup.get('week').value, this.formGroup.get('day').value, isCopyOnly);
      this.savedObject.emit(data);
      this.activeModal.dismiss('saved');
      resetButton();
    });
  }

}

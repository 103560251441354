import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyStatusModalComponent } from 'src/app/_components/runner-daily-status/daily-status-modal.component';
import { SurveySummaryViewModelRead } from 'src/app/_models/generatedModels';
import { coachUserRoleArray } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ClientsService } from 'src/app/_services/generatedServices';
import { DailyStatusesService } from 'src/app/_services/dailyStatuses.service';
import { SurveyHandlerService } from 'src/app/_services/surveyHandler.service';


@Component({
  selector: 'bxl-runner-dashboard',
  templateUrl: 'runner-dashboard.component.html',
})

export class RunnerDashboardComponent implements OnInit {
  initialized: boolean = false;
  hasDailyStatus: boolean = true;
  userId: number;
  userCredentialId: number;
  survey: SurveySummaryViewModelRead;
  hasCoachRole: boolean;

  constructor(private auth: AuthenticationService, private modalService: NgbModal, private clientService: ClientsService, private dailyStatusesService: DailyStatusesService,
      private surveyHandlerService: SurveyHandlerService, private breadcrumbs: BreadcrumbsService) {}

  ngOnInit(): void {
    this.breadcrumbs.SetSecondaryBreadcrumb('Dashboard', 'dashboard', []);
    const today = new Date();
    this.auth.fetchUserProfile().subscribe(user => {
      this.userId = user.id;
      this.userCredentialId = user.userCredentialId;
      this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);

      this.surveyHandlerService.getSurvey().subscribe(result => {
        this.survey = result;
        this.initialized = true;
      });

      const timeZoneOffset = new Date().getTimezoneOffset();
      return this.clientService.doesUserHaveDailyStatusToday(user.id, timeZoneOffset).subscribe(hasEntry => {
        this.hasDailyStatus = hasEntry;
      });
    });
  }

  onDailyStatus() {
    const modalRef = this.modalService.open(DailyStatusModalComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.userId = this.userId;
    modalRef.result.then(
      (result) => {
      },
      (reason) => {
        if (reason === 'saved') {
          this.dailyStatusesService.SendEntryAddedEvent();
          this.hasDailyStatus = true;
        }
      }
    );
  }

  handleSurvey() {
    this.surveyHandlerService.handleSurvey();
  }
}
